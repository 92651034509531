import { SELLOUT_PRICE_VS_SUGGESTED_PRICE_TABLE, SOP_VS_SRP} from './constants';

let intiState = {
  selloutPriceVsSuggestedPriceDataLoader: false,
  sopVsSrpDataLoader: false,
};

const selloutPriceVsSuggestedFilter = (state = intiState, action) => {
  switch (action.type) {
    case SELLOUT_PRICE_VS_SUGGESTED_PRICE_TABLE:
      return {
        ...state,
        selloutPriceVsSuggestedPriceDataLoader: action.payload,
      };
    case SOP_VS_SRP:
      return {
        ...state,
        sopVsSrpDataLoader: action.payload,
      };
    default:
      return state;
  }
};

export default selloutPriceVsSuggestedFilter;
