import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Typography } from '@material-ui/core';

const SecTextBtn = styled(Button)(({ theme }) => ({
  textTransform: 'inherit',
  fontSize: '14px',
  color: theme.palette.secondary.main,
}));

const CstmBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
  fontSize: '12px',
  width: '140px',
  height: '45px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

const Icon = styled('img')(({ theme }) => ({
  width: '30px',
}));

const NotificationTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.9px;
`;

const NotificationTitleColor = styled(Typography)<{ type: string }>(
  ({ type }) => ({
    color:
      type === 'bug'
        ? '#FF721E !important'
        : type === 'alert'
        ? '#CE0065 !important'
        : type === 'new'
        ? '#0093CD !important'
        : type === 'update'
        ? '#00AA52 !important'
        : '#000',
    fontSize: 16,
    fontWeight: 600,
  })
);

export { SecTextBtn, CstmBtn, Icon, NotificationTitle, NotificationTitleColor };
