import { Box, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Dashboard from "../../../../../../components/Dashboard";
import HighchartContainer from "../../../../../../components/HighchartContainer";
import { loader } from "../../../../../../store/actions/common.action";
import { NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import { chartConfig } from "./chartConfig";
import { plotElementsOnChart } from "../../../../../../util/helper";

const PriceRangeGraph: React.FC<{
  data;
  skeleton;
  title?;
  tooltip;
  id;
  type;
}> = ({ data, skeleton, title, tooltip, id, type }) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        const result = formatData(data);
        let entries = Object.entries(result.stackSeries);
        let entriesLength = entries.length;
        while (chart && chart.series.length) {
          chart.series[0].remove();
        }
        chart.xAxis[0].setCategories(result.categories);
        chart.addSeries({
          data: result.series1,
          ...(type === "pepsi"
            ? { name: "PepsiCo Total", color: "#757171" }
            : { name: "Competitor Total", color: "#757171" }),
          type: "spline",
          zIndex: 6,
          lineWidth: 4,
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            formatter: function () {
              return this.y === 0 ? null : this.y.toFixed(1);
            },
          },
          tooltip: {
            headerFormatter: function () {
              return `<b>${this.x.toFixed(1)}</b><br/>`;
            },
            pointFormatter: function () {
              return `${this.series.name}: ${this.y.toFixed(1)}`;
            },
          },
          plotOptions: {
            spline: {
              marker: {
                lineColor: "#D0CECE",
              },
            },
          },
        });

        const colorPallet = [
          "#6C88B7",
          "#486492",
          "#3984A3",
          "#4DADB5",
          "#62AC5A",
          "#92D050",
          "#F1C058",
          "#E08428",
          "#DC3E4E",
          "#C22B49",
        ];

        entries.map(([key, val], index) => {
          chart.addSeries({
            data: val,
            name: key,
            color:
              entriesLength < 10
                ? colorPallet[entriesLength - index - 1]
                : colorPallet[9 - index],
            dataLabels: {
              formatter: function () {
                return this.y === 0 ? null : this.y.toFixed(1);
              },
            },
            tooltip: {
              headerFormatter: function () {
                return `<b>${this.x.toFixed(1)}</b><br/>`;
              },
              pointFormatter: function () {
                return `${this.series.name}: ${this.y.toFixed(
                  1
                )}<br/>Total: ${this.stackTotal.toFixed(1)}`;
              },
            },
          });
        });

        let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
        if (dashbaordData && dashbaordData[id]) {
          plotElementsOnChart(dashbaordData[id], chart, id);
        }
      }
      dispatch(loader(false));
    }
  }, [data]);

  const formatData = (payload) => {
    const categories: any = [];
    const series1: any = [];
    const stackSeries: any = {};
    let entries = Object.keys(payload.priceRanges[0]).reverse();
    payload.priceRanges.map((item) => {
      categories.push(item.date);
      entries.map((key) => {
        if (key === "total") {
          series1.push(item[key]);
        } else if (key !== "date") {
          stackSeries[key]
            ? stackSeries[key].push(item[key])
            : (stackSeries[key] = [item[key]]);
        }
      });
    });
    return { categories, series1, stackSeries };
  };

  return (
    <Dashboard
      title={title}
      tooltip={tooltip}
      id={id}
      chartRef={chartRef}
      showSkeleton={skeleton}
      actionButtons={{
        mmpw: true,
        screenshot: true,
        editMedia: true,
      }}
      // mmpwConfig={{ disableRetriveMMPW: true }}
    >
      {skeleton ? (
        <Grid container justifyContent="space-around">
          <Grid item xs={12} sm={6} md={12}>
            <Skeleton variant="rectangular" height={400} width={"95%"} />
          </Grid>
        </Grid>
      ) : data && Object.keys(data).length > 0 ? (
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={12}>
            <HighchartContainer chartRef={chartRef} id={id}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartConfig}
                ref={chartRef}
              />
            </HighchartContainer>
          </Grid>
        </Grid>
      ) : (
        <Box
          style={{ height: 400 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <NoData>{messages.noData}</NoData>
        </Box>
      )}
    </Dashboard>
  );
};

export default PriceRangeGraph;
