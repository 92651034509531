import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { getImageUrl, getUnitVariationExpectedData } from "../../service";
import { unitVariationLoader } from "../../store/elasticityPricing.action";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import UnitVariationExpected from "./components/UnitVariationExpected";

const UnitVariationExpectedContainer: React.FC<{ filters }> = ({ filters }) => {
  const [data, setData] = useState<any>([]);
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const dispatch = useDispatch();

  const getUnitVariationExpected = () => {
    getUnitVariationExpectedData(filters).then((res) => {
      if (res.status === 200) {
        let response = [...res.data.brands, res.data.allBrands];
        if (_.isArray(response)) {
          Promise.all(
            response?.map(async (item, i) => {
              if (item.parent.imageName) {
                item.parent.image = await getImageUrl(
                  filters.country,
                  item.parent.imageName
                );
              } else {
                i === response.length - 1
                  ? (item.parent.image = await getImageUrl(
                      filters.country,
                      "All Product(Weighted Average)"
                    ))
                  : (item.parent.image = "");
              }
              if (_.isArray(item.child)) {
                await Promise.all(
                  item.child.map(async (c) => {
                    if (c.imageName) {
                      c.image = await getImageUrl(filters.country, c.imageName);
                    } else {
                      c.image = "";
                    }
                  })
                );
              }
            })
          ).then(() => {
            setData([...response]);
            dispatch(unitVariationLoader(false));
            setSkeleton(false);
          });
        }
      } else {
        dispatch(unitVariationLoader(false));
        setSkeleton(false);
      }
    });
  };

  useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      setData([]);
      getUnitVariationExpected();
    }
  }, [filters]);

  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <CardContent>
        <Title className="ellipsis">Units Variation Expected</Title>
        {!skeleton ? (
          <>
            {_.isArray(data) && data.length > 0 ? (
              data?.map((item, i) => (
                <>
                  <ExpansionPanel
                    parent={{
                      icon: item?.parent.image ? item?.parent.image : null,
                      imageHeight: 100,
                      imageWidth: 100,
                      buttonOffset: 37,
                      name: item?.parent.name,
                      displayName: item?.parent.displayName,
                      component: (
                        <UnitVariationExpected
                          id={`elasticity-pricing-track-unit-variation-expected-parent-${i}`}
                          data={item?.parent}
                        />
                      ),
                    }}
                    childrens={item?.child?.map((c, j) => {
                      return {
                        icon: c.image ? c.image : null,
                        imageHeight: 100,
                        imageWidth: 100,
                        height: 1025,
                        iconPosition: 200,
                        name: c.name,
                        displayName: c?.displayName,
                        component: (
                          <UnitVariationExpected
                            id={`elasticity-pricing-track-unit-variation-expected-child-${j}`}
                            data={c}
                          />
                        ),
                      };
                    })}
                  />
                </>
              ))
            ) : (
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={400}
              >
                <NoData sx={{ color: "black" }}>{messages.noData}</NoData>
              </Grid>
            )}
          </>
        ) : (
          <Grid container>
            <Grid item sm={12}>
              <Skeleton
                variant="rectangular"
                width="auto"
                height={400}
                className="m-b-20 m-t-10"
              />
            </Grid>
            <Grid item sm={12}>
              <Skeleton
                variant="rectangular"
                width="1100px"
                sx={{ margin: "auto" }}
                height={100}
                className="m-b-20 m-t-10"
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UnitVariationExpectedContainer;
