import React, { useEffect, useState } from "react";
import { CardContent, Divider, Grid } from "@mui/material";
import { Pin } from "../../../../assets/icons/mmpw/icons";

import {
  StyledCard,
  StyledTextField,
  Title,
} from "../../MMPW.Styled";
import { PrimaryBtn, BlackTextBtn } from "../../../../styles/Common.Styled";
import { convertSVGToBase64, getRandomColor } from "../../../../util/helper";
import { useSelector } from "react-redux";
import _ from "lodash";
import { pinConfig } from "../../config";

const CommentBox: React.FC<{ callback: any, dashboardId?}> = ({ callback, dashboardId }) => {
  const [comment, setComment] = useState("");
  const [commentNumber, setCommentNumber] = useState<number>();
  const onChangeText = (e) => {
    setComment(e.target.value);
  };

  const userDetail = useSelector((state: any) => state.User);

  const PinIcon = (props) => (
    <svg width={45} height={45} id="comment-pin" style={{ display: 'none' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" x={0} y={15} viewBox="0 0 120 124" enable-background="new 0 0 122.879 122.867"><path fill-rule="evenodd" clip-rule="evenodd" d="M83.88,0.451L122.427,39c0.603,0.601,0.603,1.585,0,2.188l-13.128,13.125 c-0.602,0.604-1.586,0.604-2.187,0l-3.732-3.73l-17.303,17.3c3.882,14.621,0.095,30.857-11.37,42.32 c-0.266,0.268-0.535,0.529-0.808,0.787c-1.004,0.955-0.843,0.949-1.813-0.021L47.597,86.48L0,122.867l36.399-47.584L11.874,50.76 c-0.978-0.98-0.896-0.826,0.066-1.837c0.24-0.251,0.485-0.503,0.734-0.753C24.137,36.707,40.376,32.917,54.996,36.8l17.301-17.3 l-3.733-3.732c-0.601-0.601-0.601-1.585,0-2.188L81.691,0.451C82.295-0.15,83.279-0.15,83.88,0.451L83.88,0.451z" /></svg>
      <circle
        cx={28}
        cy={12}
        r={12}
        strokeWidth={1}
        fill="#25d4cf"
      />
      <text x={props.commentNumber === 1 ? 24 : props.commentNumber > 9 ? 22 : 24} y={17} fill="#fff">{props.commentNumber}</text>
    </svg>
  )

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("mmpw"));
    if (localStorageData && localStorageData[dashboardId] && _.isArray(localStorageData[dashboardId].Comment)) {
      const result = _.orderBy(localStorageData[dashboardId].Comment, 'commentNumber', 'desc');
      setCommentNumber(_.isArray(result) && result.length>0? result[0].commentNumber + 1 : 1);
    } else {
      setCommentNumber(1);
    }
  }, [])

  const saveHandler = async () => {
    if (comment) {
      await convertSVGToBase64('comment-pin', 40, 40).then((response: any) => {
        callback({
          type: "Comment", content: comment, ...response,
          user: userDetail.data ? `${userDetail.data.firstName} ${userDetail.data.lastName}` : '',
          date: new Date(),
          commentNumber,
          width: 45,
          height: 45
        });
      })
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title className="m-b-10">Comment</Title>
            {/* <Divider sx={{ borderColor: "#858C94" }} /> */}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <StyledTextField
                  multiline
                  rows={3}
                  variant="outlined"
                  onChange={onChangeText}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PrimaryBtn
              variant="contained"
              onClick={saveHandler}
              className="m-r-10"
            >
              Add
            </PrimaryBtn>
            <BlackTextBtn
              className="disable"
              onClick={() => {
                callback();
              }}
            >
              Cancel
            </BlackTextBtn>
          </Grid>
        </Grid>
      </CardContent>
      {pinConfig === 'number'? 
        <PinIcon commentNumber={commentNumber} />
        :
        <Pin style={{width: 0, height: 0}} id="comment-pin" fill={getRandomColor()}/>
      }
    </StyledCard>
  );
};

export default CommentBox;
