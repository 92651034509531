import React, { useEffect } from 'react';
import DashboardActions from '../../components/DashboardActions';
import PriceLadderGraph from './components/PriceLadderGraph';
import TopFilters from './components/TopFilters/TopFilters';
import { pageList } from '../../mocks/common';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { useDispatch, useSelector } from 'react-redux';
import { priceLadderDataLoader } from './store/PriceLadder.action';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';
import _ from 'lodash';

const PriceLadder: React.FC<{}> = () => {
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [defaultFilters, setDefaultFilters] = React.useState<any>({});
  const [graphRendering, setGraphRendering] = React.useState(true);
  const [updatedGraphData, setUpdatedGraphData] = React.useState([]);
  const [disableDownloadButton, setDisableDownloadButton] = React.useState(true);
  const dispatch = useDispatch();

  const PriceLadderDataLoader = useSelector((state: any) => state.PriceLadderDataLoader);

  const onApplyFilter = (data) => {
    if (data && data.filters) {
      setDisableDownloadButton(false);
      setFilters(data.filters);
      setGraphRendering(true);
      setUpdatedGraphData([]);
      setDefaultFilters({});
    }
  };
  const topClearCallBack = () => {
    setGraphRendering(false);
  };

  const getSaveDashBoardsData = (data) => {
    // dispatch(priceLadderDataLoader(true));
    if (data.filters) {
      let savedDashboardData: any = { filters: JSON.parse(data.filters) };
      let savedDashboardDataPepsico: any = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      setUpdatedGraphData(savedDashboardData?.filters?.updatedGraphData);
      setDefaultFilters(savedDashboardDataPepsico?.filters?.global);
      setFilters(savedDashboardData?.filters?.global);
    }
  };

  const isDisabled: boolean = false;
  return (
    <>
      <DashboardActions
        title='Price Ladder Dashboard'
        disabled={isDisabled}
        data={{
          filters: {
            global: { ...appliedFilters },
            updatedGraphData:
              updatedGraphData && updatedGraphData.length > 0
                ? _.cloneDeep(updatedGraphData)?.map((g, i) => {
                    return g?.products?.map((p, j) => {
                      delete p?.imagBlob;
                      return p;
                    });
                  })
                : [],
          },
        }}
        dashboardKey={pageList[14].id}
        callback={getSaveDashBoardsData}
        disableDownloadButton={disableDownloadButton || PriceLadderDataLoader.priceLadderDataLoader}
      >
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[14].value} />
        </Grid>
        <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} topClearCallBack={topClearCallBack} />
        {graphRendering && (
          <>
            <PriceLadderGraph
              filters={appliedFilters}
              defaultFilters={defaultFilters}
              setUpdatedGraphData={setUpdatedGraphData}
              updatedGraphData={updatedGraphData}
            />
            <DashboardSourceList dashboardName={pageList[14].id} appliedFilters={appliedFilters} category={appliedFilters?.pepsi?.category} />
          </>
        )}
      </DashboardActions>
    </>
  );
};
export default PriceLadder;
