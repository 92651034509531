import React, { useEffect } from "react";
import { Divider, Grid } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import _ from "lodash";
import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup";
import TextField from '@mui/material/TextField';
import Dropdown from "../../../../components/Dropdown";
import filterMock from "../../../../mocks/brandLadderMock"
import { fetchFilterMonthData } from "../../service";

const initialCustomPremiumValues = {
  valueStart: '',
  valueEnd: '',
  mainStreamStart: '',
  mainStreamEnd: '',
  mainStreamPlusStart: '',
  mainStreamPlusEnd: '',
  premiumStart: '',
  premiumEnd: '',
  superPremiumStart: '',
  superPremiumEnd: '',
  megaPremiumStart: '',
  megaPremiumEnd: ''
}

const initialDefaultPremiumValues = {
  valueStart: '',
  valueEnd: "80",
  mainStreamStart: "81",
  mainStreamEnd: "100",
  mainStreamPlusStart: "101",
  mainStreamPlusEnd: "120",
  premiumStart: "121",
  premiumEnd: "150",
  superPremiumStart: "151",
  superPremiumEnd: "200",
  megaPremiumStart: "201",
  megaPremiumEnd: '250'
}

const indexInitialState = {
  valueIndexData: null,
  mainStreamIndexData: null,
  mainStreamPlusIndexData: null,
  premiumIndexData: null,
  superPremiumIndexData: null,
  megaPremiumIndexData: null
}

const MeasuresFilters: React.FC<{ clearFilters, selectedMeasuresFilters; callback; callbackIndexes; selectedGeoFilters?, defaultSelection, productFilter?}> = ({
  clearFilters,
  selectedMeasuresFilters,
  callback,
  callbackIndexes,
  selectedGeoFilters,
  defaultSelection,
  productFilter
}) => {
  const [tier, setTier] = React.useState('');
  const [premiumValue, setPremiumValue] = React.useState<any>({});
  const [dataIndexmock, setDataIndexmock] = React.useState<any>({});
  const [monthData, setMonthData] = React.useState<any>(null);

  useEffect(() => {
    if (clearFilters) {
      clearFilter()
    }
  }, [clearFilters]);

  useEffect(()=>{
    if(selectedMeasuresFilters.measuresLevel){
      setTier(selectedMeasuresFilters.measuresLevel)
    }
  }, [selectedMeasuresFilters.measuresLevel])

  useEffect(()=>{
    if(Object.keys(defaultSelection).length>0){
      setPremiumValue({...defaultSelection})
      callbackIndexes({...defaultSelection});
      const newIndexesData = {...indexInitialState}
      Object.keys(indexInitialState).map(key=>{
        let start = defaultSelection[key.replace('IndexData', 'Start')];
        newIndexesData[key] = generateIndexs(start? start : 31);
      })
      setDataIndexmock({...newIndexesData})
    }
  },[defaultSelection])

  useEffect(() => {
    if (tier === 'default') {
      setPremiumValue({ ...initialDefaultPremiumValues });
      callbackIndexes({ ...initialDefaultPremiumValues });
      setDataIndexmock({ ...indexInitialState })
    } else if(tier === 'customize'){
      const indexes = generateIndexs(31);
      setDataIndexmock({ ...indexInitialState, valueIndexData: indexes });
      setPremiumValue({ ...initialCustomPremiumValues })
      callbackIndexes({ ...initialCustomPremiumValues });
    }
  }, [tier])

  const [previousSelectedCountry, setPreviousSelectedCountry] = React.useState<string>('');

  useEffect(() => {
    if (previousSelectedCountry && productFilter && productFilter.anchorCategory) {
      apiCallForMonthData(
        {
          country: selectedGeoFilters.country,
          geoLevel: selectedGeoFilters.geoLevel,
          geoLevel2: selectedGeoFilters.geoLevel2,
          category: productFilter.anchorCategory
        });
    }
  }, [previousSelectedCountry, productFilter]);

  useEffect(() => {
    if (selectedGeoFilters.country && selectedGeoFilters.country !== previousSelectedCountry) {
      setPreviousSelectedCountry(selectedGeoFilters.country)
    }
  }, [selectedGeoFilters]);

  const clearFilter = () => {
    setTier('default');
    setDataIndexmock({ ...indexInitialState })
  }

  const apiCallForMonthData = async (payload) => {
    await fetchFilterMonthData(payload)
      .then((response) => {
        if (response && response.data) {
          setMonthData(response.data.lastRefreshDate);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const onChangeOption = (key, value) => {
    let defaultValues = tier === 'default' ? { ...initialDefaultPremiumValues } : { ...initialCustomPremiumValues };
    let indexes = [];
    let start = `${parseInt(value) + 1}`;
    switch (key) {
      case 'value':
        defaultValues = {
          ...defaultValues,
          valueEnd: value,
          mainStreamStart: start
        }
        indexes = generateIndexs(start);
        setDataIndexmock({ ...dataIndexmock, mainStreamIndexData: indexes });
        break;
      case 'mainStream':
        defaultValues = {
          ...defaultValues,
          valueEnd: premiumValue.valueEnd,
          mainStreamStart: premiumValue.mainStreamStart,
          mainStreamEnd: value,
          mainStreamPlusStart: start
        }
        indexes = generateIndexs(defaultValues.mainStreamPlusStart);
        setDataIndexmock({ ...dataIndexmock, mainStreamPlusIndexData: indexes })
        break;
      case 'mainStreamplus':
        defaultValues = {
          ...defaultValues,
          valueEnd: premiumValue.valueEnd,
          mainStreamStart: premiumValue.mainStreamStart,
          mainStreamEnd: premiumValue.mainStreamEnd,
          mainStreamPlusStart: premiumValue.mainStreamPlusStart,
          mainStreamPlusEnd: value,
          premiumStart: start
        }
        indexes = generateIndexs(defaultValues.premiumStart);
        setDataIndexmock({ ...dataIndexmock, premiumIndexData: indexes })
        break;
      case 'premium':
        defaultValues = {
          ...defaultValues,
          valueEnd: premiumValue.valueEnd,
          mainStreamStart: premiumValue.mainStreamStart,
          mainStreamEnd: premiumValue.mainStreamEnd,
          mainStreamPlusStart: premiumValue.mainStreamPlusStart,
          mainStreamPlusEnd: premiumValue.mainStreamPlusEnd,
          premiumStart: premiumValue.premiumStart,
          premiumEnd: value,
          superPremiumStart: start
        }
        indexes = generateIndexs(defaultValues.superPremiumStart);
        setDataIndexmock({ ...dataIndexmock, superPremiumIndexData: indexes })
        break;
      case 'superPremium':
        defaultValues = {
          ...defaultValues,
          valueEnd: premiumValue.valueEnd,
          mainStreamStart: premiumValue.mainStreamStart,
          mainStreamEnd: premiumValue.mainStreamEnd,
          mainStreamPlusStart: premiumValue.mainStreamPlusStart,
          mainStreamPlusEnd: premiumValue.mainStreamPlusEnd,
          premiumStart: premiumValue.premiumStart,
          premiumEnd: premiumValue.premiumEnd,
          superPremiumStart: premiumValue.superPremiumStart,
          superPremiumEnd: value,
          megaPremiumStart: start
        }
        indexes = generateIndexs(defaultValues.megaPremiumStart);
        setDataIndexmock({ ...dataIndexmock, megaPremiumIndexData: indexes })
        break;
      // case 'megaPremium':
      //   defaultValues = {...premiumValue, megaPremiumEnd: value};
      //   break;
      default: break;
    }
    callbackIndexes(defaultValues);
    setPremiumValue(defaultValues)
  }


  const generateIndexs = (indexNumber) => {
    let indexValue: any = [];
    for (let i = parseInt(indexNumber) + 9; i <= 250; i += 10) {
      let rec = { label: `${[i]}`, value: `${[i]}` }
      indexValue.push(rec)
    }
    return indexValue;
  }

  return (
    <>
      <Grid
        container
        display='flex'
        alignItems='center'
        justifyContent="space-between"
        key={`api-brandLadder-measures-variable-config`}
      >
        <Grid item display='flex' flexDirection={'row'}>
          <DropdownTitle style={{ lineHeight: '35px', marginRight: '20px' }}>{filterMock.measuresFilter.somGroup.title}</DropdownTitle>
          <Grid style={{ marginBottom: '20px' }}>
            <CheckBoxGroup
              data={filterMock.measuresFilter.somGroup.options}
              color="#000"
              direction="row"
              single={true}
              defaultOption={selectedMeasuresFilters.somGroup}
              select={(data) => callback({ ...selectedMeasuresFilters, somGroup: data })}
            />
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, borderBottom: 0 }} style={{ marginLeft: '20px', marginRight: '20px' }}></Divider>
          <Grid display='flex'>
            <RadioButtonGroup
              key={`measures-filters-1`}
              color="#000"
              showButton={filterMock.measuresFilter.somMeasure.showRadioButton}
              data={filterMock.measuresFilter.somMeasure.options}
              defaultOption={selectedMeasuresFilters.somMeasure}
              select={(data) => callback({ ...selectedMeasuresFilters, somMeasure: data })}
              direction={'row'}
            />
          </Grid>
        </Grid>
        <Grid item display='flex' flexDirection={'row'} justifyContent={'flex-end'} alignContent={'end'}>
          <DropdownTitle style={{ lineHeight: '45px', marginRight: '20px' }}>{filterMock.measuresFilter.monthRange.title}</DropdownTitle>
          <Grid display='flex' justifyContent={'flex-end'} style={{ marginBottom: '20px', position: 'relative' }}>
            <RadioButtonGroup
              key={`measures-filters-1`}
              color="#000"
              showButton={filterMock.measuresFilter.monthRange.showRadioButton}
              data={filterMock.measuresFilter.monthRange.options}
              defaultOption={selectedMeasuresFilters.monthRange}
              select={(data) => callback({ ...selectedMeasuresFilters, monthRange: data })}
              direction={'row'}
            />
            {monthData && <div style={{ position: 'absolute', right: 15, bottom: -30, padding: 5, backgroundColor: '#c8deef', color: '#25d4cf', fontWeight: '700' }}>Data through {monthData}</div>}

          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} key={`measures-dropdown-container-1`}>
        <Grid item xs={12} sm={3}>
          <DropdownTitle>{filterMock.measuresFilter.measuresLevel.title}</DropdownTitle>
          <RadioButtonGroup
            key={`measures-filters-1`}
            color="#000"
            data={filterMock.measuresFilter.measuresLevel.options}
            defaultOption={tier}
            select={(data)=>{
              callback({ ...selectedMeasuresFilters, measuresLevel: data })
              setTier(data)
            }}
          />
        </Grid>
        {tier === 'customize' &&
          <Grid item xs={12} sm={9} style={{ marginTop: 30 }}>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Mega Premium
              </Grid>
              <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                <span style={{ fontSize: '24px' }}>{'>'}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  id="mega-premium"
                  size="small"
                  value={premiumValue.megaPremiumStart}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Super Premium
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="super-premium"
                  value={premiumValue.superPremiumStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  disabled={false}
                  data={dataIndexmock.superPremiumIndexData}
                  onChange={(data) => onChangeOption("superPremium", data[0])}
                  sort={{ enable: false }}
                  defaultOption={[premiumValue.superPremiumEnd]}
                  search={{enable: false}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Premium
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="premium"
                  value={premiumValue.premiumStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  disabled={false}
                  data={dataIndexmock.premiumIndexData}
                  onChange={(data) => onChangeOption("premium", data[0])}
                  sort={{ enable: false }}
                  defaultOption={[premiumValue.premiumEnd]}
                  search={{enable: false}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Main Stream ++
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-stream-plus"
                  value={premiumValue.mainStreamPlusStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  disabled={false}
                  data={dataIndexmock.mainStreamPlusIndexData}
                  onChange={(data) => onChangeOption("mainStreamplus", data[0])}
                  sort={{ enable: false }}
                  defaultOption={[premiumValue.mainStreamPlusEnd]}
                  search={{enable: false}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Main Stream
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-stream"
                  value={premiumValue.mainStreamStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  disabled={false}
                  data={dataIndexmock.mainStreamIndexData}
                  onChange={(data) => onChangeOption("mainStream", data[0])}
                  sort={{ enable: false }}
                  defaultOption={[premiumValue.mainStreamEnd]}
                  search={{enable: false}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Value
              </Grid>
              <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                <span style={{ fontSize: '24px' }}>{'<='}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dropdown
                  disabled={false}
                  data={dataIndexmock.valueIndexData}
                  onChange={(data) => onChangeOption("value", data[0])}
                  sort={{ enable: false }}
                  defaultOption={[premiumValue.valueEnd]}
                  search={{enable: false}}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        {tier === 'default' &&
          <Grid item xs={12} sm={9} style={{ marginTop: 30 }}>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Mega Premium
              </Grid>
              <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                <span style={{ fontSize: '24px' }}>{'>'}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  id="mega-premium"
                  size="small"
                  value={premiumValue.megaPremiumStart}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Super Premium
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="super-premium-1"
                  value={premiumValue.superPremiumStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="super-premium-2"
                  value={premiumValue.superPremiumEnd}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Premium
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="premium-1"
                  value={premiumValue.premiumStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="premium-2"
                  value={premiumValue.premiumEnd}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Main Stream ++
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-plus-stream-1"
                  value={premiumValue.mainStreamPlusStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-plus-stream-2"
                  value={premiumValue.mainStreamPlusEnd}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Main Stream
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-stream-1"
                  value={premiumValue.mainStreamStart}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="main-stream-2"
                  value={premiumValue.mainStreamEnd}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }} display="flex" alignItems="center">
              <Grid item xs={12} sm={2}>
                Value
              </Grid>
              <Grid item xs={12} sm={2} display="flex" justifyContent="center">
                <span style={{ fontSize: '24px' }}>{'<='}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField InputProps={{ autoComplete: 'off' }}
                  type="number"
                  size="small"
                  id="value"
                  value={premiumValue.valueEnd}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
};

export default MeasuresFilters;
