import {
    CREATE_NOTIFICATION,
    CREATE_NOTIFICATION_SUCCESS,
    CREATE_NOTIFICATION_FAILURE,
  } from "../../actions/AlertAndNotification/constants";
  
  let CreateNotificationInitialState = {
    data: null,
    loading: true,
    error: null,
  };
  
  const CreateNotification = (state = CreateNotificationInitialState, action) => {
    switch (action.type) {
      case     CREATE_NOTIFICATION:
        return {
          ...state,
          data: null,
          loading: true,
          error: null,
        };
      case CREATE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case CREATE_NOTIFICATION_FAILURE:
        return {
          ...state,
          data: null,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default CreateNotification;
  