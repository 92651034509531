import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterAccordion from "../../../../components/Filters/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import Indicator from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import mockData, {
  elasticityRadio,
} from "../../../../mocks/priceSettingToolMock";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import ProductFiltersV3 from "../../../../components/ProductFilters-v3";
import MeasureFilters from "../MeasureFilters/MeasureFilters";
import { priceSettingToolDataLoader } from "../../store/priceSettingTool.action";
const TopFilters: React.FC<{
  callback;
  showSkeleton?: boolean;
  defaultFilters?;
}> = ({ callback, showSkeleton, defaultFilters }) => {
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const initialState = {
    country: null,
    geoLevel: null,
    geoLevel2: null,
    channel: null,
  };
  const initialProductState = {
    category: null,
    segment: null,
    brand: null,
    packSize: null,
    permutation: null,
  };
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedProductFilters, setSelectedProductFilters] =
    useState(initialProductState);
  const [selectedMeasureFilters, setMeasureFilters] = useState({
    monthRange:
      defaultFilters.monthRange ||
      mockData.measuresFilter.monthRange.defaultOption.value ||
      undefined,
    priceElasticityCurve:
      defaultFilters.priceElasticityCurve ||
      mockData.measuresFilter.priceElasticityCurve.defaultOption.value ||
      undefined,
    gramsElasticityCurve:
      defaultFilters.gramsElasticityCurve ||
      mockData.measuresFilter.gramsElasticityCurve.defaultOption.value ||
      undefined,
  });
  const [selectedSellOutAndInaseOption, setSelectedSellOutAndInaseOption] =
    React.useState(elasticityRadio.defaultOption.value);
  const dispatch = useDispatch();
  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setSelectedGeoFilters({
        ...selectedGeoFilters,
        country: defaultFilters.country,
        geoLevel: defaultFilters.geoLevel,
        geoLevel2: defaultFilters.geoLevel2,
        channel: defaultFilters.channel,
      });
      setSelectedProductFilters({
        ...selectedProductFilters,
      });
      setSelectedSellOutAndInaseOption(
        defaultFilters.selectedSellOutAndInaseOption || undefined
      );
      setMeasureFilters({
        monthRange: defaultFilters.monthRange || undefined,
        priceElasticityCurve: defaultFilters.priceElasticityCurve || undefined,
        gramsElasticityCurve: defaultFilters.gramsElasticityCurve || undefined,
      });
    }
  }, [defaultFilters]);
  const disableApplyFilter = () => {
    const flag: boolean =
      !selectedMeasureFilters.monthRange ||
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel2 ||
      !selectedProductFilters.permutation;
    return flag;
  };
  const onApplyFilter = async () => {
    dispatch(priceSettingToolDataLoader(true));
    const payload = {
      ...selectedGeoFilters,
      selectedSellOutAndInaseOption,
      ...selectedProductFilters,
      ...selectedMeasureFilters,
    };
    callback(payload);
  };
  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({
      ...selectedGeoFilters,
      country: null,
      geoLevel: null,
      geoLevel2: null,
      channel: null,
    });
    setSelectedSellOutAndInaseOption(elasticRadioButton.defaultOption.value);
    setSelectedProductFilters({
      ...selectedProductFilters,
      category: null,
      segment: null,
      brand: null,
      packSize: null,
      permutation: null,
    });
    setMeasureFilters({
      monthRange:
        mockData.measuresFilter.monthRange.defaultOption.value || undefined,
      priceElasticityCurve:
        mockData.measuresFilter.priceElasticityCurve.defaultOption.value ||
        undefined,
      gramsElasticityCurve:
        mockData.measuresFilter.gramsElasticityCurve.defaultOption.value ||
        undefined,
    });
  };
  const reduxState = useSelector((state: any) => state);
  const selectProductLogic = (e) => {
    setSelectedProductFilters({ ...e });
    clearGeoFilter(false);
  };
  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || reduxState.PriceSettingTool.priceSettingToolDataLoader}
      />
      <CardContent>
        <FilterAccordion title="Geo-Filters">
          <GeoFiltersV2
            key="api-predictive-top-filter"
            data={geoFilterV2Config}
            onSelectFilters={setSelectedGeoFilters}
            clearFilter={clearFilterData}
            apiPath="ApiPredictive"
            showLoader={setLoader}
            defaultFilters={defaultFilters}
            showDatePicker={false}
          />
        </FilterAccordion>
        {/* <FilterAccordion title="Additional Filters">
          <BlackInputTitle sx={{ mb: 2, fontSize: "14px" }}>
            Data Sources
          </BlackInputTitle>
          <Grid container spacing={2} columns={17}>
            <Grid item>
              <RadioButtonGroup
                key={"elasticity-radio-1"}
                color="#000"
                showButton={true}
                data={elasticityRadio.elasticRadioButton}
                defaultOption={selectedSellOutAndInaseOption}
                select={setSelectedSellOutAndInaseOption}
              />
            </Grid>
          </Grid>
        </FilterAccordion> */}
        <FilterAccordion title="Product Filters">
          <ProductFiltersV3
            onChange={(e) => selectProductLogic(e)}
            data={mockData.productFilter}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters}
            clearFilter={clearFilterData}
            // params={{ productFilterFlag: "pepsi", ...selectedGeoFilters }}
            apiURL="filter/priceSettingProductFilter"
            defaultFilters={
              defaultFilters
                ? {
                    category: defaultFilters.category,
                    segment: defaultFilters.segment,
                    brand: defaultFilters.brand,
                    packSize: defaultFilters.packSize,
                    permutation: defaultFilters.permutation,
                  }
                : null
            }
          />
        </FilterAccordion>
        <FilterAccordion title="Measure Filters">
          <MeasureFilters
            callback={setMeasureFilters}
            clearFilters={clearFilterData}
            selectedMeasuresFilters={selectedMeasureFilters}
          />
        </FilterAccordion>
        <Grid className="p-l-16">
          <OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={disableApplyFilter()}
            color="primary"
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TopFilters;
