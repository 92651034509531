import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { RemarkChip, RemarkChipTitle, StyleTotalScore, StyledHeading, StyledKPIValue, StyledKPIheading, StyledScore, } from '../../ExecutionCommon.Styled'
interface OverviewCardComponentProps {
  data: any
};

export const HeadingMapper={
  'SHELFANDMERCHANDISING':'Shelf & Merchandising',
  'ASSORTMENT':'Assortment',
  'MIX':'Mix',
  'PRICE':'Price'
}
const OverviewCard: React.FC<OverviewCardComponentProps> = ({ data }) => {
  return (
    <>
      <Card sx={{ border: "none", boxShadow: "none" }}>
        <CardContent sx={{ padding:'10px 8px' }}>
          {console.log(data.title)}
          <StyledHeading >{HeadingMapper[data.title]}</StyledHeading>
          <Grid container  spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <StyledScore>{parseFloat(data.score).toFixed(1)}</StyledScore>
                <StyleTotalScore>/100</StyleTotalScore>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
              <RemarkChip status={data.status} >
                <RemarkChipTitle status={data.status}>{data.status}</RemarkChipTitle>
              </RemarkChip>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {data.kpi.map((item: any) => {
              return <>
                <Grid item xs={6}>
                  <StyledKPIheading>  {
                    Object.keys(item)[0]
                  }</StyledKPIheading>
                </Grid>

                <Grid item xs={6}>

                  <StyledKPIValue>
                    {
                      parseFloat(item[Object.keys(item)[0]]).toFixed(1)
                    }<span style={{opacity:'0.6',fontSize:'10px'}}>/100</span>
                  </StyledKPIValue>
                </Grid>
              </>
            })}
          </Grid>
        </CardContent>
      </Card>

    </>
  )
}

export default OverviewCard