import React from 'react';
import CategoryDahboardList from '../../components/CategoryDashboardList/CartegoryDashboadList';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageList, pageNames } from '../../mocks/common';

const TradeManagement: React.FC = () => {
  return <PageContainer page={pageNames.tradeManagement}>
    <CategoryDahboardList title={'Trade Management'} categoryKey={'trade_management'} pageName={pageList[15].value}/>
  </PageContainer>;
};
export default TradeManagement;
