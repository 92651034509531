import * as React from 'react';
import {
	CardContent,
	Grid,
	Card,
	Popover
} from '@mui/material';
import { Title, CancelButton, SaveButton, ColorPickerBox } from '../../MMPW.Styled';
import ColorPicker from '../ColorPicker';
import _ from 'lodash';

const LineBackground: React.FC<{ data, callback }> = ({ data, callback }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [sections, setSections] = React.useState([]);
	const [selectedSection, setSelectedSection] = React.useState<any>({});

	const handleClick = (event, index) => {
		setAnchorEl(event.currentTarget);
		setSelectedSection({ ...sections[index], index });
	};

	const pickedColor = (borderColor) => {
		setSelectedSection({ ...selectedSection, borderColor });
		const allSections = [...sections];
		allSections[selectedSection.index].borderColor = borderColor;
		setSections(allSections);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelectedSection({});
	};
	const colorPickerOpen = Boolean(anchorEl);

	React.useEffect(() => {
		setSections(data);
	}, [data])

	const onSave = () => {
		callback({ type: 'LineBorderColor', payload: [...sections] })
	}

	const onCancel = () => {
		callback(null)
	}

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid>
				<Grid item xs={12} className="m-b-20">
					<Title>Change Line Background</Title>
					<hr />
				</Grid>
				<Grid>
					{
						_.isArray(sections) && sections.map((item, i) =>
							<Grid container display="flex" alignItems="center" className="m-b-10">
								<Grid item xs={10}>
									{item.title}
								</Grid>
								<Grid item xs={2}>
									<ColorPickerBox height={30} width={40} color={item.borderColor} onClick={(e) => handleClick(e, i)} />
								</Grid>
							</Grid>
						)
					}
					<Popover
						anchorEl={anchorEl}
						open={colorPickerOpen}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<ColorPicker color={selectedSection.borderColor} callback={pickedColor} />
					</Popover>
				</Grid>
			</CardContent>
		</Card >
	);
};

export default LineBackground;
