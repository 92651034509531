import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';

const Icon = styled('img')(({ theme }) => ({
  width: '50px',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '20px',
  letterSpacing: '0.5px',
  fontWeight: 600,
  // color: "#1E1E1F",
}));

const ShortDiscription = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '18px',
  letterSpacing: '0.45px',
}));

const TitleSubText = styled(Typography)(({ theme }) => ({
  marginLeft: '25px',
  textAlign: 'left',
  fontSize: '14px',
  letterSpacing: '0.35px',
  color: '#858C94',
  // color: theme.palette.secondary.main,
}));

const SubtitleButton = styled(Button)(({ theme }) => ({
  textTransform: 'inherit',
  marginLeft: '25px',
  textAlign: 'left',
  fontSize: '14px',
  letterSpacing: '0.35px',
  color: theme.palette.secondary.main,
}));

export {
  Icon,
  Title,
  Subtitle,
  ShortDiscription,
  TitleSubText,
  SubtitleButton,
};
