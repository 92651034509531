import { API } from "../../util/helper"

export const fetchPricePianoData = async (data) => {
    try {
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/pricepianorange/pricerangegraph`,
        data
      );
    } catch (e) {
      return e;
    }
  };

  export const fetchPricePianoVisTwoData = async (data) => {
    try {
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/pricepianorange/GetPricePiano`,
        data
      );
    } catch (e) {
      return e;
    }
  };