import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import FilterAccordion from '../../../../components/Filters/Filters';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import Indicator from '../../../../components/Loader';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { initialState } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import filterMock from '../../../../mocks/brandLadderMock';
import MeasuresFilters from '../MeasuresFilters';
import { brandLadderDataLoader } from '../../store/brandLadder.action';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import Dropdown from '../../../../components/Dropdown';
import { CustomDropDownTitle } from '../../../../components/ProductFilters-v3/ProductFilters-v3';
import { enableCompetitorFilter } from '../../../../util/helper';
const TopFilters: React.FC<{ callback; defaultFilters?; savedIndexes }> = ({ callback, defaultFilters, savedIndexes }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedAnchorFilters, setSelectedAnchorFilters] = React.useState<any>({});
  const [defaultAnchorFilters, setDefaultAnchorFilters] = useState({});
  const [selectedPepsicoFilters, setSelectedPepsicoFilters] = React.useState<any>({});
  const [defaultPepsicoFilters, setDefaultPepsicoFilters] = useState({});
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] = React.useState<any>(defaultFilters?.competitor || {});
  const [defaultCompetitorFilters, setDefaultCompetitorFilters] = useState({});
  const [anchorProductComparision, setAnchorProductComparision] = useState(
    defaultFilters.anchorProductComparision || filterMock.comparisonFilter.anchorProductComparision.defaultOption.value || undefined
  );
  const [data, setData] = useState(defaultFilters.data || filterMock.comparisonFilter.data.defaultOption.value || undefined);
  const [comparisonLevel, setComparisonLevel] = useState(filterMock.comparisonFilter.comparisonLevel.defaultOption.value || undefined);
  const [prevComparisonLevel, setPrevComparisonLevel] = useState(filterMock.comparisonFilter.comparisonLevel.defaultOption.value || undefined);
  const [selectedMeasureFilters, setMeasureFilters] = useState({
    somMeasure: filterMock.measuresFilter.somMeasure.defaultOption.value,
    monthRange: filterMock.measuresFilter.monthRange.defaultOption.value,
    somGroup: filterMock.measuresFilter.somGroup.defaultOption.value,
    measuresLevel: filterMock.measuresFilter.measuresLevel.defaultOption.value,
  });
  const [indexes, setIndexes] = useState<any>({});
  const [productMockData, setProductMockData] = useState<any>({});
  const [compitetorMockData, setcompitetorMockData] = useState<any>({});
  const [clearPepsiCompetitorFilters, setClearPepsiCompetitorFilters] = useState<boolean>(false);
  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      setMeasureFilters({
        somMeasure: defaultFilters.somMeasure || filterMock.measuresFilter.somMeasure.defaultOption.value || undefined,
        monthRange: defaultFilters.monthRange || filterMock.measuresFilter.monthRange.defaultOption.value || undefined,
        somGroup: defaultFilters.somGroup || filterMock.measuresFilter.somGroup.defaultOption.value || undefined,
        measuresLevel: defaultFilters.measuresLevel || filterMock.measuresFilter.measuresLevel.defaultOption.value || undefined,
      });
      setData(defaultFilters?.data);
    }
  }, [defaultFilters]);
  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setAnchorProductComparision(filterMock.comparisonFilter.anchorProductComparision.defaultOption.value || undefined);
    setData(filterMock.comparisonFilter.data.defaultOption.value || undefined);
    setComparisonLevel(filterMock.comparisonFilter.comparisonLevel.defaultOption.value || undefined);
    setPrevComparisonLevel(filterMock.comparisonFilter.comparisonLevel.defaultOption.value || undefined);
    setMeasureFilters({
      somMeasure: filterMock.measuresFilter.somMeasure.defaultOption.value || undefined,
      monthRange: filterMock.measuresFilter.monthRange.defaultOption.value || undefined,
      somGroup: filterMock.measuresFilter.somGroup.defaultOption.value || undefined,
      measuresLevel: filterMock.measuresFilter.measuresLevel.defaultOption.value || undefined,
    });
    setSelectedAnchorFilters({});
    setSelectedPepsicoFilters({});
    setSelectedCompetitorFilters({});
  };
  const setSelectedGeoFiltersLogic = (e) => {
    setComparisonLevel(filterMock.comparisonFilter.comparisonLevel.defaultOption.value);
    setPrevComparisonLevel(filterMock.comparisonFilter.comparisonLevel.defaultOption.value);
    setTimeout(() => {
      setSelectedGeoFilters({ ...e });
      if (clearFilterData) {
        clearGeoFilter(false);
      }
    }, 0);
  };
  const onApplyFilter = async () => {
    dispatch(brandLadderDataLoader(true));
    if (selectedGeoFilters.periodView) {
      delete selectedGeoFilters.periodView;
    }
    const anchorSelect = {
      category: selectedAnchorFilters.anchorCategory,
      segment: selectedAnchorFilters.anchorSegment,
      brand: selectedAnchorFilters.anchorBrand,
      subBrand: selectedAnchorFilters.anchorSubBrand,
      packSize: selectedAnchorFilters.anchorPackSize,
    };
    callback({
      filters: {
        ...selectedGeoFilters,
        comparisonLevel,
        anchorProductComparision,
        data,
        ...selectedMeasureFilters,
        pepsi: { ...selectedPepsicoFilters },
        competitor: { ...selectedCompetitorFilters },
        anchor: { ...anchorSelect },
      },
      indexes,
    });
  };
  useEffect(() => {
    if (!selectedAnchorFilters.anchorBrand) {
      setAnchorProductComparision('segment');
    } else if (selectedAnchorFilters.anchorBrand) {
      setAnchorProductComparision('product');
    }
  }, [selectedAnchorFilters.anchorBrand]);
  useEffect(() => {
    if (comparisonLevel === 'brand') {
      setProductMockData({ ...filterMock.productFilterBrand });
      setcompitetorMockData({ ...filterMock.competitorFilterBrand });
    } else if (comparisonLevel === 'brandPackSize') {
      setProductMockData({ ...filterMock.productFilterBrandPackSize });
      setcompitetorMockData({ ...filterMock.competitorFilterBrandPackSize });
    } else if (comparisonLevel === 'subBrand') {
      setProductMockData({ ...filterMock.productFilterSubBrand });
      setcompitetorMockData({ ...filterMock.competitorFilterSubBrand });
    } else if (comparisonLevel === 'subBrandPackSize') {
      setProductMockData({ ...filterMock.productFilterSubBrandPackSize });
      setcompitetorMockData({ ...filterMock.competitorFilterSubBrandPackSize });
    }
    // defaultFilters = {}
  }, [comparisonLevel]);
  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      setComparisonLevel(defaultFilters.comparisonLevel);
      setTimeout(() => {
        setPrevComparisonLevel(defaultFilters.comparisonLevel);
      }, 0);
      const defaultAnchor = {
        anchorCategory: defaultFilters.anchor.category,
        anchorSegment: defaultFilters.anchor.segment,
        anchorBrand: defaultFilters.anchor.brand,
        anchorSubBrand: defaultFilters.anchor.subBrand,
        anchorPackSize: defaultFilters.anchor.packSize,
      };
      setSelectedAnchorFilters({ ...defaultAnchor });
      setDefaultAnchorFilters({ ...defaultAnchor });
      setSelectedPepsicoFilters({ ...defaultFilters.pepsi });
      setDefaultPepsicoFilters({ ...defaultFilters.pepsi });
      setSelectedCompetitorFilters({ ...defaultFilters.competitor });
      setDefaultCompetitorFilters({ ...defaultFilters.competitor });
    }
  }, [defaultFilters]);
  const onChangeAnchorFilter = (data) => {
    setDefaultAnchorFilters({});
    if (JSON.stringify(selectedAnchorFilters.anchorSegment) !== JSON.stringify(data.anchorSegment)) setClearPepsiCompetitorFilters(true);
    setTimeout(() => {
      setSelectedAnchorFilters(data);
    }, 0);
  };
  const onChangePepsiFilter = (data) => {
    setDefaultPepsicoFilters({});
    setClearPepsiCompetitorFilters(false);
    setTimeout(() => {
      setSelectedPepsicoFilters(data);
    }, 0);
  };
  const onChangeCompetitorFilter = (data) => {
    setDefaultCompetitorFilters({});
    setClearPepsiCompetitorFilters(false);
    setTimeout(() => {
      setSelectedCompetitorFilters(data);
    }, 0);
  };
  const disableApplyFilter = () => {
    const flag =
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel2 ||
      !data ||
      // (comparisonLevel === "brand"
      //   ? !selectedAnchorFilters.anchorBrand
      //   : !selectedAnchorFilters.anchorPackSize) ||
      !selectedAnchorFilters.anchorSegment ||
      !(selectedPepsicoFilters && selectedPepsicoFilters.permutation) ||
      (data === 'SELL-OUT' && enableCompetitorFilter(selectedGeoFilters) && !selectedCompetitorFilters.permutation) ||
      !indexes.valueEnd ||
      !indexes.mainStreamEnd ||
      !indexes.mainStreamPlusEnd ||
      !indexes.premiumEnd ||
      !indexes.superPremiumEnd ||
      !indexes.megaPremiumStart;
    return flag;
  };
  const BrandLadderLoader = useSelector((state: any) => state.BrandLadderLoader);
  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator position='absolute' show={loader || BrandLadderLoader.brandLadderDataLoader} />
      <CardContent>
        <>
          <FilterAccordion title='Geo-Filters'>
            <GeoFiltersV2
              key='brand-ladder-top-filter'
              data={geoFilterV2Config}
              onChangeDate={(dt) => {
                clearGeoFilter(false);
              }}
              onSelectFilters={setSelectedGeoFilters}
              clearFilter={clearFilterData}
              apiPath='BrandLadder'
              showLoader={setLoader}
              defaultFilters={defaultFilters}
              showDatePicker={false}
            />
          </FilterAccordion>
          <FilterAccordion title='Additional Filters'>
            <Grid key={`measures-dropdown-container-2`}>
              <DropdownTitle> {filterMock.comparisonFilter.data.title}</DropdownTitle>
              <RadioButtonGroup
                key={`measures-filters-2`}
                color='#000'
                showButton={filterMock.comparisonFilter.data.showRadioButton}
                data={filterMock.comparisonFilter.data.options}
                defaultOption={data}
                select={setData}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} key={`measures-dropdown-container-1`}>                
            <DropdownTitle style={{ marginTop: '15px', marginBottom: '15px' }}>{filterMock.comparisonFilter.anchorProductComparision.title}</DropdownTitle>                
            <RadioButtonGroup
                  key={`measures-filters-1`}
                  color="#000"
                  showButton={filterMock.comparisonFilter.anchorProductComparision.showRadioButton}
                  data={filterMock.comparisonFilter.anchorProductComparision.options}
                  defaultOption={anchorProductComparision}
                  select={setAnchorProductComparision}
                />              
              </Grid> */}
            <Grid key={`measures-dropdown-container-3`}>
              <DropdownTitle style={{ marginTop: '15px', marginBottom: '15px' }}>{filterMock.comparisonFilter.comparisonLevel.title}</DropdownTitle>
              <RadioButtonGroup
                key={`measures-filters-3`}
                color='#000'
                showButton={true}
                data={
                  selectedGeoFilters.country === 'MEXICO'
                    ? filterMock.comparisonFilter.comparisonLevel.mexicoOptions
                    : filterMock.comparisonFilter.comparisonLevel.options
                }
                defaultOption={comparisonLevel}
                select={(data) => {
                  setComparisonLevel(data);
                  setTimeout(() => {
                    setPrevComparisonLevel(data);
                  }, 0);
                }}
              />
            </Grid>
            <Grid>
              <DropdownTitle style={{ marginTop: '15px', marginBottom: '15px' }}>
                {filterMock.comparisonFilter.productFilterTitle.title}
              </DropdownTitle>
              <ProductFiltersV3
                onChange={onChangeAnchorFilter}
                data={filterMock.anchorFilter}
                onChangeLoader={setLoader}
                selectedGeoFilters={selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}}
                defaultFilters={defaultAnchorFilters}
                clearFilter={clearFilterData}
                apiURL='filter/anchorproducts'
              />
            </Grid>
            <Grid container spacing={2} columns={Object.keys(productMockData).length + 1}>
              <Grid item xs={1}>
                {!selectedAnchorFilters.anchorSegment ? (
                  <>
                    <Skeleton height={22} /> <Skeleton variant='rectangular' height={45} />
                  </>
                ) : (
                  <>
                    <CustomDropDownTitle title={'Vendor <span style="color: #858c94;">(Pepsico)</span>'} />
                    <Dropdown
                      disabled={true}
                      data={[{ lable: 'PEPSICO', value: 'PEPSICO' }]}
                      defaultOption={['PEPSICO']}
                      placeholder='Select'
                      search={{ enable: false }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={Object.keys(productMockData).length}>
                <ProductFiltersV3
                  onChange={onChangePepsiFilter}
                  data={productMockData}
                  onChangeLoader={setLoader}
                  selectedGeoFilters={
                    comparisonLevel === prevComparisonLevel &&
                    selectedGeoFilters.country &&
                    selectedGeoFilters.geoLevel2 &&
                    selectedAnchorFilters &&
                    selectedAnchorFilters.anchorSegment
                      ? selectedGeoFilters
                      : {}
                  }
                  clearFilter={clearFilterData || clearPepsiCompetitorFilters}
                  defaultFilters={{
                    category: defaultPepsicoFilters.category,
                    segment: defaultPepsicoFilters.segment,
                    brand: defaultPepsicoFilters.brand,
                    subBrand: defaultPepsicoFilters.subBrand,
                    packSize: defaultPepsicoFilters.packSize,
                    permutation: defaultPepsicoFilters.permutation,
                  }}
                  params={{
                    isPepsi: true,
                    comparisonLevel,
                    country: selectedGeoFilters.country,
                    vendor: ['PEPSICO'],
                    anchor: {
                      category: selectedAnchorFilters.anchorCategory,
                      segment: selectedAnchorFilters.anchorSegment,
                    },
                  }}
                  apiURL='filter/BrandLadderProductFilter'
                  showSkeleton={!selectedAnchorFilters.anchorSegment}
                />
              </Grid>
            </Grid>
            <Grid>
              <ProductFiltersV3
                onChange={onChangeCompetitorFilter}
                data={compitetorMockData}
                onChangeLoader={setLoader}
                selectedGeoFilters={
                  Object.keys(selectedGeoFilters).length > 0 &&
                  enableCompetitorFilter(selectedGeoFilters) &&
                  comparisonLevel === prevComparisonLevel &&
                  selectedGeoFilters.country &&
                  selectedGeoFilters.geoLevel2 &&
                  selectedAnchorFilters &&
                  selectedAnchorFilters.anchorSegment &&
                  (defaultFilters.data || data === 'SELL-OUT')
                    ? selectedGeoFilters
                    : {}
                }
                clearFilter={clearFilterData || clearPepsiCompetitorFilters}
                defaultFilters={{
                  vendor: defaultCompetitorFilters.vendor,
                  category: defaultCompetitorFilters.category,
                  segment: defaultCompetitorFilters.segment,
                  brand: defaultCompetitorFilters.brand,
                  subBrand: defaultCompetitorFilters.subBrand,
                  packSize: defaultCompetitorFilters.packSize,
                  permutation: defaultCompetitorFilters.permutation,
                }}
                params={{
                  isPepsi: false,
                  comparisonLevel,
                  country: selectedGeoFilters.country,
                  anchor: {
                    category: selectedAnchorFilters.anchorCategory,
                    segment: selectedAnchorFilters.anchorSegment,
                  },
                }}
                apiURL={'filter/BrandLadderProductFilter'}
                showSkeleton={!selectedAnchorFilters.anchorSegment}
              />
            </Grid>
            <Grid>
              <b>Note:</b> Selecting more than 10 products for PEPSICO and 10 products for Competitors, will impact the dashboard performance and the
              quality of the visualization.
            </Grid>
          </FilterAccordion>
          <FilterAccordion title='Measure Filters'>
            <MeasuresFilters
              callback={setMeasureFilters}
              clearFilters={clearFilterData}
              selectedMeasuresFilters={selectedMeasureFilters}
              callbackIndexes={setIndexes}
              selectedGeoFilters={selectedGeoFilters}
              defaultSelection={savedIndexes}
              productFilter={selectedAnchorFilters}
            />
          </FilterAccordion>
          <Grid className='p-l-16'>
            <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
              Clear Filter
            </OrangeBtn>
            <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
              Apply Filter
            </PrimaryBtn>
          </Grid>
        </>
      </CardContent>
    </Card>
  );
};
export default TopFilters;
