import { Skeleton, Grid } from "@mui/material";
import React from "react";

import { DropdownTitle, SectionTitle } from "../AssortmentTool.Styled";

const renderContent = (type) => {
  switch (type) {
    case "GeoFilters":
      return (
        <>
          <Grid item style={{ marginLeft: 0 }}>
            <DropdownTitle>
              Channel
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              Region
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              Store Segment
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <></>
        </>
      );
    case "OverallFilters":
      return (
        <>
          <Grid item style={{ marginLeft: 0 }}>
            <DropdownTitle>
              Country
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              Date Time Period
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <DropdownTitle>
              Business Unit
              <span style={{ color: "red" }}>*</span>
            </DropdownTitle>
            <Skeleton
              variant="rectangular"
              className="m-r-20"
              width={130}
              height={30}
            />
          </Grid>
          <></>
        </>
      );
    case "PenetrationTarget":
      return (
        <>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item xs={2}>
              <SectionTitle color="black">
                Must have SKU<span style={{ color: "red" }}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <>
                <Skeleton
                  variant="rectangular"
                  height={35}
                  width="100%"
                  style={{ padding: "10px" }}
                />
              </>
            </Grid>
            <Grid flexDirection="row" item xs={2}>
              <SectionTitle color="black">
                Good to have SKU <span style={{ color: "red" }}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <>
                <Skeleton
                  variant="rectangular"
                  height={35}
                  width="100%"
                  style={{ padding: "10px" }}
                />
              </>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item xs={2}>
              <SectionTitle color="black">
                Current Average<span style={{ color: "red" }}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <>
                <Skeleton
                  variant="rectangular"
                  height={35}
                  width="100%"
                  style={{ padding: "10px" }}
                />
              </>
            </Grid>
            <Grid flexDirection="row" item xs={2}>
              <SectionTitle color="black">
                Current Average <span style={{ color: "red" }}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <>
                <Skeleton
                  variant="rectangular"
                  height={35}
                  width="100%"
                  style={{ padding: "10px" }}
                />
              </>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            marginBottom={"15px"}
            marginRight={"35px"}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <>
              <Grid item>
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={80}
                  style={{ padding: "10px" }}
                />
              </Grid>
              <Grid style={{ marginLeft: "10px" }} item>
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={80}
                  style={{ padding: "10px" }}
                />
              </Grid>
            </>
          </Grid>
        </>
      );
    case "Summary":
      return (
        <Grid container style={{ marginTop: "2px" }} spacing={2}>
          <Skeleton variant="rectangular" width="100%" height={125} />
        </Grid>
      );
    case "Coverage":
      return (
        <Grid container style={{ marginTop: "2px" }} spacing={2}>
          <Skeleton variant="rectangular" width="100%" height={125} />
        </Grid>
      );
    case "SizeOfPrice":
      return (
        <Grid container style={{ marginTop: "2px" }} spacing={2}>
          <Skeleton variant="rectangular" width="100%" height={125} />
        </Grid>
      );
    case "AssortmentListTable":
      return (
        <Grid container style={{ marginTop: "2px" }} spacing={2}>
          <Skeleton variant="rectangular" width="100%" height={125} />
        </Grid>
      );
    case "QuadrantFilters":
      return (
        <>
          <Grid item style={{ marginBottom: "25px", marginLeft: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                Add X-Axis
                <span style={{ color: "red" }}>*</span>
              </DropdownTitle>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={130}
                height={30}
              />
            </div>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                Add Y-Axis
                <span style={{ color: "red" }}>*</span>
              </DropdownTitle>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={130}
                height={30}
              />
            </div>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                Add Bubble Size
                <span style={{ color: "red" }}>*</span>
              </DropdownTitle>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={130}
                height={30}
              />
            </div>
          </Grid>
        </>
      );
      default:
      return <p>Default content.</p>;
  }
};
const Skeletons = ({ type }) => {
  return <>{renderContent(type)}</>;
};

export default Skeletons;
