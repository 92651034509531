const primaryFilter = {
  country: {
    title: "Country",
    options: [],
  },
  geoLevel: {
    title: "Geo-Level",
    options: [],
    placeholder: "Select",
    all: false,
    multiple: false,
  },
  region: {
    title: "Region",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  city: {
    title: "City",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  channel: {
    title: "Channel",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  subChannel: {
    title: "Sub Channel",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  keyAccounts: {
    title: "Key Accounts",
    options: [],
    placeholder: "Null",
    all: false,
    multiple: false,
  },
};

const productFilter = {
  category: {
    title: "Category",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    first: true,
    children: "segment",
  },
  segment: {
    title: "Segment",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "brand",
  },
  brand: {
    title: "Brand",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "subBrand",
  },
  subBrand: {
    title: "Sub Brand",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "packSize",
  },
  packSize: {
    title: "Pack Size",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    last: true,
  },
};

const anchorFilter = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    first: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "anchorSubBrand",
  },
  anchorSubBrand: {
    title: "Anchor Sub-brand",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    children: "anchorPackSize",
  },
  anchorPackSize: {
    title: "Anchor Pack-size",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
    last: true,
  },
};

const measureFilter = {
  variable1: {
    title: "Variable 1",
    options: [
      { id: "1", value: "SOM", disabled: false },
      { id: "2", value: "API", disabled: false },
      { id: "3", value: "Volume", disabled: false },
      { id: "4", value: "Revenue", disabled: false },
      { id: "5", value: "Units", disabled: false },
    ],
    defaultOption: { id: "1", value: "SOM" },
    showRadioButton: true,
  },
  variable2: {
    title: "Variable 2",
    options: [
      { id: "1", value: "SOM", disabled: false },
      { id: "2", value: "API", disabled: false },
      { id: "3", value: "Volume", disabled: false },
      { id: "4", value: "Revenue", disabled: false },
      { id: "5", value: "Units", disabled: false },
    ],
    defaultOption: { id: "4", value: "API" },
    showRadioButton: true,
  },
  SOMVarConfig: {
    title: "SOM Variable Configuration",
    options: [
      { id: "1", value: "Sales" },
      { id: "2", value: "Volume" },
      { id: "3", value: "Units" },
    ],
    defaultOption: { id: "1", value: "Sales" },
  },
  SOM: {
    title: "SOM/Ctg Sgmnt",
    options: [
      { id: "1", value: "Category" },
      { id: "2", value: "Segment" },
    ],
    defaultOption: { id: "1", value: "Category" },
    showRadioButton: true,
  },
  measure: {
    title: "Measure",
    options: [
      { id: "1", value: "KGs ( in '000s )" },
      { id: "2", value: "Units ( in '000s )" },
    ],
    defaultOption: { id: "1", value: "KGs ( in '000s )" },
  },
};

const correlationPepsiCoTable = [
  {
    title: "Date",
    values: [],
  },
  {
    title: "PepsiCo Pack Size",
    values: [],
  },
  {
    title: "PepsiCo Grammage",
    values: [],
  },
  {
    title: "P Grammage(%)",
    values: [],
  },
];

const correlationAnchorTable = [
  {
    title: "Date",
    values: [],
  },
  {
    title: "Anchor Pack Size",
    values: [],
  },
  {
    title: "Anchor Grammage",
    values: [],
  },
  {
    title: "A Grammage(%)",
    values: [],
  },
];

const APISimulation = [
  {
    title: "Sales Out",
    value: "0",
    simulated: "0",
    percentVar: 0,
    anchor: "0",
  },
  {
    title: "Volume Out",
    value: "0",
    simulated: "0",
    percentVar: 0,
    anchor: "0",
  },
  {
    title: "Units Out",
    value: "0",
    simulated: "0",
    percentVar: 0,
    anchor: "0",
  },
  {
    title: "Share Of Market",
    value: "0",
    simulated: "0",
    percentVar: 0,
    anchor: "0",
  },
];

const realESTVolumeTable = {
  heading: [
    { title: "API" },
    { title: "Real Volume" },
    { title: "EST Volume" },
  ],
  value: [],
};

const regressionStatsTable: any = {
  heading: [{ title: "Regression Statistics", colSpan: 2 }],
  value: [
    { statsName: "Multiple R", statsPer: 0 },
    {
      statsName: { bgcolor: null, color: null, value: "R Square" },
      statsPer: { bgcolor: null, color: null, value: 0 },
    },
    { statsName: "Adjusted R Square", statsPer: 0 },
    { statsName: "Standard Error", statsPer: 0 },
    { statsName: "Obervations", statsPer: 0 },
  ],
};

const coefficientTable = {
  heading: [
    { title: "" },
    { title: "Coefficients" },
    { title: "Standard Errors" },
    { title: "T-stat" },
    { title: "P-value" },
    { title: "Lower 95" },
    { title: "Upper 95" },
  ],
  value: [],
};

const nonCompetitorFilter = {
  variable1: {
    title: "Variable 1",
    options: [
      { id: "1", value: "API", disabled: false },
      { id: "2", value: "Volume", disabled: false },
      { id: "3", value: "Revenue", disabled: false },
      { id: "4", value: "Units", disabled: false },
    ],
    defaultOption: { id: "2", value: "Volume" },
    showRadioButton: true,
  },
  variable2: {
    title: "Variable 2",
    options: [
      { id: "1", value: "API", disabled: false },
      { id: "2", value: "Volume", disabled: false },
      { id: "3", value: "Revenue", disabled: false },
      { id: "4", value: "Units", disabled: false },
    ],
    defaultOption: { id: "1", value: "API" },
    showRadioButton: true,
  },
  measure: {
    title: "Measure",
    options: [
      { id: "1", value: "KGs ( in '000s )" },
      { id: "2", value: "Units ( in '000s )" },
    ],
    defaultOption: { id: "1", value: "KGs ( in '000s )" },
  },
};

const crossElasticityFilter = {
  title: "Cross Elasticity",
  options: [
    { id: "1", value: "Yes" },
    { id: "2", value: "No" },
  ],
  defaultOption: { id: "1", value: "Yes" },
  showRadioButton: true,
};

const monthRangeFilter = {
  title: "Select Range of Month",
  options: [
    { id: "1", value: "3 Months" },
    { id: "2", value: "6 Months" },
    { id: "3", value: "12 Months" },
  ],
  defaultOption: { id: "1", value: "3 Months" },
  showRadioButton: true,
};

const realESTVolumeIndexTable = {
  heading: [
    { title: "API" },
    { title: "Real Volume Index" },
    { title: "EST Volume Index" },
  ],
};

const exportObject = {
  primaryFilter,
  productFilter,
  anchorFilter,
  measureFilter,
  APISimulation,
  realESTVolumeTable,
  regressionStatsTable,
  coefficientTable,
  correlationPepsiCoTable,
  correlationAnchorTable,
  nonCompetitorFilter,
  crossElasticityFilter,
  monthRangeFilter,
  realESTVolumeIndexTable,
};

export default exportObject;
