import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, Skeleton, Box, Tooltip } from '@mui/material';
import Dashboard from '../../../../components/Dashboard';
import { Title } from '../../SupermarketDashboard.Styled';
import { saleEveryPointChartMock, saleEveryPointMock } from '../../../../mocks/supermarketMock';
import ColumnDrilldown from '../../../../components/charts/ColumnDrilldown';
import CustomTable from '../../../../components/CustomTable';
import { loader } from '../../../../store/actions/common.action';
import _ from 'lodash';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { separator } from '../../../../util/helper';
import HighchartContainer from '../../../../components/HighchartContainer';
import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ColumnDrilldownNewConfig } from '../../../../components/charts/ColumnDrilldown/ColumnDrilldownConfig';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const SalesPricePoint: React.FC<{ pepsicoBaseline; skeleton }> = ({ pepsicoBaseline, skeleton = true }) => {
  // const { heading, value } = saleEveryPoint;
  const [saleEveryPoint, setSaleEveryPoint] = useState({ ...saleEveryPointMock });
  const [saleEveryPointChart, setSaleEveryPointChart] = useState({ ...saleEveryPointChartMock });
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const formatSalesPricePoint = (response) => {
    if (_.isArray(response.saleEveryPoint)) {
      saleEveryPointChart.xAxisData = response.saleEveryPoint.map((item) => {
        return item.pricePoint;
      });
      saleEveryPointChart.areaData = response.saleEveryPoint.map((item) => {
        return item.unitSale;
      });
      saleEveryPointChart.columnData = response.saleEveryPoint.map((item) => {
        return item.amountWeek;
      });
      saleEveryPoint.value = response.saleEveryPoint.map((item) => {
        return {
          pricePoint: separator(item.pricePoint.toFixed(2)),
          unitSale: separator(item.unitSale.toFixed(2)),
          amountWeek: separator(item.amountWeek.toFixed(2)),
        };
      });
      setSaleEveryPointChart({ ...saleEveryPointChart });
      setTimeout(() => {
        setSaleEveryPoint({ ...saleEveryPoint });
      }, 0);
    }
  };

  useEffect(() => {
    if (pepsicoBaseline && pepsicoBaseline.data) {
      dispatch(loader(false));
      formatSalesPricePoint(pepsicoBaseline.data);
    } else {
      saleEveryPointChart.xAxisData = [];
      saleEveryPointChart.areaData = [];
      saleEveryPointChart.columnData = [];
      saleEveryPoint.value = [];
      setSaleEveryPointChart({ ...saleEveryPointChart });
      setSaleEveryPoint({ ...saleEveryPoint });
      dispatch(loader(false));
    }
  }, [pepsicoBaseline]);

  return (
    <Grid className='m-b-20'>
      <Dashboard
        title='Sales at Every Price Point($)'
        chartRef={chartRef}
        themeTitle
        id='supermarket-sales-at-every-price-point-1'
        showSkeleton={skeleton}
      >
        <Card>
          <CardContent>
            {!skeleton && (
              <Box className='m-b-10'>
                {_.isArray(saleEveryPointChart.xAxisData) && saleEveryPointChart.xAxisData.length > 0 ? (
                  <HighchartContainer chartRef={chartRef} id='supermarket-sales-at-every-price-point-1'>
                    <ColumnDrilldown data={saleEveryPointChart} chartRef={chartRef} />
                  </HighchartContainer>
                ) : (
                  <Grid container display='flex' justifyContent='center' alignItems='center' height={400}>
                    <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
                  </Grid>
                )}
              </Box>
            )}
            {skeleton && <Skeleton variant='rectangular' height={400} className='m-b-10' />}
            {skeleton ? (
              <>
                <Skeleton variant='rectangular' width={400} height={30} className='m-b-20 m-t-10' />
                <CustomTable heading={saleEveryPoint.heading} value={saleEveryPoint.value} showSkeleton={skeleton} />
              </>
            ) : (
              _.isArray(saleEveryPoint.value) &&
              saleEveryPoint.value.length > 0 && (
                <Tooltip title={'Total Unit Sales by Price Point($)'}>
                  <Title className='m-b-20 ellipsis'>Total Unit Sales by Price Point($)</Title>
                </Tooltip>
              )
            )}
            {_.isArray(saleEveryPoint.value) && saleEveryPoint.value.length > 0 && (
              <CustomTable heading={saleEveryPoint.heading} value={saleEveryPoint.value} showSkeleton={skeleton} ellipsis={true} />
            )}
          </CardContent>
        </Card>
      </Dashboard>
    </Grid>
  );
};

export default SalesPricePoint;
