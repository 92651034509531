import * as React from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Skeleton,
} from "@mui/material";
import _ from "lodash";

const CheckBoxGroup: React.FC<{
  data;
  color?;
  direction?;
  select?;
  disabled?;
  single?: boolean;
  defaultOption?;
  showSkeleton?: boolean;
}> = ({
  data,
  color,
  direction,
  disabled = false,
  select,
  single = false,
  defaultOption,
  showSkeleton,
}) => {
  const [selectedVal, setSelectedVal] = React.useState([]);
  const handleChange = (event, index) => {
    let values;
    if (single) {
      if (event.target.checked) {
        values = event.target.value;
        setSelectedVal([values]);
        select(values);
      } else {
        setSelectedVal([]);
        select(null);
      }
    } else {
      if (event.target.checked) {
        values = [...selectedVal, event.target.value];
      } else {
        values = selectedVal.filter((x) => x !== event.target.value);
      }
      setSelectedVal(values);
      select(values);
    }
  };
  React.useEffect(() => {
    if (defaultOption) {
      setSelectedVal(single ? [defaultOption] : defaultOption);
    } else {
      setSelectedVal([]);
    }
  }, [defaultOption]);

  return (
    <FormControl component="fieldset" disabled={disabled}>
      {!showSkeleton ? (
        <>
          <FormGroup row={direction === "row"}>
            {data.map((item: { id: string; value: string }, index) => (
              <FormControlLabel
                key={`checkbox-group-skeleton-${index}`}
                control={
                  <Checkbox
                    checked={
                      (_.isArray(selectedVal) &&
                        selectedVal.findIndex((x) => x === item.value) !==
                          -1) ||
                      false
                    }
                    name={index}
                    sx={{
                      color: "#25d4cf",
                      "&.Mui-checked": {
                        color: "#25d4cf",
                      },
                      "&.Mui-disabled": {
                        color: "darkgrey",
                      },
                    }}
                    onChange={(e) => handleChange(e, index)}
                    value={item.value}
                  />
                }
                label={
                  <span style={color && { color, fontSize: 14 }}>
                    {item.value}
                  </span>
                }
              />
            ))}
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup row={direction === "row"}>
            {data.map(() => (
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    sx={{
                      color: "#25d4cf",
                      "&.Mui-checked": {
                        color: "#25d4cf",
                      },
                      "&.Mui-disabled": {
                        color: "darkgrey",
                      },
                    }}
                  />
                }
                label={<Skeleton width={60} />}
              />
            ))}
          </FormGroup>
        </>
      )}
    </FormControl>
  );
};

export default CheckBoxGroup;
