import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
} from "./constants";
import { API } from "../../../util/helper";

export const getNotification = () => {
  return {
    type: GET_NOTIFICATION,
  };
};

export const getNotificationSuccess = (payload: any) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const getNotificationFailure = (error) => {
  return {
    type: GET_NOTIFICATION_FAILURE,
    payload: error,
  };
};

const getNotificationData = (pageKey?, filter?) => {
  let url = `${process.env.REACT_APP_BACKEND_BASE_URL}notifications`;
  if(pageKey){
    url += `/${pageKey}`
  }else{
    url +=`?filter=${filter? filter : 3}`
  }
  return API.get(url);
};

export function fetchNotifications(pageKey?, filter?) {
  return (dispatch: any) => {
      dispatch(getNotification());
      getNotificationData(pageKey, filter)
        .then((response) => {
          dispatch(getNotificationSuccess(response.data));
        })
        .catch((error) => {
          dispatch(getNotificationFailure(error));
        });
  };
}
