import { getFormData } from "../../../pages/MixSimulation/MixHelper";
import { API } from "../../../util/helper";
import { fetchApi } from "../ExecutionTool/portfolioGeoLevel.action";
import { BACKEND_URL, FAILURE_MIX_SIMULATION_DECISION_VARIABLE, FAILURE_MIX_SIMULATION_SAVE_SCENARIO, FETCH_MIX_SIMULATION_DECISION_VARIABLE, REQUEST_MIX_SIMULATION_SAVE_SCENARIO, RESET_MIX_USER_INPUTS,  SET_ALL_MIX_USERINPUT_DETAILS, SET_MIX_SCENARIO_NAME, SET_MIX_SIMULATION_DECISION_VARIABLE, SET_MIX_SIMULATION_GOAL, SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID, SET_MIX_SIMULATION_UID } from "../constants";
import { fetchMixSimulationConstraintTableData, fetchMixSimulationConstraintTableFilter, loaderForMixScreen } from "./mixSimulationConstraintTable.action";

export const setMixSimulationGoal = (payload) => ({
    type: SET_MIX_SIMULATION_GOAL,
    payload
});
export const setMixSimulationUID = (payload) => ({
    type: SET_MIX_SIMULATION_UID,
    payload
});
export const setMixSimulationScenarioName = (payload) => ({
    type: SET_MIX_SCENARIO_NAME,
    payload
});
export const resetMixUserInputs = () => ({
    type: RESET_MIX_USER_INPUTS,
});
export const setMixUserInputDetails = (payload) => ({
    type: SET_ALL_MIX_USERINPUT_DETAILS,
    payload
});

export const setMixSimulationAssortmentScenarioUid = (payload) => ({
    type: SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID,
    payload
});

export const requestMixSimulationSaveScenario = () => ({
    type: REQUEST_MIX_SIMULATION_SAVE_SCENARIO,
});
export const failureMixSimulationSaveScenario = (payload) => ({
    type: FAILURE_MIX_SIMULATION_SAVE_SCENARIO,
    payload
});
export const fetchMixSimulationDecisionVariable = () => ({
    type: FETCH_MIX_SIMULATION_DECISION_VARIABLE,
});
export const setMixSimulationDecisionVariable = (payload) => ({
    type: SET_MIX_SIMULATION_DECISION_VARIABLE,
    payload
});
export const failureMixSimulationDecisionVariable = (payload) => ({
    type: FAILURE_MIX_SIMULATION_DECISION_VARIABLE,
    payload
});


export const getMixDecisionVariables = (payload):any => {
    return async (dispatch) => {
        dispatch(fetchMixSimulationDecisionVariable());
        try {
            const response = await API.post(`${BACKEND_URL}/api/filter/GetAssortmentScenario`, payload);
            // dispatch(setMixSimulationDecisionVariable(response))
            return response;
        } catch (error) {
            dispatch(failureMixSimulationDecisionVariable(error.message));
        }
    };
}


export const saveMixSimulationScenario = (data, apiUrl, capacityConstraintData, operativeComplexityData):any => {
    return async (dispatch) => {
        dispatch(requestMixSimulationSaveScenario());
        try {
            console.log("saveMixSimulationScenario",data)
            const response = await API.post(`${BACKEND_URL}/api/scenario/mix/${apiUrl}`, data);
            dispatch(setMixSimulationUID(response));
             const uid = response.data.uid;
            // console.log("responseSaveSim", response)
            // let capFormData = getFormData(uid, capacityConstraintData.file);
            // let opFormData = getFormData(uid, operativeComplexityData.file);
            // let tableDataPayload ={
            //     goal:data.goal,
            //     uid:uid,
            //     filtersSelected: false,
            //     channel:"",
            //     region:"",
            //     storeSegment:""
            // }
            // let userInputCallsAfterSave = [
            //     // dispatch(fetchMixSimulationConstraintTableFilter({default:true,uid:uid})),
            //     // dispatch(fetchMixSimulationConstraintTableData(tableDataPayload,'getMixContraintTableData')),
            // ]
            // Promise.all(userInputCallsAfterSave).then(res => {
            //     dispatch(loaderForMixScreen(false));
            // }).catch(err => {
            //     dispatch(loaderForMixScreen(false));
            // })
            dispatch(loaderForMixScreen(false));

            return response

        } catch (error) {
            dispatch(failureMixSimulationSaveScenario(error.message));
            dispatch(loaderForMixScreen(false));
        }
    };
};