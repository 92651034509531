import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Skeleton } from "@mui/material";
import Dashboard from "../../../../../../components/Dashboard";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { lineChartConfig } from "./lineChartConfig";
// import { dataFormat } from "../../../../../../util/helper";
import _ from "lodash";
import { NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import { DropdownTitle } from "../../../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../../../components/UI-components/RadioButtonGroup";
import mockData from "../../../../../../mocks/historyPriceMock";
import HighchartContainer from "../../../../../../components/HighchartContainer";
import { plotElementsOnChart } from "../../../../../../util/helper";
import { useDispatch } from "react-redux";
import { removeLabels } from "../../../../../../store/actions/common.action";


const ModelActualVsPredicted: React.FC<{
  data;
  skeleton;
}> = ({ data, skeleton }) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();


  const [y2DataPoints, setY2DataPoints] = useState(
    mockData.modelActualVSPredicted.defaultOption.value
  );


  useEffect(() => {
    if (data.predicted && data.actual) {
      const dates = data.predicted.map((item) => item.date);
      // const xAxisData: string | string[] = dataFormat(dates, "MMM-yyyy");
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.xAxis[0].setCategories(dates);
        chart.yAxis[0].setTitle({ text: data.yAxisTitle });
        chart.series[0].setData(data.basicUnits.map((item) => item.value))
        chart.series[1].setData(data.predicted.map((item) => item.value));
        chart.series[2].setData(data.actual.map((item) => item.value));
        chart.series[3].setData(
          data.y2DataPoints.map((item) =>
            y2DataPoints ? item[y2DataPoints.toLocaleLowerCase()] : item.ppu
          )
        );
        chart.series[4].setData(
          data.y1DataPoints.map((item) =>
            y2DataPoints === "PPU" ? item.edrp : y2DataPoints === "VPU" ? item.baselinePpv : item.edrp
          )
        );
        chart.update({
          title: {
            style: {
              fontSize: 14,
              fontWeight: 600,
            },
            align: "left",
            verticalAlign: "bottom",
            text:
              "This visual is using " + data.chartTitle + " price elasticity",
          },
        });
        let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
        if (
          dashbaordData &&
          dashbaordData["historic-price-track-model-actual-predicted"]
        ) {
          plotElementsOnChart(
            dashbaordData["historic-price-track-model-actual-predicted"],
            chart,
            "historic-price-track-model-actual-predicted"
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      switch (y2DataPoints) {
        case "PPU":
          chart.series[3].setData(data.y2DataPoints.map((item) => item.ppu));
          chart.series[3].setName("PPU");
          chart.series[4].setData(data.y1DataPoints.map((item) => item.edrp));
          chart.series[4].setName("EDRP");
          chart.series[4].update({ color: "violet" });
          break;
        case "PPV":
          chart.series[3].setData(data.y2DataPoints.map((item) => item.ppv));
          chart.series[3].setName("PPV");
          chart.series[4].setData(data.y1DataPoints.map((item) => item.baselinePpv));
          chart.series[4].setName("Baseline PPV");
          chart.series[4].update({ color: "violet" });
          break;
        case "VPU":
          chart.series[3].setData(data.y2DataPoints.map((item) => item.vpu));
          chart.series[3].setName("VPU");
          chart.series[4].setData([]);
          chart.series[4].setName('')
          chart.series[4].update({ color: "white" });
          break;
        default:
          break;
      }
      chart.redraw();
      dispatch(removeLabels("historic-price-track-model-actual-predicted"))
    }
  }, [y2DataPoints]);

  return (
    <Grid>
      <Dashboard
        showSkeleton={skeleton}
        id="historic-price-track-model-actual-predicted"
        title={`Model Actual VS Predicted ${data && data?.title ? data?.title : ""
          }`}
        chartRef={chartRef}
        showActionButton={
          _.isArray(data.predicted) &&
          data.predicted.length > 0 &&
          _.isArray(data.actual) &&
          data.actual.length > 0
        }
      >
        {!skeleton ? (
          _.isArray(data.predicted) &&
            data.predicted.length &&
            _.isArray(data.actual) &&
            data.actual.length ? (
            <Box>
              <Grid
                item
                xs={12}
                sm={12}
                key={`y2DataPoints`}
                textAlign={"center"}
              >
                <DropdownTitle>
                  {mockData.modelActualVSPredicted.title}
                </DropdownTitle>
                <RadioButtonGroup
                  key={`y2DataPoints`}
                  color="#000"
                  showButton={mockData.modelActualVSPredicted.showRadioButton}
                  data={mockData.modelActualVSPredicted.options}
                  defaultOption={y2DataPoints}
                  select={(data) => setY2DataPoints(data)}
                  direction={"row"}
                />
              </Grid>
              <Grid style={{ marginTop: 20, width: "100%" }}>
                <HighchartContainer
                  chartRef={chartRef}
                  id={"historic-price-track-model-actual-predicted"}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={lineChartConfig}
                    ref={chartRef}
                  />
                </HighchartContainer>
              </Grid>
            </Box>
          ) : (
            <Box
              style={{ height: 400 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <NoData>{messages.noData}</NoData>
            </Box>
          )
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Skeleton variant="rectangular" height={500} className="m-b-10" />
            </Grid>
          </Grid>
        )}
      </Dashboard>
    </Grid>
  );
};

export default ModelActualVsPredicted;
