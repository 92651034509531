import { Box, Grid, Skeleton, TableBody, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { NoData, StyledTableRow } from '../../../../../../styles/Common.Styled';
import { messages } from '../../../../../../util/config';
import mockData from '../../../../../../mocks/elasticityPricingTrack';
import { getMeasureFilterData } from '../../../../service';
import { StyledTable, StyledTableCell } from './AdjustableMeasureFilters.Styled';
import RadioButtonGroup from '../../../../../../components/UI-components/RadioButtonGroup';
import { rendarImage } from '../../../../../../util/helper';
const AdjustableMeasureFilters: React.FC<{
  filters?;
  callback;
  defaultFilters;
  clearFilter;
  showLoader;
}> = ({ filters, callback, defaultFilters, clearFilter, showLoader }) => {
  const [tableMainHeading, setTableMainHeading] = React.useState([]);
  const [tableValues, setTableValues] = React.useState([1]);
  const [tableSkeleton, setTableSkeleton] = React.useState(true);
  const [tempFilters, setTempFilters] = React.useState({});
  const [priceUpdateDate, setPriceUpdateDate] = React.useState('');
  const [originalResponse, setOriginalResponse] = React.useState([]);

  React.useEffect(() => {
    if (clearFilter) {
      setTableValues([]);
      setOriginalResponse([]);
      setTableSkeleton(true);
      setTempFilters({});
      setPriceUpdateDate('');
    }
  }, [clearFilter]);

  const getTableData = async (payload) => {
    showLoader(true);
    getMeasureFilterData(payload)
      .then((response) => {
        if (_.isArray(response.data) && response.data.length > 0) {
          setOriginalResponse(response.data);
        } else {
          setTableSkeleton(false);
          showLoader(false);
        }
      })
      .catch(() => {
        setTableSkeleton(false);
        showLoader(false);
      });
  };

  useEffect(() => {
    if (originalResponse && originalResponse.length > 0) {
      formatData(originalResponse);
    }
  }, [originalResponse]);

  const renderControls = (cellData, row, column, columnName) => {
    if (cellData.type === 'Text') {
      return (
        <TextField InputProps={{ autoComplete: 'off' }}
          id='proposed-price'
          type='number'
          size='small'
          inputProps={{ step: 0.01 }}
          value={_.isNumber(cellData.value) ? cellData.value.toFixed(2) : cellData.value}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              calculateValues(tableValues);
            }
          }}
          onChange={(e) => {
            const { value } = e.target;
            if (value.match(/\./g)) {
              const [, decimal] = value.split('.');
              if (decimal?.length > 2) {
                return;
              }
            }
            const newData = [...tableValues];
            newData[row][columnName].value = value;
            setTableValues(newData);
            calculateValues(newData);
          }}
          style={{ width: cellData.width ? cellData.width : 90 }}
        />
      );
    } else if (cellData.type === 'Radio') {
      return (
        <RadioButtonGroup
          key={`table-radio-options-${row}`}
          color='#000'
          showButton={true}
          data={cellData.options}
          defaultOption={cellData.value ? cellData.value : cellData.defaultOption.value}
          select={(e) => {
            const newData = [...tableValues];
            newData[row][columnName].value = e;
            setTableValues(newData);
            formatValues(newData);
          }}
        />
      );
    }
  };

  const calculateValues = (tableValues, pricingDate?) => {
    const newData = [...tableValues];
    _.isArray(newData) &&
      newData.map((item) => {
        {
          item.pricePerUnitFrom.value == 0
            ? (item.pricePerUnitVar.value = 0)
            : (item.pricePerUnitVar.value = (item.pricePerUnitTo.value / item.pricePerUnitFrom.value - 1) * 100);
        }
        {
          item.gramPerUnitFrom.value == 0
            ? (item.gramPerUnitVar.value = 0)
            : (item.gramPerUnitVar.value = (item.gramPerUnitTo.value / item.gramPerUnitFrom.value - 1) * 100);
        }
      });
    setTableValues(newData);
    formatValues(newData, pricingDate);
    setTableSkeleton(false);
    showLoader(false);
  };

  const formatValues = (tableValues, date?) => {
    const rows = [];
    tableValues.map((item) => {
      let row = {};
      Object.entries(item).map(([key, val]) => {
        row = {
          ...row,
          [key]: val.editable && val.type === 'Text' ? parseFloat(val.value) : val.value,
          date: date,
        };
      });
      rows.push(row);
    });
    const payload = [];
    originalResponse.map((item, i) => {
      const index = rows.findIndex((x) => x.productInfo === item.productInfo);
      delete item.image;
      if (index !== -1) {
        payload.push({ ...item, ...rows[index] });
      }
    });
    callback({ tableData: payload, date: date ? date : priceUpdateDate });
  };

  const fetchImage = (payload, name) => {
    if (payload.src) {
      return (
        <Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
          <div>
            <img src={payload.src} height={payload.height} width={payload.width} alt={payload.alt} />
            {payload.tooltip ? <div style={{ color: '#929292' }}>{payload.tooltip}</div> : null}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
          <Box className='img-error-box'>
            <span>{name}</span>
            {payload.tooltip ? <div style={{ color: '#929292' }}>{payload.tooltip}</div> : null}
          </Box>
        </Tooltip>
      );
    }
  };

  const formatData = (data) => {
    if (_.isArray(data) && data.length > 0) {
      const rows: any = [];
      Promise.all(
        data.map(async (item) => {
          item.image = {
            src: await rendarImage(item.subBrandName, filters.country),
            alt: item.subBrandName,
            height: 100,
            width: 100,
            tooltip: item.displayName,
          };
        })
      )
        .then(() => {
          let pricingDate;
          data = _.orderBy(data, 'productInfo');
          data.map((item, i) => {
            let row = {};
            if (i === 0) {
              pricingDate = item.date;
              setPriceUpdateDate(item.date);
            }
            Object.entries(mockData.measureTable1SubHeadings).map(([key, config]) => {
              row = {
                ...row,
                [key]: {
                  ...config,
                  value: getValue(item, key),
                  ...(config?.image && item.image ? { image: item.image } : {}),
                  key,
                },
              };
            });
            rows.push(row);
          });
          setTableSkeleton(false);
          calculateValues(rows, pricingDate);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getValue = (item, key) => {
    if (_.isArray(defaultFilters) && defaultFilters.length > 0) {
      const index = defaultFilters.findIndex((x) => x.productInfo === item.productInfo);

      if (index !== -1) {
        return defaultFilters[index][key];
      }
    } else return item[key];
  };

  React.useEffect(() => {
    if (filters && filters.country && JSON.stringify(filters) !== JSON.stringify(tempFilters)) {
      setTableSkeleton(true);
      setTempFilters({ ...filters });
      setTableValues([]);
      setOriginalResponse([]);
      if (filters.dataSource && filters.category && filters.packSize && filters.date) {
        getTableData({ ...filters });
      }
      if (filters.dataSource === 'sellOut') {
        const tableHeading = _.cloneDeep(mockData.measureTable1Headings);
        tableHeading[1].label = 'EDRP';
        setTableMainHeading([...tableHeading]);
      } else {
        setTableMainHeading([...mockData.measureTable1Headings]);
      }
    }
  }, [filters]);

  return (
    <TableContainer
      sx={{
        maxHeight: 600,
        maxWidth: '87vw',
        '&::-webkit-scrollbar': {
          height: 8,
        },
      }}
    >
      <StyledTable stickyHeader>
        {!tableSkeleton ? (
          <>
            {tableValues.length > 0 ? (
              <>
                <TableHead style={{ position: 'sticky', top: 0, zIndex: 3 }}>
                  <TableRow>
                    {tableMainHeading.map((item) => (
                      <StyledTableCell
                        className='b-color'
                        align='center'
                        style={{
                          color: 'white',
                          backgroundColor: '#25d4cf',
                          width: item.width ? item.width : null,
                        }}
                        colSpan={item.colspan ? item.colspan : null}
                        rowSpan={item.rowspan ? item.rowspan : null}
                      >
                        <div style={{ width: item.width ? item.width : null }}>
                          <Tooltip title={item.label}>
                            <Grid
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {item.label}
                            </Grid>
                          </Tooltip>
                        </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {Object.keys(mockData.measureTable1SubHeadings).map((key) => {
                      const item = mockData.measureTable1SubHeadings[key];
                      return item.label ? (
                        <StyledTableCell
                          align='center'
                          data-testid='tableHeader'
                          style={{
                            backgroundColor: '#0093CD',
                            color: 'white',
                            whiteSpace: 'nowrap',
                            width: item.width ? item.width : null,
                          }}
                        >
                          <div style={{ width: item.width ? item.width : null }}>
                            <Tooltip title={item.label}>
                              <Grid
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <span className='ellipsis'>{item.label}</span>
                              </Grid>
                            </Tooltip>
                          </div>
                        </StyledTableCell>
                      ) : null;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableValues.map((row, i) => (
                    <StyledTableRow key={i} data-testid='tableBodyRows'>
                      {Object.keys(row).map((key, j) => {
                        let cellData = row[key];
                        return cellData.type !== 'none' ? (
                          <StyledTableCell align='center' rowSpan={cellData?.rowspan ? cellData.rowspan : null}>
                            {_.isObject(cellData)
                              ? cellData.editable
                                ? renderControls(cellData, i, j, key)
                                : cellData.image
                                ? fetchImage(cellData.image, cellData.name)
                                : `${cellData.value.toFixed(2)}${cellData.prepend ? cellData.prepend : ''}`
                              : cellData}
                          </StyledTableCell>
                        ) : null;
                      })}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                <NoData>{messages.noData}</NoData>
              </Box>
            )}
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                {tableMainHeading.map((item) => (
                  <StyledTableCell align='center' colSpan={item.colspan}>
                    <Skeleton />
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[1, 2, 3, 4, 5].map((row, i) => (
                <TableRow key={i}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(() => (
                    <StyledTableCell align='center'>
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </StyledTable>
    </TableContainer>
  );
};
export default AdjustableMeasureFilters;
