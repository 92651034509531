import React, { useState } from "react";
import Button from "@mui/material/Button";
import { GridContainer, GridItem, StyledTypography, StyledBox } from "./MixScenario.Styled";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMixSimulationScenarioName } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import { setOutputScreenToDisplay } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { MIX_SIMULATION_SCENARIO_LIBRARY } from "../../../../router/CONSTANTS";
import { useHistory } from "react-router-dom";

const MixScenarioCreation = () => {
  const  scenarioNameState  = useSelector((state: any) => state.mixSimulationUserInput.scenarioName);
  const [scenarioName, setScenarioName] = useState(scenarioNameState);
  const dispatch = useDispatch();
  const { location, push } = useHistory();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value;
    dispatch(setMixSimulationScenarioName(name));
    setScenarioName(name);
  };

  const { outputScreen,isEditMode } = useSelector((state: any) => state.mixSimulationAllFilter);
  

  const handleBackToScenario = () => {
    dispatch(setOutputScreenToDisplay(false));
     push(MIX_SIMULATION_SCENARIO_LIBRARY);
    
  };

  return (
    <GridContainer
      style={{ padding: 5, display: "flex", justifyContent: "space-between", alignContent: "space-between" }}
    >
      <GridItem
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledTypography
          style={{
            paddingTop: 10,
            paddingRight: 10,
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Scenario Name:
        </StyledTypography>
        <StyledBox className="form-group">
          <TextField
            InputProps={{ autoComplete: "off" }}
            size="small"
            disabled={outputScreen||isEditMode}
            name="scenarioName"
            style={{ width: "400px", height: "35px" }}
            placeholder="Enter a scenario name..."
            onChange={handleInputChange}
            value={scenarioName}
          />
        </StyledBox>
      </GridItem>
       
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleBackToScenario}
            style={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "16px",
              padding: "10px 20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#25d4cf",
              backgroundColor: "#FFFFFF",
            }}
          >
            Back To Scenario Library
          </Button>
        </div>
      
    </GridContainer>
  );
};

export default MixScenarioCreation;
