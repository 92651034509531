import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import Indicator from "../../../../components/Loader/Loader";
import FilterAccordion from "../../../../components/Filters/Filters";
import { pascaleCase } from "../../../../util/helper";
import mockData from "../../../../mocks/productXREFMock";
import { fetchProdXrefColumns } from "../../service";

import ProductFiltersV3 from "../../../../components/ProductFilters-v3";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import MeasureFilters from "../MeasureFilters";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import Dropdown from "../../../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { prodxrefDataLoader } from "../../store/ProdXref.action";
import _ from "lodash";

const defaultColumns = ["raw_name", "source", "uniqueid"];
const TopFilters: React.FC<{ callback; defaultFilters? }> = ({
  callback,
  defaultFilters,
}) => {
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(
    defaultFilters ? defaultFilters : { country: null }
  );

  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [columnHeadings, setColumnHeadings] = useState<any[]>([]);
  const dispatch = useDispatch();

  const [selectedAnchorFilters, setSelectedAnchorFilters] = React.useState<any>(
    {}
  );

  const ProdXrefDataLoader = useSelector(
    (state: any) => state.ProdXrefDataLoader
  );

  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
    }
  }, [selectedGeoFilters.country]);

  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...selectedGeoFilters, country: null });
    setSelectedAnchorFilters({});
    setColumnsData([]);
  };

  const onApplyFilter = async () => {
    dispatch(prodxrefDataLoader(true));
    callback({
      filters: {
        ...selectedGeoFilters,
        ...selectedAnchorFilters,
        columnNames: columnHeadings.length>0 ? [...defaultColumns, ..._.sortBy(columnHeadings)] : defaultColumns,
      },
    });
  };

  const disableApplyFilter = () => {
    const flag =
      !selectedGeoFilters.country ||
      !selectedAnchorFilters.anchorPackSize 
    return flag;
  };

  useEffect(() => {
    getColumnsData();
  }, []);

  const getColumnsData = () => {
    if (selectedGeoFilters) {
      fetchProdXrefColumns().then((response) => {
        if (response && response.data) {
          const responseData = response.data.map((item) => {
            const disable = defaultColumns.findIndex((x) => x === item.name) !== -1;
            return { label: pascaleCase(item.key, '_'), value: item.name, disable };
          }).filter(x=> !x.disable);
          setColumnsData(_.sortBy(responseData, 'label'));
        }
      });
    }
  };

  return (
    <Card style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || ProdXrefDataLoader.prodxrefDataLoader}
      />
      <CardContent>
        <FilterAccordion title="Geo Filters">
          <Grid container spacing={2} columns={17}>
            <Grid item xs={12} md={2} sm={2} lg={2}>
              <DropdownTitle>Country</DropdownTitle>
              <Dropdown
                disabled={false}
                data={mockData.countries}
                onChange={(data) =>
                  setSelectedGeoFilters({
                    ...selectedGeoFilters,
                    country: data[0],
                  })
                }
                defaultOption={[selectedGeoFilters.country] || ""}
              />
            </Grid>
          </Grid>
        </FilterAccordion>
        <FilterAccordion title="Product Filters">
          <ProductFiltersV3
            onChange={setSelectedAnchorFilters}
            data={mockData.anchorFilters}
            onChangeLoader={setLoader}
            selectedGeoFilters={
              selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}
            }
            clearFilter={clearFilterData}
            defaultFilters={defaultFilters.competitor}
            apiURL="filter/anchorproducts"
          />
        </FilterAccordion>
        <FilterAccordion title="Measure Filters">
          <MeasureFilters
            data={columnsData}
            callback={setColumnHeadings}
          />
        </FilterAccordion>
        <Grid className="p-l-16">
          <OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={disableApplyFilter()}
            color="primary"
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
