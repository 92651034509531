export const CHANGE_THEME = "change_theme";
export const OPEN_SIDE_MENU = "open_side_menu";
export const LOADER = "open_close_loader";
export const TOAST = "toast";
export const ADDREMOVELABEL = "add_remove_label";
export const REMOVELABEL = "remove_label";
export const REMOVESERIESLABEL = "remove_series_label";

//Filters

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCESS = "GET_COUNTRIES_SUCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_BUSINESS_UNIT = "GET_BUSINESS_UNIT";
export const GET_BUSINESS_UNIT_SUCESS = "GET_BUSINESS_UNIT_SUCESS";
export const GET_BUSINESS_UNIT_FAILURE = "GET_BUSINESS_UNIT_FAILURE";

export const GET_GEO_FILTERS = "GET_GEO_FILTERS";
export const GET_GEO_FILTERS_SUCESS = "GET_GEO_FILTERS_SUCESS";
export const GET_GEO_FILTERS_FAILURE = "GET_GEO_FILTERS_FAILURE";
export const CLEAR_GEO_FILTERS = "CLEAR_GEO_FILTERS";

export const GET_PRODUCT_FILTERS = "GET_PRODUCT_FILTERS";
export const GET_PRODUCT_FILTERS_SUCESS = "GET_PRODUCT_FILTERS_SUCESS";
export const GET_PRODUCT_FILTERS_FAILURE = "GET_PRODUCT_FILTERS_FAILURE";
export const ON_CHANGE_PRODUCT_OPTION = "ON_CHANGE_PRODUCT_OPTION";
export const CLEAR_PRODUCT_FILTERS = "CLEAR_PRODUCT_FILTERS";

//Assortment Filters
export const FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST =
  "FETCH_ASSORTMENT_PRODUCT_FILTERS_REQUEST";
export const FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS =
  "FETCH_ASSORTMENT_PRODUCT_FILTERS_SUCCESS";
export const FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE =
  "FETCH_ASSORTMENT_PRODUCT_FILTERS_FAILURE";

export const FETCH_ASSORTMENT_GEO_FILTERS_REQUEST =
  "FETCH_ASSORTMENT_GEO_FILTERS_REQUEST";
export const FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS =
  "FETCH_ASSORTMENT_GEO_FILTERS_SUCCESS";
export const FETCH_ASSORTMENT_GEO_FILTERS_FAILURE =
  "FETCH_ASSORTMENT_GEO_FILTERS_FAILURE";

export const FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST =
  "FETCH_ASSORTMENT_QUADRANT_FILTERS_REQUEST";
export const FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS =
  "FETCH_ASSORTMENT_QUADRANT_FILTERS_SUCCESS";
export const FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE =
  "FETCH_ASSORTMENT_QUADRANT_FILTERS_FAILURE";
//Assortment Filters

//UserInput
export const SET_USER_INPUT = "SET_USER_INPUT";
export const FETCH_USER_INPUT_REQUEST = "FETCH_USER_INPUT_REQUEST";
export const FETCH_USER_INPUT_SUCCESS = "FETCH_USER_INPUT_SUCCESS";
export const FETCH_USER_INPUT_FAILURE = "FETCH_USER_INPUT_FAILURE";
//UserInput

//assortment simulation
export const FETCH_ASSORTMENT_SIMULATION_REQUEST =
  "FETCH_ASSORTMENT_SIMULATION_REQUEST";
export const FETCH_ASSORTMENT_SIMULATION_SUCCESS =
  "FETCH_ASSORTMENT_SIMULATION_SUCCESS";
export const FETCH_ASSORTMENT_SIMULATION_FAILURE =
  "FETCH_ASSORTMENT_SIMULATION_FAILURE";
  
export const FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS =
  "FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS";

export const FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS =
  "FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS";

export const FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST =
  "FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST";
export const FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS =
  "FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS";
export const FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE =
  "FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE";
//assortment simulation

//assortment simulation
export const FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST =
  "FETCH_ASSORTMENT_RUN_SIMULATION_DATA_REQUEST";
export const FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS =
  "FETCH_ASSORTMENT_RUN_SIMULATION_DATA_SUCCESS";
export const FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE =
  "FETCH_ASSORTMENT_RUN_SIMULATION_DATA_FAILURE";

export const FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST =
  "FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST";
export const FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS =
  "FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS";
export const FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE =
  "FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE";

export const FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST =
  "FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST";
export const FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS =
  "FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS";
export const FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE =
  "FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE";

export const FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST =
  "FETCH_ASSORTMENT_PENETRATION_AVG_DATA_REQUEST";
export const FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS =
  "FETCH_ASSORTMENT_PENETRATION_AVG_DATA_SUCCESS";
export const FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE =
  "FETCH_ASSORTMENT_PENETRATION_AVG_DATA_FAILURE";

export const FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST =
  "FETCH_ASSORTMENT_MANUAL_CSV_DATA_REQUEST";
export const FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS =
  "FETCH_ASSORTMENT_MANUAL_CSV_DATA_SUCCESS";
export const FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE =
  "FETCH_ASSORTMENT_MANUAL_CSV_DATA_FAILURE";

export const FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST =
  "FETCH_ASSORTMENT_UPLOAD_CSV_DATA_REQUEST";
export const FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS =
  "FETCH_ASSORTMENT_UPLOAD_CSV_DATA_SUCCESS";
export const FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE =
  "FETCH_ASSORTMENT_UPLOAD_CSV_DATA_FAILURE";
export const SET_WALK_RATE = "SET_WALK_RATE"
export const SET_DEFAULT_PRODUCT_FILTERS = 'SET_DEFAULT_PRODUCT_FILTERS';
export const SET_DEFAULT_GEO_FILTERS = 'SET_DEFAULT_GEO_FILTERS'
export const SET_MAX_SOP_MIX_BAR_VAL = 'SET_MAX_SOP_MIX_BAR_VAL';
export const RESET_STATE_FOR_ASSORTMENT_FILTERS = "RESET_STATE_FOR_ASSORTMENT_FILTERS"
//assortment tool

export const GET_PEPSICO_FILTERS = "GET_PEPSICO_FILTERS";
export const GET_PEPSICO_FILTERS_SUCESS = "GET_PEPSICO_FILTERS_SUCESS";
export const GET_PEPSICO_FILTERS_FAILURE = "GET_PEPSICO_FILTERS_FAILURE";
export const ON_CHANGE_PEPSICO_OPTION = "ON_CHANGE_PEPSICO_OPTION";
export const CLEAR_PEPSICO_FILTERS = "CLEAR_PEPSICO_FILTERS";

export const GET_ANCHOR_PRODUCT_FILTERS = "GET_ANCHOR_PRODUCT_FILTERS";
export const GET_ANCHOR_PRODUCT_FILTERS_SUCESS =
  "GET_ANCHOR_PRODUCT_FILTERS_SUCESS";
export const GET_ANCHOR_PRODUCT_FILTERS_FAILURE =
  "GET_ANCHOR_PRODUCT_FILTERS_FAILURE";
export const ON_CHANGE_ANCHOR_OPTION = "ON_CHANGE_ANCHOR_OPTION";
export const CLEAR_ANCHOR_PRODUCT_FILTERS = "CLEAR_ANCHOR_PRODUCT_FILTERS";

export const GET_20_SEC_MARKET_CHECK = "GET_20_SEC_MARKET_CHECK";
export const GET_20_SEC_MARKET_CHECK_SUCESS = "GET_20_SEC_MARKET_CHECK_SUCESS";
export const GET_20_SEC_MARKET_CHECK_FAILURE =
  "GET_20_SEC_MARKET_CHECK_FAILURE";

export const DOWNLOAD_DASHBOARD = "DOWNLOAD_DASHBOARD";
export const DOWNLOAD_DASHBOARD_SUCESS = "DOWNLOAD_DASHBOARD_SUCESS";
export const DOWNLOAD_DASHBOARD_FAILURE = "DOWNLOAD_DASHBOARD_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCESS = "GET_USER_SUCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

/**Execution Filter */
export const SELECTED_EXECUTION_FILTER = "SELECTED_EXECUTION_FILTER";
export const UPDATE_EXECUTION_FILTER = "UPDATE_EXECUTION_FILTER";
export const FETCH_EXECUTION_FILTERS_REQUEST = "FETCH_ALL_FILTERS_REQUEST";
export const CLEAR_EXECUTION_FILTERS_REQUEST ="CLEAR_EXECUTION_FILTERS_REQUEST";
export const FETCH_EXECUTION_FILTERS_SUCCESS = "FETCH_ALL_FILTERS_SUCCESS";
export const FETCH_EXECUTION_FILTERS_FAILURE = "FETCH_ALL_FILTERS_FAILURE";
export const FETCH_EXECUTION_USER_INPUT_SUCCESS = "FETCH_EXECUTION_USER_INPUT_SUCCESS";
export const SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT = "SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT";
export const FETCH_EXECUTION_USER_INPUT_REQUEST = "FETCH_EXECUTION_USER_INPUT_REQUEST";
export const FETCH_EXECUTION_USER_INPUT_FAILURE = "FETCH_EXECUTION_USER_INPUT_FAILURE";
export const SET_EXECUTION_SELECTED_USER_INPUT_DATA = "SET_EXECUTION_SELECTED_USER_INPUT_DATA";
export const SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA = "SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA";
export const SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA = "SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA";

export const SET_PREVIOUSLY_SELECTED_FILTER = "SET_PREVIOUSLY_SELECTED_FILTER";
export const SET_LAST_TRIGGERED_KEY = "SET_LAST_TRIGGERED_KEY";
export const SELECTED_EXECUTION_TABLE_FILTER = "SELECTED_EXECUTION_TABLE_FILTER";
export const GET_PORTFOLIO_TABLE_FILTER_DATA = "GET_PORTFOLIO_TABLE_FILTER_DATA";
export const GET_GEO_TABLE_FILTER_DATA = "GET_GEO_TABLE_FILTER_DATA"
export const  FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST = "FETCH_EXECUTION_PORTFOLIO_TABLE_FILTERS_REQUEST";
export const FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST = "FETCH_EXECUTION_GEO_TABLE_FILTERS_REQUEST";
export const FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE = "FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE";
export const FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS = "FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS";
export const FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST = "FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST";
export const FETCH_EXECUTION_AVG_DATA_FAILURE = "FETCH_EXECUTION_AVG_DATA_FAILURE";
export const FETCH_EXECUTION_AVG_DATA_SUCCESS = "FETCH_EXECUTION_AVG_DATA_SUCCESS";
export const FETCH_EXECUTION_AVG_DATA_REQUEST = "FETCH_EXECUTION_AVG_DATA_REQUEST";
export const FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST = "FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST";
export const FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST = "FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST";
export const FETCH_EXECUTION_OPPORTUNITIES_SUCCESS = "FETCH_EXECUTION_OPPORTUNITIES_SUCCESS";
export const FETCH_EXECUTION_OPPORTUNITIES_FAILURE = "FETCH_EXECUTION_OPPORTUNITIES_FAILURE";
export const FETCH_EXECUTION_SALES_DATA_SUCCESS = "FETCH_EXECUTION_SALES_DATA_SUCCESS";
export const FETCH_EXECUTION_SALES_DATA_FAILURE = "FETCH_EXECUTION_SALES_DATA_FAILURE";
export const LOADER_STATE_FOR_RUN_SIMULATION = "LOADER_STATE_FOR_RUN_SIMULATION";
export const RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE = "RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE";
export const USER_LOGIN_STATE = "USER_LOGIN_STATE";
export const GET_SCENARIO_DETAILS = "GET_SCENARIO_DETAILS";
export const LOADER_FOR_SCENARIO_DETAILS = "LOADER_FOR_SCENARIO_DETAILS";
export const SAVE_SCENARIO = "SAVE_SCENARIO"
export const LOADER_FOR_SCENARIO_SAVE = "LOADER_FOR_SCENARIO_SAVE";
export const VIEW_EDIT_SCENARIO = "VIEW_EDIT_SCENARIO"
export const CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS = "CLEAR_GEO_PORTFOLIO_LEVEL_SELECTED_FILTERS";
export const SET_TIMEPERIOD = "SET_TIMEPERIOD"
//mix simulation constants

export const CLEAR_MIX_SIMULATION_FILTERS_REQUEST="CLEAR_MIX_SIMULATION_FILTERS_REQUEST";
export const FETCH_MIX_SIMULATION_FILTERS_FAILURE="FETCH_MIX_SIMULATION_FILTERS_FAILURE";
export const FETCH_MIX_SIMULATION_FILTERS_REQUEST="FETCH_MIX_SIMULATION_FILTERS_REQUEST";
export const FETCH_MIX_SIMULATION_FILTERS_SUCCESS="FETCH_MIX_SIMULATION_FILTERS_SUCCESS";
export const RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST="RESET_MIX_SIMULATION_GEO_FILTERS_REQUEST";
export const SELECTED_MIX_SIMULATION_FILTER="SELECTED_MIX_SIMULATION_FILTER";
export const SET_MIX_SIMULATION_LAST_TRIGGERED_KEY="SET_MIX_SIMULATION_LAST_TRIGGERED_KEY";
export const SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER="SET_PREVIOUSLY_SELECTED_MIX_SIMULATION_FILTER";
export const UPDATE_MIX_SIMULATION_FILTER="UPDATE_MIX_SIMULATION_FILTER";
export const MIX_PORTFOLIO_TABLE_DATA_REQUEST = "MIX_PORTFOLIO_TABLE_DATA_REQUEST";
export const MIX_PORTFOLIO_TABLE_DATA_SUCCESS = "MIX_PORTFOLIO_TABLE_DATA_SUCCESS";
export const MIX_PORTFOLIO_TABLE_DATA_FAILURE = "MIX_PORTFOLIO_TABLE_DATA_FAILURE";
export const MIX_GEO_LEVEL_TABLE_DATA_REQUEST = "MIX_GEO_LEVEL_TABLE_DATA_REQUEST";
export const MIX_GEO_LEVEL_TABLE_DATA_SUCCESS = "MIX_GEO_LEVEL_TABLE_DATA_SUCCESS";
export const MIX_GEO_LEVEL_TABLE_DATA_FAILURE = "MIX_GEO_LEVEL_TABLE_DATA_FAILURE";
export const MIX_SUMMARY_DATA_REQUEST = "MIX_SUMMARY_DATA_REQUEST";
export const MIX_SUMMARY_DATA_SUCCESS = "MIX_SUMMARY_DATA_SUCCESS";
export const MIX_SUMMARY_DATA_FAILURE = "MIX_SUMMARY_DATA_FAILURE";
export const HANDLE_MIX_SIMULATION_EDIT_MODE = "HANDLE_MIX_SIMULATION_EDIT_MODE";
export const RESET_ALL_MIX_SIMULATION_FILTERS = "RESET_ALL_MIX_SIMULATION_FILTERS";


export const SET_MIX_SIMULATION_GOAL = "SET_MIX_SIMULATION_GOAL";
export const SET_MIX_SIMULATION_UID = "SET_MIX_SIMULATION_UID";
export const REQUEST_MIX_SIMULATION_SAVE_SCENARIO = "REQUEST_MIX_SIMULATION_SAVE_SCENARIO";
export const FAILURE_MIX_SIMULATION_SAVE_SCENARIO = "FAILURE_MIX_SIMULATION_SAVE_SCENARIO";

export const FETCH_MIX_SIMULATION_DECISION_VARIABLE = "FETCH_MIX_SIMULATION_DECISION_VARIABLE";
export const SET_MIX_SIMULATION_DECISION_VARIABLE = "SET_MIX_SIMULATION_DECISION_VARIABLE";
export const FAILURE_MIX_SIMULATION_DECISION_VARIABLE = "FAILURE_MIX_SIMULATION_DECISION_VARIABLE";


export const REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA = "REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA";
export const HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER = "HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER";
export const SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA="SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA";

export const SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA = "SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA";
export const SET_MIX_SIMULATION_CONSTRAINT_DOWNLOAD_TABLE_DATA = "SET_MIX_SIMULATION_CONSTRAINT_DOWNLOAD_TABLE_DATA";
export const SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA = "SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA";
export const FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA = "FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA";
export const SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER = "SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER"
export const SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER = "SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER"
export const REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER = "REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER";
export const SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER = "SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER";
export const FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER = "FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER";
export const LOADER_FOR_RUN_SIMULATION = "LOADER_FOR_RUN_SIMULATION";
export const UPLOAD_VALIDATION_SUCCESS = "UPLOAD_VALIDATION_SUCCESS";
export const IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE = "IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE";


export const MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST = "MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_REQUEST";
export const MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS = "MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_SUCCESS";
export const MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE = "MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_DATA_FAILURE"
export const RESET_MIX_USER_CONSTRAINTS_CSV_DATA = "RESET_MIX_USER_CONSTRAINTS_CSV_DATA"

export const MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS = "MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_OPERATIVE_DATA_SUCCESS"
export const MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS = "MIX_USER_CONSTRAINTS_DOWNLOAD_OR_UPLOAD_CAPACITY_DATA_SUCCESS";
export const LOADER_FOR_MIX_RUN_SIMULATION = "LOADER_FOR_MIX_RUN_SIMULATION";
export const UPLOAD_OPERATIVE_COMPLEXITY = "UPLOAD_OPERATIVE_COMPLEXITY";
export const UPLOAD_CAPACITIVE_COMPLEXITY = "UPLOAD_CAPACITIVE_COMPLEXITY";
export const RESET_MIX_CONSTRAINT_TABLE = "RESET_MIX_CONSTRAINT_TABLE";


export const SET_OUTPUT_SCREEN_TO_DISPLAY = "SET_OUTPUT_SCREEN_TO_DISPLAY";
export const SET_MIX_PORTFOLIO_LEVEL = "SET_MIX_PORTFOLIO_LEVEL";
export const SET_MIX_GEO_LEVEL = "SET_MIX_GEO_LEVEL"
export const OUTPUT_SELECTED_MIX_FILTERS = "OUTPUT_SELECTED_MIX_FILTERS";
export const GET_GEO_FILTER_DATA = "GET_GEO_FILTER_DATA";

export const SET_PENETRATION_VIEW_MODE = "SET_PENETRATION_VIEW_MODE";
export const SET_VIEW_MODE ='SET_VIEW_MODE';
export const SET_GEO_FILTER_VISIBILITY = 'SET_GEO_FILTER_VISIBILITY'
export const SET_DEFAULT_QUADRANT_FILTERS = 'SET_DEFAULT_QUADRANT_FILTERS';

export const SET_SCENARIO_NAME = "SET_SCENARIO_NAME";
export const SET_PENETRATION_LIMIT_LEVEL = "SET_PENETRATION_LIMIT_LEVEL";
export const SET_MIX_SCENARIO_NAME = "SET_MIX_SCENARIO_NAME";
export const SET_MIX_VIEW_MODE = "SET_MIX_VIEW_MODE";
export const SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID = "SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID";
export const SECTION_FILTER_LOADER = "SECTION_FILTER_LOADER";
export const GET_HISTORICAL_RANGE_DATA = "GET_HISTORICAL_RANGE_DATA";
export const GET_PRICE_ELASTICITY_DATA = "GET_PRICE_ELASTICITY_DATA";
export const LOADER_FOR_HISTORICAL_RANGE = "LOADER_FOR_HISTORICAL_RANGE";
export const LOADER_FOR_Elasticity = "LOADER_FOR_Elasticity";
export const TOGGLE_FOR_PI = "TOGGLE_FOR_PI";
export const RESET_DATA_FOR_GRAPH = "RESET_DATA_FOR_GRAPH";
export const RESET_MIX_USER_INPUTS = "RESET_MIX_USER_INPUTS";
export const SET_ALL_MIX_USERINPUT_DETAILS = "SET_ALL_MIX_USERINPUT_DETAILS";
export const SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR = "SELECTED_BUBBLE_VALUE_SELECTION_FILTER_MIX_SIMULATOR";
export const SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR = "SELECTED_BUBBLE_CHART_OPTIONS_SELECTION_FILTER_MIX_SIMULATOR";
export const SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA = "SET_MIX_SIMULATOR_PRICE_ELASTICITY_DOWNLOAD_DATA";
export const SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA = "SET_MIX_SIMULATOR_PRICE_ELASTICITY_UPLOAD_DATA";



export const SET_SELECTED_DATE_TIMEPERIOD_DETAILS = "SET_SELECTED_DATE_TIMEPERIOD_DETAILS";
export const RESET_MIX_SIMULATION_PORTFOLIO_TABLE = "RESET_MIX_SIMULATION_PORTFOLIO_TABLE";
export const RESET_MIX_SIMULATION_GEO_LEVEL_TABLE = "RESET_MIX_SIMULATION_GEO_LEVEL_TABLE";

export const API_URL = {
  ASSORTMENT:{
    SCENARIO_LIST:"scenario/assortment/ScenarioList",
    SCENARIO_DETAILS:"scenario/assortment/ScenarioDetails",
    SUMMARY_DATA:"output/assortment/summary",
    CURRENT_AVERAGE:"assortmentSimulation/getAssortmentCurrentAverage",
    QUADRANT_FILTER_OPTIONS:"assortment/QuadrantFilterOptions",
    QUADRANT_GRAPH_DATA:"assortment/QuadrantGraphData",
    DELETE_SCENARIO:"scenario/assortment/DeleteScenario",
    TABLE_AND_SIZE_OF_PRIZE_DATA:"output/assortment/AssortmentTableAndSizeOfPrizeData",
    ASSORTMENT_CALCULATION_OUTPUT:"output/assortmentSimulation/getAssortmentCalculationOutput",
    DATE_TIME_PERIOD_DATA:"scenario/assortment/getDateTimePeriodData",
    MUST_HAVE_SKU_DATA:"assortment/coverage",
    DOWNLOAD_ASSORTMENT_SIMULATION_DATA:"output/assortmentSimulation/downloadAssortmentSimulationData",
    UPDATE_CSV_FILE_IN_OUTPUT_TABLE:"output/assortmentSimulation/UpdateCsvFileInOutputTable",
    DOWNLOAD_CSV_FILE_FROM_ASSORTMENT_SIMULATION_OUTPUT_TABLE:"output/assortment/downloadCsvFileFromAssortmentSimulationOutputTable",
    EDIT_ASSORTMENT_RUN_SIMULATION:"output/assortmentSimulation/editAssortmentRunSimulation",
    UNIQUE_ASSORTMENT_SCENARIO_NAME:"output/assortmentSimulation/uniqueAssortmentScenarioName",
    PRODUCT_FILTERS:"filter/getAssortmentProductFilter",
    GEO_FILTERS:"filter/getAssortmentGeoFilter",
  },
  EXECUTION:{
    TRACKER_SIMULATION_DATA:"executionTrackerSimulationData",
    CURRENT_AVERAGES:"currentAverages",
    GET_OPPORTUNITIES_DATA_AFTER_EXECUTION:"getOpportunitiesDataAfterExecution",
    GET_REST_OVERVIEW_DATA_AFTER_EXECUTION:"getRestOverviewDataAfterExecution",
    GET_PORTFOLIO_DATA_AFTER_EXECUTION:"output/executionTracker/getPortfolioDataAfterExecution",
    GET_GEO_LEVEL_SUMMARY_DATA_AFTER_EXECUTION:"output/executionTracker/getGeoLevelSummaryDataAfterExecution",
    EXECUTION_TRACKER_FILTER:"executionTrackerFilter",
    EXECUTION_TRACKER_USER_FILTER:"executionTrackerUserFilter",
    TRACKER_GEO_TABLE_FILTER:"filter/executionTrackerGeoTableFilter",
  },
  MIXSIMULATION:{
    GET_OPTIMIZER_DATA:"output/Optimizer/getOptimizerData",
    FETCH_HISTORICAL_RANGE_GRAPH_DATA:"output/mix/fetchHistoricalRangeGraphData",
    FETCH_PRICE_ELASTICITY_GRAPH_DATA:"output/mix/fetchPriceElasticityGraphData",
    UPDATE_PRICE_ELASTICITY_GRAPH_DATA:"output/mix/updatePriceElasticityGraphData",
    DOWNLOAD_CSV_FILE_OPERATVE_COMPLEXITY:"mix/downloadCsvFileOperatveComplexity",
    DOWNLOAD_CSV_FILE_CAPACITY_CONSTRAINT:"mix/downloadCsvFileCapacityConstraint",
    UPLOAD_CSV_FILE_OPERATIVE_COMPLEXITY:"output/mix/uploadCsvFileOperativeComplexity",
    UPLOAD_CSV_FILE_CAPACITY_CONSTRAINT:"output/mix/uploadCsvFileCapacityConstraint",
    GET_MIX_SUMMARY_DATA:"MixSimulation/getMixSummaryData",
    GET_PORTFOLIO_DATA_AFTER_RUN_SIMULATION:"MixSimulation/getPortfolioDataAfterRunSimulation",
    GET_GEO_LEVEL_SUMMARY_DATA_AFTER_RUN_SIMULATION:"MixSimulation/getGeoLevelSummaryDataAfterRunSimulation",
    UNIQUE_MIX_SCENARIO_NAME:"scenario/mix/uniqueMixScenarioName",
    MOVE_INITIAL_PRICE_ELASTICITY_TABLE_DATA_TO_UPDATE_IT:"output/mix/mixMoveInitialPriceElasticityTableDataToUpdateIt",
    OPTIMIZER_SECTION_FILTER:"filter/mixOptimizerSectionFilter",
    GET_MIX_CONTRAINT_TABLE_DATA:"getMixContraintTableData",
    OPTIMIZER_FILTER:"filter/mixOptimizerFilter",
    MIX_OPTIMIZER_DYNAMIC_FILTER:"filter/mixOptimizerDynamicFilter",
    SAVE_MIX_CONSTRIANT_TABLE_DATA:"output/MixSimulation/saveMixConstriantTableData",
    SCENARIO_GEO_FILTERS:"output/mix/MixScenarioGeoFilters",
    OPTIMIZER_DATA_PERIOD:"filter/mixOptimizerDataPeriod",
    SCENARIO_LIST:"scenario/mix/ScenarioList",
    SCENARIO_DELETE:"scenario/mix/ScenarioDelete",
    GET_MIX_DATA_AFTER_RUN_SIMULATION:"output/MixSimulation/getMixDataAfterRunSimulation",
    SCENARIO_DETAILS:"scenario/mix/ScenarioDetails",
    GET_ALL_DOWNLOAD_DATA_AFTER_RUN_SIMULATION:"output/MixSimulation/getAllDownloadDataAfterRunSimulation"
  }
}


// export const BACKEND_URL = "http://revolutiom-loyal-dev.loyal.cl:5001"
export const BACKEND_URL = process.env.REACT_APP_BACKEND_BASE_URL
// export const BACKEND_URL = "https://mix-assortment-hubble-qa-backend.azurewebsites.net"

// export const BACKEND_URL = "https://revolutiom-loyal-qa.loyal.cl"
// export const BACKEND_URL = "https://localhost:6001"

// export const BACKEND_URL = "https://localhost:5001"
// export const BACKEND_URL = "http://172.176.129.128:5001"
