import * as React from "react";
import { CardContent, Grid, Typography, Switch } from "@mui/material";
import {
  Title,
  StyledCard,
  Item,
  CancelButton,
  SaveButton,
} from "../../MMPW.Styled";

const GraphPortions: React.FC<{}> = ({}) => {
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12} display="flex" justifyContent="flex-end">
            <SaveButton>Save</SaveButton>
            <CancelButton>Cancel</CancelButton>
          </Grid>
          <Grid item xs={12}>
            <Title>GraphPortions OFF/ON</Title>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography color="black">Show Labels</Typography>
              <Switch color="primary" />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography color="black">Graph Title</Typography>
              <Switch color="primary" />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography color="black">Graph Icon</Typography>
              <Switch color="primary" />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography color="black">Trend Line</Typography>
              <Switch color="primary" />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default GraphPortions;
