import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlackInputTitle, NoData } from '../../../../styles/Common.Styled';
import { LinearRegressionLineChartConfig } from '../../chartsConfig';
import _ from 'lodash';
import CustomTable from '../../../../components/CustomTable';
import Dashboard from '../../../../components/Dashboard';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import data from '../../../../mocks/profitParabola';
import { loader } from '../../../../store/actions/common.action';
import { messages } from '../../../../util/config';
import { plotElementsOnChart, separator, setRSquareColor } from '../../../../util/helper';
import HighchartContainer from '../../../../components/HighchartContainer';
import { getLinearRegressionChartData } from '../../service';
import { linearRegressionLoader } from '../../store/ProfitParabolaDashboard.action';

const visualId = 'profit-parabola-linear-regression-model';

const LinearRegression: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = React.useRef(null);
  const [originalResponse, setOriginalResponse] = useState<any>({});
  const [skeleton, setSkeleton] = useState(true);
  const [linearRegressionChartData, setLinearRegressionChartData] = useState({});
  const [tableData, setTableData] = useState({ ...data });
  const [defaultPeriodicity, setDefaultPeriodicity] = useState('weekly');

  const formatLinearRegressionChartData = (response) => {
    if (response.data) {
      const dates = response.data.map((i) => i.date);
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.xAxis[0].setCategories(dates);
        chart.series[0].setData(response.data.map((i) => i.realVolume));
        chart.series[1].setData(response.data.map((i) => i.estVolume));
      }
    }
  };

  // response is hard coded in linearRegressionChart.action
  const formatLinearRegressionTableData = (response) => {
    tableData.regressionStatsTable.value[0].statsPer = `${Math.round(response.multipleR * 100) + '%'}`;
    tableData.regressionStatsTable.value[1].statsName = {
      ...tableData.regressionStatsTable.value[1].statsName,
      ...setRSquareColor(response.rSquare * 100),
    };
    tableData.regressionStatsTable.value[1].statsPer = {
      ...setRSquareColor(response.rSquare * 100),
      value: `${Math.round(response.rSquare * 100) + '%'}`,
    };
    tableData.regressionStatsTable.value[2].statsPer = `${Math.round(response.adjustedRSquare * 100) + '%'}`;
    tableData.regressionStatsTable.value[3].statsPer = response.standardError;
    tableData.regressionStatsTable.value[4].statsPer = response.observations;

    tableData.realESTVolumeTable.value = response.data.map((item) => {
      return {
        time: item.date, //dataFormat(item.date, getDataFormat(filters.periodicity)),
        api: separator(item.api.toFixed(2)),
        realVol: separator(item.realVolume.toFixed(2)),
        estVol: separator(item.estVolume.toFixed(2)),
      };
    });

    if (_.isArray(response.standardErrors)) {
      const orderData = _.orderBy(response.standardErrors, (se) => se.displayOrder);
      tableData.coefficientTable.value = orderData.map((item) => {
        return {
          name: item.name,
          value1: separator(item.value1.toFixed(2)),
          value2: separator(item.value2.toFixed(2)),
          tStat: separator(item.tStat.toFixed(2)),
          pValue: separator(item.pValue.toFixed(2)),
          lower95: separator(item.lower95.toFixed(2)),
          upper95: separator(item.upper95.toFixed(2)),
        };
      });
    }
    setTableData({ ...tableData });
  };

  useEffect(() => {
    if (linearRegressionChartData && Object.keys(linearRegressionChartData).length) {
      formatLinearRegressionTableData(linearRegressionChartData);
      setTimeout(() => {
        formatLinearRegressionChartData(linearRegressionChartData);
      }, 0);
    }
  }, [linearRegressionChartData]);

  useEffect(() => {
    if (filters && filters.country) {
      dispatch(linearRegressionLoader(true));
      setLinearRegressionChartData({});
      setOriginalResponse({});
      setSkeleton(true);
      getLinearRegressionChartData(filters)
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            dispatch(linearRegressionLoader(false));
            setSkeleton(false);
            dispatch(loader(false));
            setOriginalResponse(response.data);
            const periodicities = {
              Week: 'weekly',
              Month: 'monthly',
              Q: 'quarterly',
            };
            const periodicity = periodicities[filters.periodicity];
            setDefaultPeriodicity(periodicity);
            setLinearRegressionChartData({ ...response.data[periodicity] });
          } else {
            dispatch(linearRegressionLoader(false));
            setSkeleton(false);
            dispatch(loader(false));
          }
        })
        .catch((err) => {
          dispatch(linearRegressionLoader(false));
          setSkeleton(false);
          dispatch(loader(false));
        });
    }
  }, [filters]);

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
      if (dashbaordData && dashbaordData[visualId]) {
        plotElementsOnChart(dashbaordData[visualId], chart, visualId);
      }
    }
  });

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard
          title='Linear Regression Model'
          showSkeleton={!filters.packSize || !filters.anchorPackSize || skeleton}
          chartRef={chartRef}
          id={visualId}
          showActionButton={
            _.isArray(linearRegressionChartData.data) &&
            linearRegressionChartData?.data?.length > 0 &&
            Number(tableData?.coefficientTable?.value[0]?.value1) !== 0
          }
        >
          {!skeleton ? (
            filters.packSize && filters.anchorPackSize ? (
              _.isArray(linearRegressionChartData.data) &&
              linearRegressionChartData.data.length &&
              tableData.coefficientTable.value.length &&
              Number(tableData.coefficientTable.value[0].value1) !== 0 &&
              Number(tableData.coefficientTable.value[1].value2 !== 0) ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <BlackInputTitle className='m-b-20'>Applied in the Simulation</BlackInputTitle>
                      <Grid container spacing={2} className='m-b-20'>
                        <Grid
                          item
                          xs={12}
                          md={7}
                          style={{
                            maxHeight: 350,
                            overflow: 'auto',
                            paddingTop: 0,
                            marginTop: 20,
                          }}
                        >
                          <CustomTable heading={tableData.realESTVolumeTable.heading} value={tableData.realESTVolumeTable.value} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <CustomTable heading={tableData.regressionStatsTable.heading} value={tableData.regressionStatsTable.value} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} display='flex' alignItems='center'>
                      <Grid item xs={12}>
                        {filters.periodicity === 'Year' ? (
                          <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                            <NoData>{messages.noDataYearPeriodicity}</NoData>
                          </Box>
                        ) : (
                          <HighchartContainer chartRef={chartRef} id={visualId}>
                            <HighchartsReact highcharts={Highcharts} options={LinearRegressionLineChartConfig} ref={chartRef} />
                            <Grid
                              style={{
                                position: 'relative',
                                bottom: 50,
                                left: 30,
                                zIndex: 2,
                                width: '100%',
                              }}
                            >
                              <IncrementDecrement
                                list={['weekly', 'monthly', 'quarterly']}
                                defaultOption={
                                  (localStorage.getItem('periodicity') && JSON.parse(localStorage.getItem('periodicity'))[visualId]?.item) ||
                                  defaultPeriodicity ||
                                  null
                                }
                                data={originalResponse}
                                selectedResponse={setLinearRegressionChartData}
                                show={originalResponse && Object.keys(originalResponse).length > 0}
                                id={visualId}
                              />
                            </Grid>
                          </HighchartContainer>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <CustomTable heading={tableData.coefficientTable.heading} value={tableData.coefficientTable.value} />
                  </Grid>
                </>
              ) : (
                <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                  <NoData>{messages.noData}</NoData>
                </Box>
              )
            ) : (
              <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                <NoData>{messages.incorrectFilters}</NoData>
              </Box>
            )
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant='rectangular' width={150} height={30} className='m-b-10 m-t-10' />
                  <Grid container spacing={2} className='m-b-20'>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        maxHeight: 400,
                        overflow: 'auto',
                        paddingTop: 0,
                        marginTop: 20,
                      }}
                    >
                      <CustomTable heading={[1, 2, 3, 4]} value={[]} showSkeleton={skeleton} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <CustomTable
                        heading={tableData.regressionStatsTable.heading}
                        value={tableData.regressionStatsTable.value}
                        showSkeleton={skeleton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} display='flex' alignItems='center'>
                  <Grid item xs={12}>
                    <Skeleton variant='rectangular' height={300} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <CustomTable heading={tableData.coefficientTable.heading} value={[]} showSkeleton={skeleton} />
              </Grid>
            </>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default LinearRegression;
