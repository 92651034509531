import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import Indicator from "../../../../components/Loader/Loader";
import FilterAccordion from "../../../../components/Filters/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import { fetchGeoXrefColumns } from "../../service";
import { geoxrefDataLoader } from "../../store/GeoXref.action";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import MeasureFilters from "../MeasureFilters";
import { useDispatch, useSelector } from "react-redux";
import { pascaleCase } from "../../../../util/helper";
import _ from "lodash";
const defaultColumns = ["geokey", "raw_division", "raw_geo", "source"];
const TopFilters: React.FC<{ callback; defaultFilters? }> = ({
  callback,
  defaultFilters,
}) => {
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(
    defaultFilters ? defaultFilters : { ...initialState }
  );
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [columnHeadings, setColumnHeadings] = useState<any[]>([]);
  const dispatch = useDispatch();
  const GeoXrefDataLoader = useSelector(
    (state: any) => state.GeoXrefDataLoader
  );
  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
    }
  }, [selectedGeoFilters.country]);
  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...selectedGeoFilters, country: null });
    setColumnsData([]);
  };
  const onApplyFilter = async () => {
    dispatch(geoxrefDataLoader(true));
    callback({
      filters: {
        ...selectedGeoFilters,
        columnNames: columnHeadings.length>0 ? [...defaultColumns, ..._.sortBy(columnHeadings)] : defaultColumns,
      },
    });
  };
  const disableApplyFilter = () => {
    const flag = !selectedGeoFilters.country || !selectedGeoFilters.geoLevel2
    return flag;
  };
  useEffect(() => {
    getColumnsData();
  }, []);
  const getColumnsData = () => {
    if (selectedGeoFilters) {
      fetchGeoXrefColumns().then((response) => {
        if (response && response.data) {
          const responseData = response.data.map((item) => {
            const disable = defaultColumns.findIndex((x) => x === item.name) !== -1;
            return { label: pascaleCase(item.key, '_'), value: item.name, disable };
          }).filter(x=> !x.disable);
          setColumnsData(_.sortBy(responseData, 'label'));
        }
      });
    }
  };
  return (
    <Card style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || GeoXrefDataLoader.geoxrefDataLoader}
      />
      <CardContent>
        <FilterAccordion title="Geo Filters">
          <GeoFiltersV2
            key="geo-xref-top-filter"
            data={geoFilterV2Config}
            onChangeDate={(dt) => {
              clearGeoFilter(false);
            }}
            onSelectFilters={(e) => setSelectedGeoFilters(e)}
            clearFilter={clearFilterData}
            apiPath="BrandLadder"
            showLoader={setLoader}
            defaultFilters={defaultFilters}
            showDatePicker={false}
          />
        </FilterAccordion>
        <FilterAccordion title="Measure Filters">
          <MeasureFilters
            data={columnsData}
            callback={setColumnHeadings}
          />
        </FilterAccordion>
        <Grid className="p-l-16">
          <OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={disableApplyFilter()}
            color="primary"
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TopFilters;
