import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, TextField, Skeleton } from "@mui/material";
import { removeMMPWFromLocal } from "../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { LightBlueBtn } from "../../styles/Common.Styled";
import UserInputs from "./components/UserInputs";
import {
  MandotoryTypography,
  ManualSkuButton,
  ManualSkuTypography,
  StyledTypography,
} from "./AssortmentTool.Styled";
import AssortmentGeoFilters from "./components/AssortmentGeoFilters";
import AssortmentListTableSKU from "./components/AssortmentListTableSKU/AssortmentListTableSKU";
import QuadrantGraph from "./components/QuadrantGraph/QuadrantGraph";
import AssortmentSummary from "./components/AssortmentSummary/AssortmentSummary";
import { MustHaveCoverage } from "./components/ColumnGraph/MustHaveCoverage";
import { SizeOfPrize } from "./components/WaterfallChart/SizeOfPrize";
import { useHistory } from "react-router-dom";
import {
  isViewEditScenario,
  setAssortmentScenarioName,
} from "../../store/actions//AssortmentTool/assortmentFilters.action";
import Spinner from "./components/Loader/Loader";
import { SCENARIO_LIBRARY } from "../../router/CONSTANTS";
import AsssignPenetrationTarget from "./components/UserInputs/AsssignPenetrationTarget/AsssignPenetrationTarget";
import { PopUptableWrapper } from "./components/AddManualSKUTable/PopUptableWrapper";
import AssortmentOverallFilters from "./components/AssortmentOverallFilters";
import { AssortmentOutput } from "./components/AssortmentOutput/AssortmentOutput";

const AssortmentTool: React.FC<{}> = () => {
  const { uid } = useSelector((state: any) => state.AssortmentFiltersReducer);
  const { viewMode } = useSelector(
    (state: any) => state.assortmentDashboardReducer
  );
  const { location, push } = useHistory();
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState({} as any);

  const [defaultFilters, setDefaultFilters] = useState({});
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [isRevenue, setisRevenueColumns] = useState(false);
  const [scenarioName, setScenarioName] = useState("");

  const handleInputChange = (event) => {
    let name = event.target.value;
    dispatch(setAssortmentScenarioName(name));
    return setScenarioName(name);
  };

  const handleRedirectToLibrary = () => {
    return push(SCENARIO_LIBRARY);
  };

  useEffect(() => {
    if (location?.state?.id) {
      setScenarioName(location.state.scenarioName);
      dispatch(isViewEditScenario(true));
    } else {
      dispatch(isViewEditScenario(false));
    }
  }, []);

  const onApplyFilter = (filters) => {
    setDisableDownloadButton(false);
    removeMMPWFromLocal("historic-price");
    setAppliedFilters(filters);
  };

  const [selectedGeoFilters, setSelectedGeoFilters] = useState({});
  const [selectedProductFilters, setSelectedProductFilters] = useState({});
  const [selectedQuadrantFilters, setSelectedQuadrantFilters] = useState({});
  const [userInputValues, setUserInputValues] = useState({});
  const [open, setOpen] = useState(false);

  const handleGeoFiltersChange = (selectedFilters) => {
    setSelectedGeoFilters(selectedFilters);
  };

  const handleProductFiltersChange = (selectedFilters) => {
    setSelectedProductFilters(selectedFilters);
  };

  const handleQuadrantFiltersChange = (selectedFilters) => {
    setSelectedQuadrantFilters(selectedFilters);
  };

  const handleInputPenetrationChange = (fieldName, value) => {
    setUserInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleAddManualSku = () => {
    setOpen((isOpen) => !isOpen);
  };

  const { loaderForRunSimulation } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );

  return (
    <>
      {loaderForRunSimulation && (
        <>
          <Spinner position="fixed" show={loaderForRunSimulation} text={""} />
        </>
      )}
      <Grid
        style={{
          position: "relative",
        }}
      >
        <Grid container spacing={1} className="m-b-20" alignItems="center">
          <Grid
            xs={6}
            item
            display={"flex"}
            alignItems={"center"}
            gap={"0.5rem"}
          >
            <Typography>
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Scenario Name :
              </span>
            </Typography>
            <TextField
              InputProps={{ autoComplete: "off" }}
              size="small"
              name="scenarioName"
              style={{ width: "400px", height: "35px" }}
              placeholder="Enter a scenario name..."
              disabled={location?.state?.id ? true : false}
              onChange={handleInputChange}
              value={scenarioName}
            />
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end" gap={"1rem"}>
            <LightBlueBtn onClick={handleRedirectToLibrary}>
              Back to Scenario Library
            </LightBlueBtn>
          </Grid>
        </Grid>

        <AssortmentOverallFilters
          onSelectFilters={handleProductFiltersChange}
          isViewEdit={location.state?.id}
        />

        <Grid
          container
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <StyledTypography>User Inputs</StyledTypography>
          <MandotoryTypography>* Mandatory</MandotoryTypography>
        </Grid>

        <UserInputs
          productFilters={selectedProductFilters}
          isViewEdit={location.state?.id}
        />

        <Grid spacing={1}>
          <AssortmentGeoFilters
            onSelectFilters={handleGeoFiltersChange}
            selectedProductFilters={selectedProductFilters}
            isViewEdit={location.state?.id}
          />
        </Grid>

        <AsssignPenetrationTarget
          onInputChange={handleInputPenetrationChange}
          geoFilters={selectedGeoFilters}
        />
        <AssortmentOutput />
        <AssortmentSummary />
        <Grid style={{ marginTop: "20px" }}>
          <StyledTypography>Must have/Good to have Coverage %</StyledTypography>
          <MustHaveCoverage
            data={{
              columnData: [60, 30],
              areaData: [10, 20],
              xAxisData: ["Revenue Mix", "Profit Mix"],
            }}
            selectedGeoFilters={selectedGeoFilters}
          />
        </Grid>
        <Grid style={{ marginTop: "20px" }} spacing={1}>
          <SizeOfPrize
            isRevenue={isRevenue}
            setisRevenueColumns={setisRevenueColumns}
            filtersPayload={{ ...selectedGeoFilters, uid }}
            selectedGeoFilters={selectedGeoFilters}
          />
        </Grid>
        <Grid style={{ marginTop: "20px" }} spacing={1}>
          <AssortmentListTableSKU />
        </Grid>
        {open && (
          <PopUptableWrapper
            isOpen={open}
            setIsOpen={setOpen}
          ></PopUptableWrapper>
        )}
        <Card style={{ marginTop: "20px" }}>
          <Grid container>
            <ManualSkuTypography>
              Add Manual override SKU Assortment:{" "}
            </ManualSkuTypography>
            {!viewMode ? (
              <>
                <Skeleton
                  style={{ marginTop: "15px" }}
                  variant="rectangular"
                  width={150}
                  height={30}
                />
              </>
            ) : (
              <>
                <ManualSkuButton onClick={handleAddManualSku}>
                  Add Manual SKU Type
                </ManualSkuButton>
              </>
            )}
          </Grid>
        </Card>

        <>
          <QuadrantGraph
            onSelectFilters={handleQuadrantFiltersChange}
            id={1}
            selectedGeoFilters={selectedGeoFilters}
          />
        </>
      </Grid>
    </>
  );
};

export default AssortmentTool;
