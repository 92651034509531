import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryBtn } from "../../../../styles/Common.Styled";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast } from "../../../../store/actions/toast.action";
import {
  fetchAssortmentManualCSVDataFailure,
  fetchAssortmentManualCSVDataRequest,
  fetchAssortmentManualCSVDataSuccess,
  fetchAssortmentUploadCSVDataFailure,
  fetchAssortmentUploadCSVDataRequest,
  setIsCsvUploaded,
} from "../../../../store/actions//AssortmentTool/assortmentFilters.action";
import Spinner from "../Loader/Loader";
import { fetchApi } from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { closeIcon } from "../../../../assets/icons/common";
import {
  allowedValuesForCSV,
  convertCsvToJson,
  convertToCSV,
  downloadCSVAlertLabel,
  downloadInExcelCsvFormat,
  isValidValue,
  requiredHeadersForCSV,
  uploadCSVAlertLabel,
} from "../CommonComponents/utils";
import { API_URL } from "../../../../store/actions/constants";
import { VisuallyHiddenInput } from "./PopTableStyle";


export const PopUptableWrapper = ({ isOpen, setIsOpen }) => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();
  const { uid } = useSelector((state: any) => state.AssortmentFiltersReducer);
  const [downloadCSVData, setDownloadCSVData] = useState<
    { [key: string]: any }[] | null
  >(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("second");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loaderText, setLoaderText] = useState("");
  const { loaderForCsvDownload, loaderForCsvUpload } = useSelector(
    (state: any) => state.AssortmentFiltersReducer
  );

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setLoaderText(uploadCSVAlertLabel);
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      let id = uid;
      const formData = new FormData();

      const reader = new FileReader();

      reader.onload = (e) => {
        const csvText = e?.target?.result as string;

        if (csvText) {
          const jsonData = convertCsvToJson(csvText);
          if (jsonData.length === 0) {
            setIsError(true);
            seterrorMessage(`Incorrect file format! Uploaded file is empty`);
          } else {
            if (validateCsv(jsonData, downloadCSVData)) {
              formData.append("CsvFile", file);
              formData.append("uid", id);

              dispatch(fetchAssortmentUploadCSVDataRequest());
              dispatch(
                fetchApi(
                  formData,
                  API_URL.ASSORTMENT.UPDATE_CSV_FILE_IN_OUTPUT_TABLE,
                  null
                )
              )
                .then((res) => {
                  dispatch(setIsCsvUploaded(true));
                  dispatch(
                    toast("File upload successfully!", true, 3000, "success")
                  );
                  setIsOpen(false);
                })
                .catch((err) => {
                  dispatch(fetchAssortmentUploadCSVDataFailure(err.data));
                  dispatch(
                    toast(
                      "Something went wrong, please try again later.",
                      true,
                      3000,
                      "error"
                    )
                  );
                  setIsOpen(false);
                });
            }
          }
        }
      };

      reader.readAsText(file);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateCsv = (jsonData, originalData) => {
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
      return handleError(`Incorrect file format!`);
    }
  
    const csvHeaders = Object.keys(jsonData[0]);
    if (!validateHeaders(csvHeaders)) {
      return handleError(
        `Incorrect file format! Please upload it with same format as Downloaded file.`
      );
    }
  
    for (let i = 0; i < jsonData.length; i++) {
      if (!validateRow(jsonData[i], originalData[i], i)) {
        return false;
      }
    }
  
    return true;
  };
  
  const validateHeaders = (csvHeaders) => {
    const missingHeaders = requiredHeadersForCSV.filter(
      (header) => !csvHeaders.includes(header)
    );
  
    if (missingHeaders.length > 0) {
      return false;
    }
    return true;
  };
  
  const validateRow = (row, originalRow, rowIndex) => {
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        if (!validateCell(row[key], key, rowIndex, originalRow)) {
          return false;
        }
      }
    }
    return true;
  };
  
  const validateCell = (value, key, rowIndex, originalRow) => {
    if (key.trim() !== "SkuTypeFlag" && !isValidValue(value)) {
      return handleError(
        `Incorrect file format! Error:'${key}' is empty in row ${rowIndex + 2}`
      );
    }
  
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      key.trim() === "SkuTypeFlag" &&
      !allowedValuesForCSV.includes(value)
    ) {
      return handleError(
        `Incorrect file format! Error:'${key}' has wrong value '${value}' in row ${
          rowIndex + 2
        }. Accepted values for SkuTypeFlag are "Must-Have", "Good-to-Have", "Delist", "Festival","Innovation", "No Flag","Others"`
      );
    }
  
    if (key !== "SkuTypeFlag" && value !== originalRow[key]) {
      return handleError(
        `Incorrect file format! Error:'${key}' has wrong value '${value}' in row ${
          rowIndex + 2
        }`
      );
    }
  
    return true;
  };
  
  const handleError = (message) => {
    setIsError(true);
    seterrorMessage(message);
    return false;
  };
  

  const downloadExcel = () => {
    setIsError(false);
    setLoaderText(downloadCSVAlertLabel);

    let payload = { uid: uid };
    dispatch(fetchAssortmentManualCSVDataRequest());
    dispatch(
      fetchApi(
        payload, API_URL.ASSORTMENT.DOWNLOAD_CSV_FILE_FROM_ASSORTMENT_SIMULATION_OUTPUT_TABLE,
        null
      )
    )
      .then((res) => {
        const data = res.data.assortmentSimulationOutputData;
        setDownloadCSVData(data);
        dispatch(fetchAssortmentManualCSVDataSuccess(data));
        const csvData = convertToCSV(data);
        downloadInExcelCsvFormat(csvData, 'download_manual_sku_table')
      })
      .catch((err) => {
        dispatch(fetchAssortmentManualCSVDataFailure(err.message));
        dispatch(
          toast(
            "Something went wrong, please try again later.",
            true,
            3000,
            "error"
          )
        );
      });
  };

  const closeDialog = () => {
    setIsError(false);
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      fullWidth
      maxWidth="md"
      sx={{
        margin: "0 auto",
        width: "40%",
        height: "90vh",
        maxHeight: "90vh",
        minHeight: "90vh",
        overflow: "hidden",
        padding: "1%",
      }}
    >
      <DialogTitle
        style={{ position: "relative" }}
        sx={{
          backgroundColor: "#F1F5FE",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Add Manual SKU Type
        <img
          style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
          src={closeIcon}
          alt="X"
          onClick={closeDialog}
        />
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#F1F5FE" }}>
        <Grid>
          {loaderForCsvDownload || loaderForCsvUpload ? (
            <div style={{ height: "100px", marginTop: "90px" }}>
              <Grid style={{ position: "relative" }}>
                <Spinner
                  position="absolute"
                  show={loaderForCsvDownload || loaderForCsvUpload}
                  text={loaderText}
                />
              </Grid>
            </div>
          ) : (
            <Grid
              container
              sx={{ marginTop: "2rem" }}
              display="flex"
              justifyContent={"center"}
              gap={"3rem"}
            >
              <PrimaryBtn color="primary" onClick={downloadExcel}>
                <FileDownloadOutlinedIcon fontSize="small" />
                Download
              </PrimaryBtn>
              <PrimaryBtn component="label" color="primary">
                <FileUploadOutlinedIcon fontSize="small" />
                Upload File
                <VisuallyHiddenInput
                  ref={fileInputRef}
                  onChange={handleUpload}
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </PrimaryBtn>
              <Typography style={{}}>
                <span style={{ fontSize: 10, fontWeight: 700, color: "black" }}>
                  *NOTE:{" "}
                </span>
                <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
                  Please download the file before uploading it.
                </span>
                <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
                  {" "}
                  Use SKU type flag to override Model Output.
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
        {isError && (
          <Typography style={{ color: "red", marginTop: "1rem" }}>
            {" "}
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
