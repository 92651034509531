import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, TableBody, Skeleton } from '@mui/material';
import { StyledTable, StyledTableCell, StyledTableRow } from './packEconTable.Styled';
import { formatNumber } from '../../../../util/helper';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  stickyPosition: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#D3D3D3',
  },
}));

const PackEconTable: React.FC<{ data; showSkeleton?: boolean; titleWidth?; headerMinWidth?; headerWidth?; isFormatNumber?: boolean }> = ({
  data,
  showSkeleton,
  titleWidth = '85%',
  headerMinWidth = 150,
  headerWidth = 150,
  isFormatNumber = true,
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <StyledTable stickyHeader aria-label="sticky table">
        {!showSkeleton ? (
          <TableBody>
            {data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  variant="head"
                  className={classes.stickyPosition}
                  style={{ minWidth: headerMinWidth, width: headerWidth }}
                >
                  {item.title}
                </StyledTableCell>
                {item.values && item.values.length > 0
                  ? item.values.map((value) => (
                      <StyledTableCell align="center" colSpan={value.colSpan} style={{ minWidth: 100, width: 100 }}>
                        {_.isNumber(value) && isFormatNumber ? formatNumber(value) : value}
                      </StyledTableCell>
                    ))
                  : i === 0 && <StyledTableCell rowSpan={data.length} style={{ width: titleWidth }} />}
              </StyledTableRow>
            ))}
          </TableBody>
        ) : (
          <>
            <TableBody>
              {data.map((item) => (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" variant="head" className={classes.stickyPosition}>
                    <Skeleton width={150} />
                  </StyledTableCell>
                  {item.values.map((value) => (
                    <StyledTableCell align="center" colSpan={value.colSpan}>
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        )}
      </StyledTable>
    </TableContainer>
  );
};

export default PackEconTable;
