import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    styled,
} from "@mui/material";
import { closeIcon } from "../../../../assets/icons/common";
import { toast } from "../../../../store/actions/toast.action";
import { PrimaryBtn } from '../../../../styles/Common.Styled';



const SaveChangesPopup = ({ isOpen, setIsOpen, onYes, onNo }) => {
    const onCloseHandler = () => {
        setIsOpen(false);
    };

    const saveChanges = () => {
        if (onYes) {
          onYes();
        }
        onCloseHandler();
      };
    
      const changeFilters = () => {
        if (onNo) {
          onNo();
        }
        onCloseHandler();
      };

    const closeDialog = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onCloseHandler}
                fullWidth={true}
                maxWidth={"sm"}
                sx={{
                    "& .MuiDialog-paper": {
                        height: "30vh",
                    },
                    zIndex: 900
                }}
            >
                <DialogTitle
                    style={{ position: "relative" }}
                    sx={{
                        backgroundColor: "#F1F5FE",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    Save Changes
                    <img
                        style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
                        src={closeIcon}
                        alt="X"
                        onClick={closeDialog}
                    />
                </DialogTitle>
                <DialogContent style={{ backgroundColor: "#F1F5FE", height: "100px" }}>
                <div>Seems you have changed the data in table,<br></br> Do you want to save it?</div>
                    <Grid
                        container
                        sx={{ marginTop: "3rem" }}
                        display="flex"
                        justifyContent={"center"}
                        gap={"3rem"}
                    >
                        
                        <PrimaryBtn color="primary" onClick={saveChanges}>
                            Yes
                        </PrimaryBtn>
                        <PrimaryBtn color="primary" onClick={changeFilters}>
                            No
                        </PrimaryBtn>
                </Grid>
            </DialogContent>
        </Dialog>

    </div >
  )
}

export default SaveChangesPopup