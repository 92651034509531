import React from "react";

import {
  sidedrawerItems,
  sidedrawerBottomItems,
} from "../../../util/menuItems";
import AppMenuItem from "./AppMenuItems";
import { Box, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { DrawerList } from "./AppMenu.Styled";
import { checkPermission } from "../../../util/helper";

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
});

const AppMenu: React.FC<{ drawerState }> = ({ drawerState }) => {
  const classes = useStyles();
  return (
    <DrawerList className={classes.container}>
      <Box>
        {sidedrawerItems.map((item, index) => (
          <AppMenuItem
            {...item}
            isAccess={checkPermission(item.key)}
            key={`mainMenu${index}`}
            drawerState={drawerState}
          />
        ))}
        <Divider />
      </Box>
      <Box className={classes.footer}>
        <Box style={{ height: 10 }} />
        {sidedrawerBottomItems.map((item, index) => (
          <AppMenuItem
            {...item}
            isAccess={checkPermission(item.key)}
            key={`bottomMenu${index}`}
            drawerState={drawerState}
          />
        ))}
      </Box>
    </DrawerList>
  );
};

export default AppMenu;
