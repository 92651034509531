import { DATA_SOURCE_TABLE_DATA } from "./constants";

let intiState = {
  dataSourceLoader: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case DATA_SOURCE_TABLE_DATA:
      return {
        ...state,
        dataSourceLoader: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
