import React, { useState, useEffect } from 'react';
import { Grid, Skeleton } from '@mui/material';
import Dropdown from '../Dropdown';
import { DropdownTitle } from '../DashboardFilters/DashboardFilters.Styled';
import { fetchCompetitorProductFiltersData, fetchProductFiltersData, fetchAnchorFiltersData } from '../../util/services';
import {
  mapFilterOptions,
  selectAllOptions,
  formatValues,
  disabledFilters,
  disabledAnchorFilters as disabledAnchorFiltersHandler,
  formatAnchorValues,
} from '../../util/helper';
import _ from 'lodash';
import { IDefaultFiltersAPIPayload } from '../../types/common';

const competitorFilterInitialState = {
  selectedFilters: {
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  },
  disableFilters: {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  },
};
const productFilterInitialState = {
  selectedFilters: {
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  },
  disableFilters: {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  },
};

const anchorFilterInitialState = {
  selectedFilters: {
    anchorCategory: null,
    anchorSegment: null,
    anchorBrand: null,
    anchorSubBrand: null,
    anchorPackSize: null,
  },
  disableFilters: {
    anchorCategory: true,
    anchorSegment: true,
    anchorBrand: true,
    anchorSubBrand: true,
    anchorPackSize: true,
  },
};
const CustomDropDownTitle: React.FC<{ title: string }> = ({ title }) => {
  return <DropdownTitle dangerouslySetInnerHTML={{ __html: title }} />;
};

const ProductFiltersV2: React.FC<{
  data;
  callback?;
  onChange;
  showSkeleton?: boolean;
  hideProductFilter?: boolean;
  hideAnchorFilter?: boolean;
  hideCompetitorFilter?: boolean;
  defaultProductFilters?;
  defaultAnchorFilters?;
  defaultCompetitorFilters?;
  disabledProductFilters?;
  disabledAnchorFilters?;
  disabledCompetitorFilters?;
  onChangeLoader;
  selectedGeoFilters;
  clearFilter;
}> = ({
  data,
  callback,
  onChange,
  showSkeleton,
  hideProductFilter = false,
  hideAnchorFilter = false,
  hideCompetitorFilter = false,
  defaultProductFilters,
  defaultAnchorFilters,
  defaultCompetitorFilters,
  disabledProductFilters,
  disabledAnchorFilters,
  disabledCompetitorFilters,
  onChangeLoader,
  selectedGeoFilters,
  clearFilter,
}) => {
  const [disableProductFilter, setDisableFilter] = React.useState({
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  });
  const [disableAnchorFilter, setDisableAnchorFilter] = React.useState({
    anchorCategory: true,
    anchorSegment: true,
    anchorBrand: true,
    anchorSubBrand: true,
    anchorPackSize: true,
  });
  const [competitorFilter, setcompetitorFilter] = useState(data?.competitorFilter);
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] = useState({ ...competitorFilterInitialState.selectedFilters });
  const [disableCompetitorFilters, setDisableCompetitorFilters] = useState({ ...competitorFilterInitialState.disableFilters });

  const [productFilter, setProductrFilter] = useState(data?.productFilter);
  const [selectedProductFilters, setSelectedProductFilters] = useState({ ...productFilterInitialState.selectedFilters });
  const [disableProductFilters, setDisableProductFilters] = useState({ ...productFilterInitialState.disableFilters });

  const [anchorFilter, setAnchorFilter] = useState(data?.anchorFilter);
  const [selectedAnchorFilters, setSelectedAnchorFilters] = useState({ ...anchorFilterInitialState.selectedFilters });
  const [disableAnchorFilters, setDisableAnchorFilters] = useState({ ...anchorFilterInitialState.disableFilters });

  const [defaultProductFilterAPICalls, setDefaultProductFilterAPICalls] = useState<IDefaultFiltersAPIPayload[]>([]);

  const formatDefaultFilters = (keys, defaultFilters) => {
    let defaultFilterAPIPayload: IDefaultFiltersAPIPayload[] = [];
    keys.forEach((key) => {
      if (defaultFilters[key]?.length > 0) {
        defaultFilterAPIPayload.push({
          name: key,
          value: defaultFilters[key],
          apiCalled: false,
          key: key
        });
      }
    });
    return defaultFilterAPIPayload;
  };

  const onChangeCompetitorFilter = (key, value) => {
    const filterValues = { ...selectedCompetitorFilters };
    const disableFilter = { ...disableCompetitorFilters };
    const result = formatValues(key, value, filterValues, disableFilter);

    setSelectedCompetitorFilters({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== 'packSize' && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
        fetchCompetitorProductFilters({ ...result.filterValues, [key]: value }, []);
      } else {
        onChange({
          type: 'COMPETITOR_FILTER',
          filters: { ...result.filterValues, [key]: value },
        });
      }
    }
    setDisableCompetitorFilters({ ...result.disableFilter });
  };
  const onChangeProductOptions = (key, value) => {
    const filterValues = { ...selectedProductFilters };
    const disableFilter = { ...disableProductFilters };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedProductFilters({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== 'packSize' && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
        fetchProductFilters({ ...result.filterValues, [key]: value }, []);
      } else {
        onChange({
          type: 'PRODUCT_FILTER',
          filters: { ...result.filterValues, [key]: value },
        });
      }
    }
    setDisableProductFilters({ ...result.disableFilter });
  };

  // const onChangeCompetitorFilter = (key, value) => {
  //   const filterValues = { ...selectedCompetitorFilters };
  //   const disableFilter = { ...disableCompetitorFilters };
  //   const result = formatValues(key, value, filterValues, disableFilter);
  //   setSelectedCompetitorFilters({ ...result.filterValues, [key]: value });
  //   if (value && value.length > 0) {
  //     if (key !== 'packSize' && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
  //       fetchCompetitorProductFilters({ ...result.filterValues, [key]: value });
  //     }
  //     else {
  //       onChange({
  //         type: "COMPETITOR_FILTER",
  //         filters: {...result.filterValues, [key]: value },
  //       });
  //     }
  //   }
  //   setDisableCompetitorFilters({ ...result.disableFilter });
  // }
  // const onChangeProductOptions = (key, val) => {
  //   onChange({
  //     type: "PRODUCT_FILTER",
  //     filters: { [key]: val },
  //   });
  // }
  const onChangeAnchorOptions = (key, value) => {
    const filterValues = { ...selectedAnchorFilters };
    const disableFilter = { ...disableAnchorFilters };
    const result = formatAnchorValues(key, value, filterValues, disableFilter);
    setSelectedAnchorFilters({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== 'anchorPackSize' && ((_.isArray(value) && value.length > 0) || (_.isString(value) && value !== null))) {
        fetchAnchorFilters({ ...result.filterValues, [key]: value }, []);
      } else {
        onChange({
          type: 'ANCHOR_FILTER',
          filters: { ...result.filterValues, [key]: value },
        });
      }
    }
    setDisableAnchorFilters({ ...result.disableFilter });
  };

  // const onChangeAnchorOptions = (key, val) => {
  //   onChange({
  //     type: 'ANCHOR_FILTER',
  //     filters: { [key]: val },
  //   });
  // };

  const fetchCompetitorProductFilters = (payload, defaultCompetitorFilterAPICallsPayload) => {
    if (selectedGeoFilters.country) {
      onChangeLoader(true);
      fetchCompetitorProductFiltersData({
        country: selectedGeoFilters.country,
        ...payload,
        productFilterFlag: 'competitor',
      })
        .then((response) => {
          let tempProductFilter = { ...competitorFilter };
          if (response && response.data) {
            tempProductFilter = mapFilterOptions(response, data.competitorFilter);
            setcompetitorFilter(tempProductFilter);
            setSelectedCompetitorFilters({
              ...payload,
              ...selectAllOptions(response, data.competitorFilter),
            });
            onChange({
              type: 'COMPETITOR_FILTER',
              filters: { ...payload, ...selectAllOptions(response, data.competitorFilter) },
            });
          }
          onChangeLoader(false);

          const index = defaultCompetitorFilterAPICallsPayload.findIndex((filtertemp) => !filtertemp.apiCalled);

          if (index !== -1) {
            defaultCompetitorFilterAPICallsPayload[index].apiCalled = true;
            //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
            const key = defaultCompetitorFilterAPICallsPayload[index].name;
            const value = defaultCompetitorFilterAPICallsPayload[index]['value'];
            const result = disabledFilters({ [key]: value });
            setDisableCompetitorFilters({ ...result });
            if (defaultCompetitorFilterAPICallsPayload[index].name !== 'packSize')
              fetchCompetitorProductFilters({ ...payload, [key]: value }, defaultCompetitorFilterAPICallsPayload);
            else {
              setSelectedCompetitorFilters({
                ...{
                  ...payload,
                  ...selectAllOptions(response, data.competitorFilter),
                },
                [key]: value,
              });
            }
          }
        })
        .catch((e) => {
          onChangeLoader(false);
        });
    }
  };

  const fetchProductFilters = (payload, defaultProductFilterAPICallsPayload) => {
    if (selectedGeoFilters.country) {
      onChangeLoader(true);
      fetchProductFiltersData({
        country: selectedGeoFilters.country,
        ...payload,
      })
        .then((response) => {
          let tempProductFilter = { ...productFilter };
          if (response && response.data) {
            tempProductFilter = mapFilterOptions(response, data.productFilter);
            setProductrFilter(tempProductFilter);
            setSelectedProductFilters({
              ...payload,
              ...selectAllOptions(response, data.productFilter),
            });
            onChange({
              type: 'PRODUCT_FILTER',
              filters: { ...payload, ...selectAllOptions(response, data.productFilter) },
            });
            onChangeLoader(false);

            const index = defaultProductFilterAPICallsPayload.findIndex((filtertemp) => !filtertemp.apiCalled);
            if (index !== -1) {
              defaultProductFilterAPICallsPayload[index].apiCalled = true;
              //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
              const key = defaultProductFilterAPICallsPayload[index].name;
              const value = defaultProductFilterAPICallsPayload[index]['value'];
              const result = disabledFilters({ [key]: value });
              setDisableProductFilters({ ...result });
              if (defaultProductFilterAPICallsPayload[index].name !== 'packSize')
                fetchProductFilters({ ...payload, [key]: value }, defaultProductFilterAPICallsPayload);
              else {
                setSelectedProductFilters({
                  ...{
                    ...payload,
                    ...selectAllOptions(response, data.productFilter),
                  },
                  [key]: value,
                });
              }
            }
          }
        })
        .catch((e) => {
          onChangeLoader(false);
        });
    }
  };

  const fetchAnchorFilters = (payload, defaultAnchorFilterAPICallsPayload) => {
    if (selectedGeoFilters.country) {
      onChangeLoader(true);
      fetchAnchorFiltersData({
        country: selectedGeoFilters.country,
        ...payload,
      })
        .then((response) => {
          let tempAnchorFilter = { ...anchorFilter };
          if (response && response.data) {
            tempAnchorFilter = mapFilterOptions(response, data.anchorFilter);
            setAnchorFilter(tempAnchorFilter);
            setSelectedAnchorFilters({
              ...payload,
              ...selectAllOptions(response, data.anchorFilter),
            });
            onChange({
              type: 'ANCHOR_FILTER',
              filters: { ...payload, ...selectAllOptions(response, data.anchorFilter) },
            });
            onChangeLoader(false);
            const index = defaultAnchorFilterAPICallsPayload.findIndex((filtertemp) => !filtertemp.apiCalled);
            if (index !== -1) {
              defaultAnchorFilterAPICallsPayload[index].apiCalled = true;
              //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
              const key = defaultAnchorFilterAPICallsPayload[index].name;
              const value = defaultAnchorFilterAPICallsPayload[index]['value'];
              const result = disabledAnchorFiltersHandler({ [key]: value });
              setDisableAnchorFilters({ ...result });
              if (defaultAnchorFilterAPICallsPayload[index].name !== 'anchorPackSize')
                fetchAnchorFilters({ ...payload, [key]: value }, defaultAnchorFilterAPICallsPayload);
              else {
                setSelectedAnchorFilters({
                  ...{
                    ...payload,
                    ...selectAllOptions(response, data.anchorFilter),
                  },
                  [key]: value,
                });
              }
            }
          }
        })
        .catch((e) => {
          onChangeLoader(false);
        });
    }
  };
  // useEffect(() => {
  //   if (defaultProductFilters && _.isObject(defaultProductFilters) && Object.keys(defaultProductFilters).length > 0) {
  //     const defaultFiltersAPICallPayload = formatDefaultFilters(
  //       Object.keys(productFilterInitialState.selectedFilters),
  //       defaultProductFilters
  //     );
  //     setDefaultProductFilterAPICalls(defaultFiltersAPICallPayload);
  //   }
  // }, [defaultProductFilters]);

  useEffect(() => {
    if (defaultCompetitorFilters) {
      setSelectedCompetitorFilters(defaultCompetitorFilters);
    }
  }, [defaultCompetitorFilters]);

  // useEffect(() => {
  //   if (defaultProductFilters) {
  //     setSelectedProductFilters(defaultProductFilters);
  //   }
  // }, [defaultProductFilters]);

  // useEffect(() => {
  //   if (defaultAnchorFilters) {
  //     setSelectedAnchorFilters(defaultAnchorFilters);
  //   }
  // }, [defaultAnchorFilters]);

  React.useEffect(() => {
    setDisableFilter(disabledProductFilters);
  }, [disabledProductFilters]);

  React.useEffect(() => {
    setDisableAnchorFilter(disabledAnchorFilters);
  }, [disabledAnchorFilters]);

  React.useEffect(() => {
    if (disabledCompetitorFilters) {
      setDisableCompetitorFilters(disabledCompetitorFilters);
    }
  }, [disabledCompetitorFilters]);

  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters?.country) {
    
      if (!hideCompetitorFilter) {
        let defaultCompetitorFilterAPICallsPayload: any = [];
        if (defaultCompetitorFilters && _.isObject(defaultCompetitorFilters) && Object.keys(defaultCompetitorFilters).length > 0) {
          defaultCompetitorFilterAPICallsPayload = formatDefaultFilters(
            Object.keys(anchorFilterInitialState.selectedFilters),
            defaultCompetitorFilters
          );
        }
        defaultCompetitorFilterAPICallsPayload = defaultCompetitorFilterAPICallsPayload.map((data) => {
          let field = data.name.split('anchor').join('');
          field = field.charAt(0).toLowerCase() + field.slice(1);
          return {
            ...data,
            name: field,
          };
        });
        Object.keys(data.competitorFilter).map((key) => (data.competitorFilter[key].options = [])); //Clear the competitor data
        setDisableCompetitorFilters({ ...competitorFilterInitialState.disableFilters, category: false });
        fetchCompetitorProductFilters({ ...competitorFilterInitialState.selectedFilters }, defaultCompetitorFilterAPICallsPayload);
      }
      if (!hideProductFilter) {
        let defaultProductFilterAPICallsPayload: any = [];
        if (defaultProductFilters && _.isObject(defaultProductFilters) && Object.keys(defaultProductFilters).length > 0) {
          defaultProductFilterAPICallsPayload = formatDefaultFilters(Object.keys(productFilterInitialState.selectedFilters), defaultProductFilters);
        }

        Object.keys(data.productFilter).map((key) => (data.productFilter[key].options = [])); //Clear the competitor data
        setDisableProductFilters({ ...productFilterInitialState.disableFilters, category: false });
        fetchProductFilters({ ...productFilterInitialState.selectedFilters }, defaultProductFilterAPICallsPayload);
      }
      if (!hideAnchorFilter) {
        let defaultAnchorFilterAPICallsPayload: any = [];
        if (defaultAnchorFilters && _.isObject(defaultAnchorFilters) && Object.keys(defaultAnchorFilters).length > 0) {
          defaultAnchorFilterAPICallsPayload = formatDefaultFilters(Object.keys(anchorFilterInitialState.selectedFilters), defaultAnchorFilters);
        }
        Object.keys(data.anchorFilter).map((key) => (data.anchorFilter[key].options = [])); //Clear the competitor data
        setDisableAnchorFilters({ ...anchorFilterInitialState.disableFilters, anchorCategory: false });
        fetchAnchorFilters({ ...anchorFilterInitialState.selectedFilters }, defaultAnchorFilterAPICallsPayload);
      }
    }
    if (!hideProductFilter) {
      let defaultProductFilterAPICallsPayload: any = [];
      if (defaultProductFilters && _.isObject(defaultProductFilters) && Object.keys(defaultProductFilters).length > 0) {
        defaultProductFilterAPICallsPayload = formatDefaultFilters(Object.keys(productFilterInitialState.selectedFilters), defaultProductFilters);
      }
      Object.keys(data.productFilter).map((key) => (data.productFilter[key].options = [])); //Clear the competitor data
      setDisableProductFilters({ ...productFilterInitialState.disableFilters, category: false });
      fetchProductFilters({ ...productFilterInitialState.selectedFilters }, defaultProductFilterAPICallsPayload);
    }
    if (!hideAnchorFilter) {
      let defaultAnchorFilterAPICallsPayload: any = [];
      if (defaultAnchorFilters && _.isObject(defaultAnchorFilters) && Object.keys(defaultAnchorFilters).length > 0) {
        defaultAnchorFilterAPICallsPayload = formatDefaultFilters(Object.keys(anchorFilterInitialState.selectedFilters), defaultAnchorFilters);
      }
      Object.keys(data.anchorFilter).map((key) => (data.anchorFilter[key].options = [])); //Clear the competitor data
      setDisableAnchorFilters({ ...anchorFilterInitialState.disableFilters, anchorCategory: false });
      fetchAnchorFilters({ ...anchorFilterInitialState.selectedFilters }, defaultAnchorFilterAPICallsPayload);
    }
  }, [selectedGeoFilters.country]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedCompetitorFilters({ ...competitorFilterInitialState.selectedFilters });
      setDisableCompetitorFilters({ ...competitorFilterInitialState.disableFilters });
      setSelectedProductFilters({ ...productFilterInitialState.selectedFilters });
      setDisableProductFilters({ ...productFilterInitialState.disableFilters });
      setSelectedAnchorFilters({ ...anchorFilterInitialState.selectedFilters });
      setDisableAnchorFilters({ ...anchorFilterInitialState.disableFilters });
    }
  }, [clearFilter]);
  return (
    <Grid>
      {!showSkeleton ? (
        <>
          {!hideProductFilter && (
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.productFilter.category.title} />
                <Dropdown
                  disabled={disableProductFilters.category}
                  data={data.productFilter.category.options}
                  onChange={(data) => onChangeProductOptions('category', data)}
                  defaultOption={selectedProductFilters.category || ['empty']}
                  placeholder={data.productFilter.category.placeholder}
                  multiple={data.productFilter.category.multiple}
                  allOption={data.productFilter.category.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.productFilter.segment.title} />
                <Dropdown
                  disabled={disableProductFilters.segment}
                  data={data.productFilter.segment.options}
                  onChange={(data) => onChangeProductOptions('segment', data)}
                  defaultOption={selectedProductFilters.segment || ['empty']}
                  placeholder={data.productFilter.segment.placeholder}
                  multiple={data.productFilter.segment.multiple}
                  allOption={data.productFilter.segment.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.productFilter.brand.title} />
                <Dropdown
                  disabled={disableProductFilters.brand}
                  data={data.productFilter.brand.options}
                  onChange={(data) => onChangeProductOptions('brand', data)}
                  defaultOption={selectedProductFilters.brand || ['empty']}
                  placeholder={data.productFilter.brand.placeholder}
                  multiple={data.productFilter.brand.multiple}
                  allOption={data.productFilter.brand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.productFilter.subBrand.title} />
                <Dropdown
                  disabled={disableProductFilters.subBrand}
                  data={data.productFilter.subBrand.options}
                  onChange={(data) => onChangeProductOptions('subBrand', data)}
                  defaultOption={selectedProductFilters.subBrand || ['empty']}
                  placeholder={data.productFilter.subBrand.placeholder}
                  multiple={data.productFilter.subBrand.multiple}
                  allOption={data.productFilter.subBrand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.productFilter.packSize.title} />
                <Dropdown
                  disabled={disableProductFilters.packSize}
                  data={data.productFilter.packSize.options}
                  onChange={(data) => onChangeProductOptions('packSize', data)}
                  defaultOption={selectedProductFilters.packSize || ['empty']}
                  placeholder={data.productFilter.packSize.placeholder}
                  multiple={data.productFilter.packSize.multiple}
                  allOption={data.productFilter.packSize.all}
                />
              </Grid>
            </Grid>
          )}
          {!hideAnchorFilter && (
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.anchorFilter.anchorCategory.title} />
                <Dropdown
                  disabled={disableAnchorFilters.anchorCategory}
                  data={data.anchorFilter.anchorCategory.options}
                  onChange={(data) => onChangeAnchorOptions('anchorCategory', data)}
                  defaultOption={selectedAnchorFilters.anchorCategory || ['empty']}
                  placeholder={data.anchorFilter.anchorCategory.placeholder}
                  multiple={data.anchorFilter.anchorCategory.multiple}
                  allOption={data.anchorFilter.anchorCategory.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.anchorFilter.anchorSegment.title} />
                <Dropdown
                  disabled={disableAnchorFilters.anchorSegment}
                  data={data.anchorFilter.anchorSegment.options}
                  onChange={(data) => onChangeAnchorOptions('anchorSegment', data)}
                  defaultOption={selectedAnchorFilters.anchorSegment || ['empty']}
                  placeholder={data.anchorFilter.anchorSegment.placeholder}
                  multiple={data.anchorFilter.anchorSegment.multiple}
                  allOption={data.anchorFilter.anchorSegment.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.anchorFilter.anchorBrand.title} />
                <Dropdown
                  disabled={disableAnchorFilters.anchorBrand}
                  data={data.anchorFilter.anchorBrand.options}
                  onChange={(data) => onChangeAnchorOptions('anchorBrand', data)}
                  defaultOption={selectedAnchorFilters.anchorBrand || ['empty']}
                  placeholder={data.anchorFilter.anchorBrand.placeholder}
                  multiple={data.anchorFilter.anchorBrand.multiple}
                  allOption={data.anchorFilter.anchorBrand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.anchorFilter.anchorSubBrand.title} />
                <Dropdown
                  disabled={disableAnchorFilters.anchorSubBrand}
                  data={data.anchorFilter.anchorSubBrand.options}
                  onChange={(data) => onChangeAnchorOptions('anchorSubBrand', data)}
                  defaultOption={selectedAnchorFilters.anchorSubBrand || ['empty']}
                  placeholder={data.anchorFilter.anchorSubBrand.placeholder}
                  multiple={data.anchorFilter.anchorSubBrand.multiple}
                  allOption={data.anchorFilter.anchorSubBrand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.anchorFilter.anchorPackSize.title} />
                <Dropdown
                  disabled={disableAnchorFilters.anchorPackSize}
                  data={data.anchorFilter.anchorPackSize.options}
                  onChange={(data) => onChangeAnchorOptions('anchorPackSize', data)}
                  defaultOption={selectedAnchorFilters.anchorPackSize || ['empty']}
                  placeholder={data.anchorFilter.anchorPackSize.placeholder}
                  multiple={data.anchorFilter.anchorPackSize.multiple}
                  allOption={data.anchorFilter.anchorPackSize.all}
                />
              </Grid>
            </Grid>
          )}
          {!hideCompetitorFilter && (
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.competitorFilter.category.title} />
                <Dropdown
                  disabled={disableCompetitorFilters.category}
                  data={data.competitorFilter.category.options}
                  onChange={(data) => onChangeCompetitorFilter('category', data)}
                  defaultOption={selectedCompetitorFilters.category || ['empty']}
                  placeholder={data.competitorFilter.category.placeholder}
                  multiple={data.competitorFilter.category.multiple}
                  allOption={data.competitorFilter.category.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.competitorFilter.segment.title} />
                <Dropdown
                  disabled={disableCompetitorFilters.segment}
                  data={data.competitorFilter.segment.options}
                  onChange={(data) => onChangeCompetitorFilter('segment', data)}
                  defaultOption={selectedCompetitorFilters.segment || ['empty']}
                  placeholder={data.competitorFilter.segment.placeholder}
                  multiple={data.competitorFilter.segment.multiple}
                  allOption={data.competitorFilter.segment.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.competitorFilter.brand.title} />
                <Dropdown
                  disabled={disableCompetitorFilters.brand}
                  data={data.competitorFilter.brand.options}
                  onChange={(data) => onChangeCompetitorFilter('brand', data)}
                  defaultOption={selectedCompetitorFilters.brand || ['empty']}
                  placeholder={data.competitorFilter.brand.placeholder}
                  multiple={data.competitorFilter.brand.multiple}
                  allOption={data.competitorFilter.brand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.competitorFilter.subBrand.title} />
                <Dropdown
                  disabled={disableCompetitorFilters.subBrand}
                  data={data.competitorFilter.subBrand.options}
                  onChange={(data) => onChangeCompetitorFilter('subBrand', data)}
                  defaultOption={selectedCompetitorFilters.subBrand || ['empty']}
                  placeholder={data.competitorFilter.subBrand.placeholder}
                  multiple={data.competitorFilter.subBrand.multiple}
                  allOption={data.competitorFilter.subBrand.all}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <CustomDropDownTitle title={data.competitorFilter.packSize.title} />
                <Dropdown
                  disabled={disableCompetitorFilters.packSize}
                  data={data.competitorFilter.packSize.options}
                  onChange={(data) => onChangeCompetitorFilter('packSize', data)}
                  defaultOption={selectedCompetitorFilters.packSize || ['empty']}
                  placeholder={data.competitorFilter.packSize.placeholder}
                  multiple={data.competitorFilter.packSize.multiple}
                  allOption={data.competitorFilter.packSize.all}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid container spacing={2} className="m-b-20">
          {Object.keys(data.productFilter).map(() => (
            <Grid item xs={12} sm={2}>
              <Skeleton height={22} />
              <Skeleton variant="rectangular" width={210} height={45} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ProductFiltersV2;
