import { CardContent, Divider, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SectionTitle, Title } from "./PentrationLimit.Styled";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { setAssortmentGeoFilterVisibility, setPenetrationLimitLevel, setPenetrationViewMode } from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import { setViewMode } from "../../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import TextFieldInput from "../../CommonComponents/TextFieldInput/TextFieldInput";
import { menuOptionsForPenetrationLimit } from "../../CommonComponents/utils";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
  selectContainer: {
    minWidth: 200,
  },
  verticalDivider: {
    borderLeft: `2px solid black`,
    height: "100%",
  },
}));

const PenetrationLimit = ({ onInputChange, inputValues, setInputValues }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(
    inputValues.chooseLevel || ""
  );

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    onInputChange(fieldName, value);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setInputValues((prevValues) => ({
      ...prevValues,
      chooseLevel: value,
    }));
    dispatch(setPenetrationLimitLevel(value));
    dispatch(setViewMode(false))
    dispatch((setAssortmentGeoFilterVisibility(false)))
    dispatch(setPenetrationViewMode(false))
    onInputChange("chooseLevel", value);
  };

  useEffect(() => {
    setSelectedOption(inputValues.chooseLevel);
  }, [inputValues]);

  return (
    <>
      <CardContent sx={{ backgroundColor: "#F1F5FE", color: "#fff" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title color="black">Add Penetration (Distribution) Limit (in %)</Title>
          </Grid>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item xs={2}>
              <SectionTitle color="black">
                Minimum Penetration (Distribution) for SKU
                <span className={classes.asterik}>*</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={2}>
              <TextFieldInput
                defaultValue={inputValues.minimumPenetrationForSku}
                callback={(value) =>
                  handleInputChange("minimumPenetrationForSku", value)
                }
                disabled={false}
              />
            </Grid>
            <Divider
              color="#08509A"
              sx={{ height: 125, m: 1 }}
              orientation="vertical"
            />
            <Grid flexDirection="row" item xs={2}>
              <SectionTitle color="black">Choose Level</SectionTitle>
            </Grid>
            <Grid item xs={2} className={classes.selectContainer}>
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
                className={classes.selectContainer}
              >
                {menuOptionsForPenetrationLimit.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default PenetrationLimit;
