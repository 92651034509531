
import {
  MIX_SUMMARY_DATA_REQUEST,
  MIX_SUMMARY_DATA_SUCCESS,
  MIX_SUMMARY_DATA_FAILURE,
} from "../../actions/constants";

// Initial state
const initialState = {
  summaryData: null,
  summaryLoader: false,
};

const MixSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case MIX_SUMMARY_DATA_REQUEST:
      return {
        ...state,
        error: null,
        summaryLoader: true,
      };
    
    case MIX_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        summaryData: action.payload,
        summaryLoader: false,
      };
   
    case MIX_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        summaryData: null,
        summaryLoader: false,
        error: action.payload,
      };
        default:
      return state;
  }
};

export default MixSummaryReducer;
