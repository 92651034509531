import { makeStyles } from '@material-ui/core/styles';
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import Row from './Row';
const useStyles = makeStyles(() => ({
  borderClass: {
    borderLeft: '2px solid #D3D3D3',
    borderBottom: '2px solid #D3D3D3',
  },
  widthClass: {
    borderLeft: '2px solid #D3D3D3',
    borderBottom: '2px solid #D3D3D3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '18%',
  },
  pl2: {
    paddingLeft: '10px',
  },
  headerColor: {
    backgroundColor: '#D3D3D3',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  customWidth: {
    width: '7vw !important',
  },
}));
const TableCtnr: React.FC<{
  data: any;
  tableHeader?: any;
  type?: string;
  style?;
  level;
  groupName?;
  showSkeleton?: boolean;
  vendor?: string;
  responseKeys;
}> = ({ data, tableHeader, type, style, level, groupName, showSkeleton, vendor, responseKeys }) => {
  const classes = useStyles();
  let levelData = data[level];
  const groupedData = vendor ? _.groupBy(levelData, (x) => x.vendor && x.parentName) : _.groupBy(levelData, 'parentName');
  if (groupName) {
    if (groupedData[groupName] && vendor) levelData = groupedData[groupName].filter((item) => item.vendor === vendor);
    else levelData = groupedData[groupName];
  }
  levelData = _.sortBy(levelData, (item) => item?.name);

  const isFirstCell = (idx) => idx === 0;
  const header = tableHeader?.map((cell, idx) => {
    return (
      <TableCell
        sx={{ bgcolor: '#D3D3D3' }}
        key={cell?.value}
        align={isFirstCell(idx) ? 'left' : 'center'}
        className={`${classes.headerColor} ${!isFirstCell(idx) && classes.customWidth} ${
          isFirstCell(idx) ? classes.borderClass : classes.widthClass
        }`}
      >
        {cell?.label}
      </TableCell>
    );
  });
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ height: style.height, overflow: style.overflow, width: 'auto' }}
        className={classes.customTableContainer}
      >
        <Table aria-label='collapsible table' stickyHeader>
          <TableHead>
            <TableRow>{header}</TableRow>
          </TableHead>
          {!showSkeleton ? (
            <TableBody>
              {levelData ? (
                levelData?.map((row: any) => (
                  <Row key={row?.name} row={row} data={data} level={level} type={type} responseKeys={responseKeys} style={style} />
                ))
              ) : (
                <Typography className={classes.pl2}>No Data Found</Typography>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {Array.from({ length: 8 }).map(() => (
                <TableRow>
                  {Array.from({ length: tableHeader.length }).map(() => (
                    <TableCell>
                      <Skeleton width='100%' />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <Table></Table>
      </TableContainer>
    </>
  );
};
export default TableCtnr;
