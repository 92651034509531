export const assortmentDataSource = [
  { id: 1, label: "SMKT", value: "RSI" },
  { id: 2, label: "DTS/C-Stores", value: "INASE" },
  { id: 3, label: "WHSL", value: "ISCAM" },
  { id: 4, label: "Industry Data", value: "NST/NRI" },
  { id: 5, label: "Financial Data", value: "BPC" },
];

export const convertCsvToJson = (csvText: string) => {
  const lines = csvText
    .split(/\r\n|\n|\r/)
    .filter((line) => line.trim() !== "");

  if (lines.length <= 1) {
    return [];
  }

  const headers = lines[0].split(",");
  const jsonData: { [key: string]: string | number }[] = [];

  for (let i = 1; i < lines.length; i++) {
    const data = lines[i].split(",");
    const row: { [key: string]: string | number } = {};

    for (let j = 0; j < headers.length; j++) {
      row[headers[j]] = data[j];
    }

    jsonData.push(row);
  }

  return jsonData;
};

export const isValidValue = (value) => {
  return value !== null && value !== undefined && value.trim() !== "";
};

export const convertToCSV = (jsonData) => {
  const header = Object.keys(jsonData[0]).join(",");
  const rows = jsonData.map((obj) => Object.values(obj).join(","));
  return `${header}\n${rows.join("\n")}`;
};

export const hasNullOrBlankValue = (obj) => {
  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key) || key === "") {
      continue;
    }

    const value = obj[key];
    const isValueNullOrBlank = value == null || value === "";

    if (isValueNullOrBlank) {
      return true;
    }
  }
  return false;
};

export const convertToCSVWithKeys = (data, selectedKeys, columns) => {
  const header = selectedKeys
    .map((key) => {
      const column = columns.find((column) => column.field === key);
      return column ? column.headerName : key;
    })
    .join(",");
  const rows = data.map((row) => selectedKeys.map((key) => row[key]).join(","));
  return `${header}\n${rows.join("\n")}`;
};

export const convertToCSVForQuadrant = (data, columns) => {
  const selectedkeys = columns.map((column) => column.key);
  const header = selectedkeys
    .map((key) => {
      const column = columns.find((column) => column.key === key);
      return column ? column.title : key;
    })
    .join(",");
  const rows = data.map((row) => selectedkeys.map((key) => row[key]).join(","));
  return `${header}\n${rows.join("\n")}`;
};

export const requiredHeadersForCSV = [
  "Channel",
  "Region",
  "StoreSegment",
  "Segment",
  "Brand",
  "PackSize",
  "SKU",
  "AssortmentType",
  "SkuTypeFlag",
];

export const allowedValuesForCSV = [
  "",
  " ",
  "\n",
  "Good-to-Have",
  "Good to have",
  "Good to Have",
  "Must-Have",
  "Must have",
  "Must Have",
  "Delist",
  "Festival",
  "Innovation",
  "No Flag",
  "Others",
  "INNOVATION",
];

export const downloadCSVAlertLabel = "Please wait! Downloading CSV File...";
export const uploadCSVAlertLabel = "Please wait! Uploading CSV File...";

export const defaultFiltersForQuadrant = [
  {
    id: "xAxis",
    label: "Add X-axis",
    options: [
      "Weighted score",
      "Penetration",
      "Revenue",
      "Profit",
      "Profit margin",
      "NO PBT",
      "Volume",
    ],
  },
  {
    id: "yAxis",
    label: "Add Y-axis",
    options: [
      "Weighted score",
      "Penetration",
      "Revenue",
      "Profit",
      "Profit margin",
      "NO PBT",
      "Volume",
    ],
  },
  {
    id: "bubbleSize",
    label: "Add Bubble Size",
    options: ["Volume"],
  },
];

export const formatValue = (val) => {
  return (Number(val) * 100).toFixed(0) + "%";
};

export const formatInputValue = (val) => {
  return parseFloat((Number(val) * 100 + 10).toFixed(0));
};

export const menuOptionsForPenetrationLimit = [
  { value: "Country", label: "Country" },
  { value: "storeSegment", label: "Store Segment" },
];

export const keysToRemoveInUserInput = [
  "mustHaveSku",
  "mustHaveSkuAvg",
  "goodToHaveSku",
  "goodToHaveSKUAvg",
  "chooseLevel",
  "minimumPenetrationForSku",
];

export const downloadInExcelCsvFormat = (csvData, fileName) => {
  const file = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
