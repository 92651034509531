import { SIMULATION_CURRENT_VALUE_DATA, SIMULATION_PREDICTIVE_VALUE_DATA, VIEW_TYPE_DATA, QUADRANT_INTENSITY } from './constants';

export const simulationCurrentValueDataLoader = (payload) => {
  return {
    type: SIMULATION_CURRENT_VALUE_DATA,
    payload,
  };
};

export const simulationPredictiveValueDataLoader = (payload) => {
  return {
    type: SIMULATION_PREDICTIVE_VALUE_DATA,
    payload,
  };
};

export const viewTypeDataLoader = (payload) => {
  return {
    type: VIEW_TYPE_DATA,
    payload,
  };
};

export const quadrantIntensityLoader = (payload) => {
  return {
    type: QUADRANT_INTENSITY,
    payload,
  };
};
