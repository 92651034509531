import * as React from 'react';
import { VectorMap } from '@react-jvectormap/core';
import _ from 'lodash';
import { countries } from '../../../../mocks/common';

const Map: React.FC<{ defaultCountries; onChange; map; region }> = ({
  defaultCountries,
  onChange,
  map,
  region,
}) => {
  const [isShowMap, setIsShowMap] = React.useState(true);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const handleMapClick = (
    e: JQuery.Event,
    c: string,
    i: boolean,
    selectedRegions: string[]
  ) => {
    const index = countries.findIndex((x) => x.initial === c);
    if (index !== -1) {
      setSelectedCountries(selectedRegions);
      onChange({ region, selectedRegions });
    }
  };

  React.useEffect(() => {
    if (
      _.isArray(defaultCountries) &&
      selectedCountries.length !== defaultCountries.length
    ) {
      setIsShowMap(false);
      setSelectedCountries(defaultCountries);
      setTimeout(() => {
        setIsShowMap(true);
      });
    }
  }, [defaultCountries, selectedCountries]);

  return (
    <>
      {isShowMap && (
        <VectorMap
          map={map}
          backgroundColor='transparent' //change it to ocean blue: #0077be
          zoomOnScroll={false}
          panOnDrag={true}
          style={{ width: '100%', height: '100px' }}
          onRegionSelected={handleMapClick} //gets the country code
          regionStyle={{
            initial: {
              fill: '#e4e4e4',
              stroke: 'none',
            },
            hover: {
              cursor: 'pointer',
            },
            selected: {
              fill: '#2938bc', //color for the clicked country
            },
            selectedHover: {},
          }}
          regionsSelectable={false}
          selectedRegions={selectedCountries}
        />
      )}
    </>
  );
};

export default Map;
