import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import {
  BlackTitle,
  OrangeBtn,
  PrimaryBtn,
} from "../../../../styles/Common.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import { getMultipleApiProducts, getProductFilters } from "../../service";
import {
  disabledFiltersWithVendor,
  enableCompetitorFilter,
  formatDefaultFilters,
  mapFilterOptions,
  selectAllOptions,
} from "../../../../util/helper";
import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup/CheckBoxGroup";
import {
  productFilterConfig,
  anchorFilterConfig,
  measureFilter,
  comparisonLevelConfig,
  competitorFilterConfig,
} from "./config";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import FilterAccordion from "../../../../components/Filters/Filters";
import Indicator from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import _ from "lodash";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
const initialComparisonMapping = {
  vendor: "Vendor",
  category: "Category",
  segment: "Segment",
  brand: "Brand",
  subbrand: "Sub Brand",
  packsize: "Pack Size",
};
const MultipleAPIFilters: React.FC<{
  data;
  callback;
  globalFilters?;
  defaultFilters?;
}> = ({ data, callback, globalFilters, defaultFilters }) => {
  const [anchorFilter, setAnchorFilter] = useState({ ...anchorFilterConfig });
  const [productFilter, setProductFilter] = useState({
    ...productFilterConfig,
  });
  const [competitorFilter, setCompetitorFilter] = useState(
    competitorFilterConfig
  );
  const [comparison, setComparison] = useState("Default");
  const [clearGeoFilterData, clearGeoFilter] = useState(false);
  const [isSave, setisSave] = useState(true);
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    variable1: measureFilter.api.defaultOption.value,
    variable2: measureFilter.anchorVar.defaultOption.value,
    somMeasure: measureFilter.SOMConfig.defaultOption.value,
    somGroup: measureFilter.anchorVariableConfig.defaultOption.value,
    apiUnits: measureFilter.apiVariableConfig.defaultOption.value,
  });
  const [skeleton, setSkeleton] = useState(true);
  const [loader, setLoader] = useState(false);
  const [selectedGeoFilter, setSelectedGeoFilter] = React.useState({
    country: null,
    geoLevel: null,
    geoLevel2: null,
    channel: null,
  });
  const [selectedAnchorFilter, setSelectedAnchorFilter] = React.useState({
    vendor: null,
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  });
  const [disableAnchorFilter, setDisableAnchorFilter] = React.useState({
    vendor: true,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  });
  const [selectedProductFilter, setSelectedProductFilter] = React.useState({
    vendor: productFilterConfig.vendor.options[0].value,
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  });
  const [disableProductFilter, setDisableProductFilter] = React.useState({
    vendor: true,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  });
  const [selectedCompetitorFilter, setSelectedCompetitorFilter] =
    React.useState({
      vendor: null,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
  const [disableCompetitorFilter, setDisableCompetitorFilter] = React.useState({
    vendor: true,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  });
  const [comparisonLevel, setComparisonLevel] = React.useState({
    vendor: false,
    category: false,
    segment: false,
    brand: false,
    subBrand: false,
    packSize: false,
  });
  useEffect(() => {
    if (
      defaultFilters &&
      _.isObject(defaultFilters.data) &&
      defaultFilters?.type === "local" &&
      Object.values(defaultFilters.data).length > 0 &&
      isSave
    ) {
      let defaultAnchorFIltersPayload: any = [];
      let defaultPepsicoFIltersPayload: any = [];
      let defaultCompetitorFIltersPayload: any = [];
      setMeasureFilter({
        variable1: defaultFilters?.data?.variable1,
        variable2: defaultFilters?.data?.variable2,
        somMeasure: defaultFilters?.data?.somMeasure,
        somGroup: defaultFilters?.data?.somGroup,
        apiUnits:
          defaultFilters?.data?.apiUnits === "Volume"
            ? "KGs ( in '000s )"
            : "Units ( in '000s )",
      });
      setComparison("Default");
      if (
        defaultFilters &&
        _.isObject(defaultFilters.data) &&
        Object.keys(defaultFilters.data).length > 0 &&
        isSave
      ) {
        setTimeout(() => {
          setComparison(
            defaultFilters?.data?.comparisonLevel !== null &&
              defaultFilters?.data?.comparisonLevel !== ""
              ? initialComparisonMapping[defaultFilters?.data?.comparisonLevel]
              : initialComparisonMapping["category"]
          );
        });
        let keys = [
          "vendor",
          "category",
          "segment",
          "brand",
          "subBrand",
          "packSize",
        ];
        const anchorKeys = keys.map(
          (filter) =>
            `anchor${filter.charAt(0).toUpperCase() + filter.slice(1)}`
        );
        const pepsicoKeys = keys;
        const competitorKeys = keys.map(
          (filter) =>
            `competetior${filter.charAt(0).toUpperCase() + filter.slice(1)}`
        );
        defaultAnchorFIltersPayload = formatDefaultFilters(
          anchorKeys,
          defaultFilters.data
        ).map((payload) => {
          let key = payload.name.split("anchor").join("");
          return {
            ...payload,
            name: key.charAt(0).toLowerCase() + key.slice(1),
          };
        });
        defaultPepsicoFIltersPayload = formatDefaultFilters(
          pepsicoKeys,
          defaultFilters.data
        );
        defaultCompetitorFIltersPayload = formatDefaultFilters(
          competitorKeys,
          defaultFilters.data
        ).map((payload) => {
          let key = payload.name.split("competetior").join("");
          return {
            ...payload,
            name: key.charAt(0).toLowerCase() + key.slice(1),
          };
        });
        fetchAnchorFilters(
          { ...selectedAnchorFilter },
          defaultFilters?.data?.country,
          defaultAnchorFIltersPayload
        );
      }
      setLoader(true);
    }
  }, [defaultFilters]);

  let defaultFilterAPIPayload: any = [];

  React.useEffect(() => {
    if (comparison) {
      const temp = {
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      switch (comparison) {
        case "Category":
          temp.vendor = true;
          temp.category = true;
          break;
        case "Segment":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          break;
        case "Brand":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          break;
        case "Sub Brand":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          break;
        case "Pack Size":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          temp.packSize = true;
          break;
      }
      if (
        defaultFilters &&
        _.isObject(defaultFilters.data) &&
        Object.keys(defaultFilters.data).length > 0 &&
        isSave
      ) {
        for (let key in temp) {
          if (temp[key]) {
            if (defaultFilters.data[key]?.length > 0) {
              defaultFilterAPIPayload.push({
                name: key,
                value: defaultFilters.data[key],
                apiCalled: false,
              });
            }
          }
        }
        let competitorTemp = Object.keys(temp).map(
          (key) => `competetior${key.charAt(0).toUpperCase() + key.slice(1)}`
        );
        let defaultCompetitorFilterAPICallsPayload = formatDefaultFilters(
          competitorTemp,
          defaultFilters.data
        );
        defaultCompetitorFilterAPICallsPayload =
          defaultCompetitorFilterAPICallsPayload.map((data) => {
            let field = data.name.split("competetior").join("");
            field = field.charAt(0).toLowerCase() + field.slice(1);
            return {
              ...data,
              name: field,
            };
          });

        fetchProductFilters(
          { ...selectedProductFilter },
          defaultFilters?.data?.country,
          defaultFilterAPIPayload
        );
        fetchCompetiorFilters(
          { ...selectedCompetitorFilter },
          defaultFilters?.data?.country,
          defaultCompetitorFilterAPICallsPayload
        );
      } else {
        setSelectedProductFilter({
          vendor: productFilterConfig.vendor.options[0].value,
          category: null,
          segment: null,
          brand: null,
          subBrand: null,
          packSize: null,
        });

        setDisableProductFilter({
          vendor: true,
          category: false,
          segment: true,
          brand: true,
          subBrand: true,
          packSize: true,
        });

        setSelectedCompetitorFilter({
          vendor: null,
          category: null,
          segment: null,
          brand: null,
          subBrand: null,
          packSize: null,
        });

        setDisableCompetitorFilter({
          vendor: false,
          category: true,
          segment: true,
          brand: true,
          subBrand: true,
          packSize: true,
        });
      }
      setComparisonLevel(temp);
    }
  }, [comparison]);
  React.useEffect(() => {
    if (globalFilters && globalFilters.country) {
      if (
        !(
          defaultFilters &&
          _.isObject(defaultFilters.data) &&
          Object.keys(defaultFilters.data).length > 0
        )
      ) {
        setComparison(comparisonLevelConfig.defaultOption.value);
      }
      setSkeleton(false);
      setSelectedGeoFilter({
        country: globalFilters.country,
        geoLevel: _.isArray(globalFilters.geoLevel)
          ? globalFilters.geoLevel
          : [globalFilters.geoLevel],
        channel: _.isArray(globalFilters.channel)
          ? globalFilters.channel
          : [globalFilters.channel],
        geoLevel2: _.isArray(globalFilters.geoLevel2)
          ? globalFilters.geoLevel2
          : [globalFilters.geoLevel2],
      });

      fetchProductVendors(selectedGeoFilter.country);
      fetchCompetitorVendors(selectedGeoFilter.country);
      setDisableProductFilter({ ...disableProductFilter });
    }
    if (
      !defaultFilters &&
      _.isObject(defaultFilters.data) &&
      Object.keys(defaultFilters.data).length <= 0
    )
      fetchProductFilters({ ...selectedProductFilter }, globalFilters.country);
  }, [globalFilters]);
  const [categoryAPICall, setCategoryAPICall] = useState<boolean>(false);
  React.useEffect(() => {
    if (selectedGeoFilter && selectedGeoFilter.country && !categoryAPICall) {
      clearGeoFilter(false);
      setCategoryAPICall(true);
      fetchProductVendors(selectedGeoFilter.country);
      fetchCompetitorVendors(selectedGeoFilter.country);
      setDisableProductFilter({ ...disableProductFilter, category: false });
      fetchProductFilters(
        { ...selectedProductFilter },
        selectedGeoFilter.country
      );
    }
  }, [selectedGeoFilter.country]);
  const fetchProductVendors = (country) => {
    if (country) {
      setLoader(true);
      getMultipleApiProducts({ country }, "product")
        .then((response) => {
          if (response && response.data) {
            const mappedAnchorFilter = mapFilterOptions(response, {
              ...anchorFilter,
            });
            setAnchorFilter(mappedAnchorFilter);
            setSelectedAnchorFilter({
              ...selectedAnchorFilter,
              ...selectAllOptions(response, anchorFilter),
            });
            setDisableAnchorFilter({ ...disableAnchorFilter, vendor: false });
          }
          setLoader(false);
          setCategoryAPICall(false);
        })
        .catch((e) => {
          setLoader(false);
          setCategoryAPICall(false);
        });
    } else {
      let disableFilter = {
        vendor: true,
        category: true,
        segment: true,
        brand: true,
        subBrand: true,
        packSize: true,
      };
      setDisableAnchorFilter(disableFilter);
    }
  };
  const fetchCompetitorVendors = (country) => {
    if (country) {
      setLoader(true);
      getMultipleApiProducts({ country }, "competitor")
        .then((response) => {
          if (response && response.data) {
            const mappedCompetitorFilter = mapFilterOptions(response, {
              ...competitorFilter,
            });
            setCompetitorFilter(mappedCompetitorFilter);
            setSelectedCompetitorFilter({
              ...selectedCompetitorFilter,
              ...selectAllOptions(response, competitorFilter),
            });
            setDisableCompetitorFilter({
              ...disableCompetitorFilter,
              vendor: false,
            });
          }
          setLoader(false);
          setCategoryAPICall(false);
        })
        .catch((e) => {
          setLoader(false);
          setCategoryAPICall(false);
        });
    } else {
      let disableFilter = {
        vendor: true,
        category: true,
        segment: true,
        brand: true,
        subBrand: true,
        packSize: true,
      };
      setDisableCompetitorFilter(disableFilter);
    }
  };
  const onChangeAnchorFilter = (key, value) => {
    const filterValues = { ...selectedAnchorFilter };
    const disableFilter = { ...disableAnchorFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedAnchorFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== "packSize") {
        fetchAnchorFilters({ ...result.filterValues, [key]: value });
      }
    }
    setisSave(false);
    setDisableAnchorFilter({ ...result.disableFilter });
  };
  const fetchAnchorFilters = (
    payload,
    defaultCountry?,
    defaultAnchorFIltersPayload?
  ) => {
    if (selectedGeoFilter.country || defaultCountry) {
      setLoader(true);
      getMultipleApiProducts(
        { country: selectedGeoFilter.country || defaultCountry, ...payload },
        "anchor"
      )
        .then((response) => {
          let tempAnchorFilter = { ...anchorFilter };
          if (response && response.data) {
            tempAnchorFilter = mapFilterOptions(response, tempAnchorFilter);
          }
          setAnchorFilter(tempAnchorFilter);
          setSelectedAnchorFilter({
            ...payload,
            ...selectAllOptions(response, anchorFilter),
          });
          setLoader(false);
          const index = defaultAnchorFIltersPayload.findIndex(
            (filtertemp) => !filtertemp.apiCalled
          );
          if (index !== -1) {
            defaultAnchorFIltersPayload[index].apiCalled = true;
            //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
            const key = defaultAnchorFIltersPayload[index].name;
            const value = defaultAnchorFIltersPayload[index]["value"];
            const result = disabledFiltersWithVendor({ [key]: value }, false);
            setDisableAnchorFilter({ ...result });
            if (defaultAnchorFIltersPayload[index].name !== "packSize")
              fetchAnchorFilters(
                { ...payload, [key]: value },
                defaultFilters?.data?.country,
                defaultAnchorFIltersPayload
              );
            else {
              setSelectedAnchorFilter({
                ...{
                  ...payload,
                  ...selectAllOptions(response, tempAnchorFilter),
                },
                [key]: value,
              });
            }
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const onChangeProductFilter = (key, value) => {
    const filterValues = { ...selectedProductFilter };
    const disableFilter = { ...disableProductFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedProductFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (
        key !== "packSize" &&
        key.toLowerCase() !== comparison.toLowerCase()
      ) {
        fetchProductFilters({ ...result.filterValues, [key]: value });
      }
    }
    setDisableProductFilter({ ...result.disableFilter });
  };
  const fetchProductFilters = (
    payload,
    country?,
    defaultProductFilterAPICallsPayload?
  ) => {
    if (selectedGeoFilter.country || country) {
      setLoader(true);
      getProductFilters({
        country: selectedGeoFilter.country || country,
        ...payload,
      })
        .then((response) => {
          let tempProductFilter = { ...productFilter };
          if (response && response.data) {
            tempProductFilter = mapFilterOptions(response, productFilter);
            setProductFilter(tempProductFilter);
            setSelectedProductFilter({
              ...payload,
              ...selectAllOptions(response, tempProductFilter),
            });
          }
          setLoader(false);
          const index = defaultProductFilterAPICallsPayload.findIndex(
            (filtertemp) => !filtertemp.apiCalled
          );
          if (index !== -1) {
            defaultProductFilterAPICallsPayload[index].apiCalled = true;
            //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
            const key = defaultProductFilterAPICallsPayload[index].name;
            const value = defaultProductFilterAPICallsPayload[index]["value"];
            const result = disabledFiltersWithVendor({ [key]: value }, false);
            setDisableProductFilter({ ...result });
            if (defaultProductFilterAPICallsPayload[index].name !== "packSize")
              fetchProductFilters(
                { ...payload, [key]: value },
                globalFilters.country,
                defaultProductFilterAPICallsPayload
              );
            else {
              setSelectedProductFilter({
                ...{
                  ...payload,
                  ...selectAllOptions(response, data.productFilter),
                },
                [key]: value,
              });
            }
          }
          setCategoryAPICall(false);
        })
        .catch((e) => {
          setLoader(false);
          setCategoryAPICall(false);
        });
    }
  };
  const onChangeCompetitorFilter = (key, value) => {
    const filterValues = { ...selectedCompetitorFilter };
    const disableFilter = { ...disableCompetitorFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedCompetitorFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (
        key !== "packSize" &&
        key.toLowerCase() !== comparison.toLowerCase()
      ) {
        fetchCompetiorFilters({ ...result.filterValues, [key]: value });
      }
    }
    setDisableCompetitorFilter({ ...result.disableFilter });
  };
  const fetchCompetiorFilters = (
    payload,
    country?,
    defaultCompetitorFilterAPICallsPayload?
  ) => {
    if (selectedGeoFilter.country || country) {
      setLoader(true);
      getMultipleApiProducts(
        { country: selectedGeoFilter.country || country, ...payload },
        "competitor"
      )
        .then((response) => {
          let tempCompetitorFilter = { ...competitorFilter };
          if (response && response.data) {
            tempCompetitorFilter = mapFilterOptions(
              response,
              tempCompetitorFilter
            );
          }
          setCompetitorFilter(tempCompetitorFilter);
          setSelectedCompetitorFilter({
            ...payload,
            ...selectAllOptions(response, competitorFilter),
          });
          setLoader(false);
          const index = defaultCompetitorFilterAPICallsPayload.findIndex(
            (filtertemp) => !filtertemp.apiCalled
          );
          if (index !== -1) {
            defaultCompetitorFilterAPICallsPayload[index].apiCalled = true;
            //setDefaultProductFilterAPICalls(defaultProductFilterAPICallsPayload);
            const key = defaultCompetitorFilterAPICallsPayload[index].name;
            const value =
              defaultCompetitorFilterAPICallsPayload[index]["value"];
            const result = disabledFiltersWithVendor({ [key]: value }, false);
            setDisableCompetitorFilter({ ...result });
            if (
              defaultCompetitorFilterAPICallsPayload[index].name !== "packSize"
            )
              fetchCompetiorFilters(
                { ...payload, [key]: value },
                globalFilters.country,
                defaultCompetitorFilterAPICallsPayload
              );
            else {
              setSelectedCompetitorFilter({
                ...{
                  ...payload,
                  ...selectAllOptions(response, tempCompetitorFilter),
                },
                [key]: value,
              });
            }
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  const formatValues = (key, value, filterValues, disableFilter) => {
    if (key === "vendor") {
      filterValues.category = null;
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;
      disableFilter.category = value === null || value.length === 0;
      disableFilter.segment = true;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "category") {
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;
      disableFilter.segment = value === null || value.length === 0;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "segment") {
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;
      disableFilter.brand = value === null || value.length === 0;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "brand") {
      filterValues.subBrand = null;
      filterValues.packSize = null;
      disableFilter.subBrand = value === null || value.length === 0;
      disableFilter.packSize = true;
    } else if (key === "subBrand") {
      filterValues.packSize = null;
      disableFilter.packSize = value === null || value.length === 0;
    }
    return { filterValues, disableFilter };
  };
  const onApplyFilter = () => {
    callback({
      ...selectedGeoFilter,
      ...selectedMeasureFilters,
      apiUnits:
        selectedMeasureFilters.apiUnits === "KGs ( in '000s )"
          ? "Volume"
          : "Units",
      somMeasure: selectedMeasureFilters.somMeasure,
      somGroup: selectedMeasureFilters.somGroup,
      date: globalFilters.date,
      category: selectedProductFilter.category,
      segment: selectedProductFilter.segment,
      brand: selectedProductFilter.brand,
      subBrand: selectedProductFilter.subBrand,
      packSize: selectedProductFilter.packSize,
      anchorVendor: selectedAnchorFilter.vendor,
      anchorCategory: selectedAnchorFilter.category,
      anchorSegment: selectedAnchorFilter.segment,
      anchorBrand: selectedAnchorFilter.brand,
      anchorSubBrand: selectedAnchorFilter.subBrand,
      anchorPackSize: selectedAnchorFilter.packSize,
      competetiorVendor: selectedCompetitorFilter.vendor,
      competetiorCategory: selectedCompetitorFilter.category,
      competetiorSegment: selectedCompetitorFilter.segment,
      competetiorBrand: selectedCompetitorFilter.brand,
      competetiorSubBrand: selectedCompetitorFilter.subBrand,
      competetiorPackSize: selectedCompetitorFilter.packSize,
      comparisonLevel: comparison.toLowerCase().replace(/ /g, ""),
    });
  };
  const clearFilter = () => {
    anchorFilter.vendor.options = [];
    competitorFilter.vendor.options = [];
    setSelectedGeoFilter({
      country: null,
      geoLevel: null,
      channel: null,
      geoLevel2: null,
    });
    setSelectedAnchorFilter({
      vendor: null,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setDisableAnchorFilter({
      vendor: !(
        selectedGeoFilter.country && anchorFilter.vendor.options.length > 0
      ),
      category: true,
      segment: true,
      brand: true,
      subBrand: true,
      packSize: true,
    });
    setSelectedProductFilter({
      ...selectedProductFilter,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setDisableProductFilter({
      vendor: true,
      category: true,
      segment: true,
      brand: true,
      subBrand: true,
      packSize: true,
    });
    setSelectedCompetitorFilter({
      vendor: null,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setDisableCompetitorFilter({
      vendor: !(
        selectedGeoFilter.country && competitorFilter.vendor.options.length > 0
      ),
      category: true,
      segment: true,
      brand: true,
      subBrand: true,
      packSize: true,
    });
    setComparisonLevel({
      vendor: false,
      category: false,
      segment: false,
      brand: false,
      subBrand: false,
      packSize: false,
    });
    setMeasureFilter({
      variable1: measureFilter.api.defaultOption.value,
      variable2: measureFilter.anchorVar.defaultOption.value,
      somMeasure: measureFilter.SOMConfig.defaultOption.value,
      somGroup: measureFilter.anchorVariableConfig.defaultOption.value,
      apiUnits: measureFilter.apiVariableConfig.defaultOption.value,
    });
    setComparison(comparisonLevelConfig.defaultOption.value);
    setisSave(false);
    clearGeoFilter(true);
  };
  const disableApplyFilter = () => {
    const flag: boolean =
      (selectedMeasureFilters.variable1
        ? selectedMeasureFilters.variable1 === "API"
          ? selectedMeasureFilters.apiUnits === "" ||
            selectedMeasureFilters.apiUnits === undefined
          : false
        : true) ||
      (selectedMeasureFilters.variable2
        ? selectedMeasureFilters.variable2 === "SOM"
          ? selectedMeasureFilters.somGroup === "" ||
            selectedMeasureFilters.somGroup === undefined ||
            selectedMeasureFilters.somMeasure === "" ||
            selectedMeasureFilters.somMeasure === undefined
          : false
        : true) ||
      selectedGeoFilter.country === "" ||
      selectedGeoFilter.country === undefined ||
      (comparison.toLowerCase().replace(/ /g, "") === "category" &&
        (!selectedProductFilter.category ||
          (enableCompetitorFilter(selectedGeoFilter) &&
            !selectedCompetitorFilter.category))) ||
      (comparison.toLowerCase().replace(/ /g, "") === "segment" &&
        (!selectedProductFilter.segment ||
          (enableCompetitorFilter(selectedGeoFilter) &&
            !selectedCompetitorFilter.segment))) ||
      (comparison.toLowerCase().replace(/ /g, "") === "brand" &&
        (!selectedProductFilter.brand ||
          (enableCompetitorFilter(selectedGeoFilter) &&
            !selectedCompetitorFilter.brand))) ||
      (comparison.toLowerCase().replace(/ /g, "") === "subbrand" &&
        (!selectedProductFilter.subBrand ||
          (enableCompetitorFilter(selectedGeoFilter) &&
            !selectedCompetitorFilter.subBrand))) ||
      (comparison.toLowerCase().replace(/ /g, "") === "packsize" &&
        (!selectedProductFilter.packSize ||
          (enableCompetitorFilter(selectedGeoFilter) &&
            !selectedCompetitorFilter.packSize)));
    return flag;
  };
  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    if (type === "variable2" && selectedVal !== "SOM") {
      temp.somGroup = undefined;
      temp.somMeasure = undefined;
    }
    temp[type] = selectedVal;
    setMeasureFilter(temp);
  };
  const multipleAPIDataLoader = useSelector(
    (state: any) => state.ApiPredictive.multipleAPIDataLoader
  );

  useEffect(() => {
    if (loader && !multipleAPIDataLoader) {
      setLoader(false);
    }
  }, [multipleAPIDataLoader]);

  const clearValues = (key) => {
    if (key === "Category") {
      const payloadSelected = {
        segment: null,
        brand: null,
        subBrand: null,
        packSize: null,
      };
      const payloadDisabled = {
        segment: false,
        brand: true,
        subBrand: true,
        packSize: true,
      };
      setSelectedProductFilter({
        ...selectedProductFilter,
        ...payloadSelected,
      });
      setDisableProductFilter({
        ...disableProductFilter,
        ...payloadDisabled,
      });
      setSelectedCompetitorFilter({
        ...selectedCompetitorFilter,
        ...payloadSelected,
      });
      setDisableCompetitorFilter({
        ...disableCompetitorFilter,
        ...payloadDisabled,
      });
    } else if (key === "Segment") {
      const payloadSelected = {
        brand: null,
        subBrand: null,
        packSize: null,
      };
      const payloadDisabled = {
        brand: false,
        subBrand: true,
        packSize: true,
      };
      setSelectedProductFilter({
        ...selectedProductFilter,
        ...payloadSelected,
      });
      setDisableProductFilter({
        ...disableProductFilter,
        ...payloadDisabled,
      });
      setSelectedCompetitorFilter({
        ...selectedCompetitorFilter,
        ...payloadSelected,
      });
      setDisableCompetitorFilter({
        ...disableCompetitorFilter,
        ...payloadDisabled,
      });
    } else if (key === "Brand") {
      const payloadSelected = {
        subBrand: null,
        packSize: null,
      };
      const payloadDisabled = {
        subBrand: false,
        packSize: true,
      };
      setSelectedProductFilter({
        ...selectedProductFilter,
        ...payloadSelected,
      });
      setDisableProductFilter({
        ...disableProductFilter,
        ...payloadDisabled,
      });
      setSelectedCompetitorFilter({
        ...selectedCompetitorFilter,
        ...payloadSelected,
      });
      setDisableCompetitorFilter({
        ...disableCompetitorFilter,
        ...payloadDisabled,
      });
    } else if (key === "Sub Brand") {
      const payloadSelected = {
        packSize: null,
      };
      const payloadDisabled = {
        packSize: false,
      };
      setSelectedProductFilter({
        ...selectedProductFilter,
        ...payloadSelected,
      });
      setDisableProductFilter({
        ...disableProductFilter,
        ...payloadDisabled,
      });
      setSelectedCompetitorFilter({
        ...selectedCompetitorFilter,
        ...payloadSelected,
      });
      setDisableCompetitorFilter({
        ...disableCompetitorFilter,
        ...payloadDisabled,
      });
    }
  };

  useEffect(() => {
    if (!enableCompetitorFilter(globalFilters)) {
      setDisableCompetitorFilter({
        ...disableCompetitorFilter,
        vendor: true,
      });
    }
  }, [selectedGeoFilter, comparison, globalFilters]);

  const reduxState = useSelector((state: any) => state.ApiPredictive);

  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator position="absolute" show={loader || reduxState.multipleAPIDataLoader} />
      <CardContent>
        {!skeleton ? (
          <>
            <FilterAccordion title="Primary Filters">
              <GeoFiltersV2
                data={{ ...geoFilterV2Config }}
                showDatePicker={false}
                onSelectFilters={setSelectedGeoFilter}
                defaultFilters={globalFilters}
                showLoader={setLoader}
                clearFilter={clearGeoFilterData}
              />
            </FilterAccordion>
            <FilterAccordion title="Anchor Filters">
              <Grid container spacing={2} columns={14} className="m-b-20">
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.vendor.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.vendor}
                    data={_.sortBy(anchorFilter.vendor.options, (i) => i.value)}
                    onChange={(val) => onChangeAnchorFilter("vendor", val)}
                    defaultOption={selectedAnchorFilter.vendor || ["empty"]}
                    placeholder={anchorFilter.vendor.placeholder}
                    multiple={anchorFilter.vendor.multiple}
                    allOption={anchorFilter.vendor.all}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.category.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.category}
                    data={anchorFilter.category.options}
                    onChange={(val) => onChangeAnchorFilter("category", val)}
                    defaultOption={selectedAnchorFilter.category || ["empty"]}
                    placeholder={anchorFilter.category.placeholder}
                    multiple={anchorFilter.category.multiple}
                    allOption={anchorFilter.category.all}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.segment.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.segment}
                    data={anchorFilter.segment.options}
                    onChange={(val) => onChangeAnchorFilter("segment", val)}
                    defaultOption={selectedAnchorFilter.segment || ["empty"]}
                    placeholder={anchorFilter.segment.placeholder}
                    multiple={anchorFilter.segment.multiple}
                    allOption={anchorFilter.segment.all}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.brand.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.brand}
                    data={anchorFilter.brand.options}
                    onChange={(val) => onChangeAnchorFilter("brand", val)}
                    defaultOption={selectedAnchorFilter.brand || ["empty"]}
                    placeholder={anchorFilter.brand.placeholder}
                    multiple={anchorFilter.brand.multiple}
                    allOption={anchorFilter.brand.all}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.subBrand.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.subBrand}
                    data={anchorFilter.subBrand.options}
                    onChange={(val) => onChangeAnchorFilter("subBrand", val)}
                    defaultOption={selectedAnchorFilter.subBrand || ["empty"]}
                    placeholder={anchorFilter.subBrand.placeholder}
                    multiple={anchorFilter.subBrand.multiple}
                    allOption={anchorFilter.subBrand.all}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{anchorFilter.packSize.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableAnchorFilter.packSize}
                    data={anchorFilter.packSize.options}
                    onChange={(val) => onChangeAnchorFilter("packSize", val)}
                    defaultOption={selectedAnchorFilter.packSize || ["empty"]}
                    placeholder={anchorFilter.packSize.placeholder}
                    multiple={anchorFilter.packSize.multiple}
                    allOption={anchorFilter.packSize.all}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="m-b-10">
                <Grid item>
                  <BlackTitle className="f-w-5">Measure Filters: </BlackTitle>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="m-b-10">
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{measureFilter.api.title}</DropdownTitle>
                  <RadioButtonGroup
                    key={`measures-api-filters`}
                    color="#000"
                    showButton={measureFilter.api.showRadioButton}
                    data={measureFilter.api.options}
                    defaultOption={selectedMeasureFilters.variable1 || "API"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DropdownTitle>
                    {measureFilter.apiVariableConfig.title}
                  </DropdownTitle>
                  <CheckBoxGroup
                    data={measureFilter.apiVariableConfig.options}
                    color="#000"
                    direction="row"
                    single={true}
                    defaultOption={selectedMeasureFilters.apiUnits}
                    select={(data) => onSelectMeasure("apiUnits", data)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{measureFilter.anchorVar.title}</DropdownTitle>
                  <RadioButtonGroup
                    key={`measures-api-filters`}
                    color="#000"
                    showButton={measureFilter.anchorVar.showRadioButton}
                    data={measureFilter.anchorVar.options}
                    defaultOption={selectedMeasureFilters.variable2}
                    select={(data) => onSelectMeasure("variable2", data)}
                  />
                </Grid>
                <Grid item>
                  <DropdownTitle>
                    {measureFilter.anchorVariableConfig.title}
                  </DropdownTitle>
                  <Grid container>
                    <Grid item className="m-r-10 b-r-1">
                      <CheckBoxGroup
                        data={measureFilter.anchorVariableConfig.options}
                        color="#000"
                        direction="row"
                        single={true}
                        defaultOption={selectedMeasureFilters.somGroup}
                        select={(data) => onSelectMeasure("somGroup", data)}
                        disabled={selectedMeasureFilters.variable2 !== "SOM"}
                      />
                    </Grid>
                    <Grid item>
                      <RadioButtonGroup
                        key={`measures-filters-som`}
                        color="#000"
                        showButton={measureFilter.SOMConfig.showRadioButton}
                        data={measureFilter.SOMConfig.options}
                        defaultOption={selectedMeasureFilters.somMeasure}
                        select={(data) => onSelectMeasure("somMeasure", data)}
                        direction="row"
                        disabled={selectedMeasureFilters.variable2 !== "SOM"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FilterAccordion>
            <FilterAccordion title="Comparison level">
              <Grid container spacing={2} className="m-b-20">
                <Grid item xs={12} sm={2}>
                  <RadioButtonGroup
                    key={`measures-api-filters`}
                    color="#000"
                    showButton={comparisonLevelConfig.showRadioButton}
                    data={comparisonLevelConfig.options}
                    select={(data) => {
                      clearValues(data);
                      setComparison(data);
                      setisSave(false);
                    }}
                    defaultOption={comparison}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="m-b-10">
                <Grid item>
                  <BlackTitle className="f-w-5">PEPSICO: </BlackTitle>
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={14} className="m-b-20">
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{productFilter.vendor.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableProductFilter.vendor}
                    data={productFilter.vendor.options}
                    onChange={(val) => onChangeProductFilter("vendor", val)}
                    defaultOption={selectedProductFilter.vendor || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{productFilter.category.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableProductFilter.category}
                    data={productFilter.category.options}
                    onChange={(val) => onChangeProductFilter("category", val)}
                    defaultOption={selectedProductFilter.category || ["empty"]}
                    placeholder={productFilter.category.placeholder}
                    multiple={productFilter.category.multiple}
                    allOption={productFilter.category.all}
                    selectionLimit={productFilter.category.selectionLimit}
                  />
                </Grid>
                {comparisonLevel.segment && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.segment.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.segment}
                      data={productFilter.segment.options}
                      onChange={(val) => onChangeProductFilter("segment", val)}
                      defaultOption={selectedProductFilter.segment || ["empty"]}
                      placeholder={productFilter.segment.placeholder}
                      multiple={productFilter.segment.multiple}
                      allOption={productFilter.segment.all}
                      selectionLimit={productFilter.segment.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.brand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.brand.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.brand}
                      data={productFilter.brand.options}
                      onChange={(val) => onChangeProductFilter("brand", val)}
                      defaultOption={selectedProductFilter.brand || ["empty"]}
                      placeholder={productFilter.brand.placeholder}
                      multiple={productFilter.brand.multiple}
                      allOption={productFilter.brand.all}
                      selectionLimit={productFilter.brand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.subBrand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {productFilter.subBrand.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.subBrand}
                      data={productFilter.subBrand.options}
                      onChange={(val) => onChangeProductFilter("subBrand", val)}
                      defaultOption={
                        selectedProductFilter.subBrand || ["empty"]
                      }
                      placeholder={productFilter.subBrand.placeholder}
                      multiple={productFilter.subBrand.multiple}
                      allOption={productFilter.subBrand.all}
                      selectionLimit={productFilter.subBrand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.packSize && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {productFilter.packSize.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.packSize}
                      data={productFilter.packSize.options}
                      onChange={(val) => onChangeProductFilter("packSize", val)}
                      defaultOption={
                        selectedProductFilter.packSize || ["empty"]
                      }
                      placeholder={productFilter.packSize.placeholder}
                      multiple={productFilter.packSize.multiple}
                      allOption={productFilter.packSize.all}
                      selectionLimit={productFilter.packSize.selectionLimit}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} className="m-b-10">
                <Grid item>
                  <BlackTitle className="f-w-5">Competitor: </BlackTitle>
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={14} className="m-b-20">
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{competitorFilter.vendor.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableCompetitorFilter.vendor}
                    data={_.sortBy(
                      competitorFilter.vendor.options,
                      (i) => i.value
                    )}
                    onChange={(val) => onChangeCompetitorFilter("vendor", val)}
                    defaultOption={selectedCompetitorFilter.vendor || ["empty"]}
                    placeholder={competitorFilter.vendor.placeholder}
                    multiple={competitorFilter.vendor.multiple}
                    allOption={competitorFilter.vendor.all}
                    selectionLimit={competitorFilter.vendor.selectionLimit}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>
                    {competitorFilter.category.title}
                  </DropdownTitle>
                  <Dropdown
                    disabled={disableCompetitorFilter.category}
                    data={competitorFilter.category.options}
                    onChange={(val) =>
                      onChangeCompetitorFilter("category", val)
                    }
                    defaultOption={
                      selectedCompetitorFilter.category || ["empty"]
                    }
                    placeholder={competitorFilter.category.placeholder}
                    multiple={competitorFilter.category.multiple}
                    allOption={competitorFilter.category.all}
                    selectionLimit={competitorFilter.category.selectionLimit}
                  />
                </Grid>
                {comparisonLevel.segment && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {competitorFilter.segment.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.segment}
                      data={competitorFilter.segment.options}
                      onChange={(val) =>
                        onChangeCompetitorFilter("segment", val)
                      }
                      defaultOption={
                        selectedCompetitorFilter.segment || ["empty"]
                      }
                      placeholder={competitorFilter.segment.placeholder}
                      multiple={competitorFilter.segment.multiple}
                      allOption={competitorFilter.segment.all}
                      selectionLimit={competitorFilter.segment.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.brand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {competitorFilter.brand.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.brand}
                      data={competitorFilter.brand.options}
                      onChange={(val) => onChangeCompetitorFilter("brand", val)}
                      defaultOption={
                        selectedCompetitorFilter.brand || ["empty"]
                      }
                      placeholder={competitorFilter.brand.placeholder}
                      multiple={competitorFilter.brand.multiple}
                      allOption={competitorFilter.brand.all}
                      selectionLimit={competitorFilter.brand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.subBrand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {competitorFilter.subBrand.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.subBrand}
                      data={competitorFilter.subBrand.options}
                      onChange={(val) =>
                        onChangeCompetitorFilter("subBrand", val)
                      }
                      defaultOption={
                        selectedCompetitorFilter.subBrand || ["empty"]
                      }
                      placeholder={competitorFilter.subBrand.placeholder}
                      multiple={competitorFilter.subBrand.multiple}
                      allOption={competitorFilter.subBrand.all}
                      selectionLimit={competitorFilter.subBrand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.packSize && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {competitorFilter.packSize.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.packSize}
                      data={competitorFilter.packSize.options}
                      onChange={(val) =>
                        onChangeCompetitorFilter("packSize", val)
                      }
                      defaultOption={
                        selectedCompetitorFilter.packSize || ["empty"]
                      }
                      placeholder={competitorFilter.packSize.placeholder}
                      multiple={competitorFilter.packSize.multiple}
                      allOption={competitorFilter.packSize.all}
                      selectionLimit={competitorFilter.packSize.selectionLimit}
                    />
                  </Grid>
                )}
              </Grid>
            </FilterAccordion>
            <Grid className="p-l-16">
              <OrangeBtn
                color="secondary"
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn
                color="primary"
                onClick={onApplyFilter}
                disabled={disableApplyFilter()}
              >
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={45} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={45} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={45} />
              </Grid>
            </Grid>
            <Grid className="p-l-16 m-t-20" container spacing={2}>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={99}
                height={45}
              />
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={110}
                height={45}
              />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default MultipleAPIFilters;
