import { API } from "../../util/helper";

export const getProductFilters = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/products`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const getAnchorFilters = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/anchorproducts`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const getMultipleApiProducts = async (data, type) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/multipleapiproducts?type=${type}`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const getCorrelationChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/somcorrelation`,
    payload
  );
};

export const getCorrelationTableData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/somcorrelationdatatable`,
    payload
  );
};

export const getAPIOptimizationChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/optimization`,
    payload
  );
};

export const getG5ChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/g5`,
    payload
  );
};

export const getAPISimulationChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/simulationSaleOut`,
    payload
  );
};

export const getAPISimulationValuesChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/simulationvalues`,
    payload
  );
};

export const getMultipleAPIChartData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/multipleApis`,
    payload
  );
};
