import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterAccordion from '../../../../components/Filters/Filters';
import Indicator from '../../../../components/Loader';
import ProductFiltersV2 from '../../../../components/ProductFilters-v2';

import { makeStyles } from '@material-ui/core/styles';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import { dateRange, initialState } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { productFilterInitialState } from '../../../../mocks/supermarketMock';
import { clearGeoFilters } from '../../../../store/actions/geoFilter.action';
import { clearProductFilters } from '../../../../store/actions/productFilter.action';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import { dataFormat } from '../../../../util/helper';
import { CustomSwitch } from '../../../HistoricPriceAndCrossPriceElasticity/HistoricPriceAndCrossPriceElasticity.Styled';
import { elasticityCoefficientLoader } from '../../store/SupermarketDashboard.action';
const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    marginBottom: '30px !important',
    fontWeight: 600,
    fontSize: '18px !important',
  },
  toggleTheme: {
    fontSize: '1.2rem',
  },
}));
const TopFilters: React.FC<{ data; callback; defaultFilters? }> = ({ data, callback, defaultFilters }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);
  const [intensityFlag, setIntensityFlag] = useState(false);
  const [clearGeoFilterData, clearGeoFilter] = useState(false);
  const [clearProductFilter, setClearProductFilter] = useState(false);
  const geoFilter = useSelector((state: any) => state.geoFilter);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(defaultFilters ? defaultFilters : initialState);
  const [selectedProductFilters, setSelectedProductFilters] = useState({ ...productFilterInitialState.selectedFilters });

  // const productFilter = useSelector((state: any) => state.productFilter);
  // const selectedProductFilters = productFilter && productFilter.selectedFilters;
  // const disabledProductFilters = (productFilter && productFilter.disabledFilters) || {
  //   ...productInitialState.disabledFilters,
  // };

  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setSelectedGeoFilters({
        country: defaultFilters.country,
        geoLevel: defaultFilters.geoLevel,
        geoLevel2: defaultFilters.geoLevel2,
        channel: defaultFilters.channel,
        periodView: defaultFilters.periodView,
      });
      setIntensityFlag(defaultFilters.intensityFlag);
    }
  }, [defaultFilters]);

  const onSelectProductFilter = (payload) => {
    if (payload.type === 'PRODUCT_FILTER') {
      setSelectedProductFilters(payload.filters);
    }
  };

  const reduxState = useSelector((state: any) => state);

  useEffect(() => {
    if (loader && !reduxState.SupermarketPepsicoBaseline.elasticityCoefficient) {
      setLoader(false);
    }
  }, [reduxState.SupermarketPepsicoBaseline.elasticityCoefficient]);

  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
      // dispatch(clearProductFilters());
      // Object.keys(data.productFilter).map((key) => (data.productFilter[key].options = [])); //Clear the product data
      // dispatch(
      //   fetchProductFiltersData(
      //     {
      //       country: selectedGeoFilters.country,
      //       ...productInitialState.selectedFilters,
      //     },
      //     data.productFilter
      //   )
      // );
    }
  }, [selectedGeoFilters.country]);

  const onApplyFilter = () => {
    dispatch(elasticityCoefficientLoader(true));
    setLoader(true);
    const payload = {
      type: 'FILTER_APPLIED',
      filters: {
        ...selectedGeoFilters,
        ...selectedProductFilters,
        intensityFlag,
        date: selectedDate,
      },
    };
    callback(payload);
  };

  const clearFilter = () => {
    dispatch(clearGeoFilters());
    dispatch(clearProductFilters());
    clearGeoFilter(true);
    setClearProductFilter(true);
    setSelectedGeoFilters({ ...initialState });
  };

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator position='absolute' show={!reduxState.common.loader && (loader || geoFilter.countriesLoading || geoFilter.geoLevelLoading)} />
      <CardContent>
        <FilterAccordion title='Geo-Filters'>
          <GeoFiltersV2
            key='supermarket-top-filter'
            data={geoFilterV2Config}
            onChangeDate={(dt) => {
              clearGeoFilter(false);
              setSelectedDate(dt);
            }}
            onSelectFilters={setSelectedGeoFilters}
            clearFilter={clearGeoFilterData}
            apiPath='Supermarket'
            showLoader={setLoader}
            defaultFilters={defaultFilters}
            defaultDate={defaultFilters?.date ? defaultFilters?.date.split('-') : null}
          />
        </FilterAccordion>
        {/* <FilterAccordion title='Product Filters'>
          <ProductFilters
            data={data}
            anchorFilter={false}
            disabledProductFilters={defaultDisabledFilters ? defaultDisabledFilters : disabledProductFilters}
            selectedProductFilters={defaultSelectedProductFilters ? defaultSelectedProductFilters : selectedProductFilters}
            change={onSelectProductFilter}
          />
        </FilterAccordion> */}
        <FilterAccordion title='Product Filters'>
          <ProductFiltersV2
            data={data}
            hideAnchorFilter={true}
            hideCompetitorFilter={true}
            // disabledProductFilters={disabledProductFilters}
            // defaultProductFilters={selectedProductFilters}
            onChange={onSelectProductFilter}
            selectedGeoFilters={selectedGeoFilters}
            onChangeLoader={setLoader}
            defaultProductFilters={defaultFilters}
            clearFilter={clearProductFilter}
          />
        </FilterAccordion>
        <FilterAccordion title='Measure Filters'>
          <Box className={classes.flexCenter}>
            <Typography style={{color:"#000"}} className={classes.toggleTheme}>View Price Points at Intermediate Values</Typography>
            <Box sx={{ minWidth: 120 }}>
              <CustomSwitch
                onChange={() => {
                  setIntensityFlag(!intensityFlag);
                }}
                checked={intensityFlag}
              />
            </Box>
          </Box>
        </FilterAccordion>
        <Grid className='p-l-16'>
          <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            color='primary'
            onClick={onApplyFilter}
            disabled={
              geoFilter.loading ||
              // productFilter.loading ||
              !selectedGeoFilters.country ||
              !selectedGeoFilters.geoLevel2 ||
              !selectedProductFilters.category
            }
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
