import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import FilterAccordion from '../../../../components/Filters/Filters';
import { initialState } from '../GeoFilters/GeoFilters';
import GeoFilters from '../GeoFilters';
import Indicator from '../../../../components/Loader';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import mockData from '../../../../mocks/pricingPotentialMocks';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import MeasureFilter from '../MeasureFilters';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
const TopFilters: React.FC<{
  callback;
  showSkeleton?: boolean;
  defaultFilters?;
}> = ({ callback, showSkeleton, defaultFilters }) => {
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    benchMark: mockData.measureFilter.benchMark.defaultOption.value || undefined,
  });
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [productMockData, setProductMockData] = useState<any>({});
  const [selectedGeoFilters, setSelectedGeoFilters] = useState({
    ...initialState,
  });
  const [comparisionLevel, setComparisonLevel] = useState(mockData.measureFilter.comparisonLevel.defaultOption.value || undefined);
  const [prevComparisonLevel, setPrevComparisonLevel] = useState(mockData.measureFilter.comparisonLevel.defaultOption.value || undefined);
  const [selectedAnchorFilters, setSelectedAnchorFilters] = useState<any>({
    anchorCategory: null,
    anchorSegment: null,
    anchorBrand: null,
    anchorSubBrand: null,
    anchorPackSize: null,
    anchorPermutation: null,
  });
  const disableApplyFilter = () => {
    const flag: boolean =
      !comparisionLevel ||
      selectedGeoFilters.country === '' ||
      selectedGeoFilters.country === null ||
      selectedGeoFilters.geoLevel2 === '' ||
      selectedGeoFilters.geoLevel2 === null ||
      (comparisionLevel === 'brand' ? !selectedAnchorFilters.anchorBrand : !selectedAnchorFilters.anchorPermutation);
    return flag;
  };
  const onApplyFilter = async () => {
    if (selectedGeoFilters.periodView) {
      delete selectedGeoFilters.periodView;
    }
    const payload = {
      ...selectedGeoFilters,
      comparisonLevel: comparisionLevel,
      ...selectedAnchorFilters,
      ...selectedMeasureFilters,
    };
    if (comparisionLevel === 'brand') {
      delete payload.anchorSubBrand;
      delete payload.anchorPackSize;
    }
    callback(payload);
  };
  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setComparisonLevel(mockData.measureFilter.comparisonLevel.defaultOption.value || undefined);
    setPrevComparisonLevel(mockData.measureFilter.comparisonLevel.defaultOption.value || undefined);
    setSelectedAnchorFilters({
      anchorCategory: null,
      anchorSegment: null,
      anchorBrand: null,
      anchorSubBrand: null,
      anchorPackSize: null,
      anchorPermutation: null,
    });
    setMeasureFilter({
      benchMark: mockData.measureFilter.benchMark.defaultOption.value || undefined,
    });
  };
  const onChangeAnchorFilter = (data) => {
    setSelectedAnchorFilters({ ...data });
    clearGeoFilter(false);
  };
  useEffect(() => {
    if (comparisionLevel === 'brand') {
      setProductMockData({ ...mockData.anchorFiltersBrand });
    } else if (comparisionLevel === 'subBrandPackSize') {
      setProductMockData({ ...mockData.anchorFilter });
    }
  }, [comparisionLevel]);
  // useEffect(() => {
  //   if (selectedGeoFilters.country) {
  //     comparisionLevel.options[1].label =
  //       selectedGeoFilters.country !== "MEXICO"
  //         ? "Brand Pack Size"
  //         : "Sub-Brand/Pack-Size";
  //   }
  // }, [selectedGeoFilters.country]);
  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setComparisonLevel(defaultFilters.comparisonLevel);
      setPrevComparisonLevel(defaultFilters.comparisonLevel);
      setTimeout(() => {
        setSelectedGeoFilters({
          country: defaultFilters.country,
          geoLevel: defaultFilters.geoLevel,
          geoLevel2: defaultFilters.geoLevel2,
          channel: defaultFilters.channel,
        });
        setSelectedAnchorFilters({
          anchorCategory: defaultFilters.anchorCategory,
          anchorSegment: defaultFilters.anchorSegment,
          anchorBrand: defaultFilters.anchorBrand,
          anchorSubBrand: defaultFilters.anchorSubBrand,
          anchorPackSize: defaultFilters.anchorPackSize,
          anchorPermutation: defaultFilters.anchorPermutation,
        });
        setMeasureFilter({
          benchMark: defaultFilters.benchMark || undefined,
        });
      }, 0);
    }
  }, [defaultFilters]);
  const reduxState = useSelector((state: any) => state);
  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        show={
          !reduxState.common.loader &&
          (loader ||
            reduxState.geoFilter.geoLevelLoading ||
            reduxState.PricingPotentialLocalFilter.pricingPotentialSliderLoader ||
            reduxState.PricingPotentialLocalFilter.pricingPotentialChartLoader)
        }
      />
      <CardContent>
        {!showSkeleton ? (
          <>
            <FilterAccordion title='Geo-Filters'>
              <GeoFilters
                key='api-predictive-top-filter'
                data={geoFilterV2Config}
                onSelectFilters={setSelectedGeoFilters}
                clearFilter={clearFilterData}
                apiPath='ApiPredictive'
                showLoader={setLoader}
                defaultFilters={defaultFilters}
                showDatePicker={false}
              />
            </FilterAccordion>
            <FilterAccordion title='Additional Filters'>
              <Grid key={`measures-dropdown-container-2`}>
                <DropdownTitle>Comparision Level</DropdownTitle>
                <RadioButtonGroup
                  key={`measures-filters-2`}
                  color='#000'
                  showButton={mockData.measureFilter.comparisonLevel.showRadioButton}
                  data={mockData.measureFilter.comparisonLevel.options}
                  defaultOption={comparisionLevel}
                  select={(data) => {
                    setComparisonLevel(data);
                    setTimeout(() => {
                      setPrevComparisonLevel(data);
                    }, 0);
                  }}
                />
              </Grid>
            </FilterAccordion>
            <FilterAccordion title='Product Filters'>
              <ProductFiltersV3
                onChange={(data) => onChangeAnchorFilter(data)}
                data={productMockData}
                onChangeLoader={setLoader}
                selectedGeoFilters={
                  comparisionLevel === prevComparisonLevel && selectedGeoFilters.country && selectedGeoFilters.geoLevel2 ? selectedGeoFilters : {}
                }
                clearFilter={clearFilterData}
                apiURL='filter/pricingPotentialProducts'
                defaultFilters={
                  Object.keys(defaultFilters).length > 0
                    ? {
                        anchorCategory: defaultFilters.anchorCategory,
                        anchorSegment: defaultFilters.anchorSegment,
                        anchorBrand: defaultFilters.anchorBrand,
                        anchorSubBrand: defaultFilters.anchorSubBrand,
                        anchorPackSize: defaultFilters.anchorPackSize,
                        anchorPermutation: defaultFilters.anchorPermutation,
                      }
                    : null
                }
                params={{
                  ...selectedGeoFilters,
                  comparisonLevel: comparisionLevel,
                }}
              />
              <span style={{ fontSize: 16 }}>
                <b>NOTE :</b> Products with imputed elasticities are not listed in the filters (<span style={{ color: 'red' }}>*</span>)
              </span>
            </FilterAccordion>
            <FilterAccordion title='Measure Filters'>
              <MeasureFilter
                data={mockData.measureFilter}
                selectedGeoFilters={selectedGeoFilters}
                callback={(e) => setMeasureFilter(e)}
                selectedMeasureFilters={selectedMeasureFilters}
              />
            </FilterAccordion>
            <Grid className='p-l-16'>
              <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <FilterAccordion showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} /> <Skeleton variant='rectangular' width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </FilterAccordion>
            <Grid className='m-b-20'>
              <FilterAccordion showSkeleton>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </FilterAccordion>
            </Grid>
            <Grid className='p-l-16' container spacing={2}>
              <Skeleton variant='rectangular' className='m-r-20' width={99} height={45} />
              <Skeleton variant='rectangular' className='m-r-20' width={110} height={45} />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default TopFilters;
