import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Box, Grid, Card, CardContent, Skeleton } from '@mui/material';
import { Icon, Value, Title, Profit, Loss } from './StatCard.Styled';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const StatCard: React.FC<{ data; showSkeleton: boolean }> = ({
  data,
  showSkeleton,
}) => {
  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );

  const { icon, color, title, value, raise, change } = data;

  return (
    <Card>
      <CardContent data-testid='stat-card-container'>
        {!showSkeleton ? (
          <>
            <Box
              className='stats-icon-container'
              style={{
                backgroundColor: theme.darkmode ? '#FFF' : '#EAF6FB',
              }}
            >
              {color ? (
                <FontAwesomeIcon icon={icon} color={color} fontSize='1.2rem' />
              ) : (
                <Icon
                  data-testid='image'
                  className='stats-icon'
                  src={String(icon)}
                  alt='raiseImg'
                />
              )}
            </Box>
            <Title>{title}</Title>
            <Box alignItems='center'>
              <Grid
                container
                spacing={2}
                display='flex'
                justifyContent='space-between'
              >
                <Grid item>
                  <Value>{value}</Value>
                </Grid>
                <Grid item>
                  {raise ? (
                    <Profit data-testid='profit'>
                      + {change} <ArrowUpward />
                    </Profit>
                  ) : raise !== undefined? (
                    <Loss data-testid='loss'>
                      - {change} <ArrowDownward />
                    </Loss>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Box
              className='stats-icon-container'
              style={{
                backgroundColor: theme.darkmode ? '#FFF' : '#EAF6FB',
              }}
            >
              <Skeleton width={25} height={35} />
            </Box>
            <Title>
              <Skeleton />
            </Title>
            <Box alignItems='center'>
              <Grid
                container
                spacing={2}
                display='flex'
                justifyContent='space-between'
              >
                <Grid item>
                  <Value>
                    <Skeleton width={50} />
                  </Value>
                </Grid>
                <Grid item>
                  <Skeleton width={50} height={30} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default StatCard;
