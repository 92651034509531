import * as React from "react";
import { Box } from "@mui/system";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

const Spinner: React.FC<{
  position?;
  show?;
  text?;
  textColor?: string;
}> = ({ position = "fixed", show = null, text = "", textColor = "white" }) => {
  const [showLoader, setLoader] = React.useState();
  const reduxState = useSelector((state: any) => state);

  React.useEffect(() => {
    if (show === null) {
      setLoader(reduxState.common.loader);
    } else {
      setLoader(show);
    }
  }, [show, reduxState.common.loader]);

  return (
    <>
      {showLoader && (
        <Box
          style={{
            position,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 999,
          }}
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            {text !== "" && (
              <Grid item>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#25d4cf",
                  }}
                >
                  {text}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={true}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Spinner;
