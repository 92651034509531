import { CardContent, Grid, Card } from "@material-ui/core";
import { Box, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pricingPotentialSliderDataLoader,
  pricingPotentialChartDataLoader,
} from "../../store/pricingPotential.action";
import LineChart from "./components/LineChart";
import ScaleSlider from "./components/ScaleSlider";
import { fetchPredictedSalesUnitData, fetchSliderData } from "../../service";
import {
  NoData,
  OrangeBtn,
  PrimaryBtn,
} from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import _ from "lodash";
import { color } from "highcharts";
import StackChart from "./components/StackChart";
import { rendarImage } from "../../../../util/helper";
const GraphContainer: React.FC<{ filters; callback; defaultFilters }> = ({
  filters,
  callback,
  defaultFilters,
}) => {
  const [productImage, setProductImage] = useState({});
  const [benchMarkValue, setBenchMarkValue] = useState<any>([]);
  const [ppuValue, setPpuValue] = useState<any>();
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [stackData, setStackData] = useState<any>([]);
  const [slidersData, setSlidersData] = useState<any>([]);
  const [predectedSalesUnitData, setPredectedSalesUnitData] = useState<any>({});
  const dispatch = useDispatch();
  const getSliderData = () => {
    dispatch(pricingPotentialSliderDataLoader(true));
    fetchSliderData({ ...filters })
      .then((response) => {
        //remove PPU data
        let index = response.data.findIndex((x) => x.label === "EDRP");
        if (index !== -1) {
          const ppu = response.data.splice(index, 1);
          if (ppu.length > 0) setPpuValue(ppu[0].defaultValue);
        }
        //change position of VPU data to first
        index = response.data.findIndex((x) => x.label === "VPU");
        if (index !== -1) {
          const vpu = response.data.splice(index, 1);
          response.data.unshift(vpu[0]);
        }
        setBenchMarkValue(
          response.data.map((item) => {
            return item.defaultValue;
          })
        );
        //set a default values
        if (defaultFilters && _.isArray(defaultFilters.variables)) {
          defaultFilters.variables.map((item) => {
            const i = response.data.findIndex((x) => x.label === item.label);
            if (i !== -1) {
              response.data[i].defaultValue = item.defaultValue;
            }
          });
        }
        setSlidersData(response.data);
        dispatch(pricingPotentialSliderDataLoader(false));
      })
      .catch((e) => {
        dispatch(pricingPotentialSliderDataLoader(false));
      });
  };
  const getPredictedSalesUnitData = (sliderChangedData) => {
    dispatch(pricingPotentialChartDataLoader(true));
    fetchPredictedSalesUnitData({ ...sliderChangedData, ...filters })
      .then((response) => {
        dispatch(pricingPotentialChartDataLoader(false));
        if (response && response.data) {
          setPredectedSalesUnitData(response.data);
          setStackData(response.data.predPEVsPPU);
        }
      })
      .catch((e) => {
        dispatch(pricingPotentialChartDataLoader(false));
      });
  };
  React.useEffect(() => {
    if (filters && filters.country) {
      getImage();
      getSliderData();
      getPredictedSalesUnitData(defaultFilters ? defaultFilters : {});
      setSkeleton(false);
    }
  }, [filters]);

  const reset = () => {
    if (filters && filters.country) {
      getImage();
      setSkeleton(false);
      getSliderData();
      defaultFilters = {};
      getPredictedSalesUnitData({});
    }
  };
  const getImage = async () => {
    let productName = filters?.anchorSubBrand?.length
      ? filters?.anchorSubBrand[0]
      : filters?.anchorBrand?.length
      ? filters?.anchorBrand[0]
      : null;
    setProductImage({
      src: await rendarImage(productName, filters.country),
      name: productName,
    });
  };
  const onChangeSlider = (val, i) => {
    const payload = [...slidersData];
    payload[i].defaultValue = val;
    setSlidersData(payload);
  };

  const onApply = () => {
    callback({ variables: slidersData });
    getPredictedSalesUnitData({ variables: slidersData });
  };
  const pricingPotentialState = useSelector(
    (state: any) => state.PricingPotentialLocalFilter
  );
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} className="m-b-20">
          <Grid item xs={12} md={5}>
            {!pricingPotentialState.pricingPotentialSliderLoader &&
            !skeleton ? (
              <>
                <Grid style={{ height: 1150, overflow: "auto", width: "100%" }}>
                  {_.isArray(slidersData) && slidersData.length > 0 ? (
                    <>
                      <Card style={{ width: "95%" }}>
                        <CardContent>
                          <Typography sx={{ fontWeight: 800, fontSize: 20 }}>
                            <span style={{ color: "#2196f3" }}>EDRP</span> 
                             <span style={{color:"black"}}> : {ppuValue?.toFixed(2)} </span> 
                          </Typography>
                        </CardContent>
                      </Card>
                      <Grid container style={{ marginTop: 2 }}>
                        <Grid item md={4}>
                          <Typography sx={{ mt: 2, mr: 2, fontSize: "14px" }}>
                            Benchmark Value
                          </Typography>
                        </Grid>
                        <Grid md={5}></Grid>
                        <Grid item md={3}>
                          <Typography sx={{ mt: 2, ml: 5, fontSize: "14px" }}>
                            Coefficients
                          </Typography>
                        </Grid>
                      </Grid>
                      {slidersData.map((item, i) => (
                        <ScaleSlider
                          data={item}
                          callback={(val) => onChangeSlider(val, i)}
                          key={`slider-${i}`}
                          benchMarkValues={benchMarkValue[i]}
                        />
                      ))}
                    </>
                  ) : (
                    <Box
                      style={{ height: 400 }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <NoData>{messages.noData}</NoData>
                    </Box>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid>
                  <Skeleton variant="rectangular" height={1150} />
                </Grid>
                <Grid container className="m-t-10">
                  <Grid item xs={6} sm={3} className="m-r-20">
                    <Skeleton variant="rectangular" height={40} width={120} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      width={120}
                      style={{ marginLeft: "3vw" }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <LineChart
              id={`pricing-potential-chart1`}
              skeleton={
                skeleton || pricingPotentialState.pricingPotentialChartLoader
              }
              chartTitle="Predicted Sales Unit Out "
              seriesName={["Benchmark Scenario", "Simulated Scenario"]}
              data={predectedSalesUnitData.predSalesOutVsPPU}
              image={productImage}
              variation={predectedSalesUnitData.variationPercentage}
            />

            <StackChart
              stackData={stackData}
              skeleton={
                skeleton || pricingPotentialState.pricingPotentialChartLoader
              }
              title="Variable Impact"
              tooltip="Variable Impact"
              id="pricing-potential-chart2"
            />
          </Grid>

          {!pricingPotentialState.pricingPotentialSliderLoader && !skeleton ? (
            <Grid item xs={12} md={8}>
              <OrangeBtn
                color="secondary"
                onClick={reset}
                className="m-r-20"
                style={{ width: 100 }}
              >
                Reset
              </OrangeBtn>
              <PrimaryBtn
                color="primary"
                onClick={onApply}
                style={{ width: 100 }}
              >
                Simulate
              </PrimaryBtn>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default GraphContainer;
