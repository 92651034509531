import React, { useRef, useEffect } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import Dashboard from "../../../../components/Dashboard";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { lineChartConfig } from "./lineChartConfig";
import _ from "lodash";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import HighchartContainer from "../../../../components/HighchartContainer";
import { plotElementsOnChart } from "../../../../util/helper";

const CrossPriceEleasticityChart: React.FC<{
  data;
  skeleton;
  seriesColor;
  isImputed;
  id;
}> = ({ data, skeleton, seriesColor, isImputed, id }) => {
  const chartRef = [
    { chartRef: useRef(null) },
    { chartRef: useRef(null) },
    { chartRef: useRef(null) },
  ];

  useEffect(() => {
    data.map((item, index) => {
      if (
        chartRef[index] &&
        chartRef[index].chartRef.current &&
        chartRef[index].chartRef.current.chart &&
        item?.crossPriceElasticity?.length > 0
      ) {
        const chart = chartRef[index].chartRef.current.chart;
        setTimeout(() => {
          chart.xAxis[0].setCategories(
            item.crossPriceElasticity.map((x) => x.date)
          );
          chart.series[0].setData(
            item.crossPriceElasticity.map((x) => x.value)
          );
          chart.series[0].update({ color: seriesColor });
          chart.update({
            title: {
              style: { fontSize: 14, fontWeight: 600 },
              align: "left",
              verticalAlign: "bottom",
              text: isImputed
                ? "Cross Price Elasticity : Imputed"
                : "Cross Price Elasticity : Actual",
            },
          });
        }, 0);
        let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
        if (dashbaordData && dashbaordData[id]) {
          plotElementsOnChart(dashbaordData[id], chart, id);
        }
      }
    });
  }, [data, chartRef]);

  return (
    <>
      <Grid className="m-t-10">
        {skeleton ? (
          <Grid container justifyContent="space-around">
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton variant="rectangular" height={400} width={"95%"} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton variant="rectangular" height={400} width={"95%"} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton variant="rectangular" height={400} width={"95%"} />
            </Grid>
          </Grid>
        ) : data && _.isArray(data) && data.length > 0 ? (
          <Grid container spacing={2} justifyContent="space-between">
            {data.map((items, index) => (
              items.productName?
              <Grid item xs={12} sm={6} md={4}>
                <Dashboard
                  title={`<span style="font-size: 14px;">${items.productName}</span>`}
                  tooltip={items.productName}
                  id={`${id}-${index}`}
                  chartRef={chartRef[index].chartRef}
                  showSkeleton={skeleton}
                >
                  <HighchartContainer
                    chartRef={chartRef[index].chartRef}
                    id={`${id}-${index}`}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={lineChartConfig}
                      ref={chartRef[index].chartRef}
                    />
                  </HighchartContainer>
                </Dashboard>
              </Grid>
              :null
            ))}
          </Grid>
        ) : (
          <Box
            style={{ height: 400 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <NoData>{messages.noData}</NoData>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default CrossPriceEleasticityChart;
