import {
  GET_PRODUCT_FILTERS,
  GET_PRODUCT_FILTERS_SUCESS,
  GET_PRODUCT_FILTERS_FAILURE,
  ON_CHANGE_PRODUCT_OPTION,
  CLEAR_PRODUCT_FILTERS,
} from "./constants";
import { API } from "../../util/helper";
import _ from "lodash";

export const getProductFilters = () => {
  return {
    type: GET_PRODUCT_FILTERS,
  };
};

export const getProductFiltersSucess = (payload: any, filterData) => {
  return {
    type: GET_PRODUCT_FILTERS_SUCESS,
    payload,
    filterData
  };
};

export const getProductFiltersFailure = (error) => {
  return {
    type: GET_PRODUCT_FILTERS_FAILURE,
    payload: error,
  };
};

const getProductFilter = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}filter/products`,
    payload
  );
};

export function fetchProductFiltersData(filters, filterData) {
  return (dispatch: any) => {
    dispatch(getProductFilters());
    getProductFilter(filters)
      .then((response) => {
        dispatch(getProductFiltersSucess(response, filterData));
      })
      .catch((error) => {
        console.error(error.message);
        dispatch(getProductFiltersFailure(error));
      });
  };
}

export const onChangeProductOption = (key, val) => {
  if (_.isArray(val) && val.length===0) {
    val = null;
   }
  return {
    type: ON_CHANGE_PRODUCT_OPTION,
    payload: {[key]: val},
  };
};

export const clearProductFilters = () => {
  return {
    type: CLEAR_PRODUCT_FILTERS,
  };
};
