import { API } from '../../util/helper';

export const getCorrelationAnalysisData = (payload) => {
  return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/profit/volumecorrelation`, payload);
};

export const getLinearRegressionChartData = (payload) => {
  return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/profit/linearregression`, payload);
};

export const getParabolaSimulationChartData = (payload) => {
  return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/profit/parabolasimulation`, payload);
};

export const getFinalSimulationValueData = (payload) => {
  return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/profit/finalsimulation`, payload);
};
