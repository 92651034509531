import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomTable from "../../../../components/CustomTable";
import Dashboard from "../../../../components/Dashboard";
import data from "../../../../mocks/apiPredictiveDashboard";
import { loader } from "../../../../store/actions/common.action";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import {
  checkForMultiSelections,
  formatNumber,
  plotImages,
  rendarImage,
  separator,
  windowResizeListner,
} from "../../../../util/helper";
import { APIOptimizationChartConfig } from "../../chartsConfig";
import HighchartContainer from "../../../../components/HighchartContainer";
import { getAPIOptimizationChartData } from '../../service';
import { optimizationChartDataLoader } from "../../store/apiPredictive.action";

const APIOptimization: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = React.useRef<any>(null);
  const [skeleton, setSkeleton] = React.useState(true);
  const [realESTVolumeIndexTableValues, setValues] = React.useState([]);
  const [productImages, setProductImages] = useState<any>([]);

  const [apiOptimizationData, setApiOptimizationData] = useState<any>({});

  const formatAPIOptimizationData = async (data) => {
    const xAxis =
      _.isArray(data.realVolume) && data.realVolume.length > 0
        ? data.realVolume.map((x) => x.api)
        : _.isArray(data.estVolume) && data.estVolume.length > 0
        ? data.estVolume.map((x) => x.api)
        : [];
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      chart.yAxis[0].setTitle({ text: "Vol.Index" });
      chart.xAxis[0].setCategories(xAxis);
      while (chart.series.length) {
        chart.series[0].remove();
      }
      if (_.isArray(data.realVolume)) {
        chart.addSeries({
          color: "#598ee3",
          data: data.realVolume.map((x) => _.values(x)),
          tooltip: {
            pointFormatter: function () {
              return formatNumber(this.y);
            },
          },
        });
      }

      if (_.isArray(data.estVolume)) {
        chart.addSeries({
          color: "#d41165",
          marker: {
            symbol: "circle",
          },
          data: data.estVolume.map((x) => _.values(x)),
          tooltip: {
            pointFormatter: function () {
              return formatNumber(this.y);
            },
          },
        });
      }

      if (data.productName || data.anchorProductName) {
        let product1: any = null;
        let product2: any = null;
        if (data.productName) {
          product1 = {
            base64: await rendarImage(data.productName, filters.country),
          };
        }
        if (data.anchorProductName) {
          product2 = {
            base64: await rendarImage(data.anchorProductName, filters.country),
          };
        }
        if (product1 && product1.base64 && product2 && product2.base64) {
          setProductImages([product1, product2]);
        } else if (product1 && product1.base64) {
          setProductImages([product1]);
        } else if (product2 && product2.base64) {
          setProductImages([product2]);
        }
      }
      setLabels(chart);
    }

    if (_.isArray(data.realVolume) && _.isArray(data.estVolume)) {
      const values = data.estVolume.map((item, index) => {
        return {
          api: separator(data.estVolume[index].api.toFixed(2)),
          realVolume: separator(data.realVolume[index].value.toFixed(2)),
          estVolume: separator(data.estVolume[index].value.toFixed(2)),
        };
      });
      setValues(values);
    }
  };

  React.useEffect(() => {
    if(apiOptimizationData && apiOptimizationData.estVolume){
      formatAPIOptimizationData(apiOptimizationData);
    }
  },[apiOptimizationData])

  React.useEffect(() => {
    if (filters && filters.country) {
      resetData();
      dispatch(optimizationChartDataLoader(true));
      getAPIOptimizationChartData(filters)
      .then((response) => {
        setSkeleton(false);
        dispatch(optimizationChartDataLoader(false));
        if (response && response.data) {
          let res = {...response.data}
          res.estVolume = res.estVolume.filter(x=> x.api !== 0 &&  x.value !== 0)
          res.realVolume = res.realVolume.filter(x=> x.api !== 0 &&  x.value !== 0)
          setApiOptimizationData(res)
        } else {
          dispatch(loader(false));
        }
      })
      .catch((error) => {
        dispatch(optimizationChartDataLoader(false));
        setSkeleton(false);
        dispatch(loader(false));
      });
    }
  }, [filters]);

  const setLabels = (chart) => {
    chart.legend.allItems[0].update({
      name: "Real Vol.Index",
    });
    chart.legend.allItems[1].update({
      name: "Est Vol.Index",
    });
  };

  useEffect(() => {
    if (productImages.length > 0 && chartRef && chartRef.current && chartRef.current.chart) {
      console.log(productImages)
      const chart = chartRef.current.chart;
      const config = {
        productImages: productImages,
        id: "api-predictive-dash-api-optimization-image",
        imgPosition: { x: 200, y: 270 },
        imgDimension: { width: 75, height: 60 },
        nameDimension: { width: 50, height: 55 },
      };
      windowResizeListner(chart, config);
      plotImages(chart, config);
    }
  }, [productImages]);

  const resetData = () =>{
    setSkeleton(true);
    setValues([]);
    setProductImages([]);
    setApiOptimizationData({});
  }

  return (
    <Card className="m-b-20">
      <CardContent>
        <Dashboard
          title="API Optimization"
          showSkeleton={skeleton}
          chartRef={chartRef}
          id="api-predictive-dash-api-optimization-container"
        >
          {skeleton ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3.5}>
                <Skeleton variant="rectangular" height={350} />
              </Grid>
              <Grid item xs={12} md={8.5}>
                <Skeleton variant="rectangular" height={350} />
              </Grid>
            </Grid>
          ) : filters && filters.packSize && filters.anchorPackSize ? (
            apiOptimizationData &&
            checkForMultiSelections(filters) &&
            _.isArray(apiOptimizationData.estVolume) &&
            apiOptimizationData.estVolume.length ? (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={3.5}
                  style={{
                    maxHeight: 400,
                    overflow: "auto",
                    paddingTop: 0,
                    marginTop: 20,
                  }}
                >
                  <CustomTable
                    heading={data.realESTVolumeIndexTable.heading}
                    value={realESTVolumeIndexTableValues}
                    showSkeleton={skeleton}
                  />
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <HighchartContainer
                    chartRef={chartRef}
                    id={"api-predictive-dash-api-optimization-container"}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={APIOptimizationChartConfig}
                      ref={chartRef}
                    />
                  </HighchartContainer>
                </Grid>
              </Grid>
            ) : (
              <Box
                style={{ height: 400 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )
          ) : (
            <Box
              style={{ height: 400 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <NoData>{messages.incorrectFilters}</NoData>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default APIOptimization;
