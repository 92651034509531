import { PRICE_ELASTICITY_LOCAL_FILTER, PRICE_ELASTICITY_MODEL } from './constants';

export const priceElasticityDataLoader = (payload) => {
  return {
    type: PRICE_ELASTICITY_MODEL,
    payload,
  };
};

export const priceElasticityLocalFilter = (payload) =>{
  return {
    type: PRICE_ELASTICITY_LOCAL_FILTER,
    payload
  }
}
