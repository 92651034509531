import { API } from "../../util/helper";

export const createUserAccount = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const getUserList = async (pageNo, offset) => {
  try {
    return await API.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users?skip=${
        (pageNo - 1) * offset
      }&take=${offset}`
    );
  } catch (e) {
    return e;
  }
};

export const updateUser = async (payload) => {
  try {
    return await API.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users`,
      payload
    );
  } catch (e) {
    return e;
  }
};
export const logoutUser = async () => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users/logout`
    );
  } catch (e) {
    return e;
  }
};
export const userProfile = async (payload, config) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users/${payload.id}/profile`,
      payload.formData,
      config
    );
  } catch (e) {
    return e;
  }
};
export const deleteUserProfile = async (payload) => {
  try {
    return await API.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users/${payload.id}/profile`
    );
  } catch (e) {
    return e;
  }
};

export const fetchHistoricPriceFilterData = async(data) =>{
  try{
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/historicPriceProductFilter`, data);
  }catch(e){
    return e
  }
}

export const fetchHistoricPriceElasticityFilterData = async(data) =>{
  try{
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashbaords/historicprice/priceElasticity`, data);
  }catch(e){
    return e
  }
}
