import { Card, CardContent, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from '../../../components/Dashboard';
import { Title } from '../../../components/Dashboard/Dashboard.Styled';
import mockData from '../../../mocks/pricePackEconomicsDashboardMock';
import { NoData } from '../../../styles/Common.Styled';
import { messages } from '../../../util/config';
import MultiLevelTable from '../../SellOutDashboard/components/MultiLevelTable/MultiLevelTable';
import { getPackEconomicsDetailsData } from '../service';
import { predictivePackEconomicsDetails } from '../store/PredictivePackEconomics.action';
const MultilevelTableContainer: React.FC<{ filters }> = ({ filters }) => {
  const [pepsiData, setPepsiData] = useState({});
  const [showSkeleton, setshowSkeleton] = useState(true);
  const [tableHeader, setTableHeader] = useState(mockData.tableHeaders);
  const dispatch = useDispatch();
  const fetchProductData = async () => {
    try {
      setshowSkeleton(true);
      const response = await getPackEconomicsDetailsData(filters);
      if (response?.data && response?.status === 200) {
        dispatch(predictivePackEconomicsDetails(false));
        setshowSkeleton(false);
        setPepsiData({
          vendor: [{ name: 'PEPSICO', parentName: 'PEPSICO', vendor: 'PEPSICO' }].map((item) => {
            return { ...item, index: 0 };
          }),
          pepsiCategory: response?.data.pepsiCategory.map((item) => {
            return { ...item, index: 1 };
          }),
          pepsiSegment: response?.data.pepsiSegment.map((item) => {
            return { ...item, index: 2 };
          }),
          pepsiBrands: response?.data.pepsiBrands.map((item) => {
            return { ...item, index: 3 };
          }),
          pepsiSubBrands: response?.data.pepsiSubBrands.map((item) => {
            return { ...item, index: 4 };
          }),
          pepsiPackSizes: response?.data.pepsiPackSizes.map((item) => {
            return { ...item, index: 5 };
          }),
        });
      } else {
        dispatch(predictivePackEconomicsDetails(false));
        setshowSkeleton(false);
        setPepsiData({});
      }
    } catch (error) {
      dispatch(predictivePackEconomicsDetails(false));
      console.log(error);
      setshowSkeleton(false);
      setPepsiData({});
    }
  };
  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      const tableHeaders = [...mockData.tableHeaders];
      tableHeaders[5].label = filters.isMarkUp === 1 ? 'MarkUp' : 'MarkDown';
      setTableHeader([...tableHeaders]);
      fetchProductData();
    }
  }, [filters]);

  return (
    <Card>
      <CardContent>
        <Dashboard
          title={null}
          showSkeleton={showSkeleton}
          chartRef={() => {}}
          showActionButton={true}
          actionButtons={{
            mmpw: false,
            screenshot: true,
            editMedia: false,
          }}
          id={null}
        >
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} sm={12} xl={12}>
              <Grid container display='flex' flexDirection='column'>
                <Grid item display='flex' justifyContent='space-between' sx={{ mb: 2 }}>
                  <Grid item xs={12} md={12} sm={12} xl={12}>
                    <Title className='ellipsis'>
                      Pack Economics Details | <span style={{ color: '#0093CD' }}>PepsiCo</span>
                    </Title>
                  </Grid>
                  <Grid item display='flex' justifyContent='flex-end' xs={12} md={12} sm={12} xl={12} fontStyle='italic' sx={{ pt: 1 }}>
                    {' '}
                    {filters?.date}
                  </Grid>
                </Grid>
                {showSkeleton || Object.keys(pepsiData).length > 0 ? (
                  <Grid container spacing={0}>
                    <MultiLevelTable
                      data={pepsiData}
                      tableHeader={tableHeader}
                      type='pepsico'
                      level='vendor'
                      showSkeleton={showSkeleton}
                      responseKeys={['name', 'ptc', 'adherence', 'srp', 'taxes', 'markUp', 'listPrice', 'dandA', 'ptr', 'cogs', 'gp', 'mix']}
                      style={{ cellWidth: '14vw' }}
                    />
                  </Grid>
                ) : (
                  <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                    <NoData>{messages.noData}</NoData>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Dashboard>
      </CardContent>
    </Card>
  );
};
export default MultilevelTableContainer;
