const primaryFilter = {
  country: {
    title: "Country",
    options: [],
  },
  geoLevel: {
    title: "Geo-Level",
    options: [],
    single: true,
    placeholder: "Select",
    all: false,
    multiple: false,
  },
  region: {
    title: "Region",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
  },
  city: {
    title: "City",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
  },
  channel: {
    title: "Channel",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
  },
  subChannel: {
    title: "Sub Channel",
    options: [],
    placeholder: "All",
    all: true,
    multiple: true,
  },
  keyAccounts: {
    title: "Key Accounts",
    options: [],
    placeholder: "Null",
    all: false,
    multiple: false,
  },
};

const productFilter = {
  category: {
    title: "Category",
    options: [],
    placeholder: "Select",
    first: true,
    all: true,
    defaultSelectAll: false,
    multiple: true,
    children: "segment",
  },
  segment: {
    title: "Segment",
    options: [],
    all: true,
    defaultSelectAll: false,
    multiple: true,
    placeholder: "Select",
    children: "brand",
  },
  brand: {
    title: "Brand",
    options: [],
    all: true,
    defaultSelectAll: false,
    multiple: true,
    placeholder: "Select",
    children: "subBrand",
  },
  subBrand: {
    title: "Sub-brand",
    options: [],
    all: true,
    defaultSelectAll: false,
    multiple: true,
    placeholder: "Select",
    children: "packSize",
  },
  packSize: {
    title: "Pack-size",
    options: [],
    all: true,
    defaultSelectAll: false,
    multiple: true,
    placeholder: "Select",
    last: true,
  },
};

const measureFilter = {
  absIndex: {
    title: "ABS/Index",
    options: [
      { id: "1", label: "Absolute Price", value: "absolutePrice" },
      { id: "2", label: "Index", value: "index" },
    ],
    defaultOption: { id: "1", value: "absolutePrice" },
    showRadioButton: true,
  },
  unitVolume: {
    title: "Unit/Volume",
    options: [
      { id: "1", label: "Price Per Unit", value: "ppu" },
      { id: "2", label: "Price Per Volume(KGs)", value: "ppv" },
    ],
    defaultOption: { id: "2", value: "ppu" },
    showRadioButton: true,
  },
  taxes: {
    title: "Taxes(%)",
    defaultValue: { id: "1", value: 8 },
  },
  markUp: {
    title: "Mark Up(%)",
    defaultValue: { id: "2", value: 27 },
  },
  markUpAndMarkDown: {
    title: "",
    options: [
      { label: "Mark Up", value: "markup" },
      { label: "Mark Down", value: "markdown" },
    ],
    defaultOption: { label: "Mark Up", value: "markup" },
  },
};

const titleMapper = {
  ptc: {
    title: "PTC",
  },
  adherence: {
    title: "Adherence",
  },
  srp: {
    title: "SRP",
  },
  taxes: {
    title: "Taxes",
  },
  markUp: {
    title: "Markup",
  },
  listPrice: {
    title: "List Price",
  },
  dandA: {
    title: "D&A",
  },
  ptr: {
    title: "PTR",
  },
  cogs: {
    title: "COGS",
  },
  gp: {
    title: "Gross Profit",
  },
};

const chartData = {
  ptc: { avg: 14.67, min: 13.2, max: 16.14 },
  adherence: { avg: -0.34, min: 0.31, max: 0.37 },
  srp: { avg: 15.05, min: 13.55, max: 16.56 },
  taxes: { avg: 0.89, min: 0.8, max: 0.98 },
  markUp: { avg: 3, min: 2.7, max: 3.3 },
  listPrice: { avg: 11.12, min: 10.01, max: 12.23 },
  dandA: { avg: 0.25, min: 0.23, max: 0.28 },
  ptr: { avg: 10.78, min: 9.7, max: 11.86 },
  cogs: { avg: 2.99, min: 2.69, max: 3.29 },
  gp: { avg: 7.88, min: 7.09, max: 8.67 },
};

const tableData = [
  {
    title: "TITLES",
    values: [
      "PTC",
      "Adherence",
      "SRP",
      "Taxes",
      "Markup",
      "List Price",
      "D&A",
      "PTR",
      "COGS",
      "Gross Profit",
    ],
  },
  {
    title: "MAX VALUE",
    values: [],
  },
  {
    title: "WEIGHTED AVERAGE VALUE​",
    values: [],
  },
  {
    title: "MIN  VALUE​",
    values: [],
  },
];

const tableHeaders = [
  { value: "manufacturer", label: "Manufacturer" },
  { value: "ptc", label: "PTC" },
  { value: "adherence", label: "Adherence" },
  { value: "srp", label: "SRP" },
  { value: "taxes", label: "Taxes" },
  { value: "markUp", label: "Markup" },
  { value: "listPrice", label: "List Price" },
  { value: "dandA", label: "D&A" },
  { value: "ptr", label: "PTR" },
  { value: "cogs", label: "COGS" },
  { value: "gp", label: "Gross Profit" },
  { value: "mix", label: "Mix$" },
];

const exportObject = {
  primaryFilter,
  productFilter,
  measureFilter,
  chartData,
  tableData,
  titleMapper,
  tableHeaders
};

export default exportObject;
