import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import _ from "lodash";

import Indicator from "../../../../components/Loader";
import FilterAccordion from "../../../../components/Filters/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import {
  initialState,
  dateRange,
} from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import { dataFormat } from "../../../../util/helper";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { clearGeoFilters } from "../../../../store/actions/geoFilter.action";
import MeasureFilter from "../MeasureFilters/MeasureFilter";

const initialMeasureFilter = {
  periodView: "fullYear",
  currentVs: "immediatePreviousPeriod",
  variable: null,
  dataSource: null,
};
const TopFilters: React.FC<{ callback; defaultFilters }> = ({
  callback,
  defaultFilters,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [clearGeoFilterData, clearGeoFilter] = useState(false);
  const [macroEconVariables, setMacroEconVariables] = useState();

  const [selectedMeasureFilters, setMeasureFilter] = useState({});

  const [defaultDate, setDefaultDate] = useState([
    new Date("01/01/2021"),
    new Date("12/31/2021"),
  ]);
  const [selectedDate, setSelectedDate] = useState(
    `${dataFormat(dateRange[0], "MM/dd/yyyy")}-${dataFormat(
      dateRange[1],
      "MM/dd/yyyy"
    )}`
  );
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const geoFilter = useSelector((state: any) => state.geoFilter);

  const reduxState = useSelector((state: any) => state);

  const MacroEconDashboard = useSelector(
    (state: any) => state.MacroEconDashboard
  );

  useEffect(() => {
    let measureFilterPayload = { ...initialMeasureFilter };
    if (
      defaultFilters &&
      _.isObject(defaultFilters) &&
      Object.keys(defaultFilters).length > 0
    ) {
      measureFilterPayload = {
        ...(defaultFilters?.periodView && {
          periodView: defaultFilters?.periodView,
        }),
        ...(defaultFilters?.currentVs && {
          currentVs: defaultFilters?.currentVs,
        }),
        ...(defaultFilters?.variable && { variable: defaultFilters?.variable }),
        ...(defaultFilters?.dataSource && {
          dataSource: defaultFilters?.dataSource,
        }),
      };

      if (defaultFilters?.startDate && defaultFilters?.endDate) {
        setDefaultDate([
          new Date(defaultFilters?.startDate),
          new Date(defaultFilters?.endDate),
        ]);
      }
    }
    setMeasureFilter(measureFilterPayload);
  }, [defaultFilters]);
  const onApplyFilter = () => {
    const payload = {
      ...selectedGeoFilters,
      ...selectedMeasureFilters,
      startDate: selectedDate.split("-")[0],
      endDate: selectedDate.split("-")[1],
    };
    callback({
      filters: payload,
      macroEconVariables,
      selectedMeasureFilters,
    });
  };

  const clearFilter = () => {
    dispatch(clearGeoFilters());
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setMeasureFilter({ ...initialMeasureFilter });
  };
  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={
          !reduxState.common.loader &&
          (MacroEconDashboard.varDataSrcLoader ||
            MacroEconDashboard.macroEconLoader ||
            MacroEconDashboard.productFilterLoader ||
            MacroEconDashboard.macroEconCheckLoader ||
            loader ||
            geoFilter.countriesLoading ||
            geoFilter.geoLevelLoading)
        }
      />
      <CardContent>
        <FilterAccordion title="Geo-Filters">
          <GeoFiltersV2
            data={geoFilterV2Config}
            onChangeDate={(dt) => {
              clearGeoFilter(false);
              setSelectedDate(dt);
            }}
            onSelectFilters={setSelectedGeoFilters}
            defaultDate={defaultDate}
            clearFilter={clearGeoFilterData}
            apiPath="MicroEcon"
            showLoader={setLoader}
            defaultFilters={defaultFilters}
          />
        </FilterAccordion>
        <FilterAccordion title="Measure Filters">
          <MeasureFilter
            geoFilters={selectedGeoFilters}
            callback={(e) => setMeasureFilter(e)}
            selectedMeasureFilters={selectedMeasureFilters}
            macroEconVariables={setMacroEconVariables}
            clearFilter={clearGeoFilterData}
          />
        </FilterAccordion>
        <Grid className="p-l-16">
          <OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={
              geoFilter.loading ||
              !selectedGeoFilters.country ||
              !selectedGeoFilters.geoLevel ||
              !selectedGeoFilters.channel ||
              !selectedGeoFilters.geoLevel2 ||
              !selectedMeasureFilters.variable ||
              !selectedMeasureFilters.dataSource ||
              !selectedDate
            }
            color="primary"
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
