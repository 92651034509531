import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DashboardActions from '../../components/DashboardActions';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { pageList } from '../../mocks/common';
import data from '../../mocks/sellOutDashboard';
import { removeMMPWFromLocal, storeMMPWData } from '../../util/helper';
import MultilevelTableContainer from './components/MultilevelTableContainer';
import PriceTrackingAPIFilters from './components/PriceTrackingAPIFilters';
import SalesValueAndPerformanceContainer from './components/SalesValueAndPerformanceContainer';
import SellOutRetailPriceTable from './components/SellOutRetailPriceTable';
import SellVsSuggestedFilter from './components/SellVsSuggestedFilter';
import SOPvsSRPGraph from './components/SOPvsSRPGraph';
import TopFilters from './components/TopFilters';

const initialState = {
  data: {},
  loading: true,
  error: '',
};

const SellOutDashboard: React.FC = () => {
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [isLoading, setisLoading] = useState(true);
  const [selloutVsSuggestedFilters, setSelloutVsSuggestedFilters] = useState<any>({});
  const [SelloutVsSuggestedFilterswithTaxMarkups, setSelloutVsSuggestedFilterswithTaxMarkups] = useState<any>({});
  const [defaultFilters, setDefaultFilters] = useState<any>({});
  const [defaultselloutVsSuggestedFilters, setDefaultselloutVsSuggestedFilters] = useState<any>({});
  const [priceTrackingAPIFilters, setPriceTrackingAPIFilters] = useState<any>({});
  const [defaultpriceTrackingAPIFilters, setDefaultPriceTrackingAPIFilters] = useState<any>({});
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();

  const onApplyFilter = async (data) => {
    if (data.type === 'FILTER_APPLIED') {
      setDisableDownloadButton(false);
      removeMMPWFromLocal('sales-value-trends-and-sop-performance');
      setFilters(data.filters);
    }
    setisLoading(true);
  };

  const isDisabled: boolean = true;
  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData: any = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      setDisableDownloadButton(false);
      setFilters(savedDashboardData?.filters?.global);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setDefaultselloutVsSuggestedFilters(savedDashboardData?.filters?.selloutVsSuggestedFilters);
      setSelloutVsSuggestedFilters(savedDashboardData?.filters?.selloutVsSuggestedFilters);
      setSelloutVsSuggestedFilterswithTaxMarkups(savedDashboardData?.filters?.SelloutVsSuggestedFilterswithTaxMarkups);
      setDefaultPriceTrackingAPIFilters(savedDashboardData?.filters?.priceTrackingAPIFilters);
      storeMMPWData(savedDashboardData?.mmpw);
    }
  };

  return (
    <>
      <DashboardActions
        title='Sell Out Dashboard'
        data={{
          filters: {
            global: { ...appliedFilters },
            selloutVsSuggestedFilters: { ...selloutVsSuggestedFilters },
            SelloutVsSuggestedFilterswithTaxMarkups: {
              ...SelloutVsSuggestedFilterswithTaxMarkups,
            },
            priceTrackingAPIFilters: { ...priceTrackingAPIFilters },
          },
          mmpwKey: 'sales-value-trends-and-sop-performance',
        }}
        dashboardKey={pageList[5].id}
        callback={getSaveDashBoardsData}
        disableDownloadButton={disableDownloadButton || isLoading}
      >
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[5].value} />
        </Grid>
        <Box className='m-b-20'>
          <TopFilters callback={onApplyFilter} data={data} commonLoader={isLoading} defaultFilters={defaultFilters} />
        </Box>
        <SalesValueAndPerformanceContainer appliedFilters={appliedFilters} isLoading={isLoading} setisLoading={setisLoading} />
        <MultilevelTableContainer appliedFilters={appliedFilters} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <PriceTrackingAPIFilters
              globalFilters={appliedFilters}
              callback={setPriceTrackingAPIFilters}
              defaultFIlters={defaultpriceTrackingAPIFilters}
            />
          </Grid>
          <Grid item xs={12} md={12} spacing={2}>
            <SellVsSuggestedFilter
              callback={setSelloutVsSuggestedFilters}
              globalFilters={appliedFilters}
              defaultFIlters={defaultselloutVsSuggestedFilters}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <SellOutRetailPriceTable
            globalFilters={appliedFilters}
            selloutVsSuggestedFilters={selloutVsSuggestedFilters}
            callback={setSelloutVsSuggestedFilterswithTaxMarkups}
          />
        </Grid>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SOPvsSRPGraph globalFilters={appliedFilters} sopAndSrpFiltersWithTaxMarkups={SelloutVsSuggestedFilterswithTaxMarkups} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DashboardSourceList category={appliedFilters.category} dashboardName={pageList[5].id} appliedFilters={appliedFilters} />
      </DashboardActions>
    </>
  );
};

export default SellOutDashboard;
