import styled from 'styled-components';
import { Box, ListItem, Switch } from '@mui/material';
import { Typography } from '@mui/material';

const BlackTitle = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: 20, 
  fontWeight: 600,
  marginBottom: 20
}));

const GraphCard = styled(Box)(({ theme }) => ({
  marginRight: '10px',
}));

const ColorBox = styled(Box)(({ theme }) => ({
  width: '15px',
  height: '15px',
  margin: '0px 3px',
  display: 'inline-block',
}));

const GraphCardTitle = styled(Box)(({ theme }) => ({
  fontSize: '15px',
  marginBottom: '10px',
  color: '#000',
}));

const ListProduct = styled(ListItem)(({ theme }) => ({
  color: '#000',
  paddingTop: '1px',
  paddingBottom: '3px',
  fontSize: '9px',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '23px',
  color: '#000',
  padding: '15px 0px 0px 20px',
  fontWeight: '500',
}));

const GraphSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: '#000',
  fontWeight: '600',
}));

const ProductContainer = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'scroll',
}));

const LegendBox = styled(Box)<any>`
  height: 20px;
  width: 20px;
  backgroundcolor: ${(props) => props.bgcolor};
  borderradius: 3px;
`;

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#25d4cf',
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'gray'
  },
}));

export { ProductContainer, GraphSubTitle, BlackTitle, GraphCard, GraphCardTitle, ListProduct, ColorBox, CardTitle, LegendBox, CustomSwitch };
