import { SIMULATION_CURRENT_VALUE_DATA, SIMULATION_PREDICTIVE_VALUE_DATA, VIEW_TYPE_DATA, QUADRANT_INTENSITY } from './constants';

let intiState = {
  simulationCurrentValueDataLoader: false,
  simulationPredictiveValueDataLoader: false,
  viewTypeDataLoader: false,
  quadrantIntensityLoader: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case SIMULATION_CURRENT_VALUE_DATA:
      return {
        ...state,
        simulationCurrentValueDataLoader: action.payload,
      };
    case SIMULATION_PREDICTIVE_VALUE_DATA:
      return {
        ...state,
        simulationPredictiveValueDataLoader: action.payload,
      };
    case VIEW_TYPE_DATA:
      return {
        ...state,
        viewTypeDataLoader: action.payload,
      };
    case QUADRANT_INTENSITY:
      return {
        ...state,
        quadrantIntensityLoader: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
