import { Card, CardContent, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { BlackTitle, Title } from '../../../../styles/Common.Styled';
import { useDispatch } from 'react-redux';
import '../priceLadder.css';
import PriceLadderSRP from '../PriceLadderSRP/PriceLadderSRP';
import PriceLadderMainCompetitor from '../PriceLadderMainCompetitor/PriceLadderMainCompetitor';
import { fetchPriceLadderMainCompetitorData, fetchPriceLadderSrpData } from '../../service';
import { priceLadderDataLoader } from '../../store/PriceLadder.action';
import { toast } from '../../../../store/actions/toast.action';
import Indicator from '../../../../components/Loader';
import Dashboard from '../../../../components/Dashboard/Dashboard';
import { rendarImage } from '../../../../util/helper';
import _ from 'lodash';

const PriceLadderGraph: React.FC<{
  filters;
  defaultFilters?;
  setUpdatedGraphData;
  updatedGraphData;
}> = ({ filters, defaultFilters, setUpdatedGraphData, updatedGraphData }) => {
  const dispatch = useDispatch();
  const [vendors, setVendors] = React.useState([]);
  const [SRPData, setSRPData] = React.useState([]);
  const [MainCompetitorData, setMainCompetitorData] = React.useState([]);
  const [callGraph, setCallGraph] = React.useState('');
  const [graphRendering, setGraphRendering] = React.useState(false);
  const [initialApiResponse, setInitialApiResponse] = React.useState([]);
  const [noDataFlag, setNoDataFlag] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const getSRPGraphData = async (payload) => {
    const requestPayload = { ...payload };
    setLoader(true);
    await fetchPriceLadderSrpData(requestPayload)
      .then(async (response) => {
        setNoDataFlag(response.data.graphData.length === 0);

        if (response && response.data) {
          if (response.data.graphData.length === 0) {
            setNoDataFlag(true);
          }
          const initData = _.cloneDeep(response.data.graphData);
          const graphData = _.cloneDeep(response.data.graphData);

          await Promise.all(
            response.data.vendors.map(async (vendor, i) => {
              const img = await rendarImage(vendor.logo.split('/')[vendor.logo.split('/').length - 1], filters.country);
              vendor['logoImg'] = img;
            })
          );
          await Promise.all(
            graphData.map(async (g, i) => {
              await Promise.all(
                g.products.map(async (p, j) => {
                  let img;
                  img = await rendarImage(p.img.split('/')[p.img.split('/').length - 1], filters.country);
                  p['imagBlob'] = img;
                })
              );
            })
          );


          setInitialApiResponse(initData);
          const jsonObj = JSON.stringify(initData);
          localStorage.setItem('srpGraphData', jsonObj);
          setVendors(response.data.vendors);
          setSRPData(graphData);
          setCallGraph(defaultFilters.priceLadderBy || filters.priceLadderBy);
          setLoader(false);
          setGraphRendering(true);
        }
        dispatch(priceLadderDataLoader(false));
      })
      .catch((e) => {
        dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
        dispatch(priceLadderDataLoader(false));
        setLoader(false);
      });
  };

  const getMainCompetitorGraphData = async (payload) => {
    const requestPayload = { ...payload };
    setLoader(true);
    await fetchPriceLadderMainCompetitorData(requestPayload)
      .then(async (response) => {
        setNoDataFlag(response.data.mainCompetitorGraph.pepsiProducts.length === 0);
        if (response && response.data) {
          const jsonObjpepsiProducts = JSON.stringify(_.cloneDeep(response.data.mainCompetitorGraph.pepsiProducts));
          localStorage.setItem('pepsiProducts', jsonObjpepsiProducts);

          const jsonObjcompetitorProducts = JSON.stringify(_.cloneDeep(response.data.mainCompetitorGraph.competitorProducts));
          localStorage.setItem('competitorProducts', jsonObjcompetitorProducts);

          await Promise.all(
            response.data.vendors.map(async (vendor, i) => {
              const img = await rendarImage(vendor.logo.split('/')[vendor.logo.split('/').length - 1], filters.country);
              vendor['logoImg'] = img;
            })
          );
          await Promise.all(
            response.data.mainCompetitorGraph.pepsiProducts.map(async (g, i) => {
              let img;
              img = await rendarImage(g.img.split('/')[g.img.split('/').length - 1], filters.country);
              g['imagBlob'] = img;
            })
          );
          await Promise.all(
            response.data.mainCompetitorGraph.competitorProducts.map(async (g, i) => {
              let img;
              img = await rendarImage(g.img.split('/')[g.img.split('/').length - 1], filters.country);
              g['imagBlob'] = img;
            })
          );

          setVendors(response.data.vendors);
          setMainCompetitorData(response.data.mainCompetitorGraph);
          setCallGraph(defaultFilters.priceLadderBy || filters.priceLadderBy);
          setGraphRendering(true);
          dispatch(priceLadderDataLoader(false));
          setLoader(false);
        }
      })
      .catch((e) => {
        dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
        dispatch(priceLadderDataLoader(false));
        setLoader(false);
      });
  };

  useEffect(() => {
    if (filters && filters.geoLevel2) {
      setCallGraph('');
      setNoDataFlag(false);
      if (filters.priceLadderBy === 'suggestedretailprice') {
        getSRPGraphData(filters);
      } else {
        getMainCompetitorGraphData(filters);
      }
    }
  }, [filters]);

  useEffect(() => {
    setUpdatedGraphData(SRPData);
  }, [SRPData]);

  useEffect(() => {
    setUpdatedGraphData(MainCompetitorData);
  }, [MainCompetitorData]);

  return (
    <>
      <Indicator position='absolute' show={loader} />
      {graphRendering && callGraph && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Dashboard
                  title={null}
                  showSkeleton={false}
                  chartRef={() => {}}
                  showActionButton={true}
                  actionButtons={{
                    mmpw: false,
                    screenshot: true,
                    editMedia: false,
                  }}
                  id={null}
                >
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid xs display='flex' justifyContent='center' alignItems='center' className='m-b-20 m-t-20'>
                      {noDataFlag ? (
                        <BlackTitle fontSize={24} fontWeight={600} color='red'>
                          No data found!
                        </BlackTitle>
                      ) : (
                        <BlackTitle fontSize={24} fontWeight={600}>
                          {filters.priceLadderBy === 'maincompetitor' ? 'Price Ladder by Main Competitor ' : 'Price Ladder by Suggested Retail Price'}
                        </BlackTitle>
                      )}
                    </Grid>
                  </Grid>
                  {callGraph && callGraph === 'suggestedretailprice' && (
                    <PriceLadderSRP
                      filters={filters}
                      defaultFilters={defaultFilters}
                      vendors={vendors}
                      SRPData={SRPData}
                      setSRPData={setSRPData}
                      initApiRes={initialApiResponse}
                    />
                  )}
                  {callGraph && callGraph === 'maincompetitor' && (
                    <PriceLadderMainCompetitor
                      filters={filters}
                      defaultFilters={defaultFilters}
                      vendors={vendors}
                      MainCompetitorData={MainCompetitorData}
                      setMainCompetitorData={setMainCompetitorData}
                    />
                  )}
                </Dashboard>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default PriceLadderGraph;
