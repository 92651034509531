export default new class Utilities {
  sortBy: any;
  compareArraysAsc = (a, b) => {
    const value1 = a[this.sortBy] && isNaN(a[this.sortBy]) ? a[this.sortBy].toUpperCase() : a[this.sortBy]
    const value2 = b[this.sortBy] && isNaN(b[this.sortBy]) ? b[this.sortBy].toUpperCase() : b[this.sortBy]

    let comparison = 0
    if (value1 > value2) {
      comparison = 1
    } else if (value1 < value2) {
      comparison = -1
    }
    return comparison
  }

  compareArraysDesc = (a, b) => {
    const value1 = a[this.sortBy] && isNaN(a[this.sortBy]) ? a[this.sortBy].toUpperCase() : a[this.sortBy]
    const value2 = b[this.sortBy] && isNaN(b[this.sortBy]) ? b[this.sortBy].toUpperCase() : b[this.sortBy]

    let comparison = 0
    if (value1 < value2) {
      comparison = 1
    } else if (value1 > value2) {
      comparison = -1
    }
    return comparison
  }

  sortArray = (array, sortyBy, type = "asc") => {
    this.sortBy = sortyBy
    type === 'asc' ? array.sort(this.compareArraysAsc) : array.sort(this.compareArraysDesc)
    return array
  }
}