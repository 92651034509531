import { styled, Button, Box } from "@mui/material";
import { BlackInputTitle } from "../../styles/Common.Styled";

const BlackTextBtn = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  fontSize: 14,
  color: theme.palette.common.black,
}));

const Title = styled(BlackInputTitle)(({}) => ({
  fontSize: 20,
  fontWeight: 600,
}));

const Label = styled(Box)(() => ({
  color: "#000",
  backgroundColor: "#fff",
  height: 46,
  borderRadius: 5,
  width: 197,
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  padding: "8px 14px",
}));

export { BlackTextBtn, Title, Label };
