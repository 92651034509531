import _ from 'lodash';
import { formatNumber } from '../../../util/helper';

const incrementalityGraphConfig = {
  chart: {
    backgroundColor: 'transparent',
  },
  title: null,
  xAxis: {
    gridLineWidth: 1,
    categories: [],
    labels: {
      formatter: function () {
        return `$${this.value}`;
      },
    },
  },
  yAxis: [
    {
      lineWidth: 1,
      title: {
        text: 'Units Variation %',
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 1,
      opposite: true,
      title: null,
    },
  ],
  plotOptions: { series: { marker: false } },
  series: [
    {
      type: 'column',
      name: 'Elasticity Projections',
      data: [],
      color: '#598ee3',
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y.toFixed(2));
        },
      },
    },
    {
      type: 'column',
      name: 'Historical Price Point Incrementality',
      data: [],
      color: '#d41165',
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y.toFixed(2));
        },
      },
    },
    {
      type: 'line',
      name: 'Gross Profit Breakeven',
      data: [],
      color: '#00AA52',
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y.toFixed(2));
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: false,
};

export { incrementalityGraphConfig };
