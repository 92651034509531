import { Box, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useRef } from "react";
import Dashboard from "../../../../components/Dashboard/Dashboard";
import { chartConfig } from "../MacroEconCard/chartsConfig";
import HighchartContainer from "../../../../components/HighchartContainer";

const MacroEconCheck: React.FC<{ data; skeleton? }> = ({ data, skeleton }) => {
  const chartRef = useRef(null);
  const highchartConfig = {
    ...chartConfig,
    title: {
      text: data.title,
      margin: 30,
      style: {
        fontSize: 18,
        fontWeight: 600,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      },
    },
  };
  const setLabels = (chart) => {
    if (data && data.title) {
      chart.yAxis[0].setTitle({ text: data.title });
      chart.legend.update({
        enabled: false,
      });
    }
  };
  useEffect(() => {
    if (data && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      setLabels(chart);
      chart.xAxis[0].setCategories(data.xAxis);
      chart.series[0].setData(data.yAxis);
    }
  }, [data]);

  return (
    <Dashboard
      title={null}
      showSkeleton={skeleton}
      chartRef={chartRef}
      id={data.id}
      key={data.id}
    >
      <Grid
        item
        xs={12}
        md={12}
        border={"1px solid #DCDCE3"}
        borderRadius={"5px"}
      >
        {!skeleton && (
          <HighchartContainer chartRef={chartRef} id={data.id}>
            <HighchartsReact
              highcharts={Highcharts}
              options={highchartConfig}
              ref={chartRef}
            />
          </HighchartContainer>
        )}
        {skeleton && <Skeleton variant="rectangular" height={400} />}
      </Grid>
    </Dashboard>
  );
};

export default MacroEconCheck;
