import { Box, Card, CardContent, Grid, Skeleton, TextField } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from '../../../../components/Dashboard';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import GradientTable from '../../../../components/GradientTable';
import HighchartContainer from '../../../../components/HighchartContainer';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import data from '../../../../mocks/profitParabola';
import { loader } from '../../../../store/actions/common.action';
import { getParabolaSimulationChartData } from '../../service';
import { BlackInputTitle, NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { convertSVGToBase64, inputRegex, plotElementsOnChart, removeElementsOnChart, rendarImage, roundDecimal } from '../../../../util/helper';
import { ParabolaSimulationConfig } from '../../chartsConfig';
import { Label } from '../../ProfitParabolaDashboard.Styled';
const ParabolaSimulations: React.FC<{ callback; filters; defaultFilters }> = ({ callback, filters, defaultFilters }) => {
  const dispatch = useDispatch();
  const chartRef = React.useRef(null);
  const [simulationTablePageCount, setSimulationTablePageCount] = useState(1);
  const [skeleton, setSkeleton] = useState(true);
  const [inputBoxSkeleton, setInputBoxSkeleton] = useState(true);
  const [inputProduct, setInputProduct] = useState({
    sdv: null,
    taxes: '8',
    markup: '25',
    cogs: null,
    sop: null,
    srp: null,
  });
  const [parabolaSimulationTableValues, setParabolaSimulationTableValues] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [parabolaSimulationChartData, setParabolaSimulationChartData] = useState({});
  const [markupOrMarkdown, setMarkupOrMarkdown] = useState(data.markUpAndMarkDown.defaultOption.value);
  const formatSimulationChartData = async (response) => {
    if (response.directprofit && response.selloutprice) {
      let xAxis = [];
      let yAxis1 = [];
      let yAxis2 = [];
      response.simulation.map((item) => {
        xAxis.push(item.pepsicoProductPrice.toFixed(2));
        yAxis1.push(item.dprc);
        yAxis2.push(item.sellOutRC);
      });
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.xAxis[0].setTitle({ text: `Price ${response.pepsicoProduct}` });
        chart.xAxis[0].setCategories(xAxis);
        chart.series[0].setData(response.selloutprice.map((i) => i.selloutPrice));
        chart.series[1].setData(response.directprofit.map((i) => i.profit));
        chart.xAxis[0].addPlotLine({
          color: '#d41165',
          width: 2,
          value: _.isArray(response.simulation) && response.simulation.findIndex((x) => x.dprc === response.maxProfit),
          dashStyle: 'ShortDash',
          label: { text: `DP: $${Math.round(response.maxProfit)}` },
          zIndex: 1,
          type: 'line',
          id: 'maxProfit',
        });
        chart.xAxis[0].addPlotLine({
          color: '#598ee3',
          width: 2,
          value: _.isArray(response.simulation) && response.simulation.findIndex((x) => x.sellOutRC === response.maxSellOut),
          dashStyle: 'ShortDash',
          label: { text: `SOP: $${Math.round(response.maxSellOut)}` },
          zIndex: 1,
          type: 'line',
          id: 'maxSellOut',
        });
        let productName =
          filters.subBrand && filters.subBrand.length > 0 ? filters.subBrand[0] : filters.brand && filters.brand.length > 0 ? filters.brand[0] : '';
        const product1 = await getbase64Image(
          `${process.env.REACT_APP_BACKEND_BASE_URL}files/products/${filters.country}/${productName}`,
          productName
        );
        productName =
          filters.anchorSubBrand && filters.anchorSubBrand.length > 0
            ? filters.anchorSubBrand[0]
            : filters.anchorBrand && filters.anchorBrand.length > 0
            ? filters.anchorBrand[0]
            : '';
        const product2 = await getbase64Image(
          `${process.env.REACT_APP_BACKEND_BASE_URL}files/products/${filters.country}/${productName}`,
          productName
        );
        setProductImages([product1, product2]);
      }
    }
  };
  const getbase64Image = async (url, productName) => {
    try {
      const blob = await rendarImage(productName, filters.country);
      if (blob) {
        return { base64: blob };
      } else {
        return { productName };
      }
    } catch (e) {
      return { productName };
    }
  };
  useEffect(() => {
    if (productImages.length > 0 && chartRef && chartRef.current && chartRef.current.chart) {
      let flag = true;
      $(window).resize(function () {
        if (flag) {
          setTimeout(() => {
            flag = true;
            if (chartRef && chartRef.current && chartRef.current.chart) {
              const chart = chartRef.current.chart;
              removeElementsOnChart('parabola-visual');
              plotImages();
            }
          }, 1000);
          flag = false;
        }
      });
      plotImages();
    }
  }, [productImages]);
  const plotImages = async () => {
    const chart = chartRef.current.chart;
    var plotline = chart.xAxis[0].plotLinesAndBands[0];
    if (plotline) {
      const payload = { Image: [] };
      let x = -50;
      const allProductImages = [...productImages];
      await Promise.all(
        allProductImages.map(async (item, i) => {
          if (item.base64) {
            payload.Image.push({
              type: 'Image',
              base64: item.base64,
              x: chart.xAxis[0].plotLinesAndBands[i].label.alignAttr.x,
              y: 365,
              width: 75,
              height: 60,
            });
            return;
          } else {
            return await convertSVGToBase64(item.productName, 50, 55).then((response: any) => {
              payload.Image.push({
                type: 'Image',
                base64: response.base64,
                x: chart.xAxis[0].plotLinesAndBands[i].label.alignAttr.x + 10,
                y: 370,
                width: 50,
                height: 55,
              });
            });
          }
        })
      );
      plotElementsOnChart(payload, chart, 'parabola-visual');
    }
  };
  const formatSimulationTableData = (response) => {
    data.parabolaSimulationTable.heading = [
      { title: 'Price ' + response.anchorProduct, formatNumber: true },
      { title: 'API', formatNumber: true },
      { title: 'Price ' + response.pepsicoProduct, formatNumber: true },
      { title: 'Units', formatNumber: false, separator: true },
      {
        title: 'Sell Out',
        gradient: true,
        formatNumber: false,
        separator: true,
      },
      {
        title: 'Direct Profit',
        gradient: true,
        formatNumber: false,
        separator: true,
      },
    ];
    if (response.simulation && response.simulation.length) {
      const values = response.simulation.map((item) => {
        return {
          anchorProductPrice: item.anchorProductPrice,
          api: item.api,
          pepsicoProductPrice: item.pepsicoProductPrice,
          unitsRC: Math.round(item.unitsRC),
          sellOutRC: Math.round(item.sellOutRC),
          dprc: Math.round(item.dprc),
        };
      });
      setParabolaSimulationTableValues(values);
    }
  };
  const viewMoreHandler = () => {
    if (simulationTablePageCount < parabolaSimulationTableValues.length) {
      setSimulationTablePageCount(simulationTablePageCount + 1);
    }
  };
  const setLabels = (chart) => {
    if (filters && filters.variable1) {
      chart.yAxis[0].setTitle({ text: 'Direct Profit' });
      chart.yAxis[1].setTitle({ text: 'Sell Out' });
    }
  };
  // const parabolaSimulationChartData = useSelector(
  //   (state: any) => state.ProfitParabolaParabolaSimulationChart
  // );
  const inputProductHandler = (type, input) => {
    if (input.match(/\./g)) {
      const [, decimal] = input.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    let temp = { ...inputProduct };
    temp[type] = parseFloat(input);
    setInputProduct(temp);
  };
  const setInputValues = (data) => {
    setInputProduct({
      ...inputProduct,
      cogs: data.cogs.toFixed(2),
      sdv: data.sdv.toFixed(2),
      sop: data.sop.toFixed(2),
      srp: roundDecimal(data.srp).toFixed(2),
    });
    callback(inputProduct);
  };
  useEffect(() => {
    if (parabolaSimulationChartData && parabolaSimulationChartData.data) {
      setSkeleton(false);
      setInputBoxSkeleton(false);
      dispatch(loader(false));
      setInputValues(parabolaSimulationChartData.data);
      formatSimulationTableData(parabolaSimulationChartData.data);
      setTimeout(() => {
        setProductImages([]);
        formatSimulationChartData(parabolaSimulationChartData.data);
      }, 0);
    }
  }, [parabolaSimulationChartData]);
  useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      setInputBoxSkeleton(true);
      const payload = {
        sdv: null,
        taxes: '8',
        markup: '25',
        cogs: null,
        sop: null,
        srp: null,
      };
      setInputProduct(defaultFilters && Object.keys(defaultFilters).length > 0 ? defaultFilters : payload);
      setParabolaSimulationTableValues([]);
      getParabolaSimulationChartData({
        ...filters,
        ...payload,
        taxes: parseFloat(payload.taxes) / 100,
        markup: parseFloat(payload.markup) / 100,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            setParabolaSimulationChartData(response);
          } else {
            setSkeleton(false);
            setInputBoxSkeleton(false);
            dispatch(loader(false));
          }
        })
        .catch((err) => {
          setSkeleton(false);
          setInputBoxSkeleton(false);
          console.log(err);
        });
    }
  }, [filters]);
  const getSRP = () => {
    if (inputProduct.sdv && inputProduct.cogs && inputProduct.markup && inputProduct.taxes) {
      setParabolaSimulationTableValues([]);
      getParabolaSimulationChartData({
        ...filters,
        ...inputProduct,
        taxes: parseFloat(inputProduct.taxes) / 100,
        markup: parseFloat(inputProduct.markup) / 100,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            setParabolaSimulationChartData(response);
          } else {
            setSkeleton(false);
            setInputBoxSkeleton(false);
            dispatch(loader(false));
          }
        })
        .catch((err) => {
          setSkeleton(false);
          setInputBoxSkeleton(false);
          console.log(err);
        });
    } else if (inputProduct.srp) {
      getParabolaSimulationChartData({
        ...filters,
        ...inputProduct,
        taxes: parseFloat(inputProduct.taxes) / 100,
        markup: parseFloat(inputProduct.markup) / 100,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            setParabolaSimulationChartData(response);
          } else {
            setSkeleton(false);
            setInputBoxSkeleton(false);
            dispatch(loader(false));
          }
        })
        .catch((err) => {
          setSkeleton(false);
          setInputBoxSkeleton(false);
          console.log(err);
        });
    }
  };
  const onChangeInputPepsiCo = (e) => {
    if (e.keyCode == 13) {
      getSRP();
    } else {
      return inputRegex(e);
    }
  };
  const onChangeInputCompetitor = (e) => {
    if (e.keyCode == 13) {
      getSRP();
    } else {
      return inputRegex(e);
    }
  };
  const splitText = (str) => {
    const result = str.match(/.{1,6}/g);
    if (result.length > 5) {
      result.splice(5, result.length);
      result[4] = `${result[4].split('', 3).join('')}...`;
    }
    const length = result.length;
    const startY = length === 1 ? 24 : length === 5 || length === 4 ? 8 : 16;
    return (
      <text transform='translate(20)' x='0' y='50' fontSize='20' style={{ zIndex: 9 }}>
        {result.map((item, index) => (
          <tspan x={0} y={startY + 8 * index} text-anchor='middle' style={{ fontSize: 7 }}>
            {item}
          </tspan>
        ))}
      </text>
    );
  };
  return (
    <Card className='m-b-20'>
      {skeleton || (filters && filters.packSize && _.isArray(filters.packSize) && filters.anchorPackSize) ? (
        <CardContent>
          <Dashboard
            title='Parabola Simulations'
            showSkeleton={skeleton}
            chartRef={chartRef}
            id='profit-parabola-simulations'
            showActionButton={_.isArray(parabolaSimulationTableValues) && parabolaSimulationTableValues.length > 0}
          >
            {inputBoxSkeleton ? (
              <>
                <Grid container spacing={2} className='m-b-20'>
                  <Grid item xs={12}>
                    <Skeleton variant='rectangular' width={250} height={30} className='m-b-10' />
                  </Grid>
                  {[...Array(5)].map((_, i) => (
                    <Grid item xs={12} sm={2} key={i}>
                      <Skeleton height={22} width={90} />
                      <Skeleton variant='rectangular' width={200} height={45} />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2} className='m-b-20'>
                  <Grid item xs={12}>
                    <BlackInputTitle className='f-w-5'>Input PepsiCo Product</BlackInputTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container display='flex' alignItems='center'>
                      <Grid className='m-r-20'>
                        <Box style={{ fontSize: 15, fontWeight: 600 }}>MarkUp/MarkDown -</Box>
                      </Grid>
                      <Grid>
                        <RadioButtonGroup
                          key={`markup-and-markdown`}
                          color='#000'
                          showButton={true}
                          data={data.markUpAndMarkDown.options}
                          defaultOption={markupOrMarkdown}
                          select={setMarkupOrMarkdown}
                          direction='row'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>SDV</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='sdv'
                      type='number'
                      size='small'
                      value={inputProduct.sdv || ''}
                      onChange={(e) => inputProductHandler('sdv', e.target.value)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>COGS</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='cogs'
                      type='number'
                      size='small'
                      value={inputProduct.cogs || ''}
                      onChange={(e) => inputProductHandler('cogs', e.target.value)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>{markupOrMarkdown === 'markup' ? 'Markup(%)' : 'Markdown(%)'}</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='markup'
                      type='number'
                      size='small'
                      value={inputProduct.markup || ''}
                      onChange={(e) => inputProductHandler('markup', e.target.value)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>Taxes(%)</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='taxes'
                      type='number'
                      size='small'
                      value={inputProduct.taxes || ''}
                      onChange={(e) => inputProductHandler('taxes', e.target.value)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>SRP</DropdownTitle>
                    <Label style={{ backgroundColor: '#dbe7f3' }}>{inputProduct.srp}</Label>
                  </Grid>
                </Grid>
              </>
            )}
            {skeleton ? (
              <Grid container spacing={2} className='m-b-20'>
                <Grid item xs={12}>
                  <Skeleton variant='rectangular' width={250} height={30} className='m-b-10' />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Skeleton height={22} width={90} />
                  <Skeleton variant='rectangular' width={200} height={45} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} className='m-b-20'>
                <Grid item xs={12}>
                  <BlackInputTitle className='f-w-5'>Input Competitor Product</BlackInputTitle>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle className='m-r-10'>SOP</DropdownTitle>
                  <TextField InputProps={{ autoComplete: 'off' }}
                    id='sop'
                    type='number'
                    size='small'
                    value={inputProduct.sop || ''}
                    onChange={(e) => inputProductHandler('sop', e.target.value)}
                    onKeyDown={onChangeInputCompetitor}
                  />
                </Grid>
              </Grid>
            )}
            {!skeleton ? (
              _.isArray(parabolaSimulationTableValues) && parabolaSimulationTableValues.length > 0 ? (
                <Grid container spacing={2} className='m-b-20'>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      maxHeight: 500,
                      overflow: 'auto',
                      paddingTop: 0,
                      marginTop: 10,
                    }}
                  >
                    <GradientTable
                      heading={data.parabolaSimulationTable.heading}
                      rowData={parabolaSimulationTableValues}
                      pageNumber={simulationTablePageCount}
                      offset={parabolaSimulationTableValues.length}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {filters.periodicity === 'Year' ? (
                      <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                        <NoData>{messages.noDataYearPeriodicity}</NoData>
                      </Box>
                    ) : (
                      <HighchartContainer chartRef={chartRef} id={'profit-parabola-simulations'}>
                        <HighchartsReact highcharts={Highcharts} options={ParabolaSimulationConfig} ref={chartRef} />
                      </HighchartContainer>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Box style={{ height: 300 }} display='flex' justifyContent='center' alignItems='center'>
                  <NoData>{messages.noData}</NoData>
                </Box>
              )
            ) : (
              <Grid container spacing={2} className='m-b-20'>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    maxHeight: 500,
                    overflow: 'auto',
                    paddingTop: 0,
                    marginTop: 10,
                  }}
                >
                  <GradientTable heading={data.parabolaSimulationTable.heading} rowData={[]} showSkeleton={skeleton} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton variant='rectangular' height={500} className='m-b-20' />
                </Grid>
              </Grid>
            )}
          </Dashboard>
        </CardContent>
      ) : (
        <CardContent>
          <Dashboard
            title='Parabola Simulations'
            showSkeleton={skeleton}
            chartRef={chartRef}
            id='profit-parabola-simulations'
            showActionButton={false}
          >
            <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.incorrectFilters}</NoData>
            </Box>
          </Dashboard>
        </CardContent>
      )}
      {productImages && productImages.length
        ? productImages.map((item) => {
            if (item.productName) {
              return (
                <svg viewBox='0 0 40 45' width='40' height='45' id={item.productName} style={{ display: 'none' }}>
                  <rect width='40' height='45' style={{ fill: '#fff', stroke: '#000', strokeWidth: 2 }} />
                  {splitText(item.productName)}
                </svg>
              );
            }
          })
        : null}
    </Card>
  );
};

export default ParabolaSimulations;
