import React from "react";
import Highcharts from "highcharts";
import { Grid } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import { ColumnDrilldownConfig } from "./ColumnDrilldownConfig";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const ColumnDrilldown: React.FC<{ data; xAxis?; chartRef; y1AxisLable?}> = ({
  data,
  xAxis,
  chartRef,
  y1AxisLable
}) => {
  const highchartOptions = ColumnDrilldownConfig(xAxis, data, y1AxisLable);
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartOptions}
          ref={chartRef}
        />
      </Grid>
    </Grid>
  );
};

export default ColumnDrilldown;
