import axios from "axios";
import {
  ERROE_STATE_PORTFOLIO,
  ERROR_STATE_GEO_LEVEL,
  GET_GEO_LEVEL_SUMMARY_DATA,
  GET_PORTFOLIO_DATA,
  LOADER_STATE_GEO_LEVEL,
  LOADER_STATE_PORTFOLIO,
  RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA,
  SET_GEO_LEVEL,
  SET_PORTFOLIO_LEVEL,
} from "./constant";
import { BACKEND_URL } from "../constants";
import { API } from "../../../util/helper";

export interface dispatchState {
  loaderStateFetch: any;
}

export const resetPortfolioSummaryState = () => ({
  type: RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA,
});
export const portfolioSummary = (payload) => {
  return {
    type: GET_PORTFOLIO_DATA,
    payload,
  };
};

export const setPortfolioLevel = (data) => ({
  type: SET_PORTFOLIO_LEVEL,
  payload: data,
});

export const setGeoLevel = (data) => ({
  type: SET_GEO_LEVEL,
  payload: data,
});

export const portfolioSummaryLoader = (payload) => {
  return {
    type: LOADER_STATE_PORTFOLIO,
    payload,
  };
};

export const portfolioSummaryError = (payload) => {
  return {
    type: ERROE_STATE_PORTFOLIO,
    payload,
  };
};

export const geoLevelSummaryLoader = (payload) => {
  return {
    type: LOADER_STATE_GEO_LEVEL,
    payload,
  };
};

export const geoLevelSummaryError = (payload) => {
  return {
    type: ERROR_STATE_GEO_LEVEL,
    payload,
  };
};

export const geoLevelSummaryResponse = (payload) => {
  return {
    type: GET_GEO_LEVEL_SUMMARY_DATA,
    payload,
  };
};

export const fetchApi = (
  payload,
  apiUrl,
  dispatchActions?: dispatchState | null,
  requestType?,
  fullApiPath?
): any => {
  return async (dispatch) => {
    if (dispatchActions) {
      dispatch(dispatchActions.loaderStateFetch);
    }
    try {
      const response = await API({
        method: requestType || "post",
        url: fullApiPath || `${BACKEND_URL}/api/${apiUrl}`,
        data: payload,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
};
