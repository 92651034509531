import {
  GET_USER,
  GET_USER_SUCESS,
  GET_USER_FAILURE
} from "./constants";
import { API, blobToBase64, getToken } from "../../util/helper";
import _ from "lodash";
import { loader } from "./common.action";

export const getUser = () => {
  return {
    type: GET_USER,
  };
};

export const getUserSucess = (payload: any) => {
  return {
    type: GET_USER_SUCESS,
    payload
  };
};

export const getUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
};

const getUserDetail = () => {
  return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}users/getuser`);
};

export function fetchUser() {
  return (dispatch: any) => {
    dispatch(getUser());
    dispatch(loader(true));
    getUserDetail()
      .then(async (response) => {
        const userData = {...response.data, profile: await fetchProfileImage(response.data.imageUrl)}
        dispatch(getUserSucess(userData));
        localStorage.setItem('user',JSON.stringify(userData))
        dispatch(loader(false));
      })
      .catch((error) => {
        dispatch(loader(false));
        dispatch(getUserFailure(error));
      });
  };
}

export const fetchProfileImage = async (url) =>{
  let response: any = { status: 404 };
  try {
    response = await fetch(url, {
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
      }
    );
  } catch (error) {
    // console.log(error);
  }
  if (response?.status === 200) {
    return await blobToBase64(await response.blob());
  }
}
