import * as React from 'react';
import {
	CardContent,
	Grid
} from '@mui/material';
import { Title, StyledCard, Item } from '../../MMPW.Styled';
import { FormatAlignCenterTwoTone, FormatAlignLeftTwoTone, FormatAlignRightTwoTone } from '@mui/icons-material';

const TextAlign: React.FC<{}> = ({ }) => {
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Title>Text Alignment</Title>
					</Grid>
					<Grid item xs={12}>
						<Item>
							<FormatAlignLeftTwoTone />
							<FormatAlignCenterTwoTone />
							<FormatAlignRightTwoTone />
						</Item>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>

	);
};

export default TextAlign;
