import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../store/actions/toast.action';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Toast: React.FC = () => {
  //type= success | error | warning | info
  const dispatch = useDispatch();
  const toastStore = useSelector((state: any) => state.toast);
  
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(toast('', false));
  };

  return (
      <Snackbar 
        open={toastStore.open} 
        autoHideDuration={toastStore.duration} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={toastStore.type} sx={{ width: toastStore.width }}>
          {toastStore.msg}
        </Alert>
      </Snackbar>
  );
}

export default Toast