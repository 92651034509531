import {
    FETCH_EXECUTION_AVG_DATA_FAILURE,
  FETCH_EXECUTION_AVG_DATA_REQUEST,
  FETCH_EXECUTION_AVG_DATA_SUCCESS,
  FETCH_EXECUTION_USER_INPUT_FAILURE,
  FETCH_EXECUTION_USER_INPUT_REQUEST,
  FETCH_EXECUTION_USER_INPUT_SUCCESS,
  SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA,
  SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA,
  SET_EXECUTION_SELECTED_USER_INPUT_DATA,
  SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT,
} from "../../actions/constants";

const initialState = {
  data: {
    assortment_scenario: [],
    mix_scenario: [],
    channel: [],
    Region: [],
    Storesegment: [],
  },
  selectedData: {
    assortment_scenario: [],
    mix_scenario: [],
  },
  weights: {
    goodToHaveSku: "0",
    mustHaveSku: "0",
  },
  currentAverage: {
    mustHaveCurrentAverage: "",
    goodToHaveCurrentAverage: "",
  },
  default: true,
  loading: true,
};

const executionUserInputs = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT:
      return initialState;
    case FETCH_EXECUTION_USER_INPUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXECUTION_USER_INPUT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          assortment_scenario: action.payload.data.assortment_scenario,
          mix_scenario: action.payload.data.mix_scenario,
        },
        loading: false,
        default: true,
      };
    case FETCH_EXECUTION_USER_INPUT_FAILURE:
      return {
        ...state,
        default: false,
        loading: false,
      };
    case SET_EXECUTION_SELECTED_USER_INPUT_DATA:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [action.payload?.name]:
            typeof action.payload.value === "string"
              ? [action.payload.value]
              : action.payload.value,
        },
        default: false,
      };
    case SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA:
      return {
        ...state,
        weights: {
          ...state.weights,
          mustHaveSku: action.payload,
        },
      };
    case SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA:
      return {
        ...state,
        weights: {
          ...state.weights,
          goodToHaveSku: action.payload,
        },
      };
    case FETCH_EXECUTION_AVG_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXECUTION_AVG_DATA_SUCCESS:
      return {
        ...state,
        currentAverage: action.payload.data,
        loading: false,
        error: null,
      };
    case FETCH_EXECUTION_AVG_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default executionUserInputs;
