import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? theme.palette.common.white : "#F2F8FC",
  color: theme.palette.common.black
}));

export { ExpandIcon };

