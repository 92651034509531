import React, { useEffect, useState } from "react";
import Goals from "../MixSimulation/component/Goals/Goals";
import {
  CardContent,
  Grid,
  Card,
  Divider,
  Typography,
  ThemeProvider,
  Stack,
  Button,
} from "@mui/material";
import UserConstraints from "./component/UserConstraints/UserConstraints";
import HorizontalDashedLine from "./component/DashedLine/DashedLine";

import MixOverallFilters from "./component/MixOverallFilters/MixOverallFilters";
import MixGeoFilters from "./component/MixGeoFilters/MixGeoFilters";
import MixTable from "./component/MixTable/MixTable";
import { useDispatch, useSelector } from "react-redux";
import { clearMixSimulationFiltersSuccess, fetchMixSimulationFiltersData, fetchMixSimulationFiltersRequest, fetchMixSimulationFiltersSuccess, getGeoFilterData, selectedMixSimulationFilters, updateMixSimulationFilters } from "../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import PortFolio from "./component/PortFolio/PortFolio";
import CircularProgress from '@mui/material/CircularProgress';

import {
  darkThemeForExecution,
  lightThemeForExecution,
} from "../ExecutionTool/components/Theme/theme";
import GeoLevel from "./component/GeoLevel/GeoLevel";
import OutputSummary from "./component/OutputSummary/OutputSummary";
import Spinner from "../AssortmentTool/components/Loader/Loader";
import DecisionVariable from "./component/DecisionVariables/DecisionVariables";
import MixScenarioCreation from "./component/MixScenario/MixScenarioCreation";
import SaveScenario from "./component/SaveScenario/SaveScenario";
import BubbleChart from "./component/BubbleChart/BubbleChart";
import { setMixSimulationGoal, setMixUserInputDetails } from "../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import HistoricalRange from "./component/HistoricalRange/HistoricalRange";
import PriceElasticity from "./component/PriceElasticity/PriceElasticity";
import SectionFilters from "./component/SectionFilters/SectionFilters";
import { dispatchState, fetchApi } from "../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../store/actions/toast.action";
import { fetchMixSimulationConstraintTableData, fetchMixSimulationConstraintTableFilter, loaderForMixScreen, setAllMixConstraintTableFilter } from "../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import { useHistory, useLocation } from "react-router-dom";
import { fetchMixSummaryData } from "../../store/actions/MixSimulationTool/mixSummary.action";
import { fetchMixGeoLevelData } from "../../store/actions/MixSimulationTool/mixGeoLevel.action";
import { fetchMixPortfolioData } from "../../store/actions/MixSimulationTool/mixPortfolio.action";
import { LightBlueBtn } from "../../styles/Common.Styled";
import { DownloadIcon } from "../../assets/icons/assorticons";
import { API_URL } from "../../store/actions/constants";
import { downloadInExcelCsvFormat } from "../AssortmentTool/components/CommonComponents/utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


const responseWait = "Please wait until the calculations are done..";
const responseDownloadWait = "";

const MixSimulation = () => {
  const history = useHistory();
  const location = useLocation();
  const { id, scenarioName } = location.state || {};
  console.log(id, scenarioName);
  const { outputScreen, selectedFilters } = useSelector(
    (state: any) => state.mixSimulationAllFilter
  );
  const { loaderMixScreen } = useSelector(
    (state: any) => state.mixSimulationConstraintTable
  );
  const { constraintsLoader } = useSelector(
    (state: any) => state.MixUserConstraintsReducer
  );
  const filters = useSelector((state: any) => state.mixSimulationAllFilter);
  const { isEditMode } = useSelector((state: any) => state.mixSimulationAllFilter);
  const { portfolioLevel } = useSelector(
    (state: any) => state.MixPortfolioReducer
  );
  const { geoLevel } = useSelector((state: any) => state.MixGeoLevelReducer);
  const dispatch = useDispatch();
  const [isOutputScreen, setIsOutputScreen] = useState(false);
  const { selectedGoal } = useSelector((state: any) => state.mixSimulationUserInput)

  const [isInitialScreen, setInitialScreen] = useState({
    filters: false,
    userInputs: false,
  });
  const [isDistribution, setIsDistribution] = useState(false);
  const [downloadButtonLoader,setDownloadButtonLoader] = useState(false)

  useEffect(() => {
    if (isInitialScreen.filters || isInitialScreen.userInputs) {
      setInitialScreen((prev) => {

        return {
          filters: filters.data.overall.country.length > 0 ? false : true,
          userInputs: filters.selectedFilters.storeSegment.length > 0 ? false : true,
        }
      })
    }


  }, [filters])
  const setSelectedStates = () => {
    if (isEditMode) {
      const dispatchActions: dispatchState = {
        loaderStateFetch: loaderForMixScreen(true)
      }
      dispatch(fetchApi(null, API_URL.MIXSIMULATION.SCENARIO_DETAILS + "?Uid=" + id, dispatchActions, 'GET'))
        .then((res) => {
          let data = res.data;
          dispatch(loaderForMixScreen(false))
          let filtersData = {
            country: [data.country],
            period: [data.dateTimePeriod],
            businessUnit: [data.businessUnit],
            level: [data.level],
            channel: data.channel,
            region: data.region,
            storeSegment: data.storeSegment,
          }
          // console.log("filtersData",filtersData)
          let userInputData = {
            scenarioName: data.scenarioName,
            selectedGoal: data.goal,
            uid: id,
            selectedAssortmentScenaioUid: data.assortmentScenarioUid
          }
          let inputGeoFilterPayload = {

            "availableDynamicFilters": [
              "level",
              "sub_chnl",
              "geo_level",
              "store_segment"
            ],
            "availableDynamicFiltersSelectedValues": {
              "level": filtersData.level,
              "sub_chnl": filtersData.channel,
              "geo_level": filtersData.region,
              "store_segment": filtersData.storeSegment
            },
            "overallFiltersSelectedValues": {
              "country": filtersData.country,
              "time_period": filtersData.period,
              "business_unit": filtersData.businessUnit,
              objective_function: [
                data.goal
              ],
            },
            "toolName": "mix_optimizer",
            "pageName": "mix_optimizer"
          };
          // console.log("inputGeoFilterPayload",inputGeoFilterPayload)
          const outputGeoFilterPayload = {

            availableDynamicFilters: ["Level", "Channel", "Region", "StoreSegment"],
            availableDynamicFiltersSelectedValues: {
              Level: filtersData.level,
              Channel: filtersData.channel,
              Region: filtersData.region,
              StoreSegment: filtersData.storeSegment,
            },
            overallFiltersSelectedValues: {
              Uid: [id.toUpperCase()],
              Country: filtersData.country,
              TimePeriod: filtersData.period,
              BusinessUnit: filtersData.businessUnit,
            },
            toolName: "mix_optimizer_output",
            pageName: "mix_optimizer_output",
          };

          let payloadOfDynamicFilter = outputScreen ? outputGeoFilterPayload : inputGeoFilterPayload;
          let payloadOverAllFilters = {
            overallFilters: {
              country: [
                data.country
              ],
              period:
                data.dateTimePeriod
              ,
              businessUnit: []
            },
            geo: {
              channel: [],
              region: [],
              storeSegment: []
            }
          }

          let dispatchAction: dispatchState = {
            loaderStateFetch: fetchMixSimulationFiltersRequest()
          }
          let geoFilterUrl = outputScreen ? "filter/mixOptimizerDynamicFilterForOutputScreen" : "filter/mixOptimizerDynamicFilter";
          let dispatchSequence = [
            dispatch(
              fetchMixSimulationFiltersData(payloadOverAllFilters, API_URL.MIXSIMULATION.OPTIMIZER_FILTER)),
            dispatch(updateMixSimulationFilters(filtersData)),
            dispatch(setMixUserInputDetails(userInputData)),
            dispatch(fetchApi(payloadOfDynamicFilter, geoFilterUrl, dispatchAction))
              .then(res => {
                if (outputScreen) {
                  const mapperKeyResult = {
                    channel: "sub_chnl",
                    region: "geo_level",
                    storeSegment: "store_segment",
                    level: "level",
                  };
                  const result = Object.keys(res.data).reduce((acc, key) => {
                    acc[mapperKeyResult[key]] = res.data[key];
                    return acc;
                  }, {});
                  // console.log("responseGetGeoFilterData",res.data)
                  dispatch(getGeoFilterData(result));

                } else {
                  dispatch(getGeoFilterData(res.data));

                }
                // allOutputApiCall(filtersData,userInputData);

              }),

            dispatch(fetchMixSimulationConstraintTableFilter({ default: true, uid: id })).then((res) => {
              // console.log(res)
              allOutputApiCall(filtersData, userInputData);
              let selectedMixConstraintFilters = {
                channel: [res.data.channel[0]],
                region: [res.data.region[0]],
                storeSegment: [res.data.storeSegment[0]]
              }
              dispatch(setAllMixConstraintTableFilter(selectedMixConstraintFilters))
              let tableDataPayload = {
                goal: data.goal,
                uid: id,
                filtersSelected: true,
                channel: res.data.channel[0],
                region: res.data.region[0],
                storeSegment: res.data.storeSegment[0]
              }
              dispatch(fetchMixSimulationConstraintTableData(tableDataPayload, API_URL.MIXSIMULATION.GET_MIX_CONTRAINT_TABLE_DATA));
            }),
          ]
          Promise.all(dispatchSequence).then(res => {
            dispatch(loaderForMixScreen(false));
          }).catch(err => {
            dispatch(loaderForMixScreen(false));
          })
        }).
        catch((err) => {
          dispatch(loaderForMixScreen(false));
          dispatch(toast('Something went wrong!', true, 2000, 'error'));
        })
      // dispatch(updateMixSimulationFilters({}))
    }


  }

  const allOutputApiCall = (filterData, inputData) => {
    if (outputScreen) {
      const outputPayload = {
        uid: id,
        goal: inputData.selectedGoal,
        region: filterData.region,
        storeSegment: filterData.storeSegment,
      };
      const geoLevelPayload = {
        ...outputPayload,
        level: geoLevel.length === 0 ? ["channel"] : geoLevel,
        goal: inputData.selectedGoal,
      };
      const portfolioPayload = {
        ...outputPayload,
        levels: [portfolioLevel],
        goal: inputData.selectedGoal,
      };
      dispatch(
        fetchMixSummaryData(
          outputPayload,
          API_URL.MIXSIMULATION.GET_MIX_SUMMARY_DATA)
      );
      dispatch(
        fetchMixPortfolioData(
          portfolioPayload,
          API_URL.MIXSIMULATION.GET_PORTFOLIO_DATA_AFTER_RUN_SIMULATION
        )
      );
      dispatch(
        fetchMixGeoLevelData(
          geoLevelPayload,
          API_URL.MIXSIMULATION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_RUN_SIMULATION
        )
      );
    }
  }
  const clearFilters = (e) => {

    dispatch(clearMixSimulationFiltersSuccess({
      data: {
        overall: {
          country: [],
          period: [],
          businessUnit: []
        },
        geo: {
          channel: [],
          region: [],
          storeSegment: []
        }
      }
    }));
    dispatch(setMixSimulationGoal(""))
    dispatch(fetchMixSimulationFiltersData({ default: true }, API_URL.MIXSIMULATION.OPTIMIZER_FILTER));
  };

  useEffect(() => {
    if (isEditMode) {
      setSelectedStates();

    } else {
      dispatch(
        fetchMixSimulationFiltersData({ default: true }, API_URL.MIXSIMULATION.OPTIMIZER_FILTER)
      );
    }
  }, []);

  const downloadAllMixData = () => {
    setDownloadButtonLoader(true);
    // console.log("hello")
    let payload = {
      uid: id,
      goal: selectedGoal,
      region: filters.selectedFilters.region,
      storeSegment: filters.selectedFilters.storeSegment,
      levels: [
        "region"
        ,
        "storeSegment"
        ,
        "channel"
        ,
        "sku"
        ,
        "segment"
        ,
        "brand"
      ]
    }
    // console.log("hello", payload)

    dispatch(fetchApi(payload, API_URL.MIXSIMULATION.GET_ALL_DOWNLOAD_DATA_AFTER_RUN_SIMULATION)).then((res) => {

      let fileName = "mix_all_data"
      let csvData = res.data;
      downloadInExcelCsvFormat(csvData, fileName)
      setDownloadButtonLoader(false);

    })

  }

  const getHistoricalCategory = (category) => {
    setIsDistribution(category);
  }

  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );
  return (
    <ThemeProvider
      theme={theme.darkmode ? darkThemeForExecution : lightThemeForExecution}
    >
      <Spinner text={loaderMixScreen ? responseWait : responseDownloadWait} show={loaderMixScreen} ></Spinner>
      <MixScenarioCreation />
      <MixOverallFilters showSkeleton={isInitialScreen.filters} isOutputScreen={isOutputScreen} />
      {outputScreen ?
        <></>
        : <Card
          className="m-b-20 m-t-10"
          style={{
            position: "relative",
            backgroundColor: "#F1F5FE",
          }}
        ><CardContent>
            <Goals />
          </CardContent>
        </Card>}
      <MixGeoFilters showSkeleton={isInitialScreen.filters} clearFilter={clearFilters} isOutputScreen={isOutputScreen} />

      {outputScreen ? (
        <>
          <Grid style={{ marginTop: "10px" }} container spacing={2}>
            <Grid item xs={12} md={6} style={{}}>
              <Typography
                style={{ marginBottom: "15px" }}
                color={"white"}
                variant="h5"
              >
                OUTPUT
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ whiteSpace: "nowrap", display: "flex", justifyContent: "end" }}
            >
              <>
                <LightBlueBtn onClick={downloadAllMixData} style={{ height: "35px", width: "110px" }}
                startIcon={downloadButtonLoader ? <CircularProgress style={{
              width: '20px', height: '20px',
            }} color="inherit" /> : <FileDownloadOutlinedIcon />}
            >
                  {/* <img style={{ marginRight: "5px" }} src={DownloadIcon} alt="" /> */}
                  Download
                </LightBlueBtn>
              </>
            </Grid>
          </Grid>
          <Divider
            color="#dfdfdf"
            style={{ marginBottom: "15px" }}
            sx={{ height: 2, m: 0 }}
            orientation="vertical"
          />
          <OutputSummary />
          <PortFolio />
          <GeoLevel />
          <BubbleChart />
          <SectionFilters isDistibution={isDistribution} />
          <HistoricalRange isDistibutionCategory={getHistoricalCategory} />
          <PriceElasticity />
        </>
      ) : (
        <>
          <Card
            className="m-b-20 m-t-10"
            style={{
              position: "relative",
              backgroundColor: "#F1F5FE",
            }}
          >
            <CardContent>
              <Stack>
                <DecisionVariable showSkeleton={false} />
                <UserConstraints showSkeleton={isInitialScreen.userInputs} />
              </Stack>
            </CardContent>
          </Card>
          <SaveScenario />
          <Typography
            style={{ fontSize: "18px", fontWeight: 700, color: "white" }}
          >
            User Inputs
          </Typography>
          <Card
            className="m-b-20 m-t-10"
            style={{
              position: "relative",
              backgroundColor: "#F1F5FE",
            }}
          >
            <CardContent>
              <MixTable />
            </CardContent>
          </Card>
        </>
      )}
    </ThemeProvider>
  );
};

export default MixSimulation;

