import { API, rendarImage } from '../../util/helper';

export const getMeasureFilterData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/adjustablefilters`, payload);
  } catch (e) {
    return e;
  }
};

export const getAdditionalFilterData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/inasefilters`, payload);
  } catch (e) {
    return e;
  }
};

export const getUnitVariationExpectedData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/unitvariationsexpected`, payload);
  } catch (e) {
    return e;
  }
};

export const getImageUrl = async (country, productName) => {
  return await rendarImage(productName, country);
};

export const getResultPerWeek = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/weeklyormonthlyaccumulated`, payload);
  } catch (err) {
    return err;
  }
};

export const getWeeksData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/getweeks`, payload);
  } catch (err) {
    return err;
  }
};

export const getRealVsExpectedData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/realvsexpected`, payload);
  } catch (e) {
    return e;
  }
};

export const getVariableTrendData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/variabletrend`, payload);
  } catch (err) {
    return err;
  }
};

export const getSimulationDetailsData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/elasticitypricetracking/simulationdetails`, payload);
  } catch (err) {
    return err;
  }
};

export const getInaseMaxDate = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/GetInaseMaxDate`, payload);
  } catch (err) {
    return err;
  }
};
