import { styled } from "@mui/material/styles";
import { TextareaAutosize } from "@material-ui/core";
import { Box } from "@mui/system";
import { Button, Card, TextField, Typography } from "@mui/material";

const CreateNotificationTitle = styled(Typography)`
  font-size: 16px;
  margin: 20px 0px;
`;

const CreateNotificationSubtitle = styled(Typography)`
  font-size: 16px;
`;

const NotificationTemplateModal = styled(Box)`
  padding: 15px;
  padding-bottom: 0;
  width: 350px;
`;

const NotificationTemplateTitle = styled(Box)`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const SaveButton = styled(Button)`
  background: #858c94;
  color: #fff;
  &:hover {
    background: #858c94;
    color: #fff;
    cursor: pointer;
  }
`;

const Text = styled(TextField)`
  width: 100%;
`;

const TemplateCard = styled(Card)`
  padding: 15px;
  margin-bottom: 10px;
  background: #fff1f8;
  border: 1px solid #f6cbe0;
`;

const EditTemplateCloseButton = styled(Box)`
  height: 20px;
  width: 20px;
  font-size: 12px;
  border-radius: 50%;
  background: #fff;
  color: #858c94;
  border: 1px solid #858c94;
  text-align: center;
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
`;

const EditTemplateTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

const EditTemplateSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 5,
}));

const EditTemplateDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontsize: 12,
}));

const EditTemplateTextarea = styled(TextareaAutosize)(({ theme }) => ({
  fontSize: "1rem",
  padding: "10px",
  width: "100%",
  borderRadius: "4px",
  "&:hover": {
    border: "1px solid black !important",
  },
  "&:focus": {
    outline: "none !important",
    border: "none !important",
  },
}));

const EditTemplateButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: "capitalize",
}));

export {
  CreateNotificationSubtitle,
  CreateNotificationTitle,
  NotificationTemplateModal,
  NotificationTemplateTitle,
  SaveButton,
  Text,
  TemplateCard,
  EditTemplateCloseButton,
  EditTemplateTitle,
  EditTemplateSubtitle,
  EditTemplateDescription,
  EditTemplateTextarea,
  EditTemplateButton,
};
