import { Button } from "@mui/material";
import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { saveMixSimulationScenario } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { loaderForMixScreen } from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import { toast } from "../../../../store/actions/toast.action";
import { useHistory } from "react-router-dom";
import { MIX_SIMULATION_SCENARIO_LIBRARY } from "../../../../router/CONSTANTS";
import { API_URL } from "../../../../store/actions/constants";

const SaveScenario = () => {
  const [scenarioNameError, setScenarioNameError] = React.useState(false);
  const { isEditMode } = useSelector(
    (state: any) => state.mixSimulationAllFilter
  );
  const { location, push } = useHistory();

  const { uid } = useSelector((state: any) => state.mixSimulationUserInput);
  const { capacitiveFile, operativeFile } = useSelector(
    (state: any) => state["MixUserConstraintsReducer"]
  );
  const allFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );
  const {selectedDateTimePeriodDetails} = useSelector(
    (state: any) => state.mixSimulationAllFilter
  );
  const userInputs = useSelector((state: any) => state.mixSimulationUserInput);
  const dispatch = useDispatch();
  const isDisabled:boolean = _.isEmpty(userInputs.selectedAssortmentScenaioUid)||_.isEmpty(userInputs.scenarioName)||_.isEmpty(allFilterData.channel)||_.isEmpty(allFilterData.region)||_.isEmpty(allFilterData.storeSegment);
  const handleCreateScenario = () => {
      if (userInputs.scenarioName.trim() === "") {
        dispatch(toast("Enter Scenario name", true, 2000, "error"));
      } else if (isEditMode) {
           if (_.isEmpty(operativeFile) || _.isEmpty(capacitiveFile)) {
      dispatch(
        toast(
          "Please re upload operative complexity and capacity constraint file",
          true,
          2000,
          "error"
        )
      );
    } else {
        dispatch(loaderForMixScreen(true));
        let fileOperative = new Blob([operativeFile], { type: "text/csv" });
        let fileCapacitive = new Blob([capacitiveFile], { type: "text/csv" });
        const formData = new FormData();
        formData.append("uid", uid);
        formData.append("OperativeCsvFile", fileOperative);
        formData.append("CapacityCsvFile", fileCapacitive);
        formData.append(
          "assortmentUid",
          userInputs.selectedAssortmentScenaioUid
        );
        formData.append("goal", userInputs.selectedGoal);
        formData.append("flag", "true");
        formData.append("status", "4");
        formData.append("geoFilters.StoreSegment", allFilterData.storeSegment);
        formData.append("geoFilters.Region", allFilterData.region);
        formData.append("geoFilters.Channel", allFilterData.channel);
        formData.append("geoFilters.Level", allFilterData.level);
        formData.append(
          "overallFilters.BusinessUnit",
          allFilterData.businessUnit[0]
        );
        formData.append(
          "overallFilters.DataTimePeriod",
          allFilterData.period[0]
        );
        formData.append("overallFilters.Country", allFilterData.country[0]);
        formData.append("scenarioName", userInputs.scenarioName);
        let operativeComplexityData = {
          url: API_URL.MIXSIMULATION.UPLOAD_CSV_FILE_OPERATIVE_COMPLEXITY,
          file: fileOperative,
        };
        let capacityConstraintData = {
          url: API_URL.MIXSIMULATION.UPLOAD_CSV_FILE_CAPACITY_CONSTRAINT,
          file: fileCapacitive,
        };
        dispatch(
          saveMixSimulationScenario(
            formData,
            "saveMixScenario",
            capacityConstraintData,
            operativeComplexityData
          )
        ).then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log(res);
            push(MIX_SIMULATION_SCENARIO_LIBRARY);
          } else {
            dispatch(toast("Something went wrong", true, 2000, "error"));
          }
        });
      }
      } else {
        const uniqenessCheckerPayload = {
          scenarioName: userInputs.scenarioName,
        };
        dispatch(loaderForMixScreen(true));
        dispatch(
          fetchApi(
            uniqenessCheckerPayload,
            API_URL.MIXSIMULATION.UNIQUE_MIX_SCENARIO_NAME,
            null
          )
        ).then((res) => {
          if (res.data.message === "ScenarioName is unique.") {
            dispatch(loaderForMixScreen(true));
            let fileOperative = new Blob([operativeFile], { type: "text/csv" });
            let fileCapacitive = new Blob([capacitiveFile], {
              type: "text/csv",
            });
            const formData = new FormData();
            formData.append("uid", "");
            if (_.isEmpty(operativeFile) && _.isEmpty(capacitiveFile)) {
              formData.append("OperativeCsvFile", null);
              formData.append("CapacityCsvFile", null);
            } else {
              formData.append("OperativeCsvFile", fileOperative);
              formData.append("CapacityCsvFile", fileCapacitive);
            }
            formData.append(
              "assortmentUid",
              userInputs.selectedAssortmentScenaioUid
            );
            formData.append("goal", userInputs.selectedGoal);
            formData.append("flag", "true");
            formData.append("status", "4");
            formData.append(
              "geoFilters.StoreSegment",
              allFilterData.storeSegment
            );
            formData.append("geoFilters.Region", allFilterData.region);
            formData.append("geoFilters.Channel", allFilterData.channel);
            formData.append("geoFilters.Level", allFilterData.level);
            formData.append(
              "overallFilters.BusinessUnit",
              allFilterData.businessUnit[0]
            );
            formData.append(
              "overallFilters.DataTimePeriod",
              allFilterData.period[0]
            );
            formData.append("overallFilters.Country", allFilterData.country[0]);
            formData.append("scenarioName", userInputs.scenarioName);
            formData.append("StartMonth", selectedDateTimePeriodDetails.startMonth);
            formData.append("EndMonth", selectedDateTimePeriodDetails.endMonth);
            formData.append("StartYear", selectedDateTimePeriodDetails.startYear);
            formData.append("EndYear", selectedDateTimePeriodDetails.endYear);
            let operativeComplexityData = {
              url: API_URL.MIXSIMULATION.UPLOAD_CSV_FILE_OPERATIVE_COMPLEXITY ,
              file: fileOperative,
            };
            let capacityConstraintData = {
              url: API_URL.MIXSIMULATION.UPLOAD_CSV_FILE_CAPACITY_CONSTRAINT,
              file: fileCapacitive,
            };
            dispatch(
              saveMixSimulationScenario(
                formData,
                "saveMixScenario",
                capacityConstraintData,
                operativeComplexityData
              )
            ).then((res) => {
              if (res.status == 200) {
                let payload = {
                  mixScenarioUid: res.data.uid,
                  country: allFilterData.country,
                  businessUnit: allFilterData.businessUnit
                }
                dispatch(fetchApi(payload,API_URL.MIXSIMULATION.MOVE_INITIAL_PRICE_ELASTICITY_TABLE_DATA_TO_UPDATE_IT))
                push(MIX_SIMULATION_SCENARIO_LIBRARY);
              } else {
                dispatch(toast("Something went wrong", true, 2000, "error"));
              }
            });
          } else {
            dispatch(
              toast("Please Enter a Unique Scenario Name", true, 2000, "error")
            );
            dispatch(loaderForMixScreen(false));
          }
        });
        //    const saveScenarioCalls = [dispatch(saveMixSimulationScenario(payload, 'saveMixScenario',capacityConstraintData,operativeComplexityData)),
        //     dispatch(
        //       fetchApi(
        //         capFormData,
        //         "output/mix/uploadCsvFileCapacityConstraint",
        //         dispatchAction
        //       )
        //     ),
        //     dispatch(
        //       fetchApi(
        //         opeFormData,
        //         "output/mix/uploadCsvFileOperativeComplexity",
        //         dispatchAction
        //       )
        //     )]
        //    Promise.all(saveScenarioCalls).then(res => {
        //     // dispatch((false))
        // }).catch(err => {
        //     console.log("RUN SIMULAR ERROR", err)
        //     // dispatch((false))
        // })

        // function getFormData(file) {
        //   const formData = new FormData();
        //   formData.append('Uid', uid);
        //   formData.append('CsvFile', file);
        //   return formData
        // }
      }
    
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        disabled={isDisabled}
        onClick={handleCreateScenario}
        style={{
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "16px",
          padding: "10px 20px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#2F5597",
          backgroundColor: isDisabled?'#D3D3D3':'#FFFFFF',
        }}
      >
        Save Scenario
      </Button>
    </div>
  );
};
export default SaveScenario;
