import { Box, Grid } from '@mui/material';
import React from 'react';
import mockData from '../../../../mocks/priceSettingToolMock';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';

const MeasuresFilters: React.FC<{
  clearFilters;
  selectedMeasuresFilters;
  callback;
}> = ({ clearFilters, selectedMeasuresFilters, callback }) => {
  return (
    <>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <Grid container className="m-t-10">
            <Grid item xs={12} md={6}>
              <Box style={{ fontSize: 16, fontWeight: 600 }}>{mockData.measuresFilter.priceElasticityCurve.title}</Box>
              <RadioButtonGroup
                key={`measures-filters-1`}
                color="#000"
                showButton={true}
                data={mockData.measuresFilter.priceElasticityCurve.options}
                defaultOption={selectedMeasuresFilters.priceElasticityCurve}
                select={(data) =>
                  callback({
                    ...selectedMeasuresFilters,
                    priceElasticityCurve: data,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={{ fontSize: 16, fontWeight: 600 }}>{mockData.measuresFilter.gramsElasticityCurve.title}</Box>
              <RadioButtonGroup
                key={`measures-filters-3`}
                color="#000"
                showButton={true}
                data={mockData.measuresFilter.gramsElasticityCurve.options}
                defaultOption={selectedMeasuresFilters.gramsElasticityCurve}
                select={(data) =>
                  callback({
                    ...selectedMeasuresFilters,
                    gramsElasticityCurve: data,
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container display="flex" alignItems="center">
            <Grid className="m-r-20">
              <Box style={{ fontSize: 16, fontWeight: 600 }}>{mockData.measuresFilter.monthRange.title}</Box>
            </Grid>
            <Grid>
              <RadioButtonGroup
                key={`measures-filters-1`}
                color="#000"
                showButton={mockData.measuresFilter.monthRange.showRadioButton}
                data={mockData.measuresFilter.monthRange.options}
                defaultOption={selectedMeasuresFilters.monthRange}
                select={(data) => callback({ ...selectedMeasuresFilters, monthRange: data })}
                direction={'row'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MeasuresFilters;
