export const keysToDownloadMapper = {
        'id':'ID',
        'channel':'Channel',
        'region':'Region',
        'store_segment':'Store Segment',
        'segment':'Segment',
        'brand':'Brand',
        'subbrand':'Sub Brand',
        'sku':'SKU',
        'min_mix':'Min mix (%)',
        'max_mix':'Max mix (%)',
        'penetration':'Penetration (%)',
        'sku_volume':'SKU Volume',
        'sku_sales':'SKU Sales',
        'no_pbt':'NOPBT',
        'business_unit':'Business Unit',
        'date_time_period':'Time Period',
        'country':'Country',
        'objective_function':'Goal',
        'vendor':'Vendor',
        'smkt_chain':'SMKT Chain',
        'flavour':'Flavour',
        'net_revenue':'Net Revenue',
        'no_pbt_per_kg':'NOPBT/KG',
        'capacity_constraints':'Capacity Constraint',
        'operational_complexity':'Operational Complexity',
        'pack_size':'Pack Size',
        'inclusion_flag':'Inclusion Flag',
        'level':'Level',
        'net_revenue_per_kg':'Net Revenue/KG',
        'pack_min_mix':'Pack Min Mix (%)',
        'pack_max_mix':'Pack Max Mix (%)',
        'seg_min_mix':'Segment Min Mix (%)',
        'seg_max_mix':'Segment Max Mix (%)',
        'pack_group':'Pack Group'
}

export const keysToUploadMapper = {
    'ID': 'id',
    'Channel': 'channel',
    'Region': 'region',
    'Store Segment': 'store_segment',
    'Segment': 'segment',
    'Brand': 'brand',
    'Sub Brand': 'subbrand',
    'SKU': 'sku',
    'Min mix (%)': 'min_mix',
    'Max mix (%)': 'max_mix',
    'Penetration (%)': 'penetration',
    'SKU Volume': 'sku_volume',
    'SKU Sales': 'sku_sales',
    'NOPBT': 'no_pbt',
    'Business Unit': 'business_unit',
    'Time Period': 'date_time_period',
    'Country': 'country',
    'Goal': 'objective_function',
    'Vendor': 'vendor',
    'SMKT Chain': 'smkt_chain',
    'Flavour': 'flavour',
    'Net Revenue': 'net_revenue',
    'NOPBT/KG': 'no_pbt_per_kg',
    'Capacity Constraint': 'capacity_constraints',
    'Operational Complexity': 'operational_complexity',
    'Pack Size': 'pack_size',
    'Inclusion Flag': 'inclusion_flag',
    'Level': 'level',
    'Net Revenue/KG': 'net_revenue_per_kg',
    'Pack Min Mix (%)': 'pack_min_mix',
    'Pack Max Mix (%)': 'pack_max_mix',
    'Segment Min Mix (%)': 'seg_min_mix',
    'Segment Max Mix (%)': 'seg_max_mix',
    'Pack Group': 'pack_group'
  }

  export const keysToDownload = [
    'id',
    'channel',
    'region',
    'store_segment',
    'segment',
    'brand',
    'subbrand',
    'sku',
    'min_mix',
    'max_mix',
    'penetration',
    'sku_volume',
    'sku_sales',
    'no_pbt',
    'business_unit',
    'date_time_period',
    'country',
    'objective_function',
    'vendor',
    'smkt_chain',
    'flavour',
    'net_revenue',
    'no_pbt_per_kg',
    'capacity_constraints',
    'operational_complexity',
    'pack_size',
    'inclusion_flag',
    'level',
    'net_revenue_per_kg',
    'pack_min_mix',
    'pack_max_mix',
    'seg_min_mix',
    'seg_max_mix',
    'pack_group'
  ]

