import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import TopFilters from './components/TopFilters';
import DashboardActions from '../../components/DashboardActions';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { pageList } from '../../mocks/common';
import SourceOfVolumeOfYear from './components/SourceOfVolumeByYear/SourceOfVolumeByYear';
import PriceElasticityAndModelContainer from './components/PriceElasticityAndModelContainer';
import CauseOfBusinessChange from './components/CauseOfBusinessChange';
import CrossPriceElasticityFilter from './components/CrossPriceElasticityFilter';
import CrossPriceEleasticityContainer from './components/CrossPriceEleasticityContainer/CrossPriceEleasticityContainer';
import mockData from '../../mocks/historyPriceMock';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { removeMMPWFromLocal } from '../../util/helper';
import { useDispatch, useSelector } from 'react-redux';
import { priceElasticityDataLoader } from './store/historicPrice.action';

const HistoricPriceAndCrossPriceElasticity: React.FC<{}> = () => {
  const [appliedFilters, setFilters] = useState({} as any);
  const [impactVariable, setImpactVariable] = useState('');
  const [defaultImpactVariable, setDefaultImpactVariable] = useState('');
  const [crossPriceFilter, setCrossPriceFilter] = useState({});
  const [defaultFilters, setDefaultFilters] = useState({});
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const dispatch = useDispatch();

  const onApplyFilter = (filters) => {
    setDisableDownloadButton(false);
    removeMMPWFromLocal('historic-price');
    setFilters(filters);
  };

  const getSaveDashBoardsData = (data) => {
    dispatch(priceElasticityDataLoader(true));
    if (data.filters) {
      let savedDashboardData = JSON.parse(data.filters);
      setChartPeriodicity(savedDashboardData.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.periodicity || '{}');
      setDisableDownloadButton(false);
      setDefaultFilters({
        global: savedDashboardData?.global,
        local: savedDashboardData?.local,
      });
      setFilters(savedDashboardData?.global);
      setDefaultImpactVariable(savedDashboardData?.impactVariable);
    }
  };

  const HistoricPrice = useSelector((state: any) => state.HistoricPrice);


  return (
    <DashboardActions
      title='Historic Price and Cross Price Elasticity'
      data={{
        endpoint: 'apiHistoricPriceDashboard',
        filters: {
          global: { ...appliedFilters },
          impactVariable,
          local: {
            pepsi: {
              category: crossPriceFilter.category,
              segment: crossPriceFilter.segment,
              brand: crossPriceFilter.brand,
              subBrand: crossPriceFilter.subBrand,
              packSize: crossPriceFilter.packSize,
              permutationCombination: crossPriceFilter.pepsiPermutation,
            },
            competitor: {
              category: crossPriceFilter.competitorCategory,
              segment: crossPriceFilter.competitorSegment,
              brand: crossPriceFilter.competitorBrand,
              subBrand: crossPriceFilter.competitorSubBrand,
              packSize: crossPriceFilter.competitorPackSize,
              permutationCombination: crossPriceFilter.competitorPermutation,
            },
          },
        },
        mmpwKey: 'historic-price',
      }}
      dashboardKey={pageList[7].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={disableDownloadButton || HistoricPrice.priceElasticityDataLoader}
    >
      <Grid>
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[7].value} />
        </Grid>
        <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters.global} />
        <PriceElasticityAndModelContainer filters={appliedFilters} />
        <CauseOfBusinessChange filters={appliedFilters} callback={setImpactVariable} defaultImpactVariable={defaultImpactVariable} />
        <SourceOfVolumeOfYear filters={appliedFilters} />
        <CrossPriceElasticityFilter filters={appliedFilters} callback={setCrossPriceFilter} allDefaultFilters={defaultFilters} />
        <Card>
          <CardContent>
            <CrossPriceEleasticityContainer filters={appliedFilters} localFilter={crossPriceFilter} />
          </CardContent>
        </Card>
      </Grid>
      <DashboardSourceList category={appliedFilters.anchorCategory} dashboardName={pageList[7].id} appliedFilters={appliedFilters} />
    </DashboardActions>
  );
};
export default HistoricPriceAndCrossPriceElasticity;
