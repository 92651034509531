import React from 'react';
import { CardContent, Skeleton } from '@mui/material';
import { StyledCard, Title, Value, Profit, Loss } from './GrossVolumeCard.Styled';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const GrossVolumeCard: React.FC<{ data: any; showSkeleton?: boolean }> = ({ data, showSkeleton }) => {
  return (
    <StyledCard>
      <CardContent style={{ background: 'white', height: '100%' }}>
        {!showSkeleton ? (
          <>
            <Title data-testid='grossVolumeCardTitle'>{data.title}</Title>
            <Value data-testid='grossVolumeCardValue'>
              {data.value} {data.valueLabel}
            </Value>
            {data.percent &&
              (data.raise ? (
                <Profit data-testid='profitIcon'>
                  + {data.percent} <ArrowUpward />
                </Profit>
              ) : (
                <Loss data-testid='lossIcon'>
                  {data.percent} <ArrowDownward />
                </Loss>
              ))}
          </>
        ) : (
          <>
            <Title>
              <Skeleton />
            </Title>
            <Value>
              <Skeleton />
            </Value>
            {data.percent && <Skeleton />}
          </>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default GrossVolumeCard;
