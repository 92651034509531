import { API } from '../../util/helper';

export const getMacroEconVarSrc = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/VariableFilter`, payload);
  } catch (e) {
    return e;
  }
};

export const getMacroEconData = async (request, type) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/macro-econ/${type}`, request);
  } catch (e) {
    return e;
  }
};

export const getProductFilters = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/ProductFilter`, payload);
  } catch (e) {
    return e;
  }
};

export const getMacroEconCheckData = async (request) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/MacroEconInflation`, request);
  } catch (e) {
    return e;
  }
};
