import React, { useEffect, useRef } from 'react';
import { Grid, Card, Divider, List, Box, Typography, CardContent, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { donutConfig } from './donutConfig';
import { BlackTitle } from '../../../../../../styles/Common.Styled';

const Donut: React.FC<{ data; skeleton }> = ({ data, skeleton }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    try {
      if (data.rSquareValue && chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.series[0].setData(data.rSquareValue);
        chart.setTitle({ text: `${data.rSquareValue[0].y}%<br><span style="margin-top: 10px; font-size: 10px;">R square</span>` });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <Grid className='m-b-20'>
      {!skeleton ? (
        <Box flex='3'>
          {data.variables ? (
            <Card sx={{ height: 520 }}>
              <CardContent>
                <HighchartsReact highcharts={Highcharts} containerProps={{ style: { height: '100%' } }} options={donutConfig} ref={chartRef} />
                <Divider />
                <Box display='flex' alignItems='start' justifyContent='start' flexDirection='column'>
                  <Typography sx={{ color: 'blue', fontWeight: 500, fontSize: 15, mt: 2 }}>Variables: {data.title}</Typography>
                  <Grid sx={{ height: 235, overflow: 'auto', width: '100%' }}>
                    {data.variables &&
                      data.variables.map((item, index) => (
                        <BlackTitle sx={{ fontSize: 13, fontWeight: 300 }}>
                          {index + 1}. {item}
                        </BlackTitle>
                      ))}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          ) : null}
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Skeleton variant='rectangular' height={500} sx={{ marginTop: '45px' }} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Donut;
