import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Grid, Paper, Skeleton } from '@mui/material';
import Dropdown from '../../../../../../components/Dropdown';
import { GraphLabel, NoData } from '../../../../../../styles/Common.Styled';
import CustomTable from '../../../../../../components/CustomTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { chartConfig } from '../../../../chartsConfig';
import { dataFormat, getDataFormat, rendarImage, setColor } from '../../../../../../util/helper';
import { useSelector } from 'react-redux';
import { getMacroEconData } from '../../../../service';
import Indicator from '../../../../../../components/Loader';
import { messages } from '../../../../../../util/config';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../../../../../components/Dashboard/Dashboard.Styled';
import HighchartContainer from '../../../../../../components/HighchartContainer';

const useStyles = makeStyles((theme) => ({
  alignImage: {
    textAlign: 'center',
  },
  customTableCellStyle: {
    maxWidth: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const Section: React.FC<{
  selectedFilter?;
  lastSection;
  addVisualization;
  display?;
  productVariables;
  macroEconVariables;
  selectedMacroEconVar;
  callback;
  type;
  id;
  title?;
  defaultFilters?;
  skeleton;
}> = ({
  selectedFilter,
  lastSection,
  addVisualization,
  display,
  productVariables,
  macroEconVariables,
  selectedMacroEconVar,
  callback,
  type,
  id,
  title,
  defaultFilters,
  skeleton,
}) => {
  const classes = useStyles();
  const chartRef = React.useRef(null);
  const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
  const [loader, setLoader] = useState(false);
  const [chartSkeleton, setChartSkeleton] = React.useState(true);
  const [headings, setHeadings] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [noData, setNoDataFlag] = useState(false);
  const [productImage, setProductImage] = useState<{
    imageName: string;
    image: string;
  }>({ imageName: '', image: '' });
  const [productVar, setProductVar] = React.useState<string>();
  const [macroEconVar, setMacroEconVar] = React.useState<string>();

  const [correlation, setCorrelation] = React.useState(0);
  const [apiCalled, setAPICalled] = React.useState<boolean>(false);
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

  useEffect(() => {
    if (!chartSkeleton) {
      setTimeout(() => {
        if (chartRef && chartRef.current && chartRef.current.chart) {
          callback({
            chartRef: chartRef,
            macroEconVar: macroEconVar,
            productVar: productVar,
          });
        }
      }, 0);
    }
  }, [chartSkeleton]);

  useEffect(() => {
    if (selectedFilter && selectedFilter.variable && selectedFilter.category) {
      setMacroEconVar(selectedMacroEconVar);
      const productVar = _.isArray(productVariables) ? productVariables.find((x) => x.default)?.value : '';
      setProductVar(productVar);
      const payload = {
        ...selectedFilter,
        productVar,
        variable: _.isArray(selectedMacroEconVar) ? selectedMacroEconVar[0] : selectedMacroEconVar,
        macroEconVar: _.isArray(selectedMacroEconVar) ? selectedMacroEconVar[0] : selectedMacroEconVar,
      };
      if (payload.periodicity && payload.packSize && productVar && macroEconVar) {
        getMacroEcon(payload);
      }

      const fetchImage = async (productName) => {
        setProductImage({ imageName: productName, image: await rendarImage(productName, selectedFilter.country) });
      };

      let productName =
        selectedFilter?.subBrand?.length === 1 ? selectedFilter?.subBrand[0] : selectedFilter?.brand?.length === 1 ? selectedFilter?.brand[0] : null;
      if (productName) {
        fetchImage(productName).catch((err) => console.log(err));
      } else {
        setProductImage({ imageName: '', image: '' });
      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (productVar && macroEconVar) {
      const payload = {
        ...selectedFilter,
        productVar: _.isArray(productVar) ? productVar[0] : productVar,
        variable: _.isArray(macroEconVar) ? macroEconVar[0] : macroEconVar,
        macroEconVar: _.isArray(macroEconVar) ? macroEconVar[0] : macroEconVar,
      };
      if (payload.periodicity && payload.category) {
        getMacroEcon(payload);
      }
    }
  }, [productVar, macroEconVar]);

  const getMacroEcon = (payload) => {
    if (!apiCalled) {
      setAPICalled(true);
      setLoader(true);
      setChartSkeleton(true);
      setNoDataFlag(false);
      getMacroEconData(payload, type)
        .then((res) => {
          if (res.status === 200) {
            setChartSkeleton(false);
            setLoader(false);
            setAPICalled(false);
            setTimeout(() => {
              if ((res && res.data && !_.isEmpty(res.data.macroEconVar)) || !_.isEmpty(res.data.productVar)) {
                formatChartTableData(res.data);
              } else {
                setNoDataFlag(true);
              }
            }, 0);
          } else {
            setNoDataFlag(true);
            setAPICalled(false);
            setChartSkeleton(false);
            setLoader(false);
          }
        })
        .catch((err) => {
          setNoDataFlag(true);
          setAPICalled(false);
          setChartSkeleton(false);
          setLoader(false);
        });
    }
  };

  const formatChartTableData = (response) => {
    if (response && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const dates = response.productVar.map((item) => item.date);
      const formattedDate = dataFormat(dates, getDataFormat(selectedFilter.periodicity));

      chart.xAxis[0].setCategories(formattedDate);
      chart.series[0].setData(response.productVar.map((item) => item.value));
      chart.series[1].setData(response.macroEconVar.map((item) => item.value));
      setLabels(chart);
    }
    const correlation = parseFloat((response.correlation * 100).toFixed(2));
    setCorrelation(correlation);

    if (_.isArray(response.productVar) && _.isArray(response.macroEconVar)) {
      const allHeadings = [{ title: '' }];
      let macroEconVariable = {},
        productVariable = {};
      let y1Axis, y2Axis;
      if (productVar && macroEconVar) {
        y1Axis = _.isArray(productVar) && productVar.length ? productVar[0] : productVar;
        y2Axis = _.isArray(macroEconVar) && macroEconVar.length ? macroEconVar[0] : macroEconVar;
      }
      response.productVar.map((item, i) => {
        const title = dataFormat(item.date, getDataFormat(selectedFilter.periodicity));
        allHeadings.push({ title });
        productVariable = {
          ...productVariable,
          [`${title}${i}`]: item.value,
        };
      });
      response.macroEconVar.map((item, i) => {
        const title = dataFormat(item.date, getDataFormat(selectedFilter.periodicity));
        macroEconVariable = {
          ...macroEconVariable,
          [`${title}${i}`]: item.value,
        };
      });
      setHeadings(allHeadings);
      setValues([
        { 0: y2Axis, ...macroEconVariable },
        { 0: y1Axis, ...productVariable },
      ]);
    }
  };

  const setLabels = (chart) => {
    if (productVar && macroEconVar) {
      const y1Axis = _.isArray(productVar) && productVar.length ? productVar[0] : productVar;
      const y2Axis = _.isArray(macroEconVar) && macroEconVar.length ? macroEconVar[0] : macroEconVar;

      chart.yAxis[0].setTitle({ text: y1Axis });
      chart.yAxis[1].setTitle({ text: y2Axis });
      chart.legend.allItems[0].update({
        name: y1Axis,
      });
      chart.legend.allItems[1].update({
        name: y2Axis,
      });
    }
  };

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setMacroEconVar(defaultFilters.macroEconVar);
      setProductVar(defaultFilters.productVar);
    }
  }, [defaultFilters]);

  return (
    <Box className='m-t-20'>
      <Indicator position='absolute' show={loader} />
      <Grid container>
        {(productImage?.image || productImage?.imageName || skeleton || chartSkeleton) && (
          <Grid item xs={12} sm={2} display='flex'>
            <Grid container display='flex' alignItems='center' justifyContent='center'>
              {!skeleton && !chartSkeleton && productImage?.image ? (
                <Grid className={classes.alignImage}>
                  <Title className='m-b-10'>{productImage?.imageName}</Title>
                  <img src={productImage?.image} alt={productImage?.imageName} width='100%' loading='lazy' />
                </Grid>
              ) : !skeleton && !chartSkeleton && !productImage.image ? (
                <Grid className={classes.alignImage} style={{border:'1px solid #000'}} width={220} height={390} display={'flex'} alignItems='center' justifyContent={'center'}>
                  <Title>{productImage?.imageName}</Title>
                </Grid>
              ) : (
                <Grid display='flex' justifyContent='center' alignItems='center'>
                  <Skeleton variant='rectangular' width={220} height={390} />
                </Grid>
              )}
              <Grid />
            </Grid>
          </Grid>
        )}
        <Grid item xs sm>
          {!skeleton && !chartSkeleton && macroEconVar ? (
            <>
              <HighchartContainer chartRef={chartRef} id={id}>
                <HighchartsReact highcharts={Highcharts} options={chartConfig} ref={chartRef} />
              </HighchartContainer>

              <Grid container justifyContent='center' className='m-b-20'>
                <GraphLabel width={'100%'} textAlign='center' {...setColor(correlation, 'correlation')}>
                  Correlation {correlation}%
                </GraphLabel>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Skeleton variant='rectangular' height={350} />
              </Grid>
              <Grid container justifyContent='center' className='m-b-20'>
                <Skeleton variant='rectangular' width='100%' height={40} className='m-t-20' />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} style={{ paddingTop: '76px' }}>
          <Grid container spacing={2} display='flex' justifyContent='space-between'>
            <Grid item xs={6}>
              <Title>Macro-Econ Variable : </Title>
            </Grid>
            <Grid item xs={6} className='m-b-10'>
              {!skeleton ? (
                <Dropdown
                  data={macroEconVariables}
                  placeholder='Select'
                  onChange={(data) => {
                    setMacroEconVar(data);
                    callback({
                      chartRef: chartRef,
                      macroEconVar: data,
                      productVar: productVar,
                    });
                  }}
                  defaultOption={macroEconVar || ['empty']}
                  maxWidth='20vw'
                />
              ) : (
                <Skeleton variant='rectangular' height={45} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} display='flex' justifyContent='space-between'>
            <Grid item xs={6}>
              <Title>{`${title} Variable : `}</Title>
            </Grid>
            <Grid item xs={6}>
              {!skeleton ? (
                <Dropdown
                  data={productVariables}
                  placeholder='Select'
                  onChange={(data) => {
                    setProductVar(data);
                    callback({
                      chartRef: chartRef,
                      macroEconVar: macroEconVar,
                      productVar: data,
                    });
                  }}
                  defaultOption={productVar || ['empty']}
                  maxWidth='20vw'
                />
              ) : (
                <Skeleton variant='rectangular' height={45} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          {noData && macroEconVar && _.isEmpty(values) ? (
            <Grid container display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.noData}</NoData>
            </Grid>
          ) : (
            <Grid container>
              {!skeleton && !chartSkeleton && macroEconVar ? (
                <Grid
                  style={{
                    maxWidth: sidebarOpen ? '59.5vw' : '60.5vw',
                  }}
                >
                  <CustomTable
                    headingBgColor='#ECECEC'
                    headingColor='#1E1E1F'
                    heading={headings}
                    value={values}
                    dataAlign='center'
                    headerAlign='center'
                    customTableCellStyle={classes.customTableCellStyle}
                  />
                </Grid>
              ) : (
                <CustomTable
                  headingBgColor='#ECECEC'
                  headingColor='#1E1E1F'
                  heading={[1, 2, 3, 4, 5, 6, 7, 8]}
                  value={[
                    { a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8 },
                    { a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8 },
                  ]}
                  showSkeleton={true}
                  dataAlign='center'
                  headerAlign='center'
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {lastSection && display && (
        <Grid container className='m-t-10'>
          <Grid item xs={12} textAlign='center'>
            {!skeleton && !chartSkeleton ? (
              <Box onClick={addVisualization}>
                <Grid container>
                  <Grid item xs={12}>
                    <AddCircleIcon
                      style={{ fontSize: '50px' }}
                      color={theme.darkmode ? 'primary' : 'secondary'}
                      className='cursor-pointer'
                    ></AddCircleIcon>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>Add Chart</Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box display='flex' justifyContent='center'>
                <Skeleton variant='rectangular' width={100} height={45} />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Section;
