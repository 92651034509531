import { styled } from "@mui/material/styles";
import { Container, Paper, Typography } from "@mui/material";

const SummaryPaper = styled(Paper)(({ theme }) => ({
  width: "240px",
  height: "104px",
  padding: "16px",
  marginTop: "12px",
}));

const SummaryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  fontFamily: "Inter, sans-serif",
  color: "#93A3AB",
  lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'center',
}));

const SummaryCardValue = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "20px",
  fontFamily: "Inter, sans-serif",
  color: "#000000",
  lineHeight: '22px',
letterSpacing: '0em',
textAlign: 'center'
}));
const SubSummaryPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: "16px",
  backgroundColor: "#F1F5FE",
}));

export { SummaryPaper, SummaryTitle, SummaryCardValue, SubSummaryPaper };
