import React from "react";
import { Grid, Typography } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";

const MeasuresFilters: React.FC<{
  data;
  callback;
}> = ({ data, callback }) => {
  return (
    <Grid>
      <Typography variant="subtitle2">Table Columns</Typography>
      <Grid container display="flex" alignItems="center">
        <Grid
          sx={{ width: 200, mt: 2, mb: 2, mr: 2 }}
        >
          <Dropdown
            data={data}
            onChange={(data) => {
              callback(data);
            }}
            sort={{ enable: false }}
            multiple={true}
            allOption={true}
            placeholder='Select'
          />
        </Grid>
        <Grid>
          <b>Note:</b> Default table columns of the Product Xref file - Raw Name, Source, Uniqueid
        </Grid>

      </Grid>
    </Grid>
  );
};

export default MeasuresFilters;
