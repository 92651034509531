import {
  GET_GEO_FILTERS,
  GET_GEO_FILTERS_SUCESS,
  GET_GEO_FILTERS_FAILURE,
  CLEAR_GEO_FILTERS,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCESS,
  GET_COUNTRIES_FAILURE
} from "./constants";
import { API } from "../../util/helper";
import _ from "lodash";
import { DateTime } from "luxon";

export const getGeoFilters = () => {
  return {
    type: GET_GEO_FILTERS,
  };
};

export const getGeoFiltersSucess = (payload: any) => {
  return {
    type: GET_GEO_FILTERS_SUCESS,
    payload
  };
};

export const getGeoFiltersFailure = (error) => {
  return {
    type: GET_GEO_FILTERS_FAILURE,
    payload: error,
  };
};

const getGeoFilter = (dashboardKey) => {
  return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/${dashboardKey}/geolevels2`);
};

export function fetchGeoFiltersData(dashboardKey?, country?) {
  return (dispatch: any) => {
    const geoLevelSessionData = JSON.parse(sessionStorage.getItem('geoLevelsFilters'));
    const dt1 = DateTime.fromJSDate(new Date());
    const dt2 = geoLevelSessionData && geoLevelSessionData.expiresAt ? DateTime.fromJSDate(new Date(geoLevelSessionData.expiresAt)) : DateTime.fromJSDate(new Date());
    if (geoLevelSessionData && _.isArray(geoLevelSessionData.allGeoLevels) && dt1 < dt2) {
      if (country) {
        const data = geoLevelSessionData.allGeoLevels.find(x => x.label === country);
        if (data) {
          dispatch(getGeoFiltersSucess(data.geoLevels));
        }
      }
      if (dashboardKey) {
        const countries = _.isArray(geoLevelSessionData.allGeoLevels) ? geoLevelSessionData.allGeoLevels.map(x => ({ label: x.label, value: x.label })) : [];
        dispatch(getCountriesSucess(countries));
      }
    } else {
      dispatch(getGeoFilters());
      getGeoFilter(dashboardKey)
        .then((response) => {
          const expiresAt = DateTime.fromJSDate(new Date()).plus({ hours: 4 })
          sessionStorage.setItem('geoLevelsFilters', JSON.stringify({ expiresAt, allGeoLevels: response.data }));
          if (country) {
            const data = _.isArray(response.data) ? response.data.find(x => x.label === country) : null;
            dispatch(getGeoFiltersSucess(data.geoLevels));
          }
          if (dashboardKey) {
            const countries = _.isArray(response.data) ? response.data.map(x => ({ label: x.label, value: x.label })) : [];
            dispatch(getCountriesSucess(countries));
            dispatch(getGeoFiltersSucess([]));
          }
        })
        .catch((error) => {
          dispatch(getGeoFiltersFailure(error));
        });
    }
  };
}
export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
  };
};

export const getCountriesSucess = (payload: any) => {
  return {
    type: GET_COUNTRIES_SUCESS,
    payload
  };
};

export const clearGeoFilters = () => {
  return {
    type: CLEAR_GEO_FILTERS,
  };
}

export const getCountriesFailure = (error) => {
  return {
    type: GET_COUNTRIES_FAILURE,
    payload: error,
  };
};

const getCountry = () => {
  return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/countries`);
};

export function fetchCountries() {
  return (dispatch: any) => {
    dispatch(getCountries());
    const countiresSessionData = JSON.parse(sessionStorage.getItem('countries'));
    const dt1 = DateTime.fromJSDate(new Date());
    const dt2 = countiresSessionData && countiresSessionData.expiresAt ? DateTime.fromJSDate(new Date(countiresSessionData.expiresAt)) : DateTime.fromJSDate(new Date());
    if (countiresSessionData && _.isArray(countiresSessionData.countires) && dt1 < dt2) {
      dispatch(getCountriesSucess(countiresSessionData.countires));
    } else {
      getCountry()
        .then((response) => {
          const countires = response.data.map(country => ({ label: country, value: country }));
          const expiresAt = DateTime.fromJSDate(new Date()).plus({ hours: 4 })
          sessionStorage.setItem('countries', JSON.stringify({ expiresAt, countires }));
          dispatch(getCountriesSucess(countires));
        })
        .catch((error) => {
          dispatch(getCountriesFailure(error));
        });
    }
  };
}
