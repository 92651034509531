import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import _ from 'lodash';
import Dropdown from '../../../../components/Dropdown';
import { getMacroEconVarSrc } from '../../service';
import { measureFilterData } from '../../../../mocks/macroEconDashboard';
import { MacroEconVarDataSrc } from '../../store/macroEcon.action';
import { useDispatch } from 'react-redux';

const MeasureFilter: React.FC<{
  geoFilters;
  selectedMeasureFilters;
  callback;
  macroEconVariables;
  clearFilter;
}> = ({ geoFilters, selectedMeasureFilters, callback, macroEconVariables, clearFilter }) => {
  const dispatch = useDispatch();
  const onSelectMeasure = (type, selectedVal) => {
    if (type === 'dataSource') {
      const payload = {
        country: geoFilters.country,
        dataSource: selectedVal,
        macroEconVariable: null,
      };
      fetchMacroEconVarSrc(payload);
      let temp = {
        ...selectedMeasureFilters,
        variable: null,
        [type]: selectedVal,
      };
      callback(temp);
    } else {
      let temp;
      if (type === 'periodView' && selectedVal === 'yearToDate') {
        temp = { ...selectedMeasureFilters, currentVs: 'samePreviousPeriod', [type]: selectedVal };
      } else {
        temp = { ...selectedMeasureFilters, [type]: selectedVal };
      }
      callback(temp);
    }
  };
  const [macroEconVariableData, setMacroEconVariableData] = useState([]);
  const [macroEconDataSourceData, setMacroEconDataSourceData] = useState([]);

  const fetchMacroEconVarSrc = (payload) => {
    dispatch(MacroEconVarDataSrc(true));
    getMacroEconVarSrc(payload)
      .then((res) => {
        if (res.data.macroEconVariable) {
          setMacroEconVariableData(res.data.macroEconVariable);
          macroEconVariables(res.data.macroEconVariable);
        } else if (res.data.dataSource) {
          setMacroEconDataSourceData(res.data.dataSource);
        }
        dispatch(MacroEconVarDataSrc(false));
      })
      .catch((err) => {
        dispatch(MacroEconVarDataSrc(false));
      });
  };

  useEffect(() => {
    if (geoFilters && geoFilters.country) {
      onSelectMeasure('dataSource', selectedMeasureFilters?.dataSource ? selectedMeasureFilters?.dataSource : null);
      onSelectMeasure('variable', selectedMeasureFilters?.variable ? selectedMeasureFilters?.variable : null);

      setMacroEconVariableData([]);
      setMacroEconDataSourceData([]);
      const payload = {
        country: geoFilters.country,
        dataSource: null,
      };
      fetchMacroEconVarSrc(payload);
    }
  }, [geoFilters.country]);

  useEffect(() => {
    if (clearFilter) {
      setMacroEconVariableData([]);
      setMacroEconDataSourceData([]);
    }
  }, [clearFilter]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Grid container spacing={2}>
            <Grid item key={`measures-radio-button-container-1`}>
              <Box style={{ fontSize: 16, fontWeight: 600 }}>Period View</Box>
              <RadioButtonGroup
                key={`measures-filters-1`}
                color='#000'
                showButton={true}
                data={measureFilterData.period1}
                defaultOption={selectedMeasureFilters.periodView}
                select={(data) => onSelectMeasure('periodView', data)}
              />
            </Grid>
            <Grid item key={`measures-radio-button-container-2`}>
              <Box className='m-t-25'></Box>
              <RadioButtonGroup
                key={`measures-filters-2`}
                color='#000'
                showButton={true}
                data={measureFilterData.period2}
                defaultOption={selectedMeasureFilters.periodView}
                select={(data) => onSelectMeasure('periodView', data)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Grid item xs={12} key={`measures-radio-button-container-3`}>
            <Box style={{ fontSize: 16, fontWeight: 600 }}>Current vs</Box>
            <RadioButtonGroup
              key={`measures-filters-3`}
              color='#000'
              showButton={true}
              disabled={selectedMeasureFilters.periodView === 'yearToDate'}
              data={measureFilterData.current}
              defaultOption={selectedMeasureFilters.currentVs}
              select={(data) => onSelectMeasure('currentVs', data)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className='m-t-10'>
        <Grid item xs={12} sm={4} md={3}>
          <DropdownTitle>Macro-Econ Data Source</DropdownTitle>
          <Dropdown
            data={macroEconDataSourceData}
            defaultOption={selectedMeasureFilters.dataSource || ['empty']}
            onChange={(data) => {
              onSelectMeasure('dataSource', data);
            }}
            disabled={macroEconDataSourceData.length < 1}
            multiple={true}
            selectionLimit={5}
            maxWidth={'30vw'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DropdownTitle>Macro-Econ Variable</DropdownTitle>
          <Dropdown
            multiple={true}
            selectionLimit={5}
            data={macroEconVariableData}
            disabled={macroEconVariableData.length < 1}
            // disabled={macroEconVariableData.length<1&&!selectedMeasureFilters.variable}
            defaultOption={selectedMeasureFilters.variable || ['empty']}
            onChange={(data) => {
              onSelectMeasure('variable', data);
            }}
            maxWidth={'30vw'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MeasureFilter;
