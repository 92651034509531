import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import FilterAccordion from "../../../../components/Filters/Filters";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import {
  BlackTitle,
  OrangeBtn,
  PrimaryBtn,
} from "../../../../styles/Common.Styled";
import mockData from "../../../../mocks/priceRangePianoMock";
import Indicator from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { priceSpecificLoader } from "../../store/PriceRangePiano.action";
import { removeMMPWFromLocal } from "../../../../util/helper";

const SpecificFilters: React.FC<{ callback; defaultFilters?; filters }> = ({
  callback,
  defaultFilters,
  filters,
}) => {
  const dispatch = useDispatch();
  const [skeleton, setSkeleton] = React.useState(true);
  const [selectedSpecificFilter, setSelectedSpecificFilter] =
    React.useState<any>({
      viewSharedByForPiano:
        mockData.specificFilter.viewSharedByForPiano.defaultOption.value ||
        undefined,
      currentVsLast:
        mockData.specificFilter.currentVsLast.defaultOption.value || undefined,
    });

  const clearFilter = () => {
    setSelectedSpecificFilter({
      viewSharedByForPiano:
        mockData.specificFilter.viewSharedByForPiano.defaultOption.value ||
        undefined,
      currentVsLast:
        mockData.specificFilter.currentVsLast.defaultOption.value || undefined,
    });
  };

  const onApplyFilter = () => {
    removeMMPWFromLocal("price-range-pack-index-piano");
    dispatch(priceSpecificLoader(true));
    callback({
      ...selectedSpecificFilter,
    });
  };

  useEffect(() => {
    if (filters && filters.geoLevel2) {
      clearFilter();
      setSkeleton(false);
    }
  }, [filters]);

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setSelectedSpecificFilter({
        ...selectedSpecificFilter,
        viewSharedByForPiano: defaultFilters.viewSharedByForPiano,
        currentVsLast: defaultFilters.currentVsLast,
      });
      setSkeleton(false);
    }
  }, [defaultFilters]);

  const PricePianoDataLoader = useSelector(
    (state: any) => state.PricePianoDataLoader
  );

  return (
    <>
      <Card style={{ position: "relative" }}>
        <Indicator
          position="absolute"
          show={PricePianoDataLoader.priceSpecificLoader}
        />
        <CardContent>
          <FilterAccordion title="Specific Filter">
            <Grid
              container
              display={"flex"}
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">View Share by</Typography>
                <RadioButtonGroup
                  key={`measures-filters-1`}
                  color="#000"
                  showButton={
                    mockData.specificFilter.viewSharedByForPiano.showRadioButton
                  }
                  data={mockData.specificFilter.viewSharedByForPiano.options}
                  defaultOption={selectedSpecificFilter.viewSharedByForPiano}
                  select={(data) =>
                    setSelectedSpecificFilter({
                      ...selectedSpecificFilter,
                      viewSharedByForPiano: data,
                    })
                  }
                  direction={"column"}
                  showSkeleton={skeleton}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  Period View selection
                </Typography>
                <RadioButtonGroup
                  key={`measures-filters-1`}
                  color="#000"
                  showButton={
                    mockData.specificFilter.currentVsLast.showRadioButton
                  }
                  data={mockData.specificFilter.currentVsLast.options}
                  defaultOption={selectedSpecificFilter.currentVsLast}
                  select={(data) =>
                    setSelectedSpecificFilter({
                      ...selectedSpecificFilter,
                      currentVsLast: data,
                    })
                  }
                  direction={"column"}
                  showSkeleton={skeleton}
                />
              </Grid>
            </Grid>
            <span style={{ fontSize: 16 }}>
              <b>NOTE :</b> The calculation for index is made at segment pack
              size level
            </span>
          </FilterAccordion>
          {skeleton ? (
            <Grid container className="p-l-16">
              <Grid className="m-r-20">
                <Skeleton variant="rectangular" height={40} width={120} />
              </Grid>
              <Grid>
                <Skeleton variant="rectangular" height={40} width={120} />
              </Grid>
            </Grid>
          ) : (
            <Grid className="p-l-16">
              <OrangeBtn
                color="secondary"
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn color="primary" onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default SpecificFilters;
