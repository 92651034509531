import { API } from '../../util/helper';

export const getProductFilter = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/RevenueMapProductFilter?type=product`, payload);
  } catch (e) {
    return e;
  }
};

export const getQuadrantIntensity = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/revenuemap/quadintensity`, payload);
  } catch (error) {
    return error;
  }
};

export const fetchPackPriceCurveSimulationData = (payload) => {
  return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/revenuemap/simulation`, payload);
};

export const fetchViewTypeData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/revenuemap/viewtype`, payload);
  } catch (e) {
    return e;
  }
};

export const getProductFilters = async (data) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/modelproductfilter`, data);
  } catch (e) {
    return e;
  }
};

export const getCompetitorFilters = async (data) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/modelproductfilter`, data);
  } catch (e) {
    return e;
  }
};
