import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { closeIcon } from "../../assets/icons/common";
const useStyles = makeStyles(() => ({
  darkText: {
    color: "#000000 !important",
  },
  errorText: {
    color: "red",
  },
  nameInput: {
    "& fieldset": {
      border: "1px solid #000000 !important",
      // backgroundColor: '#f0f0f0'
    },
  },
}));
const MixErrorPopup: React.FC<{
  open?: boolean;
  onCloseHandler;
  errorMessage?;
}> = ({ open = true, onCloseHandler, errorMessage }) => {
  const classes = useStyles();
  const [error, setError] = useState(errorMessage);

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseHandler}
        style={{
          zIndex: 1,
          minHeight: "80vh",
          maxHeight: "80vh",
          margin: "0 auto",
        }}
      >
        <DialogTitle
          style={{
            position: "relative",
            textAlign: "center",
            paddingBottom: "16px",
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "24px",
          }}
        >
          Error
          <img
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              cursor: "pointer",
            }}
            src={closeIcon}
            alt="X"
            onClick={onCloseHandler}
          />
        </DialogTitle>
        <DialogContent style={{ padding: "25px" }}>
          {error && <p className={classes.errorText}>{error}</p>}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default MixErrorPopup;
