import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { LoadMoreBtn } from '../Dashboard.Styled';
import { Grid } from '@mui/material';
import NoDashboard from '../../../../components/NoDashboard/NoDashboard';
import _ from 'lodash';
import DashboardPreviewCard from '../../../../components/DashboardPreviewCard';
import { dashboardCardMock } from '../../../../mocks/common';
import { getFavoriteDashboards } from '../../../../util/services';
import { sortData } from '../../../../util/helper';
const FavoritedDashboard: React.FC<{ sortOrder }> = ({ sortOrder }) => {
  const [allDashboards, setAllDashboards] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [skeleton, setSkeleton] = useState(true);

  const fetchFavDashboards = async () => {
    setSkeleton(true);
    const response = await getFavoriteDashboards();
    if (response && _.isArray(response.data) && response.data.length) {
      sortDashboards(response.data);
    }
    setSkeleton(false);
  };

  const sortDashboards = (data) => {
    const sortedData = sortData(sortOrder, data);
    setAllDashboards(sortedData);
  };

  React.useEffect(() => {
    sortDashboards(allDashboards);
  }, [sortOrder]);

  React.useEffect(() => {
    fetchFavDashboards();
  }, []);

  useEffect(() => {
    if (_.isArray(allDashboards) && allDashboards.length) {
      setDashboards([..._.take(allDashboards, 4)]);
    }
  }, [allDashboards]);

  const loadMore = () => {
    setDashboards([...allDashboards]);
  };

  return (
    <Grid>
      {skeleton ? (
        <Grid container spacing={2} className='m-b-15'>
          {dashboardCardMock.map((item, index) => (
            <Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-cards-${index}`}>
              <DashboardPreviewCard data={item} skeleton={true} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {allDashboards.length > 0 ? (
            <>
              <Grid container spacing={2} className='m-b-15'>
                {dashboards.map((item, index) => (
                  <Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-cards-${index}`}>
                    <DashboardPreviewCard data={item} onToggleFavorite={fetchFavDashboards} showViewCount={false} />
                  </Grid>
                ))}
              </Grid>
              <Box className='m-b-15' display='flex' justifyContent='center' alignItems='center'>
                {_.isArray(allDashboards) && dashboards.length !== allDashboards.length ? (
                  <LoadMoreBtn onClick={loadMore}>Load More</LoadMoreBtn>
                ) : null}
              </Box>
            </>
          ) : (
            <NoDashboard heading='No dashboards have been favorited'></NoDashboard>
          )}
        </>
      )}
    </Grid>
  );
};
export default FavoritedDashboard;
