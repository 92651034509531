import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import ProductFiltersV3 from "../../../../components/ProductFilters-v3";
import mockData from "../../../../mocks/historyPriceMock";
import Indicator from "../../../../components/Loader";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import FilterAccordion from "../../../../components/Filters/Filters";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { priceElasticityLocalFilter } from "../../store/historicPrice.action";
import { removeMMPWFromLocal } from "../../../../util/helper";

const CrossPriceElasticityFilter: React.FC<{
  filters;
  callback;
  allDefaultFilters;
}> = ({
  filters,
  callback,
  allDefaultFilters: { local: defaultFilters, global },
}) => {
  const [loader, setLoader] = useState(false);
  const [clearFilterData, setClearFilter] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [productMockData, setProductMockData] = useState<any>({
    ...mockData.productFilter,
  });
  const [compitetorMockData, setcompitetorMockData] = useState<any>({
    ...mockData.competitorFilter,
  });
  const dispatch = useDispatch();
  const [selectedProductFilters, setSelectedProductFilters] = useState<any>({});
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] =
    useState<any>({});
  const [selectedGeoFilters, setSelectedGeoFilters] = useState<any>({});

  const disableApplyFilter = () => {
    return !(
      selectedProductFilters.permutationCombination ||
      selectedCompetitorFilters.permutationCombination
    );
  };

  const clearFilter = async () => {
    setSelectedCompetitorFilters({});
    setSelectedProductFilters({});
    setClearFilter(true);
  };

  useEffect(() => {
    setClearFilter(false);
  }, [selectedProductFilters, selectedCompetitorFilters]);

  const onApplyFilter = () => {
    removeMMPWFromLocal("historic-price-track-cross-eleasticity");
    dispatch(priceElasticityLocalFilter(true));
    if (filters?.comparisonLevel == "brand") {
      callback({
        category: selectedProductFilters.category,
        segment: selectedProductFilters.segment,
        brand: selectedProductFilters.brand,
        pepsiPermutation: selectedProductFilters.permutationCombination,
        competitorCategory: selectedCompetitorFilters.category,
        competitorSegment: selectedCompetitorFilters.segment,
        competitorBrand: selectedCompetitorFilters.brand,
        competitorPermutation: selectedCompetitorFilters.permutationCombination,
      });
    } else {
      callback({
        category: selectedProductFilters.category,
        segment: selectedProductFilters.segment,
        brand: selectedProductFilters.brand,
        subBrand: selectedProductFilters.subBrand,
        packSize: selectedProductFilters.packSize,
        pepsiPermutation: selectedProductFilters.permutationCombination,
        competitorCategory: selectedCompetitorFilters.category,
        competitorSegment: selectedCompetitorFilters.segment,
        competitorBrand: selectedCompetitorFilters.brand,
        competitorSubBrand: selectedCompetitorFilters.subBrand,
        competitorPackSize: selectedCompetitorFilters.packSize,
        competitorPermutation: selectedCompetitorFilters.permutationCombination,
      });
    }
    setSkeleton(false);
  };

  useEffect(() => {
    if (filters.country) {
      setSelectedGeoFilters({});
      setTimeout(() => {
        if (filters.comparisonLevel === "brand") {
          setProductMockData({ ...mockData.productFilterBrand });
          setcompitetorMockData({ ...mockData.competitorFilterBrand });
        } else {
          setProductMockData({ ...mockData.productFilter });
          setcompitetorMockData({ ...mockData.competitorFilter });
        }
        setSelectedGeoFilters({ ...filters });
        setSkeleton(false);
      }, 100);
      setClearFilter(false);
    }
  }, [filters]);

  useEffect(() => {
    if (
      defaultFilters &&
      defaultFilters.pepsi &&
      Object.keys(defaultFilters.pepsi).length > 0 &&
      defaultFilters.competitor &&
      Object.keys(defaultFilters.competitor).length > 0
    ) {
      dispatch(priceElasticityLocalFilter(true));
      if (global?.comparisonLevel == "brand") {
        callback({
          category: defaultFilters.pepsi.category,
          segment: defaultFilters.pepsi.segment,
          brand: defaultFilters.pepsi.brand,
          pepsiPermutation: defaultFilters.pepsi.permutationCombination,
          competitorCategory: defaultFilters.competitor.category,
          competitorSegment: defaultFilters.competitor.segment,
          competitorBrand: defaultFilters.competitor.brand,
          competitorPermutation:
            defaultFilters.competitor.permutationCombination,
        });
      } else {
        callback({
          category: defaultFilters.pepsi.category,
          segment: defaultFilters.pepsi.segment,
          brand: defaultFilters.pepsi.brand,
          subBrand: defaultFilters.pepsi.subBrand,
          packSize: defaultFilters.pepsi.packSize,
          pepsiPermutation: defaultFilters.pepsi.permutationCombination,
          competitorCategory: defaultFilters.competitor.category,
          competitorSegment: defaultFilters.competitor.segment,
          competitorBrand: defaultFilters.competitor.brand,
          competitorSubBrand: defaultFilters.competitor.subBrand,
          competitorPackSize: defaultFilters.competitor.packSize,
          competitorPermutation:
            defaultFilters.competitor.permutationCombination,
        });
      }
      setSkeleton(false);
    }
  }, [defaultFilters]);

  const HistoricPriceLocalFilter = useSelector(
    (state: any) => state.HistoricPriceLocalFilter
  );
  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || HistoricPriceLocalFilter.priceElasticityLocalFilter}
      />
      <CardContent>
        <FilterAccordion title="Product Filters">
          <ProductFiltersV3
            onChange={setSelectedProductFilters}
            data={productMockData}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters}
            clearFilter={clearFilterData}
            params={{ productFilterFlag: "pepsi", ...selectedGeoFilters }}
            showSkeleton={skeleton}
            apiURL="filter/historicPriceProductFilter"
            defaultFilters={defaultFilters?.pepsi}
          />

          <ProductFiltersV3
            onChange={setSelectedCompetitorFilters}
            data={compitetorMockData}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters}
            clearFilter={clearFilterData}
            params={{ productFilterFlag: "competitor", ...selectedGeoFilters }}
            showSkeleton={skeleton}
            apiURL="filter/historicPriceProductFilter"
            defaultFilters={defaultFilters?.competitor}
          />
        </FilterAccordion>

        <Grid className="p-l-16">
          <OrangeBtn className="m-r-20" color="secondary" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            color="primary"
            disabled={disableApplyFilter()}
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CrossPriceElasticityFilter;
