import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import FilterAccordion from '../../../../components/Filters/Filters';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import mockData from '../../../../mocks/apiPredictiveDashboard';
import _ from 'lodash';
import MeasureFilter from '../MeasureFilters';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import Indicator from '../../../../components/Loader';
import { dataFormat } from '../../../../util/helper';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { initialState, dateRange } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import { useSelector } from 'react-redux';
const TopFilters: React.FC<{
  data;
  callback;
  defaultFilters;
  showSkeleton?: boolean;
}> = ({ data, callback, showSkeleton, defaultFilters }) => {
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    variable1: data.measureFilter.variable1.defaultOption.value || undefined,
    variable2: data.measureFilter.variable2.defaultOption.value || undefined,
    somMeasure: data.measureFilter.SOM.defaultOption.value || undefined,
    somGroup: data.measureFilter.SOMVarConfig.defaultOption.value || undefined,
    apiUnits: data.measureFilter.measure.defaultOption.value || undefined,
  });
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);

  const [clearFilterData, setClearFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedAnchorFilters, setSelectedAnchorFilters] = useState({});
  const [selectedProductFilters, setSelectedProductFilters] = useState({});

  const disableApplyFilter = () => {
    const flag: boolean =
      (selectedMeasureFilters.variable1
        ? selectedMeasureFilters.variable1 === 'SOM'
          ? selectedMeasureFilters.somGroup === '' || selectedMeasureFilters.somMeasure === ''
          : selectedMeasureFilters.variable1 === 'API'
          ? selectedMeasureFilters.apiUnits === ''
          : false
        : true) ||
      (selectedMeasureFilters.variable2
        ? selectedMeasureFilters.variable2 === 'SOM'
          ? selectedMeasureFilters.somGroup === '' || selectedMeasureFilters.somMeasure === ''
          : selectedMeasureFilters.variable2 === 'API'
          ? selectedMeasureFilters.apiUnits === ''
          : false
        : true) ||
      selectedGeoFilters.country === '' ||
      selectedGeoFilters.country === null ||
      selectedGeoFilters.geoLevel2 === '' ||
      selectedGeoFilters.geoLevel2 === null;
    return flag;
  };

  const onApplyFilter = () => {
    const measures = {
      ...selectedMeasureFilters,
      apiUnits: selectedMeasureFilters.apiUnits === "KGs ( in '000s )" ? 'Volume' : 'Units',
    };
    const payload = {
        ...selectedGeoFilters,
        ...selectedProductFilters,
        ...selectedAnchorFilters,
        ...measures,
        date: selectedDate,
    };
    callback(payload);
  };

  const clearFilter = () => {
    setClearFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setMeasureFilter({
      variable1: undefined,
      variable2: undefined,
      somMeasure: undefined,
      somGroup: undefined,
      apiUnits: undefined,
    });
    data.measureFilter.variable1.options.map((item) => {
      item.disabled = false;
    });
    data.measureFilter.variable2.options.map((item) => {
      item.disabled = false;
    });
  };
  useEffect(() => {
    if (defaultFilters && _.isObject(defaultFilters) && Object.keys(defaultFilters).length > 0) {
      setMeasureFilter({
        variable1: defaultFilters?.variable1 || undefined,
        variable2: defaultFilters?.variable2 || undefined,
        somMeasure: defaultFilters?.somMeasure || undefined,
        somGroup: defaultFilters?.somGroup || undefined,
        apiUnits: defaultFilters?.apiUnits === 'Volume' ? "KGs ( in '000s )" : "Units ( in '000s )" || undefined,
      });
    }
  }, [defaultFilters]);
  
  const reduxState = useSelector((state: any) => state);

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        show={!reduxState.common.loader && (
          loader || 
          reduxState.ApiPredictive.correlationChartDataLoader || 
          reduxState.ApiPredictive.correlationTableDataLoader || 
          reduxState.ApiPredictive.optimizationChartData || 
          reduxState.ApiPredictive.g5ChartData || 
          reduxState.ApiPredictive.simulationDataLoader || 
          reduxState.ApiPredictive.simulationValueLoader || 
          reduxState.ApiPredictive.multipleAPIDataLoader)}
      />
      <CardContent>
        {!showSkeleton ? (
          <>
            <FilterAccordion title='Geo-Filters'>
              <GeoFiltersV2
                key='api-predictive-top-filter'
                data={geoFilterV2Config}
                onChangeDate={(dt) => {
                  setClearFilter(false);
                  setSelectedDate(dt);
                }}
                onSelectFilters={setSelectedGeoFilters}
                clearFilter={clearFilterData}
                apiPath='ApiPredictive'
                showLoader={setLoader}
                defaultFilters={defaultFilters}
                defaultDate={defaultFilters?.date ? defaultFilters?.date.split('-') : null}
              />
            </FilterAccordion>
            <FilterAccordion title='Product Filters'>
              <ProductFiltersV3
                onChange={(e) => setSelectedProductFilters(e)}
                data={mockData.productFilter}
                onChangeLoader={setLoader}
                selectedGeoFilters={selectedGeoFilters}
                clearFilter={clearFilterData}
                apiURL='filter/products'
                defaultFilters={
                  defaultFilters
                    ? {
                        category: defaultFilters.category,
                        segment: defaultFilters.segment,
                        brand: defaultFilters.brand,
                        subBrand: defaultFilters.subBrand,
                        packSize: defaultFilters.packSize,
                      }
                    : null
                }
              />
              <ProductFiltersV3
                onChange={(e) => setSelectedAnchorFilters(e)}
                data={mockData.anchorFilter}
                onChangeLoader={setLoader}
                selectedGeoFilters={selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}}
                clearFilter={clearFilterData}
                apiURL='filter/anchorproducts'
                defaultFilters={
                  defaultFilters
                    ? {
                        anchorCategory: defaultFilters.anchorCategory,
                        anchorSegment: defaultFilters.anchorSegment,
                        anchorBrand: defaultFilters.anchorBrand,
                        anchorSubBrand: defaultFilters.anchorSubBrand,
                        anchorPackSize: defaultFilters.anchorPackSize,
                      }
                    : null
                }
              />
            </FilterAccordion>
            <FilterAccordion title='Measure Filters'>
              <MeasureFilter data={data.measureFilter} callback={(e) => setMeasureFilter(e)} selectedMeasureFilters={selectedMeasureFilters} selectedProductFilters={selectedProductFilters} selectedAnchorFilters={selectedAnchorFilters} />
            </FilterAccordion>
            <Grid className='p-l-16'>
              <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <FilterAccordion showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} />
                    <Skeleton variant='rectangular' width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </FilterAccordion>
            <Grid className='m-b-20'>
              <FilterAccordion showSkeleton>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </FilterAccordion>
            </Grid>
            <Grid className='p-l-16' container spacing={2}>
              <Skeleton variant='rectangular' className='m-r-20' width={99} height={45} />
              <Skeleton variant='rectangular' className='m-r-20' width={110} height={45} />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TopFilters;
