import { API } from "../../util/helper";

export const fetchPriceSettingScenarioData = async (payload) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/pricesettingtool/priceSettingToolScenario`,
      payload
    );
  } catch (e) {
    return e;
  }
};

export const fetchPermutations = async (payload) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/priceSettingProductFilter`,
      payload
    );
  } catch (e) {
    return e;
  }
};
