import * as React from 'react';
import {
	CardContent,
	Grid,
	Box,
	Typography,
	Switch
} from '@mui/material';
import { Title, StyledCard, Item, CancelButton, SaveButton } from '../../MMPW.Styled';

const HideGrid: React.FC<{}> = ({ }) => {
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid xs={12} display="flex" justifyContent="flex-end">
						<SaveButton>Save</SaveButton>
						<CancelButton>Cancel</CancelButton>
					</Grid>
					<Grid container alignItems="center" justifyContent="space-between">
						<Typography color="black">Hide Grid Line</Typography>
						<Switch color="primary" />
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard >

	);
};

export default HideGrid;
