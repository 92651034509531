import * as React from "react";
import { CardContent, Grid, Card, Checkbox, MenuItem } from "@mui/material";
import { Title, CancelButton, SaveButton } from "../../MMPW.Styled";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveLabel } from "../../../../store/actions/common.action";

const AddLabel: React.FC<{ chart; data; id }> = ({ chart, data, id }) => {
  const [series, setSeries] = React.useState([]);
  const dispatch = useDispatch();
  const tooltips = useSelector((state: any) => state.common.highChartTooltips);

  React.useEffect(() => {
    setSeries(data);
  }, [data]);

  // const onSave = () => {
  // 	callback([...selectedSeries])
  // }

  // const onCancel = () => {
  // 	callback(null)
  // }

  const onChecked = (event, seriesIndex) => {
    dispatch(
      addRemoveLabel(
        [...chart.series[seriesIndex].data],
        id,
        tooltips,
        chart,
        event.target.checked,
      )
    );
  };

  return (
    <Card>
      <CardContent>
        {/* <Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid> */}
        <Grid item xs={12} className="m-b-20">
          <Title>Add Label</Title>
          <hr />
        </Grid>
        <Grid>
          {_.isArray(series) &&
            series.map((item, i) => (
              <Grid
                container
                display="flex"
                alignItems="center"
                className="m-b-10"
              >
               
                <MenuItem  onClick={(event) => onChecked(event, item.seriesIndex)}>
                  <Checkbox
                    checked={
                      !_.isEmpty(tooltips[id]) && tooltips[id]
                        ? tooltips[id].filter(
                            (x) => x.seriesIndex === item.seriesIndex
                          ).length === [...chart.series[item.seriesIndex].data.filter(d=> d.y!==null)].length
                        : false
                    }
                    color="secondary"
                  />
                  {item.title}
               
                </MenuItem>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddLabel;
