import {
  GET_20_SEC_MARKET_CHECK,
  GET_20_SEC_MARKET_CHECK_SUCESS,
  GET_20_SEC_MARKET_CHECK_FAILURE,
} from '../constants';
import { API } from '../../../util/helper';

export const get20secMarketCheck = () => {
  return {
    type: GET_20_SEC_MARKET_CHECK,
  };
};

export const get20secMarketCheckSucess = (payload: any) => {
  return {
    type: GET_20_SEC_MARKET_CHECK_SUCESS,
    payload,
  };
};

export const get20secMarketCheckFailure = () => {
  return {
    type: GET_20_SEC_MARKET_CHECK_FAILURE,
  };
};

const get20SecMarketCheckData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/marketcheck`,
    payload
  );
};

export function fecth20SecMarketCheckData(payload) {
  return (dispatch: any) => {
    dispatch(get20secMarketCheck());
    get20SecMarketCheckData(payload)
      .then((response) => {
        if (response.status !== 200) {
          const response = {
            sales: { value: 0, change: 0 },
            volume: { value: 0, change: 0 },
            units: { value: 0, change: 0 },
            nrr: { value: 0, change: 0 },
            shareSales: { value: 0, change: 0 },
            shareVolume: { value: 0, change: 0 },
            shareUnits: { value: 0, change: 0 },
          };
          dispatch(get20secMarketCheckSucess(response));
        } else {
          dispatch(get20secMarketCheckSucess(response.data));
        }
      })
      .catch((error) => {
        console.error(error.message);
        dispatch(get20secMarketCheckFailure());
      });
  };
}
