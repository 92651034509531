import { Skeleton, Stack, Card, CardContent, Grid, Divider } from '@mui/material';
import React from 'react'
import { CardGrid, MixFilterName, SalesCardGrid, UserInputFilterTitle, UserInputSubTitle } from '../components/ExecutionCommon.Styled';

const renderContent = (type) => {
    switch (type) {
        case 'filter':
            return <>
                <Stack direction="row" spacing={1}>
                    <Stack spacing={1 / 8}>
                        <Skeleton  variant="text" width={75} sx={{ fontSize: '1rem' }} />
                        <Skeleton  variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                    </Stack>
                </Stack>
            </>;
        case 'overviewscore':
            return <>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Card sx={{ border: "none", boxShadow: "none", zIndex: "2", padding: '0px' }}>
                                <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                <CardContent sx={{ padding: '0px' }}>
                                    <Skeleton  variant="circular" width={200} height={200} sx={{ borderRadius: '100px' }} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={9}>
                            <Card sx={{ border: "none", boxShadow: "none" }}>
                                <CardContent>
                                    <CardGrid>
                                        <Skeleton  variant="rectangular" width={150} height={200} sx={{ borderRadius: '4px' }} />
                                        <Skeleton  variant="rectangular" width={150} height={200} sx={{ borderRadius: '4px' }} />
                                        <Skeleton  variant="rectangular" width={150} height={200} sx={{ borderRadius: '4px' }} />
                                        <Skeleton  variant="rectangular" width={150} height={200} sx={{ borderRadius: '4px' }} />
                                        <Skeleton  variant="rectangular" width={150} height={200} sx={{ borderRadius: '4px' }} />

                                    </CardGrid>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </>;
        case 'userinput':
            return <Card style={{ border: "none", boxShadow: "none", padding: '30px', background: '#F1F5FE' }}>
                <UserInputSubTitle>Decision Variables</UserInputSubTitle>
                <Grid container direction="row"
                    justifyContent="left"
                    alignItems="center" sx={{ marginTop: '20px' }} >
                    <Grid item xs={2}><UserInputFilterTitle>Assortment Scenario</UserInputFilterTitle></Grid>
                    <Grid item xs={4}><Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} /></Grid>
                </Grid>
                <Grid container direction="row"
                    justifyContent="left"
                    alignItems="center" sx={{ marginTop: '20px' }} >

                    <Grid item xs={2}><UserInputFilterTitle>Mix Scenario</UserInputFilterTitle></Grid>
                    <Grid item xs={3}><Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} /></Grid>
                    <Grid item xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <MixFilterName>Channel</MixFilterName>
                            <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <MixFilterName>Region</MixFilterName>
                            <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <MixFilterName>Store Segment</MixFilterName>
                            <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>
                </Grid>
                <UserInputSubTitle>Assortment Targets</UserInputSubTitle>
                <Grid container direction="row" spacing={2}
                    justifyContent="left"
                    alignItems="center" sx={{ marginTop: '20px' }} >

                    <Grid item xs={6}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'

                        }}>
                            <UserInputFilterTitle>Must have SKU*</UserInputFilterTitle>
                            <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}>
                            <UserInputFilterTitle>Good to have SKU*</UserInputFilterTitle>
                            <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>

                </Grid>
                <Grid container direction="row" spacing={2}
                    justifyContent="left"
                    alignItems="center" sx={{ marginTop: '20px' }} >

                    <Grid item xs={6}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'

                        }}>
                            <UserInputFilterTitle>Current Average</UserInputFilterTitle>
                            <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}>
                            <UserInputFilterTitle>Current Average</UserInputFilterTitle>
                            <Skeleton variant="rectangular" width={200} height={36} sx={{ borderRadius: '4px' }} />
                        </div>
                    </Grid>

                </Grid>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    <Skeleton variant="rectangular" width={200} height={30} sx={{ borderRadius: '4px' }} />
                </div>
            </Card>;
        case 'opportunities':
            return <>
                <CardContent>
                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }} />

                    <Stack spacing={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={2} alignItems={'center'}>
                                {/* <div style={{height:'100%',width:'100%',border:'solid red 1px',borderRadius:'35px'}}></div> */}
                                <Skeleton  variant="circular" width={40} height={40} sx={{ borderRadius: '20px' }} />

                            </Grid>
                            <Grid item xs={10}>
                                <Stack spacing={1 / 8}>
                                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2} alignItems={'center'}>
                                {/* <div style={{height:'100%',width:'100%',border:'solid red 1px',borderRadius:'35px'}}></div> */}
                                <Skeleton  variant="circular" width={40} height={40} sx={{ borderRadius: '20px' }} />

                            </Grid>
                            <Grid item xs={10}>
                                <Stack spacing={1 / 8}>
                                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2} alignItems={'center'}>
                                {/* <div style={{height:'100%',width:'100%',border:'solid red 1px',borderRadius:'35px'}}></div> */}
                                <Skeleton  variant="circular" width={40} height={40} sx={{ borderRadius: '20px' }} />

                            </Grid>
                            <Grid item xs={10}>
                                <Stack spacing={1 / 8}>
                                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2} alignItems={'center'}>
                                {/* <div style={{height:'100%',width:'100%',border:'solid red 1px',borderRadius:'35px'}}></div> */}
                                <Skeleton  variant="circular" width={40} height={40} sx={{ borderRadius: '20px' }} />

                            </Grid>
                            <Grid item xs={10}>
                                <Stack spacing={1 / 8}>
                                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2} alignItems={'center'}>
                                {/* <div style={{height:'100%',width:'100%',border:'solid red 1px',borderRadius:'35px'}}></div> */}
                                <Skeleton  variant="circular" width={40} height={40} sx={{ borderRadius: '20px' }} />

                            </Grid>
                            <Grid item xs={10}>
                                <Stack spacing={1 / 8}>
                                    <Skeleton  variant="text" width={100} sx={{ fontSize: '1rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                    <Skeleton  variant="text" width={150} sx={{ fontSize: '0.5rem' }}></Skeleton>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Stack>

                </CardContent>
            </>;
        case 'salescard':
            return <SalesCardGrid>
                <Card>
                    <CardContent>
                        <Stack spacing={1 / 12}>
                            <Skeleton  variant="text" width={75} sx={{ fontSize: '1rem' }} />
                            <Skeleton  variant="text" width={175} sx={{ fontSize: '2rem' }} />
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Stack spacing={1 / 12}>
                            <Skeleton  variant="text" width={75} sx={{ fontSize: '1rem' }} />
                            <Skeleton  variant="text" width={175} sx={{ fontSize: '2rem' }} />
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Stack spacing={1 / 12}>
                            <Skeleton  variant="text" width={75} sx={{ fontSize: '1rem' }} />
                            <Skeleton  variant="text" width={175} sx={{ fontSize: '2rem' }} />
                        </Stack>

                    </CardContent>
                </Card>
            </SalesCardGrid>;

        case 'table':
            return <>This is content for otion 3.</>;
        default:
            return <p>Default content.</p>;
    }
};
const Skeletons = ({ type }) => {
    return (
        <>
            {renderContent(type)}
        </>
    )
}

export default Skeletons