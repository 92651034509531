import React, { useEffect, useState } from "react";
import DashboardActions from "../../components/DashboardActions";
import TopFilters from "./components/TopFilters";
import DashboardSourceList from "../../components/DashboardSourceList/DashboardSourceList";
import PageContainer from "../../components/PageContainer/PageContainer";
import { pageNames } from "../../mocks/common";

const DataSourceRefresh: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [skeleton, setSkeleton] = React.useState(true);

  const onApplyFilter = (data) => {
    if (data && data.filters) {
      setAppliedFilters(data.filters);
    }
  };

  return (
    <PageContainer page={pageNames.dataSourceRefresh}>
      <DashboardActions title="Data Source Refresh">
        <TopFilters defaultFilters={{}} callback={onApplyFilter} />
        <DashboardSourceList
          dashboardName="DataSourceRefresh"
          endpoint="MainPageDashboardData"
          appliedFilters={appliedFilters}
        />
      </DashboardActions>
    </PageContainer>
  );
};

export default DataSourceRefresh;
