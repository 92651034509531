import * as React from 'react';
import {
	CardContent,
	Grid,
	Box,
	Typography
} from '@mui/material';
import { Title, StyledCard, Item, CancelButton, SaveButton } from '../../MMPW.Styled';
import { DropdownTitle } from '../../../DashboardFilters/DashboardFilters.Styled';
import Dropdown from '../../../Dropdown';

const data = {
	title: "Point to point percentage call out",
	options: [
		{ id: "", value: "All" },
		{ id: "1", value: "Maxico" },
		{ id: "2", value: "Option2" },
		{ id: "3", value: "Option3" },
		{ id: "4", value: "Option4" },
		{ id: "5", value: "Option5" },
	]
}


const SortData: React.FC<{}> = ({ }) => {
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid xs={12} display="flex" justifyContent="flex-end">
						<SaveButton>Save</SaveButton>
						<CancelButton>Cancel</CancelButton>
					</Grid>
					<Grid md={12} container>
						<Grid sm={5}>
							<DropdownTitle>
								{data.title}
							</DropdownTitle>
							<Dropdown
								data={data.options}
								search={{enable: false}}
							/>
						</Grid>
						<Grid sm={2} display="block">
						<Typography color="black" textAlign="center">to</Typography>
						</Grid>
						<Grid sm={5}>
							<DropdownTitle>
								{data.title}
							</DropdownTitle>
							<Dropdown
								data={data.options}
								search={{enable: false}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>

	);
};

export default SortData;
