import React from 'react';
import { Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import BrandLadderChart from './components/BrandLadderDragChart/BrandLadderChart';
import mockData, { KPIs } from '../../../../mocks/brandLadderMock';
import GrossVolumeCard from '../../components/GrossVolumeCard/GrossVolumeCard';
import { useDispatch, useSelector } from 'react-redux';
import { brandLadderDataLoader } from '../../store/brandLadder.action';
import { fetchBrandLadderAndTableData } from '../../service';
import CustomTable from '../../../../components/CustomTable';
import _ from 'lodash';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { getBreadcrumbs, rendarImage } from '../../../../util/helper';
import { BlackTitle } from '../../../HistoricPriceAndCrossPriceElasticity/HistoricPriceAndCrossPriceElasticity.Styled';
import Dashboard from '../../../../components/Dashboard/Dashboard';

const BrandLadderContainer: React.FC<{ filters; indexes; callback; defaultFilters? }> = ({ filters, indexes, callback, defaultFilters }) => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();
  const [kpiData, setkpiData] = React.useState<any>([...mockData.KPIDefaultData]);
  const [kpiSkeleton, setKpiSkeleton] = React.useState<any>(true);
  const [simulationTableSkeleton, setSimulationTableSkeleton] = React.useState(true);
  const [simulateTableHeading, setSimulateTableHeading] = React.useState<any>([]);
  const [tableValues, setTableValues] = React.useState([]);
  const [pepsiData, setPepsiData] = React.useState<any>(null);
  const [competitorData, setCompetitorData] = React.useState<any>(null);
  const [originalResponse, setOriginalResponse] = React.useState<any>(null);
  const [isSimulated, setIsSimulated] = React.useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);

  const formatKPIs = (data) => {
    if (data) {
      const payload = Object.entries(KPIs).map(([key, val]) => ({
        title: val.title ? val.title : '',
        valueFrom: data[key].valueFrom,
        valueTo: data[key].valueTo,
        percentage: data[key].percentage * 100,
        isNA: (filters.data === 'SELL-IN' && key === 'shareOfMarket') || (filters.data === 'SELL-OUT' && key === 'grossProfit'),
      }));
      setkpiData([...payload]);
      setKpiSkeleton(false);
    }
  };

  const onChangeIndex = (products) => {
    const pepsi = [...(pepsiData ? pepsiData : [])];
    const competitor = [...(competitorData ? competitorData : [])];
    products.map((payload) => {
      if (payload.id === 'pepsi-to-be') {
        const index = pepsi.findIndex((x) => x.productInfo === payload.productInfo);
        if (index !== -1) pepsi[index].indexSimulated = payload.simulationIndex;
      }
      if (payload.id === 'competitor-to-be') {
        const index = competitor.findIndex((x) => x.productInfo === payload.productInfo);
        if (index !== -1) competitor[index].indexSimulated = payload.simulationIndex;
      }
    });
    const requestPayload = {
      ...filters,
      ...(_.isArray(products) && products.length > 0
        ? { simulation: { ...originalResponse, isIndexChange: true, pepsi, competitor } }
        : { simulation: null }),
    };
    callback(requestPayload);
    setIsSimulated(true);
    setIsDataLoaded(false);
    getBrandLadderAndTableData(requestPayload);
  };

  const getBrandLadderAndTableData = async (payload, tableHeadings?) => {
    setPepsiData(null);
    setCompetitorData(null);
    setSimulationTableSkeleton(true);
    setKpiSkeleton(true);
    dispatch(brandLadderDataLoader(true));

    if (payload && payload.measuresLevel) {
      delete payload.measuresLevel;
    }

    // const response = {"grossSale":{"valueFrom":13331279341.39999844,"valueTo":13331279341.39999844,"percentage":0.0},"volumes":{"valueFrom":55553186.27200000,"valueTo":55553186.27200000,"percentage":0.0},"grossProfit":{"valueFrom":9255372709.31199824,"valueTo":9255372709.31199824,"percentage":0.0},"shareOfMarket":{"valueFrom":0.0,"valueTo":0.0,"percentage":0.0},
    // "pepsi":[
    //   {"productName":"RUFFLES 1","productInfo":"RUFFLES","productTag":"RUFFLES","isAnchorProduct":false,"isCompetitor":false,"mffr":"mfrr","ppvElasticity":-0.10000000,"ppvCurrent":226.07460894587177344415317340,"ppvSimulated":226.07460894587177344415317340,
    //   "indexCurrent":42,"indexSimulated":99,"currentMarketShare":0.0,"simulatedMarketShare":0.0,"currentGrossProfit":4267975105.25199836,"simulatedGrossProfit":4267975105.25199836,"currentGrossSale":6346824818.43999856,"simulatedGrossSale":6346824818.43999856,"currentVolume":28074027.63200000,"simulatedVolume":28074027.63200000,"costCogs":487692878.53225005,"daValue":32019549.76475000},
    // {"productName":"SABRITAS PC","productInfo":"SABRITAS PC","productTag":"SABRITAS PC","isAnchorProduct":true,"isCompetitor":false,"mffr":"PEPSICO","ppvElasticity":-1.90940000,"ppvCurrent":254.17279380574222268109443110,"ppvSimulated":254.17279380574222268109443110,
    // "indexCurrent":100.0,"indexSimulated":100.0,"currentMarketShare":0.0,"simulatedMarketShare":0.0,"currentGrossProfit":4987397604.05999988,"simulatedGrossProfit":4987397604.05999988,"currentGrossSale":6984454522.95999988,"simulatedGrossSale":6984454522.95999988,"currentVolume":27479158.64000000,"simulatedVolume":27479158.64000000,"costCogs":468048879.20550000,"daValue":31215350.51950000}],
    // "competitor":[],"miscValues":{"somD":{"slsValOut":16439524817.50000000,"slsUntOut":1152769210.00000000,"slsVolGmsOut":72671890000.00000000,"slsGroup":"Sales"},"elasticity":[{"productName":"RUFFLES","adjSlopePPU":-0.10000000},{"productName":"SABRITAS PC","adjSlopePPU":-1.90940000}],"anchor":[{"sgmntDesc":"SABRITAS PC","brandName":"SABRITAS PC","label":"SABRITAS PC","indexValue":100.0,"mffr":"PEPSICO","slsValIn":1746113630.73999997,"slsUntIn":144015643.00000000,"slsVolGmsIn":6869789660.00000000,"daValue":31215350.51950000,"costCogs":468048879.20550000,"sellIn":true,"ppv":254.17279380574222268109443110,"index":100.0,"som":0.0,"slsValOut":1875212652.50000000,"slsUntOut":119866760.00000000,"slsVolGmsOut":6011860000.00000000,"slsGroup":"Sales"}],"pepsi":[{"sgmntDesc":"RUFFLES","brandName":"RUFFLES","label":"RUFFLES","indexValue":0.0,"mffr":"mfrr","slsValIn":1586706204.60999964,"slsUntIn":135395274.93761476,"slsVolGmsIn":7018506908.00000000,"daValue":32019549.76475000,"costCogs":487692878.53225005,"sellIn":true,"ppv":226.07460894587177344415317340,"index":88.94524294313530086795540989,"som":0.0,"slsValOut":1691563295.00000000,"slsUntOut":110634882.50000000,"slsVolGmsOut":6265950000.00000000,"slsGroup":"Sales"}],"competitor":null},"isIndexChange":false}
    //     if (response && response) {
    //       setOriginalResponse(response);
    //       formatKPIs(response);
    //       setPepsiData([...response.pepsi])
    //       if (filters === 'SELL-IN') {
    //         formatTableData([...response.pepsi], tableHeadings);
    //         setCompetitorData([]);
    //       } else {
    //         formatTableData([...response.pepsi, ...response.competitor], tableHeadings);
    //         setCompetitorData([...response.competitor])
    //       }
    //     }
    //     dispatch(brandLadderDataLoader(false));
    //     setIsDataLoaded(true);
    await fetchBrandLadderAndTableData(payload)
      .then((response) => {
        if (response && response.data) {
          setOriginalResponse(response.data);
          formatKPIs(response.data);
          setPepsiData([...response.data.pepsi]);
          if (filters.data === 'SELL-IN') {
            formatTableData([...response.data.pepsi], tableHeadings);
            setCompetitorData([]);
          } else {
            formatTableData([...response.data.pepsi, ...response.data.competitor], tableHeadings);
            setCompetitorData([...response.data.competitor]);
          }
        }
        dispatch(brandLadderDataLoader(false));
        setIsDataLoaded(true);
      })
      .catch((e) => {
        dispatch(brandLadderDataLoader(false));
        setIsDataLoaded(true);
        setKpiSkeleton(false);
      });
  };

  React.useEffect(() => {
    if (filters && filters.country) {
      initialCall();
    }
  }, [filters]);

  const initialCall = () => {
    setIsSimulated(false);
    setIsDataLoaded(false);
    let tableHeadings: any = [];
    if (filters.data === 'SELL-IN') {
      tableHeadings = [...mockData.simulationTableHeadSellIn.heading];
    } else if (filters.data === 'SELL-OUT') {
      tableHeadings = [...mockData.simulationTableHeadSellOut.heading];
    }
    setSimulateTableHeading(tableHeadings);
    getBrandLadderAndTableData(
      defaultFilters && Object.keys(defaultFilters).length ? { ...filters, ...defaultFilters } : { ...filters, simulation: null },
      tableHeadings
    );
  };

  const onCellKeyDown = (payload) => {
    if (payload.event.keyCode === 13) {
      setSimulationTableSkeleton(true);
      const payload = [];
      tableValues.map((item) => {
        let row = {};
        Object.entries(item).map(([key, val]) => {
          row = {
            ...row,
            [key]: val.editable ? parseFloat(val.value) : val.value,
            ...(key === 'productName' ? { productInfo: val.productInfo } : {}),
          };
        });
        payload.push(row);
      });

      const mappedData = [];
      payload.map((item) => {
        const originalData = [
          ...(originalResponse && originalResponse.pepsi ? originalResponse.pepsi : []),
          ...(originalResponse && originalResponse.competitor ? originalResponse.competitor : []),
        ].find((x) => x.productInfo === item.productInfo);
        const record = { ...originalData, ...item };
        if (record.image) {
          delete record.image;
        }
        mappedData.push(record);
      });

      let simulationProducts: any = {};
      if (mappedData.length > 0) {
        simulationProducts.pepsi = mappedData.filter((x) => !x.isCompetitor);
        simulationProducts.competitor = mappedData.filter((x) => x.isCompetitor);
      }
      const requestPayload = {
        ...filters,
        ...(payload ? { simulation: { ...originalResponse, isIndexChange: false, ...simulationProducts } } : { simulation: null }),
      };
      callback(requestPayload);
      setIsSimulated(true);
      setIsDataLoaded(false);
      getBrandLadderAndTableData(requestPayload);
    }
  };

  const dataHandler = (payload) => {
    const newData = [...tableValues];
    newData[payload.row][payload.columnName].value = payload.value;
    setTableValues(newData);
  };

  const formatTableData = (data, tableHeadings?) => {
    if (_.isArray(data) && data.length > 0) {
      const rows: any = [];
      Promise.all(
        data.map(async (item) => {
          item.image = {
            src: await rendarImage(item.productName, filters.country),
            alt: item.productName,
            height: 100,
            width: 100,
            tooltip: item.productInfo,
          };
        })
      ).then(() => {
        if (!tableHeadings) {
          tableHeadings = [...simulateTableHeading];
        }
        data.map((item) => {
          let row = {};
          tableHeadings.map((head) => {
            row = {
              ...row,
              [head.value]: {
                ...head,
                value:
                  head.value === 'currentMarketShare' || head.value === 'simulatedMarketShare'
                    ? (item[head.value] * 100).toFixed(2)
                    : item[head.value],
                ...(head?.image && item.image ? { image: item.image } : {}),
                key: head.value,
                ...(head.value === 'productName' ? { productInfo: item.productInfo } : {}),
              },
            };
          });
          rows.push(row);
        });
        setTableValues(rows);
        setSimulationTableSkeleton(false);
      });
    }
  };

  return (
    <Card>
      <CardContent>
        <Dashboard
          title={null}
          showSkeleton={kpiSkeleton}
          chartRef={() => {}}
          showActionButton={true}
          actionButtons={{
            mmpw: false,
            screenshot: true,
            editMedia: false,
          }}
          id={null}
        >
          <Grid container className='m-b-20'>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
              {kpiData.map((item) => (
                <Grid item xs={6} md={3}>
                  <GrossVolumeCard data={item} showSkeleton={kpiSkeleton} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container className='m-b-20'>
            <Grid item xs={12} className='m-b-10'>
              {!kpiSkeleton ? (
                <>
                  {filters.comparisonLevel ? (
                    <Typography sx={{ fontSize: 16 }}>
                      <span style={{ fontWeight: 'bold', color:"#000" }}>Brand Comparison Level: </span>
                      <span style={{ color: 'gray' }}>{filters.comparisonLevel === 'brand' ? 'Brand' : 'Brand Packsize'}</span>
                    </Typography>
                  ) : null}
                  {filters.anchor ? (
                    <Typography sx={{ fontSize: 16 }}>
                      <span style={{ fontWeight: 'bold', color:"#000"  }}>Anchor Filter: </span>
                      <span style={{ color: 'gray' }}>
                        {getBreadcrumbs(filters.anchor, ['category', 'segment', 'brand', 'subBrand', 'packSize'], 3)}
                      </span>
                    </Typography>
                  ) : null}
                </>
              ) : (
                <>
                  <Typography>
                    <Skeleton />
                  </Typography>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </>
              )}
            </Grid>
            <BrandLadderChart
              indexes={indexes}
              filters={filters}
              pepsi={pepsiData}
              competitor={competitorData}
              callback={onChangeIndex}
              reset={initialCall}
              showSkeleton={kpiSkeleton}
              isSimulated={isSimulated}
              isDataLoaded={isDataLoaded}
            />
          </Grid>
          <BlackTitle style={{ fontSize: 20, fontWeight: 600, mb: 3 }}>Brand Ladder Simulation Table </BlackTitle>
          {simulationTableSkeleton ? (
            <Grid className='m-t-20'>
              <CustomTable heading={simulateTableHeading} value={mockData.simulationResponseDataSellIn} showSkeleton={simulationTableSkeleton} />
            </Grid>
          ) : tableValues.length > 0 ? (
            <Grid
              container
              className='m-b-20'
              style={{
                maxWidth: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)',
              }}
            >
              <CustomTable
                heading={simulateTableHeading}
                value={tableValues}
                callback={dataHandler}
                onCellKeyDown={onCellKeyDown}
                onCellBlur={() => {}}
                isFormatNumber={true}
                customTableCellStyle={true}
              />
            </Grid>
          ) : (
            <Box style={{ height: 200 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.noData}</NoData>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default BrandLadderContainer;
