export const columnsToDowloadListTableSKU = [
  { headerName: "Segment", field: "segment" },
  { headerName: "Brand", field: "brand" },
  { headerName: "Sub-Brand", field: "subBrand" },
  { headerName: "Pack-Size", field: "packSize" },
  { headerName: "SKU Name", field: "skuName" },
  { headerName: "Flavor", field: "flavor" },
  { headerName: "Rank", field: "rank" },
  { headerName: "Model Output", field: "modelOutput" },
  { headerName: "Net Incremental Revenue Mix (%)", field: "netIncrementalRevenueMix" },
  { headerName: "Cumulative Incremental Revenue Mix (%)", field: "cumulativeNetIncrementalRevenueMix" },
  { headerName: "Penetration (Distribution in %)", field: "penetration" },
  { headerName: "Manufacturer Walk Rate (%)", field: "manufacturerWalkRate" },
  { headerName: "Revenue Mix (%)", field: "revenueMix" },
  { headerName: "NOPBT (%)", field: "nopbtPercent" },
  { headerName: "Total Score", field: "totalScore" },
  { headerName: "Incremental Revenue Score", field: "incrementalRevenueScore" },
  { headerName: "Incremental NoPBT Score", field: "incrementalNoPBTScore" },
  { headerName: "NOPBT Score", field: "noPBTScore" },
  { headerName: "Growth Score", field: "growthScore" },
  { headerName: "Market Size Score", field: "marketSizeScore" },
  { headerName: "Market Growth Score", field: "marketGrowthScore" },
  { headerName: "Capacity Constraint Score", field: "capacityConstraintScore" },
  { headerName: "Operative Complexity Score", field: "operativeComplexityScore" },
  { headerName: "Inventory Turn Score", field: "inventoryTurnScore" },
  { headerName: "SOP Revenue Mix (%)", field: "sopRevenueMix" },
  { headerName: "SOP NOPBT Mix (%)", field: "sopNopbtMix" },
];