import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import CreateNotification from "./components/CreateNotification/CreateNotification";
import NotificationsListContainer from "./components/NotificationsListContainer/NotificationsListContainer";
import { Title } from "../../styles/Common.Styled";
import PageContainer from "../../components/PageContainer/PageContainer";
import { pageNames } from "../../mocks/common";

const AlertAndNotification: React.FC = () => {
  const [selectedNotification, setSelectedNotification] = useState({});
  return (
    <PageContainer page={pageNames.alertAndNotification}>
      <Box>
        <Title sx={{ mb: 2 }}>Alerts And Notifications</Title>
        <CreateNotification data={selectedNotification}/>
        <Divider sx={{ m: 3 }} />
        <NotificationsListContainer callback={setSelectedNotification} />
      </Box>
    </PageContainer>
  );
};

export default AlertAndNotification;
