import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useStyles } from "../ScenarioLibrary/ScenarioLibrary.styles";
import { ViewEditButton } from "../../AssortmentTool.Styled";

const ConfirmDialog = ({ open, handleClose, deleteText }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog open={open} aria-labelledby="alert-dialog-title" maxWidth={"sm"}>
        <DialogTitle
          className={classes.customPadding}
          sx={{ padding: "2rem", textAlign: "center" }}
          id="alert-dialog-title"
        >
          Are you sure you want to delete<b> "{deleteText}"</b> ?
        </DialogTitle>
        <DialogActions sx={{ padding: "1rem", gap: "1rem" }}>
          <Button onClick={() => handleClose(false)} variant="outlined">
            No
          </Button>
          <ViewEditButton onClick={() => handleClose(true)} autoFocus>
            Yes
          </ViewEditButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
