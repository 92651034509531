import React, { useEffect } from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { RedBtn } from "../../../AssortmentTool/components/AssortmentOverallFilters/AssortmentOverallFilters.Styled";
import {
  dispatchState,
  fetchApi,
  geoLevelSummaryLoader,
  geoLevelSummaryResponse,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import {
  fetchExecutionGeoTableFiltersRequest,
  getGeoTableFilterData,
  selectedTableFilters,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevelFilter.action";
import DynamicFilter from "../CommonComponents/DynamicFilter";
import { ApplyBtn } from "../../../../styles/Common.Styled";
import { BlueBtn } from "../Portfolio/portfolioTableStyled";
import { API_URL } from "../../../../store/actions/constants";

const GeoLevelFilters: React.FC<{}> = ({}) => {
  const { geoFilterData } = useSelector(
    (state: any) => state.portfolioGeoLevelFilter
  );
  const selectedFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );

  const selectedTableLevelFilters = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.selectedFilters
  );
  const overallFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const executionUserInputs = useSelector(
    (state: any) => state.executionUserInputs
  );
  const { selectedLevelForGeoLevelSummary, geoLevelSummaryData } = useSelector(
    (state: any) => state.portfolioGeoLevelReducer
  );
  const dispatch = useDispatch();
  const currentAverage = useSelector(
    (state: any) => state.executionUserInputs.currentAverage
  );

  useEffect(() => {
    if (selectedFilters.storeSegment.length !== 0) {
      handleGeoLevelFilters();
    }
  }, [currentAverage]);

  const updateSelectedTableFilters = (filters) => {
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filterData = filters[key].options;
        const values = filterData
          .filter((option) => !option.checked)
          .map((option) => option.value);
        dispatch(
          selectedTableFilters({
            name: key,
            value: values,
          })
        );
      }
    }
  };

  const handleGeoLevelFilters = (key?, filterData?, isReset?) => {
    dispatch(
      selectedTableFilters({
        name: key,
        value: filterData,
      })
    );
    dynamicApiFilter(key, filterData, isReset);
  };

  const filterMapper = {
    segment: "Segment",
    brand: "Brand",
    sku: "Sku",
  };

  const dynamicApiFilter = (key, filterData, isReset?) => {
    let payload = {};
    let previousSelection = {
      Sku: [],
      Brand: [],
      Segment: [],
    };
    if (isReset) {
      previousSelection = {
        ...previousSelection,
      };
    } else {
      previousSelection = {
        ...previousSelection,
        Sku: selectedTableLevelFilters.sku,
        Brand: selectedTableLevelFilters.brand,
        Segment: selectedTableLevelFilters.segment,
        [filterMapper[key]]: filterData,
      };
    }

    const filteredPreviousSelection = Object.fromEntries(
      Object.entries(previousSelection).filter(
        ([key, value]) => key !== undefined && value !== null
      )
    );

    payload = {
      availableDynamicFilters: ["Segment", "Brand", "Sku"],
      availableDynamicFiltersSelectedValues: filteredPreviousSelection,
      overallFiltersSelectedValues: {
        Country: selectedFilters.country,
        Period: selectedFilters.period,
        BusinessUnit: selectedFilters.businessUnit,
        channel: selectedFilters.channel,
        region: selectedFilters.region,
        storeSegment: selectedFilters.storeSegment,
      },
      toolName: "Execution",
      pageName: "Execution",
    };

    let dispatchAction: dispatchState = {
      loaderStateFetch: fetchExecutionGeoTableFiltersRequest(),
    };
    let geoFilterUrl = API_URL.EXECUTION.TRACKER_GEO_TABLE_FILTER;

    dispatch(fetchApi(payload, geoFilterUrl, dispatchAction))
      .then((res) => {
        updateSelectedTableFilters(res.data);
        dispatch(getGeoTableFilterData(res.data));
      })
      .catch((error) => {
        dispatch(toast("Something Went Wrong!", true, 2000, "error"));
        console.log("ERROR", error);
      });
  };

  const applyFilters = (isReset?: Boolean) => {
    const payloadForGeoLevel = {
      overallFilters: {
        period: overallFilters.period,
        businessUnit: overallFilters.businessUnit,
        country: [overallFilters.country[0].toUpperCase()],
      },
      geo: {
        channel: overallFilters.channel,
        region: overallFilters.region,
        storeSegment: overallFilters.storeSegment,
        store: overallFilters.store,
      },
      portfolio: {
        Sku: isReset ? [] : selectedTableLevelFilters.sku,
        Brand: isReset ? [] : selectedTableLevelFilters.brand,
        Segment: isReset ? [] : selectedTableLevelFilters.segment,
      },
      userInputFilter: {
        decisionVariable: {
          assortmentScenarioName:
            executionUserInputs.selectedData.assortment_scenario,
          mixScenarioName: executionUserInputs.selectedData.mix_scenario,
        },
        assortmentsTargets: {
          goodToHaveSkus: [executionUserInputs.weights.goodToHaveSku],
          mustHaveSKUs: [executionUserInputs.weights.mustHaveSku],
        },
      },
      level: selectedLevelForGeoLevelSummary,
    };

    const dispatchActionsForGeoLevel: dispatchState = {
      loaderStateFetch: geoLevelSummaryLoader(true),
    };

    dispatch(
      fetchApi(
        payloadForGeoLevel,
        API_URL.EXECUTION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_EXECUTION,
        dispatchActionsForGeoLevel
      )
    )
      .then((res: any) => {
        dispatch(geoLevelSummaryLoader(false));
        dispatch(geoLevelSummaryResponse(res?.data?.geoLevelSummaryData));
      })
      .catch((error) => {
        dispatch(geoLevelSummaryLoader(false));
        console.log("ERROR", error);
      });
  };

  const isEmptyObject = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const resetFilters = () => {
    dynamicApiFilter(null, null, true);
    applyFilters(true);
  };

  return (
    <>
      {geoLevelSummaryData.length !== 0 && !isEmptyObject(geoFilterData) ? (
        <>
          <Grid
            style={{ display: "flex",marginLeft:"5px", whiteSpace: "nowrap", marginTop: "15px" }}
          >
            <DynamicFilter
              filtersConfig={geoFilterData}
              onChangeFilter={handleGeoLevelFilters}
            />
            <Grid container justifyContent="flex-end" className="p-l-16">
              <BlueBtn className="m-r-20" onClick={() => applyFilters(false)}>
                Apply Filter
              </BlueBtn>
              <RedBtn className="m-r-20" onClick={resetFilters}>
                Reset Filter
              </RedBtn>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {["Segment", "Brand", "Sku"].map((ele, index) => (
            <Grid
              style={{
                marginTop: "15px",
                display: "flex",
                marginRight: "10px",
                whiteSpace: "nowrap",
              }}
              key={index}
            >
              <Typography style={{ marginRight: "10px", marginTop: "5px" }}>
                {ele}
              </Typography>
              <Skeleton
                variant="rectangular"
                width={150}
                height={35}
                style={{ marginRight: "10x" }}
              />
            </Grid>
          ))}
          <Skeleton
            variant="rectangular"
            width={100}
            height={35}
            style={{
              marginRight: "10x",
              marginTop: "15px",
              marginLeft: "10px",
            }}
          />
        </>
      )}
    </>
  );
};

export default GeoLevelFilters;
