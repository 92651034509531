import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Dropdown from "../Dropdown";
import { DropdownTitle } from "../DashboardFilters/DashboardFilters.Styled";
import CustomDateRangePicker from "../UI-components/DateRangePicker";
import { dataFormat } from "../../util/helper";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeoFiltersData } from "../../store/actions/geoFilter.action";

export const initialState = {
  country: null,
  geoLevel: null,
  geoLevel2: null,
  region: null,
  city: null,
  channel: null,
  subChannel: null,
  keyAccounts: null
}

const GeoFilters: React.FC<{ data; onChangeDate?; showSkeleton?: boolean, defaultDate?, onSelectFilters, defaultFilters?, showDatePicker?: boolean}> = ({
  data,
  onChangeDate,
  defaultDate,
  defaultFilters,
  onSelectFilters,
  showDatePicker = true
}) => {
  const [countries, setCountries] = useState([]);
  const [regions, setRegion] = useState([]);
  const [cities, setCities] = useState([]);
  const [channels, setChannels] = useState([]);
  const [subChannels, setSubChannel] = useState([]);
  const [keyAccounts, setKeyAccounts] = useState([]);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [disabledGeoFilters, setDisabledFilters] = useState({
    country: false,
    geoLevel: true,
    region: true,
    city: true,
    channel: true,
    subChannel: true,
    keyAccounts: true
  });
  const dispatch = useDispatch();
  
  const geoReducer = useSelector((state: any) => state.geoFilter);
  const geoLevels = useSelector((state: any) => (state.geoFilter && state.geoFilter.geoLevel)? state.geoFilter.geoLevel.geolevel : []);
  useEffect(() => {
      if(geoReducer.countries){
        setCountries(geoReducer.countries)
      }
      if(geoReducer.geoLevel){
        if(defaultFilters && _.isString(defaultFilters.geoLevel)){
          onChangeGeoLevel([defaultFilters.geoLevel])
        }else{
          const deafultGeoLevel = geoLevels.find(i=> i.defaultVal);
          if(deafultGeoLevel && deafultGeoLevel.value){
            onChangeGeoLevel([deafultGeoLevel.value])
          }
        }
      }
  }, [geoReducer.countries, geoReducer.geoLevel])

  useEffect(() => {
      if(defaultFilters){
        setSelectedGeoFilters(defaultFilters);
      }
  }, [defaultFilters])

  const onSelectDate = (date) => {
    if (date.length === 2) {
      const formattedDate = [];
      for (let i = 0; i < date.length; i++) {
        if (date[i]) formattedDate.push(dataFormat(date[i], "MM/dd/yyyy"));
      }
      onChangeDate(formattedDate.join("-"));
    }
  };

  const onChangeCountry = (country) => {
    const payload = { ...initialState, country };
    setSelectedGeoFilters(payload);
    dispatch(fetchGeoFiltersData(country));
    onSelectFilters({...payload})
  }

  const onChangeGeoLevel = (geoLevel) => {
    if(geoLevels && _.isArray(geoLevels)){
    let result;
    if(_.isArray(geoLevel)){
      result = geoLevels.find((item)=> item.value === geoLevel[0]);
    }else{
      result = geoLevels.find((item)=> item.value === geoLevel);
    }
    
    const newDisableData = {...disabledGeoFilters, geoLevel: false};
    const newSelectedData = {...selectedGeoFilters, geoLevel};
    if(_.isArray(result.regions)){
      if(result.regions.length === 1){ 
        if(_.findIndex(result.regions, (item: any)=> item.value === 'NA') !== -1)
          newDisableData.region = true;
        else{
          newDisableData.region = false;
        }
      }else{
        newDisableData.region = false;
      }
      const deafultRegion = result.regions.find(i=> i.defaultVal);
      if(deafultRegion){
        newSelectedData.region = result.regions.filter(x=> x.defaultVal).map(item=> item.value);
      }else{
        newSelectedData.region = result.regions.map(item=> item.value);
      }
      setRegion(result.regions);
    }

    if(_.isArray(result.cities)){
      if(result.cities.length === 1){ 
        if(_.findIndex(result.cities, (item: any)=> item.value === 'NA') !== -1)
          newDisableData.city = true;
        else 
          newDisableData.city = false;
      }else{
        newDisableData.city = false;
      }
      const deafultCity = result.cities.find(i=> i.defaultVal);
      if(deafultCity){
        newSelectedData.city = result.cities.filter(x=> x.defaultVal).map(item=> item.value);
      }else{
        newSelectedData.city = result.cities.map(item=> item.value);
      }
      setCities(result.cities);
    }
  
    if(_.isArray(result.channel)){
      if(result.channel.length === 1){ 
        if(_.findIndex(result.channel, (item: any)=> item.value === 'NA') !== -1)
          newDisableData.channel = true;
        else 
          newDisableData.channel = false;
      }else{
        newDisableData.channel = false;
      }
      const deafultChannel = result.channel.find(i=> i.defaultVal);
      if(deafultChannel){
        newSelectedData.channel = result.channel.filter(x=> x.defaultVal).map(item=> item.value);
      }else{
        newSelectedData.channel = result.channel.map(item=> item.value);
      }
      setChannels(result.channel);
    }
    if(_.isArray(result.subChannel)){
      if(result.subChannel.length === 1){ 
        if(_.findIndex(result.subChannel, (item: any)=> item.value === 'NA') !== -1)
          newDisableData.subChannel = true;
        else 
          newDisableData.subChannel = false;
      }else{
        newDisableData.subChannel = false;
      }
      const deafultSubChannel = result.subChannel.find(i=> i.defaultVal);
      if(deafultSubChannel){
        newSelectedData.subChannel = result.subChannel.filter(x=> x.defaultVal).map(item=> item.value);
      }else{
        newSelectedData.subChannel = result.subChannel.map(item=> item.value);
      }
      setSubChannel(result.subChannel);
    }
    setDisabledFilters(newDisableData);
    setSelectedGeoFilters(newSelectedData);
    onSelectFilters({...newSelectedData})
  }
  }

  const onChangeOption = (key, val) => {
    if (_.isArray(val) && val.length === 0) {
      val = null;
    }
    const payload = { ...selectedGeoFilters, [key]: val };
    setSelectedGeoFilters(payload);
    onSelectFilters({...payload})
  };

  return (
    <Grid container spacing={2} columns={17}>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.country.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.country}
          data={countries}
          onChange={(data) =>
            onChangeCountry(data[0])
          }
          defaultOption={[selectedGeoFilters.country] || ''}
        />
      </Grid>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.geoLevel.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.geoLevel}
          data={geoLevels}
          onChange={(data) =>
            onChangeGeoLevel(data)
          }
          defaultOption={selectedGeoFilters.geoLevel || ''}
          placeholder={data.geoLevel.placeholder}
        />
      </Grid>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.region.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.region}
          data={regions}
          onChange={(data) =>
            onChangeOption('region', data)
          }
          defaultOption={selectedGeoFilters.region || ["empty"]}
          placeholder={data.region.placeholder}
          multiple={data.region.multiple}
          allOption={data.region.all}
          defaultSelectAll={data.defaultSelectAll}
          selectionLimit={data.selectionLimit}
        />
      </Grid>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>{data.city.title}</DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.city}
          data={cities}
          onChange={(data) =>
            onChangeOption('city', data)
          }
          defaultOption={selectedGeoFilters.city || ["empty"]}
          placeholder={data.city.placeholder}
          multiple={data.city.multiple}
          allOption={data.city.all}
        />
      </Grid>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.channel.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.channel}
          data={channels}
          onChange={(data) =>
            onChangeOption('channel', data)
          }
          defaultOption={selectedGeoFilters.channel || ["empty"]}
          placeholder={data.channel.placeholder}
          multiple={data.channel.multiple}
          allOption={data.channel.all}
        />
      </Grid>
      <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.subChannel.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.subChannel}
          data={subChannels}
          onChange={(data) =>
            onChangeOption('subChannel', data)
          }
          defaultOption={selectedGeoFilters.subChannel || ["empty"]}
          placeholder={data.subChannel.placeholder}
          multiple={data.subChannel.multiple}
          allOption={data.subChannel.all}
        />
      </Grid>
      {/* <Grid item xs={12} md={2} sm={2}>
        <DropdownTitle>
          {data.keyAccounts.title}
        </DropdownTitle>
        <Dropdown
          disabled={disabledGeoFilters.keyAccounts}
          data={keyAccounts}
          onChange={(data) =>
            onChangeOption('keyAccounts', data)
          }
          defaultOption={selectedGeoFilters.keyAccounts || ["empty"]}
          placeholder={data.keyAccounts.placeholder}
          multiple={data.keyAccounts.multiple}
          allOption={data.keyAccounts.all}
        />
      </Grid> */}
      {showDatePicker &&
        <Grid item xs={12} sm={3}>
          <DropdownTitle>Date Range</DropdownTitle>
          <CustomDateRangePicker
            defaultDate={defaultDate}
            callback={onSelectDate}
          />
        </Grid>
      }
    </Grid>
  );
};

export default GeoFilters;
