import { Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import Indicator from '../../../../components/Loader';
import { dataFormat } from '../../../../util/helper';
import MeasureFilters from '../MeasureFilters';

import FilterAccordion from '../../../../components/Filters/Filters';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';

import { clearProductFilters, fetchProductFiltersData, onChangeProductOption } from '../../../../store/actions/productFilter.action';
import { productInitialState, setValues } from '../../../../store/reducers/productFilter.reducer';

import { dateRange } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import { initialState } from '../../../../components/GeoFilters/GeoFilters';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { clearAnchorFilters, fetchAnchorProductFiltersData, onChangeAnchorOption } from '../../../../store/actions/anchorFilter.action';
import { anchorInitialState, setAnchorValues } from '../../../../store/reducers/anchorFilter.reducer';
import { linearRegressionLoader } from '../../store/ProfitParabolaDashboard.action';

const TopFilters: React.FC<{ data; callback; defaultFilters }> = ({ data, callback, defaultFilters }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);

  const geoFilter = useSelector((state: any) => state.geoFilter);
  const [clearGeoFilterData, clearGeoFilter] = useState<any>(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState<any>(initialState);

  const [selectedAnchorFilters, setSelectedAnchorFilters] = useState<any>({
    anchorCategory: null,
    anchorSegment: null,
    anchorBrand: null,
    anchorSubBrand: null,
    anchorPackSize: null,
  });

  const [selectedProductFilters, setSelectedProductFilters] = useState<any>({
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  });

  const productFilter = useSelector((state: any) => state.productFilter);
  // const selectedProductFilters = productFilter && productFilter.selectedFilters;
  // const disabledProductFilters = (productFilter &&
  //   productFilter.disabledFilters) || {
  //   ...productInitialState.disabledFilters,
  // };

  const anchorFilter = useSelector((state: any) => state.anchorFilter);
  // const selectedAnchorFilters = anchorFilter && anchorFilter.selectedFilters;
  // const disabledAnchorFilters = (anchorFilter &&
  //   anchorFilter.disabledFilters) || {
  //   ...anchorInitialState.disabledFilters,
  // };

  const [selectedMeasureFilters, setMeasureFilter] = useState({
    periodicity: data.measureFilter.periodicity.defaultOption.value,
    variable1: data.measureFilter.variable1.defaultOption.value,
    variable2: data.measureFilter.variable2.defaultOption.value,
    apiUnits: data.measureFilter.apiUnits.defaultOption.value,
  });

  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
      dispatch(clearProductFilters());
      Object.keys(data.productFilter).map((key) => (data.productFilter[key].options = [])); //Clear the product data
      dispatch(
        fetchProductFiltersData(
          {
            country: selectedGeoFilters.country,
            ...productInitialState.selectedFilters,
          },
          data.productFilter
        )
      );
      dispatch(clearAnchorFilters());
      Object.keys(data.anchorFilter).map((key) => (data.anchorFilter[key].options = [])); //Clear the anchorFilter data
      dispatch(
        fetchAnchorProductFiltersData(
          {
            country: selectedGeoFilters.country,
            ...anchorInitialState.selectedFilters,
          },
          data.anchorFilter
        )
      );
    }
  }, [selectedGeoFilters.country]);

  const reduxState = useSelector((state: any) => state);

  const onSelectProductFilter = (payload) => {
    if (payload.type === 'PRODUCT_FILTER') {
      const key = Object.keys(payload.filters)[0];
      const val = payload.filters[key];
      dispatch(onChangeProductOption(key, val));
      const filterPayload = setValues(selectedProductFilters, payload.filters);
      const requestBody = {
        country: selectedGeoFilters.country,
        ...filterPayload,
        [key]: val,
      };
      if (key !== 'packSize' && ((_.isArray(val) && val.length > 0) || (_.isString(val) && val !== null))) {
        dispatch(fetchProductFiltersData(requestBody, data.productFilter));
      }
    }
    if (payload.type === 'ANCHOR_FILTER') {
      const key = Object.keys(payload.filters)[0];
      const val = payload.filters[key];
      dispatch(onChangeAnchorOption(key, val));
      const filterPayload = setAnchorValues(selectedAnchorFilters, payload.filters);
      const requestBody = {
        country: selectedGeoFilters.country,
        ...filterPayload,
        [key]: val,
      };
      if (key !== 'anchorPackSize' && ((_.isArray(val) && val.length > 0) || (_.isString(val) && val !== null))) {
        dispatch(fetchAnchorProductFiltersData(requestBody, data.anchorFilter));
      }
    }
  };

  const onApplyFilter = () => {
    const payload =  {
        ...selectedGeoFilters,
        ...selectedProductFilters,
        ...selectedAnchorFilters,
        ...selectedMeasureFilters,
        date: selectedDate,
        apiUnits: selectedMeasureFilters.apiUnits === "KGs ( in '000s )" ? 'Volume' : 'Units',
      }
    callback(payload);
  };

  const clearFilter = () => {
    // dispatch(clearProductFilters());
    // dispatch(clearAnchorFilters());
    setSelectedAnchorFilters({
      anchorCategory: null,
      anchorSegment: null,
      anchorBrand: null,
      anchorSubBrand: null,
      anchorPackSize: null,
    });
    setSelectedProductFilters({
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setMeasureFilter({
      periodicity: data.measureFilter.periodicity.defaultOption.value,
      variable1: data.measureFilter.variable1.defaultOption.value,
      variable2: data.measureFilter.variable2.defaultOption.value,
      apiUnits: data.measureFilter.apiUnits.defaultOption.value,
    });
    setSelectedGeoFilters({ ...initialState });
    clearGeoFilter(true);
    setSelectedDate(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);
  };

  const onChangeAnchorFilter = (data) => {
    setSelectedAnchorFilters({ ...data });
    clearGeoFilter(false);
  };

  const onChangeProductFilters = (data) => {
    setSelectedProductFilters({ ...data });
    clearGeoFilter(false);
  };

  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setSelectedGeoFilters({
        country: defaultFilters.country,
        geoLevel: defaultFilters.geoLevel,
        geoLevel2: defaultFilters.geoLevel2,
        channel: defaultFilters.channel,
      });
      setSelectedAnchorFilters({
        anchorCategory: defaultFilters.anchorCategory,
        anchorSegment: defaultFilters.anchorSegment,
        anchorBrand: defaultFilters.anchorBrand,
        anchorSubBrand: defaultFilters.anchorSubBrand,
        anchorPackSize: defaultFilters.anchorPackSize,
      });
      setSelectedProductFilters({
        category: defaultFilters.category,
        segment: defaultFilters.segment,
        brand: defaultFilters.brand,
        subBrand: defaultFilters.subBrand,
        packSize: defaultFilters.packSize,
      });
      setMeasureFilter({
        periodicity: defaultFilters.periodicity,
        variable1: defaultFilters.variable1,
        variable2: defaultFilters.variable2,
        apiUnits: defaultFilters.apiUnits === 'Volume' ? "KGs ( in '000s )" : "Units ( in '000s )",
      });
    }
  }, [defaultFilters]);

  const disableApplyFilter = () => {
    const flag: boolean = (
      productFilter.loading ||
      anchorFilter.loading ||
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel ||
      !selectedGeoFilters.geoLevel2 ||
      !selectedProductFilters.category ||
      !selectedAnchorFilters.anchorCategory ||
      !selectedMeasureFilters.periodicity ||
      !selectedMeasureFilters.variable1 ||
      !selectedMeasureFilters.variable2 ||
      !selectedMeasureFilters.apiUnits
    )
    return flag;
  }

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        show={
          !reduxState.common.loader &&
          (loader || reduxState.ProfitParabolaLinearRegression.linearRegression || geoFilter.countriesLoading || geoFilter.geoLevelLoading || productFilter.loading || anchorFilter.loading)
        }
      />
      <CardContent>
        <FilterAccordion title='Geo-Filters'>
          <GeoFiltersV2
            key='profit-parabola-top-filter'
            data={geoFilterV2Config}
            onChangeDate={(dt) => {
              setSelectedDate(dt);
            }}
            onSelectFilters={setSelectedGeoFilters}
            clearFilter={clearGeoFilterData}
            defaultFilters={defaultFilters}
            apiPath='ProfitParabola'
            showLoader={setLoader}
          />
        </FilterAccordion>
        {/* <FilterAccordion title="Product Filters">
          <ProductFilters
            callback={() => {}}
            change={onSelectProductFilter}
            data={data}
            selectedProductFilters={selectedProductFilters}
            selectedAnchorFilters={selectedAnchorFilters}
            disabledProductFilters={disabledProductFilters}
            disabledAnchorFilters={disabledAnchorFilters}
          />
        </FilterAccordion> */}
        <FilterAccordion title='Product Filters'>
          <ProductFiltersV3
            onChange={(data) => onChangeProductFilters(data)}
            data={data.productFilter}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters.country && selectedGeoFilters.geoLevel2 ? selectedGeoFilters : {}}
            clearFilter={clearGeoFilterData}
            apiURL='filter/products'
            defaultFilters={
              Object.keys(defaultFilters).length > 0
                ? {
                    category: defaultFilters.category,
                    segment: defaultFilters.segment,
                    brand: defaultFilters.brand,
                    subBrand: defaultFilters.subBrand,
                    packSize: defaultFilters.packSize,
                  }
                : null
            }
          />
          <ProductFiltersV3
            onChange={(data) => onChangeAnchorFilter(data)}
            data={data.anchorFilter}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters.country && selectedGeoFilters.geoLevel2 ? selectedGeoFilters : {}}
            clearFilter={clearGeoFilterData}
            apiURL='filter/anchorproducts'
            defaultFilters={
              Object.keys(defaultFilters).length > 0
                ? {
                    anchorCategory: defaultFilters.anchorCategory,
                    anchorSegment: defaultFilters.anchorSegment,
                    anchorBrand: defaultFilters.anchorBrand,
                    anchorSubBrand: defaultFilters.anchorSubBrand,
                    anchorPackSize: defaultFilters.anchorPackSize,
                  }
                : null
            }
          />
        </FilterAccordion>
        <FilterAccordion title='Measure Filters'>
          <MeasureFilters
            data={data.measureFilter}
            selectedMeasureFilters={selectedMeasureFilters}
            callback={(measures) => setMeasureFilter(measures)}
          />
        </FilterAccordion>
        <Grid className='p-l-16'>
          <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={disableApplyFilter()}
            color='primary'
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
