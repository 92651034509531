import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { SecureRoute } from "@okta/okta-react";
import { isAuthenticated } from "../util/helper";
import { Redirect, Route, useHistory } from "react-router-dom";
// import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import UserLogin from "../pages/AssortmentTool/components/UserLogin/UserLogin";

const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  uniqueKey,
  ...rest
}) => {
  const { userLogin } = useSelector((state: any) => state.geoFilter);

  return (
    <>
      {process.env.REACT_APP_SSO_ENABLE === "No" ? (
        <SecureRoute
          {...rest}
          render={(props) => (
            <Layout>
              {isAuthenticated(uniqueKey) ? <Component {...props} /> : <></>}
            </Layout>
          )}
        />
      ) : (
        // <Route {...rest} render={(props) => <Layout>{isAuthenticated(uniqueKey) ? <Component {...props} /> : <Redirect to='/' />}</Layout>} />
        // <Route {...rest} render={(props) => <Layout>{<Component {...props} /> }</Layout>} />
        <Route
          {...rest}
          render={(props) => (
            <Layout>
              {userLogin ? <Component {...props} /> : <UserLogin />}
            </Layout>
          )}
        />
      )}
    </>
  );
};

export default PrivateRoute;
