import { API } from "../../util/helper";

export const fetchProdXrefColumns = async () => {
  try {
    return await API.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}portal/GetProdXrefColumns`
    );
  } catch (e) {
    return e;
  }
};

export const fetchProdXrefTableData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}portal/GetProdXref`,
      data
    );
  } catch (e) {
    return e;
  }
};
