import { Box, Card, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { BoxSlotChartConfig } from './HistoricalRange.config';
import { historicalRangeIcon, piIcon } from '../../../../assets/icons/common';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../../components/Loader/Loader';
import { toggleForPI } from '../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action';
import InfoIcon from '@mui/icons-material/Info';


const HistoricalRange = ({ isDistibutionCategory }) => {
    const [isDistribution, setIsDistribution] = useState(false);
    const [configBoxPlotChart, setConfigBoxPlotChart] = useState({});
    const { loaderHistoricalRange, historicalRangeData } = useSelector((state: any) => state.mixSimulationAllFilter);
    const dispatch = useDispatch();

    const handleSwitch = () => {
        dispatch(toggleForPI({ 'isDistibution': !isDistribution }))
        isDistibutionCategory(!isDistribution);
        setIsDistribution(prev => !prev);
    }

    useEffect(() => {
        if (historicalRangeData.data.length) {
            const category = isDistribution ? "Distribution" : "Price";
            console.log("historicalRangeData", historicalRangeData)
            const configOfBoxPlotChart = BoxSlotChartConfig(historicalRangeData.data, historicalRangeData.categories, category);
            setConfigBoxPlotChart(configOfBoxPlotChart);
        }
        else {
            const configOfBoxPlotChart = BoxSlotChartConfig([], [], '');
            setConfigBoxPlotChart(configOfBoxPlotChart);
        }
    }, [isDistribution, historicalRangeData])

    return (
        <div>
            <Grid style={{ marginTop: "1.5rem" }} container spacing={2}>
                <Grid container direction={'row'} justifyContent={'space-between'} paddingX={2}>
                <Grid item display={'flex'} justifyContent={'center'} alignItems='center'>
                    <Typography color={"white"} variant="h6">
                    Pricing & Distribution Opportunity Assessment
                    </Typography>
                    
                    <Tooltip title="Price Index is a ratio of  price of a specific Pepsico SKU  to the average price of industry within a  segment and pack size group">
                        <IconButton  aria-label="info" size="large" sx={{ color: '#ffff' }}>
                            <InfoIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    </Grid>

                    <Grid item>
                        <Box display="flex" alignItems="center">
                            <Typography style={{ color: "#ffff" }}>PI</Typography>
                            <Switch disabled={historicalRangeData.data.length == 0 ? true : false} checked={isDistribution} onChange={handleSwitch} color="default" />
                            <Typography style={{ color: "#ffff" }}>Distribution</Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Card
                        sx={{
                            borderRadius: "5px",
                            position: "relative",
                            backgroundColor: "#F1F5FE",
                        }}
                    >
                        <Indicator position="absolute" show={loaderHistoricalRange} />
                        <Grid padding={5}>
                            <HighchartsReact highcharts={Highcharts} options={configBoxPlotChart} />
                            <Grid container direction={'row'} justifyContent={'center'}>
                                {/* <img src={legendsIcon} alt="legendIcon" /> */}
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Box
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                    ><img src={piIcon} alt="legendIcon" /><Typography style={{
                                        opacity: '0.6',
                                        fontSize: '14px',
                                        margin:'2px 5px'
                                    }}>{`Current ${isDistribution ? 'Distribution' : 'PI'}`}</Typography></Box>
                                    <Box
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                    ><img src={historicalRangeIcon} alt="legendIcon" /><Typography style={{
                                        opacity: '0.6',
                                        fontSize: '14px',
                                        margin:'2px 5px'
                                    }}>Historical Range</Typography></Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default HistoricalRange