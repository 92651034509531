import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const Value = styled(Typography)(() => ({
  textAlign: "left",
  fontSize: "24px",
  letterSpacing: "0px",
  color: "#555A60",
  fontWeight: 600
}));

const Title = styled(Typography)(() => ({
  textAlign: "left",
  marginTop: "5px",
  color: "#25d4cf",
  fontSize: "20px",
  // textTransform: "uppercase",
  // fontWeight: 600
}));

const Profit = styled(Typography)(() => ({
  color: "green",
  fontSize: "16px",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const Loss = styled(Typography)(() => ({
  color: "red",
  fontSize: "16px",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const Icon = styled("img")(() => ({
  width: "45px",
  height: "45px",
  borderRadius: "5px",
  display: "block",
}));

export { Value, Profit, Loss, Icon, Title };