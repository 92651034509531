import {
  MIX_GEO_LEVEL_TABLE_DATA_REQUEST,
  MIX_GEO_LEVEL_TABLE_DATA_SUCCESS,
  MIX_GEO_LEVEL_TABLE_DATA_FAILURE,
  SET_MIX_GEO_LEVEL,
  RESET_MIX_SIMULATION_GEO_LEVEL_TABLE,
} from "../../actions/constants";

// Initial state
const initialState = {
  geoLevelTableData: null,
  geoLevelTableLoader: false,
  geoLevel: ["region"],
};

const MixGeoLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case MIX_GEO_LEVEL_TABLE_DATA_REQUEST:
      return {
        ...state,
        error: null,
        geoLevelTableLoader: true,
      };

    case MIX_GEO_LEVEL_TABLE_DATA_SUCCESS:
      return {
        ...state,
        geoLevelTableData: action.payload,
        geoLevelTableLoader: false,
      };

    case MIX_GEO_LEVEL_TABLE_DATA_FAILURE:
      return {
        ...state,
        geoLevelTableData:null,
        geoLevelTableLoader: false,
        error: action.payload,
      };
    case SET_MIX_GEO_LEVEL:
      return {
        ...state,
        geoLevel: action.payload,
        geoLevelTableLoader: false,
      };
    case RESET_MIX_SIMULATION_GEO_LEVEL_TABLE:
        return initialState;
    default:
      return state;
  }
};

export default MixGeoLevelReducer;
