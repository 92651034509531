import { API } from '../../util/helper';

export const getMultipleApiProducts = async (data, type) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/multipleapiproducts?type=${type}`, data);
  } catch (e) {
    return e;
  }
};

export const getProductFilters = async (data) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/products`, data);
  } catch (e) {
    return e;
  }
};

export const getDataForPriceTracking = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/sellout/pricetracking`, payload);
  } catch (e) {
    return e;
  }
};
export const getDataForSOPAndSRP = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/sellout/sopvssrp`, payload);
  } catch (e) {
    return e;
  }
};

export const getValueTrendData =async (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/sellout/valueTrend`,
    payload
  );
};

export const getProductDetails = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/sellout/detail`, payload);
  } catch (e) {
    return e;
  }
};

export const getSRPVsSOPGraphData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/sellout/brandladder`, payload);
  } catch (e) {
    return e;
  }
};

