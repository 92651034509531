import React from 'react';
import { Transformer, Image } from 'react-konva';
import { loadImageFromBase64 } from '../../../util/helper';

const DraggableImage: React.FC<{ shapeProps?, isSelected, onSelect?, onChange, data }> = ({ shapeProps, isSelected, onSelect, onChange, data }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef<any>();
    const [imgData, setImgData] = React.useState({
      width: 0,
      height: 0,
      image: null,
      x: 0,
      y: 0
    });

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    React.useEffect(() => {
      if(data){
        loadImageFromBase64(data).then((res: Object)=>{
          setImgData({...data, ...res});
        })
      }
    }, [data])

    return (
        <React.Fragment>
          <Image
            onClick={onSelect}
            onTap={onSelect}
            width={imgData.width}
            height={imgData.height}
            image={imgData.image}
            ref={shapeRef}
            x={imgData.x}
            y={imgData.y}
            // {...shapeProps}
            draggable
            onDragEnd={(e) => {
              onChange({
                ...shapeProps,
                x: e.target.x(),
                y: e.target.y(),
                e
              });
            }}
            onTransformEnd={(e) => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node:any = shapeRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
    
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              onChange({
                ...shapeProps,
                x: node.x(),
                y: node.y(),
                // set minimal value
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              });
            }}
          />
          {isSelected && (
            <Transformer
              ref={trRef}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </React.Fragment>
      );

};

export default DraggableImage;