const measuresFilter = {
  viewShareby: {
    title: "View Share by",
    options: [
      { id: "1", label: "Sales", value: "valueSales" },
      { id: "2", label: "Volume", value: "volumeSales" },
      { id: "3", label: "Units", value: "unitSales" },
    ],
    defaultOption: { id: "1", value: "valueSales" },
    showRadioButton: true,
  },
};

const specificFilter = {
  viewSharedByForPiano: {
    title: "View Shared by",
    options: [
      { id: "1", label: "Absolute Value", value: "AbsoluteValue" },
      { id: "2", label: "% Vertical", value: "VerticalPercentage" },
      { id: "3", label: "% Horizontal", value: "HorizontalPercentage" },
    ],
    defaultOption: { id: "1", value: "AbsoluteValue" },
    showRadioButton: true,
  },
  currentVsLast: {
    title: "Current Vs Last",
    options: [
      { id: "1", label: "Current Period", value: "CurrentPeriod" },
      { id: "2", label: "Last Period", value: "LastPeriod" },
      {
        id: "3",
        label: "Current Period vs. Last Period",
        value: "CurrentVsLast",
      },
    ],
    defaultOption: { id: "1", value: "CurrentPeriod" },
    showRadioButton: true,
  },
};

const priceRanges = [
  {
    label: "3",
    value: "3",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "10",
    value: "10",
  },
];

const productFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
    defaultSelectAll: false,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
    defaultSelectAll: false,
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    last: true,
  },
};
const competitorFilter = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    defaultSelectAll: false,
    children: "category",
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
    defaultSelectAll: false,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
    defaultSelectAll: false,
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    last: true,
  },
};

const pepsiProducts = [
  {
    productName: "Ruffles 4X CHICO",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
      {
        date: "2022-08-11T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-08T07:02:46.073Z",
        value: 20,
      },
    ],
  },
];

const competitorProducts = [
  {
    productName: "Chips 4X CHICO",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
    ],
  },
];

const pepsiPricePiano = [
  {
    productName: "Ruffles 4X CHICO",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
      {
        date: "2022-08-11T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-08T07:02:46.073Z",
        value: 20,
      },
    ],
  },
];

const competitorPricePiano = [
  {
    productName: "Chips 4X CHICO",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
    ],
  },
];

const pricePionoVisData = [
  {
    productName: "Pack Piano",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
    ],
  },
  {
    productName: "Unit Price Piano",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
    ],
  },
  {
    productName: "Index Piano",
    productInfo: "",
    priceRangeData: [
      {
        date: "2022-08-03T07:02:46.073Z",
        value: 10,
      },
      {
        date: "2022-08-10T07:02:46.073Z",
        value: 100,
      },
      {
        date: "2022-08-20T07:02:46.073Z",
        value: 20,
      },
    ],
  },
];

const priceRangeTableHeading = [
  { title: "Product" },
  { title: "" },
  { title: "Jan" },
  { title: "Feb" },
  { title: "Mar" },
  { title: "Apr" },
  { title: "May" },
  { title: "Jun" },
  { title: "Jul" },
  { title: "Aug" },
  { title: "Sep" },
  { title: "Oct" },
  { title: "Nov" },
  { title: "Dec" },
];
const priceRangeVisTableHeading = [
  { title: "Product" },
  { title: "" },
  { title: "Jan" },
  { title: "Feb" },
];

const pricePianoTableResponseData = [
  {
    productName: "Pepsico",
    productInfo: " ",
    priceRanges: [
      {
        date: "2021-01-01T00:00:00",
        "<$8": 9.13979646,
        "$11-$12": 12.338725221,
        ">$12": 11.80315025,
        Total: 23.777,
      },
      {
        date: "2021-02-01T00:00:00",
        "<$8": 9.13011176,
        "$11-$12": 12.325650876,
        ">$12": 11.91341655,
        Total: 23.777,
      },
    ],
  },
  {
    productName: "Competitor",
    productInfo: " ",
    priceRanges: [
      {
        date: "2021-01-01T00:00:00",
        "<$8": 9.13979646,
        "$11-$12": 12.338725221,
        ">$12": 11.80315025,
        Total: 23.777,
      },
      {
        date: "2021-02-01T00:00:00",
        "<$8": 9.13011176,
        "$11-$12": 12.325650876,
        ">$12": 11.91341655,
        Total: 23.777,
      },
      {
        date: "2021-03-01T00:00:00",
        "<$8": 9.218832,
        "$11-$12": 12.4454232,
        ">$12": 11.96562814,
        Total: 23.777,
      },
      {
        date: "2021-04-01T00:00:00",
        "<$8": 9.48541179,
        "$11-$12": 12.8053059165,
        ">$12": 12.18423573,
        Total: 23.777,
      },
      {
        date: "2021-05-01T00:00:00",
        "<$8": 9.57286573,
        "$11-$12": 12.9233687355,
        ">$12": 12.32024075,
        Total: 23.777,
      },
      {
        date: "2021-06-01T00:00:00",
        "<$8": 9.83201685,
        "$11-$12": 13.2732227475,
        ">$12": 12.46889969,
        Total: 23.777,
      },
      {
        date: "2021-07-01T00:00:00",
        "<$8": 9.93094647,
        "$11-$12": 13.4067777345,
        ">$12": 12.65551822,
        Total: 23.777,
      },
      {
        date: "2021-08-01T00:00:00",
        "<$8": 9.8763953,
        "$11-$12": 13.333133655,
        ">$12": 12.73222965,
        Total: 23.777,
      },
      {
        date: "2021-09-01T00:00:00",
        "<$8": 9.87380062,
        "$11-$12": 13.329630837,
        ">$12": 12.78552299,
        Total: 23.777,
      },
      {
        date: "2021-10-01T00:00:00",
        "<$8": 9.89374532,
        "$11-$12": 13.356556182,
        ">$12": 12.81492173,
        Total: 23.777,
      },
      {
        date: "2021-11-01T00:00:00",
        "<$8": 9.9730777,
        "$11-$12": 13.463654895,
        ">$12": 12.8489899,
        Total: 23.777,
      },
      {
        date: "2021-12-01T00:00:00",
        "<$8": 9.98284034,
        "$11-$12": 13.476834459,
        ">$12": 12.92782083,
        Total: 23.777,
      },
    ],
  },
];

const pricePianoTableSkeletonData = [
  ["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
  ["", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
  ["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
];

const pricePianoVisSkeletonData = [
  ["", 10, 1, 4],
  ["", 10, 1, 4],
  ["", 10, 1, 4],
  ["", 10, 1, 4],
];

const pianoTableResponsePacksize = [
  {
    productName: "Pepsi",
    productInfo: "Pepsi",
    priceRanges: [
      {
        date: "January 2021",
        "<$8": 9.13979646,
        "$11-12": 12.338725221,
      },
      {
        date: "February 2021",
        "$11-12": 20,
        "<$8": 9.13011176,
      },
    ],
  },
];

const pianoTableResponsePricerange = [
  {
    priceRange: "1-2",
    years: "2022",
    pespsico: 34334.33,
    compititor: 212121.121,
  },
];

const pianoTableResponseIndexrange = [
  {
    indexRange: "<80",
    years: "2022",
    pespsico: 34334.33,
    compititor: 212121.121,
  },
];

const seriesColor = ["#27bce9", "#00b33e", "#82d12e", "#ff8c26"];

const visTwoColors = ["#d41165", "#598ee3"];

const reviseResponse = [
  {
    packPiano: [
      {
        tag: "04X-CHICO 2021",
        label: "04X-CHICO",
        year: 2021,
        pepsi: 5,
        competator: 15,
      },
      {
        tag: "11X-COMPARTE 2021",
        label: "11X-COMPARTE",
        year: 2021,
        pepsi: 31,
        competator: 25,
      },
      {
        tag: "12X-FAMILIAR 2021",
        label: "12X-FAMILIAR",
        year: 2021,
        pepsi: 9,
        competator: 31,
      },
      {
        tag: "07X-LARGE 2022",
        label: "07X-LARGE",
        year: 2022,
        pepsi: 19,
        competator: 33,
      },
    ],
  },
  {
    unitPricePiano: [
      {
        tag: "04X-CHICO 2021",
        label: "04X-CHICO",
        year: 2021,
        pepsi: 5,
        competator: 15,
      },
      {
        tag: "11X-COMPARTE 2021",
        label: "11X-COMPARTE",
        year: 2021,
        pepsi: 31,
        competator: 25,
      },
      {
        tag: "12X-FAMILIAR 2021",
        label: "12X-FAMILIAR",
        year: 2021,
        pepsi: 9,
        competator: 31,
      },
      {
        tag: "07X-LARGE 2022",
        label: "07X-LARGE",
        year: 2022,
        pepsi: 45,
        competator: 7,
      },
    ],
  },
  {
    indexPiano: [
      {
        tag: "04X-CHICO 2021",
        label: "04X-CHICO",
        year: 2021,
        pepsi: 5,
        competator: 15,
      },
      {
        tag: "11X-COMPARTE 2021",
        label: "11X-COMPARTE",
        year: 2021,
        pepsi: 31,
        competator: 25,
      },
      {
        tag: "12X-FAMILIAR 2021",
        label: "12X-FAMILIAR",
        year: 2021,
        pepsi: 9,
        competator: 31,
      },
      {
        tag: "07X-LARGE 2022",
        label: "07X-LARGE",
        year: 2022,
        pepsi: 30,
        competator: 60,
      },
    ],
  },
];
const pianoPackHeading = [
  {
    heading: [
      { title: "Pack Size", value: "label" },
      { title: "Year", value: "year" },
      { title: "PepsiCo", value: "pepsi", formatNumber: true },
      { title: "Competitor", value: "competator", formatNumber: true },
    ],
  },
  {
    heading: [
      { title: "Price Range", value: "label" },
      { title: "Year", value: "year" },
      { title: "PepsiCo", value: "pepsi", formatNumber: true },
      { title: "Competitor", value: "competator", formatNumber: true },
    ],
  },
  {
    heading: [
      { title: "Index Range", value: "label" },
      { title: "Year", value: "year" },
      { title: "PepsiCo", value: "pepsi", formatNumber: true },
      { title: "Competitor", value: "competator", formatNumber: true },
    ],
  },
];

const firstVisualApiData = {
  pepsi: {
    productName: "Pepsi",
    productInfo: "Pepsi",
    priceRanges: [
      {
        date: "January 2021",
        "<$10": 0.8449343132138927,
        "$10-$20": 21.878843968127235,
        "$20-$30": 7.375083089287904,
        "$30-$40": 16.228164810914972,
        "$40-$50": 5.482355840041101,
        "$50-$60": 1.7691276250784904,
        total: 53.57850964666359,
      },
      {
        date: "February 2021",
        "<$10": 1.3452147062636468,
        "$10-$20": 20.145323076483482,
        "$20-$30": 7.912217383199537,
        "$30-$40": 17.402631711411367,
        "$40-$50": 3.4628559575596656,
        "$50-$60": 1.699701502438474,
        "$60-$70": 1.50088197249657,
        total: 53.468826309852744,
      },
      {
        date: "March 2021",
        "<$10": 1.3891412595980555,
        "$10-$20": 20.048155697084795,
        "$20-$30": 7.255726586569678,
        "$30-$40": 17.074838115909323,
        "$40-$50": 1.7282174690459957,
        "$50-$60": 1.7656117740029884,
        "$60-$70": 2.0985231283282744,
        total: 51.360214030539105,
      },
      {
        date: "April 2021",
        "<$10": 0.9783377600395646,
        "$10-$20": 20.249523456237778,
        "$20-$30": 7.876694398884971,
        "$30-$40": 17.81023025656983,
        "$40-$50": 3.398989265466183,
        "$50-$60": 1.6416798421099086,
        "$60-$70": 2.0013181883444324,
        total: 53.95677316765267,
      },
      {
        date: "May 2021",
        "<$10": 0.907491431043196,
        "$10-$20": 20.726162837436274,
        "$20-$30": 7.605654037537009,
        "$30-$40": 16.67592846984785,
        "$40-$50": 4.037817962514452,
        "$50-$60": 3.0028661680535818,
        "$60-$70": 2.0612718203362173,
        total: 55.01719272676858,
      },
      {
        date: "June 2021",
        "<$10": 0.8932755266736379,
        "$10-$20": 21.662691472963942,
        "$20-$30": 4.930293701959345,
        "$30-$40": 18.084557145964844,
        "$40-$50": 3.038945583106304,
        "$50-$60": 4.583099189605959,
        "$60-$70": 2.0163978126442927,
        total: 55.20926043291833,
      },
      {
        date: "July 2021",
        "<$10": 0.8827037601563417,
        "$10-$20": 22.24484387033651,
        "$20-$30": 6.470150966228487,
        "$30-$40": 17.15006669828867,
        "$40-$50": 2.6181494742793325,
        "$50-$60": 2.4288226001384956,
        "$60-$70": 0.39638098331016935,
        "$70-$80": 1.7949857556383266,
        total: 53.98610410837633,
      },
      {
        date: "August 2021",
        "<$10": 1.08600775158801,
        "$10-$20": 21.222636113403855,
        "$20-$30": 6.44428745418723,
        "$30-$40": 18.28898539228065,
        "$40-$50": 1.62493758063033,
        "$50-$60": 1.7433656152535393,
        "$70-$80": 0.5470783803131991,
        total: 50.95729828765681,
      },
      {
        date: "September 2021",
        "<$10": 0.6953740542845723,
        "$10-$20": 19.9931157833011,
        "$20-$30": 4.181376279736039,
        "$30-$40": 17.7479437293137,
        "$40-$50": 3.723687590812899,
        "$50-$60": 2.5871051839512624,
        "$60-$70": 1.4267570265602731,
        ">= $100": 2.0948579259877644,
        total: 52.45021757394761,
      },
      {
        date: "October 2021",
        "<$10": 0.5446986628879292,
        "$10-$20": 19.3961237371932,
        "$20-$30": 4.2117607555767,
        "$30-$40": 17.50664201700623,
        "$40-$50": 3.599105568552473,
        "$50-$60": 4.030545570845068,
        "$60-$70": 0.3744337895220627,
        ">= $100": 2.661886512218322,
        total: 52.325196613801985,
      },
      {
        date: "November 2021",
        "<$10": 0.549488587321395,
        "$10-$20": 19.698190535904182,
        "$20-$30": 4.438762267312755,
        "$30-$40": 18.04180763536729,
        "$40-$50": 2.6859469683599566,
        "$50-$60": 3.3657886641620256,
        ">= $100": 2.7621738369424813,
        total: 51.54215849537008,
      },
      {
        date: "December 2021",
        "<$10": 0.6159615159764961,
        "$10-$20": 19.021200877142096,
        "$20-$30": 4.179390111021453,
        "$30-$40": 15.192414743497881,
        "$40-$50": 5.757744713631951,
        "$50-$60": 1.6624445084447852,
        "$60-$70": 1.3169763855872676,
        "$80-$90": 3.572901598477213,
        ">= $100": 0.5330541619950775,
        total: 51.85208861577422,
      },
    ],
  },
  competitors: {
    productName: "Competitor",
    productInfo: "Competitor",
    priceRanges: [
      {
        date: "January 2021",
        "<$10": 2.878022552820952,
        "$10-$20": 25.360705914033204,
        "$20-$30": 1.624639001962547,
        "$30-$40": 16.558122884519705,
        total: 46.42149035333641,
      },
      {
        date: "February 2021",
        "<$10": 2.736479338630951,
        "$10-$20": 25.11207160517671,
        "$20-$30": 2.5014216234942572,
        "$30-$40": 16.18120112284534,
        total: 46.531173690147256,
      },
      {
        date: "March 2021",
        "<$10": 2.319824318829491,
        "$10-$20": 27.974529742617154,
        "$20-$30": 2.9938901336052948,
        "$30-$40": 15.35154177440895,
        total: 48.639785969460895,
      },
      {
        date: "April 2021",
        "<$10": 1.4548859714859441,
        "$10-$20": 26.918792232846567,
        "$20-$30": 3.4222973564599446,
        "$30-$40": 13.206066114551675,
        "$40-$50": 1.0411851570032011,
        total: 46.04322683234733,
      },
      {
        date: "May 2021",
        "<$10": 2.2651146241275435,
        "$10-$20": 25.29170144425571,
        "$20-$30": 4.046557388147697,
        "$30-$40": 13.051551624614577,
        "$40-$50": 0.3278821920858904,
        total: 44.98280727323142,
      },
      {
        date: "June 2021",
        "<$10": 2.4644345108802823,
        "$10-$20": 24.54267030110386,
        "$20-$30": 3.175413337049672,
        "$30-$40": 14.608221418047858,
        total: 44.79073956708167,
      },
      {
        date: "July 2021",
        "<$10": 2.0504723600959487,
        "$10-$20": 24.13439092547775,
        "$20-$30": 2.8176790306202366,
        "$30-$40": 15.944387592101995,
        "$40-$50": 1.0669659833277374,
        total: 46.01389589162367,
      },
      {
        date: "August 2021",
        "<$10": 2.3094645771559863,
        "$10-$20": 24.010523609156234,
        "$20-$30": 3.908621795229094,
        "$30-$40": 18.488900280020335,
        "$40-$50": 0.3251914507815356,
        total: 49.04270171234319,
      },
      {
        date: "September 2021",
        "<$10": 2.1717891745216793,
        "$10-$20": 22.160251621794732,
        "$20-$30": 3.8099192052314956,
        "$30-$40": 17.608308002968585,
        "$40-$50": 1.7995144215358954,
        total: 47.54978242605239,
      },
      {
        date: "October 2021",
        "<$10": 1.7919934434545757,
        "$10-$20": 22.361230310948613,
        "$20-$30": 3.6280127424759807,
        "$30-$40": 16.590866091121285,
        "$40-$50": 3.3027007981975607,
        total: 47.674803386198015,
      },
      {
        date: "November 2021",
        "<$10": 2.0982553616026465,
        "$10-$20": 21.881488200317186,
        "$20-$30": 5.005777652877213,
        "$30-$40": 15.045175395695752,
        "$40-$50": 4.427144894137117,
        total: 48.45784150462992,
      },
      {
        date: "December 2021",
        "<$10": 1.5632513144279296,
        "$10-$20": 21.52155672151938,
        "$20-$30": 5.1661218200873,
        "$30-$40": 12.262879023763794,
        "$40-$50": 6.223141220724823,
        "$60-$70": 1.410961283702548,
        total: 48.14791138422578,
      },
    ],
  },
};

const secondVisApiData = {
  packPiano: [
    {
      tag: "01X-MINI 2021",
      label: "01X-MINI",
      year: 2021,
      pepsi: 1.14,
      competator: 21.44,
    },
    {
      tag: "02X-JR LINE 2021",
      label: "02X-JR LINE",
      year: 2021,
      pepsi: 0.12,
      competator: 189.55,
    },
    {
      tag: "03X-EPP SIZE 2021",
      label: "03X-EPP SIZE",
      year: 2021,
      pepsi: 13.64,
      competator: 0,
    },
    {
      tag: "04X-CHICO 2021",
      label: "04X-CHICO",
      year: 2021,
      pepsi: 8985.15,
      competator: 3359.39,
    },
    {
      tag: "05P-MULTIPK 2021",
      label: "05P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "06X-BOTANOTA 2021",
      label: "06X-BOTANOTA",
      year: 2021,
      pepsi: 3.65,
      competator: 0,
    },
    {
      tag: "07X-MAX 2021",
      label: "07X-MAX",
      year: 2021,
      pepsi: 0.27,
      competator: 584.9,
    },
    {
      tag: "08X-REC 2021",
      label: "08X-REC",
      year: 2021,
      pepsi: 1.38,
      competator: 0,
    },
    {
      tag: "09X-JUMBO 2021",
      label: "09X-JUMBO",
      year: 2021,
      pepsi: 912.17,
      competator: 0,
    },
    {
      tag: "10P-MULTIPK 2021",
      label: "10P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "10X-AMIGOTE 2021",
      label: "10X-AMIGOTE",
      year: 2021,
      pepsi: 0.16,
      competator: 0,
    },
    {
      tag: "11X-COMPARTE 2021",
      label: "11X-COMPARTE",
      year: 2021,
      pepsi: 399.88,
      competator: 519.76,
    },
    {
      tag: "12P-MULTIPK 2021",
      label: "12P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "12X-FAMILIAR 2021",
      label: "12X-FAMILIAR",
      year: 2021,
      pepsi: 925.69,
      competator: 1334.93,
    },
    {
      tag: "13X-LLEVA2 2021",
      label: "13X-LLEVA2",
      year: 2021,
      pepsi: 26.9,
      competator: 0,
    },
    {
      tag: "14X-FIESTA 2021",
      label: "14X-FIESTA",
      year: 2021,
      pepsi: 20.88,
      competator: 0.04,
    },
    {
      tag: "15X-MEGA 2021",
      label: "15X-MEGA",
      year: 2021,
      pepsi: 3.65,
      competator: 0.11,
    },
    {
      tag: "16X-SUPER SIZE 2021",
      label: "16X-SUPER SIZE",
      year: 2021,
      pepsi: 0.79,
      competator: 0.65,
    },
    {
      tag: "18X-BOTE GRANDE 2021",
      label: "18X-BOTE GRANDE",
      year: 2021,
      pepsi: 13.89,
      competator: 1.52,
    },
    {
      tag: "19X-BOTE CHICO 2021",
      label: "19X-BOTE CHICO",
      year: 2021,
      pepsi: 14.81,
      competator: 203.43,
    },
    {
      tag: "24X-CHICO 2021",
      label: "24X-CHICO",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "24X-ESPEJO 2021",
      label: "24X-ESPEJO",
      year: 2021,
      pepsi: 955.28,
      competator: 0,
    },
    {
      tag: "25P-MULTIPK 2021",
      label: "25P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "25X-POPULITO 2021",
      label: "25X-POPULITO",
      year: 2021,
      pepsi: 393.91,
      competator: 0,
    },
    {
      tag: "27P-MULTIPK 2021",
      label: "27P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "30P-GET TOGETHER MIX 2021",
      label: "30P-GET TOGETHER MIX",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "30P-MULTIPK 2021",
      label: "30P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "99X-OTHER 2021",
      label: "99X-OTHER",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "02P-MULTIPK 2021",
      label: "02P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "08P-MULTIPK 2021",
      label: "08P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "17X-BOTE MEDIANO 2021",
      label: "17X-BOTE MEDIANO",
      year: 2021,
      pepsi: 0,
      competator: 99.56,
    },
    {
      tag: "20P-MULTIPK 2021",
      label: "20P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "24P-MULTIPK 2021",
      label: "24P-MULTIPK",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
  ],
  unitPricePiano: [
    {
      tag: "<10 2021",
      label: "<10",
      year: 2021,
      pepsi: 17.67,
      competator: 267.69,
    },
    {
      tag: "10-20 2021",
      label: "10-20",
      year: 2021,
      pepsi: 11264.68,
      competator: 4656.46,
    },
    {
      tag: "20-30 2021",
      label: "20-30",
      year: 2021,
      pepsi: 399.62,
      competator: 282.95,
    },
    {
      tag: "30-40 2021",
      label: "30-40",
      year: 2021,
      pepsi: 832.13,
      competator: 950.15,
    },
    {
      tag: "40-50 2021",
      label: "40-50",
      year: 2021,
      pepsi: 136.79,
      competator: 157.45,
    },
    {
      tag: "50-60 2021",
      label: "50-60",
      year: 2021,
      pepsi: 16.1,
      competator: 0.02,
    },
    {
      tag: "60-70 2021",
      label: "60-70",
      year: 2021,
      pepsi: 6.97,
      competator: 0.14,
    },
    {
      tag: "70-80 2021",
      label: "70-80",
      year: 2021,
      pepsi: 0.28,
      competator: 0.04,
    },
    {
      tag: "80-90 2021",
      label: "80-90",
      year: 2021,
      pepsi: 0.27,
      competator: 0,
    },
    {
      tag: "90-100 2021",
      label: "90-100",
      year: 2021,
      pepsi: 0.29,
      competator: 0,
    },
    {
      tag: ">100 2021",
      label: ">100",
      year: 2021,
      pepsi: 0.38,
      competator: 0.69,
    },
  ],
  indexPiano: [
    {
      tag: "<80 2021",
      label: "<80",
      year: 2021,
      pepsi: 12673.36,
      competator: 6315.28,
    },
    {
      tag: "80-95 2021",
      label: "80-95",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "95-105 2021",
      label: "95-105",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: "105-120 2021",
      label: "105-120",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
    {
      tag: ">120 2021",
      label: ">120",
      year: 2021,
      pepsi: 0,
      competator: 0,
    },
  ],
};

const vis2GraphHeading = {
  packPiano: "Pack Piano",
  unitPricePiano: "Unit Price Piano",
  indexPiano: "Index Piano",
};

export const periodView = {
  title: "Period View",
  options: [
    { label: "NA", value: "na" },
    { label: "Full Year", value: "FullYear" },
    { label: "Year to Date", value: "YearToDate" },
    { id: "3", label: "Rolling Year", value: "RollingYear" },
  ],
  placeholder: "Select",
};

const exportObject = {
  productFilter,
  competitorFilter,
  measuresFilter,
  priceRanges,
  priceRangeTableHeading,
  pepsiProducts,
  competitorProducts,
  pricePianoTableResponseData,
  specificFilter,
  pepsiPricePiano,
  competitorPricePiano,
  pricePionoVisData,
  pianoTableResponsePacksize,
  pianoTableResponsePricerange,
  pianoTableResponseIndexrange,
  pricePianoTableSkeletonData,
  seriesColor,
  visTwoColors,
  reviseResponse,
  pianoPackHeading,
  firstVisualApiData,
  pricePianoVisSkeletonData,
  priceRangeVisTableHeading,
  secondVisApiData,
  vis2GraphHeading,
};
export default exportObject;
