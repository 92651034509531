import { formatNumber } from "../../../../../../util/helper";

export const chartConfig = {
  chart: {
    type: "column",
    height: 650,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
    align: "left",
  },
  xAxis: {
    categories: ["Benchmark", "Simulated", "Variation Percentage"],
  },
  yAxis: {
    title: {
      enabled: false,
      text: "Count trophies",
    },
    stackLabels: {
      enabled: true,
      formatter: function () {
        var sum = 0;
        var series = this.axis.series;
        for (var i in series) {
          if (series[i].visible && series[i].options.stacking == "normal") {
            sum += series[i].yData[this.x]? series[i].yData[this.x] : 0;
          }
        }
        return this.total > 0? formatNumber(sum, 3) : ''
      },
    },
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    backgroundColor: "white",
    borderColor: "#CCC",
    borderWidth: 1,
    shadow: false,
    symbolWidth: 16,
    symbolRadius: 0,
    squareSymbol: false,
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      pointWidth: 120,
    },
  },
  series: [],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
