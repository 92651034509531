import React, { useEffect, useState } from "react";
import {
  IconButton,
  CardContent,
  Card,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import { ViewEditButton } from "../../AssortmentTool.Styled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { styleForScenarioDataGrid } from "./ScenarioLibrary.styles";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import {
  fetchScenarioData,
  loaderForScenario,
} from "../../../../store/actions/ScenarioLibrary/scenario.action";
import Indicator from "../../../../components/Loader/Loader";
import DataGridTables from "../../../../components/ExecutionDataTable/DataGridTables";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import SearchScenarioenario from "../SearchScenario/SearchScenario";
import { useHistory } from "react-router-dom";
import { ASSORTMENT_TOOL } from "../../../../router/CONSTANTS";
import { toast } from "../../../../store/actions/toast.action";
import {
  resetAssortmentFilterState,
  setAssortmentScenarioName,
  setPenetrationViewMode,
} from "../../../../store/actions//AssortmentTool/assortmentFilters.action";
import { setViewMode } from "../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import { API_URL } from "../../../../store/actions/constants";

const ScenarioLibrary: React.FC = () => {
  const { scenarioLibraryData, loading } = useSelector(
    (state: any) => state.ScenarioLibraryReducer
  );
  const [scenarios, setScenarios] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [uid, setUid] = useState("");
  const [deleteText, setDeleteText] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const handleInputChange = (value) => {
    const lowerCaseValue = value.toLowerCase();
    setScenarios((prev) => {
      const filteredData = scenarioLibraryData
        .filter((ele) =>
          ele?.scenarioName.toLowerCase().includes(lowerCaseValue)
        )
        .map((entry) => ({
          ...entry,
          status: mapStatus(entry.status),
        }));
      return filteredData;
    });
  };

  const handleClose = (isDelete) => {
    setOpen(false);

    if (isDelete) {
      const dispatchActions: dispatchState = {
        loaderStateFetch: loaderForScenario(true),
      };
      dispatch(
        fetchApi(
          null,
          `${API_URL.ASSORTMENT.DELETE_SCENARIO}?Uid=${uid}`,
          dispatchActions,
          "delete"
        )
      )
        .then((res) => {
          dispatch(
            toast(
              `${res.data.scenarioName} deleted Successfully!`,
              true,
              4000,
              "success"
            )
          );
          getAllScenarios();
        })
        .catch((err) => {
          dispatch(toast("Something went wrong!", true, 2000, "error"));
        });
    }
  };

  function handleDelete(params) {
    setUid(params.id);
    setDeleteText(params.row.scenarioName);
    return setOpen(true);
  }

  function handleEdit(id) {
    dispatch(resetAssortmentFilterState());
    const { uid, scenarioName } = id.row;
    dispatch(setAssortmentScenarioName(scenarioName));
    dispatch(setPenetrationViewMode(false));
    dispatch(setViewMode(false));
    history.push(ASSORTMENT_TOOL, { id: uid, scenarioName });
  }

  function getAllScenarios() {
    const dispatchActions: dispatchState = {
      loaderStateFetch: loaderForScenario(true),
    };
    dispatch(
      fetchApi(null, API_URL.ASSORTMENT.SCENARIO_LIST, dispatchActions, "GET")
    )
      .then((res) => {
        dispatch(loaderForScenario(false));
        dispatch(fetchScenarioData(res.data));
      })
      .catch((err) => {
        dispatch(loaderForScenario(false));
        dispatch(toast("Something went wrong!", true, 2000, "error"));
      });
  }

  useEffect(() => {
    getAllScenarios();
  }, []);

  function mapStatus(status) {
    switch (status) {
      case 0:
        return "Failed";
      case 1:
        return "Completed";
      case 2:
        return "In Progress";
      default:
        return "Failed";
    }
  }

  function mapSenarioStatus(data) {
    return data.map((entry) => ({
      ...entry,
      status: mapStatus(entry.status),
    }));
  }

  useEffect(() => {
    setScenarios(mapSenarioStatus(scenarioLibraryData));
  }, [scenarioLibraryData]);

  const columnsInScenario: any = [
    {
      field: "scenarioName",
      headerAlign: "center",
      headerName: "Scenario Name",
      flex: 1,
      align: "center",
      type: "string",
      cellClassName: "scenarios-cell",
    },
    {
      field: "createdOn",
      headerName: "Created on",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "dateTime",
    },
    {
      field: "dateTimePeriod",
      headerName: "Time Period",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Actions",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Grid
            container
            justifyContent={"center"}
            display={"flex"}
            style={{ gap: "0.75rem" }}
          >
            <Grid item>
              <ViewEditButton
                disabled={params.row.status !== "Completed"}
                onClick={() => handleEdit(params)}
              >
                {" "}
                View/Edit{" "}
              </ViewEditButton>
            </Grid>
            <Grid>
              <Divider
                color="#dfdfdf"
                sx={{ height: 5, m: 1 }}
                orientation="vertical"
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => handleDelete(params)}
                style={{ color: "#005ea6" }}
                aria-label="delete"
                size="small"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ backgroundColor: "#f1f5fe", position: "relative" }}>
        <Indicator position="absolute" show={loading} />
        <CardContent>
          <Typography variant="h6" sx={{ color: "black" }}>
            Assortment Prioritization
          </Typography>
          <SearchScenarioenario
            inputValue={handleInputChange}
            onRefresh={getAllScenarios}
            isAssortment={true}
          />
          <DataGridTables
            isScenarioLibrary={true}
            filterableColumns={[]}
            sortableColumns = {[]}
            rows={scenarios}
            columns={columnsInScenario}
            disableColumnMenu={true}
            style={styleForScenarioDataGrid}
            autoPageSize={true}
            rowCount={scenarios.length}
            paginationMode="client"
            callback={(row) => row.uid}
          ></DataGridTables>
        </CardContent>
        <ConfirmDialog
          deleteText={deleteText}
          open={open}
          handleClose={handleClose}
        />
      </Card>
    </>
  );
};

export default ScenarioLibrary;