import { formatNumber } from '../../../../../util/helper';

const chartConfig: any = {
  chart: {
    type: 'waterfall',
    backgroundColor: '#FAFAFA',
  },
  credits: { enabled: false },
  title: {
    text: '',
  },

  tooltip: {
    enable: true,
  },
  legend: {
    enabled: false,
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 20,
    symbolWidth: 20,
    symbolRadius: 0,
    itemStyle: {
      fontSize: '16px',
    },
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      tooltip: {
        pointFormatter: function (): string {
          return formatNumber(this.y.toFixed(2));
        },
      },
    },
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    title: {
      enabled: false,
    },
  },

  series: [],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export { chartConfig };
