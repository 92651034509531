import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { NoData } from '../../../../styles/Common.Styled';
import SpecificFilters from '../SpecificFilters';
import mockData from '../../../../mocks/priceRangePianoMock';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { messages } from '../../../../util/config';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../../../components/Dashboard';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { chartConfig } from './chartConfig';
import _ from 'lodash';
import { formatNumber, formatNumberToOne, separator } from '../../../../util/helper';
import { fetchPricePianoVisTwoData } from '../../service';
import { priceSpecificLoader } from '../../store/PriceRangePiano.action';
import HighchartContainer from '../../../../components/HighchartContainer';

const PackPriceIndexPiano: React.FC<{
  filters;
  callback;
  defaultFilters?;
}> = ({ filters, callback, defaultFilters }) => {
  const dispatch = useDispatch();
  const visualTitles = [
    { title: 'Pack Piano', chartRef: useRef(null) },
    { title: 'Unit Price Piano', chartRef: useRef(null) },
    { title: 'Index Piano', chartRef: useRef(null) },
  ];
  const [packPriceIndexPianoData, setPackPriceIndexPianoData] = React.useState<any>([]);
  const [specificFilter, setSpecificFilter] = React.useState({});
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const [skeleton, setSkeleton] = React.useState(true);
  const [defaultApiCalled, setDefaultApiCalled] = React.useState(false);

  const getPackPriceIndexPianoData = async (payload) => {
    const requestPayload = { ...payload };
    if (requestPayload.periodView[0] ==='na') {
      requestPayload.periodView = 'null';
    }
    else{
      requestPayload.periodView = requestPayload.periodView[0].toString()
    }
    dispatch(priceSpecificLoader(true));
    setSkeleton(true);
    await fetchPricePianoVisTwoData(requestPayload)
      .then((response) => {
        if (response && response.data) {
          const result = [
            formatData(response.data.packPiano, 0),
            formatData(response.data.unitPricePiano, 1),
            formatData(response.data.indexPiano, 2),
          ];
          setPackPriceIndexPianoData(result);
          setSkeleton(false);
          dispatch(priceSpecificLoader(false));
        }
        dispatch(priceSpecificLoader(false));
      })
      .catch((e) => {
        setSkeleton(false);
        dispatch(priceSpecificLoader(false));
      });
  };

  const formatData = (data, i) => {
    const series1 = [],
      series2 = [],
      categories = [];
    data.map((item) => {
      if (item) {
        categories.push(item.tag);
        series1.push(item.pepsi);
        series2.push(item.competator);
      }
    });
    return {
      categories,
      series1,
      series2,
      tableData: formatTableData(data, i),
    };
  };

  useEffect(() => {
    if (packPriceIndexPianoData && packPriceIndexPianoData.length > 0) {
      setTimeout(() => {
        packPriceIndexPianoData.map((item, i) => {
          if (
            visualTitles[i].chartRef &&
            visualTitles[i].chartRef.current &&
            visualTitles[i].chartRef.current.chart
          ) {
            const chart = visualTitles[i].chartRef.current.chart;
            chart.update({
              chart: {
                scrollablePlotArea: {
                  minHeight: item.categories.length * 60,
                  opacity: 1,
                },
              },
            });
            chart.xAxis[0].setCategories(item.categories);
            chart.series[0].setData(item.series2);
            chart.series[1].setData(item.series1);
          }
        });
      }, 0);
    }
  }, [packPriceIndexPianoData]);

  useEffect(() => {
    if (filters.geoLevel2 && Object.keys(specificFilter).length > 0) {
      getPackPriceIndexPianoData({ ...filters, ...specificFilter });
      callback({ ...specificFilter });
    }
  }, [specificFilter]);

  useEffect(() => {
    if (
      Object.keys(defaultFilters.local).length > 0 &&
      defaultFilters.global.country &&
      defaultFilters.local.currentVsLast && 
      !defaultApiCalled
    ) {
      setDefaultApiCalled(true);
      getPackPriceIndexPianoData({
        ...defaultFilters.global,
        ...defaultFilters.local,
      });
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (filters && filters.geoLevel2) {
      setSkeleton(true);
      setPackPriceIndexPianoData([]);
      setSpecificFilter({});
    }
  }, [filters]);

  const formatTableData = (data, indexNo) => {
    let tableHeadings: any = [...mockData.pianoPackHeading[indexNo].heading];
    const rows: any = [];
    data.map((item) => {
      if (item) {
        let row = {};
        tableHeadings.map((head) => {
          row = {
            ...row,
            [head.value]: {
              ...head,
              value: head.formatNumber ? formatNumber(item[head.value], 1) : item[head.value],
              key: separator(item[head.value], 2),
            },
          };
        });
        rows.push(row);
      }
    });
    return { tableHeadings, rows };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SpecificFilters
          callback={setSpecificFilter}
          filters={filters}
          defaultFilters={defaultFilters.local}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Dashboard
              title={null}
              showSkeleton={skeleton}
              chartRef={() => {}}
              showActionButton={true}
              actionButtons={{
                mmpw: false,
                screenshot: true,
                editMedia: false,
              }}
              id={null}
            >
              <Grid item xs={12}>
                {skeleton ? (
                  <Grid container justifyContent='space-around'>
                    <Grid item xs={12} sm={4} md={4}>
                      <Skeleton variant='rectangular' height={200} width={'95%'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Skeleton variant='rectangular' height={200} width={'95%'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className='m-b-20'>
                      <Skeleton variant='rectangular' height={200} width={'95%'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <CustomTable
                        heading={mockData.priceRangeVisTableHeading}
                        value={mockData.pricePianoVisSkeletonData}
                        showSkeleton={skeleton}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <CustomTable
                        heading={mockData.priceRangeVisTableHeading}
                        value={mockData.pricePianoVisSkeletonData}
                        showSkeleton={skeleton}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <CustomTable
                        heading={mockData.priceRangeVisTableHeading}
                        value={mockData.pricePianoVisSkeletonData}
                        showSkeleton={skeleton}
                      />
                    </Grid>
                  </Grid>
                ) : packPriceIndexPianoData &&
                  _.isArray(packPriceIndexPianoData) &&
                  packPriceIndexPianoData.length > 0 ? (
                  <Grid container spacing={3} justifyContent='space-between'>
                    {packPriceIndexPianoData.map((items, index) => (
                      <Grid item xs={12} sm={4} md={4}>
                        <Dashboard
                          title={visualTitles[index].title}
                          id={`price-range-pack-index-piano-${index}`}
                          chartRef={visualTitles[index].chartRef}
                          showSkeleton={skeleton}
                          actionButtons={{
                            mmpw: true,
                            screenshot: true,
                            editMedia: true,
                          }}
                        >
                          {items.series1.length > 0 ? (
                            <HighchartContainer
                              chartRef={visualTitles[index].chartRef}
                              id={`price-range-pack-index-piano-${index}`}
                            >
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={chartConfig}
                                ref={visualTitles[index].chartRef}
                              />
                            </HighchartContainer>
                          ) : (
                            <Box
                              style={{ height: 400 }}
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                            >
                              <NoData>{messages.noData}</NoData>
                            </Box>
                          )}
                          <CustomTable
                            heading={items.tableData.tableHeadings}
                            value={items.tableData.rows}
                            callback={() => {}}
                            onCellBlur={() => {}}
                            isFormatNumber={false}
                            customTableCellStyle={true}
                            stickyHeader={true}
                            maxHeight={400}
                          />
                        </Dashboard>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Box
                    style={{ height: 400 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <NoData>{messages.noData}</NoData>
                  </Box>
                )}
              </Grid>
            </Dashboard>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PackPriceIndexPiano;
