import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import TextField from "@mui/material/TextField";
import Dropdown from "../../../../components/Dropdown";
import filterMock from "../../../../mocks/priceRangePianoMock";
import _ from "lodash";

const gap = 10;

const MeasuresFilters: React.FC<{
  selectedMeasuresFilters;
  callback;
}> = ({
  selectedMeasuresFilters,
  callback
}) => {

  const onChangeValue = (val, i) => {
    const indexes = [...selectedMeasuresFilters.indexes];
    if(i<=indexes.length-1){
      indexes[i].end = val? val : " ";
      if(i!==0)
        indexes[i - 1].start = val? val : " ";
    }
    callback({ ...selectedMeasuresFilters, indexes })
  };

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        key={`api-price-piano-measures-variable-config`}
        style={{ alignItems: "baseline" }}
      >
        <Grid container spacing={3} xs={12} sm={8}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">View Share by</Typography>
            <Grid>
              <RadioButtonGroup
                key={`measures-filters-1`}
                color="#000"
                showButton={
                  filterMock.measuresFilter.viewShareby.showRadioButton
                }
                data={filterMock.measuresFilter.viewShareby.options}
                defaultOption={selectedMeasuresFilters.viewShareby}
                select={(data) =>
                  callback({ ...selectedMeasuresFilters, viewShareby: data })
                }
                direction={"column"}
              />
            </Grid>
            <Typography variant="subtitle2" style={{ marginTop: 10 }}>
              # Price Ranges
            </Typography>
            <Grid display="flex" alignItems="center">
              <Dropdown
                data={filterMock.priceRanges}
                onChange={(data) => {
                  const indexes = generateIndexTextControl(data[0]);
                  callback({ ...selectedMeasuresFilters, indexes })
                }}
                sort={{ enable: false }}
                search={{ enable: false }}
                defaultOption={[`${selectedMeasuresFilters.indexes.length}`]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: 30 }}>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 10 }}
              display="flex"
              alignItems="center"
            >
              {selectedMeasuresFilters.indexes.map((item, index) => (
                <>
                  {item.start ? (
                    <Grid item xs={12} sm={6}>
                      <TextField InputProps={{ autoComplete: 'off' }}
                        type="number"
                        size="small"
                        id="start"
                        value={item.start}
                        disabled={true}
                      />
                      <span
                        style={{
                          fontSize: "24px",
                          marginLeft: 10,
                          marginTop: 5,
                        }}
                      >
                        {">="}
                      </span>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    ></Grid>
                  )}
                  {item.end ? (
                    <Grid item xs={12} sm={6}>
                      <span
                        style={{
                          fontSize: "24px",
                          marginRight: 10,
                          marginTop: 5,
                        }}
                      >
                        {"<"}
                      </span>
                      <TextField InputProps={{ autoComplete: 'off' }}
                        type="number"
                        size="small"
                        id="end"
                        value={item.end}
                        onChange={(e) => onChangeValue(e.target.value, index)}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                    ></Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MeasuresFilters;


export const generateIndexTextControl = (n) => {
  let indexes: any = [];
  for (let i = n - 1; i >= 0; i--) {
    indexes.push({
          start: i > 0 ? `${gap * i}` : null,
          end: i !== n - 1 ? `${gap * (i + 1)}` : null,
        });
  }
  return indexes;
};