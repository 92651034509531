export const donutConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
    height: "200px",
    backgroundColor: null,
  },
  title: {
    text: "",
    align: "center",
    verticalAlign: "middle",
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    pointFormatter: function () {
      return this.enableTooltip ? `R Square: <b>${this.y}%</b>` : "";
    },
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  colors: ["#5dcb7e", "#0993cd"],
  plotOptions: {
    pie: {
      allowPointSelect: true,
      borderColor: "#ffffff",
      borderWidth: "0",
      cursor: true,
      innerSize: "70%",
      dataLabels: {
        enabled: false,
        format: "{point.y} %",
      },
      shadow: true,
      center: ["50%", "50%"],
    },
    series: {
      animation: false,
    },
  },
  series: [
    {
      type: "pie",
      innerSize: "60%",
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
