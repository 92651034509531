import styled from 'styled-components';
import { BlackInputTitle } from '../../styles/Common.Styled';
import { Box } from '@mui/material';

// const Button = styled(Box)`
//     height: 42px;
//     width: 42px;
//     color: #fff;
//     display: flex;
//     cursor: pointer;
//     margin-right: 14px;
//     border-radius: 6px;
//     align-items: center;
//     justify-content: center;
//     background: ${props => props.color};
// `;

const Title = styled(BlackInputTitle)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));

const SliderContainer = styled(Box)(() => ({
  width: 400,
  padding: '10px 24px',
  background: '#dbe7f3',
  borderRadius: 5,
}));

export { Title, SliderContainer };
