import { API } from "../../util/helper"

export const getDashboardSource = async (endpoint, payload) => {
    try {
      //`${process.env.REACT_APP_BACKEND_BASE_URL}portalFeature/${payload.country}/${payload.dashboardName}/${payload.measureFilter}/${payload.data}${payload.isInase? '?isInase=true' : ''}`,
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}portalFeature/${endpoint}`,
        payload
      );
    } catch (e) {
      return e;
    }
  };