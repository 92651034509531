import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../../../components/Dashboard";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import { historicPriceFilters } from "../../../../mocks/historyPriceMock";
import { BlackTitle, NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { GraphCard } from "../../../HistoricPriceAndCrossPriceElasticity/HistoricPriceAndCrossPriceElasticity.Styled";
import { fetchCauseBusinessChangeData } from "../../service";
import WaterFall from "./WaterFall";
import { chartConfig } from "./WaterFall/chartConfig";

const initialState = [0, 1, 2];

const CauseOfBusinessChange: React.FC<{
  filters;
  callback;
  defaultImpactVariable;
}> = ({ filters, callback, defaultImpactVariable }) => {
  const chartRef = [
    { chartRef: useRef(null) },
    { chartRef: useRef(null) },
    { chartRef: useRef(null) },
  ];
  const [skeleton, setSkeleton] = useState(true);
  const [chartData, setChartData] = useState<any>([...initialState]);
  const [selectedVariable, setSelectedVariable] = useState(
    historicPriceFilters[0].value
  );

  useEffect(() => {
    if (filters && filters.country) {
      fetchCauseBusinessData(selectedVariable);
    }
  }, [filters]);

  useEffect(() => {
    if (defaultImpactVariable)
      setSelectedVariable(
        defaultImpactVariable || historicPriceFilters[0].value
      );
  }, [defaultImpactVariable]);

  const fetchCauseBusinessData = (impactVar?) => {
    setSkeleton(true);
    const payload = { ...filters, impactVariable: impactVar };
    fetchCauseBusinessChangeData(payload)
      .then((response) => {
        //data binding logic
        setChartData([...response.data]);
        setSkeleton(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSelectVariable = (data) => {
    fetchCauseBusinessData(data);
    setSelectedVariable(data);
    callback(data);
  };

  return (
    <Card className="m-b-20">
      <CardContent>
        <BlackTitle style={{ fontSize: 20, fontWeight: 600, mb: 3 }}>
          Cause Of Business Change in a Rolling Year{" "}
        </BlackTitle>
        {!skeleton ? (
          <Grid className="m-b-10">
            {_.isArray(chartData) && chartData.length ? (
              <>
                <BlackTitle>Business Impact Variable</BlackTitle>
                <Grid className="m-b-10">
                  <RadioButtonGroup
                    key={Math.random()}
                    color="#000"
                    data={historicPriceFilters}
                    direction="row"
                    defaultOption={selectedVariable}
                    select={(data) => onSelectVariable(data)}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={400}
                  sx={{ marginBottom: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2.5} sm={2.5}>
                <Grid container>
                  <Grid item xs={4} sm={4}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      width={110}
                      sx={{ marginBottom: "20px" }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      width={110}
                      sx={{ marginBottom: "20px" }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      width={110}
                      sx={{ marginBottom: "20px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {_.isArray(chartData) && chartData.length ? (
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-around"
            className="m-b-20"
          >
            {chartData.map((item, index) => (
              <GraphCard
                flex="3"
                key={Math.random()}
                data-testid="businessImpactGraph"
              >
                <Dashboard
                  id={`historic-price-track-cause-of-business-${item}`}
                  chartRef={chartRef[index].chartRef}
                  showSkeleton={skeleton}
                >
                  {!skeleton ? (
                    <Grid container>
                      <Grid item md={12}>
                        <Card>
                          <CardContent style={{ backgroundColor: "#FAFAFA" }}>
                            <BlackTitle textAlign="center">
                              Growth for{" "}
                              <Typography
                                component="span"
                                sx={{
                                  color: "#25d4cf",
                                }}
                              >
                                {" "}
                                {filters.anchorBrand +
                                  " " +
                                  item.data[0].name +
                                  " Vs " +
                                  item.data[item.data.length - 1].name}{" "}
                              </Typography>
                            </BlackTitle>
                            <WaterFall
                              chartConfig={chartConfig}
                              chartRef={chartRef[index].chartRef}
                              data={item.data}
                              id={`historic-price-track-cause-of-business-${item}`}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Skeleton variant="rectangular" height={400} />
                      </Grid>
                    </Grid>
                  )}
                </Dashboard>
              </GraphCard>
            ))}
          </Box>
        ) : (
          <Box
            style={{ height: 400 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <NoData>{messages.noData}</NoData>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CauseOfBusinessChange;
