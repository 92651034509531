export const primaryFilter = {
  country: {
    title: "Country",
    options: [],
  },
  geoLevel: {
    title: "Geo-Level",
    options: [],
    placeholder: "Select",
    all: false,
    multiple: false,
  },
  geoLevel2: {
    title: "Geo-Level 2",
    options: [],
    placeholder: "Select",
    all: false,
    multiple: false,
    defaultSelectAll: false,
  },
  channel: {
    title: "Channel",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
};
const sellOutAndInaseFilters = {
  regions: {
    title: "Regions",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
  },
};
const productFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "subBrand",
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "packSize",
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation <span style="color: red;">*</span>`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    defaultSelectAll: false,
    all: true,
  },
  // permutationCombination: {
  //   title: `Permutation Computation`,
  //   options: [],
  //   placeholder: 'Select',
  //   multiple: true,
  //   last: true,
  //   defaultSelectAll: false,
  //   all: true,
  // },
};
const measureTable1Headings = [
  {
    label: "Product",
    value: "productName",
    rowspan: 2,
  },
  {
    label: "Price Per Unit",
    value: "pricePerUnit",
    colspan: 3,
    width: 400,
  },
  {
    label: "Grams Per Unit",
    value: "gramsPerUnit",
    colspan: 3,
    width: 400,
  },
  {
    label: "Price Elasticity Expected",
    value: "priceElasticityExpected",
    rowspan: 2,
    width: 150,
  },
  {
    label: "Grams Elasticity Expected",
    value: "gramsElasticityExpected",
    rowspan: 2,
    width: 150,
  },
  {
    label: "Model Price Elasticity Curve",
    value: "modelpriceElasticity",
    rowspan: 2,
    width: 200,
  },
  {
    label: "Model Grams Elasticity Curve",
    value: "modelGramsElasticityCurve",
    rowspan: 2,
    width: 200,
  },
];
const measureTable1SubHeadings = {
  productName: {
    label: "",
    image: true,
  },
  productInfo: { label: "", type: "none", value: "productInfo" },
  mixSellIn: { label: "", type: "none", value: "mixSellIn" },
  pricePerUnitFrom: {
    label: "From",
    value: "pricePerUnitFrom",
    editable: true,
    type: "Text",
    width: 150,
  },
  pricePerUnitTo: {
    label: "To",
    value: "pricePerUnitTo",
    editable: true,
    type: "Text",
    width: 150,
  },
  pricePerUnitVar: {
    label: "Var",
    value: "pricePerUnitVar",
    prepend: "%",
    width: 100,
  },
  gramPerUnitFrom: {
    label: "From",
    value: "gramPerUnitFrom",
    editable: true,
    type: "Text",
    width: 150,
  },
  gramPerUnitTo: {
    label: "To",
    value: "gramPerUnitTo",
    editable: true,
    type: "Text",
    width: 150,
  },
  gramPerUnitVar: {
    label: "Var",
    value: "gramPerUnitVar",
    prepend: "%",
    width: 100,
  },
  priceElasticityExpected: {
    label: "",
    value: "priceElasticityExpected",
    editable: true,
    type: "Text",
    width: 150,
  },
  gramsElasticityExpected: {
    label: "",
    value: "gramsElasticityExpected",
    editable: true,
    type: "Text",
    width: 150,
  },
  modelPriceElasticity: {
    label: "",
    value: "modelpriceElasticity",
    editable: true,
    type: "Radio",
    defaultOption: {
      id: 1,
      value: "High",
    },
    options: [
      {
        id: 1,
        label: "High",
        value: "High",
      },
      {
        id: 2,
        label: "Medium",
        value: "Medium",
      },
      {
        id: 3,
        label: "Low",
        value: "Low",
      },
      {
        id: 4,
        label: "No Curve",
        value: "NoCurve",
      },
    ],
  },
  modelGramsElasticityCurve: {
    label: "",
    value: "modelGramsElasticityCurve",
    editable: true,
    type: "Radio",
    defaultOption: {
      id: 1,
      value: "High",
    },
    options: [
      {
        id: 1,
        label: "High",
        value: "High",
      },
      {
        id: 2,
        label: "Medium",
        value: "Medium",
      },
      {
        id: 3,
        label: "Low",
        value: "Low",
      },
      {
        id: 4,
        label: "No Curve",
        value: "NoCurve",
      },
    ],
  },
};

export const RealVsExpectedRadio = {
  options: [
    {
      id: "1",
      label: "Unit Variation",
      value: "unit",
      inline: "true",
    },
    {
      id: "2",
      label: "Elasticity",
      value: "elasticity",
      inline: "true",
    },
  ],
  defaultOption: { id: "1", value: "unit" },
  showRadioButton: true,
};

//new
export const Unit = [
  { id: 1, value: "sales", label: "Sales" },
  { id: 2, value: "units", label: "Units" },
  { id: 3, value: "volume", label: "Volume" },
];
//new end

export const SimulationTableHeadingRowData = {
  beforeVsAfter: {
    label: "Real Before vs Real After",
  },
  modelVsSimulation: {
    label: "Model Output vs Model Output No Pricing Simulation",
  },
};

export const elasticityRadio = {
  dataSource: [
    {
      id: "1",
      label: "Sell Out",
      value: "sellOut",
    },
    {
      id: "2",
      label: "INASE",
      value: "inase",
    },
  ],
  defaultOption: { id: "2", value: "inase" },
  showRadioButton: true,
};

export const SimulationRadio = {
  options: [
    {
      id: "1",
      label: "Sales",
      value: "sales",
    },
    {
      id: "2",
      label: "Units ",
      value: "units",
    },
    {
      id: "3",
      label: "Volume ",
      value: "volume",
    },
  ],
  defaultOption: { id: "1", value: "sales" },
  showRadioButton: true,
};

export const SimulationTableHeading = [
  {
    label: "Real",
    value: "real",
  },
  {
    label: "Model Output",
    value: "modelOutput",
  },
  {
    label: "No Pricing Simulation",
    value: "noPricingSimulation",
  },
];

//5th visual new
export const macroEconVariables = [
  "inflationRate",
  "htCovidPositiveRate",
  "loansPersonalAndMicroCreditsind",
  "htCovidTotalCases",
  "cciIndicator",
  "minWageNatPesosday",
  "htCovidTotalDeaths",
  "loansCreditCards",
  "mbtyRetailRecreationPercentchangeFromBaseline",
  "loansPayroll",
  "ppiPrimaryActivities",
  "wrAvgMoneyorders",
  "minWageNatIndex",
  "ppiTotalProduction",
  "gasolinePremium",
  "cpiPriceToConsumer",
  "unemploymentRateTrendCycle",
  "htCovidTotalVaccinations",
  "gasolineRegular",
  "gasolineDiesel",
  "mbtyGroceryPharmacyPercentchangeFromBaseline",
  "unemploymentRateSznadj",
  "ppiTotalProductionWithoutPetrol",
];

export const variableTrendInPriceElasticityModelTableHead = [
  {
    label: "Business Variables",
    value: "modelVariables",
  },
  {
    label: "Avg Before Pricing",
    value: "avgBeforePricing",
  },
  {
    label: "Avg After Pricing",
    value: "avgAfterPricing",
  },
  {
    label: "Var ABS",
    value: "varAbs",
  },
  {
    label: "Var %",
    value: "varPercentage",
  },
  {
    label: "Model Contribution %",
    value: "variableContribution",
  },
  // {
  //   label: "R2",
  //   value: "rSquare",
  // },
];
//5th visual new end

export const resultPerWeekMainHeadings = [
  {
    label: "",
    value: "",
    config: { width: 50, display: true },
  },
  {
    label: "Weekly Result",
    value: "weeklyResult",
    config: { colspan: 10, display: true },
  },
  {
    label: "Accumulated Result",
    value: "accumulatedResult",
    config: { colspan: 10, display: true },
  },
];

export const resultPerWeekHeadings = {
  image: {
    label: "",
    config: { width: 50, display: true },
  },
  name: {
    label: " ",
    config: { colspan: 2, width: 260, display: true },
  },
  "weekly.salesIndex": {
    label: "Sales - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "weekly.unitsIndex": {
    label: "Units - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "weekly.volumeIndex": {
    label: "Volume - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "weekly.ppuPricing": {
    label: "PPU Pricing %",
    config: { rowspan: 2, width: 100, display: true },
  },
  "weekly.realUnitsVariation": {
    label: "Real Unit Variation %",
    config: { rowspan: 2, width: 100, display: true },
  },
  "weekly.expectedUnitsVariation": {
    label: "Expected Unit Variation %",
    config: { rowspan: 2, width: 100, display: true },
  },

  "weekly.realElasticity": {
    label: "Real Elasticity",
    config: { rowspan: 2, width: 100, display: true },
  },
  "weekly.expectedElasticity": {
    label: "Expected Elasticity",
    config: { rowspan: 2, width: 100, display: true },
  },
  "accumulated.salesIndex": {
    label: "Sales - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "accumulated.unitsIndex": {
    label: "Units - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "accumulated.volumeIndex": {
    label: "Volume - Index",
    config: { colspan: 2, width: 300, display: true },
  },
  "accumulated.ppuPricing": {
    label: "PPU Pricing %",
    config: { rowspan: 2, width: 100, display: true },
  },
  "accumulated.realUnitsVariation": {
    label: "Real Unit Variation %",
    config: { rowspan: 2, width: 100, display: true },
  },
  "accumulated.expectedUnitsVariation": {
    label: "Expected Unit Variation %",
    config: { rowspan: 2, width: 100, display: true },
  },

  "accumulated.realElasticity": {
    label: "Real Elasticity",
    config: { rowspan: 2, width: 100, display: true },
  },
  "accumulated.expectedElasticity": {
    label: "Expected Elasticity",
    config: { rowspan: 2, width: 100, display: true },
  },
};

export const resultPerWeekSubHeadings = {
  image: {
    label: "",
    config: { width: 50, display: true, showColorDot: false },
  },
  displayName: {
    label: "Product",
    config: { width: 160, display: true, showColorDot: false },
  },
  mixSales: {
    label: "Mix Of Sales",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
      showPercent: true,
    },
  },
  "weekly.salesIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "weekly.salesIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // 'weekly.salesIndex.vsExpected': {
  //   label: 'VS Expected',
  //   config: { formatNumber: true, width: 100, display: true, showColorDot: true },
  // },
  "weekly.unitsIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "weekly.unitsIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // "weekly.unitsIndex.vsExpected": {
  //   label: "VS Expected",
  //   config: {
  //     formatNumber: true,
  //     width: 100,
  //     display: true,
  //     showColorDot: true,
  //   },
  // },
  "weekly.volumeIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "weekly.volumeIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // 'weekly.volumeIndex.vsExpected': {
  //   label: 'VS Expected',
  //   config: { formatNumber: true, width: 100, display: true, showColorDot: true },
  // },
  "weekly.ppuPricing": {
    label: "PPU Pricing %n",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "weekly.realUnitsVariation": {
    label: "Real Unit Variation",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "weekly.expectedUnitsVariation": {
    label: "Expected Unit Variation",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },

  "weekly.realElasticity": {
    label: "Real Elasticity",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "weekly.expectedElasticity": {
    label: "Expected Elasticity",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "accumulated.salesIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "accumulated.salesIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // 'accumulated.salesIndex.vsExpected': {
  //   label: 'VS Expected',
  //   config: { formatNumber: true, width: 100, display: true, showColorDot: true },
  // },
  "accumulated.unitsIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "accumulated.unitsIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // "accumulated.unitsIndex.vsExpected": {
  //   label: "VS Expected",
  //   config: {
  //     formatNumber: true,
  //     width: 100,
  //     display: true,
  //     showColorDot: true,
  //   },
  // },
  "accumulated.volumeIndex.absoluteMM": {
    label: "Absolute MM",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: false,
    },
  },
  "accumulated.volumeIndex.vsYAGO": {
    label: "VS YAGO",
    config: {
      formatNumber: true,
      width: 100,
      display: true,
      showColorDot: true,
    },
  },
  // 'accumulated.volumeIndex.vsExpected': {
  //   label: 'VS Expected',
  //   config: { formatNumber: true, width: 100, display: true, showColorDot: true },
  // },
  "accumulated.ppuPricing": {
    label: "PPU Pricing %n",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "accumulated.realUnitsVariation": {
    label: "Real Unit Variation",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "accumulated.expectedUnitsVariation": {
    label: "Expected Unit Variation",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },

  "accumulated.realElasticity": {
    label: "Real Elasticity",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
  "accumulated.expectedElasticity": {
    label: "Expected Elasticity",
    config: {
      formatNumber: true,
      rowspan: 2,
      width: 100,
      display: false,
      showColorDot: false,
    },
  },
};

const exportObject = {
  primaryFilter,
  productFilter,
  measureTable1Headings,
  measureTable1SubHeadings,
  elasticityRadio,
  sellOutAndInaseFilters,
};
export default exportObject;
