import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, IconButton, Collapse, Button, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Remove } from '@mui/icons-material';
import MultiLevelTable from './MultiLevelTable';
import { separator } from '../../util/helper';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  borderClass: {
    borderLeft: '1px solid #F5F5F5',
  },
  cellPadding: {
    padding: '0px 0px 0px 5px !important',
  },
  flexEvenly: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  overflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 0px',
  },
  dFlex: {
    display: 'flex !important',
    alignItems: 'center',
  },
  tdWidth: {
    width: '6vw',
  },
  collapseSignColor: {
    color: '#25d4cf',
  },
}));

const Row: React.FC<{ data: any; originalData: any; level; style; hierarchy, hierarchyData, orderByNum, }> = ({ data, originalData, level, style, hierarchy, hierarchyData, orderByNum }) => {
  const [payload, setPayload] = useState({
    open: false,
    hierarchyData: {},
    nextLevel: '',
    filteredData: []
  });
  const classes = useStyles();
  const keys = Object.keys(hierarchy);
  const isFirstCell = (i) => i === 0;
  const isLastLevel = () => {
    return keys.findIndex(k => k === level) === keys.length - 1
  };
  const rowData = hierarchy[level] && hierarchy[level].responseKeys?.map((attr, i) => (
    <TableCell
      align={isFirstCell(i) ? 'left' : 'center'}
      className={`${!isFirstCell(i) && classes.borderClass} ${isFirstCell(i) && classes.dFlex} ${!isFirstCell(i) && classes.tdWidth} ${classes.overflowEllipsis
        }`}
      key={attr}
      style={{ width: isFirstCell(i) ? '100%' : '18%' }}
    >
      {isFirstCell(i) && !isLastLevel() && (
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            const index = keys.findIndex(k => k === level);
            let nextLevel = '';
            if (!isLastLevel()) {
              nextLevel = keys[index + 1];
            }
            const filterByKeys = hierarchy[nextLevel].filterBy;
            const filteredData = originalData[nextLevel].filter(d => {
              const length = filterByKeys.filter(k => {
                if (hierarchyData && hierarchyData[k])
                  return hierarchyData[k] === d[k]
                else if (level === k)
                  return data[attr] === d[k]
                else
                  return false
              }).length
              return filterByKeys.length === length
            })
            setPayload({
              ...payload,
              open: !payload.open,
              nextLevel,
              hierarchyData: { ...hierarchyData, [level]: data[attr] },
              filteredData,
            })
          }}
        >
          {payload.open ? <Remove className={classes.collapseSignColor} /> : <Add className={classes.collapseSignColor} />}
        </IconButton>
      )}
      {isFirstCell(i) && isLastLevel() && <IconButton style={{ width: '30px' }}></IconButton>}
      {
        <p className={classes.overflowEllipsis} title={data[attr]} style={{ width: isFirstCell(i) ? style.cellWidth : '100%' }}>
          {_.isNumber(data[attr]) ? separator(data[attr], 2) : data[attr]}
        </p>
      }
    </TableCell>
  ));
  return (
    <React.Fragment>
      {rowData}
      <TableRow style={{ overflow: 'hidden' }}>
        <TableCell className={classes.cellPadding} colSpan={hierarchy[level] ? hierarchy[level].responseKeys.length : 0}>
          <Collapse in={payload.open} timeout='auto' unmountOnExit>
            <>
              {!isLastLevel() && payload.nextLevel && (
                <MultiLevelTable
                  style={{ overflow: 'hidden', cellWidth: style.cellWidth }}
                  data={originalData}
                  level={payload.nextLevel}
                  hierarchy={hierarchy}
                  hierarchyData={payload.hierarchyData}
                  filteredData={payload.filteredData}
                  orderByNum={orderByNum}
                />
              )}
            </>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default Row;
