import * as React from 'react';
import Highcharts from 'highcharts';
import { MultipleChartConfig } from './MultipleChartConfig';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const MultipleChart: React.FC<{
  chartType;
  data;
  axisTitle;
  pricing;
  chartColor;
  id;
  xAxisLabel;
  outlier?;
}> = ({ chartType, chartColor, data, axisTitle = { x: '', y: '' }, pricing, id, xAxisLabel = false, outlier }) => {
  const [outliers, setOutliers] = React.useState<any>({});
  React.useEffect(() => {
    const maxVal = _.maxBy(data, 'y');
    const outlier: Array<any> = data.map((item, i) =>
      item.outlier == true ? { name: ' ', total: { label: item.outlierLabel }, y: maxVal.y } : null
    );
    setOutliers({ maxVal, data: outlier });
  }, [data]);

  const highchartOptions = MultipleChartConfig(chartType, data, axisTitle, pricing, chartColor, id, xAxisLabel, outliers);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={highchartOptions}
      //   ref={chartRef}
    />
  );
};
export default MultipleChart;
