import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Grid, Skeleton } from '@mui/material';
import mockData from '../../../../../../mocks/macroEconDashboard';
import { DropdownTitle } from '../../../../../../components/DashboardFilters/DashboardFilters.Styled';
import RadioButtonGroup from '../../../../../../components/UI-components/RadioButtonGroup';
import { OrangeBtn, PrimaryBtn } from '../../../../../../styles/Common.Styled';
import Indicator from '../../../../../../components/Loader';
import ProductFiltersV3 from '../../../../../../components/ProductFilters-v3';
import { enableCompetitorFilter, removeMMPWFromLocal } from '../../../../../../util/helper';

const periodicityData = [
  { id: '1', label: 'Daily', value: 'Daily' },
  { id: '2', label: 'Weekly', value: 'Weekly' },
  { id: '3', label: 'Monthly', value: 'Monthly' },
  { id: '4', label: 'Quarterly', value: 'Quarterly' },
  { id: '5', label: 'Yearly', value: 'Yearly' },
];

const initialProductState = {
  category: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
};

const TopFilters: React.FC<{
  showLoader;
  geoFilter?;
  callback;
  type;
  title;
  defaultFilters;
  id;
}> = ({ showLoader, geoFilter, callback, type, title, defaultFilters, id }) => {
  const [loader, setLoader] = useState(false);
  const [skeleton, setSkeleton] = React.useState(false);

  const [periodicity, setPeriodicity] = React.useState(periodicityData[3].value);

  const [selectedGeoFilters, setSelectedGeoFilters] = useState({});
  const [localDefaultFilters, setLocalDefaultFilters] = useState(null);
  const [selectedProductFilters, setSelectedProductFilters] = useState<any>({});
  const [clearFilterData, clearGeoFilter] = useState(false);

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters)?.length > 0) {
      setPeriodicity(defaultFilters?.periodicity);
      setLocalDefaultFilters({ ...defaultFilters });
    }
    setTimeout(() => {
      setSelectedGeoFilters({ ...geoFilter });
      setSelectedProductFilters({});
      clearGeoFilter(true);
    }, 0);
  }, [geoFilter]);

  useEffect(() => {
    setLoader(showLoader);
  }, [showLoader]);

  const disableApplyFilter = () => {
    return (
      !selectedProductFilters.category ||
      !periodicity
    );
  };

  const onApplyFilter = () => {
    removeMMPWFromLocal(id);
    callback({
      ...selectedProductFilters,
      periodicity,
    });
  };

  useEffect(() => {
    setLoader(showLoader);
  }, []);

  const clearFilter = () => {
    setPeriodicity(periodicityData[3].value);
    setSelectedProductFilters(initialProductState);
  };

  const selectProductLogic = (e) => {
    setSelectedProductFilters({ ...e });
    clearGeoFilter(false);
  };

  return (
    <Box style={{ position: 'relative' }}>
      {!skeleton ? (
        <>
          <Indicator position='absolute' show={loader} />
          <Grid className='p-l-r-20'>
            <Grid>
              <ProductFiltersV3
                onChange={(e) => selectProductLogic(e)}
                data={
                  type === 'pepsi' ? mockData.productFilterConfig : mockData.competitorFilterConfig
                }
                onChangeLoader={setLoader}
                selectedGeoFilters={
                  type === 'pepsi'
                    ? selectedGeoFilters
                    : enableCompetitorFilter(selectedGeoFilters)
                    ? selectedGeoFilters
                    : {}
                }
                clearFilter={clearFilterData}
                params={{
                  productFilterFlag: type,
                }}
                defaultFilters={
                  localDefaultFilters &&
                  Object.keys(localDefaultFilters).length > 0 && {
                    category: localDefaultFilters.category,
                    segment: localDefaultFilters.segment,
                    brand: localDefaultFilters.brand,
                    subBrand: localDefaultFilters.subBrand,
                    packSize: localDefaultFilters.packSize,
                  }
                }
                apiURL='dashboards/ProductFilter'
              />
            </Grid>
            <Grid container className='m-b-20'>
              <Grid item xs={12} sm={8.1} key={`measures-product-list`}>
                <DropdownTitle>Data Aggregation</DropdownTitle>
                <RadioButtonGroup
                  key={`periodicity-radio-buttons`}
                  color='#000'
                  showButton={true}
                  data={periodicityData}
                  defaultOption={periodicity}
                  select={setPeriodicity}
                  direction='row'
                />
              </Grid>
              <Grid item xs={12} sm={2} key={`measures-product-list`}>
                {selectedProductFilters.brand && (
                  <DropdownTitle className='m-b-10'>{title.toUpperCase()}</DropdownTitle>
                )}
                {selectedProductFilters.brand && selectedProductFilters.brand.length && (
                  <Box style={{ overflow: 'auto', maxHeight: 100 }}>
                    {selectedProductFilters.brand.map((b) => (
                      <Box style={{ color: '#000' }}>{b}</Box>
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid>
              <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} className='m-b-20'>
            {Object.keys(mockData.productFilter).map(() => (
              <Grid item xs={12} sm={2}>
                <Skeleton height={22} width={210} />
                <Skeleton variant='rectangular' width={210} height={45} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} className='m-b-20'>
            <Grid item xs={12}>
              <Skeleton width={210} height={30} />
            </Grid>
          </Grid>
          <Grid className='p-l-16 m-b-20' container spacing={2}>
            {periodicityData.map((item) => (
              <Skeleton className='m-r-20' width={150} height={22} />
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Skeleton variant='rectangular' width={210} height={45} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Skeleton variant='rectangular' width={210} height={45} />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TopFilters;
