import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

const StyledCard = styled(Card)(() => ({
  height: "8em",
  border: "1px solid #D3D3D3",
}));

const Title = styled(Typography)(() => ({
  color: "#25d4cf",
  fontSize: 16,
}));

const Value = styled(Typography)(() => ({
  fontSize: 20,
  color: "#555A60",
  fontWeight: 600,
}));

const Profit = styled(Typography)(() => ({
  marginTop: 4,
  color: "green",
  fontSize: 16,
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
}));

const Loss = styled(Typography)(() => ({
  marginTop: 4,
  color: "red",
  fontSize: 16,
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
}));

export { StyledCard, Value, Profit, Loss, Title };
