import { Box, Grid, Skeleton, Table, TableBody, TableHead, TableRow, TextField, Tooltip, Typography, Switch } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { StyledNoBorderEmptyCell, StyledTableCell, StyledTableContainer, StyledTableRow } from './VisualizationTable.Styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DropdownTitle } from '../../../../../../components/DashboardFilters/DashboardFilters.Styled';
import Dropdown from '../../../../../../components/Dropdown';
import mockData from '../../../../../../mocks/priceSettingToolMock';
import { NoData } from '../../../../../../styles/Common.Styled';
import { messages } from '../../../../../../util/config';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { priceSettingToolDataLoader } from '../../../../store/priceSettingTool.action';
import { formatNumber, rendarImage } from '../../../../../../util/helper';
import { fetchPriceSettingScenarioData } from '../../../../service';

const VisualizationTable: React.FC<{
  index;
  filters;
  defaultFilters;
  callback;
  skeleton;
}> = ({ index, filters, defaultFilters, callback, skeleton }) => {
  const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
  const [filterSkeleton, setFilterSkeleton] = React.useState(false);
  const [tableSkeleton, setTableSkeleton] = React.useState(true);
  const [tableHeading, setTableHeading] = React.useState<any>([...mockData.TableHeadings]);
  const [tableSubHeading, setTableSubHeading] = React.useState<any>({
    ...mockData.tableSubHeadings,
  });
  const [tableValues, setTableValues] = React.useState([1]);
  const [tempScenarioName, setTempScenarioName] = React.useState('');
  const [scenarioName, setScenarioName] = React.useState('');
  const [products, setProducts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [hideColumns, setHideColumns] = React.useState(false);
  const [originalResponse, setOriginalResponse] = React.useState([]);
  const [isAPICalled, setIsAPICalled] = React.useState(false);
  const [unitsVar, setUnitsVar] = React.useState<any>();
  const [volumeVar, setVolumeVar] = React.useState<any>();
  const [grossSalesVar, setGrossSalesVar] = React.useState<any>();
  const [grossProfitVar, setGrossProfitVar] = React.useState<any>();
  const [unitsPer, setUnitsPer] = React.useState<any>();
  const [volumePer, setVolumePer] = React.useState<any>();
  const [grossSalesPer, setGrossSalesPer] = React.useState<any>();
  const [grossProfitPer, setGrossProfitPer] = React.useState<any>();
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();

  const onChangeHideColumns = (event) => {
    const flag = event.target.checked;
    setHideColumns(flag);
    setTableSkeleton(true);
    if (!flag) {
      setTableHeading(mockData.TableHeadings);
      setTableSubHeading(mockData.tableSubHeadings);
      if (originalResponse.length > 0) formatData(originalResponse, { ...mockData.tableSubHeadings });
    } else {
      setTableHeading(mockData.collapsedTableHeadings);
      setTableSubHeading(mockData.collapsedTableSubHeadings);
      if (originalResponse.length > 0) formatData(originalResponse, { ...mockData.collapsedTableSubHeadings });
    }
  };

  useEffect(() => {
    if (filters && filters.country) {
      if (index === 0 && (!defaultFilters || !Object.keys(defaultFilters)?.length)) {
        setProducts(filters.permutation.map((x) => x.value));
        setHideColumns(false);
        setOriginalResponse([]);
        setTableHeading([...mockData.TableHeadings]);
        setTableSubHeading({ ...mockData.tableSubHeadings });
        setFilterSkeleton(false);
        getTableData(
          null,
          filters.permutation.map((x) => x.value),
          { ...mockData.tableSubHeadings }
        );
      }
      setDefaultVal();
    }
  }, [filters]);

  useEffect(() => {
    setFilterSkeleton(index === 0);
  }, [index]);

  const setDefaultVal = () => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setScenarioName(defaultFilters.scenarioName ? defaultFilters.scenarioName : `Scenario-${index + 1}`);
      setTempScenarioName(defaultFilters.scenarioName ? defaultFilters.scenarioName : `Scenario-${index + 1}`);
      setProducts(defaultFilters.products ? defaultFilters.products : []);
      const payload =
        _.isArray(defaultFilters.simulation) && defaultFilters.simulation.length > 0 && Object.keys(defaultFilters.simulation[0]).length > 0
          ? defaultFilters.simulation
          : null;
      getTableData(payload, defaultFilters.products);
    } else {
      setScenarioName(`Scenario-${index + 1}`);
      setTempScenarioName(`Scenario-${index + 1}`);
    }
  };

  const getTableData = (simulation, products?, tableSubHeading?) => {
    if (_.isArray(products) && products.length > 0) {
      setFilterSkeleton(true);
      setIsAPICalled(true);
      setTableSkeleton(true);
      const requestPayload = {
        ...filters,
        scenario: {
          index,
          name: tempScenarioName || 'Scenario-1',
        },
        simulation,
        permutation: products,
      };
      dispatch(priceSettingToolDataLoader(true));
      fetchPriceSettingScenarioData(requestPayload)
        .then((res) => {
          setOriginalResponse(res.data);
          formatData(res.data, tableSubHeading);
        })
        .catch((e) => {
          setTableSkeleton(false);
          setFilterSkeleton(false);
          dispatch(priceSettingToolDataLoader(false));
        });
    }
  };

  const formatData = (data, tableHeader?) => {
    if (_.isArray(data) && data.length > 0) {
      const rows: any = [];
      Promise.all(
        data.map(async (item) => {
          item.image = {
            src: await rendarImage(item.productName, filters.country),
            alt: item.productName,
            height: 100,
            width: item.width ? item.width : 100,
            tooltip: item.productInfo,
          };
        })
      ).then(() => {
        data.map((item) => {
          let row = {};
          Object.entries(tableHeader ? tableHeader : tableSubHeading).map(([key, config]) => {
            row = {
              ...row,
              [key]: {
                ...config,
                value: config?.muliply ? item[key] * 100 : item[key],
                ...(config?.image && item.image ? { image: item.image } : {}),
                key,
                ...(key === 'productName' ? { productInfo: item.productInfo } : {}),
              },
            };
          });
          rows.push(row);
        });
        const unitsTo = data.reduce(function (tot, arr) {
          return tot + arr.unitsTo;
        }, 0);

        const unitsFrom = data.reduce(function (tot, arr) {
          return tot + arr.unitFrom;
        }, 0);
        const unitsVar = data.reduce(function (tot, arr) {
          return tot + arr.unitsVarAbs;
        }, 0);

        const volumeTo = data.reduce(function (tot, arr) {
          return tot + arr.volumeTo;
        }, 0);
        const volumeFrom = data.reduce(function (tot, arr) {
          return tot + arr.volumeFrom;
        }, 0);

        const volume = data.reduce(function (tot, arr) {
          return tot + arr.volumeVarAbs;
        }, 0);

        const grossSalesTo = data.reduce(function (tot, arr) {
          return tot + arr.grossSalesTo;
        }, 0);
        const grossSalesFrom = data.reduce(function (tot, arr) {
          return tot + arr.grossSalesFrom;
        }, 0);
        const grossSales = data.reduce(function (tot, arr) {
          return tot + arr.grossSalesVarAbs;
        }, 0);

        const grossProfitTo = data.reduce(function (tot, arr) {
          return tot + arr.grossProfitTo;
        }, 0);

        const grossProfitFrom = data.reduce(function (tot, arr) {
          return tot + arr.grossProfitFrom;
        }, 0);

        const grossProfit = data.reduce(function (tot, arr) {
          return tot + arr.grossProfitVarAbs;
        }, 0);

        setUnitsVar(unitsVar);
        setVolumeVar(volume);
        setGrossSalesVar(grossSales);
        setGrossProfitVar(grossProfit);
        setUnitsPer((unitsTo / unitsFrom - 1) * 100);
        setVolumePer((volumeTo / volumeFrom - 1) * 100);
        setGrossSalesPer((grossSalesTo / grossSalesFrom - 1) * 100);
        setGrossProfitPer((grossProfitTo / grossProfitFrom - 1) * 100);
        setTableValues(rows);
        setTableSkeleton(false);
        setFilterSkeleton(false);
        dispatch(priceSettingToolDataLoader(false));
      });
    } else {
      setOriginalResponse([]);
      setTableValues([]);
      setTableSkeleton(false);
      setFilterSkeleton(false);
      dispatch(priceSettingToolDataLoader(false));
    }
  };

  useEffect(() => {
    if (!isAPICalled && products.length > 0) {
      getTableData(null, products);
      callback({ scenarioName, products });
    }
  }, [products]);

  const onChangeDropdown = (data) => {
    setIsAPICalled(false);
    setProducts(data);
  };

  const onBlur = () => {
    if (scenarioName.trim() !== tempScenarioName.trim()) {
      const simulation = tableValues ? formatValues(tableValues) : null;
      callback({ scenarioName, products, simulation });
    }
  };

  const fetchImage = (payload, name) => {
    if (payload.src) {
      return (
        <Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
          <div>
            <img src={payload.src} height={payload.height} width={payload.width} alt={payload.alt} />
            {payload.tooltip ? <div className='ellipsis'>{payload.tooltip}</div> : null}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip disableHoverListener={!payload.tooltip} title={payload.tooltip}>
          <Box className='img-error-box' sx={{ border: '1px solid #D3D3D3' }}>
            {payload.tooltip ? (
              <div className='ellipsis' style={{ color: '#929292', fontSize: '14px' }}>
                {payload.tooltip}
              </div>
            ) : null}
          </Box>
        </Tooltip>
      );
    }
  };

  const renderControls = (cellData, row, column, columnName) => {
    if (cellData.type === 'Text') {
      return (
        <TextField
          id='proposed-price'
          type='number'
          size='small'
          value={
            _.isInteger(cellData.value) || _.isString(cellData.value)
              ? cellData.value
              : cellData.value.toFixed(cellData.decimal ? cellData.decimal : 2)
          }
          autoFocus={false}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              const payload = formatValues(tableValues);
              getTableData(payload, products);
              callback({ scenarioName, products, simulation: payload });
            }
          }}
          onChange={(e) => {
            const { value } = e.target;
            if (value.match(/\./g)) {
              const [, decimal] = value.split('.');
              if (decimal?.length > (cellData.decimal ? cellData.decimal : 2)) {
                return;
              }
            }
            const newData = [...tableValues];
            newData[row][columnName].value = value;
            setTableValues(newData);
          }}
          style={{
            width: cellData.width ? cellData.width - 20 : 90,
          }}
          // InputProps={{ style: { fontSize: "14px" } }}
          inputProps={{
            step: cellData.step ? cellData.step : 1,
            lang: 'en-US',
            style: { fontSize: '14px' },
            autoComplete: 'off',
          }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
        />
      );
    }
  };

  const formatValues = (tableValues) => {
    const rows = [];
    tableValues.map((item) => {
      let row = {};
      Object.entries(item).map(([key, val]) => {
        row = {
          ...row,
          [key]: val.editable && val.type === 'Text' ? parseFloat(val.value) : val.value,
          ...(key === 'productName' ? { productInfo: val.productInfo } : {}),
        };
      });
      rows.push(row);
    });
    return rows;
  };

  const totalRow = () => {
    return (
      <>
        {/* <StyledTableCell>
         <div></div>
       </StyledTableCell> */}
        <StyledTableCell colSpan={!hideColumns ? 20 : 3} style={{ height: 40, marginLeft: 10, background: '#e8e8e8' }}>
          <div style={{ paddingLeft: 20 }}>Total</div>
        </StyledTableCell>
        {/* unitsVar column start */}
        <StyledNoBorderEmptyCell style={{ borderLeft: '1px solid #D3D3D3' }} />
        <StyledNoBorderEmptyCell />
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {unitsVar ? formatNumber(unitsVar?.toFixed(2)) : 0.0}
        </StyledNoBorderEmptyCell>
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {unitsPer ? unitsPer.toFixed(2) : 0.0}
        </StyledNoBorderEmptyCell>
        {/* unitsVar column start */}
        {/* volumeVar column start */}
        <StyledNoBorderEmptyCell style={{ borderLeft: '1px solid #D3D3D3' }} />
        <StyledNoBorderEmptyCell />
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {volumeVar ? formatNumber(volumeVar?.toFixed(2)) : 0.0}
        </StyledNoBorderEmptyCell>
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {volumePer ? volumePer.toFixed(2) : 0.0}
        </StyledNoBorderEmptyCell>
        {/* volumeVar column end */}
        {/* grossSalesVar column start */}
        <StyledNoBorderEmptyCell style={{ borderLeft: '1px solid #D3D3D3' }} />
        <StyledNoBorderEmptyCell />
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {grossSalesVar ? formatNumber(grossSalesVar?.toFixed(2)) : 0.0}
        </StyledNoBorderEmptyCell>
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {grossSalesPer ? grossSalesPer.toFixed(2) : 0.0}
        </StyledNoBorderEmptyCell>
        {/* grossSalesVar column end */}
        {/* grossProfitVar column start */}
        <StyledNoBorderEmptyCell style={{ borderLeft: '1px solid #D3D3D3' }} />
        <StyledNoBorderEmptyCell />
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {grossProfitVar ? formatNumber(grossProfitVar?.toFixed(2)) : 0.0}
        </StyledNoBorderEmptyCell>
        <StyledNoBorderEmptyCell align='center' style={{ fontWeight: 600 }}>
          {grossProfitPer ? grossProfitPer.toFixed(2) : 0.0}
        </StyledNoBorderEmptyCell>
        {/* <StyledTableCell
          colSpan={17}
          style={{ height: 40, marginLeft: 10, background: "#e8e8e8" }}
        ></StyledTableCell> */}
        {/* grossProfitVar column end */}
      </>
    );
  };

  return (
    <Grid sx={{ m: 2 }}>
      <Grid container spacing={2} className={'m-b-20'}>
        <Grid item xs={12} md={3} className={'m-l-10'}>
          <DropdownTitle style={{ fontSize: '1rem' }}>Scenario Name</DropdownTitle>
          {!filterSkeleton && !skeleton ? (
            <TextField
              InputProps={{ autoComplete: 'off' }}
              autoFocus
              id='visualName'
              placeholder='Enter a scenario name'
              type='text'
              value={tempScenarioName}
              variant='outlined'
              onChange={(e) => setTempScenarioName(e.target.value)}
              autoComplete='off'
              onBlur={onBlur}
              style={{ width: '100%' }}
            />
          ) : (
            <Skeleton variant='rectangular' height={45} />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <DropdownTitle style={{ fontSize: '1rem' }}>Products</DropdownTitle>
          {!filterSkeleton && !skeleton ? (
            <Dropdown
              data={filters && filters.permutation ? filters.permutation : []}
              onChange={onChangeDropdown}
              defaultOption={products}
              allOption={true}
              multiple={true}
            />
          ) : (
            <Skeleton variant='rectangular' height={45} />
          )}
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {!filterSkeleton && !skeleton ? (
              <Box display='flex' alignItems='center'>
                <Switch color={theme.darkmode ? 'primary' : 'secondary'} checked={hideColumns} onChange={onChangeHideColumns} />
                <Typography style={{ color: '#000' }}>Hide Columns</Typography>
              </Box>
            ) : (
              <Skeleton width={200} variant='rectangular' height={45} />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <StyledTableContainer
            isSidebarOpen={sidebarOpen}
            sx={{
              paddingBottom: 4,
              '&::-webkit-scrollbar': {
                width: 20,
                height: 8,
              },
            }}
          >
            <Table stickyHeader>
              {!tableSkeleton && !skeleton ? (
                <>
                  {tableValues?.length > 0 ? (
                    <>
                      <TableHead style={{ position: 'sticky', top: 0 }}>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              border: 'none',
                              background: theme.darkmode ? 'transparent' : '#fff',
                              width: 40,
                            }}
                            rowSpan={2}
                          >
                            <div style={{ width: 40 }}></div>
                          </StyledTableCell>
                          {tableHeading.map((item) => (
                            <StyledTableCell
                              className='b-color'
                              align='center'
                              style={{
                                color: 'white',
                                backgroundColor: item.color ? item.color : 'red',
                                height: 50,
                                width: item.width ? item.width : null,
                              }}
                              colSpan={item.colspan ? item.colspan : null}
                              rowSpan={item.rowspan ? item.rowspan : null}
                            >
                              <div
                                style={{
                                  width: item.width ? item.width : null,
                                }}
                              >
                                <Tooltip title={item.label}>
                                  <Grid
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {item.label}
                                  </Grid>
                                </Tooltip>
                              </div>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          {Object.keys(tableSubHeading).map((key) => {
                            const item = tableSubHeading[key];
                            return item.label ? (
                              <StyledTableCell
                                align='center'
                                data-testid='tableHeader'
                                style={{
                                  backgroundColor: item.color ? item.color : '#3cc6e6',
                                  color: 'white',
                                  whiteSpace: 'nowrap',
                                  height: 20,
                                  width: item.width ? item.width : null,
                                }}
                              >
                                <div
                                  style={{
                                    width: item.width ? item.width : null,
                                  }}
                                >
                                  <Tooltip title={item.label}>
                                    <Grid
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span className='ellipsis'>{item.label}</span>
                                    </Grid>
                                  </Tooltip>
                                </div>
                              </StyledTableCell>
                            ) : null;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            style={{
                              border: 'none',
                              position: 'absolute',
                              marginBottom: 20,
                            }}
                          >
                            <div style={{ width: 40 }}>
                              <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)} sx={{ zIndex: 99 }}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </div>
                          </StyledTableCell>
                          {!open ? totalRow() : null}
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={37} style={{ padding: 0, border: 'none' }}>
                            <Collapse in={open} timeout='auto' unmountOnExit>
                              {tableValues.map((row, i) => (
                                <StyledTableRow key={i}>
                                  <StyledTableCell
                                    style={{
                                      border: 'none',
                                      background: theme.darkmode ? 'transparent' : '#fff',
                                    }}
                                  >
                                    <div style={{ width: 40 }}></div>
                                  </StyledTableCell>
                                  {Object.keys(row).map((key, j) => {
                                    let cellData = row[key];
                                    return (
                                      <StyledTableCell
                                        align='center'
                                        rowSpan={cellData?.rowspan ? cellData.rowspan : null}
                                        colSpan={cellData.colspan ? cellData.colspan : null}
                                      >
                                        <div
                                          style={{
                                            width: cellData.width ? cellData.width : 100,
                                          }}
                                        >
                                          {_.isObject(cellData)
                                            ? cellData.editable
                                              ? renderControls(cellData, i, j, key)
                                              : cellData.image
                                              ? fetchImage(cellData.image, cellData.name)
                                              : `${formatNumber(cellData.value.toFixed(2))}${cellData.prepends ? cellData.prepend : ''}`
                                            : cellData}
                                        </div>
                                      </StyledTableCell>
                                    );
                                  })}
                                </StyledTableRow>
                              ))}
                            </Collapse>
                          </StyledTableCell>
                        </TableRow>
                        {open ? (
                          <TableRow>
                            <StyledTableCell style={{ border: 'none' }}>
                              <div style={{ width: 40 }}></div>
                            </StyledTableCell>
                            {totalRow()}
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </>
                  ) : (
                    <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                      <NoData>{messages.noData}</NoData>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      {tableHeading.map((item) => (
                        <StyledTableCell align='center' colSpan={item.colspan}>
                          <div style={{ width: item.width ? item.width : null }}>
                            <Skeleton height={50} />
                          </div>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(5).keys()].map((row, i) => (
                      <TableRow key={i}>
                        {!hideColumns
                          ? [...Array(35).keys()].map(() => (
                              <StyledTableCell align='center'>
                                <Skeleton height={30} />
                              </StyledTableCell>
                            ))
                          : [...Array(19).keys()].map(() => (
                              <StyledTableCell align='center'>
                                <Skeleton height={30} />
                              </StyledTableCell>
                            ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default VisualizationTable;
