import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import _ from "lodash";

const MeasureFilter: React.FC<{
  data;
  selectedGeoFilters;
  selectedMeasureFilters;
  callback;
}> = ({ data, selectedGeoFilters, selectedMeasureFilters, callback }) => {
  return (
    <Grid>
      <Grid container spacing={2} columns={16}>
        <Grid item xs={12} sm={4} key={`measures-dropdown-container-1`}>
          <DropdownTitle sx={{ mb: 2 }}>{data.benchMark.title}</DropdownTitle>
          <RadioButtonGroup
            key={`measures-filters-1`}
            color="#000"
            showButton={data.benchMark.showRadioButton}
            data={data.benchMark.options}
            defaultOption={selectedMeasureFilters.benchMark}
            select={(data) =>
              callback({ ...selectedMeasureFilters, benchMark: data })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MeasureFilter;
