export const tableHeading = [
  { title: "Country", key: "country" },
  { title: "Source Type", key: "sourceType" },
  { title: "Source Name", key: "sourceName" },
  { title: "Max Date", key: "maxDate" },
  { title: "Dashboard Use Date", key: "dashboardUsedDate" },
  { title: "Refresh Date", key: "refreshedDate" },
];

export const dataSourceTableHeading = [
  { title: "Country", key: "country" },
  { title: "Source Type", key: "sourceType" },
  { title: "Source Name", key: "sourceName" },
  { title: "Max Date", key: "maxDate" },
  { title: "Refresh Date", key: "refreshedDate" },
];

export const tableSkeletonData = [
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    dashboardUsedDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    dashboardUsedDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    dashboardUsedDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    dashboardUsedDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    dashboardUsedDate: "",
    refreshedDate: "",
  },
];

export const dataSourceTableSkeletonData = [
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    refreshedDate: "",
  },
  {
    country: "",
    sourceType: "",
    sourceName: "",
    maxDate: "",
    refreshedDate: "",
  },
];
