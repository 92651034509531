import React from 'react';
import { IconButton, Paper, InputBase } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from '../ScenarioLibrary/ScenarioLibrary.styles';


const InputSearch = ({handleInputChange,placeholder,isDisabled,searchIcon=true}) => {

    const classes = useStyles();

    return (
        <>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'block' }}
                style={{ width: '400px', borderRadius: '10px' }}
            >
                {searchIcon ? <IconButton type="button" className={classes.customIconButton}>
                    <SearchIcon />
                </IconButton>
                : <></>
                }
                <InputBase
                    sx={{ ml: 1, p: 2 }}
                    placeholder={placeholder}
                    inputProps={{ 'aria-label': 'Search for a scenario by name' }}
                    className={classes.inputBox}
                    onChange={handleInputChange}
                    disabled={isDisabled}
                />
            </Paper>

        </>
    )
}

export default InputSearch