import React, { useState } from "react";
import { CardContent, Grid, Popover } from "@mui/material";
import { BlackTextBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { StyledCard, Title, BoxBorder, GreyText, ColorPickerBox } from "../../MMPW.Styled";

import { IIconPickerType } from "../../../../types/common";
import ColorPicker from "../ColorPicker/ColorPicker";
import { convertSVGToBase64 } from "../../../../util/helper";
import { icons } from "../../config";

const IconPicker: React.FC<({callback})> = ({callback}) => {
  const [selectedIcon, setSelectedIcon] = useState<IIconPickerType>();
  const [fillColor, setFillColor] = useState("#000");
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddClick = async () => {
    if(selectedIcon){
      await convertSVGToBase64(selectedIcon.id).then((response: any) => {
        callback({ type: 'Icon', ...selectedIcon, color: fillColor, ...response})
      })
    }
  };

  const pickedFillColor = (fillColor) => {
    setFillColor(fillColor);
  };
  
  const colorPickerOpen = Boolean(anchorEl);
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title>System Icons</Title>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <BoxBorder>
                  {icons.map((i) => {
                    return (
                      <i.Icon 
                        fill={selectedIcon && selectedIcon.id === i.id && fillColor} 
                        key={i.id} 
                        id={i.id} 
                        className={"cursor-pointer m-r-5"} 
                        style={{
                          padding: 5,
                          borderRadius: 5,
                          background: selectedIcon && selectedIcon.id === i.id && '#EDF5FA', 
                          width: 30, 
                          height: 30
                        }}
                        onClick={()=> setSelectedIcon(i)}/>
                    )
                  })}
                </BoxBorder>
              </Grid>
              <Grid item xs={2}>
                <GreyText>Fill color</GreyText>
                <ColorPickerBox color={fillColor} onClick={handleClick} />
                <Popover
                  anchorEl={anchorEl}
                  open={colorPickerOpen}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ColorPicker color={fillColor} callback={pickedFillColor} />
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PrimaryBtn
              variant="contained"
              onClick={onAddClick}
              className="m-r-10"
            >
              Add
            </PrimaryBtn>
            <BlackTextBtn
              className="disable"
              onClick={() => {
                callback();
              }}
            >
              Cancel
            </BlackTextBtn>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default IconPicker;

