import { FETCH_MIX_SCENARIO_DATA, FETCH_SCENARIO_DATA, LOADER_STATE_SCENARIO_DATA } from "./constant";

export const fetchScenarioData = (payload) => ({
    type : FETCH_SCENARIO_DATA,
    payload
});

export const loaderForScenario = (payload) => ({
    type : LOADER_STATE_SCENARIO_DATA,
    payload
})

export const fetchMixScenarioData = (payload) => ({
    type : FETCH_MIX_SCENARIO_DATA,
    payload
});