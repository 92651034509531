import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import inflationIconDown from '../../../../assets/icons/macroEcon/Inflation_icon_down.svg';
import inflationIconUp from '../../../../assets/icons/macroEcon/Inflation_icon_up.svg';
import { Title } from '../../../../components/Dashboard/Dashboard.Styled';
import { dataFormat, separator } from '../../../../util/helper';

const useStyles = makeStyles(() => ({
  selected: { border: '3px solid #0388fc' },
}));

const MacroEconCard: React.FC<{ skeleton; data }> = ({ skeleton, data }) => {
  const classes = useStyles();
  return (
    <Box style={{ height: '100%', width: '400px' }}>
      {!skeleton ? (
        <Card style={{ position: 'relative', marginRight: '20px' }} className={data.selected ? classes.selected : ''}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <img src={data.percentDiff < 0 ? inflationIconDown : inflationIconUp} width='70' />
                <Grid container>
                  <Grid xs={12}>
                    <Title className='ellipsis' style={{ color: '#25d4cf', fontSize: '26px' }}>
                      {`${data.title} `}
                    </Title>
                  </Grid>
                  <Grid className='m-t-10' display='flex' alignItems='center'>
                    <Title
                      style={{
                        fontSize: '28px',
                        fontWeight: 'bold',
                      }}
                      className='m-r-10'
                    >
                      {data.percent !== null ? `${separator(data.percent.toFixed(2))}${data.variableUnit === '%' ? '%' : ''}` : 'NA'}
                    </Title>
                    <Title style={{ color: '#555A60', fontSize: '16px' }}>
                      {data.periodView ? data.periodView : 'Last Month'}
                    </Title>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='m-t-10'>
                <Grid container display='flex' alignItems='center'>
                  <Typography
                    style={{
                      color: data.percentDiff < 0 ? '#F22E0F' : '#008000',
                      fontSize: '28px',
                      fontWeight: 'bold',
                    }}
                    className='m-r-10'
                  >
                    {data.percentDiff !== null
                      ? `${data.percentDiff > 0 ? '+' : ''}${data.percentDiff.toFixed(2)} ${data.variableUnit === '%' ? 'PP' : '%'}`
                      : 'NA'}
                  </Typography>
                  <Typography style={{ fontSize: '14px', color: 'rgb(85, 90, 96)' }}>{data.currentView}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Title style={{ color: '#555A60', fontSize: '22px' }}>
                  Aggregation :{' '}
                  <span style={{ fontWeight: 'normal' }}>{data.aggregationType ? `${data.aggregationType}` : 'NA'}</span>
                </Title>
              </Grid>

              <Grid item xs={12} className='m-t-10'>
                <Title style={{ color: '#555A60', fontSize: '22px' }}>
                  Periodicity : <span style={{ fontWeight: 'normal' }}>{data.periodicity ? `${data.periodicity}` : 'NA'}</span>
                </Title>
              </Grid>

              <Grid item xs={12} className='m-t-10'>
                <Title style={{ color: '#555A60', fontSize: '22px' }}>
                  Last Refresh:{' '}
                  <span style={{ fontWeight: 'normal' }}>
                    {data.lastRefresh ? dataFormat(`${data.lastRefresh}-01T00:00:00`, 'MMM yyyy') : 'NA'}
                  </span>
                </Title>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <Skeleton variant='rectangular' height={400} />
        </Card>
      )}
    </Box>
  );
};

export default MacroEconCard;
