import { Grid, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  ThemeTitlePL,
  SrpSopPrice,
  SomBlk,
  SrpSopBlk,
  SrpSopBlueSec,
  SrpSopPinkSecRight,
  SrpSopBlueSecLeft,
  SopPinkList,
  SrpBlueList,
  SrpSopImageSec,
  SrpSopImageName,
  SrpSopContentSec,
  SrpSopContenttitle,
  toolTipButton,
  mainCompetitorSec,
  mainComLeftSec,
  mainComLeftSecInn,
  mainComSomBlk,
  mainComPriceProduct,
  mainComPriceBlk,
  mainComPriceBlkInner,
  pinkBg,
  mainComPriceProductRight,
  blueBg,
  mainComRightSec,
  mainComLogo,
  mainComLabel,
  mainComPriceInner,
  editIconPos,
  greenSmallText,
  redSmallText,
  graySmallText,
  inputMaxWidth,
  cursorMove,
} from '../priceLadder.Styled';
import { BlackTitle, OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import Typography from '@mui/material/Typography';
import VendorsList from '../VendorsList';
import filterMock from '../../../../mocks/priceLadderMock';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import Dropdown from '../../../../components/Dropdown';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { round } from 'lodash';
import { rendarImage, valueBasedOnColor } from '../../../../util/helper';

const PriceLadderMainCompetitor: React.FC<{
  filters;
  defaultFilters?;
  vendors?;
  MainCompetitorData?;
  setMainCompetitorData?;
}> = ({ filters, defaultFilters, vendors, MainCompetitorData, setMainCompetitorData }) => {
  const img_base_url = `${process.env.REACT_APP_BACKEND_BASE_URL}`;
  const [pepsiProducts, setPepsicoProducts] = React.useState(MainCompetitorData.pepsiProducts);
  const [competitorProducts, setCompetitorProducts] = React.useState(MainCompetitorData.competitorProducts);
  const [pcSelectedOptions, setPcSelectedOptions] = React.useState({});
  const [comFilterApplied, setComFilterApplied] = React.useState(false);
  const [dashboardURL, setDashboardURL] = React.useState('#');

  // Manage Api changes on srp value
  const [editSRP, setEditSRP] = React.useState(-1);
  const [srpValue, setSRP] = React.useState<number>(0);
  const setEditableSRP = (i, value) => {
    setEditSRP(i);
    setSRP(value ? value : 0);
  };
  const setSaveSRP = (i) => {
    setEditSRP(-1);
    pepsiProducts[i].srpsop = srpValue ? srpValue : 0;
    pepsiProducts[i].api?.map((apiItem) => {
      let ppvPepsi = parseFloat(srpValue) === 0 || parseFloat(pepsiProducts[i].gr) === 0 ? 0 : (srpValue / pepsiProducts[i].gr) * 1000;
      pepsiProducts[i].ppv = ppvPepsi;

      if (filters.apiFilter === 'kgs') {
        apiItem.value = parseFloat(ppvPepsi) === 0 || parseFloat(apiItem.ppv) === 0 ? 0 : ((ppvPepsi / apiItem.ppv) * 100).toFixed(2);
      } else {
        apiItem.value = parseFloat(srpValue) === 0 || parseFloat(apiItem.sop) === 0 ? 0 : ((srpValue / apiItem.sop) * 100).toFixed(2);
      }
    });
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };

  // Manage Api changes on pepsiCo GR
  const [editGrPepsi, setEditGrPepsi] = React.useState(-1);
  const [grPepsiValue, setGrPepsi] = React.useState(0);
  const setEditableGrPepsi = (i, value) => {
    setEditGrPepsi(i);
    setGrPepsi(value ? parseFloat(value).toFixed(2) : 0);
  };
  const setSaveGrPepsi = (i) => {
    setEditGrPepsi(-1);
    pepsiProducts[i].gr = grPepsiValue ? grPepsiValue : 0;
    pepsiProducts[i].api?.map((apiItem) => {
      let ppvPepsi =
        parseFloat(pepsiProducts[i].srpsop) === 0 || parseFloat(pepsiProducts[i].gr) === 0
          ? 0
          : (pepsiProducts[i].srpsop / pepsiProducts[i].gr) * 1000;

      pepsiProducts[i].ppv = ppvPepsi;
      if (filters.apiFilter === 'kgs') {
        apiItem.value = parseFloat(ppvPepsi) === 0 || parseFloat(apiItem.ppv) === 0 ? 0 : ((ppvPepsi / apiItem.ppv) * 100).toFixed(2);
      }
    });
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };

  //Manage api values on comp sop changes
  const [editSOP, setEditSOP] = React.useState('');
  const [sopValue, setSOP] = React.useState(0);
  const setEditableSOP = (i, icomp, brandsize) => {
    let tindex = i + '-' + icomp;
    setEditSOP(tindex);
    setSOP(getCompSop(i, brandsize));
  };
  const getCompSop = (i, brandsize) => {
    let indx = pepsiProducts[i].api.findIndex((item) => {
      return item.key === brandsize;
    });
    return pepsiProducts[i].api[indx].sop ? pepsiProducts[i].api[indx].sop : 0;
  };

  const setSaveSOP = (i, brandsize) => {
    setEditSOP(-1);
    let indx = pepsiProducts[i].api.findIndex((item) => {
      return item.key === brandsize;
    });
    pepsiProducts[i].api[indx].sop = sopValue ? sopValue : 0;
    if (filters.apiFilter === 'kgs') {
      let ppvComp =
        parseFloat(sopValue) === 0 || parseFloat(pepsiProducts[i].api[indx].gr) === 0
          ? 0
          : ((sopValue / pepsiProducts[i].api[indx].gr) * 1000).toFixed(2);
      pepsiProducts[i].api[indx].ppv = ppvComp;
      pepsiProducts[i].api[indx].value =
        parseFloat(pepsiProducts[i].ppv) === 0 || parseFloat(pepsiProducts[i].api[indx].ppv) === 0
          ? 0
          : round((pepsiProducts[i].ppv / pepsiProducts[i].api[indx].ppv) * 100);
    } else {
      pepsiProducts[i].api[indx].value =
        parseFloat(pepsiProducts[i].srpsop) === 0 || parseFloat(sopValue) === 0 ? 0 : round((pepsiProducts[i].srpsop / sopValue) * 100);
    }
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };

  //Manage api values on comp GR changes
  const [editGrComp, setEditGrComp] = React.useState('');
  const [GrCompValue, setGrComp] = React.useState<number>(0);
  const setEditableGrComp = (i, icomp, brandsize) => {
    let tindex = i + '-' + icomp;
    setEditGrComp(tindex);
    setGrComp(getCompGr(i, brandsize));
  };
  const getCompGr = (i, brandsize) => {
    let indx = pepsiProducts[i].api.findIndex((item) => {
      return item.key === brandsize;
    });
    return pepsiProducts[i].api[indx].gr ? parseFloat(pepsiProducts[i].api[indx].gr).toFixed(2) : 0;
  };

  const setSaveGrComp = (i, brandsize) => {
    setEditGrComp(-1);
    let indx = pepsiProducts[i].api.findIndex((item) => {
      return item.key === brandsize;
    });

    pepsiProducts[i].api[indx].gr = GrCompValue ? GrCompValue : 0;
    if (filters.apiFilter === 'kgs') {
      let ppvComp = !pepsiProducts[i].api[indx].sop || !GrCompValue ? 0 : ((pepsiProducts[i].api[indx].sop / GrCompValue) * 1000).toFixed(2);

      pepsiProducts[i].api[indx].ppv = ppvComp;
      pepsiProducts[i].api[indx].value =
        parseFloat(pepsiProducts[i].ppv) === 0 || parseFloat(ppvComp) === 0 ? 0 : round((pepsiProducts[i].ppv / parseFloat(ppvComp)) * 100);
    }
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };

  const onChangeHandlerPC = (data, i) => {    
    var key = 'key_' + i;
    var selected = pcSelectedOptions;
    if (data.length === 0) {
      delete pcSelectedOptions[key];
      setPcSelectedOptions({ ...pcSelectedOptions });
    } else {
      setPcSelectedOptions({ ...pcSelectedOptions, [key]: data });
      selected[key] = data;
    }
  };

  const [permutationOptions, setPermutationOptions] = useState([...filters.permutationOptions]);

  const applyFilter = () => {
    setComFilterApplied(true);
    setPepsicoProducts([...pepsiProducts]);
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });    
  };
  const resetFilter = async () => {
    setEditSRP(-1);
    setEditGrPepsi(-1);
    setEditSOP(-1);
    setEditGrComp(-1);
    setComFilterApplied(false);
    setPcSelectedOptions({});
    const pepsiProductsOrg = JSON.parse(localStorage.getItem('pepsiProducts'));
    const competitorProductsOrg = JSON.parse(localStorage.getItem('competitorProducts'));

    await Promise.all(
      pepsiProductsOrg.map(async (g, i) => {
        let img;
        img = await rendarImage(g.img.split('/')[g.img.split('/').length - 1], filters.country);
        g['imagBlob'] = img;
      })
    );
    await Promise.all(
      competitorProductsOrg.map(async (g, i) => {
        let img;
        img = await rendarImage(g.img.split('/')[g.img.split('/').length - 1], filters.country);
        g['imagBlob'] = img;
      })
    );
    setPepsicoProducts(pepsiProductsOrg);
    setCompetitorProducts(competitorProductsOrg);
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };
  const disabledApplyFilter = () => {
    if (pcSelectedOptions) {
      let tempLengthArr = Object.keys(pcSelectedOptions).map((key) => pcSelectedOptions[key].length);
      const isAllZero = tempLengthArr.every((item) => item === 0);
      return isAllZero;
    }
  };

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...pepsiProducts];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setPepsicoProducts(copyListItems);
    setMainCompetitorData({ pepsiProducts: [copyListItems], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  };

  //Handle missing images
  const handleMissingImages = (event) => {
    const brandName = event.target.nextElementSibling;
    event.target.style.display = 'none';
    brandName.style.display = 'block';
  };

  let poNotInComp = permutationOptions.filter((x) => !competitorProducts.find((y) => y.brandsize == x.value));
  let comTemp = [...competitorProducts];
  const pepTemp = [...pepsiProducts];
  if (poNotInComp.length > 0) {    
    poNotInComp?.map((x) => {
      comTemp.push({
        api: null,
        brandsize: x.value,
        gr: 0,
        img: x.image,
        ispepsico: false,
        name: x.brand || x.subBrand,
        packsize: x.packSize,
        ppv: 0,
        ppvpp: 0,
        som: 0,
        sop: 0,
        vendor: '',
      });

      pepTemp?.map((pp, idx) => {
        let Indx = pepTemp[idx].api.find((a) => a.key === x.value);
        if (Indx === undefined) {
          pepTemp[idx].api.push({ key: x.value, value: 0, gr: 0, sop: 0, som: 0, ppv: 0 });
        }
      });
    });
    setCompetitorProducts(comTemp);
    setMainCompetitorData({ pepsiProducts: [...pepsiProducts], competitorProducts: [...competitorProducts], pcSelectedOptions: pcSelectedOptions });
  }

  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      if (MainCompetitorData.pcSelectedOptions) {
        setPcSelectedOptions(MainCompetitorData.pcSelectedOptions);
      }
      setComFilterApplied(true);
      let search = window.location.search;
      let params = new URLSearchParams(search);
      const recordId = parseInt(params.get('id'));
      setDashboardURL('?id=' + recordId);
    }
  }, [defaultFilters]);

  return (
    <>
      <VendorsList vendors={vendors} />
      {/* MY design start */}
      <Grid style={mainCompetitorSec}>
        {/* Left blue section start */}
        <Grid style={mainComLeftSec}>
          <Grid container height={'100%'} direction='row' justifyContent='space-between' alignItems='stretch'>
            <Grid display={'flex'} direction='column' justifyContent='center' item xs={1} sm={1} className='srp_price' textAlign={'center'}>
              <Typography variant='subtitle2' style={{ fontSize: '18px' }}>
                SOM <br /> ({filterMock.measuresFilter.somUnit})
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} style={mainComPriceProduct}>
              <Grid style={mainComPriceBlk}>
                <Grid style={mainComPriceInner} height={'100%'} container direction='row' justifyContent='space-around' alignItems='center'>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='subtitle2' style={{ fontSize: '18px' }}>
                      SRP
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={mainComPriceBlk} flex={'6'}>
                <Grid container direction='row' height={100} justifyContent='space-between' alignItems='stretch' style={mainComPriceBlkInner}>
                  <Grid item xs={4} sm={4} display={'flex'} direction='column' justifyContent='center'></Grid>

                  <Grid item xs={8} height='100'>
                    <Grid container direction='row' height={'100%'} justifyContent='space-between' alignItems='center' xs={12} sm={12}>
                      <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography variant='subtitle2' style={{ fontSize: '18px' }}>
                          SOP
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} height={'100%'} display={'flex'} justifyContent='space-around' direction='column'>
                        <Typography variant='subtitle2' style={{ fontSize: '18px' }}>
                          API
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Left blue section end */}

        {/* ==== */}
        {/* Right pink section start */}
        <Grid style={mainComRightSec}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' style={mainComLeftSecInn}>
            <Grid item xs={10} sm={10}>
              <Grid
                item
                xs={12}
                sm={12}
                height={'inherit'}
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                style={mainComLeftSecInn}
              >
                <Grid item xs={10} sm={10} height={'inherit'} style={mainComPriceProductRight} className='progress_right'>
                  <Grid flex={'6'} className='m-r-5'></Grid>
                  <Grid flex={'1'} style={mainComPriceBlk}>
                    <Grid style={mainComPriceInner} container direction='row' justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12} sm={12} className='price_txt'>
                        <Typography variant='subtitle2' style={{ fontSize: '18px', textAlign: 'center' }}>
                          SOP
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2} sm={2} style={mainComSomBlk} className='som_blk'>
                  <Grid height={'100%'} container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12} sm={12} className='srp_price' textAlign={'center'}>
                      <Typography variant='subtitle2' style={{ fontSize: '17px' }}>
                        SOM <br />({filterMock.measuresFilter.somUnit})
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={2} className={'sel'} display={'flex'} alignItems={'center'}>
              <Typography variant='subtitle2' style={{ fontSize: '17px' }}>
                Permutation
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Right pink section end */}
        {/* ==== */}
      </Grid>
      {/* Top label section end */}

      {/* My Second Graph design start */}
      <Grid item xs={12} className='m-b-40'>
        {/* Dynamic */}
        {pepsiProducts?.map((p, i) => {
          if (p !== undefined) {
            let tempIndx = 0;
            return (
              <Grid
                style={{ ...mainCompetitorSec, ...cursorMove }}
                onDragStart={(e) => dragStart(e, i)}
                onDragEnter={(e) => dragEnter(e, i)}
                onDragEnd={drop}
                key={'item-row-' + i}
                draggable
              >
                {/* Left blue section start */}
                <Grid style={mainComLeftSec}>
                  <Grid container height={'100%'} direction='row' justifyContent='space-between' alignItems='stretch'>
                    <Grid display={'flex'} direction='column' justifyContent='center' item xs={1} sm={1} className='srp_price' textAlign={'center'}>
                      <BlackTitle style={{ fontWeight: '600' }}>{p.som}</BlackTitle>
                      {
                        <BlackTitle 
                      sx={{
                        fontWeight: 'normal',
                        fontSize: '12px',
                        color: valueBasedOnColor(parseFloat(p.ppvpp)),
                      }}>
                        {p.ppvpp > 0 ? '+' : ''}
                        {p.ppvpp.toFixed(2)} pp
                      </BlackTitle>
                      }
                    </Grid>
                    <Grid item xs={11} sm={11} style={mainComPriceProduct}>
                      <Grid m={blueBg} style={mainComPriceBlk}>
                        <Grid style={mainComPriceInner} height={'100%'} container direction='row' justifyContent='space-around' alignItems='center'>
                          <Grid m={editIconPos} item xs={12} sm={12}>
                            {editSRP == i ? (
                              <Link
                                to={dashboardURL}
                                onClick={() => {
                                  setSaveSRP(i);
                                }}
                              >
                                <SaveIcon sx={{ fontSize: 16, color: '#fff' }}></SaveIcon>
                              </Link>
                            ) : (
                              <Link
                                to={dashboardURL}
                                onClick={() => {
                                  setEditableSRP(i, p.srpsop);
                                }}
                              >
                                <RateReviewOutlinedIcon sx={{ fontSize: 16, color: '#fff' }}></RateReviewOutlinedIcon>
                              </Link>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            {editSRP == i ? (
                              <Typography m={inputMaxWidth}>
                                <TextField
                                  inputProps={{
                                    min: 0,
                                    step: 1,
                                    style: { padding: '3.1px 2px' },
                                    autoComplete: 'off',
                                  }}
                                  type='number'
                                  id='srp'
                                  //placeholder={p.srpsop}
                                  onChange={(e) => setSRP(e.target.value)}
                                  value={parseFloat(srpValue)}
                                />
                              </Typography>
                            ) : (
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>${p.srpsop}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid m={blueBg} style={mainComPriceBlk} flex={'6'}>
                        <Grid container direction='row' height={100} justifyContent='space-between' alignItems='center' style={mainComPriceBlkInner}>
                          <Grid item xs={4} sm={4} display={'flex'} direction='column' justifyContent='center'>
                            <Typography className='logo_icon'>
                              {p.imagBlob ? (
                                <img
                                  className='logo'
                                  style={mainComLogo}
                                  src={p.imagBlob}
                                  // onError={(event) => handleMissingImages(event)}
                                />
                              ) : (
                                <Typography>{p.name}</Typography>
                              )}
                            </Typography>
                            <Typography>{p.packsize}</Typography>
                          </Grid>
                          <Grid item xs={8} height='100%'>
                            <Grid container direction='row' height={'100%'} justifyContent='space-between' alignItems='center' xs={12} sm={12}>
                              <Grid item xs={4} sm={4}>
                                <Grid container direction='column' justifyContent='center' alignItems='center' position={'relative'}>
                                  <Grid height='100%' display={'flex'} justifyContent='center' alignItems='center' item xs={12} sm={12}>
                                    <Grid m={editIconPos} left={'30%'}>
                                      {editGrPepsi == i ? (
                                        <Link
                                          to={dashboardURL}
                                          onClick={() => {
                                            setSaveGrPepsi(i);
                                          }}
                                        >
                                          <SaveIcon sx={{ fontSize: 16, color: '#fff', position: 'absolute', bottom: '17px' }}></SaveIcon>
                                        </Link>
                                      ) : (
                                        <Link
                                          to={dashboardURL}
                                          onClick={() => {
                                            setEditableGrPepsi(i, p.gr);
                                          }}
                                        >
                                          <RateReviewOutlinedIcon
                                            sx={{ fontSize: 16, color: '#fff', position: 'absolute', bottom: '11px' }}
                                          ></RateReviewOutlinedIcon>
                                        </Link>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      {editGrPepsi == i ? (
                                        <Typography m={inputMaxWidth}>
                                          <TextField
                                            inputProps={{
                                              min: 0,
                                              step: Number.isInteger(grPepsiValue) ? 1 : 0.01,
                                              style: { padding: '3px 5px' },
                                              autoComplete: 'off',
                                            }}
                                            type='number'
                                            id='gr-pepsi-value'
                                            onChange={(e) => setGrPepsi(e.target.value)}
                                            value={parseFloat(grPepsiValue)}
                                          />
                                        </Typography>
                                      ) : (
                                        <Typography>{parseFloat(p.gr).toFixed(2)} GR</Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <Typography>${p.sop}</Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} height={'100%'} display={'flex'} justifyContent='space-around' direction='column'>
                                {comFilterApplied &&
                                  pcSelectedOptions &&
                                  pcSelectedOptions['key_' + p.brandsize] &&
                                  p.api?.map((item) => {
                                    let ifExists = pcSelectedOptions['key_' + p.brandsize].find((x) => x == item.key);
                                    let apiVal = ifExists ? parseFloat(item.value) : null;
                                    return <>{apiVal !== null && <Typography>{round(apiVal)}</Typography>}</>;
                                  })}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Left blue section end */}

                {/* Right pink section start */}
                <Grid style={mainComRightSec}>
                  <Grid container direction='row' justifyContent='space-between' alignItems='center' style={mainComLeftSecInn}>
                    <Grid item xs={10} sm={10}>
                      {
                        // IF products

                        comFilterApplied &&
                          pcSelectedOptions &&
                          pcSelectedOptions['key_' + p.brandsize] != undefined &&
                          competitorProducts?.map((compItem, compI) => {
                            let ifShowRow =
                              pcSelectedOptions &&
                              pcSelectedOptions['key_' + p.brandsize].length > 0 &&
                              pcSelectedOptions['key_' + p.brandsize].indexOf(compItem.brandsize) !== -1
                                ? true
                                : false;
                            tempIndx = ifShowRow ? ++tempIndx : tempIndx;

                            return ifShowRow ? (
                              <Grid
                                key={'competitor-products-' + compI}
                                item
                                xs={12}
                                sm={12}
                                height='inherit'
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                style={mainComLeftSecInn}
                                className={pcSelectedOptions['key_' + p.brandsize].length === tempIndx ? 'm-b-0' : 'm-b-5'}
                              >
                                <Grid item xs={10} sm={10} style={mainComPriceProductRight} className='progress_right'>
                                  <Grid m={pinkBg} flex={'6'} className='m-r-5'>
                                    <Grid container direction='row' justifyContent='space-between' alignItems='center' height={'100%'}>
                                      <Grid item xs={4} sm={4}>
                                        <Typography className='logo_icon'>
                                          {compItem.imagBlob ? (
                                            <img
                                              style={mainComLogo}
                                              className='logo'
                                              src={compItem.imagBlob}
                                              // onError={(event) => handleMissingImages(event)}
                                            />
                                          ) : (
                                            <Typography>{compItem.name}</Typography>
                                          )}
                                        </Typography>
                                        <Typography>{compItem.packsize}</Typography>
                                      </Grid>
                                      <Grid item xs={8} sm={8} height='100%' display={'flex'} justifyContent='center' alignItems='center'>
                                        <Grid
                                          container
                                          height='100%'
                                          direction='column'
                                          justifyContent='space-between'
                                          alignItems='center'
                                          position={'relative'}
                                        >
                                          <Grid height='100%' display={'flex'} justifyContent='center' alignItems='center' item xs={12} sm={12}>
                                            <Grid m={editIconPos} left={'20%'} item xs={12} sm={12} className='edit_save_blk'>
                                              {editGrComp === i + '-' + compI ? (
                                                <Link
                                                  to={dashboardURL}
                                                  onClick={() => {
                                                    setSaveGrComp(i, compItem.brandsize);
                                                  }}
                                                >
                                                  <SaveIcon sx={{ fontSize: 16, color: '#fff', position: 'absolute', top: '-8px' }}></SaveIcon>
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={dashboardURL}
                                                  onClick={() => {
                                                    setEditableGrComp(i, compI, compItem.brandsize);
                                                  }}
                                                >
                                                  <RateReviewOutlinedIcon
                                                    sx={{ fontSize: 16, color: '#fff', position: 'absolute' }}
                                                  ></RateReviewOutlinedIcon>
                                                </Link>
                                              )}
                                            </Grid>
                                            <Grid display={'flex'} height={'100%'} justifyContent='center' alignItems='center' item xs={12} sm={12}>
                                              {editGrComp === i + '-' + compI ? (
                                                <Typography m={inputMaxWidth}>
                                                  <TextField
                                                    inputProps={{
                                                      min: 0,
                                                      step: Number.isInteger(GrCompValue) ? 1 : 0.01,
                                                      style: { padding: '3.1px 2px' },
                                                      autoComplete: 'off',
                                                    }}
                                                    id='gr-comp-value'
                                                    type='number'
                                                    onChange={(e) => setGrComp(parseFloat(e.target.value))}
                                                    value={GrCompValue}
                                                  />
                                                </Typography>
                                              ) : (
                                                <Typography>{getCompGr(i, compItem.brandsize)} GR</Typography>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid m={pinkBg} flex={'1'} style={mainComPriceBlk}>
                                    <Grid
                                      style={mainComPriceInner}
                                      container
                                      height={'100%'}
                                      direction='row'
                                      justifyContent='space-between'
                                      alignItems='center'
                                    >
                                      <Grid m={editIconPos} item xs={12} sm={12} className='edit_save_blk'>
                                        {editSOP === i + '-' + compI ? (
                                          <Link
                                            to={dashboardURL}
                                            onClick={() => {
                                              setSaveSOP(i, compItem.brandsize);
                                            }}
                                          >
                                            <SaveIcon sx={{ fontSize: 16, color: '#fff' }}></SaveIcon>
                                          </Link>
                                        ) : (
                                          <Link
                                            to={dashboardURL}
                                            onClick={() => {
                                              setEditableSOP(i, compI, compItem.brandsize);
                                            }}
                                          >
                                            <RateReviewOutlinedIcon sx={{ fontSize: 16, color: '#fff' }}></RateReviewOutlinedIcon>
                                          </Link>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={12} className='price_txt'>
                                        {editSOP === i + '-' + compI ? (
                                          <Typography m={inputMaxWidth}>
                                            <TextField
                                              inputProps={{
                                                min: 0,
                                                step: Number.isInteger(sopValue) ? 1 : 0.01,
                                                style: { padding: '3.1px 2px' },
                                                autoComplete: 'off',
                                              }}
                                              id='sop-value'
                                              type='number'
                                              onChange={(e) => setSOP(e.target.value)}
                                              value={parseFloat(sopValue)}
                                            />
                                          </Typography>
                                        ) : (
                                          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                            ${getCompSop(i, compItem.brandsize)}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={2} sm={2} style={mainComSomBlk} className='som_blk'>
                                  <Grid height={'100%'} container direction='row' justifyContent='space-between' alignItems='center'>
                                    <Grid item xs={12} sm={12} className='srp_price' textAlign={'center'}>
                                      <BlackTitle style={{ fontWeight: '600' }}>{compItem.som}</BlackTitle>
                                      <BlackTitle 
                                        sx={{
                                          fontWeight: 'normal',
                                          fontSize: '12px',
                                          color: valueBasedOnColor(parseFloat(compItem.ppvpp)),
                                        }}>
                                          {compItem.ppvpp > 0 ? '+' : ''}
                                          {compItem.ppvpp.toFixed(2)} pp
                                        </BlackTitle>
                                        
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              ''
                            );
                          })
                      }
                    </Grid>

                    {
                      // IF No products
                      (!comFilterApplied || (pcSelectedOptions && pcSelectedOptions['key_' + p.brandsize] == undefined)) && (
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          container
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          style={mainComLeftSecInn}
                          className='m-b-0'
                        >
                          <Grid item xs={10} sm={10} style={mainComPriceProductRight} className='progress_right' minHeight='100px'>
                            <Grid m={pinkBg} flex={'6'} className='m-r-5'>
                              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                                <Grid item xs={4} sm={4}>
                                  <Typography className='logo_icon'></Typography>
                                  <Typography></Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <Typography></Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid m={pinkBg} flex={'1'} style={mainComPriceBlk}>
                              <Grid style={mainComPriceInner} container direction='row' justifyContent='space-between' alignItems='center'>
                                <Grid item xs={12} sm={12} className='price_txt'>
                                  <Typography></Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={2} sm={2} style={mainComSomBlk} className='som_blk'>
                            <Grid height={'100%'} container direction='row' justifyContent='space-between' alignItems='center'>
                              <Grid item xs={12} sm={12} className='srp_price' textAlign={'center'}>
                                {/* som column */}
                                <BlackTitle>0</BlackTitle>
                                <Typography></Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid item xs={2} sm={2} className={'sel'} display={'flex'} alignItems={'center'}>
                      <Dropdown
                        data={permutationOptions}
                        onChange={(data) => {
                          onChangeHandlerPC(data, p.brandsize);
                        }}
                        defaultOption={(pcSelectedOptions && pcSelectedOptions['key_' + p.brandsize]) || ['empty']}
                        placeholder={'Select'}
                        multiple={true}
                        allOption={false}
                        selectionLimit={3}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Right pink section end */}
              </Grid>
            );
          }
        })}
      </Grid>
      {/* My Second Graph design end */}

      {/* Filter section start */}
      <Grid item xs={12} display='flex' justifyContent='flex-end'>
        <Stack direction='row' spacing={1}>
          <OrangeBtn onClick={resetFilter}>Reset Filter</OrangeBtn>
          <PrimaryBtn disabled={disabledApplyFilter()} onClick={applyFilter}>
            Apply Filter
          </PrimaryBtn>
        </Stack>
      </Grid>
      {/* Filter section end */}
    </>
  );
};

export default PriceLadderMainCompetitor;
