import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    customIconButton: {
        padding: '5px'
    },
    inputBox: {
        width: '350px',
        padding: '2px'
    },
    gridContainer: {
        marginTop: '10px',
        marginBottom: '12px'
    },
    customPadding : {
        padding : '2rem'
    }
});

export const styleForScenarioDataGrid = {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
        '&:focus': {
            outline: 'none !important'
        }
    },
    '.MuiDataGrid-columnHeaders': {
        '&:focus': {
            outline: 'none !important'
        },
        background: '#f1f5fe !important',
        borderBottom: '3px solid #dfdfdf',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    '.MuiDataGrid-cell': {
        '&:focus': {
            outline: 'none !important'
        },
        borderColor: '#dfdfdf',
    },
    '.MuiDataGrid-cellContent': {
        textWrap: 'balance',
        textAlign: 'center'
    },
    '.MuiDataGrid-overlay': {
        backgroundColor: '#fff'
    },
    ".scenarios-cell" : {
        fontWeight : 600,
        fontSize : '1rem'
    }
}