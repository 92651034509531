import React, { useEffect, useState } from 'react';
import { pageList } from '../../mocks/common';
import DashboardActions from '../../components/DashboardActions';
import TopFilters from './components/TopFilters';
import VisualizationContainer from './components/VisualizationContainer';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';
const PriceSettingTool: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [tableFilters, setTableFilters] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState({
    global: {},
    local: [],
  });
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);

  const onApplyFilter = (filters) => {
    setDisableDownloadButton(false);
    setAppliedFilters(filters);
    setDefaultFilters({
      global: {},
      local: [],
    });
  };
  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      //use this after api development
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters);
      setAppliedFilters(savedDashboardData?.filters?.global);
    }
  };

  const reduxState = useSelector((state: any) => state);

  return (
    <DashboardActions
      title='Price Setting Tool'
      data={{
        filters: {
          global: { ...appliedFilters },
          local: [...tableFilters],
        },
      }}
      dashboardKey={pageList[12].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={disableDownloadButton || reduxState.PriceSettingTool.priceSettingToolDataLoader}
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[14].value} />
      </Grid>
      <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters?.global} />
      <VisualizationContainer filters={appliedFilters} defaultFilters={defaultFilters?.local} callback={setTableFilters} />
      <DashboardSourceList category={appliedFilters.category} dashboardName={pageList[12].id} appliedFilters={appliedFilters} />
    </DashboardActions>
  );
};
export default PriceSettingTool;
