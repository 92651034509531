import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { formatNumber, separator } from "../../../../../../util/helper";

const points = 5;

const ScaleSlider: React.FC<{
  data;
  callback;
  key;
  benchMarkValues;
}> = ({ data, callback, key, benchMarkValues }) => {
  const diff = data.maxValue - data.minValue;
  let step = diff === 0 ? 1 : diff / points;
  const marks = [];
  for (let i = 0; i <= points; i++) {
    const previousStep =
      marks.length > 0 ? marks[i - 1].value + step : data.minValue;
    marks.push({
      value: previousStep,
      label: `${formatNumber(previousStep)}`,
    });
  }

  step = Math.round(diff) === 0 ? 0.01 : 0.1;

  return (
    <Grid container spacing={2} style={{ width: "100%" }}>
      <Grid item md={2}>
        <Box sx={{ mt: 5, fontWeight: 700 }}>
          {separator(benchMarkValues.toFixed(2))}
        </Box>
      </Grid>
      <Grid item md={8}>
        <Box sx={{ width: "90%", mt: 2, mb: 4 }} key={key}>
          <Tooltip title={data.label}>
            <Typography style={{ fontSize: "14px" }} className="ellipsis">
              {data.label}
            </Typography>
          </Tooltip>
          <Slider
            aria-label="Small steps"
            defaultValue={data.defaultValue.toFixed(2)}
            step={step}
            marks={marks}
            min={data.minValue}
            max={data.maxValue}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => {
              return formatNumber(value.toFixed(2));
            }}
            onChangeCommitted={(_, val) => {
              callback(val);
            }}
          />
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box sx={{ mt: 5, fontWeight: 700 }}>
          {data.lastUpdatedValue.toFixed(2)}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScaleSlider;
