const countries = [
  {
    label: "ARGENTINA",
    value: "ARGENTINA",
  },
  {
    label: "COLOMBIA",
    value: "COLOMBIA",
  },
  {
    label: "BRAZIL",
    value: "BRAZIL",
  },
  {
    label: "MEXICO",
    value: "MEXICO",
  },
  {
    label: "CHILE",
    value: "CHILE",
  },
  {
    label: "GUATEMALA",
    value: "GUATEMALA",
  },
];

const anchorFilters = {
  anchorCategory: {
    title: `Category `,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorSegment",
    defaultSelectAll: false,
  },
  anchorSegment: {
    title: `Segment `,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorBrand",
    defaultSelectAll: false,
  },
  anchorBrand: {
    title: `Brand `,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorSubBrand",
    defaultSelectAll: false,
  },
  anchorSubBrand: {
    title: `Sub Brand `,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorPackSize",
    defaultSelectAll: false,
  },
  anchorPackSize: {
    title: `Pack Size `,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    last: true,
  },
};
const exportObject = {
  countries,
  anchorFilters
};

export default exportObject;
