import _ from "lodash";
import { formatNumber } from "../../util/helper";

const correlationGraphConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 1,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: "#598ee3",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      color: "#d41165",
      data: [],
      yAxis: 1,
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const APIOptimizationChartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "scatter",
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  title: {
    text: "",
  },
  xAxis: {
    title: {
      enabled: true,
      text: "API",
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    lineWidth: 1,
    gridLineWidth: 1,
    title: {
      margin: 30,
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    labels: {
      formatter: function () {
        return _.isNumber(this.axis.defaultLabelFormatter.call(this))
          ? formatNumber(this.axis.defaultLabelFormatter.call(this).toFixed(2))
          : this.axis.defaultLabelFormatter.call(this);
      },
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  series: [
    {
      color: "rgba(223, 83, 83, .5)",
      data: [],
    },
  ],
};

const G5ChartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    gridLineWidth: 1,
    lineColor: "transparent",
    categories: [],
  },
  yAxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function () {
        return _.isNumber(this.axis.defaultLabelFormatter.call(this))
          ? formatNumber(this.axis.defaultLabelFormatter.call(this).toFixed(2))
          : this.axis.defaultLabelFormatter.call(this);
      },
    },
  },
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 2,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      name: "Real Volume",
      color: "#598ee3",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: "Est Volume",
      color: "#d41165",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const MultipleAPIChartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "area",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    title: {
      text: "",
    },
    gridLineWidth: 0,
    lineColor: "transparent",
    categories: [],
  },

  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  series: [],
};

const PepsiVsNonCompetitorChartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      margin: 30,
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    {
      margin: 30,
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 1,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      name: "SOM",
      color: "#598ee3",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: "API",
      color: "#d41165",
      data: [],
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const APISimulationGraphConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      margin: 30,
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
    {
      margin: 30,
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return _.isNumber(this.axis.defaultLabelFormatter.call(this))
            ? formatNumber(
                this.axis.defaultLabelFormatter.call(this).toFixed(2)
              )
            : this.axis.defaultLabelFormatter.call(this);
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 1,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      name: "Current API",
      color: "#598ee3",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: "Currenent Units",
      color: "#d41165",
      data: [],
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: "Proposed API",
      color: "green",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: "var2",
      color: "yellow",
      data: [],
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export {
  correlationGraphConfig,
  APIOptimizationChartConfig,
  G5ChartConfig,
  MultipleAPIChartConfig,
  PepsiVsNonCompetitorChartConfig,
  APISimulationGraphConfig,
};
