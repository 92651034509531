const KPIs = {
  grossSale: {
    title: "Gross Sales",
  },
  volumes: {
    title: "Volume(KGS)",
  },
  grossProfit: {
    title: "Gross Profit",
  },
  shareOfMarket: {
    title: "Share Of Market",
  },
};

const measuresFilter = {
  measuresLevel: {
    title: "Tier/Mix",
    options: [
      { id: "1", label: "Default", value: "default" },
      { id: "2", label: "Customize", value: "customize" },
    ],
    defaultOption: { id: "1", value: "default" },
    showRadioButton: true,
  },
  somGroup: {
    title: "SOM Variable Configuration",
    options: [
      { id: "1", value: "Sales" },
      { id: "2", value: "Volume" },
      // { id: "3", value: "Units" },
    ],
    defaultOption: { id: "1", value: "Sales" },
    showRadioButton: true,
  },
  somMeasure: {
    title: "Anchor Comparison Level",
    options: [
      { id: "1", label: "Category", value: "category" },
      { id: "2", label: "Segment", value: "segment" },
    ],
    defaultOption: { id: "1", value: "category" },
    showRadioButton: true,
  },
  monthRange: {
    title: "Select Range of Month",
    options: [
      { id: "1", label: "3 Months", value: "3" },
      { id: "2", label: "6 Months", value: "6" },
      { id: "3", label: "12 Months", value: "12" },
    ],
    defaultOption: { id: "1", value: "3" },
    showRadioButton: true,
  },
};

const comparisonFilter = {
  anchorProductComparision: {
    title: "Anchor Comparison Level",
    options: [
      { id: "1", label: "Product (Brand/Brand Pack Size)", value: "product" },
      { id: "2", label: "Segment", value: "segment" },
    ],
    defaultOption: { id: "1", value: "product" },
    showRadioButton: true,
  },
  data: {
    title: "Data",
    options: [
      { id: "1", label: "Sell In Data", value: "SELL-IN" },
      { id: "2", label: "Sell Out Data", value: "SELL-OUT" },
    ],
    defaultOption: { id: "1", value: "SELL-IN" },
    showRadioButton: true,
  },
  comparisonLevel: {
    title: "Comparison Level",
    options: [
      { id: "1", label: "Brand", value: "brand" },
      { id: "2", label: "Brand Pack Size", value: "brandPackSize" }
    ],
    mexicoOptions: [
      { id: "1", label: "Brand", value: "brand" },
      { id: "2", label: "Brand Pack Size", value: "brandPackSize" },
      { id: "3", label: "Sub-Brand", value: "subBrand" },
      { id: "4", label: "Sub-Brand Pack Size", value: "subBrandPackSize" }
    ],
    defaultOption: { id: "1", value: "brand" }
  },
  productFilterTitle: {
    title: "Product Filters",
  },
};

const anchorFilter = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "Select",
    first: true,
    all: true,
    multiple: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorSubBrand",
  },
  anchorSubBrand: {
    title: "Anchor Sub-brand",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "anchorPackSize",
  },
  anchorPackSize: {
    title: "Anchor Pack-size",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    last: true,
  },
};

const productFilterBrand = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const productFilterBrandPackSize = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const productFilterSubBrand = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
  },
  subBrand: {
      title: `Sub Brand <span style="color: #858c94;">(Pepsico)</span>`,
      options: [],
      placeholder: "Select",
      all: true,
      multiple: true,
      children: "permutation"
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const productFilterSubBrandPackSize = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
  },
  subBrand: {
      title: `Sub Brand <span style="color: #858c94;">(Pepsico)</span>`,
      options: [],
      placeholder: "Select",
      all: true,
      multiple: true,
      children: "packSize"
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const competitorFilterBrand = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "category",
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const competitorFilterBrandPackSize = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "category",
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const competitorFilterSubBrand = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "category",
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
  },
  subBrand: {
      title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
      options: [],
      placeholder: "Select",
      all: true,
      multiple: true,
      children: "permutation"
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const competitorFilterSubBrandPackSize = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "category",
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
  },
  subBrand: {
      title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
      options: [],
      placeholder: "Select",
      all: true,
      multiple: true,
      children: "packSize"
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    multiple: true,
    last: true,
    all: true,
  },
};

const simulationTableHeadSellIn = {
  heading: [
    { title: "Brand", value: "productName", image: true },
    { title: "Baseline PPV Current", value: "ppvCurrent" },
    {
      title: "Baseline PPV Simulation",
      value: "ppvSimulated",
      editable: true,
      type: "Text",
      width: 115,
    },
    {
      title: "Baseline PPV Elasticity ",
      value: "ppvElasticity",
      editable: true,
      type: "Text",
      width: 115,
      padding: "0px 23px",
    },
    { title: "Current Gross Sales", value: "currentGrossSale" },
    { title: "Simulated Gross Sales", value: "simulatedGrossSale" },
    { title: "Current Volume", value: "currentVolume" },
    { title: "Simulated Volume", value: "simulatedVolume" },
    { title: "Current Gross Profit", value: "currentGrossProfit" },
    { title: "Simulated Gross Profit", value: "simulatedGrossProfit" },
  ],
  value: [],
};

const simulationTableHeadSellOut = {
  heading: [
    { title: "Brand", value: "productName", image: true },
    { title: "Baseline PPV Current", value: "ppvCurrent" },
    {
      title: "Baseline PPV Simulation",
      value: "ppvSimulated",
      editable: true,
      type: "Text",
      width: 115,
    },
    {
      title: "Baseline PPV Elasticity",
      value: "ppvElasticity",
      editable: true,
      type: "Text",
      width: 115,
      padding: "0px 23px",
    },
    { title: "Current Gross Sales", value: "currentGrossSale" },
    { title: "Simulated Gross Sales", value: "simulatedGrossSale" },
    { title: "Current Volume", value: "currentVolume" },
    { title: "Simulated Volume", value: "simulatedVolume" },
    { title: "Current Share Of Market %", value: "currentMarketShare" },
    { title: "Simulated Share Of Market %", value: "simulatedMarketShare" },
  ],
  value: [],
};

const simulationResponseDataSellIn = [
  {
    productName: "Sabritas",
    ppvCurrent: 87878.33,
    ppvSimulated: 34334.33,
    ppvElasticity: 212121.121,
    currentGrossSale: 5444.121,
    simulatedGrossSale: 3333.121,
    currentVolume: 7777.121,
    simulatedVolume: 8888.121,
    currentGrossProfit: 9999.121,
    simulatedGrossProfit: 5555.121,
  },
  {
    productName: "Doritas",
    ppvCurrent: 6565.33,
    ppvSimulated: 34334.33,
    ppvElasticity: 212121.121,
    currentGrossSale: 5444.121,
    simulatedGrossSale: 3333.121,
    currentVolume: 7777.121,
    simulatedVolume: 8888.121,
    currentGrossProfit: 9999.121,
    simulatedGrossProfit: 5555.121,
  },
];

const pepsico = {
  vendor: ["PEPSICO"],
  category: "SAV-SALTY",
  segment: "SAV-POTATO",
  brand: ["SABRITAS PC", "RUFFLES", "RECETA CRUIENTE", "STAX"],
  subBrand: null,
  packSize: null,
  permutation: ["SABRITAS PC", "RUFFLES", "RECETA CRUIENTE", "STAX"],
};

const competetor = {
  vendor: ["BIMBO"],
  category: "SAV-SALTY",
  segment: "SAV-POTATO",
  brand: ["BARCEL CHIPS", "LEO PAPAS", "PRINGLES REGULAR", "SOL POTATO"],
  subBrand: null,
  packSize: null,
  permutation: ["BARCEL CHIPS", "LEO PAPAS", "PRINGLES REGULAR", "SOL POTATO"],
};

const KPIDefaultData = [
  { title: "Gross Sales" },
  { title: "Volume(KGS)" },
  { title: "Gross Profit" },
  { title: "Share Of Market" },
];

const exportObject = {
  comparisonFilter,
  anchorFilter,
  measuresFilter,
  simulationTableHeadSellIn,
  simulationTableHeadSellOut,
  simulationResponseDataSellIn,
  pepsico,
  competetor,
  KPIDefaultData,
  productFilterBrand,
  productFilterBrandPackSize,
  productFilterSubBrand,
  productFilterSubBrandPackSize,
  competitorFilterBrand,
  competitorFilterBrandPackSize,
  competitorFilterSubBrand,
  competitorFilterSubBrandPackSize
};
export { KPIs };
export default exportObject;
