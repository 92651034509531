export const GET_PORTFOLIO_DATA = "GET_PORTFOLIO_DATA";
export const GET_GEO_LEVEL_SUMMARY_DATA = "GET_GEO_LEVEL_SUMMARY_DATA";
export const RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA =
  "RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA";
export const LOADER_STATE_PORTFOLIO = "LOADER_STATE_PORTFOLIO";
export const LOADER_STATE_GEO_LEVEL = "LOADER_STATE_GEO_LEVEL";
export const ERROE_STATE_PORTFOLIO = "ERROE_STATE_PORTFOLIO";
export const ERROR_STATE_GEO_LEVEL = "ERROR_STATE_GEO_LEVEL";
export const SET_PORTFOLIO_LEVEL = "SET_PORTFOLIO_LEVEL";
export const SET_GEO_LEVEL = "SET_GEO_LEVEL";
