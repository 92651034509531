import { graph1, graph2, graph3, graph4 } from '../assets/images/dashboard';
import { GraphCardType } from '../types/common';

const primaryFilter = {
  country: {
    title: 'Country',
    options: [],
  },
  geoLevel: {
    title: 'Geo-Level',
    options: [],
    single: true,
    placeholder: 'Select',
    all: false,
    multiple: false,
  },
  region: {
    title: 'Region',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
  },
  city: {
    title: 'City',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
  },
  channel: {
    title: 'Channel',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
  },
  subChannel: {
    title: 'Sub Channel',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
  },
  keyAccounts: {
    title: 'Key Accounts',
    options: [],
    placeholder: 'Null',
    all: false,
    multiple: false,
  },
};

// const anchorFilter = {
//   category: {
//     title: "Anchor Category <span class='text-muted'>(PepsiCo)</span>",
//     options: [],
//     placeholder: 'Select',
//   },
//   segment: {
//     title: "Anchor Segment <span class='text-muted'>(PepsiCo)</span>",
//     options: [],
//     placeholder: 'Select',
//   },
//   brand: {
//     title: "Anchor Brand <span class='text-muted'>(PepsiCo)</span>",
//     options: [],
//     placeholder: 'Select',
//   },
//   subBrand: {
//     title: "Anchor Sub-brand <span class='text-muted'>(PepsiCo)</span>",
//     options: [],
//     placeholder: 'Select',
//   },
//   packSize: {
//     title: "Anchor Pack-size <span class='text-muted'>(PepsiCo)</span>",
//     options: [],
//     placeholder: 'Select',
//   },
// };

const anchorFilter = {
  anchorCategory: {
    title: 'Anchor Category',
    options: [],
    placeholder: 'Select',
    first: true,
    children: 'anchorSegment',
  },
  anchorSegment: {
    title: 'Anchor Segment',
    options: [],
    placeholder: 'Select',
    children: 'anchorBrand',
  },
  anchorBrand: {
    title: 'Anchor Brand',
    options: [],
    placeholder: 'Select',
    children: 'anchorSubBrand',
  },
  anchorSubBrand: {
    title: 'Anchor Sub-brand',
    options: [],
    placeholder: 'Select',
    children: 'anchorPackSize',
  },
  anchorPackSize: {
    title: 'Anchor Pack-size',
    options: [],
    placeholder: 'Select',
    last: true,
  },
};

const anchorFiltersBrand = {
  anchorCategory: {
    title: 'Anchor Category',
    options: [],
    placeholder: 'Select',
    first: true,
    children: 'anchorSegment',
  },
  anchorSegment: {
    title: 'Anchor Segment',
    options: [],
    placeholder: 'Select',
    children: 'anchorBrand',
  },
  anchorBrand: {
    title: 'Anchor Brand',
    options: [],
    placeholder: 'Select',
    last: true,
  },
};

const buisnessPieChart = [
  ['Price Elasticity', 650],
  ['Cross Price Elasticity', 80],
  ['Distribution', 20],
  ['Covid Cases', 150],
  ['Key Events', 10],
  ['Gas Prices', 100],
];
const buissnessImpactColumnRangeXAxisData = [
  '2017',
  'Price Elasticity',
  'Gas Prices',
  'Key Events',
  'Distribution',
  'Cross Price Elasticity',
  'Covid Cases',
  '2018',
];

const buissnessImpactColumnRangeData = [
  {
    data: [
      [0, 14000],
      [14000, 18000],
      [18000, 20000],
      [20000, 21000],
      [21000, 21500],
      [21500, 22000],
      [20000, 21000],
      [0, 22000],
    ],
  },
];

const pepsiProducts = [
  {
    productName: 'Ruffles 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-03T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2022-08-10T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2022-08-11T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-08T07:02:46.073Z',
        value: 20,
      },
    ],
  },
  {
    productName: 'Packetaxo 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2022-08-11T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-08T07:02:46.073Z',
        value: 20,
      },
    ],
  },
  {
    productName: 'Doritas 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-03T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2022-08-10T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
    ],
  },
];

const competitorProducts = [
  {
    productName: 'Chips 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-03T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2022-08-10T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
    ],
  },
  {
    productName: 'Sabritas 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2022-08-11T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-08T07:02:46.073Z',
        value: 20,
      },
    ],
  },
  {
    productName: 'Sabritas PC 4X CHICO',
    productInfo: '',
    crossPriceElasticity: [
      {
        date: '2022-08-03T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2022-08-10T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-20T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2022-08-11T07:02:46.073Z',
        value: 100,
      },
      {
        date: '2022-08-08T07:02:46.073Z',
        value: 20,
      },
    ],
  },
];

const businessImpactColumnRange = ['Growth for Brand N 2017 vs 2018', 'Growth for Brand N 2018 vs 2019', 'Growth for Brand N 2019 vs 2020'];
const volumeByYearDonutData = [{ title: 'R Square', rSqure: 20 }];
const variables = [
  'Base Drivers',
  'Price',
  'Cross Price',
  'Competitive Activity',
  'Online/Offline SEO',
  'Digital Marketting',
  'Key Events',
  'Holidays',
];
const variableDonutColors = ['#0993cd', '#5dcb7e'];
const macroEconomicsData = ['Inflation', 'Disposable Income', 'CCI'];
const volumeByYearDonutColors = ['#e28576', '#0993cd', '#f05a9c', '#c5cfd5', '#f99e66', '#51c6f5', '#50ab53', '#25d4cf'];
const historicPriceFilters = [
  { value: 'volume', label: 'Volume' },
  { value: 'revenue', label: 'Revenue' },
  { value: 'units', label: 'Units' },
];

const historicPieChartLabels = [
  {
    name: 'Price Elasticity',
    color: '#e28576',
  },
  {
    name: 'Cross Price Elasticity',
    color: '#0993cd',
  },
  {
    name: 'Distribution',
    color: '#f05a9c',
  },
  {
    name: 'Covid Cases',
    color: '#c5cfd5',
  },
  {
    name: 'Key Events',
    color: '#f99e66',
  },
  {
    name: 'Gas Prices',
    color: '#51c6f5',
  },
];
const productFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'permutationCombination',
  },
  permutationCombination: {
    title: `Permutation Computation`,
    options: [],
    placeholder: 'Select',
    selectionLimit: 3,
    multiple: true,
    last: true,
  },
};

const productFilterBrand = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'permutationCombination',
  },
  permutationCombination: {
    title: `Permutation Computation`,
    options: [],
    placeholder: 'Select',
    selectionLimit: 3,
    multiple: true,
    last: true,
  },
};

const competitorFilterBrand = {
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'permutationCombination',
  },
  permutationCombination: {
    title: `Permutation Computation`,
    options: [],
    placeholder: 'Select',
    selectionLimit: 3,
    multiple: true,
    last: true,
  },
};

const pepsicoFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'permutationCombination',
  },
  permutationCombination: {
    title: `Permutation Computation`,
    options: [],
    placeholder: 'Select',
    selectionLimit: 3,
    multiple: true,
    last: true,
  },
};

const competitorFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'permutationCombination',
  },
  permutationCombination: {
    title: `Permutation Computation`,
    options: [],
    placeholder: 'Select',
    selectionLimit: 3,
    multiple: true,
    last: true,
  },
};

const measureFilter = {
  comparisonLevel: {
    title: 'Comparison Level',
    options: [
      { id: '1', label: 'Brand', value: 'brand' },
      { id: '2', label: 'Sub-Brand/Pack-Size', value: 'subBrandPackSize' },
    ],
    defaultOption: { id: '1', value: 'brand' },
    showRadioButton: true,
  },
  priceFormula: {
    title: 'Price Formula',
    options: [
      { id: '1', label: 'PPU', value: 'ppu' },
      { id: '2', label: 'PPV', value: 'ppv' },
    ],
    defaultOption: { id: '2', value: 'ppv' },
    showRadioButton: true,
  },
};

const modelActualVSPredicted = {
  title: '',
  options: [
    { id: '1', label: 'Price Per Unit', value: 'PPU' },
    { id: '2', label: 'Price Per Volume', value: 'PPV' },
    { id: '3', label: 'Grams Per Unit', value: 'VPU' },
  ],
  defaultOption: { id: '1', label: 'Price Per Unit', value: 'PPU' },
  showRadioButton: true,
};
// pepsico and competitors filter response data

const pepsi = [
  {
    productName: 'pepsi product1',
    productInfo: 'This product is for year 2022',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
  {
    productName: 'pepsi product2',
    productInfo: 'This product is for year 2021',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
  {
    productName: 'pepsi product3',
    productInfo: 'This product is for year 2020',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
];
const competitor = [
  {
    productName: 'competitor product1',
    productInfo: 'This product is for year 2022',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
  {
    productName: 'competitor product2',
    productInfo: 'This product is for year 2021',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
  {
    productName: 'competitor product3',
    productInfo: 'This product is for year 2020',
    crossPriceElasticity: [
      {
        date: '2018-08-01T07:02:46.073Z',
        value: 10,
      },
      {
        date: '2019-08-02T07:02:46.073Z',
        value: 20,
      },
      {
        date: '2020-08-03T07:02:46.073Z',
        value: 30,
      },
      {
        date: '2021-08-04T07:02:46.073Z',
        value: 40,
      },
      {
        date: '2022-08-05T07:02:46.073Z',
        value: -30,
      },
    ],
  },
];
const saveDashBoardTabsData = {
  data: [
    {
      dashboardId: 777,
      typeName: 'string',
      route: '/historic-price',
      viewCount: 2,
      thumbnailUrl: 'https://southportiodev.blob.core.windows.net/pepsio/thumbnail1.png',
      countries: ['string'],
      type: 'string',
      id: 908765,
      name: 'historicprice',
      version: '1',
    },
  ],
};

const recentSaveedDashboards: Array<GraphCardType> = [
  {
    graphImg: graph1,
    label: 'Net Revenue Realization',
    type: 'LineChart',
  },
  {
    graphImg: graph2,
    label: 'Discounts and Allowances',
    type: 'BarChart',
  },
  {
    graphImg: graph4,
    label: 'Target vs Actual Tracking',
    type: 'LineChart',
  },
];
const previouslySaveDashboards: Array<GraphCardType> = [
  {
    graphImg: graph4,
    label: 'Target vs Actual Tracking',
    type: 'LineChart',
  },
  {
    graphImg: graph3,
    label: 'Mix Management',
    type: 'PieChart',
  },
  {
    graphImg: graph1,
    label: 'Net Revenue Realization',
    type: 'LineChart',
  },
  {
    graphImg: graph2,
    label: 'Discounts and Allowances',
    type: 'BarChart',
  },
];

const trendingDashboards: Array<GraphCardType> = [
  {
    graphImg: graph1,
    label: 'Net Revenue Realization',
    type: 'LineChart',
  },
  {
    graphImg: graph2,
    label: 'Discounts and Allowances',
    type: 'BarChart',
  },
  {
    graphImg: graph3,
    label: 'Mix Management',
    type: 'PieChart',
  },
  {
    graphImg: graph4,
    label: 'Target vs Actual Tracking',
    type: 'LineChart',
  },
];

const exportObject = {
  primaryFilter,
  productFilter,
  anchorFilter,
  anchorFiltersBrand,
  measureFilter,
  competitorFilter,
  pepsicoFilter,
  saveDashBoardTabsData,
  recentSaveedDashboards,
  previouslySaveDashboards,
  trendingDashboards,
  productFilterBrand,
  competitorFilterBrand,
  modelActualVSPredicted,
};

export {
  variables,
  pepsiProducts,
  buisnessPieChart,
  competitorProducts,
  macroEconomicsData,
  variableDonutColors,
  historicPriceFilters,
  volumeByYearDonutData,
  volumeByYearDonutColors,
  businessImpactColumnRange,
  historicPieChartLabels,
  pepsi,
  competitor,
  buissnessImpactColumnRangeXAxisData,
  buissnessImpactColumnRangeData,
};

export default exportObject;
