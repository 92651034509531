import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { Title } from '../../../components/Dashboard/Dashboard.Styled';
import MultiLevelTable from './MultiLevelTable/MultiLevelTable';
import { tableHeader } from '../../../mocks/multiLevelTable';
import { getProductDetails } from '../service';
import _ from 'lodash';
import Dashboard from '../../../components/Dashboard';
const MultilevelTableContainer: React.FC<{ appliedFilters }> = ({ appliedFilters }) => {
  const [pepsiData, setPepsiData] = useState({});
  const [competitorData, setCompetitorData] = useState({});
  const [showSkeleton, setshowSkeleton] = useState(true);
  const fetchProductData = async () => {
    setshowSkeleton(true);
    const response = await getProductDetails(appliedFilters);
    if (response?.data) {
      let competitorVendor = _.uniqBy(response?.data.competitorCategory, (x) => x.vendor).map((item) => ({
        name: item.vendor,
        vendor: item.vendor,
        parentName: item.vendor,
      }));
      competitorVendor = _.sortBy(competitorVendor, (item) => item?.name);
      setPepsiData({
        vendor: [{ name: 'PEPSICO', parentName: 'PEPSICO', vendor: 'PEPSICO' }],
        pepsiCategory: response?.data.pepsiCategory,
        pepsiSegment: response?.data.pepsiSegment,
        pepsiBrands: response?.data.pepsiBrands,
        pepsiSubBrands: response?.data.pepsiSubBrands,
        pepsiPackSizes: response?.data.pepsiPackSizes,
      });
      setCompetitorData({
        competitorVendor,
        competitorCategory: response?.data.competitorCategory,
        competitorSegment: response?.data.competitorSegment,
        competitorBrands: response?.data.competitorBrands,
        competitorSubBrands: response?.data.competitorSubBrands,
        competitorPackSizes: response?.data.competitorPackSizes,
      });
    }
    setshowSkeleton(false);
  };
  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0) fetchProductData();
  }, [appliedFilters]);
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={6} sm={12} xl={6}>
        <Card>
          <CardContent>
            <Dashboard
              title={null}
              showSkeleton={showSkeleton}
              chartRef={() => {}}
              showActionButton={true}
              actionButtons={{
                mmpw: false,
                screenshot: true,
                editMedia: false,
              }}
              id={null}
            >
              <Grid container display='flex' flexDirection='column'>
                <Grid item display='flex' justifyContent='space-between' sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6} sm={12} xl={6}>
                    <Title className='ellipsis'>
                      Sell Out Price Details | <span style={{ color: '#0093CD' }}>PepsiCo</span>
                    </Title>
                  </Grid>
                  <Grid item display='flex' justifyContent='flex-end' xs={12} md={6} sm={12} xl={6} fontStyle='italic' sx={{ pt: 1 }}>
                    {' '}
                    {appliedFilters?.date}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <MultiLevelTable
                    data={pepsiData}
                    tableHeader={tableHeader}
                    type='pepsico'
                    level='vendor'
                    showSkeleton={showSkeleton}
                    responseKeys={['name', 'grams', 'sop', 'sopKg', 'mix']}
                    style={{ cellWidth: '12vw' }}
                  />
                </Grid>
              </Grid>
            </Dashboard>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} sm={12} xl={6}>
        <Card>
          <CardContent>
            <Dashboard
              title={null}
              showSkeleton={showSkeleton}
              chartRef={() => {}}
              showActionButton={true}
              actionButtons={{
                mmpw: false,
                screenshot: true,
                editMedia: false,
              }}
              id={null}
            >
              <Grid container display='flex' flexDirection='column'>
                <Grid item display='flex' justifyContent='space-between' sx={{ mb: 2 }}>
                  <Title className='ellipsis'>
                    Sell Out Price Details | <span style={{ color: '#0093CD' }}>Competitor</span>
                  </Title>
                  <Grid item display='flex' justifyContent='flex-end' xs={12} md={6} sm={12} xl={6} fontStyle='italic' sx={{ pt: 1 }}>
                    {appliedFilters?.date}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <MultiLevelTable
                    data={competitorData}
                    tableHeader={tableHeader}
                    type='competitor'
                    level='competitorVendor'
                    showSkeleton={showSkeleton}
                    responseKeys={['name', 'grams', 'sop', 'sopKg', 'mix']}
                    style={{ cellWidth: '12vw' }}
                  />
                </Grid>
              </Grid>
            </Dashboard>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default MultilevelTableContainer;
