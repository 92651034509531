import { Card, CardContent, Grid } from '@mui/material';
import { resolve } from 'path';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../components/CustomTable';
import MultiLevelTable from '../../../components/MultiLevelTable/MultiLevelTable';
import { BlackTitle, NoData } from '../../../styles/Common.Styled';
import { messages } from '../../../util/config';
import { getMultilevelTableData } from '../service';

const MultilevelTableContainer: React.FC<{ headers; endPoint; title; filters; child }> = ({ headers, endPoint, title, filters, child }) => {
  const [multiLevelTableData, setMultiLavelTableData] = useState({});
  const [skeleton, setSkeleton] = useState(true);
  const [hierarchy, setHierarchy] = useState({})
  const [orderBy, setOrderBy] = useState("name")

  useEffect(() => {
    if (filters && filters.date && endPoint) {
      // const payload = child === "country" ? filters : {
      //   "users": filters.userIds,
      //   "date": filters.startDate + "-" + filters.endDate
      // }
      // console.log(payload)
      setSkeleton(true)
      getMultilevelTableData(filters, endPoint)
        .then((response) => {
          console.log(response);
          setOrderBy(child === "activity" ? "name" : "viewCount")
          formatHierarchy();
          [{ child: "user", parent: 'country' }, { child: child, parent: "user" }].map((d) => {
            response.data[d.child] = response.data[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
          });
          setMultiLavelTableData(response.data);
          setSkeleton(false);
        })
        .catch((err) => {
          console.log(err);
          setMultiLavelTableData({});
          setSkeleton(false);
        });
    }
  }, [filters]);
  console.log(multiLevelTableData)

  const formatHierarchy = () => {
    child === "country" ? setHierarchy(
      {
        country: { responseKeys: headers.map((item) => item.value) },
        user: { filterBy: ['country'], responseKeys: headers.map((item) => item.value) },
        dashboard: { filterBy: ['user'], responseKeys: headers.map((item) => item.value) },
      }
    ) : setHierarchy(
      {
        country: { responseKeys: headers.map((item) => item.value) },
        user: { filterBy: ['country'], responseKeys: headers.map((item) => item.value) },
        activity: { filterBy: ['user'], responseKeys: headers.map((item) => item.value) },
      }
    )
  }


  return (
    <Grid container>
      <Grid item sm={12} className='m-b-20'>
        <Card>
          <CardContent>
            <BlackTitle style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>{title}</BlackTitle>
            {skeleton ? (
              <>
                <Grid className="m-t-20">
                  <CustomTable
                    heading={[1, 2, 3, 4, 5, 6]}
                    value={[
                      [1, 2, 3, 4, 5, 6],
                      [1, 2, 3, 4, 5, 6],
                      [1, 2, 3, 4, 5, 6],
                    ]}
                    showSkeleton={skeleton}
                  />
                </Grid>
              </>
            ) : multiLevelTableData?.country?.length > 0 ? (
              <Grid container spacing={0}>
                {multiLevelTableData && Object.keys(multiLevelTableData).length > 0 && (
                  <MultiLevelTable
                    data={{ ...multiLevelTableData }}
                    tableHeader={headers}
                    showSkeleton={skeleton}
                    level={'country'}
                    hierarchy={hierarchy}
                    orderByNum={orderBy}
                    style={{ height: 500, overflow: 'auto' }}
                  />
                )}
              </Grid>
            ) : (
              <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
                <NoData sx={{ color: 'black' }}>{messages.reports}</NoData>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MultilevelTableContainer;
