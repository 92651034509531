import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import Papa from "papaparse";
import { loaderForMixScreen } from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import { toast } from "../../../../store/actions/toast.action";
import { setOutputScreenToDisplay } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { fetchMixSummaryData } from "../../../../store/actions/MixSimulationTool/mixSummary.action";
import { fetchMixPortfolioData } from "../../../../store/actions/MixSimulationTool/mixPortfolio.action";
import { fetchMixGeoLevelData } from "../../../../store/actions/MixSimulationTool/mixGeoLevel.action";
import {
  fetchMixSimulationFiltersData,
  setDefaultMixSimulationFilterForOutput,
  setMixSimulationLastTriggeredKey,
} from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { API_URL } from "../../../../store/actions/constants";

const RunSimulationButton = ({ tableData }) => {
  const { uid } = useSelector((state: any) => state.mixSimulationUserInput);
  const { capacitiveFile, operativeFile } = useSelector(
    (state: any) => state["MixUserConstraintsReducer"]
  );
  const userInputs = useSelector((state: any) => state.mixSimulationUserInput);
  const dispatch = useDispatch();
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );
  const { portfolioLevel } = useSelector(
    (state: any) => state.MixPortfolioReducer
  );
  const { geoLevel } = useSelector((state: any) => state.MixGeoLevelReducer);

  const handleRunSimulation = () => {
    const dispatchAction: dispatchState = {
      loaderStateFetch: loaderForMixScreen(true),
    };

    const removeSrno: any = [];
    const capcitiveComplexity: any = [];
    const operativeComplexity: any = [];

    tableData.forEach((ele) => {
      delete ele.srno;
      const restElements = { ...ele };
      const capcitiveComplexityEle = {
        Segment: ele["store_segment"],
        Brand: ele["brand"],
        SKU: ele["sku"],
        "Capacity Constraint": ele["capacity_constraints"],
      };
      const operativeComplexityEle = {
        Segment: ele["store_segment"],
        Brand: ele["branc"],
        SKU: ele["sku"],
        "Operative Complexity": ele["operational_complexity"],
      };
      removeSrno.push(restElements);
      capcitiveComplexity.push(capcitiveComplexityEle);
      operativeComplexity.push(operativeComplexityEle);
    });

    const convertData = Papa.unparse(removeSrno);
    const file = new Blob([convertData], { type: "text/csv" });

    const formData = new FormData();
    formData.append("CsvFile", file);
    formData.append("Uid", uid);

    const payload = {
      uid: uid,
      region: selectedFilterData.region,
      storeSegment: selectedFilterData.storeSegment,
      goal: userInputs.selectedGoal,
    };
    const optimiserPayload = {
      Uid: uid,
      Goal: userInputs.selectedGoal,
      BusinessUnit: selectedFilterData.businessUnit[0],
      TimePeriod: selectedFilterData.period[0],
      Level: selectedFilterData.level[0],
      AssortmentUid: userInputs.selectedAssortmentScenaioUid,
      Channel: selectedFilterData.channel,
      Region: selectedFilterData.region,
      StoreSegment: selectedFilterData.storeSegment,
    };
    // const optimiserPayload=
    // {
    //   uid: uid,
    //   goal: userInputs.selectedGoal,
    //   assortmentUid: userInputs.selectedAssortmentScenaioUid,
    //   channel: selectedFilterData.channel,
    //   region: selectedFilterData.region,
    //   storeSegment: selectedFilterData.storeSegment
    // }
    // {
    //   "Uid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "Goal": "string",
    //   "BusinessUnit": "string",
    //   "Level": "string",
    //   "AssortmentUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "Channel": [
    //     "string"
    //   ],
    //   "Region": [
    //     "string"
    //   ],
    //   "StoreSegment": [
    //     "string"
    //   ]
    // }
    const geoLevelPayload = {
      uid: uid,
      level: geoLevel.length === 0 ? ["channel"] : geoLevel,
      region: selectedFilterData.region,
      storeSegment: selectedFilterData.storeSegment,
      goal: userInputs.selectedGoal,
    };
    const portfolioPayload = {
      uid: uid,
      levels: ["brand"],
      region: selectedFilterData.region,
      storeSegment: selectedFilterData.storeSegment,
      goal: userInputs.selectedGoal,
    };
    const outputFiltersPayload = {
      uid: uid,
      default: true,
      overallFilters: {
        country: selectedFilterData.country,
        period: selectedFilterData.period,
        businessUnit: selectedFilterData.businessUnit,
      },
      geo: {
        channel: selectedFilterData.channel,
        region: selectedFilterData.region,
        storeSegment: selectedFilterData.storeSegment,
      },
    };

    dispatch(
      fetchApi(
        formData,
        API_URL.MIXSIMULATION.SAVE_MIX_CONSTRIANT_TABLE_DATA,
        dispatchAction
      )
    )
      .then((res) => {
        if(res.data['message']=="Min Mix and Max Mix Ranges are Out of Bound"){
          dispatch(toast(res.data['message'], true, 3000, "error"));
          dispatch(loaderForMixScreen(false));
        }else{
        
        // let fileOperative, fileCapacitive, capFormData, opeFormData;
        // let decideApiCalls: any = [];

        // switch (true) {
        //   case capacitiveFile == null && operativeFile == null:
        //     break;
        //   case operativeFile == null:
        //     fileCapacitive = new Blob([capacitiveFile], { type: "text/csv" });
        //     capFormData = getFormData(fileCapacitive);
        //     decideApiCalls = [
        //       dispatch(
        //         fetchApi(
        //           capFormData,
        //           "output/mix/uploadCsvFileCapacityConstraint",
        //           dispatchAction
        //         )
        //       )
        //     ]
        //     break;
        //   case capacitiveFile == null:
        //     fileOperative = new Blob([operativeFile], { type: "text/csv" });
        //     opeFormData = getFormData(fileOperative);
        //     decideApiCalls = [
        //       dispatch(
        //         fetchApi(
        //           opeFormData,
        //           "output/mix/uploadCsvFileOperativeComplexity",
        //           dispatchAction
        //         )
        //       )
        //     ]
        //     break;
        //   default:
        //     fileOperative = new Blob([operativeFile], { type: "text/csv" });
        //     fileCapacitive = new Blob([capacitiveFile], { type: "text/csv" });
        //     [opeFormData, capFormData] = [getFormData(fileOperative), getFormData(fileCapacitive)]
        //     decideApiCalls = [
        //       dispatch(
        //         fetchApi(
        //           capFormData,
        //           "output/mix/uploadCsvFileCapacityConstraint",
        //           dispatchAction
        //         )
        //       ),
        //       dispatch(
        //         fetchApi(
        //           opeFormData,
        //           "output/mix/uploadCsvFileOperativeComplexity",
        //           dispatchAction
        //         )
        //       )
        //     ]
        //     break;
        // }
        // console.log("DECIDE API CALLS", decideApiCalls)

        // Promise.all(decideApiCalls)
        //   .then(res => {
        dispatch(loaderForMixScreen(false));
        dispatch(
          fetchApi(
            optimiserPayload,
            API_URL.MIXSIMULATION.GET_OPTIMIZER_DATA,
            dispatchAction
          )
        )
          .then((res) => {
            dispatch(setOutputScreenToDisplay(true));
            window.scrollTo(0, 0)
            dispatch(loaderForMixScreen(false));
            dispatch(
              fetchApi(
                { uid },
                API_URL.MIXSIMULATION.GET_MIX_DATA_AFTER_RUN_SIMULATION,
                dispatchAction
              )
            )
              .then((res) => {
                dispatch(loaderForMixScreen(false));
                dispatch(setMixSimulationLastTriggeredKey("channel"));
                dispatch(setDefaultMixSimulationFilterForOutput());
                dispatch(
                  fetchMixSummaryData(
                    payload,
                    API_URL.MIXSIMULATION.GET_MIX_SUMMARY_DATA
                  )
                );
                dispatch(
                  fetchMixPortfolioData(
                    portfolioPayload,
                    API_URL.MIXSIMULATION.GET_PORTFOLIO_DATA_AFTER_RUN_SIMULATION
                  )
                );
                dispatch(
                  fetchMixGeoLevelData(
                    geoLevelPayload,
                    API_URL.MIXSIMULATION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_RUN_SIMULATION
                  )
                );
              })
              .catch((error) => {
                dispatch(loaderForMixScreen(false));
                dispatch(toast("Something went wrong", true, 2000, "error"));
              });
          })
          .catch((err) => {
            dispatch(loaderForMixScreen(false));
            dispatch(toast("Something went wrong", true, 2000, "error"));
          });
        }
      })
      .catch((err) => {
        dispatch(loaderForMixScreen(false));
        dispatch(toast("Something went wrong", true, 2000, "error"));
      });
  };

  function getFormData(file) {
    const formData = new FormData();
    formData.append("Uid", uid);
    formData.append("CsvFile", file);
    return formData;
  }

  return (
    <Grid container display={"flex"} justifyContent={"flex-end"}>
      <Button
        variant="contained"
        onClick={handleRunSimulation}
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          color: "#FFFFFF",
          marginTop: "1.5rem",
        }}
        disabled={tableData.length == 0}
      >
        Run Simulation
      </Button>
    </Grid>
  );
};

export default RunSimulationButton;
