import Highcharts from "highcharts";
import { store } from "../../../../App";
import { setSelectedBubbleValueForSectionFilterMixSimulator } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
const bubbleChartLableMapper = {
  'Revenue/KG':'Revenue SOP',
  'Profit/KG':'NOPBT SOP',
  'Market/Share':'Share SOP',
}
export const formatValue = (value) => {
  const formats = [
    { divisor: 1e9, suffix: "B" },
    { divisor: 1e6, suffix: "M" },
    { divisor: 1e3, suffix: "K" },
  ];

  for (const format of formats) {
    if (Math.abs(value) >= format.divisor) {
      return Highcharts.numberFormat(value / format.divisor, 2) + format.suffix;
    }
  }

  return Highcharts.numberFormat(value, 2);
};

export const BubbleChartConfig = (data, segmentColorLabel, val) => ({
  
  chart: {
    type: "bubble",
    plotBorderWidth: 1,
    zoomType: "xy",
    backgroundColor: "#F1F5FE",
  },
  legend: { enabled: false },
  zoomType: "xy",
  title: { text: "" },
  exporting: { enabled: false },
  credits: { enabled: false },
  xAxis: {
    lineColor: "transparent",
    allowDecimals: true,
    showLastLabel: true,
    endOnTick: true,
    title: { text: "Mix Delta" },
    labels: {
      formatter: function () {
        return formatValue(this.value);
      },
    },
    plotLines: [{
      color: 'black',
      dashStyle: 'dot',
      width: 2,
      value: 0,
      label: {
          rotation: 0,
          y: 15,
          style: {
              fontStyle: 'italic'
          },
          text: ''
      },
      zIndex: 3
  }]
  },
  yAxis: {
    allowDecimals: true,
    title: { text: `${bubbleChartLableMapper[val]}` },
    labels: {
      formatter: function () {
        return formatValue(this.value);
      },
    },
    maxPadding: 0.2,
    plotLines: [{
      color: 'black',
      dashStyle: 'dot',
      width: 2,
      value: 0,
      label: {
          align: 'right',
          style: {
              fontStyle: ''
          },
          text: '',
          x: -10
      },
      zIndex: 3
  }]
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<table>",
    pointFormatter: function () {
      console.log("this.name", this.name, this.z, this.idealMix)
      return (
        '<tr><th colspan="2"><h3>' +
        this.name +
        "</h3></th></tr>" +
        "<tr><td></td><td>" +
        "Current Mix: " +
        formatValue(this.z) +
        "</td></tr>" +
        "<tr><td></td><td>" +
        "Ideal Mix: " +
        formatValue(this.idealMix) +
        "</td></tr>"
      );
    },
    footerFormat: "</table>",
    followPointer: true,
  },
  series: [
    {
      data: data.map((ele) => ({
        x: ele.xAxis,
        y: ele.yAxis,
        z: ele.bubbleSize,
        name: ele.skuName,
        segment: ele.segment,
        idealMix: ele.idealMix,
        color: segmentColorLabel[ele.segment],
      })),
      point: {
        events: {
          click(event) {
            const dataPoint = event.point;
            store.dispatch(setSelectedBubbleValueForSectionFilterMixSimulator(
              `${dataPoint.options.segment}|${dataPoint.options.name}`
            ))

            // console.log(event)
            console.log("segment",dataPoint.options)
          }
        }
      }
    },
  ],
  
});

export const colors = [
  "#1A99D5",
  "#EA1F28",
  "#F77B36",
  "#15A156",
  "#F2C2A6",
  "#F5A623",
  "#87D4F9",
  "#B3BCC7",
  "#50E3C2",
  "#50E3C2",
  "#2F5597",
  "#7C031A",
  "#F1F1F5",
  "#8B8BC6",
  "#48B1C8",
  "#DADC62",
  "#DC62BA",
  "#EE9B9B",
  "#2B7B71",
  "#5BC09C",
];

export const getRandomHexColor = () => {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 16).toString(16);
  }
  return color;
};
