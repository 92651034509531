import React from "react";
import {
  StyledCard,
  StyledCardContent,
  StyledDivider,
  StyledGrid,
  TitleTypography,
  LabelTypography,
  ValueTypography,
} from "./Cards.Styled";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Indicator from "../../../../components/Loader/Loader";

const Cards = ({ data, loading }) => {
  const { Title, netRevenue, grossProfit, grossProfitPercentage, marketShare } = data;

  const renderLoader = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop:"50%",position: "relative" }}
    >
      <Indicator position="absolute" show={loading} />
    </Grid>
  );

  const renderRow = (label, value, style = {}) => (
    <React.Fragment key={label}>
      <StyledGrid container mt={3}>
        <Grid item xs={6}>
          <LabelTypography>{label}</LabelTypography>
        </Grid>
        <Grid item xs={6}>
          <ValueTypography align="right" style={style}>
            {loading ? null : value}
          </ValueTypography>
        </Grid>
      </StyledGrid>
      <StyledDivider
        color="#dfdfdf"
        sx={{ height: 1, m: 1 }}
        orientation="vertical"
      />
    </React.Fragment>
  );
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  return (
    <StyledCard >
      <StyledCardContent>
        <TitleTypography>{Title}</TitleTypography>
        {loading ? (
          renderLoader()
        ) : (
          <>
            {renderRow("Net Revenue", netRevenue, { color: "#2F5597" })}
            {renderRow("NOPBT", grossProfit, { color: "#0B0E1E" })}
            {renderRow("NOPBT %", grossProfitPercentage, { color: "#0B0E1E" })}
            {renderRow("Market Share", marketShare, { color: "#0B0E1E" })}
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default Cards;
