import { CHANGE_THEME } from '../actions/constants';

let intiState = {
  darkmode: true,
};

const theme = (state = intiState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        darkmode: true,
      };
    default:
      return state;
  }
};

export default theme;
