import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
      fontFamily: 'Poppins',
    },
  },
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#25d4cf',
    },
    secondary: {
      main: '#EDF5FA',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
    background: {
      default: '#25d4cf',
      paper: '#EDF5FA',
    },
    text: {
      primary: '#1E1E1F',
    },
    mode: 'dark',
  },
  typography: {
    body1: {
      color: '#fff',
      fontFamily: 'Poppins',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          height: '46px',
          color: '#000',
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
          color: '#000',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ':focus': {
            borderColor: 'brown',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
          color: '#000',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#6e575321',
          borderRadius: 5,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        track: { border: 'none', backgroundColor: '#0d98cf', height: 10 },
        rail: { color: '#fff', border: '1px solid gray', height: 10 },
        thumb:{backgroundColor:'#0398fc'}
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#003366',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
  },
});

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#25d4cf',
    },
    background: {
      paper: '#fff',
      default: '#EAF6FB',
    },
    text: {
      primary: '#1E1E1F',
    },
    mode: 'light',
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#25d4cf',
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          height: '46px',
          color: '#000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        track: { border: 'none', backgroundColor: '#0d98cf', height: 10 },
        rail: { color: '#fff', border: '1px solid gray', height: 10 },        
        thumb:{backgroundColor:'#03a5fc'}
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       '&:focus': {
    //         border: '1px solid #000',
    //         padding: 10,
    //       },
    //     },
    //   },
    // },
  },
});

export { darkTheme, lightTheme };
