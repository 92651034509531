import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DashboardActions from '../../components/DashboardActions';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { pageList } from '../../mocks/common';
import data from '../../mocks/profitParabola';
import TopFilters from './components/TopFilters';

import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import CorrelationAnalysis from './components/CorrelationAnalysis';
import FinalSimulation from './components/FinalSimulation';
import LinearRegression from './components/LinearRegression';
import ParabolaSimulations from './components/ParabolaSimulations';
import { removeMMPWFromLocal } from '../../util/helper';
import { useSelector } from 'react-redux';

const ProfitParabolaDashboard: React.FC = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [finalSimulationData, setFinalSimulationData] = useState({});
  const [productsData, setProductsData] = useState({});
  const [defaultFilters, setDefaultFilters] = useState({
    global: {},
    simulation: {},
    finalSimulation: {},
  });
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();
  const reduxState = useSelector((state: any) => state);

  const onApplyFilter = (filters) => {
    if (filters) {
      setDisableDownloadButton(false);
      removeMMPWFromLocal('profit-parabola');
      setAppliedFilters(filters);
      setDefaultFilters({
        global: {},
        simulation: {},
        finalSimulation: {},
      });
    }
  };

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      console.log(savedDashboardData?.filters);
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters);
      setAppliedFilters(savedDashboardData?.filters?.global);
    }
  };


  return (
    <DashboardActions
      title='Profit Parabola'
      data={{
        filters: {
          global: { ...appliedFilters },
          simulation: productsData,
          finalSimulation: finalSimulationData,
        },
        mmpwKey: 'profit-parabola',
      }}
      dashboardKey={pageList[2].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={disableDownloadButton || reduxState.ProfitParabolaLinearRegression.linearRegression}
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[2].value} />
      </Grid>
      <Box className='m-b-20'>
        <TopFilters callback={onApplyFilter} data={data} defaultFilters={defaultFilters?.global} />
      </Box>
      <CorrelationAnalysis filters={appliedFilters} />
      <LinearRegression filters={appliedFilters} />
      <ParabolaSimulations callback={setProductsData} filters={appliedFilters} defaultFilters={defaultFilters?.simulation} />
      <FinalSimulation filters={appliedFilters} callback={setFinalSimulationData} defaultFilters={defaultFilters?.finalSimulation} />
      <DashboardSourceList category={appliedFilters.category} dashboardName={pageList[2].id} appliedFilters={appliedFilters} />
    </DashboardActions>
  );
};

export default ProfitParabolaDashboard;
