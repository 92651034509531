import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseLineTable from "../../../../components/BaseLineTable";
import Dashboard from "../../../../components/Dashboard";
import { grossProfitTable } from "../../../../mocks/supermarketMock";
import { loader } from "../../../../store/actions/common.action";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import { separator } from "../../../../util/helper";
import { incrementalityGraphConfig } from "../chartConfig";
import HighchartContainer from "../../../../components/HighchartContainer";

const Incrementality: React.FC<{ pepsicoBaseline; skeleton }> = ({
  pepsicoBaseline,
  skeleton = true,
}) => {
  const chartRef = useRef<any>(null);
  const dispatch = useDispatch();
  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );
  const [tableValues, setTableValues] = useState([]);
  const [noteFlag, setNoteFlag] = useState(false);

  const formatIncrementality = (response: any) => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const series1: any = [];
      const series2: any = [];
      const series3: any = [];
      const xAxis: any = [];
      const tableData: any = [];
      let max: any = _.maxBy(
        response.increvsGrossProfit,
        "pricePoint"
      ).pricePoint;
      let min: any = _.minBy(
        response.increvsGrossProfit,
        "pricePoint"
      ).pricePoint;
      setNoteFlag(max >= response.srp && min <= response.srp);
      response.increvsGrossProfit &&
        response.increvsGrossProfit.map((item) => {
          series1.push(item.elasticityProjections);
          series2.push(item.historicalPricePoint * 100);
          series3.push(item.grossProfitBreakEven);
          xAxis.push(separator(item.pricePoint.toFixed(2)));

          tableData.push({
            pricePoint: separator(item.pricePoint.toFixed(2)),
            baselinePoint: separator(item.baselinePoint.toFixed(2)),
            grossProfit: separator((item.grossProfit * 100).toFixed(2)),
            grossProfitBreakEven: separator(
              (item.grossProfitBreakEven * 100).toFixed(2)
            ),
            elasticityProjections: separator(
              (item.elasticityProjections * 100).toFixed(2)
            ),
            estimatePayOff: item.estimatePayOff,
            historicalPricePoint: separator(
              (item.historicalPricePoint * 100).toFixed(2)
            ),
            historicalDataPaidOff: item.historicalDataPaidOff,
          });
        });
      chart.series[0].setData(series1);
      chart.series[1].setData(series2);
      chart.series[2].setData(series3);
      chart.xAxis[0].setCategories(xAxis);
      setTableValues(tableData);
    }
  };

  useEffect(() => {
    if (pepsicoBaseline && pepsicoBaseline.data) {
      dispatch(loader(false));
      setTimeout(() => {
        formatIncrementality(pepsicoBaseline.data);
      }, 0);
    } else {
    }
    if (pepsicoBaseline && pepsicoBaseline.error) {
      dispatch(loader(false));
    }
  }, [pepsicoBaseline]);

  return (
    <Dashboard
      title="Incrementality Vs Gross Profit Breakeven"
      chartRef={chartRef}
      themeTitle
      id="supermarket-incrementality-vs-gross-profit-breakeven"
      showSkeleton={skeleton}
    >
      <Card>
        <CardContent>
          {!skeleton &&
            pepsicoBaseline.data &&
            _.isArray(pepsicoBaseline.data.increvsGrossProfit) && (
              <HighchartContainer
                chartRef={chartRef}
                id={"supermarket-incrementality-vs-gross-profit-breakeven"}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={incrementalityGraphConfig}
                  ref={chartRef}
                />
              </HighchartContainer>
            )}
          {skeleton && (
            <Skeleton variant="rectangular" height={400} className="m-b-20" />
          )}
          {pepsicoBaseline.data &&
            _.isArray(pepsicoBaseline.data.increvsGrossProfit) && (
              <>
                <BaseLineTable
                  heading={grossProfitTable.heading}
                  rowData={tableValues}
                  showSkeleton={skeleton}
                  baseline={pepsicoBaseline.data && pepsicoBaseline.data.srp}
                  diffVal={5}
                />

                {!noteFlag && (
                  <Box className="m-t-10">
                    NOTE : The Incrementality Vs Gross Profit Breakeven uses the
                    nearest number to the basline, since the price point is not
                    in the historical data.
                  </Box>
                )}
              </>
            )}
          {!skeleton &&
            pepsicoBaseline.data &&
            !pepsicoBaseline.data.increvsGrossProfit && (
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={400}
              >
                <NoData>{messages.SRPIsGreater}</NoData>
              </Grid>
            )}
          {!skeleton && !pepsicoBaseline.data && (
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={400}
            >
              <NoData>{messages.noData}</NoData>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Dashboard>
  );
};

export default Incrementality;
