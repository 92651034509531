import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../../../components/Dashboard";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup/CheckBoxGroup";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import apiPredictiveDashboardRowData from "../../../../mocks/apiPredictiveDashboard";
import { GraphLabel, NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import {
  convertToInternationalCurrency,
  dataFormat,
  plotImages,
  rendarImage,
  setColor,
  windowResizeListner,
} from "../../../../util/helper";
import { APISimulationGraphConfig } from "../../chartsConfig";
import { Label } from "./APISimulation.Styled";
import HighchartContainer from "../../../../components/HighchartContainer";
import {
  getAPISimulationChartData,
  getAPISimulationValuesChartData,
} from "../../service";
import IncrementDecrement from "../../../../components/IncrementDecrement/IncrementDecrement";
import { useDispatch, useSelector } from "react-redux";
import {
  simulationDataLoader,
  simulationValueLoader,
} from "../../store/apiPredictive.action";
import GrossVolumeCard from "./GrossVolumeCard/GrossVolumeCard";
const defaultPeriodicity = "monthly";

const APISimulation: React.FC<{ filters; callback; defaultFilters }> = ({
  filters,
  callback,
  defaultFilters,
}) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [correlation, setCorrelation] = useState(0);
  const [currentApi, setCurrentApi] = useState(0);
  const [currentPpuPepsico, setCurrentPpuPepsico] = useState(0);
  const [currentPpuAnchor, setCurrentPpuAnchor] = useState(0);
  const [proposedPpuPepsico, setProposedPpuPepsico] = useState<any>(0);
  const [proposedPpuAnchor, setProposedPpuAnchor] = useState<any>(0);

  const [proposedApi, setProposedApi] = useState<any>(0);
  const [skeleton, setSkeleton] = useState(true);
  const [valueSkeleton, setValueSkeleton] = useState(true);
  const [dataSkeleton, setDataSkeleton] = useState(true);
  const [productImages, setProductImages] = useState<any>([]);
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    somMeasure: null,
    somGroup: null,
  });
  const [selectedMonthRange, setSelectedMonthRange] = useState("3 Months");
  const [originalAPISimulationData, setOriginalAPISimulationData] =
    useState<any>({});
  const [APISimulationData, setAPISimulationData] = useState<any>({});
  const [APISimulationValueData, setAPISimulationValueData] = useState<any>({});

  React.useEffect(() => {
    if (APISimulationData && Object.keys(APISimulationData).length) {
      setTimeout(() => {
        formatAPISimulationData(APISimulationData);
      }, 0);
    }
  }, [APISimulationData]);

  React.useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setMeasureFilter({
        somMeasure: defaultFilters?.somMeasure || null,
        somGroup: defaultFilters?.somGroup || null,
      });
      setSelectedMonthRange(defaultFilters?.selectedMonthRange || "3 Months");
      if (defaultFilters?.proposedApi)
        setProposedApi(defaultFilters?.proposedApi);
    }
  }, [defaultFilters]);

  const formatAPISimulationData = async (data) => {
    if (data.variable1 && data.variable2) {
      setCorrelation(Math.round(data.correlation));
      setCurrentApi(data.currentApi.toFixed(2));
      setCurrentPpuPepsico(data.currentPpuPepsi.toFixed(2));
      setCurrentPpuAnchor(data.currentPpuAnchor.toFixed(2));
      setProposedPpuPepsico(
        proposedPpuPepsico || data.proposedPpuPepsi.toFixed(2)
      );
      setProposedPpuAnchor(
        proposedPpuAnchor || data.proposedPpuAnchor.toFixed(2)
      );
      if (proposedApi === 0) {
        setProposedApi(data.proposedApi.toFixed(2));
      }
      const dates = data.variable1.map((item) => item.date);
      // const formattedDate = dataFormat(dates, "MM/yy");
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        setLabels(chart);
        chart.xAxis[0].setCategories(dates);
        const variable1 = data.variable1.map((item) => item.value);
        const variable2 = data.variable2.map((item) => item.value);
        const proposedVariable1 = data.proposedVariable1.map(
          (item) => item.value
        );
        const proposedVariable2 = data.proposedVariable2.map(
          (item) => item.value
        );
        chart.series[0].setData(variable1);
        chart.series[1].setData(variable2);
        chart.series[2].setData(proposedVariable1);
        chart.series[3].setData(proposedVariable2);
      }

      if (
        (data.productName || data.anchorProductName) &&
        filters &&
        filters.data &&
        filters.data.packSize &&
        filters.data.anchorPackSize
      ) {
        let product1: any = null;
        let product2: any = null;
        if (data.productName) {
          product1 = {
            base64: await rendarImage(data.productName, filters.data.country),
          };
        }
        if (data.anchorProductName) {
          product2 = {
            base64: await rendarImage(
              data.anchorProductName,
              filters.data.country
            ),
          };
        }
        if (product1 && product1.base64 && product2 && product2.base64) {
          setProductImages([product1, product2]);
        } else if (product1 && product1.base64) {
          setProductImages([product1]);
        } else if (product2 && product2.base64) {
          setProductImages([product2]);
        }
      }
    }
  };

  useEffect(() => {
    if (
      productImages.length > 0 &&
      chartRef &&
      chartRef.current &&
      chartRef.current.chart
    ) {
      const chart = chartRef.current.chart;
      const config = {
        productImages: productImages,
        id: "api-predictive-dash-api-simulation-image",
        imgPosition: { x: 180, y: 300 },
        imgDimension: { width: 75, height: 60 },
        nameDimension: { width: 50, height: 55 },
      };
      windowResizeListner(chart, config);
      plotImages(chart, config);
    }
  }, [productImages]);

  React.useEffect(() => {
    if (APISimulationValueData && Object.keys(APISimulationValueData).length) {
      formatAPISimulationValuesData(APISimulationValueData);
    }
  }, [APISimulationValueData]);

  const formatAPISimulationValuesData = (data) => {
    apiPredictiveDashboardRowData.APISimulation[0].value = data.grossSales
      ? convertToInternationalCurrency("$", data.grossSales, "en-US")
      : "0";
    apiPredictiveDashboardRowData.APISimulation[0].simulated =
      data.simatedGrossSales
        ? convertToInternationalCurrency("$", data.simatedGrossSales, "en-US")
        : "0";
    apiPredictiveDashboardRowData.APISimulation[0].percentVar =
      data.perVarGrossSales ? data.perVarGrossSales : 0;
    apiPredictiveDashboardRowData.APISimulation[0].anchor =
      data.anchorGrossSales
        ? convertToInternationalCurrency("$", data.anchorGrossSales, "en-US")
        : "0";
    apiPredictiveDashboardRowData.APISimulation[1].value = data.volume
      ? convertToInternationalCurrency("", data.volume, "en-US")
      : "0";

    apiPredictiveDashboardRowData.APISimulation[1].simulated =
      data.simulatedVolume
        ? convertToInternationalCurrency("", data.simulatedVolume, "en-US")
        : "0";
    apiPredictiveDashboardRowData.APISimulation[1].percentVar =
      data.perVarVolume ? data.perVarVolume : 0;

    apiPredictiveDashboardRowData.APISimulation[1].anchor = data.anchorVolume
      ? convertToInternationalCurrency("", data.anchorVolume, "en-US")
      : "0";

    apiPredictiveDashboardRowData.APISimulation[2].value = data.grossUnit
      ? convertToInternationalCurrency("$", data.grossUnit, "en-US")
      : "0";
    apiPredictiveDashboardRowData.APISimulation[2].simulated =
      data.simulatedGrossUnit
        ? convertToInternationalCurrency("$", data.simulatedGrossUnit, "en-US")
        : "0";
    apiPredictiveDashboardRowData.APISimulation[2].percentVar =
      data.perVarGrossUnit ? data.perVarGrossUnit : 0;

    apiPredictiveDashboardRowData.APISimulation[2].anchor = data.anchorGrossUnit
      ? convertToInternationalCurrency("$", data.anchorGrossUnit, "en-US")
      : "0";

    apiPredictiveDashboardRowData.APISimulation[3].value = data.shareVolume
      ? `${(data.shareVolume * 100).toFixed(2)}%`
      : "0";
    apiPredictiveDashboardRowData.APISimulation[3].simulated =
      data.simulatedShareVolume
        ? `${(data.simulatedShareVolume * 100).toFixed(2)}%`
        : "0";
    apiPredictiveDashboardRowData.APISimulation[3].percentVar =
      data.perVarShareVolume ? data.perVarShareVolume : 0;

    setSkeleton(false);
  };

  React.useEffect(() => {
    if (filters && filters.type === "local") {
      const filtersData = { ...filters.data };
      const payload = {
        ...filtersData,
        monthRange: 0,
        proposedApi: 0,
        proposedPpuAnchor: 0,
        proposedPpuPepsi: 0,
      };
      setProposedPpuAnchor("");
      setProposedPpuPepsico("");
      setMeasureFilter({
        somGroup: filtersData.somGroup || "Units",
        somMeasure: filtersData.somMeasure || "Category",
      });
      const simulationsFilters = {
        ...filtersData,
        proposedApi: proposedApi ? parseInt(proposedApi) : 0,
        proposedPpuAnchor: proposedPpuAnchor ? parseInt(proposedPpuAnchor) : 0,
        proposedPpuPepsi: proposedPpuPepsico ? parseInt(proposedPpuPepsico) : 0,
      };
      onChangeDateRange(selectedMonthRange, simulationsFilters);
      getAPISimulationData(payload);
      setProposedApi(0);
    } else if (filters && filters.type === "global") {
      setAPISimulationData({});
      setAPISimulationValueData({});
    }
  }, [filters]);

  const setLabels = (chart) => {
    if (filters && filters.data) {
      const filtersData = { ...filters.data };
      if (filtersData.variable1 && filtersData.variable2) {
        chart.yAxis[0].setTitle({ text: filtersData.variable1 });
        chart.yAxis[1].setTitle({ text: filtersData.variable2 });

        chart.legend.allItems[1].update({
          name: `Current ${filtersData.variable2}`,
        });
        chart.legend.allItems[3].update({
          name: `Estimated ${filtersData.variable2}`,
        });
      }
    }
  };

  const onSubmitProposedPpuPepsico = (e) => {
    callback({
      selectedMonthRange,
      ...selectedMeasureFilters,
      proposedPpuPepsico,
    });

    if (e.keyCode == 13) {
      filters.data.proposedPpuPepsi = parseFloat(e.target.value);
      setProposedApi((parseFloat(e.target.value) / proposedPpuAnchor) * 100);
      setProposedPpuPepsico(parseFloat(e.target.value));
      const payload = {
        ...filters.data,
        // proposedPpuAnchor: parseFloat(proposedPpuAnchor),
        proposedApi: (parseFloat(e.target.value) / proposedPpuAnchor) * 100,
      };
      getAPISimulationData({
        ...payload,
        monthRange: 0,
        proposedPpuAnchor: parseFloat(proposedPpuAnchor),
      });
      getAPISimulationValuesData({
        ...payload,
        proposedPpuAnchor: parseFloat(proposedPpuAnchor),
      });
    }
  };

  const onSubmitProposedPpuAnchor = (e) => {
    callback({
      selectedMonthRange,
      ...selectedMeasureFilters,
      proposedPpuAnchor,
    });

    if (e.keyCode == 13) {
      filters.data.proposedPpuAnchor = parseFloat(e.target.value);
      setProposedApi((proposedPpuPepsico / parseFloat(e.target.value)) * 100);
      setProposedPpuAnchor(parseFloat(e.target.value));
      const payload = {
        ...filters.data,
        proposedApi: (proposedPpuPepsico / parseFloat(e.target.value)) * 100,
        // proposedPpuPepsi: parseInt(proposedPpuPepsico),
      };
      getAPISimulationData({
        ...payload,
        monthRange: 0,
        proposedPpuPepsi: parseFloat(proposedPpuPepsico),
      });
      getAPISimulationValuesData({
        ...payload,
        proposedPpuPepsi: parseFloat(proposedPpuPepsico),
      });
    }
  };

  const onChangeDateRange = (value, filter?) => {
    let monthRange = null;
    switch (value) {
      case "3 Months":
        monthRange = 3;
        setSelectedMonthRange(value);
        break;
      case "6 Months":
        monthRange = 6;
        setSelectedMonthRange(value);
        break;
      case "12 Months":
        monthRange = 12;
        setSelectedMonthRange(value);
        break;
    }
    callback({ selectedMonthRange: value, ...selectedMeasureFilters });
    if (filters && monthRange) {
      filters.data.monthRange = monthRange;
      getAPISimulationValuesData({
        ...filters.data,
        somMeasure:
          filter && filter.somMeasure
            ? filter.somMeasure
            : selectedMeasureFilters.somMeasure,
        somGroup:
          filter && filter.somGroup
            ? filter.somGroup
            : selectedMeasureFilters.somGroup,
        proposedApi: 0,
        proposedPpuAnchor: 0,
        proposedPpuPepsi: 0,
      });
    }
  };

  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    temp[type] = selectedVal;
    setMeasureFilter(temp);
    callback({ selectedMonthRange, ...temp });
    getAPISimulationValuesData({ ...filters.data, ...temp });
  };

  const getAPISimulationValuesData = (payload) => {
    setValueSkeleton(true);
    dispatch(simulationValueLoader(true));
    setAPISimulationValueData({});
    getAPISimulationValuesChartData(payload)
      .then((response) => {
        if (response && response.data) {
          setValueSkeleton(false);
          dispatch(simulationValueLoader(false));
          setAPISimulationValueData(response.data);
        }
      })
      .catch((error) => {
        setValueSkeleton(false);
        dispatch(simulationValueLoader(false));
      });
  };

  const getAPISimulationData = (payload) => {
    setDataSkeleton(true);
    dispatch(simulationDataLoader(true));
    setOriginalAPISimulationData({});
    setAPISimulationData({});
    getAPISimulationChartData(payload)
      .then((response) => {
        if (response && response.data) {
          setDataSkeleton(false);
          dispatch(simulationDataLoader(false));
          setOriginalAPISimulationData(response.data);
          setAPISimulationData(response.data);
        }
      })
      .catch((error) => {
        setDataSkeleton(false);
        dispatch(simulationDataLoader(false));
      });
  };
  const reduxState = useSelector((state: any) => state.ApiPredictive);

  return (
    <Card className="m-b-20">
      <CardContent>
        <Dashboard
          title="API Simulation"
          showSkeleton={skeleton}
          chartRef={chartRef}
          id="api-predictive-dash-api-simulation-container"
        >
          <Grid container className="m-b-10" justifyContent="space-between">
            <Grid item>
              <Grid
                container
                spacing={0}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item className="m-r-20">
                  {skeleton ? (
                    <Skeleton width={150} height={22} />
                  ) : (
                    <DropdownTitle>SOM Variable Configuration</DropdownTitle>
                  )}
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid className="m-r-10 b-r-1">
                      <CheckBoxGroup
                        data={
                          apiPredictiveDashboardRowData.measureFilter
                            .SOMVarConfig.options
                        }
                        color="#000"
                        direction="row"
                        select={(data) => onSelectMeasure("somGroup", data)}
                        defaultOption={selectedMeasureFilters.somGroup}
                        showSkeleton={skeleton}
                        single={true}
                      />
                    </Grid>
                    <Grid>
                      <RadioButtonGroup
                        key={`measures-filters-som`}
                        color="#000"
                        showButton={
                          apiPredictiveDashboardRowData.measureFilter.SOM
                            .showRadioButton
                        }
                        data={
                          apiPredictiveDashboardRowData.measureFilter.SOM
                            .options
                        }
                        defaultOption={selectedMeasureFilters.somMeasure}
                        select={(data) => onSelectMeasure("somMeasure", data)}
                        direction="row"
                        showSkeleton={skeleton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={0}
                alignItems="center"
                className="m-b-10"
              >
                <Grid item className="m-r-20">
                  {skeleton ? (
                    <Skeleton width={150} height={22} />
                  ) : (
                    <DropdownTitle>
                      {apiPredictiveDashboardRowData.monthRangeFilter.title}
                    </DropdownTitle>
                  )}
                </Grid>
                <Grid item>
                  <RadioButtonGroup
                    key={`measures-filters-month-range`}
                    color="#000"
                    showButton={
                      apiPredictiveDashboardRowData.monthRangeFilter
                        .showRadioButton
                    }
                    data={
                      apiPredictiveDashboardRowData.monthRangeFilter.options
                    }
                    direction="row"
                    showSkeleton={skeleton}
                    defaultOption={selectedMonthRange}
                    select={onChangeDateRange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="m-b-20">
            {apiPredictiveDashboardRowData.APISimulation.map((item) => (
              <Grid item xs={6} md={3}>
                <GrossVolumeCard data={item} showSkeleton={valueSkeleton} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} className="m-b-20">
            {dataSkeleton || skeleton ? (
              <>
                <Grid item>
                  <Skeleton width={110} height={30} />
                </Grid>
                <Grid item>
                  <Skeleton variant="rectangular" width={160} height={40} />
                </Grid>
                <Grid item className="m-l-30">
                  <Skeleton width={110} height={30} />
                </Grid>
                <Grid item>
                  <Skeleton variant="rectangular" width={160} height={40} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        {filters.data.apiUnits === "Volume"
                          ? "Current PPV PepsiCo"
                          : "Current PPU PepsiCo"}
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <Label style={{ backgroundColor: "#dbe7f3" }}>
                        {currentPpuPepsico}
                      </Label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        {filters.data.apiUnits === "Volume"
                          ? "Current PPV Anchor"
                          : "Current PPU Anchor"}
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <Label style={{ backgroundColor: "#dbe7f3" }}>
                        {currentPpuAnchor}
                      </Label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        Current API
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <Label style={{ backgroundColor: "#dbe7f3" }}>
                        {currentApi}
                      </Label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        {filters.data.apiUnits === "Volume"
                          ? "Proposed PPV PepsiCo"
                          : "Proposed PPU PepsiCo"}
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <TextField InputProps={{ autoComplete: 'off' }}
                        id="proposed-api"
                        type="number"
                        value={proposedPpuPepsico}
                        size="small"
                        onChange={(e) => setProposedPpuPepsico(e.target.value)}
                        onKeyDown={onSubmitProposedPpuPepsico}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        {filters.data.apiUnits === "Volume"
                          ? "Proposed PPV Anchor"
                          : "Proposed PPU Anchor"}
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <TextField InputProps={{ autoComplete: 'off' }}
                        id="proposed-api"
                        type="number"
                        value={proposedPpuAnchor}
                        size="small"
                        onChange={(e) => setProposedPpuAnchor(e.target.value)}
                        onKeyDown={onSubmitProposedPpuAnchor}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <DropdownTitle className="m-r-10">
                        Proposed API
                      </DropdownTitle>
                    </Grid>
                    <Grid item>
                      <Label style={{ backgroundColor: "#dbe7f3" }}>
                        {parseFloat(proposedApi)?.toFixed(2)}
                      </Label>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {dataSkeleton ? (
            <Grid>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={350} />
              </Grid>
              <Grid container justifyContent="center">
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={40}
                  className="m-t-20"
                />
              </Grid>
            </Grid>
          ) : filters &&
            filters.data &&
            filters.data.packSize &&
            filters.data.anchorPackSize ? (
            APISimulationData &&
            _.isArray(APISimulationData.variable1) &&
            APISimulationData.variable1.length > 0 &&
            _.isArray(APISimulationData.variable2) &&
            APISimulationData.variable2.length > 0 ? (
              <>
                <HighchartContainer
                  chartRef={chartRef}
                  id={"api-predictive-dash-api-simulation-container"}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={APISimulationGraphConfig}
                    ref={chartRef}
                  />
                  <Grid
                    style={{
                      position: "relative",
                      bottom: 50,
                      left: 5,
                      zIndex: 2,
                      width: "100%",
                    }}
                  >
                    {/* <IncrementDecrement
                      chartRef={chartRef}
                      data={originalAPISimulationData}
                      selectedResponse={setAPISimulationData}
                      show={!reduxState.simulationDataLoader}
                    /> */}
                  </Grid>
                </HighchartContainer>

                <Grid container justifyContent="center">
                  <GraphLabel {...setColor(correlation, "correlation")}>
                    Correlation {correlation}%
                  </GraphLabel>
                </Grid>
              </>
            ) : (
              <Box
                style={{ height: 400 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )
          ) : (
            <Box
              style={{ height: 400 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <NoData>{messages.incorrectFilters}</NoData>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default APISimulation;
