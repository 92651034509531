import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, CardContent } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import DashboardPreviewCard from '../../components/DashboardPreviewCard';
import _ from 'lodash';
import data from '../../mocks/priceArchitecture';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from '../../components/Dropdown';
import { Title } from '../../styles/Common.Styled';
import { standardSortOption, pageList, dashboardCardMock } from '../../mocks/common';
import { IconButton, Typography } from '@material-ui/core';
import NoDashboard from '../../components/NoDashboard/NoDashboard';
import Utilities from '../../util/Utilities';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { getCategoryData } from './service';
const useStyles = makeStyles((theme) => ({
  filterTitle: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#000',
  },
  fevBtn: {
    height: '46px',
    color: '#CE0065',
    textTransform: 'capitalize',
    width: '100%',
  },
  fevIcon: {
    fontSize: '40px !important',
    color: '#0093CD',
  },
  leftBorder: {
    borderLeft: '11px solid #0093CD',
  },
  notiAlertTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  notiAlertDescription: {
    color: '#000',
    fontSize: 14,
  },
  closeBtn: {
    fontSize: 12,
  },
}));
const CategoryDahboardList: React.FC<{ title: any; categoryKey: any; pageName: any }> = ({ title, categoryKey, pageName }) => {
  const classes = useStyles();
  // const [types, setType] = useState(null);
  const [countries, setCountries] = useState(null);
  const [allDashboards, setDashboard] = useState([]);
  const [sortedDashboards, setSortedDashbord] = useState([]);
  // const [selectedType, setSelectedType] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [skeleton, setSkeleton] = useState(true);

  const onChangeType = (data) => {
    const sortedData = allDashboards.filter((x) => {
      return _.includes(data, x.type);
    });
    const countries = _.uniqBy(
      _.flattenDeep(_.map(sortedData, (r) => r.countries)).map((c, index) => ({
        id: index,
        value: c,
      })),
      'value'
    );
    // setSelectedType(data);
    if (_.isEmpty(data)) {
      setSelectedCountries(null);
    } else {
      setSelectedCountries(countries);
    }
    setSortedDashbord(sortedData);
    setCountries(countries);
    setSelectedSort(null);
  };

  const onChangeCountry = (country) => {
    // const sortedDashboards = allDashboards.filter((x) => {
    //   return _.includes(selectedType, x.type);
    // });
    const sortedData = allDashboards.filter((x) => {
      if (_.isString(country)) {
        return x.countries.findIndex((y) => y === country) !== -1;
      } else if (_.isArray(country)) {
        return x.countries.findIndex((y) => _.includes(country, y)) !== -1;
      }
    });
    setSelectedCountries(country);
    setSelectedSort(null);
    setSortedDashbord(sortedData);
  };

  const onSort = (data) => {
    let sortedData = [];
    switch (data[0]) {
      case 'newest':
        sortedData = Utilities.sortArray(sortedDashboards, 'createdOn');
        break;
      case 'most-popular':
        sortedData = Utilities.sortArray(sortedDashboards, 'viewCount', 'desc');
        break;
      case 'a-z':
        sortedData = Utilities.sortArray(sortedDashboards, 'name');
        break;
      case 'z-a':
        sortedData = Utilities.sortArray(sortedDashboards, 'name', 'desc');
        break;
    }
    setSelectedSort(data);
    setSortedDashbord(sortedData);
  };
  let { descriptionAlertInput } = data;

  useEffect(() => {
    setSkeleton(true);
    getData();
  }, []);

  const getData = () => {
    getCategoryData(categoryKey)
      .then((response) => {
        const type = _.uniqBy(response.data, 'type').map((x: any) => ({
          label: x.type,
          value: x.type,
        }));
        const countries = _.uniqBy(
          _.flattenDeep(_.map(response.data, (r) => r.countries)).map((c) => ({
            label: c,
            value: c,
          })),
          'value'
        );
        // setType(type);
        // setSelectedType(type.map((x) => x.value));
        setCountries(countries);
        setSelectedCountries(countries.map((x) => x.value));
        setDashboard(response.data);
        setSortedDashbord(response.data);
        setSkeleton(false);
      })
      .catch((error) => {
        setSkeleton(false);
      });
  };

  return (
    <Box>
      <Title className='m-b-20'>{title}</Title>
      <Card className='m-b-20'>
        <CardContent>
          <Grid container display='flex' alignItems='center' spacing={2}>
            <Grid item xs={12} sm={1}>
              <Typography className={classes.filterTitle}>Filters :</Typography>
            </Grid>
            {/* <Grid item xs={12} sm={2}>
              <Button className={classes.fevBtn} onClick={favoriteClickHandler} variant='outlined'>
                Favorites (12)
              </Button>
            </Grid> */}
            {/* <Grid item xs={12} sm={2}>
              <Dropdown
                onChange={(data) => {
                  onChangeType(data);
                }}
                defaultOption={selectedType || ['empty']}
                keyText={`price-architecture-type-dropdown`}
                data={types}
                placeholder='Type'
                allOption={true}
                defaultSelectAll={true}
                multiple={true}
                multipleSelectionMsg='Multiple'
                search={{ enable: false }}
              />
            </Grid> */}
            <Grid item xs={12} sm={3}>
              <Dropdown
                onChange={(data) => {
                  onChangeCountry(data);
                }}
                defaultOption={selectedCountries || ['empty']}
                keyText={`price-architecture-country-dropdown`}
                data={countries}
                placeholder='Country Availability'
                allOption={true}
                defaultSelectAll={true}
                multiple={true}
                multipleSelectionMsg='Multiple'
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Dropdown
                onChange={(data) => {
                  onSort(data);
                }}
                defaultOption={selectedSort || ['empty']}
                keyText={'price-architecture-sort-by-dropdown'}
                data={standardSortOption}
                placeholder='Sort By'
                search={{ enable: false }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Description alert */}
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageName} />
      </Grid>
      {/* {descriptionAlertInput.map((item, index) => (
        <Card className={`m-b-20 ${classes.leftBorder}`} key={`price-architecture-description-alert-${index}`}>
          <CardContent>
            <Grid container>
              <Grid item xs={2} sm={'auto'} display='flex' alignItems='center'>
                <FavoriteIcon className={classes.fevIcon} />
              </Grid>
              <Grid item xs>
                <Typography className={classes.notiAlertTitle}>{item.title}</Typography>
                <Typography className={classes.notiAlertDescription}>{item.description}</Typography>
              </Grid>
              <Grid item xs={2} sm={'auto'} display='flex' alignItems='center'>
                <IconButton aria-label='delete' size='small' className={classes.closeBtn}>
                  Close
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))} */}
      {/* Dashboard preview */}
      {!skeleton ? (
        <Grid container spacing={2} className='m-b-20'>
          {sortedDashboards && sortedDashboards.length ? (
            sortedDashboards.map((item, index) => (
              <Grid item xs={12} sm={3} key={`price-architecture-dashboard -preview-card-alert-${index}`}>
                <DashboardPreviewCard data={item} onToggleFavorite={getData} />
              </Grid>
            ))
          ) : (
            <Grid xs={12} justifyContent='center'>
              <NoDashboard heading='No dashboards available' />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={2} className='m-b-15'>
          {dashboardCardMock.map((item, index) => (
            <Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-cards-${index}`}>
              <DashboardPreviewCard data={item} skeleton={true} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default CategoryDahboardList;
