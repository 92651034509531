import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const LoadMoreBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.main,
  fontSize: "12px",
  width: "160px",
  height: "45px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
  },
}));

export { LoadMoreBtn };
