import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Dashboard from '../../../../components/Dashboard';
import { GraphLabel, NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { dataFormat, formatNumber, plotImages, rendarImage, setColor, windowResizeListner } from '../../../../util/helper';
import { MultipleAPIChartConfig } from '../../chartsConfig';
import HighchartContainer from '../../../../components/HighchartContainer';
import { getMultipleAPIChartData } from '../../service';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { multipleAPIDataLoader } from '../../store/apiPredictive.action';
import { useDispatch } from 'react-redux';
const defaultPeriodicity = 'Weekly';
const visualId = 'api-predictive-dash-multiple-api-container';

const MultipleAPI: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [productImages, setProductImages] = useState<any>([]);
  const [correlations, setCorrelations] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [multipleAPIData, setMultipleAPIData] = useState({} as any);
  const [periodicityState, setPeriodicityState] = useState('');

  React.useEffect(() => {
    if (multipleAPIData && Object.keys(multipleAPIData).length) {
      setTimeout(() => {
        formatCorrelationData(multipleAPIData);
      }, 0);
    }
  }, [multipleAPIData]);

  const formatCorrelationData = async (data) => {
    if (data.products && data.products.length) {
      const dates = data.products[0].productData.map((item) => item.date);
      // const formattedDate = dataFormat(dates, "MM/yy");
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        while (chart.series.length) {
          chart.series[0].remove();
        }
        chart.xAxis[0].setCategories(dates);
        const newCorrelations: any = [];
        chart.addSeries({
          data: data.anchor.map((item) => item.value),
          name: `Anchor Variable - ${filters.data.variable2}`,
          type: 'area',
          yAxis: 1,
          tooltip: {
            pointFormatter: function () {
              return formatNumber(this.y);
            },
          },
        });

        data.products.map((item) => {
          chart.addSeries({
            type: 'line',
            data: (item.productData && item.productData.map((s) => s.value)) || [],
            name: item.name,
            tooltip: {
              pointFormatter: function () {
                return formatNumber(this.y);
              },
            },
          });
          if (item.correlation) {
            newCorrelations.push({
              name: item.name,
              value: item.correlation,
            });
          }
        });

        if (data.products[0].productName || data.products[1].productName) {
          let product1: any = null;
          let product2: any = null;
          if (data.products[0].productName) {
            product1 = {
              base64: await rendarImage(data.products[0].productName, filters.data.country),
            };
          }
          if (data.products[1].productName) {
            product2 = {
              base64: await rendarImage(data.products[1].productName, filters.data.country),
            };
          }

          if (product1 && product1.base64 && product2 && product2.base64) {
            setProductImages([product1, product2]);
          } else if (product1 && product1.base64) {
            setProductImages([product1]);
          } else if (product2 && product2.base64) {
            setProductImages([product2]);
          }
        }
        setLabels(chart);
        setTimeout(() => {
          setCorrelations([...newCorrelations]);
        }, 0);
      }
    }
  };

  const setLabels = (chart) => {
    const data = filters.data;
    if (data && data.variable1 && data.variable2) {
      chart.yAxis[0].setTitle({ text: data.variable1 });
      chart.yAxis[1].setTitle({ text: data.variable2 });
    }
  };

  React.useEffect(() => {
    if (filters && filters.type === 'local') {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        while (chart.series.length) {
          chart.series[0].remove();
        }
      }
      setPeriodicityState(defaultPeriodicity);
      onChangePeriodicity(defaultPeriodicity);
    } else if (filters && filters.type === 'global') {
      setSkeleton(true);
      dispatch(multipleAPIDataLoader(false));
      setMultipleAPIData({});
    }
  }, [filters]);

  const onChangePeriodicity = (periodicity) => {
    // if (periodicityState !== periodicity) {
    setSkeleton(true);
    dispatch(multipleAPIDataLoader(true));
    // setMultipleAPIData({});
    getMultipleAPIChartData({ ...filters.data, periodicity: periodicity })
      .then((response) => {
        setSkeleton(false);
        dispatch(multipleAPIDataLoader(false));
        setMultipleAPIData({ ...response.data });
      })
      .catch((error) => {
        setMultipleAPIData({});
        setSkeleton(false);
        dispatch(multipleAPIDataLoader(false));
      });
    // }
  };

  useEffect(() => {
    if (productImages.length > 0 && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const config = {
        productImages: productImages,
        id: 'api-predictive-dash-multiple-api-image',
        imgPosition: { x: 220, y: 300 },
        imgDimension: { width: 75, height: 60 },
        nameDimension: { width: 50, height: 55 },
      };
      windowResizeListner(chart, config);
      plotImages(chart, config);
    }
  }, [productImages]);

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard title="Multiple API's" showSkeleton={skeleton} chartRef={chartRef} id={visualId}>
          {multipleAPIData && multipleAPIData.products && _.isArray(multipleAPIData.products) && multipleAPIData.products.length > 0 && (
            <Grid style={{ position: 'relative', top: 400, left: 20, zIndex: 2, width: '100%' }}>
              <IncrementDecrement
                callback={(data) => {
                  if (periodicityState !== data.item) {
                    onChangePeriodicity(data.item);
                    setPeriodicityState(data.item);
                  }
                }}
                list={['Weekly', 'Monthly', 'Quarterly', 'Yearly']}
                show={multipleAPIData && Object.keys(multipleAPIData).length > 0 && !skeleton}
                id={visualId}
                defaultOption={
                  (localStorage.getItem('periodicity') &&
                    JSON.parse(localStorage.getItem('periodicity'))[visualId] &&
                    JSON.parse(localStorage.getItem('periodicity'))[visualId]?.item) ||
                  null
                }
              />
            </Grid>
          )}
          {skeleton ? (
            <>
              <Skeleton variant='rectangular' height={350} />
              <Grid container justifyContent='center' gap={2}>
                <Skeleton variant='rectangular' width={150} height={40} className='m-t-20' />
                <Skeleton variant='rectangular' width={150} height={40} className='m-t-20' />
              </Grid>
            </>
          ) : multipleAPIData && multipleAPIData.products && _.isArray(multipleAPIData.products) && multipleAPIData.products.length > 0 ? (
            <>
              <HighchartContainer chartRef={chartRef} id={visualId}>
                <HighchartsReact highcharts={Highcharts} options={MultipleAPIChartConfig} ref={chartRef} />
              </HighchartContainer>

              <Grid container justifyContent='center' gap={2}>
                {_.isArray(correlations) &&
                  correlations.length > 0 &&
                  correlations.map((correlation) => {
                    return (
                      <GraphLabel {...setColor(correlation.value, 'correlation')}>
                        Correlation {correlation.name} {Math.round(correlation.value)}%
                      </GraphLabel>
                    );
                  })}
              </Grid>
            </>
          ) : (
            <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.noData}</NoData>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default MultipleAPI;
