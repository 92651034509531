import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, List, Popover, ListItem, ListItemButton, styled, Typography } from '@mui/material';
import { Title, Icon } from '../../styles/Common.Styled';
import { save, share, download } from '../../assets/images/dashboard';
import SaveDashboardPopup from './SaveDashboardPopup';
import { getAllDashboards, getDashboardData, onShareDashboard, saveDashBoardData } from '../../util/services';
import _ from 'lodash';
import ShareConfirmationPopup from './components/ShareConfirmationPopup';
import { toast } from '../../store/actions/toast.action';
import { downloadFile, removeMMPWFromLocal, storeMMPWData, storeMMPWFooterData } from '../../util/helper';
import { saveTrendingPageData } from '../../store/actions/YourDashboard/trendingPage.action';
import { pageList } from '../../mocks/common';
import { loader } from '../../store/actions/common.action';
import { NoPermission } from '../../assets/images';
import { DateTime } from 'luxon';

const MessageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

const DashboardActions: React.FC<{
  title: string;
  data?;
  disabled?: boolean;
  dashboardKey?: string;
  callback?;
  disableDownloadButton?;
}> = ({ title, children, data, disabled, dashboardKey, callback, disableDownloadButton = true }) => {
  const [saveShareRecordId, setSaveShareRecordId] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const [dashboardName, setDashboardName] = useState('');
  const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
  const [openShareConfirm, setOpenShareConfirm] = useState<boolean>(false);
  const [openSaveOptions, setOpenSaveOptions] = useState(null);
  const [dashboardData, setDashboardData] = useState({});
  const [isSharedDashboard, setIsSharedDashboard] = useState(null);
  const search = window.location.search;
  const [urlParams] = useState(new URLSearchParams(search));
  const [hasNoCountryCategoryPermission, setHasNoCountryCategoryPermission] = useState({ countries: [], categories: [] });
  const userDetail = useSelector((state: any) => state.User.data);
  const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
  const dispatch = useDispatch();
  const saveOption = Boolean(openSaveOptions);
  useEffect(() => {
    removeMMPWFromLocal('All');
    const shared = urlParams.get('shared');
    setIsSharedDashboard(shared);
    const recordId = parseInt(urlParams.get('id'));
    setSaveShareRecordId(recordId);
    const dashboard = pageList.find((p) => p.id === dashboardKey);
    if (dashboard) {
      dispatch(saveTrendingPageData({ pageName: dashboard.lable, type: 'dashboard' }));
    }
  }, []);

  useEffect(() => {
    if (saveShareRecordId && userDetail) {
      retriveData(saveShareRecordId);
    }
  }, [saveShareRecordId, userDetail]);

  const retriveData = async (id) => {
    if (id) {
      dispatch(loader(true));
      try {
        const type = isSharedDashboard ? 'share' : 'save';
        const response = await getDashboardData(id, type);
        //check if user has permission for the country and category
        let filters = JSON.parse(response?.data?.filters);
        if (!isSharedDashboard || (isSharedDashboard && checkPermission(filters.global))) {
          if (response?.data && response?.data.mmpw) {
            let mmpwData = JSON.parse(response?.data.mmpw);
            let footerNoteData = {};
            Object.keys(mmpwData).map((key) => {
              footerNoteData[key] = mmpwData[key].footerNote;
              delete mmpwData[key].footerNote;
            });
            storeMMPWData(mmpwData);
            storeMMPWFooterData(footerNoteData);
          }
          setDashboardName(response.data.name);
          setDashboardData({
            ...response.data,
            country: userDetail?.defaultCountry,
            category: userDetail?.defaultCategory,
          });
          callback(response.data);
        }
        dispatch(loader(false));
      } catch (error) {
        dispatch(loader(false));
      }
    }
  };

  const checkPermission = (filters) => {
    const permissionCountries = userDetail.permissionCountry.split(',');
    const permissionCategories = userDetail.permissionCategory.split(',');
    let countriesNotHavePermission = [],
      categoriesNotHavePermission = [];
    if (_.isArray(filters.country)) {
      countriesNotHavePermission = filters.country.filter((c) => permissionCountries.findIndex((t) => t === c) === -1);
    } else if (filters.country && permissionCountries.findIndex((p) => p === filters.country) === -1) {
      countriesNotHavePermission = [filters.country];
    }
    if (_.isArray(filters.category)) {
      categoriesNotHavePermission = filters.category.filter((c) => permissionCategories.findIndex((t) => t === c) === -1);
    } else if (filters.category && permissionCountries.findIndex((p) => p === filters.category) === -1) {
      categoriesNotHavePermission = [filters.category];
    }
    setHasNoCountryCategoryPermission({ countries: countriesNotHavePermission, categories: categoriesNotHavePermission });
    return countriesNotHavePermission.length === 0 && categoriesNotHavePermission.length === 0;
  };

  const saveHandler = () => {
    if (dashboardName && !isSharedDashboard) {
      saveDashboard(dashboardName);
    } else {
      onSaveOptionClose();
      setOpenSavePopup(true);
    }
  };

  const shareHandler = () => {
    setOpenShareConfirm(true);
  };

  const onSaveOption = (e) => {
    setOpenSaveOptions(e.currentTarget);
  };

  const onSaveOptionClose = () => {
    setOpenSaveOptions(null);
  };

  useEffect(() => {
    if (dashboardKey) {
      getDashboardId();
    }
  }, [dashboardKey]);

  const getDashboardId = async () => {
    try {
      const response = await getAllDashboards();
      const dashboardDetail = response.data.find((x) => x.route.includes(dashboardKey));
      setDashboardId(dashboardDetail.id);
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedCountryAndCategory = (filters) => {
    return {
      country: _.isArray(filters?.global?.country) && filters?.global?.country.length > 0 ? filters?.global?.country[0] : filters?.global?.country,
      category: filters?.global.category
        ? _.isArray(filters?.global.category)
          ? filters?.global.category.join(',')
          : filters?.global.category
        : filters?.global.anchorCategory
        ? _.isArray(filters?.global.anchorCategory)
          ? filters?.global.anchorCategory.join(',')
          : filters?.global.anchorCategory
        : filters?.global.pepsi && filters?.global.pepsi.category
        ? _.isArray(filters?.global.pepsi.category)
          ? filters?.global.pepsi.category.join(',')
          : filters?.global.pepsi.category
        : '',
    };
  };

  const saveDashboard = async (name, sharedDashobardData?) => {
    dispatch(loader(true));
    const trimmedName = name.trim();
    let mmpwData = {};
    if (data.mmpwKey) {
      let dashbaordData = JSON.parse(localStorage.getItem('mmpw') || '{}');
      if (dashbaordData && Object.keys(dashbaordData).length > 0) {
        Object.keys(dashbaordData).map((key) => {
          if (key.includes(data.mmpwKey)) {
            mmpwData = {
              ...mmpwData,
              [key]: { ...dashbaordData[key] },
            };
          }
        });
      }
      let footerNote = JSON.parse(localStorage.getItem('mmpw-footer') || '{}');
      if (footerNote && Object.keys(footerNote).length > 0) {
        Object.keys(footerNote).map((key) => {
          if (key.includes(data.mmpwKey)) {
            if (mmpwData[key]) {
              mmpwData[key]['footerNote'] = footerNote[key];
            } else {
              mmpwData = {
                ...mmpwData,
                [key]: { ...footerNote[key], footerNote: footerNote[key] },
              };
            }
          }
        });
      }
    }
    const formatedData = {
      ...getSelectedCountryAndCategory(data.filters),
      filters: JSON.stringify({ ...data.filters, periodicity: localStorage.getItem('periodicity') }),
      name: trimmedName,
      key: dashboardKey,
      version: '1.0',
      mmpw: JSON.stringify(mmpwData),
      dashboardId,
      ...(sharedDashobardData && sharedDashobardData.length > 0 ? { emailAddresses: sharedDashobardData } : {}),
      isShared: sharedDashobardData?.length > 0,
      id: saveShareRecordId ? saveShareRecordId : 0,
      other: '',
    };
    if (trimmedName) {
      if (sharedDashobardData && sharedDashobardData.length > 0) {
        try {
          const response = await onShareDashboard({ ...formatedData, id: 0 });
          if (response && response.status === 200) {
            dispatch(toast('Dashboard Shared Successfully!', true, 2000, 'success'));
            setOpenShareConfirm(false);
            dispatch(loader(false));
          } else {
            dispatch(toast(response.response.data.userMessage, true, 2000, 'error'));
            dispatch(loader(false));
          }
        } catch (error) {
          dispatch(toast('Something went wrong', true, 2000, 'error'));
          dispatch(loader(false));
        }
      } else {
        try {
          const response = await saveDashBoardData({ ...formatedData, id: isSharedDashboard ? 0 : formatedData.id });
          if (response && response.status === 200) {
            dispatch(toast('Dashboard Saved Successfully!', true, 2000, 'success'));
            setOpenSavePopup(false);
            dispatch(loader(false));
          } else {
            dispatch(toast(response.response.data.userMessage, true, 2000, 'error'));
            dispatch(loader(false));
          }
        } catch (e) {
          dispatch(toast('Something went wrong', true, 2000, 'error'));
          dispatch(loader(false));
        }
      }
    }
  };

  const downloadExcel = () => {
    dispatch(loader(true));
    downloadFile(`${process.env.REACT_APP_BACKEND_BASE_URL}files/downloadfile/${dashboardKey}`)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = new Blob([resp], { type: 'application/vnd.ms-excel' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.download = `${dashboardKey}${DateTime.now().toFormat('yyyy-MM-dd')}.xlsx`;
        link.click();
        dispatch(loader(false));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isLoader = useSelector((state: any) => state.common.loader);
  return (
    <>
      {isLoader || (hasNoCountryCategoryPermission.countries.length === 0 && hasNoCountryCategoryPermission.categories.length === 0) ? (
        <Fragment>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Title className='m-b-20'>{title}</Title>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className='m-b-10'>
              <Grid container display='flex' justifyContent='flex-end'>
                <a onClick={(e) => !disabled && onSaveOption(e)}>
                  <Icon src={String(save)} />
                </a>
                <a onClick={() => !disabled && shareHandler()}>
                  <Icon src={String(share)} />
                </a>
                <a onClick={() => !disableDownloadButton && downloadExcel()}>
                  <Icon src={String(download)} />
                </a>
                <Popover
                  open={saveOption}
                  anchorEl={openSaveOptions}
                  onClose={onSaveOptionClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton onClick={saveHandler}>
                        {saveShareRecordId && !isSharedDashboard ? 'Update to RevolutioM' : 'Save to RevolutioM'}
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          {children}
          {openSavePopup && <SaveDashboardPopup onCloseHandler={() => setOpenSavePopup(false)} onSaveHandler={saveDashboard} value={dashboardName} />}
          {openShareConfirm && (
            <ShareConfirmationPopup
              onCloseHandler={() => setOpenShareConfirm(false)}
              shareData={isSharedDashboard ? dashboardData : { ...dashboardData, id: null }}
              userDetail={userDetail}
              onShare={saveDashboard}
            />
          )}
        </Fragment>
      ) : (
        <Grid>
          <Grid display='flex' justifyContent='center' alignItems='center' className='m-b-20'>
            <NoPermission width='120' fill={theme.darkmode ? '#0093CD' : '#0093CD'} />
          </Grid>
          <Grid display='flex' justifyContent='center' alignItems='center' className='m-b-20'>
            {hasNoCountryCategoryPermission.categories.length > 0 ? (
              <MessageTitle>
                Please request access to categories : {hasNoCountryCategoryPermission.categories.join(',')} to view the dashboard{' '}
                {urlParams.get('shared-by') ? `shared by ${urlParams.get('shared-by')}` : null}
              </MessageTitle>
            ) : null}
          </Grid>
          <Grid display='flex' justifyContent='center' alignItems='center'>
            {hasNoCountryCategoryPermission.countries.length > 0 ? (
              <MessageTitle>
                Please request access to country : {hasNoCountryCategoryPermission.countries.join(',')} to view the dashboard{' '}
                {urlParams.get('shared-by') ? `shared by ${urlParams.get('shared-by')}` : null}
              </MessageTitle>
            ) : null}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default DashboardActions;
