import { Card, CardContent, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable/CustomTable';
import DashboardActions from '../../components/DashboardActions';
import TopFilters from './components/TopFilters';
import { prodxrefDataLoader } from './store/ProdXref.action';
import { fetchProdXrefTableData } from './service';
import _ from 'lodash';
import { pascaleCase } from '../../util/helper';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageNames } from '../../mocks/common';
import Indicator from '../../components/Loader/Loader';

const skeletonHeading = [1, 2, 3, 4, 5];
const skeletonValues = [
  { a: '', b: '', c: '', d: '', e: '' },
  { a: '', b: '', c: '', d: '', e: '' },
  { a: '', b: '', c: '', d: '', e: '' },
  { a: '', b: '', c: '', d: '', e: '' },
];
const rowsPerPage = 20;

const ProductXREF: React.FC<{}> = () => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [tableValues, setTableValues] = useState<any>([]);
  const [skeleton, setSkeleton] = React.useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const dispatch = useDispatch();

  const onApplyFilter = (data) => {
    if (data && data.filters) {
      setAppliedFilters(data.filters);
    }
  };

  useEffect(() => {
    if (appliedFilters && appliedFilters.country) {
      getTableData(0, rowsPerPage);
    }
  }, [appliedFilters]);

  const getTableData = async (page, rowsPerPage) => {
    dispatch(prodxrefDataLoader(true));
    if (appliedFilters && _.isArray(appliedFilters.columnNames)) {
      await fetchProdXrefTableData({
        ...appliedFilters,
        rowStart: rowsPerPage * page,
        rowEnd: rowsPerPage + rowsPerPage * page,
      })
        .then((response) => {
          if (response && response.data) {
            console.log(response.data);
            setTableValues(response.data.records);
            setTotalRows(response.data.total);
            setSkeleton(false);
            dispatch(prodxrefDataLoader(false));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const ProdXrefDataLoader = useSelector((state: any) => state.ProdXrefDataLoader);
  return (
    <PageContainer page={pageNames.productXREF}>
      <DashboardActions title='Product XREF'>
        <TopFilters defaultFilters={{}} callback={onApplyFilter} />
        <Card sx={{ mt: 5 }} style={{ position: 'relative' }}>
          <Indicator position='absolute' show={ProdXrefDataLoader.prodxrefDataLoader} />
          <CardContent>
            {skeleton ? (
              <CustomTable heading={skeletonHeading} value={skeletonValues} showSkeleton={skeleton} />
            ) : (
              <Grid
                container
                style={{
                  maxWidth: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)',
                }}
              >
                <CustomTable
                  heading={appliedFilters?.columnNames.map((x) => ({ title: pascaleCase(x, '_') }))}
                  value={tableValues}
                  showSkeleton={skeleton}
                  pagination={{ enable: true, rowsPerPage }}
                  totalRows={totalRows}
                  paginationCallback={(data) => getTableData(data.page, data.rowsPerPage)}
                />
              </Grid>
            )}
          </CardContent>
        </Card>
      </DashboardActions>
    </PageContainer>
  );
};

export default ProductXREF;
