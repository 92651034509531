import React from "react";
import { CardContent, Skeleton } from "@mui/material";
import {
  StyledCard,
  Title,
  Value,
  Profit,
  Loss,
} from "./GrossVolumeCard.Styled";
import { formatNumber } from "../../../../../util/helper";

const GrossVolumeCard: React.FC<{ data: any; showSkeleton?: boolean }> = ({
  data,
  showSkeleton,
}) => {
  return (
    <StyledCard>
      {!showSkeleton ? (
        <>
          <CardContent>
            <Title data-testid="grossVolumeCardTitle">{data.title}</Title>
            {!data.isNA ? (
              <>
                <Value
                  data-testid="grossVolumeCardValue"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    justifyContent: "flex-start",
                  }}
                >
                  <span style={{ marginRight: "10px", float: "left" }}>
                    Current: {data.value}
                  </span>
                  <span
                    style={{
                      marginLeft: "16px",
                      float: "right",
                    }}
                  >
                    Simulated: {data.simulated}
                  </span>
                </Value>
                <Value
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    fontSize: "16px",
                  }}
                >
                  {data.percentVar.toString() && data.percentVar >= 0 ? (
                    <Profit data-testid="profitIcon">
                      +{data.percentVar.toFixed(2)} {"%"}
                    </Profit>
                  ) : (
                    <Loss data-testid="lossIcon">
                      {data.percentVar.toFixed(2)} {"%"}
                    </Loss>
                  )}
                </Value>
                {data.title === "Share Of Market" ? (
                  <></>
                ) : (
                  <Value
                    data-testid="grossVolumeCardValue"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      justifyContent: "flex-start",
                    }}
                  >
                    <span style={{ marginRight: "10px", float: "left" }}>
                      Anchor: {data.anchor}
                    </span>
                  </Value>
                )}
              </>
            ) : (
              <Value
                display={"flex"}
                justifyContent={"center"}
                style={{ fontSize: "16px" }}
              >
                NA
              </Value>
            )}
          </CardContent>
        </>
      ) : (
        <>
          <Title>
            <Skeleton />
          </Title>
          <Value>
            <Skeleton />
          </Value>
          {data && <Skeleton />}
        </>
      )}
    </StyledCard>
  );
};

export default GrossVolumeCard;
