import {
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATION_SUCCESS,
  DISMISS_NOTIFICATION_FAILURE,
} from "./constants";
import { API } from "../../../util/helper";

export const resetNotification = () => {
  return {
    type: DISMISS_NOTIFICATION,
  };
};

export const dissmissNotificationSuccess = (payload: any) => {
  return {
    type: DISMISS_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const dissmissNotificationFailure = (error) => {
  return {
    type: DISMISS_NOTIFICATION_FAILURE,
    payload: error,
  };
};

const dissmissNotificationData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}notifications/${payload.id}/dismiss`,
    payload
  );
};

export function dissmissNotification(payload) {
  return (dispatch: any) => {
    dispatch(resetNotification());
    dissmissNotificationData(payload)
      .then((response) => {
        if(response.data){
          dispatch(dissmissNotificationSuccess(response.data));
        }else{
          dispatch(dissmissNotificationSuccess(payload));
        }
      })
      .catch((error) => {
        dispatch(dissmissNotificationFailure(error));
      });
  };
}
