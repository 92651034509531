import { Button, Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Dashboard from '../../../../components/Dashboard/Dashboard';
import { PrimaryBtn } from '../../../../styles/Common.Styled';
import { fetchPermutations } from '../../service';
import VisualizationTable from './components/VisualizationTable';
const VisualizationContainer: React.FC<{
  filters;
  callback;
  defaultFilters;
}> = ({ filters, callback, defaultFilters }) => {
  const [visualizations, setVisualizations] = useState([]);
  const [topFilters, setTopFilters] = useState({});
  const [skeleton, setSkeleton] = useState(true);

  useEffect(() => {
    getPermutations();
    if (filters && filters.country && (!defaultFilters || !defaultFilters.length)) {
      setVisualizations([{ id: 1, filters: null }]);
    }
  }, [filters]);

  const getPermutations = () => {
    let permutations = [];
    if (filters && filters.country && filters.packSize) {
      const payload = {
        country: filters.country,
        category: filters.category,
        segment: filters.segment,
        brand: filters.brand,
        packSize: filters.packSize,
      };
      setSkeleton(true);
      fetchPermutations(payload)
        .then((response) => {
          if (response && response.data.permutation) {
            permutations = response.data.permutation.filter((item) => filters.permutation.findIndex((x) => x === item.value) !== -1);
            setTopFilters({ ...filters, permutation: permutations });
          }
          setSkeleton(false);
        })
        .catch((e) => {
          setTopFilters({ ...filters });
          setSkeleton(false);
        });
    }
  };

  useEffect(() => {
    if (_.isArray(defaultFilters) && defaultFilters.length > 0) {
      setVisualizations([...defaultFilters]);
    } else {
      setVisualizations([{ id: 1, filters: null }]);
    }
  }, [defaultFilters]);

  const onAddSenario = () => {
    const visuals = [...visualizations];
    visuals.push({ id: visuals.length + 1, filters: null });
    setVisualizations(visuals);
  };

  const onChangeVisualFilter = (data, i) => {
    const visuals = [...visualizations];
    visuals[i].filters = data;
    setVisualizations(visuals);
    callback(visuals);
  };

  return (
    <Card>
      <CardContent>
        {_.isArray(visualizations)
          ? visualizations.map((item, i) => (
              <Dashboard
                title={null}
                showSkeleton={skeleton}
                chartRef={() => {}}
                showActionButton={true}
                actionButtons={{
                  mmpw: false,
                  screenshot: true,
                  editMedia: false,
                }}
                id={null}
              >
                <VisualizationTable
                  index={i}
                  filters={topFilters}
                  defaultFilters={_.isArray(defaultFilters) && i < defaultFilters.length ? defaultFilters[i].filters : {}}
                  callback={(data) => {
                    onChangeVisualFilter(data, i);
                  }}
                  skeleton={skeleton}
                />
              </Dashboard>
            ))
          : null}
        <Grid sx={{ ml: 1 }}>
          <PrimaryBtn color='primary' onClick={onAddSenario}>
            Add Senario
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default VisualizationContainer;
