export const checkValidation = (data) => {
  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regexNumber = /^[0-9]{10}$/;
  const regexCountryCode = /^\+(\d{1}\-)?(\d{1,3})$/;

  if (data.key === "emailAddress") {
    if (!data.value || regexEmail.test(data.value) === false) {
      return {
        type: data.key,
        error: "Email Is Not Valid",
        valid: false,
      };
    }
  } else if (data.key === "phoneNumber") {
    if (!data.value || regexNumber.test(data.value) === false) {
      return {
        type: data.key,
        error: "Phone Number Is Not Valid",
        valid: false,
      };
    }
  }
  else if (data.key === "phoneNumberCountryCode") {
    if (!data.value || regexCountryCode.test(data.value) === false) {
      return {
        type: data.key,
        error: "Country Code Is Not Valid",
        valid: false,
      };
    }
  }
  return { valid: true };
};
