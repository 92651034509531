import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import Indicator from "../../../../components/Loader/Loader";
import FilterAccordion from "../../../../components/Filters/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import { initialState } from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import { dataSourceLoader } from "../../store/DataSourceRefresh.action";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
const TopFilters: React.FC<{ callback; defaultFilters? }> = ({
  callback,
  defaultFilters,
}) => {
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(
    defaultFilters ? defaultFilters : { ...initialState }
  );
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const dispatch = useDispatch();
  const DataSourceLoader = useSelector((state: any) => state.DataSourceLoader);
  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
    }
  }, [selectedGeoFilters.country]);
  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...selectedGeoFilters, country: null });
  };
  const onApplyFilter = async () => {
    dispatch(dataSourceLoader(true));
    callback({
      filters: {
        ...selectedGeoFilters,
      },
    });
  };
  const disableApplyFilter = () => {
    const flag = !selectedGeoFilters.country || !selectedGeoFilters.geoLevel2;
    return flag;
  };

  return (
    <Card style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || DataSourceLoader.dataSourceLoader}
      />
      <CardContent>
        <FilterAccordion title="Geo Filters">
          <GeoFiltersV2
            key="geo-xref-top-filter"
            data={geoFilterV2Config}
            onChangeDate={(dt) => {
              clearGeoFilter(false);
            }}
            onSelectFilters={(e) => setSelectedGeoFilters(e)}
            clearFilter={clearFilterData}
            apiPath="BrandLadder"
            showLoader={setLoader}
            defaultFilters={defaultFilters}
            showDatePicker={false}
          />
        </FilterAccordion>{" "}
        <Grid className="p-l-16">
          <OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn
            disabled={disableApplyFilter()}
            color="primary"
            onClick={onApplyFilter}
          >
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TopFilters;
