import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Skeleton } from "@mui/material";
import { Layer, Stage, Image, Text, Rect, Group } from "react-konva";
import _ from "lodash";
import { StyledTableCell, StyledTableHeadCell } from "./BrandLadderChart.Styled";
import { imageTagFromUrl, formatNumber, rendarImage } from "../../../../../../util/helper";
import { NoData, OrangeBtn, PrimaryBtn } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import { useSelector } from "react-redux";

const imageWidth = 60;
const imageHeight = 60;
// let pixelVal = 30;
const gap = 10;
// let singleIndexVal = pixelVal / gap;
const extraIndex = 2;
const emptyIndex = 1;

const BrandLadderChart: React.FC<{ indexes, pepsi, competitor, filters, callback?, showSkeleton, isSimulated, isDataLoaded, reset?}> = ({ indexes, pepsi, competitor, filters, callback, showSkeleton, isSimulated, isDataLoaded, reset }) => {
    const [pixelVal, setPixelVal] = useState(30);
    const [pepsiData, setPepsiData] = useState([]);
    const [competitorData, setCompetitorData] = useState([]);
    const [columnWidth, setColumnWidth] = useState(0);
    const [isPepsiDataLoaded, setIsPepsiDataLoaded] = useState<boolean>(false);
    const [isCompetitorDataLoaded, setIsCompetitorDataLoaded] = useState<boolean>(false);
    const [mixLevels, setMixLevels] = useState([]);
    const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
    const [productImages, setProductImages] = useState({});
    const [hideToBeColumn, setHideToBeColumn] = useState(false);
    const [simulationRequest, setSimulationRequest] = React.useState([]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [sidebarOpen])

    const handleResize = () => {
        const windowWidth = window.innerWidth - (sidebarOpen ? 320 : 153);
        setColumnWidth(windowWidth / 5);
    }

    useEffect(() => {
        if (_.isArray(pepsi)) {
            setSkeleton(true)
            let payload = [];
            Promise.all(
                pepsi?.map(async (item, i) => {
                    payload.push({
                        ...item,
                        image: imageTagFromUrl(await rendarImage(item.productName, filters.country)),
                        ...getXYPosition(payload, { yCurr: getYPosition(item, 'indexCurrent'), ySimu: getYPosition(item, 'indexSimulated') })
                    })
                })
            ).then(() => {
                //Cache the product images
                const newProductImages = { ...productImages };
                payload.map(item => newProductImages[item.productInfo] = item.image);
                setProductImages(newProductImages)

                payload = _.orderBy(payload, 'productInfo', 'asc');
                setPepsiData(payload);
                setIsPepsiDataLoaded(true);
            })
        } else {
            setPepsiData([]);
        }
    }, [pepsi])

    useEffect(() => {
        if (_.isArray(competitor)) {
            setSkeleton(true)
            let payload = [];
            Promise.all(
                competitor?.map(async (item) => {
                    payload.push({
                        ...item,
                        image: imageTagFromUrl(await rendarImage(item.productName, filters.country)),
                        ...getXYPosition(payload, { yCurr: getYPosition(item, 'indexCurrent'), ySimu: getYPosition(item, 'indexSimulated') })
                    })
                })
            ).then(() => {
                //Cache the product images
                const newProductImages = { ...productImages };
                payload.map(item => newProductImages[item.productInfo] = item.image);
                setProductImages(newProductImages)


                payload = _.orderBy(payload, 'productInfo', 'asc')
                setCompetitorData(payload);
                setIsCompetitorDataLoaded(true);
            })
        } else {
            setCompetitorData([]);
        }
    }, [competitor])

    useEffect(() => {
        if (isPepsiDataLoaded && isCompetitorDataLoaded && isDataLoaded) {
            getMixPercentage();
        }
    }, [isPepsiDataLoaded, isCompetitorDataLoaded, isDataLoaded])

    const getMixPercentage = () => {
        const allProducts = [...pepsi ? pepsi : [], ...competitor ? competitor : []];
        if (allProducts.length > 0) {
            const mixLevelsCopy = [...mixLevels]
            mixLevelsCopy.map((item) => {
                item.currentProducts = allProducts.filter(x =>
                    (!item.endIndex && x.indexCurrent > (item.startIndex - 1)) ||
                    (item.endIndex && item.startIndex && x.indexCurrent > (item.startIndex - 1) && x.indexCurrent <= item.endIndex) ||
                    (!item.startIndex && x.indexCurrent <= item.endIndex)
                )
                item.simulatedProducts = allProducts.filter(x =>
                    (!item.endIndex && x.indexSimulated > (item.startIndex - 1)) ||
                    (item.endIndex && item.startIndex && x.indexSimulated > (item.startIndex - 1) && x.indexSimulated <= item.endIndex) ||
                    (!item.startIndex && x.indexSimulated <= item.endIndex)
                )
            })

            const totalCurrent = _.reduce(allProducts, (sum, n) => sum + n.currentGrossSale, 0);
            const totalSimulated = _.reduce(allProducts, (sum, n) => sum + n.simulatedGrossSale, 0);
            mixLevelsCopy.map((item) => {
                const current = _.reduce(item.currentProducts, (sum, n) => sum + n.currentGrossSale, 0);
                item.current = current ? ((current / totalCurrent) * 100).toFixed(2) : 0;
                const simulated = _.reduce(item.simulatedProducts, (sum, n) => sum + n.simulatedGrossSale, 0);
                item.simulated = simulated ? ((simulated / totalSimulated) * 100).toFixed(2) : 0;
            })
            setMixLevels(mixLevelsCopy);
        }
        setSkeleton(false)
    }

    const getYPosition = (product, key) => {
        let yIndex = product[key];
        if (yIndex >= indexList[emptyIndex].index) {
            yIndex = indexList[emptyIndex].index;
        } else if (yIndex <= indexList[indexList.length - (emptyIndex + 1)].index) {
            yIndex = indexList[indexList.length - (emptyIndex + 1)].index;
        }
        let i = indexList.findIndex((x, i) => {
            if (i === emptyIndex) { //check if its last value 
                return yIndex >= x.index
            } else if (i > emptyIndex) { //check the i needs to be greater than 0 + emptyIndex
                return yIndex >= x.index && yIndex < indexList[i - 1].index
            }
        })
        let oddNum = yIndex - indexList[i].index;
        let imgGap = (imageHeight - pixelVal) / 2;
        let prevIndex = indexList[i > emptyIndex ? i - (emptyIndex + 1) : i];
        return (i * pixelVal) - ((oddNum ? oddNum : 0) * (pixelVal / prevIndex.gap)) - imgGap
    }

    const getXYPosition = (payload, yIndexs) => {
        const isCurrntXOverlapping = payload.filter(item => {
            const min = yIndexs.yCurr - imageHeight;
            const max = yIndexs.yCurr + imageHeight;
            return item.yCurr > min && item.yCurr <=max
        })
        const isSimulatedXOverlapping = payload.filter(item => {
            const min = yIndexs.ySimu - imageHeight;
            const max = yIndexs.ySimu + imageHeight;
            return item.ySimu > min && item.ySimu <= max
        })
        return {
            ...yIndexs,
            xCurr: (_.isArray(isCurrntXOverlapping) && isCurrntXOverlapping.length ?
                ((isCurrntXOverlapping.length * imageWidth) + (imageWidth+5)) > columnWidth ?
                    columnWidth - imageWidth : (isCurrntXOverlapping.length * (imageWidth+5)) : 0
            ),
            xSimu: (_.isArray(isSimulatedXOverlapping) && isSimulatedXOverlapping.length ?
                ((isSimulatedXOverlapping.length * imageWidth) + (imageWidth+5)) > columnWidth ?
                    columnWidth - imageWidth : isSimulatedXOverlapping.length * (imageWidth+5) : 0
            )
        };
    }

    const [indexList, setIndexList] = useState([]);
    const [columnHeight, setColumnHeight] = useState(750);
    const [skeleton, setSkeleton] = useState(true);

    useEffect(() => {
        const mixLevelsData = [
            { label: 'Value', startIndex: null, endIndex: parseInt(indexes.valueEnd), current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
            { label: 'Main Stream', startIndex: parseInt(indexes.mainStreamStart), endIndex: parseInt(indexes.mainStreamEnd), current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
            { label: 'Main Stream ++', startIndex: parseInt(indexes.mainStreamPlusStart), endIndex: parseInt(indexes.mainStreamPlusEnd), current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
            { label: 'Premium', startIndex: parseInt(indexes.premiumStart), endIndex: parseInt(indexes.premiumEnd), current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
            { label: 'Super Premium', startIndex: parseInt(indexes.superPremiumStart), endIndex: parseInt(indexes.superPremiumEnd), current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
            { label: 'Mega Premium', startIndex: parseInt(indexes.megaPremiumStart), endIndex: null, current: null, simulated: null, currentProducts: null, simulatedProducts: null, indexs: [], height: 0 },
        ]
        let result = [];
        let startIndex = (mixLevelsData[0].endIndex) - ((extraIndex) * gap);
        for (let i = 0; i < mixLevelsData.length; i++) {
            let indexForTier = [];
            if (i === 0) {
                if (emptyIndex > 0) {
                    [...Array(emptyIndex).keys()].map(x => {
                        indexForTier.push({ index: null, displayIndex: '', gap })
                    })
                }
                indexForTier.push({ index: startIndex, displayIndex: `<${startIndex}`, gap })

                if (extraIndex > 0) {
                    [...Array(extraIndex).keys()].map(x => {
                        startIndex += gap;
                        indexForTier.push({ index: startIndex, displayIndex: startIndex, gap })
                    })
                }

            } else if (i === mixLevelsData.length - 1) {
                if (extraIndex > 0) {
                    [...Array(extraIndex-1).keys()].map(x => {
                        startIndex += gap;
                        indexForTier.push({ index: startIndex, displayIndex: startIndex, gap })
                    })
                }
                startIndex += gap;
                indexForTier.push({ index: startIndex, displayIndex: `>${startIndex}`, gap })
                if (emptyIndex > 0) {
                    [...Array(emptyIndex).keys()].map(x => {
                        startIndex += gap;
                        indexForTier.push({ index: null, displayIndex: '', gap })
                    })
                }
            } else {
                const numberOfPlottingIndex = 5;
                const numOfIndexes = ((mixLevelsData[i].endIndex + 1) - mixLevelsData[i].startIndex);
                if (numOfIndexes > 0) {
                [...Array((numOfIndexes / gap)).keys()].map(x => {
                    startIndex += gap;
                    indexForTier.push({ index: startIndex, displayIndex: startIndex, gap })
                })
            }
                // if (numOfIndexes > 0) {
                //     if ((numOfIndexes / gap) > numberOfPlottingIndex) {
                //         let modNum = 0;
                //         [...Array(5).keys()].map((x, j) => {
                //             const plotIndex = (numOfIndexes / numberOfPlottingIndex);
                //             if (j === 4) {
                //                 startIndex += plotIndex + modNum;
                //                 indexForTier.push({ index: startIndex, displayIndex: startIndex, gap: plotIndex + modNum })
                //             } else {
                //                 const remainNum = (plotIndex % numberOfPlottingIndex)
                //                 modNum += remainNum;
                //                 startIndex += (plotIndex - remainNum);
                //                 indexForTier.push({ index: startIndex, displayIndex: startIndex, gap: plotIndex - remainNum })
                //             }
                //         })
                //     } else if ((numOfIndexes / gap) === 1) {
                //         let newGap = Math.round(gap / 2);
                //         startIndex += newGap
                //         indexForTier.push({ index: startIndex, displayIndex: startIndex, gap: newGap })

                //         newGap = Math.round(gap - newGap)
                //         startIndex += newGap
                //         indexForTier.push({ index: startIndex, displayIndex: startIndex, gap: newGap })
                //     } else {
                //         [...Array((numOfIndexes / gap)).keys()].map(x => {
                //             startIndex += gap;
                //             indexForTier.push({ index: startIndex, displayIndex: startIndex, gap })
                //         })
                //     }
                // }
            }
            mixLevelsData[i].indexs = [...indexForTier];
            // mixLevelsData[i].height = i === 0 ? (indexForTier.length * newPixelVal) - (newPixelVal / 2) : i === mixLevelsData.length - 1 ? (indexForTier.length * newPixelVal) + (newPixelVal / 2) : (indexForTier.length * newPixelVal)
            result = [...result, ...indexForTier]
        }
        const newPixelVal = columnHeight/result.length
        setPixelVal(newPixelVal);
        for (let i = 0; i < mixLevelsData.length; i++) {
            mixLevelsData[i].height = i === 0 ? (mixLevelsData[i].indexs.length * newPixelVal) - (newPixelVal / 2) : i === mixLevelsData.length - 1 ? (mixLevelsData[i].indexs.length * newPixelVal) + (newPixelVal / 2) : (mixLevelsData[i].indexs.length * newPixelVal)
        }
        setMixLevels(mixLevelsData.reverse());
        result = result.reverse();
        // setColumnHeight((result.length * gap) * singleIndexVal)
        setIndexList(result);
    }, [indexes])

    const onDrop = (payload) => {
        const newSimulationRequest = [...simulationRequest];
        const index  = newSimulationRequest.findIndex(x=> x.productInfo === payload.productInfo);
        if(index !== -1){
            newSimulationRequest[index].simulationIndex = getIndexPosition(payload);
        }else{
            newSimulationRequest.push({ simulationIndex: getIndexPosition(payload), ...payload.data })
        }
        setSimulationRequest(newSimulationRequest)
    }

    const onApply = () => {
        setSkeleton(true);
        callback(simulationRequest)
    }

    const onReset = () => {
        reset();
        setSimulationRequest([]);
    };

    const getIndexPosition = (payload) => {
        let imgGap = (imageHeight - pixelVal) / 2;
        const y = payload.y + imgGap;
        const i = Math.floor(y / pixelVal) + emptyIndex;
        let indexPosition = 0;
        if (i >= indexList.length - 1) {
            indexPosition = indexList[indexList.length - (emptyIndex + 1)].index;
        } else {
            let prevIndex = indexList[i > emptyIndex ? i - 1 : i];
            let singleIndexPix = pixelVal / prevIndex.gap;
            let remainPixel = (i * pixelVal) - y;
            indexPosition = indexList[i].index + Math.round(remainPixel / singleIndexPix);
        }
        return indexPosition
    }

    const [showTooltip, setTooltip] = useState({ id: '', content: null, x: null, y: null });
    const tooltip = (id, data, x?, y?) => {
        if (id && showTooltip.id !== id) {
            const tooltip = {
                id,
                content: data ? tooltipContent(id, data) : null,
                x: x ? x : null,
                y: y ? y : null
            }
            setTooltip(tooltip)
        }
    }

    const tooltipContent = (id, data) => {
        return (
            id === 'pepsi-as-is' || id === 'competitor-as-is' ?
                <div style={{ fontSize: 12, textAlign: 'left', padding: 5, border: '1px solid #D3D3D3', background: '#b5d8f3' }}>
                    <div><b>{filters.comparisonLevel === 'brand' ? 'BRAND' : 'BRAND PACKSIZE'}</b></div>
                    <div>{filters.comparisonLevel === 'brand' ? 'Brand: ' : 'Brand Packsize: '} <b>{data.productInfo}</b></div>
                    {filters.data === 'SELL-OUT' ?
                        <>
                            <div>Index Curr: <b>{formatNumber(data.indexCurrent)}</b></div>
                            <div>B.PPV Curr: <b>{formatNumber(data.ppvCurrent)}</b></div>
                            {/* <div>ELAS PPV Curr: <b>{formatNumber(data.ppvElasticityCurrent)}</b></div> */}
                            <div>Share of Market Curr: <b>{`${(data.currentMarketShare * 100).toFixed(2)}%`}</b></div>
                        </>
                        :
                        <>
                            <div>Index Curr: <b>{formatNumber(data.indexCurrent)}</b></div>
                            <div>B.PPV Curr: <b>{formatNumber(data.ppvCurrent)}</b></div>
                            {/* <div>ELAS PPV Curr: <b>{formatNumber(data.ppvElasticityCurrent)}</b></div> */}
                        </>
                    }
                </div>
                :
                <div style={{ fontSize: 12, textAlign: 'left', padding: 5, border: '1px solid #D3D3D3', background: '#b5d8f3' }}>
                    <div><b>{filters.comparisonLevel === 'brand' ? 'BRAND' : 'BRAND PACKSIZE'}</b></div>
                    <div>{filters.comparisonLevel === 'brand' ? 'Brand: ' : 'Brand Packsize: '} <b>{data.productInfo}</b></div>
                    {filters.data === 'SELL-OUT' ?
                        <>
                            <div>Index Curr: <b>{formatNumber(data.indexCurrent)}</b></div>
                            <div>Index Sim: <b>{formatNumber(data.indexSimulated)}</b></div>
                            <div>B.PPV Curr: <b>{formatNumber(data.ppvCurrent)}</b></div>
                            <div>B.PPV Sim: <b>{formatNumber(data.ppvSimulated)}</b></div>
                            {/* <div>ELAS PPV Curr: <b>{formatNumber(data.ppvElasticityCurrent)}</b></div>
                            <div>ELAS PPV Sim: <b>{formatNumber(data.ppvElasticitySimulated)}</b></div> */}
                            <div>Share of Market Curr: <b>{`${(data.currentMarketShare * 100).toFixed(2)}%`}</b></div>
                            <div>Share of Market Sim: <b>{`${(data.simulatedMarketShare * 100).toFixed(2)}%`}</b></div>
                        </>
                        :
                        <>
                            <div>Index Curr: <b>{formatNumber(data.indexCurrent)}</b></div>
                            <div>Index Sim: <b>{formatNumber(data.indexSimulated)}</b></div>
                            <div>B.PPV Curr: <b>{formatNumber(data.ppvCurrent)}</b></div>
                            <div>B.PPV Sim: <b>{formatNumber(data.ppvSimulated)}</b></div>
                            {/* <div>ELAS PPV Curr: <b>{formatNumber(data.ppvElasticityCurrent)}</b></div>
                            <div>ELAS PPV Sim: <b>{formatNumber(data.ppvElasticitySimulated)}</b></div> */}
                        </>
                    }
                </div>
        )
    }

    const plotToBeImages = (id, dragPositionX, dragPositionY, restrictDropFun, data) => {
        return (
            <Grid style={{ position: 'relative', width: columnWidth, height: columnHeight }}>
                {showTooltip.id === id ? <div style={{ position: 'absolute', left: showTooltip.x, top: showTooltip.y + imageHeight, zIndex: 9 }}>{showTooltip.content}</div> : null}
                <div style={{ position: 'absolute' }}>
                    <Stage
                        width={columnWidth}
                        height={columnHeight}
                        key={id}
                    >
                        {data.map((item, i) =>
                            <Layer>
                                {
                                    item.image ?

                                        <Group
                                            width={imageWidth}
                                            height={imageHeight}
                                            image={item.image}
                                            x={id === 'pepsi-as-is' || id === 'competitor-as-is' ? item.xCurr : item.xSimu}
                                            y={id === 'pepsi-as-is' || id === 'competitor-as-is' ? item.yCurr : item.ySimu}
                                            draggable
                                            onMouseMove={(e) => { tooltip(id, item, e.target.parent.x(), e.target.parent.y()) }}
                                            onMouseOut={() => setTooltip({ id: '', content: null, x: null, y: null })}
                                            onDragStart={() => setTooltip({ id: '', content: null, x: null, y: null })}
                                            onDragMove={(e) => {
                                                if (id === 'pepsi-to-be' || id === 'competitor-to-be') {
                                                    const tooltip = {
                                                        id,
                                                        content: <div style={{ fontSize: 12, textAlign: 'left', padding: 5, border: '1px solid #D3D3D3', background: '#b5d8f3' }}>Index: <b>{
                                                            getIndexPosition({
                                                                x: e.target.x(),
                                                                y: e.target.y(),
                                                                e,
                                                                data: { ...item, id }
                                                            })
                                                        }</b></div>,
                                                        x: e.target.x(),
                                                        y: e.target.y()
                                                    }
                                                    setTooltip(tooltip)
                                                }
                                            }}
                                            onDragEnd={(e) => {
                                                if (!restrictDropFun) {
                                                    onDrop({
                                                        x: e.target.x(),
                                                        y: e.target.y(),
                                                        e,
                                                        data: { ...item, id }
                                                    })
                                                }
                                                setTooltip({ id: '', content: null, x: null, y: null })
                                            }}
                                            dragBoundFunc={function (pos) {
                                                let x = this.absolutePosition().x, y = this.absolutePosition().y;
                                                if (dragPositionX) {
                                                    x = pos.x < 0 ? 0 : pos.x > (columnWidth - imageWidth) ? (columnWidth - imageWidth) : pos.x
                                                }
                                                if (dragPositionY) {
                                                    y = pos.y < 0 ? 0 : pos.y > (columnHeight - imageHeight) ? (columnHeight - (imageHeight+10)) : pos.y
                                                }
                                                return { x, y };
                                            }}
                                        >
                                            <Image width={imageWidth}
                                            height={imageHeight}
                                            image={item.image}/>
                                            <Text
                                                y={imageHeight}
                                                width={imageWidth+20}
                                                fontStyle={'bold'}
                                                height={10}
                                                fontSize={10}
                                                text={item.productPackSize? item.productPackSize : ''}
                                                align='left'
                                            />
                                            </Group>
                                        :
                                        <Group
                                            width={imageWidth}
                                            height={imageHeight}
                                            x={id === 'pepsi-as-is' || id === 'competitor-as-is' ? item.xCurr : item.xSimu}
                                            y={id === 'pepsi-as-is' || id === 'competitor-as-is' ? item.yCurr : item.ySimu}
                                            draggable
                                            onMouseMove={(e) => tooltip(id, item, e.target.parent.x(), e.target.parent.y())}
                                            onMouseOut={() => setTooltip({ id: '', content: null, x: null, y: null })}
                                            onDragStart={() => setTooltip({ id: '', content: null, x: null, y: null })}
                                            onDragMove={(e) => {
                                                if (id === 'pepsi-to-be' || id === 'competitor-to-be') {
                                                    const tooltip = {
                                                        id,
                                                        content: <div style={{ fontSize: 12, textAlign: 'left', padding: 5, border: '1px solid #D3D3D3', background: '#b5d8f3' }}>Index: <b>{
                                                            getIndexPosition({
                                                                x: e.target.x(),
                                                                y: e.target.y(),
                                                                e,
                                                                data: { ...item, id }
                                                            })
                                                        }</b></div>,
                                                        x: e.target.x(),
                                                        y: e.target.y()
                                                    }
                                                    setTooltip(tooltip)
                                                }
                                            }}
                                            onDragEnd={(e) => {
                                                if (!restrictDropFun) {
                                                    onDrop({
                                                        x: e.target.x(),
                                                        y: e.target.y(),
                                                        e,
                                                        data: { ...item, id }
                                                    })
                                                }
                                                setTooltip({ id: '', content: null, x: null, y: null })
                                            }}
                                            dragBoundFunc={function (pos) {
                                                let x = this.absolutePosition().x, y = this.absolutePosition().y;
                                                if (dragPositionX) {
                                                    x = pos.x < 0 ? 0 : pos.x > (columnWidth - 60) ? (columnWidth - 60) : pos.x
                                                }
                                                if (dragPositionY) {
                                                    y = pos.y < 0 ? 0 : pos.y > (columnHeight - imageHeight) ? (columnHeight - (imageHeight+10)) : pos.y
                                                }
                                                return { x, y };
                                            }}
                                        >
                                            <Rect
                                                width={imageWidth}
                                                height={imageHeight}
                                                fill="#f2f2f2"
                                                stroke='black'
                                                strokeWidth={1}
                                            />
                                            <Text
                                                width={imageWidth}
                                                height={imageHeight}
                                                text={item.productName}
                                                padding={5}
                                                ellipsis={true}
                                                wrap='char'
                                                align='center'
                                            />
                                            <Text
                                                y={imageHeight+5}
                                                width={imageWidth+20}
                                                fontStyle={'bold'}
                                                height={10}
                                                fontSize={10}
                                                text={item.productPackSize? item.productPackSize : ''}
                                                align='left'
                                            />
                                        </Group>
                                }
                            </Layer>
                        )}
                    </Stage>
                </div>
                {mixLevels.map((item, i) => {
                    return <div style={{
                        borderBottom: '1px solid #D3D3D3',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: item.height
                    }}>
                    </div>
                })}
            </Grid>)
    }

    return (
        <Grid container display="flex" justifyContent="center" alignItems="center">
            {!skeleton && !showSkeleton ?
                (_.isArray(pepsi) && pepsi.length > 0) || (_.isArray(competitor)) ?
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeadCell>Mix/Tiers</StyledTableHeadCell>
                                    <StyledTableHeadCell>Index</StyledTableHeadCell>
                                    <StyledTableHeadCell>PEPSICO AS IS</StyledTableHeadCell>
                                    {!hideToBeColumn ?
                                        <StyledTableHeadCell>PEPSICO TO BE</StyledTableHeadCell>
                                        : null}
                                    <StyledTableHeadCell style={{ background: filters.data === 'SELL-IN' ? '#eaeaea' : null }}>COMPETITOR AS IS</StyledTableHeadCell>
                                    {!hideToBeColumn ?
                                        <StyledTableHeadCell style={{ background: filters.data === 'SELL-IN' ? '#eaeaea' : null }}>COMPETITOR TO BE</StyledTableHeadCell>
                                        : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell style={{ width: (columnWidth / 3) * 2, height: columnHeight }}>
                                        <div style={{ height: columnHeight }}>
                                            {mixLevels.map((item, i) => {
                                                return (
                                                    <div style={{
                                                        border: '1px solid #D3D3D3',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: 14,
                                                        height: item.height
                                                    }}>
                                                        <div>
                                                            <div>
                                                                {item.label}
                                                                {item.startIndex && item.endIndex ?
                                                                    `(${item.startIndex}-${item.endIndex})` :
                                                                    item.startIndex && !item.endIndex ?
                                                                        `(>${item.startIndex})` :
                                                                        `(<=${item.endIndex})`
                                                                }
                                                            </div>
                                                            <div style={{display: item.height<=50 && isSimulated? 'flex' : null}}>
                                                            {
                                                                <div><span style={{ color: '#0093CD' }}>Current: </span><b>{item.current}%</b></div>
                                                            }
                                                            {
                                                                isSimulated ? <div><span style={{ color: '#0093CD', marginLeft: item.height<=50? 10 : null }}>Simulated: </span><b>{item.simulated}%</b></div> : null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: columnWidth / 3 }}>
                                        <div style={{ height: columnHeight }}>
                                            {indexList.map((item, i) => <div style={{ fontSize: 14, height: pixelVal, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.displayIndex}</div>)}
                                        </div>
                                    </StyledTableCell>
                                    {!hideToBeColumn ?
                                        <StyledTableCell>
                                            {plotToBeImages('pepsi-as-is', true, false, true, pepsiData)}
                                        </StyledTableCell>
                                        : null}
                                    <StyledTableCell>
                                        {plotToBeImages('pepsi-to-be', true, true, false, pepsiData)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ background: filters.data === 'SELL-IN' ? '#eaeaea' : null }}>
                                        {plotToBeImages('competitor-as-is', true, false, true, competitorData)}
                                    </StyledTableCell>
                                    {!hideToBeColumn ?
                                        <StyledTableCell style={{ background: filters.data === 'SELL-IN' ? '#eaeaea' : null }}>
                                            {plotToBeImages('competitor-to-be', true, true, false, competitorData)}
                                        </StyledTableCell>
                                        : null}
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Grid className="m-t-20" container display="flex" justifyContent="flex-start">
                            <OrangeBtn color="secondary" onClick={onReset} className="m-r-20" style={{ width: 100 }}>
                                Reset
                            </OrangeBtn>
                            <PrimaryBtn color="primary" onClick={onApply} style={{ width: 100 }} disabled={simulationRequest.length===0}>
                                Simulate
                            </PrimaryBtn>
                        </Grid>
                    </>
                    :
                    <Box style={{ height: 200 }} display='flex' justifyContent='center' alignItems='center'>
                        <NoData>{messages.noData}</NoData>
                    </Box>
                :
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={450} />
                    <Grid container className="m-t-20">
                    <Grid className="m-r-20">
                        <Skeleton variant="rectangular" height={40} width={120} />
                    </Grid>
                    <Grid>
                        <Skeleton variant="rectangular" height={40} width={120}/>
                    </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>

    )
}

export default BrandLadderChart