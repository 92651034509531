import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomInputAdornment,
  CustomUpArrow,
  CustomDownArrow,
  StyledTextField,
} from "./TextFieldInput.Styled";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },

  inputRoot: {
    '& input[type="number"]': {
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:hover::-webkit-inner-spin-button, &:hover::-webkit-outer-spin-button":
        {
          backgroundColor: "none",
        },
    },
  },
  input: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(0),
  },
  adornment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  arrowButton: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));

const TextFieldInput = ({ defaultValue, callback, disabled = false }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue || 0);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue >= 0 && newValue <= 100) {
      setValue(newValue);
      callback(newValue);
    }
  };

  const increaseValue = () => {
    const newValue = Number(value) + 1;
    if (newValue <= 100) {
      setValue(String(newValue));
      callback(newValue);
    }
  };

  const decreaseValue = () => {
    const newValue = Number(value) - 1;
    if (newValue >= 0) {
      setValue(String(newValue));
      callback(newValue);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <StyledTextField
        type="number"
        value={value}
        onChange={handleInputChange}
        className={classes.inputRoot}
        InputProps={{
          inputProps: { min: 0, max: 100 },
          endAdornment: (
            <CustomInputAdornment
              style={{ pointerEvents: disabled ? "none" : "auto" }}
            >
              <CustomUpArrow onClick={increaseValue}>&#9650;</CustomUpArrow>
              <CustomDownArrow onClick={decreaseValue}>&#9660;</CustomDownArrow>
            </CustomInputAdornment>
          ),
        }}
        disabled={disabled}
      />
    </>
  );
};

export default TextFieldInput;
