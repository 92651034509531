export const geoFilterConfig = {
    country: {
        title: "Country",
        options: [],
    },
    geoLevel: {
        title: "Geo-Level",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: false,
    },
    region: {
        title: "Region",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    city: {
        title: "City",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    channel: {
        title: "Channel",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    subChannel: {
        title: "Sub Channel",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    keyAccounts: {
        title: "Key Accounts",
        options: [],
        placeholder: "Null",
        all: false,
        multiple: false
    },
};

export const geoFilterV2Config = {
    country: {
        title: "Country",
        options: [],
    },
    geoLevel: {
        title: "Geo-Level",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: false,
    },
    geoLevel2: {
        title: "Geo-Level 2",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: false,
        defaultSelectAll: false
    },
    channel: {
        title: "Channel",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
};