import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import Dropdown from '../../../../components/Dropdown';
import FilterAccordion from '../../../../components/Filters/Filters';
import Indicator from '../../../../components/Loader/Loader';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import { NoData, OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import {
  dataFormat,
  disabledFiltersWithVendor,
  FiltersWithVendor,
  mapFilterOptions,
  selectAllOptions,
  formatDefaultFilters,
  enableCompetitorFilter,
  rendarImage,
} from '../../../../util/helper';
import { getDataForPriceTracking, getMultipleApiProducts, getProductFilters } from '../../service';
import { priceTrackingTableSkeletonData, priceTrackingTableHeading } from '../../../../mocks/sellOutDashboard';
import { comparisonLevelConfig, competitorFilterConfig, productFilterConfig } from './config';
import { Title } from '../../../../components/Dashboard/Dashboard.Styled';
import Dashboard from '../../../../components/Dashboard';

const initialProductFilter = {
  vendor: productFilterConfig.vendor.options[0].value,
  category: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
};

const initialDisableProductFilter = {
  vendor: true,
  category: false,
  segment: true,
  brand: true,
  subBrand: true,
  packSize: true,
};

const initialCompetitorFilter = {
  vendor: null,
  category: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
};

const initialDisableCompetitorFilter = {
  vendor: true,
  category: true,
  segment: true,
  brand: true,
  subBrand: true,
  packSize: true,
};

const initialComparisonMapping = {
  vendor: 'Vendor',
  category: 'Category',
  segment: 'Segment',
  brand: 'Brand',
  subbrand: 'Sub Brand',
  packsize: 'Pack Size',
};

const PriceTrackingAPIFilters: React.FC<{
  globalFilters?;
  callback;
  defaultFIlters;
}> = ({ globalFilters, callback, defaultFIlters }) => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

  const [skeleton, setSkeleton] = React.useState(true);
  const [priceTrackingTableSkeleton, setPriceTrackingTableSkeleton] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [headers, setHeaders] = React.useState(priceTrackingTableHeading);
  const [values, setValues] = React.useState([]);
  const [productFilter, setProductFilter] = useState(productFilterConfig);
  const [competitorFilter, setCompetitorFilter] = useState(competitorFilterConfig);
  const [comparison, setComparison] = useState(comparisonLevelConfig.defaultOption.value);

  const [selectedProductFilter, setSelectedProductFilter] = React.useState({
    ...initialProductFilter,
  });

  const [disableProductFilter, setDisableProductFilter] = React.useState({
    ...initialDisableProductFilter,
  });

  const [selectedCompetitorFilter, setSelectedCompetitorFilter] = React.useState({ ...initialCompetitorFilter });

  const [disableCompetitorFilter, setDisableCompetitorFilter] = React.useState({
    ...initialDisableCompetitorFilter,
  });

  const [isSave, setisSave] = useState(true);

  let defaultFilterAPIPayload: any = [];

  const [comparisonLevel, setComparisonLevel] = React.useState({
    vendor: false,
    category: false,
    segment: false,
    brand: false,
    subBrand: false,
    packSize: false,
  });

  React.useEffect(() => {
    if (comparison) {
      const temp = {
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      switch (comparison) {
        case 'Category':
          temp.vendor = true;
          temp.category = true;
          break;
        case 'Segment':
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          break;
        case 'Brand':
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          break;
        case 'Sub Brand':
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          break;
        case 'Pack Size':
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          temp.packSize = true;
          break;
      }
      if (defaultFIlters && _.isObject(defaultFIlters) && Object.keys(defaultFIlters).length > 0) {
        for (let key in temp) {
          if (temp[key]) {
            if (defaultFIlters[key]?.length > 0) {
              defaultFilterAPIPayload.push({
                name: key,
                value: defaultFIlters[key],
                apiCalled: false,
              });
            }
          }
        }
        let competitorTemp = Object.keys(temp).map((key) => `competitor${key.charAt(0).toUpperCase() + key.slice(1)}`);
        let defaultCompetitorFilterAPICallsPayload = formatDefaultFilters(competitorTemp, defaultFIlters);
        defaultCompetitorFilterAPICallsPayload = defaultCompetitorFilterAPICallsPayload.map((data) => {
          let field = data.name.split('competitor').join('');
          field = field.charAt(0).toLowerCase() + field.slice(1);
          return {
            ...data,
            name: field,
          };
        });
        fetchProductFilters({ ...initialProductFilter }, globalFilters.country);
        fetchCompetiorFilters({ ...initialCompetitorFilter }, globalFilters.country, defaultCompetitorFilterAPICallsPayload);
      } else {
        setSelectedProductFilter({ ...initialProductFilter });
        setDisableProductFilter({
          ...initialDisableProductFilter,
          category: false,
        });
        setSelectedCompetitorFilter({ ...initialCompetitorFilter });
        setDisableCompetitorFilter(
          enableCompetitorFilter(globalFilters) ? { ...initialDisableCompetitorFilter, vendor: false } : { ...initialDisableCompetitorFilter }
        );
      }
      setComparisonLevel(temp);
    }
  }, [comparison, defaultFIlters]);

  React.useEffect(() => {
    if (globalFilters && globalFilters.country) {
      setPriceTrackingTableSkeleton(true);
      setSkeleton(false);
      setDisableProductFilter({
        ...initialDisableProductFilter,
        category: false,
      });
      setDisableCompetitorFilter(
        enableCompetitorFilter(globalFilters) ? { ...initialDisableCompetitorFilter, vendor: false } : { ...initialDisableCompetitorFilter }
      );
      fetchProductFilters({ ...initialProductFilter }, globalFilters.country);
      fetchCompetiorFilters({ ...initialCompetitorFilter }, globalFilters.country, []);
    }
  }, [globalFilters]);

  const onChangeProductFilter = (key, value) => {
    const filterValues = { ...selectedProductFilter };
    const disableFilter = { ...disableProductFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedProductFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== 'packSize' && key.toLowerCase() !== comparison.toLowerCase()) {
        fetchProductFilters({ ...result.filterValues, [key]: value });
      }
    }
    setDisableProductFilter({ ...result.disableFilter });
    setisSave(false);
  };

  const fetchProductFilters = (payload, country?) => {
    if (globalFilters.country || country) {
      setLoader(true);
      getProductFilters({
        country: globalFilters.country || country,
        ...payload,
      })
        .then((response) => {
          let tempProductFilter = { ...productFilter };
          if (response && response.data) {
            tempProductFilter = mapFilterOptions(response, tempProductFilter);
            setProductFilter(tempProductFilter);
            setSelectedProductFilter({
              ...payload,
              ...selectAllOptions(response, productFilter),
            });
            const index = defaultFilterAPIPayload.findIndex((filtertemp) => !filtertemp.apiCalled);
            console.log('v2 index', defaultFilterAPIPayload, index);
            if (index !== -1 && isSave) {
              defaultFilterAPIPayload[index].apiCalled = true;
              const key = defaultFilterAPIPayload[index].name;
              const value = defaultFilterAPIPayload[index]['value'];
              const result = disabledFiltersWithVendor({ [key]: value }, true);
              setDisableProductFilter({ ...result });
              if (defaultFilterAPIPayload[index].name !== 'packSize') fetchProductFilters({ ...payload, [key]: value }, defaultFilterAPIPayload);
              else {
                setSelectedProductFilter({
                  ...{
                    ...payload,
                    ...selectAllOptions(response, productFilter),
                  },
                  [key]: value,
                });
              }
            }
          }
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const onChangeCompetitorFilter = (key, value) => {
    const filterValues = { ...selectedCompetitorFilter };
    const disableFilter = { ...disableCompetitorFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedCompetitorFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (key !== 'packSize' && key.toLowerCase() !== comparison.toLowerCase()) {
        fetchCompetiorFilters({ ...result.filterValues, [key]: value });
      }
    }
    setDisableCompetitorFilter({ ...result.disableFilter });
    setisSave(false);
  };

  const fetchCompetiorFilters = (payload, country?, defaultCompetitorFilterAPICallsPayload?) => {
    if (globalFilters.country || country) {
      setLoader(true);
      getMultipleApiProducts({ country: globalFilters.country || country, ...payload }, 'competitor')
        .then((response) => {
          let tempCompetitorFilter = { ...competitorFilter };
          if (response && response.data) {
            tempCompetitorFilter = mapFilterOptions(response, tempCompetitorFilter);
          }
          setCompetitorFilter(tempCompetitorFilter);
          setSelectedCompetitorFilter({
            ...payload,
            ...selectAllOptions(response, competitorFilter),
          });
          const index = defaultCompetitorFilterAPICallsPayload?.findIndex((filtertemp) => !filtertemp.apiCalled);
          console.log('v2 index in comp', defaultCompetitorFilterAPICallsPayload, index);
          if (index !== -1 && isSave) {
            defaultCompetitorFilterAPICallsPayload[index].apiCalled = true;
            const key = defaultCompetitorFilterAPICallsPayload[index].name;
            const value = defaultCompetitorFilterAPICallsPayload[index]['value'];
            const result = FiltersWithVendor({ [key]: value });
            setDisableCompetitorFilter({ ...result });
            if (defaultCompetitorFilterAPICallsPayload[index].name !== 'packSize')
              fetchCompetiorFilters({ ...payload, [key]: value }, globalFilters.country, defaultCompetitorFilterAPICallsPayload);
            else {
              setSelectedCompetitorFilter({
                ...{
                  ...payload,
                  ...selectAllOptions(response, competitorFilter),
                },
                [key]: value,
              });
            }
          }
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const formatValues = (key, value, filterValues, disableFilter) => {
    if (key === 'vendor') {
      filterValues.category = null;
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.category = value === null || value.length === 0;
      disableFilter.segment = true;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === 'category') {
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.segment = value === null || value.length === 0;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === 'segment') {
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.brand = value === null || value.length === 0;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === 'brand') {
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.subBrand = value === null || value.length === 0;
      disableFilter.packSize = true;
    } else if (key === 'subBrand') {
      filterValues.packSize = null;

      disableFilter.packSize = value === null || value.length === 0;
    }
    return { filterValues, disableFilter };
  };

  const onApplyFilter = () => {
    setLoader(true);
    let payload = {
      country: globalFilters.country,
      geoLevel: globalFilters.geoLevel,
      channel: globalFilters.channel,
      geoLevel2: globalFilters.geoLevel2,
      date: globalFilters.date,
      category: selectedProductFilter.category,
      segment: selectedProductFilter.segment,
      brand: selectedProductFilter.brand,
      subBrand: selectedProductFilter.subBrand,
      packSize: selectedProductFilter.packSize,
      competitorVendor: selectedCompetitorFilter.vendor,
      competitorCategory: selectedCompetitorFilter.category,
      competitorSegment: selectedCompetitorFilter.segment,
      competitorBrand: selectedCompetitorFilter.brand,
      competitorSubBrand: selectedCompetitorFilter.subBrand,
      competitorPackSize: selectedCompetitorFilter.packSize,
      comparisonLevel: comparison.toLowerCase().replace(/ /g, ''),
    };
    if (defaultFIlters && _.isObject(defaultFIlters) && Object.keys(defaultFIlters).length > 0 && isSave) {
      payload = {
        ...payload,
        comparisonLevel: defaultFIlters.comparisonLevel,
        category: defaultFIlters.category,
        segment: defaultFIlters.segment,
        brand: defaultFIlters.brand,
        subBrand: defaultFIlters.subBrand,
        packSize: defaultFIlters.packSize,
        competitorVendor: defaultFIlters.competitorVendor,
        competitorCategory: defaultFIlters.competitorCategory,
        competitorSegment: defaultFIlters.competitorSegment,
        competitorBrand: defaultFIlters.competitorBrand,
        competitorSubBrand: defaultFIlters.competitorSubBrand,
        competitorPackSize: defaultFIlters.competitorPackSize,
      };
    }
    callback(payload);
    setPriceTrackingTableSkeleton(true);
    getDataForPriceTracking(payload)
      .then((res) => {
        if (res && res.data) {
          let data = res.data;
          formatData(_.orderBy(data, ['productName'], ['asc']));
        }
        setLoader(false);
      })
      .catch((e) => {
        setPriceTrackingTableSkeleton(false);
        setLoader(false);
      });
  };

  const clearFilter = () => {
    setSelectedProductFilter({ ...initialProductFilter });
    setDisableProductFilter({ ...initialDisableProductFilter });
    setSelectedCompetitorFilter({ ...initialCompetitorFilter });
    setDisableCompetitorFilter({ ...initialDisableCompetitorFilter });
    setComparisonLevel({
      vendor: false,
      category: false,
      segment: false,
      brand: false,
      subBrand: false,
      packSize: false,
    });
    setComparison(comparisonLevelConfig.defaultOption.value);
  };

  const disableApplyFilter = () => {
    const flag: boolean =
      globalFilters.country === '' ||
      globalFilters.country === undefined ||
      (comparison.toLowerCase().replace(/ /g, '') === 'category' &&
        (!selectedProductFilter.category || (enableCompetitorFilter(globalFilters) && !selectedCompetitorFilter.category))) ||
      (comparison.toLowerCase().replace(/ /g, '') === 'segment' &&
        (!selectedProductFilter.segment || (enableCompetitorFilter(globalFilters) && !selectedCompetitorFilter.segment))) ||
      (comparison.toLowerCase().replace(/ /g, '') === 'brand' &&
        (!selectedProductFilter.brand || (enableCompetitorFilter(globalFilters) && !selectedCompetitorFilter.brand))) ||
      (comparison.toLowerCase().replace(/ /g, '') === 'subbrand' &&
        (!selectedProductFilter.subBrand || (enableCompetitorFilter(globalFilters) && !selectedCompetitorFilter.subBrand))) ||
      (comparison.toLowerCase().replace(/ /g, '') === 'packsize' &&
        (!selectedProductFilter.packSize || (enableCompetitorFilter(globalFilters) && !selectedCompetitorFilter.packSize)));

    return flag;
  };

  const formatData = (data) => {
    if (_.isArray(data) && data.length > 0) {
      const headings: any = [{ title: 'Product' }, { title: '' }];
      let columns = [];
      for (let i = 0; i < data.length; i++) {
        if (columns.length < data[i].priceTrackingProduct.length) {
          columns = data[i].priceTrackingProduct;
        }
      }
      if (columns.length > 0) {
        columns.map((item: any) => {
          headings.push({
            title: dataFormat(item.date, 'MMM') + ' ' + dataFormat(item.date, 'yyyy'),
          });
        });
      }
      const rows: any = [];
      Promise.all(
        data.map(async (item) => {
          let cell = {
            name: item.productName,
            image: {
              src: await rendarImage(item.productName, globalFilters.country),
              alt: item.productName,
              height: 100,
              width: 100,
              tooltip: item.productInfo,
            },
            rowspan:
              _.isArray(item.priceTrackingProduct) && item.priceTrackingProduct.length > 0 ? Object.keys(item.priceTrackingProduct[0]).length - 1 : 0,
          };
          let rowNames: any = [];
          if (_.isArray(item.priceTrackingProduct) && item.priceTrackingProduct.length > 0) {
            rowNames = Object.keys(item.priceTrackingProduct[0]).filter((x) => x !== 'date');
          }

          const row: any = {};
          item.priceTrackingProduct.map((cell) => {
            rowNames.map((key, i) => {
              row[key] = [...(row[key] ? row[key] : []), cell[key]];
            });
          });

          Object.keys(row).map((r, i) => {
            const payload: any = [];
            if (i === 0) payload.push(cell);
            rows.push([...payload, replaceString(r), ...row[r]]);
          });
        })
      ).then(() => {
        setHeaders(headings);
        setValues(rows);
        setPriceTrackingTableSkeleton(false);
      });
    }
  };

  const replaceString = (str) => {
    if (str === 'pricePerUnit') return '$/Unit';
    else if (str === 'pricePerVolume') return '$/Volume';
    else if (str === 'volumePerUnit') return 'Volume/Unit';
  };

  useEffect(() => {
    if (defaultFIlters && defaultFIlters.country) {
      setComparison(initialComparisonMapping[defaultFIlters?.comparisonLevel]);
      formatData(defaultFIlters);
      onApplyFilter();
    }
  }, [defaultFIlters]);

  return (
    <Grid>
      <Card className='m-b-20' style={{ position: 'relative' }}>
        <Indicator position='absolute' show={loader} />
        <CardContent>
          <Grid container spacing={2} className='m-b-10'>
            <Grid item>
              <Title>Price Tracking PepsiCo VS Competitor </Title>
            </Grid>
          </Grid>
          {!skeleton ? (
            <>
              <Grid container spacing={2} className='m-b-20'>
                <Grid item xs={12} sm={2}>
                  <RadioButtonGroup
                    key={`measures-api-filters`}
                    color='#000'
                    showButton={comparisonLevelConfig.showRadioButton}
                    data={comparisonLevelConfig.options}
                    select={(data) => {
                      setComparison(data);
                      setisSave(false);
                    }}
                    defaultOption={comparison}
                  />
                </Grid>
              </Grid>
              <FilterAccordion title='PEPSICO'>
                <Grid container spacing={2} columns={14} className='m-b-20'>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.vendor.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.vendor}
                      data={productFilter.vendor.options}
                      onChange={(val) => onChangeProductFilter('vendor', val)}
                      defaultOption={selectedProductFilter.vendor || ''}
                      search={{ enable: false }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.category.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.category}
                      data={productFilter.category.options}
                      onChange={(val) => onChangeProductFilter('category', val)}
                      defaultOption={selectedProductFilter.category || ['empty']}
                      placeholder={productFilter.category.placeholder}
                      multiple={productFilter.category.multiple}
                      allOption={productFilter.category.all}
                      selectionLimit={productFilter.category.selectionLimit}
                    />
                  </Grid>
                  {comparisonLevel.segment && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{productFilter.segment.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableProductFilter.segment}
                        data={productFilter.segment.options}
                        onChange={(val) => onChangeProductFilter('segment', val)}
                        defaultOption={selectedProductFilter.segment || ['empty']}
                        placeholder={productFilter.segment.placeholder}
                        multiple={productFilter.segment.multiple}
                        allOption={productFilter.segment.all}
                        selectionLimit={productFilter.segment.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.brand && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{productFilter.brand.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableProductFilter.brand}
                        data={productFilter.brand.options}
                        onChange={(val) => onChangeProductFilter('brand', val)}
                        defaultOption={selectedProductFilter.brand || ['empty']}
                        placeholder={productFilter.brand.placeholder}
                        multiple={productFilter.brand.multiple}
                        allOption={productFilter.brand.all}
                        selectionLimit={productFilter.brand.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.subBrand && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{productFilter.subBrand.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableProductFilter.subBrand}
                        data={productFilter.subBrand.options}
                        onChange={(val) => onChangeProductFilter('subBrand', val)}
                        defaultOption={selectedProductFilter.subBrand || ['empty']}
                        placeholder={productFilter.subBrand.placeholder}
                        multiple={productFilter.subBrand.multiple}
                        allOption={productFilter.subBrand.all}
                        selectionLimit={productFilter.subBrand.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.packSize && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{productFilter.packSize.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableProductFilter.packSize}
                        data={productFilter.packSize.options}
                        onChange={(val) => onChangeProductFilter('packSize', val)}
                        defaultOption={selectedProductFilter.packSize || ['empty']}
                        placeholder={productFilter.packSize.placeholder}
                        multiple={productFilter.packSize.multiple}
                        allOption={productFilter.packSize.all}
                        selectionLimit={productFilter.packSize.selectionLimit}
                      />
                    </Grid>
                  )}
                </Grid>
              </FilterAccordion>

              <FilterAccordion title='COMPETITOR'>
                <Grid container spacing={2} columns={14} className='m-b-20'>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{competitorFilter.vendor.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.vendor}
                      data={_.sortBy(competitorFilter.vendor.options, (i) => i.value)}
                      onChange={(val) => onChangeCompetitorFilter('vendor', val)}
                      defaultOption={selectedCompetitorFilter.vendor || ['empty']}
                      placeholder={competitorFilter.vendor.placeholder}
                      multiple={competitorFilter.vendor.multiple}
                      allOption={competitorFilter.vendor.all}
                      selectionLimit={competitorFilter.vendor.selectionLimit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{competitorFilter.category.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableCompetitorFilter.category}
                      data={competitorFilter.category.options}
                      onChange={(val) => onChangeCompetitorFilter('category', val)}
                      defaultOption={selectedCompetitorFilter.category || ['empty']}
                      placeholder={competitorFilter.category.placeholder}
                      multiple={competitorFilter.category.multiple}
                      allOption={competitorFilter.category.all}
                      selectionLimit={competitorFilter.category.selectionLimit}
                    />
                  </Grid>
                  {comparisonLevel.segment && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{competitorFilter.segment.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableCompetitorFilter.segment}
                        data={competitorFilter.segment.options}
                        onChange={(val) => onChangeCompetitorFilter('segment', val)}
                        defaultOption={selectedCompetitorFilter.segment || ['empty']}
                        placeholder={competitorFilter.segment.placeholder}
                        multiple={competitorFilter.segment.multiple}
                        allOption={competitorFilter.segment.all}
                        selectionLimit={competitorFilter.segment.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.brand && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{competitorFilter.brand.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableCompetitorFilter.brand}
                        data={competitorFilter.brand.options}
                        onChange={(val) => onChangeCompetitorFilter('brand', val)}
                        defaultOption={selectedCompetitorFilter.brand || ['empty']}
                        placeholder={competitorFilter.brand.placeholder}
                        multiple={competitorFilter.brand.multiple}
                        allOption={competitorFilter.brand.all}
                        selectionLimit={competitorFilter.brand.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.subBrand && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{competitorFilter.subBrand.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableCompetitorFilter.subBrand}
                        data={competitorFilter.subBrand.options}
                        onChange={(val) => onChangeCompetitorFilter('subBrand', val)}
                        defaultOption={selectedCompetitorFilter.subBrand || ['empty']}
                        placeholder={competitorFilter.subBrand.placeholder}
                        multiple={competitorFilter.subBrand.multiple}
                        allOption={competitorFilter.subBrand.all}
                        selectionLimit={competitorFilter.subBrand.selectionLimit}
                      />
                    </Grid>
                  )}
                  {comparisonLevel.packSize && (
                    <Grid item xs={12} sm={2}>
                      <DropdownTitle>{competitorFilter.packSize.title}</DropdownTitle>
                      <Dropdown
                        disabled={disableCompetitorFilter.packSize}
                        data={competitorFilter.packSize.options}
                        onChange={(val) => onChangeCompetitorFilter('packSize', val)}
                        defaultOption={selectedCompetitorFilter.packSize || ['empty']}
                        placeholder={competitorFilter.packSize.placeholder}
                        multiple={competitorFilter.packSize.multiple}
                        allOption={competitorFilter.packSize.all}
                        selectionLimit={competitorFilter.packSize.selectionLimit}
                      />
                    </Grid>
                  )}
                </Grid>
              </FilterAccordion>

              <Grid className='p-l-16'>
                <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
                  Clear Filter
                </OrangeBtn>
                <PrimaryBtn color='primary' onClick={onApplyFilter} disabled={disableApplyFilter()}>
                  Apply Filter
                </PrimaryBtn>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} className='m-b-10'>
                <Grid item xs={2}>
                  <Skeleton variant='rectangular' height={35} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-10'>
                <Grid item xs={2}>
                  <Skeleton variant='rectangular' height={35} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-10'>
                <Grid item xs={2}>
                  <Skeleton variant='rectangular' height={35} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-10'>
                <Grid item xs={2}>
                  <Skeleton variant='rectangular' height={35} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-10'>
                <Grid item xs={2}>
                  <Skeleton variant='rectangular' height={35} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-20'>
                <Grid item xs={12}>
                  <Skeleton variant='rectangular' height={45} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className='m-b-20'>
                <Grid item xs={12}>
                  <Skeleton variant='rectangular' height={45} />
                </Grid>
              </Grid>
              <Grid className='p-l-16 m-t-20' container spacing={2}>
                <Skeleton variant='rectangular' className='m-r-20' width={99} height={45} />
                <Skeleton variant='rectangular' className='m-r-20' width={110} height={45} />
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {priceTrackingTableSkeleton ? (
            <Grid className='m-t-20'>
              <CustomTable heading={priceTrackingTableHeading} value={priceTrackingTableSkeletonData} showSkeleton={priceTrackingTableSkeleton} />
            </Grid>
          ) : values && values.length > 0 ? (
            <Dashboard
              title={null}
              showSkeleton={priceTrackingTableSkeleton}
              chartRef={() => {}}
              showActionButton={true}
              actionButtons={{
                mmpw: false,
                screenshot: true,
                editMedia: false,
              }}
              id={null}
            >
              <Grid
                className='m-t-20'
                style={{
                  maxWidth: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)',
                }}
              >
                <CustomTable heading={headers} value={values} showSkeleton={priceTrackingTableSkeleton} />
              </Grid>
            </Dashboard>
          ) : (
            <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.noData}</NoData>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PriceTrackingAPIFilters;
