import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import DashboardPreviewCard from "../../../../components/DashboardPreviewCard";
import { LoadMoreBtn } from "../Dashboard.Styled";
import _ from "lodash";
import NoDashboard from "../../../../components/NoDashboard/NoDashboard";
import { dashboardCardMock } from "../../../../mocks/common";
import { sortData } from "../../../../util/helper";
import { getTrendingDashboard } from "../../../../util/services";

const TrendingDashboard: React.FC<{ sortOrder }> = ({ sortOrder }) => {
  const [allDashboards, setAllDashboards] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [skeleton, setSkeleton] = useState(true);

  const fetchTrendingDashboards = async () => {
    setSkeleton(true);
    const response = await getTrendingDashboard({ type: 'dashboard' });
    if (response && _.isArray(response.data) && response.data.length) {
      sortDashboards(response.data);
    }
    setSkeleton(false);
  };

  const sortDashboards = (data) =>{
    const sortedData = sortData(sortOrder, data);
    setAllDashboards(sortedData);
  }

  React.useEffect(() => {
    sortDashboards(allDashboards);
  }, [sortOrder])

  React.useEffect(() => {
    fetchTrendingDashboards();
  }, []);

  useEffect(() => {
    if (_.isArray(allDashboards) && allDashboards.length) {
      setDashboards([..._.take(allDashboards, 4)]);
    }
  }, [allDashboards])

  const loadMore = () => {
    setDashboards([...allDashboards]);
  }

  return (
    <Grid>
      {skeleton ?
        <Grid container spacing={2} className="m-b-15">
          {dashboardCardMock.map((item, index) =>
            <Grid item xs={12} sm={3} md={3} key={`favorite-dashboard-cards-${index}`}>
              <DashboardPreviewCard
                data={item}
                skeleton={true}
              />
            </Grid>

          )}
        </Grid>
        :
        <>
          {allDashboards.length > 0 ?
            <Grid>
              <Typography className="m-b-15">
                Most popular dashboards on last 30 days
              </Typography>
              <Grid container spacing={2} className="m-b-15">
                {dashboards.map((item, index) => (
                  <Grid item xs={12} sm={3} md={3} key={`trending-dashboard-cards-${index}`}>
                    <DashboardPreviewCard
                      data={item}
                      onToggleFavorite={fetchTrendingDashboards}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box
                className="m-b-15"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {_.isArray(allDashboards) && dashboards.length !== allDashboards.length ?
                  <LoadMoreBtn onClick={loadMore}>Load More</LoadMoreBtn>
                  : null}
              </Box>
            </Grid>
            :
            <Grid item xs={12} justifyContent="center">
              <NoDashboard heading="No trending dashboards available" />
            </Grid>
          }
        </>
      }
    </Grid>
  );
};
export default TrendingDashboard;
