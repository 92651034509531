import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import Dashboard from '../../../../components/Dashboard';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import mockData from '../../../../mocks/priceRangePianoMock';
import { loader, removeLabels } from '../../../../store/actions/common.action';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { separator } from '../../../../util/helper';
import { fetchPricePianoData } from '../../service';
import { pricePianoDataLoader } from '../../store/PriceRangePiano.action';
import PriceRangeGraph from './components/PriceRangeGraph';
const useStyles = makeStyles(() => ({
  hide: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const ShareOfMarketPiano: React.FC<{ filters; defaultFilters }> = ({ filters, defaultFilters }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [skeleton, setSkeleton] = React.useState(true);
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const [priceTableHeading, setPriceTableHeading] = useState({
    ...mockData.priceRangeTableHeading,
  });
  const [tableValues, setTableValues] = React.useState([]);
  const [selectedResponse, setSelectedResponse] = React.useState({
    pepsi: null,
    competitors: null,
  });
  const [originalResponse, setOriginalResponse] = React.useState({});

  useEffect(() => {
    if (!_.isEmpty(selectedResponse.pepsi) && !_.isEmpty(selectedResponse.competitors)) {
      formatTableData([selectedResponse.pepsi, selectedResponse.competitors]);
    }
  }, [selectedResponse]);

  const getShareOfMarketPiano = (payload) => {
    setSkeleton(true);
    const requestPayload = { ...payload };
    if (requestPayload.periodView[0] ==="na") {
      requestPayload.periodView = 'null';
    }
    else{
      requestPayload.periodView = requestPayload.periodView[0].toString()
    }
    fetchPricePianoData(requestPayload)
      .then((response) => {
        if (response && response.data && response.status === 200) {
          setOriginalResponse(response.data);
          // setSelectedResponse({ ...response.data.weekly });
          dispatch(pricePianoDataLoader(false));
        } else {
          setSkeleton(false);
        }
      })
      .catch((e) => {
        setSkeleton(false);
        dispatch(pricePianoDataLoader(false));
      });
  };
  useEffect(() => {
    if (filters && filters.geoLevel2) {
      setTableValues([]);
      setSelectedResponse({
        pepsi: null,
        competitors: null,
      });
      setOriginalResponse({});
      setTimeout(() => {
        getShareOfMarketPiano(filters);
      }, 0);
    }
  }, [filters]);

  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      getShareOfMarketPiano(defaultFilters);
    }
  }, [defaultFilters]);

  const formatTableData = (data) => {
    if (_.isArray(data) && data.length > 0) {
      const headings: any = [{ title: 'Product' }, { title: 'Price Ranges' }];
      data[0].priceRanges.map((item) => {
        headings.push({ title: item.date });
      });
      const rows: any = [];
      data.map(async (item, i) => {
        let cell1 = {
          name: item.productName === 'Pepsi' ? 'PepsiCo' : item.productName,
          image: { src: null },
          rowspan: _.isArray(item.priceRanges) && item.priceRanges.length > 0 ? Object.keys(item.priceRanges[0]).length - 1 : 0,
        };

        let rowNames: any = [];
        if (_.isArray(item.priceRanges) && item.priceRanges.length > 0) {
          rowNames = Object.keys(item.priceRanges[0]).filter((x) => x !== 'date');
        }

        const row: any = {};
        if (item.priceRanges !== null) {
          item.priceRanges.map((cell) => {
            rowNames.map((key, i) => {
              if (key === 'total') {
                row['Total'] = [
                  ...(row['Total'] ? row['Total'] : []),
                  {
                    style: { fontWeight: 600, fontSize: 15 },
                    value: separator(cell[key], 1),
                  },
                ];
              } else {
                row[key] = [...(row[key] ? row[key] : []), separator(cell[key], 1)];
              }
            });
          });
        }
        Object.keys(row).map((r, i) => {
          const payload: any = [];
          if (i === 0) {
            payload.push(cell1);
          }
          let cell2: any = r;
          if (r === 'Total') {
            cell2 = { style: { fontWeight: 600, fontSize: 15 }, value: r };
          }
          rows.push([...payload, cell2, ...row[r]]);
        });
        if (i < data.length - 1) {
          rows.push([{ name: 'col', colspan: 15 }]);
        }
      });
      setPriceTableHeading(headings);
      setTableValues(rows);
    }
  };

  const onChangePeriodicity = (res) => {
    if (res && Object.keys(res).length > 0) {
      dispatch(loader(true));
      setTimeout(() => {
        if (res) setSelectedResponse(res);
      }, 100);
    }
    setSkeleton(false);
    dispatch(removeLabels('price-range-share-of-market-piano-1'));
    dispatch(removeLabels('price-range-share-of-market-piano-2'));
  };

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard
          title={null}
          showSkeleton={skeleton}
          chartRef={() => {}}
          showActionButton={true}
          actionButtons={{
            mmpw: false,
            screenshot: true,
            editMedia: false,
          }}
          id={null}
        >
          <Grid container style={{ width: '100%' }} className='m-b-20'>
            <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <Grid
                style={{
                  position: 'absolute',
                  bottom: 10,
                  width: 100,
                  zIndex: 2,
                }}
                className={classes.hide}
              >
                {originalResponse && Object.keys(originalResponse).length > 0 && (
                  <IncrementDecrement
                    data={originalResponse}
                    selectedResponse={onChangePeriodicity}
                    show={selectedResponse && selectedResponse.pepsi && Object.keys(selectedResponse.pepsi).length > 0}
                    defaultOption={
                      (localStorage.getItem('periodicity') &&
                        JSON.parse(localStorage.getItem('periodicity'))['price-range-share-of-market-piano']?.item) ||
                      null
                    }
                    id='price-range-share-of-market-piano'
                  />
                )}
              </Grid>
              <PriceRangeGraph
                data={selectedResponse.pepsi}
                skeleton={skeleton}
                title="Share of Market at Price Point | <span style='color:#0093CD;'>PepsiCo</span>"
                tooltip='Share of Market at Price Point | PepsiCo'
                id='price-range-share-of-market-piano-1'
                type='pepsi'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PriceRangeGraph
                data={selectedResponse.competitors}
                skeleton={skeleton}
                title="Share of Market at Price Point | <span style='color:#0093CD;'>Competitor</span>"
                tooltip='Share of Market at Price Point | Competitor'
                id='price-range-share-of-market-piano-2'
                type='competitor'
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              {skeleton ? (
                <Grid className='m-t-20'>
                  <Grid item xs={12} sm={12} md={12}>
                    <CustomTable heading={mockData.priceRangeTableHeading} value={mockData.pricePianoTableSkeletonData} showSkeleton={skeleton} />
                  </Grid>
                </Grid>
              ) : tableValues.length > 0 ? (
                <Grid
                  container
                  className='m-b-20'
                  style={{
                    maxWidth: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)',
                  }}
                >
                  <CustomTable
                    heading={priceTableHeading}
                    value={tableValues}
                    callback={() => {}}
                    onCellBlur={() => {}}
                    isFormatNumber={true}
                    customTableCellStyle={true}
                  />
                </Grid>
              ) : (
                <Box style={{ height: 200 }} display='flex' justifyContent='center' alignItems='center'>
                  <NoData>{messages.noData}</NoData>
                </Box>
              )}
            </Grid>
          </Grid>
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default ShareOfMarketPiano;
