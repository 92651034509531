import Mexico from '../assets/images/Mexico.png';
import Paraguay from '../assets/images/Paraguay.png';

const categories = [
  { label: 'Sav-Salty', value: 'SAV-SALTY' },
  { label: 'Sweet', value: 'Sweet' },
  { label: 'Yummy', value: 'Yummy' },
  { label: 'Crunchy', value: 'Crunchy' },
  { label: 'No Sugar', value: 'No Sugar' },
  { label: 'Baked', value: 'Baked' },
];

const countries = [
  {
    id: '1',
    label: 'Mexico',
    value: 'MEXICO',
    default: false,
    initial: 'MX',
    region: 'north_america_mill',
  },
  {
    id: '2',
    label: 'Argentina',
    value: 'ARGENTINA',
    default: false,
    initial: 'AR',
    region: 'south_america_mill',
  },
  {
    id: '3',
    label: 'Chile',
    value: 'CHILE',
    default: false,
    initial: 'CL',
    region: 'south_america_mill',
  },
  {
    id: '4',
    label: 'Brazil',
    value: 'BRAZIL',
    icon: Mexico,
    default: false,
    initial: 'BR',
    region: 'south_america_mill',
  },
  {
    id: '5',
    label: 'Colombia',
    value: 'COLOMBIA',
    default: false,
    initial: 'CO',
    region: 'south_america_mill',
  },
  {
    id: '6',
    label: 'Guatemala',
    value: 'GUATEMALA',
    default: false,
    initial: 'GT',
    region: 'north_america_mill',
  },
];
const periodicities = [
  { id: '1', label: 'Daily', value: 'Daily', default: false },
  { id: '2', label: 'Weekly', value: 'Weekly', default: false },
  { id: '3', label: 'Monthly', value: 'Monthly', default: false },
  { id: '4', label: 'Quarterly', value: 'Quarterly', default: false },
  { id: '5', label: 'Yearly', value: 'Yearly', default: false },
];

const periodicFilters = [
  {
    group: [
      { id: '1', value: 'Year to Date' },
      { id: '2', value: 'Running Year' },
      { id: '3', value: 'Quarter to Date' },
    ],
  },
  {
    group: [
      { id: '4', value: 'Q1 2021' },
      { id: '5', value: 'Q2 2021' },
    ],
  },
  {
    group: [
      { id: '6', value: 'Month to Date' },
      { id: '7', value: 'Last Month' },
      { id: '8', value: 'Last Week' },
    ],
  },
];

const sortOption = [
  { id: '1', value: 'Default' },
  { id: '2', value: 'A-Z' },
  { id: '3', value: 'Z-A' },
];

const standardSortOption = [
  { id: '1', label: 'A-Z', value: 'a-z' },
  { id: '2', label: 'Newest', value: 'newest' },
  { id: '3', label: 'Most Popular', value: 'most-popular' },
  { id: '4', label: 'Z-A', value: 'z-a' },
];

const types = [
  { id: '1', value: 'Predictive' },
  { id: '2', value: 'Descriptive' },
];

const pageList = [
  {
    id: 'price-architecture',
    title: '',
    value: 'Price_Architecture',
    dashboardId: 7,
  },
  {
    id: 'api-vs-volume',
    title: '',
    value: 'API_VS_Volume_Dashboard',
    dashboardId: 4,
    lable: 'API vs Volume',
  },
  {
    id: 'profit-parabola-dashboard',
    title: '',
    value: 'Profit_Parabola',
    dashboardId: 3,
    lable: 'Profit Parabola',
  },
  {
    id: 'sales-by-price-point',
    title: 'Sales By Price Point',
    value: 'Sales_By_Price_Point',
    dashboardId: 5,
    lable: 'Sales by Price Point',
  },
  {
    id: 'macro-econ-dashboard',
    title: '',
    value: 'Macro_Econ_Dashboard',
    dashboardId: 6,
    lable: 'Macro-Econ',
  },
  {
    id: 'sell-out-dashboard',
    title: '',
    value: 'Sell_Out_Dashboard',
    dashboardId: 9,
    lable: 'Sell Out',
  },
  {
    id: 'revenue-map',
    title: '',
    value: 'Revenue_Map',
    dashboardId: 7,
    lable: 'Revenue Map',
  },
  {
    id: 'historic-price',
    title: '',
    value: 'Histroric_Price',
    dashboardId: 8,
    lable: 'Historic Price',
  },
  {
    id: 'elasticity-pricing-track',
    title: '',
    value: 'Elasticity_Pricing_Track',
    dashboardId: 10,
    lable: 'Elasticity Pricing Track',
  },
  {
    id: 'pricing-potential',
    title: '',
    value: 'Pricing_Potential',
    dashboardId: 25,
    lable: 'Pricing Potential',
  },
  {
    id: 'brand-ladder',
    title: '',
    value: 'Brand_Ladder',
    dashboardId: 26,
    lable: 'Brand Ladder',
  },
  {
    id: 'pack-economics',
    title: '',
    value: 'Pack_Economics',
    dashboardId: 28,
    lable: 'Pack Economics',
  },
  {
    id: 'price-setting-tool',
    title: '',
    value: 'Price_Setting_Tool',
    dashboardId: 30,
    lable: 'Price Setting Tool',
  },
  {
    id: 'price-range-piano',
    title: '',
    value: 'Price_Range_Piano',
    dashboardId: 32,
    lable: 'Price Range Piano',
  },
  {
    id: 'price-ladder',
    title: '',
    value: 'Price_Ladder',
    dashboardId: 27,
    lable: 'Price-Ladder',
  },
  {
    id: 'trade-management',
    title: '',
    value: 'Trade_Management',
    dashboardId: null,
    lable: 'Trade-Management',
  },
  {
    id: 'mix-management',
    title: '',
    value: 'Mix_Management',
    dashboardId: null,
    lable: 'Mix-Management',
  },
  {
    id: 'integrated-strategy',
    title: '',
    value: 'Integrated_Strategy',
    dashboardId: null,
    lable: 'Integrated-Strategy',
  },
  {
    id: 'home',
    title: '',
    value: 'Home',
    dashboardId: null,
    lable: 'Home',
  },
];

const dashboardCardMock = [
  {
    name: 'Profit Parabola',
    viewCount: 0,
    thumbnailUrl: '',
    route: '/profit-parabola-dashboard',
    countries: ['Mexico', 'Chili'],
    type: 'Predictive',
    createdOn: '2022-02-10T00:00:00',
  },
  {
    name: 'API VS Volume',
    viewCount: 0,
    thumbnailUrl: '',
    route: '/api-vs-volume',
    countries: ['Mexico', 'Chili'],
    type: 'Predictive',
    createdOn: '2022-02-11T00:00:00',
  },
  {
    name: 'EPT',
    viewCount: 0,
    thumbnailUrl: '',
    route: '/elasticity-pricing-track',
    countries: ['Mexico', 'Chili'],
    type: 'Predictive',
    createdOn: '2022-02-10T00:00:00',
  },
  {
    name: 'HPCPE',
    viewCount: 0,
    thumbnailUrl: '',
    route: '/historic-price',
    countries: ['Mexico', 'Chili'],
    type: 'Predictive',
    createdOn: '2022-02-11T00:00:00',
  },
];

const pageNames = {
  home: {
    title: 'Home',
  },
  createUser: {
    title: 'CreateUser',
  },
  editUser: {
    title: 'EditUser',
  },
  priceArchitecture: {
    title: 'PriceArchitecture',
  },
  integratedStrategy: {
    title: 'IntegratedStrategy',
  },
  tradeManagement: {
    title: 'TradeManagement',
  },
  mixManagement: {
    title: 'MixManagement',
  },
  alertAndNotification: {
    title: 'AlertAndNotification',
  },
  notificationDetail: {
    title: 'NotificationDetail',
  },
  userSettings: {
    title: 'UserSettings',
  },
  mmpwTest: {
    title: 'MMPWTest',
  },
  userList: {
    title: 'UserList',
  },
  geoXREF: {
    title: 'GeoXREF',
  },
  productXREF: {
    title: 'ProductXREF',
  },
  dataSourceRefresh: {
    title: 'DataSourceRefresh',
  },
};

export { categories, countries, periodicFilters, sortOption, standardSortOption, types, pageList, dashboardCardMock, pageNames, periodicities };
