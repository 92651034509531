import { MACRO_ECON_VAR_DATA_SRC, MACRO_ECON_DATA, MACRO_ECON_PRODUCT_FILTER, MACRO_ECON_CHECK_DATA } from "./constants";

export const MacroEconVarDataSrc = (payload) => {
    return {
        type: MACRO_ECON_VAR_DATA_SRC,
        payload,
    };
};

export const MacroEconData = (payload) => {
    return {
        type: MACRO_ECON_DATA,
        payload,
    };
};

export const ProductFilters = (payload) => {
    return {
        type: MACRO_ECON_PRODUCT_FILTER,
        payload,
    };
};

export const MacroEconCheckData = (payload) => {
    return {
        type: MACRO_ECON_CHECK_DATA,
        payload,
    };
};
