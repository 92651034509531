import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import SalesValueAndPerformance from './SalesValueAndPerformance/SalesValueAndPerformance';
import { getValueTrendData } from '../service';
const SalesValueAndPerformanceContainer: React.FC<{ appliedFilters; setisLoading; isLoading }> = ({ appliedFilters, setisLoading, isLoading }) => {
  const [competitorSalesValueTrend, setCompetitorSalesValueTrend] = useState({});
  const [pepsiSalesValueTrend, setpepsiSalesValueTrend] = useState({});
  const fetchValueTrendData = async () => {
    try {
      setCompetitorSalesValueTrend({});
      setpepsiSalesValueTrend({});
      const response = await getValueTrendData(appliedFilters);

      if (response && response.data && Object.keys(response.data).length > 0) {
        const newResponse = {
          pepsiSalesValueTrend: { weekly: null, monthly: null, quarterly: null, yearly: null },
          competitorSalesValueTrend: { weekly: null, monthly: null, quarterly: null, yearly: null },
        };
        try {
          Object.keys(response.data).map((key) => {
            newResponse.pepsiSalesValueTrend[key] = response.data[key].pepsiSalesValueTrend;
            newResponse.competitorSalesValueTrend[key] = response.data[key].competitorSalesValueTrend;
          });
        } catch (error) {
          setCompetitorSalesValueTrend({});
          setpepsiSalesValueTrend({});
          console.log(error);
        }
        setTimeout(() => {
          setCompetitorSalesValueTrend(newResponse.competitorSalesValueTrend);
          setpepsiSalesValueTrend(newResponse.pepsiSalesValueTrend);
        }, 0);
      }
    } catch (e) {
      setCompetitorSalesValueTrend({});
      setpepsiSalesValueTrend({});
      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (appliedFilters && Object.keys(appliedFilters).length > 0) {
      fetchValueTrendData();
    }
  }, [appliedFilters]);

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={6}>
        <SalesValueAndPerformance
          title='Sales Value Trends and SOP Performance | <span style="color:#0093CD;">PepsiCo</span>'
          tooltip='Sales Value Trends and SOP Performance | PepsiCo'
          valueTrend={pepsiSalesValueTrend}
          appliedFilters={appliedFilters}
          isLoading={isLoading}
          id='sales-value-trends-and-sop-performance-1'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SalesValueAndPerformance
          title='Sales Value Trends and SOP Performance | <span style="color:#0093CD;">Competitor</span>'
          tooltip='Sales Value Trends and SOP Performance | Competitor'
          valueTrend={competitorSalesValueTrend}
          appliedFilters={appliedFilters}
          isLoading={isLoading}
          id='sales-value-trends-and-sop-performance-2'
        />
      </Grid>
    </Grid>
  );
};

export default SalesValueAndPerformanceContainer;
