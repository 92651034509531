import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid } from '@mui/material';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import _ from 'lodash';
import Indicator from '../../../../components/Loader';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import FilterAccordion from '../../../../components/Filters/Filters';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import { dataFormat, selectAllOptions } from '../../../../util/helper';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { fetchGeoFiltersData } from '../../../../store/actions/geoFilter.action';
import MeasureFilters from '../MeasureFilters/MeasureFilters';
import { getProductFilter } from '../../service';
import { mapFilterOptions } from '../../../../util/helper';
import { clearProductFilters } from '../../../../store/actions/productFilter.action';
import { periodView } from '../../../../mocks/revenueMap';
import { defaultCalendarDates } from '../../../../util/config';
import { initialState } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import Dropdown from '../../../../components/Dropdown';
import CustomDateRangePicker from '../../../../components/UI-components/DateRangePicker';
import { DateTime } from 'luxon';
import { toast } from '../../../../store/actions/toast.action';
import { fetchPeriodViewDates } from '../../../../util/services';

const initialProductState = {
  category: null,
  vendor: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
};

const TopFilters: React.FC<{ callback; data; defaultFilters }> = ({ callback, data, defaultFilters }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [productFilter, setProductFilter] = React.useState({
    ...data.topProductFilterConfig,
  });

  const [selectedProductFilters, setSelectedProductFilters] = useState(initialProductState);

  const [selectedMeasureFilters, setMeasureFilter] = useState({
    viewTop: 5,
    data: null,
    viewX: null,
    viewY: null,
    viewSize: null,
    viewType: null,
  });

  const [selectedDate, setDate] = React.useState(
    defaultFilters?.date ? defaultFilters?.date.split('-') : [defaultCalendarDates.startDate, defaultCalendarDates.endDate]
  );

  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);

  const [clearGeoFilterData, clearGeoFilter] = useState(false);
  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      clearGeoFilter(false);
      Object.keys(data.topProductFilterConfig).map((key) => (data.topProductFilterConfig[key].options = [])); //Clear the product data
      setLoader(true);
      getProductFilter({
        country: selectedGeoFilters.country,
        ...initialProductState,
      })
        .then((res) => {
          setLoader(false);
          let tempProductFilter = { ...productFilter };
          tempProductFilter = mapFilterOptions(res, data.topProductFilterConfig);
          setProductFilter(tempProductFilter);
          setSelectedProductFilters({
            ...initialProductState,
            ...selectAllOptions(res, data.topProductFilterConfig),
          });
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  }, [selectedGeoFilters.country]);

  const onApplyFilter = () => {
    const filters = {
      ...selectedGeoFilters,
      ...selectedMeasureFilters,
      ...selectedProductFilters,
      date: selectedDate,
    };
    const payload = {
      ...selectedGeoFilters,
      ...selectedMeasureFilters,
      ...selectedProductFilters,
      date: `${dataFormat(selectedDate[0], 'MM/dd/yyyy')}-${dataFormat(selectedDate[1], 'MM/dd/yyyy')}`,
      startDate: selectedDate[0],
      endDate: selectedDate[1],
    };
    callback(payload);
  };

  const clearFilter = () => {
    clearGeoFilter(true);
    dispatch(clearProductFilters());
    setSelectedGeoFilters({ ...initialState });
    setSelectedProductFilters({ ...initialProductState });
    setMeasureFilter({
      viewTop: 5,
      data: null,
      viewX: null,
      viewY: null,
      viewSize: null,
      viewType: null,
    });
  };

  useEffect(() => {
    dispatch(fetchGeoFiltersData('Revenue'));
  }, []);

  const geoFilter = useSelector((state: any) => state.geoFilter);
  const reduxState = useSelector((state: any) => state);
  const RevenueMap = useSelector((state: any) => state.RevenueMap);

  const onChangeMeasureFilter = (e) => {
    const selectedMeasures = { ...selectedMeasureFilters };
    if (e.key === 'data') {
      selectedMeasures.viewX = null;
      selectedMeasures.viewY = null;
    }
    setMeasureFilter({ ...selectedMeasures, [e.key]: e.val });
  };

  const disableApplyFilter = () => {
    const flag: boolean =
      geoFilter.loading ||
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel2 ||
      !selectedMeasureFilters?.viewTop ||
      !selectedMeasureFilters?.viewSize ||
      !selectedMeasureFilters?.data ||
      !selectedMeasureFilters?.viewX ||
      !selectedMeasureFilters?.viewY ||
      _.isEmpty(selectedProductFilters.category) ||
      !selectedMeasureFilters?.viewType;
    return flag;
  };

  useEffect(() => {
    console.log(defaultFilters);

    if (defaultFilters && defaultFilters?.country) {
      setSelectedGeoFilters({
        country: defaultFilters.country,
        geoLevel: defaultFilters.geoLevel,
        geoLevel2: defaultFilters.geoLevel2,
        channel: defaultFilters.channel,
        periodView: defaultFilters.periodView,
      });

      setMeasureFilter({
        viewTop: defaultFilters.viewTop,
        data: defaultFilters.data,
        viewX: defaultFilters.viewX,
        viewY: defaultFilters.viewY,
        viewSize: defaultFilters.viewSize,
        viewType: defaultFilters.viewType,
      });
    }
  }, [defaultFilters]);

  const OnProductFiltersChange = (data) => {
    setSelectedProductFilters({ ...data });
    clearGeoFilter(false);
  };

  const setSelectedGeoFiltersLogic = (e) => {
    setSelectedGeoFilters({ ...e });
    if (clearGeoFilterData) {
      clearGeoFilter(false);
    }
  };

  useEffect(() => {
    onChangePeriodView();
  }, [selectedGeoFilters, selectedProductFilters.category]);

  const onChangePeriodView = () => {
    if (
      selectedGeoFilters.periodView[0] !== 'na' &&
      selectedGeoFilters.country &&
      selectedGeoFilters.geoLevel &&
      selectedGeoFilters.geoLevel2 &&
      selectedProductFilters.category
    ) {
      setLoader(true);
      const payload = {
        period: selectedGeoFilters.periodView[0],
        country: selectedGeoFilters.country,
        geoLevel: selectedGeoFilters.geoLevel,
        geoLevel2: selectedGeoFilters.geoLevel2,
        category: selectedProductFilters.category,
      };
      fetchPeriodViewDates('RevenueMap', payload)
        .then((res) => {
          const response = res.data;
          if (res.status === 200 && response.startDate && response.endDate) {
            const startDate = DateTime.fromString(response.startDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            const endDate = DateTime.fromString(response.endDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            setDate([startDate, endDate]);
          } else {
            dispatch(toast('Dates Unavailable!', true, 2000, 'error'));
          }
          setLoader(false);
        })
        .catch((e) => {
          dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
          setLoader(false);
        });
    }
  };

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        show={
          !reduxState.common.loader &&
          (RevenueMap.quadrantIntensityLoader ||
            RevenueMap.simulationCurrentValueDataLoader ||
            RevenueMap.simulationPredictiveValueDataLoader ||
            RevenueMap.viewTypeDataLoader ||
            geoFilter.countriesLoading ||
            geoFilter.geoLevelLoading ||
            loader)
        }
      />
      <CardContent>
        <FilterAccordion title='Geo-Filters'>
          <GeoFiltersV2
            key='revenue-map-top-filter'
            data={{ ...geoFilterV2Config }}
            onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
            clearFilter={clearGeoFilterData}
            apiPath='RevenueMap'
            showLoader={setLoader}
            defaultFilters={defaultFilters}
            showDatePicker={false}
          />
        </FilterAccordion>

        <FilterAccordion title='Product Filters'>
          <ProductFiltersV3
            onChange={(e) => OnProductFiltersChange(e)}
            data={data.productFilter}
            onChangeLoader={setLoader}
            selectedGeoFilters={selectedGeoFilters}
            clearFilter={clearGeoFilterData}
            apiURL='filter/RevenueMapProductFilter?type=product'
            defaultFilters={
              defaultFilters
                ? {
                  category: defaultFilters.category,
                  vendor: defaultFilters.vendor,
                  segment: defaultFilters.segment,
                  brand: defaultFilters.brand,
                  subBrand: defaultFilters.subBrand,
                  packSize: defaultFilters.packSize,
                }
                : null
            }
          />
        </FilterAccordion>
        <FilterAccordion title='Date Filters'>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={2}>
              <DropdownTitle>{periodView.title}</DropdownTitle>
              <Dropdown
                data={periodView.options}
                onChange={(periodView) => {
                  setSelectedGeoFilters({ ...selectedGeoFilters, periodView });
                }}
                defaultOption={selectedGeoFilters.periodView || ['empty']}
                placeholder={periodView.placeholder}
                sort={{ enable: false }}
                search={{ enable: false }}
                disabled={selectedProductFilters?.category?.length === 0 || !selectedProductFilters?.category}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={2} lg={2}>
              <DropdownTitle>Date Range</DropdownTitle>
              <CustomDateRangePicker defaultDate={selectedDate} callback={setDate} disabled={selectedGeoFilters.periodView[0] !== 'na'} />
            </Grid>
          </Grid>
        </FilterAccordion>
        <FilterAccordion title='Measure Filters'>
          <MeasureFilters
            onChange={(e) => onChangeMeasureFilter(e)}
            selectedMeasureFilters={selectedMeasureFilters}
            defaultFilters={defaultFilters}
          />
        </FilterAccordion>
        <Grid className='p-l-16'>
          <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
