import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import HighchartContainer from '../../../../components/HighchartContainer/HighchartContainer';
import { chartConfig } from '../../chartConfig';
import { BlackTitle, NoData } from '../../../../styles/Common.Styled';
import Dashboard from '../../../../components/Dashboard/Dashboard';
import { getReports } from '../../service';
import { messages } from '../../../../util/config';
import { useDispatch } from 'react-redux';
import { loader } from '../../../../store/actions/common.action';
const Report: React.FC<{ seriesData; filters }> = ({ seriesData, filters }) => {
  const chartRef = useRef(null);
  const [skeleton, setSkeleton] = useState(true);
  const [chartTableData, setChartTableData] = useState({ ...seriesData });
  const dispatch = useDispatch();


  useEffect(() => {
    if (filters && filters?.country && filters?.country.length > 0 && seriesData?.item?.endPoint) {
      setSkeleton(true);
      dispatch(loader(true))
      getReports(filters, seriesData?.item?.endPoint)
        .then((res) => {
          if (res.status === 200 && res.data) {
            let newSeriesData = { ...seriesData };

            //chart data
            newSeriesData.item.data = res.data.chartData.map((item) => {
              return item.value;
            });
            newSeriesData.item.categories = res.data.chartData.map((item) => {
              return item.date;
            });
            //chart data end

            //table data
            if (res.data.tableData && res.data.tableData.length > 0 && newSeriesData.item.tableData.show) {
              let headers: any = [{ title: 'Dashboard Name' }, { title: 'Total Count' }];
              let allValues: any = [];
              res.data.tableData.map((item: any, i) => {
                let values: any = [];
                if (i === 0) {
                  item.data.map((record) => headers.push({ title: record.label }));
                }
                values.push(item.dashboardName);
                values.push(item.totalCount);

                item.data.map((record) => values.push(record.value));
                allValues.push(values);
              });
              newSeriesData.item.tableData.headers = headers;
              newSeriesData.item.tableData.values = allValues;
            }
            //table data end
            setChartTableData(newSeriesData);
            setSkeleton(false);
            dispatch(loader(false))

          } else {
            let newSeriesData = { ...chartTableData };
            newSeriesData.item.tableData.headers = [];
            newSeriesData.item.tableData.values = [];
            newSeriesData.item.data = [];
            newSeriesData.item.categories = [];
            setChartTableData(newSeriesData);
            setSkeleton(false);
            dispatch(loader(false))
          }
        })
        .catch((err) => {
          chartTableData.item.tableData.values = [];
          chartTableData.item.tableData.headers = [];
          chartTableData.item.data = [];
          chartTableData.item.categories = [];
          setChartTableData(chartTableData);
          setSkeleton(false);
          console.log(err);
          dispatch(loader(false))
        });
    }
  }, [filters]);

  return (
    <>
      <Grid container>
        <Grid md={12} className='m-t-20'>
          <Card>
            <CardContent>
              <Dashboard
                title={chartTableData?.item?.name}
                tooltip={chartTableData?.item?.name}
                id={`revenue-map-quadrant-intensity-graph-${chartTableData?.index}`}
                chartRef={chartRef}
                showSkeleton={skeleton}
              >
                {skeleton ? (
                  <>
                    <Grid>
                      <Skeleton variant='rectangular' height={300} />
                    </Grid>
                    <Grid className='m-t-20'>
                      <CustomTable
                        heading={[1, 2, 3, 4, 5, 6]}
                        value={[
                          [1, 2, 3, 4, 5, 6],
                          [1, 2, 3, 4, 5, 6],
                          [1, 2, 3, 4, 5, 6],
                        ]}
                        showSkeleton={skeleton}
                      />
                    </Grid>
                  </>
                ) : chartTableData?.item.data.length > 0 ? (
                  <Grid container>
                    <Grid item md={12}>
                      <HighchartContainer chartRef={chartRef} id={`revenue-map-quadrant-intensity-graph-${chartTableData?.index}`}>
                        <HighchartsReact highcharts={Highcharts} options={chartConfig(chartTableData?.item)} ref={chartRef} />
                      </HighchartContainer>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      style={{
                        marginTop: 20,
                        maxWidth: '89.5vw',
                      }}
                    >
                      <CustomTable
                        heading={[...chartTableData?.item.tableData.headers]}
                        value={[...chartTableData?.item.tableData.values]}
                        stickyHeader={true}
                        maxHeight={400}
                        isFormatNumber={false}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
                    <NoData sx={{ color: 'black' }}>{messages.reports}</NoData>
                  </Grid>
                )}
              </Dashboard>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Report;
