import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BlackInputTitle } from "../../styles/Common.Styled";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16
}));

const DropdownTitle = styled(BlackInputTitle)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500
}));

const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? theme.palette.common.white : "#F2F8FC",
  color: theme.palette.common.black
}));

export { Title, DropdownTitle, ExpandIcon };

