import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button, Divider, Stack } from "@mui/material";
import { UserConstraintsPopup } from "../UserConstraintsPopup/UserConstraintsPopup";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";

const GridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const GridItem = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledTypography = styled(Typography)({
  paddingTop: 0,
  paddingRight: 5,
  fontSize: "14px",
  fontWeight: 100,
});

const StyledBox = styled(Box)({
  height: 34,
});

const StyledTextField = styled(TextField)({
  height: 24,
});

const UserConstraints = ({showSkeleton}) => {
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(null);

  const handleUserConstraintsPopup = (val) => {
    setTarget(val);
    setOpen((isOpen) => !isOpen);
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: "20px",padding:'0px 16px' }}
      >
        <Typography
          style={{
            fontFamily: "Mulish",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          User Constraints
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Stack
              spacing={1}
              alignItems="center"
              direction="row"
              style={{ paddingLeft: 50 }}
            >
              <StyledTypography style={{ color: "black" }}>
                Operative Complexity:
              </StyledTypography>
              { showSkeleton ? (
          <MixSkeletons type={'constraints'}/>
              ) : (
                <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  fontFamily: "#08509A",
                  color: "#08509A",
                  borderColor: "#08509A",
                }}
                onClick={()=>handleUserConstraintsPopup("Operative Complexity")}
              >
                Upload/Download
              </Button>
               )}
              
            </Stack>
            <UserConstraintsPopup
              isOpen={open}
              setIsOpen={setOpen}
              target={target}
            ></UserConstraintsPopup>
          </Grid>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Stack
              spacing={1}
              alignItems="center"
              direction="row"
              style={{ paddingLeft: 50 }}
            >
              <StyledTypography style={{ color: "black" }}>
                Capacity Constraint:
              </StyledTypography>
              {showSkeleton ? (
                <MixSkeletons type={'constraints'}/>
              ) : (
                <Button
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  fontFamily: "#08509A",
                  color: "#08509A",
                  borderColor: "#08509A",
                }}
                onClick={()=>handleUserConstraintsPopup("Capacity Constraint")}
              >
                Upload/Download
              </Button>
              )}
              
            </Stack>
          </Grid>
          <Divider
            variant="middle"
            style={{ borderTop: "2px dashed #08509A", color: "#F1F5FE" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserConstraints;
