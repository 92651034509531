import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DashboardActions from '../../components/DashboardActions';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { pageList } from '../../mocks/common';
import TopFilters from './components/TopFilters/TopFilters';
import UnitVariationExpectedContainer from './components/UnitVariationExpected/UnitVariationExpectedContainer';
import ResultPerAccumulated from './components/ResultPerAccumulated';
import PriceElasticityRealVsExpected from './components/PriceElasticityRealVsExpected/PriceElasticityRealVsExpected';
import SimulationDetails from './components/SimulationDetails';
import VariableTrendInPriceElasticityModel from './components/VariableTrendInPriceElasticityModel';
import { removeMMPWFromLocal } from '../../util/helper';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { useDispatch, useSelector } from 'react-redux';
import ModelDetails from './components/ModelDetails';

const ElasticityPricingTrack: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [variationType, setVariationType] = useState('');
  const [simulationDetail, setSimulationDetail] = React.useState('');
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();
  const dispatch = useDispatch();

  const [resultPerAccumulatedLocalFilters, setResultPerAccumulatedLocalFilters] = useState({});

  const onApplyFilter = (data) => {
    if (data.type === 'FILTER_APPLIED') {
      setDisableDownloadButton(false);
      removeMMPWFromLocal('elasticity-pricing-track');
      setAppliedFilters(data.filters);
    }
  };

  useEffect(() => {
    return () => {
      removeMMPWFromLocal('elasticity-pricing-track');
    };
  }, []);

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setAppliedFilters(savedDashboardData?.filters?.global);
    }
  };

  const ElasticityPricingTrack = useSelector((state: any) => state.ElasticityPricingTrack);

  return (
    <DashboardActions
      title='Elasticity Pricing Track'
      data={{
        filters: {
          global: {
            ...appliedFilters,
            variationType,
            simulationDetail,
            ...resultPerAccumulatedLocalFilters,
          },
        },
        mmpwKey: 'elasticity-pricing-track',
      }}
      callback={getSaveDashBoardsData}
      dashboardKey={pageList[8].id}
      disableDownloadButton={disableDownloadButton || ElasticityPricingTrack.unitVariationExpected}
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[8].value} />
      </Grid>
      <TopFilters callback={onApplyFilter} defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}} />
      <UnitVariationExpectedContainer filters={appliedFilters} />
      <ResultPerAccumulated
        callback={setResultPerAccumulatedLocalFilters}
        defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}}
        filters={appliedFilters}
      />
      <PriceElasticityRealVsExpected
        filters={appliedFilters}
        defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}}
        callback={setVariationType}
      />
      <ModelDetails
        filters={appliedFilters}
        defaultFilters={Object.keys(defaultFilters).length > 0 ? defaultFilters : {}}
        callback={setSimulationDetail}
      />
      {/* <SimulationDetails
        filters={appliedFilters}
        defaultFilters={
          Object.keys(defaultFilters).length > 0 ? defaultFilters : {}
        }
        callback={setSimulationDetail}
      /> */}
      {/* <VariableTrendInPriceElasticityModel filters={appliedFilters} /> */}
      <DashboardSourceList
        dashboardName={pageList[8].id}
        appliedFilters={appliedFilters}
        category={appliedFilters.category}
        sellInSellOutData={appliedFilters.dataSource}
      />
    </DashboardActions>
  );
};

export default ElasticityPricingTrack;
