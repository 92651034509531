import { CardContent, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { TotalSumTitle, TotalSumValue } from "./UserInputNotes.Styled";

const UserInputNotes = ({ val }) => {
  return (
    <CardContent
      sx={{
        backgroundColor: "#F1F5FE",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container justifyContent="flex-end" alignItems="center">
        <TotalSumTitle>Sum of Assigned Weight = </TotalSumTitle>
        <TotalSumValue>{val["sum"]}</TotalSumValue>
      </Grid>
      <Typography style={{ alignSelf: "flex-end" }}>
        <span style={{ fontSize: 10, fontWeight: 700, color: "black" }}>
          *NOTE:{" "}
        </span>
        <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
          Total sum of Assigned weights and constraint cannot be more than 100%
        </span>
      </Typography>
      <Divider
        variant="middle"
        style={{ borderTop: "2px dashed #08509A", color: "#F1F5FE" }}
      />
    </CardContent>
  );
};

export default UserInputNotes;
