import _ from 'lodash';
import { formatNumber, separator } from '../../../util/helper';

export const chartConfig = (onTooltipHover) => {
  return {
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        title: {
          enabled: false,
          text: 'Values',
          style: {
            fontSize: 18,
            fontWeight: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
        reversed: true,
        gridLineWidth: 1,
        categories: [],
      },
    ],
    yAxis: [
      {
        lineWidth: 1,
        gridLineWidth: 1,
        title: {
          enabled: false,
          margin: 30,
          style: {
            fontSize: 18,
            fontWeight: 600,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
        labels: {
          enabled: false,
          formatter: function () {
            return separator(this.value, 2);
          },
        },
      },
    ],
    legend: {
      enabled: true,
      layout: 'horizontal',
      backgroundColor: 'transparent',
      align: 'center',
      verticalAlign: 'top',
      // symbolHeight: 20,
      // symbolWidth: 20,
      // symbolRadius: 0,
      itemStyle: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      waterfall: {
        pointPadding: 0.2,
        borderWidth: 0,
        stacking: 'normal',
        dataLabels: {
          style: {
            fontSize: 14,
          },
          formatter: function () {
            return formatNumber(this.y.toFixed(2));
          },
          enabled: true,
          inside: true,
          color: 'black',
          verticalAlign: 'middle',
        },
      },
      series: {
        marker: {
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null,
          symbol: 'circle',
        },
      },
    },
    series: [
      {
        showInLegend: false,
        type: 'waterfall',
        data: [],
        name: 'waterfall',
        tooltip: {
          pointFormatter: function () {
            return `AVG: ${formatNumber(this.y)}</br>${onTooltipHover(this.index)}`;
          },
        },
      },
      {
        showInLegend: true,
        type: 'errorbar',
        data: [],
        name: 'Toggle Whiskers',
        tooltip: {
          pointFormatter: function () {
            return onTooltipHover(this.index);
          },
        },
      },
    ],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };
};
