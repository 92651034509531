import { API } from "../../util/helper";

export const getMultilevelTableData = async (payload, endPoint) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${endPoint}`,
      payload
    );
  } catch (e) {
    return e;
  }
};

export const getCountries = async () => {
  try {
    return await API.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/countries`
    );
  } catch (e) {
    return e;
  }
};

export const getCountryAccess = async (payload) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}users/countries-accesswithCountry`,
      payload
    );
  } catch (e) {
    return e;
  }
};
