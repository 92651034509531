import { Card, Dialog, Grid, IconButton, Tooltip, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { PriceElasticityConfig } from './PriceElasticity.config';
import { quadrantInfo } from '../../../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import Indicator from '../../../../components/Loader/Loader';
import { PrimaryBtn } from '../../../../styles/Common.Styled';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { fetchApi } from '../../../../store/actions/ExecutionTool/portfolioGeoLevel.action';
import _ from "lodash";
import { toast } from '../../../../store/actions/toast.action';
import Papa from 'papaparse';
import CircularProgress from '@mui/material/CircularProgress';
import { setMixSimulatorPriceElasticityUploadData } from '../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action';
import { API_URL } from '../../../../store/actions/constants';


const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const PriceElasticity = () => {

    const [bubbleData, setBubbleData] = useState({});
    const [open, setOpen] = useState(false);
    const [downloadButtonLoader, setDownloadButtonLoader] = useState(false);
    const [uploadButtonLoader, setUploadButtonLoader] = useState(false);

    const { loaderPriceElasticty, priceElasticityData, priceElasticityDownloadData } = useSelector((state: any) => state.mixSimulationAllFilter);
    const { uid } = useSelector((state: any) => state.mixSimulationUserInput);
    const dispatch = useDispatch();
    const keysToDownload = [
        'id',
        'country',
        'businessUnit',
        'channel',
        'region',
        'segment',
        'brand',
        'subBrand',
        'skuGroupShort',
        // 'skuVolume',
        // 'marketShareMinusFairShare',
        'pe',
        // 'marketShareMinusFairSharePercentage',
        // 'skuVolumePercentage',
    ]
    const keysToDownloadMapper = {

        
    }

    function movePeToLast(obj) {
        let newObj = {};
        Object.keys(obj).forEach(key => {
            if (key !== 'pe') {
                newObj[key] = obj[key];
            }
        });
        newObj['pe'] = obj['pe'];
        return newObj;
    }
    const handleDownload = () => {
        dispatch(setMixSimulatorPriceElasticityUploadData('downloaded'))
        setDownloadButtonLoader(true)
        console.log("priceElasticityData", priceElasticityDownloadData)
        let updatedDataToDownload = priceElasticityDownloadData.map(obj => movePeToLast(obj));
        const header = Object.keys(updatedDataToDownload[0]).filter((key) => keysToDownload.includes(key));
        // const ModifiedHeader = header.map(item=>{return keysToDownloadMapper[item]})
        const csvContent = header.join(",") + "\n" +
        updatedDataToDownload.map(obj => header.map(key => obj[key]).join(",")).join("\n");

        // console.log(csvContent)

        const file = new Blob([csvContent], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = "price_elasticity.csv";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event to start the download
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);

        // Release the object URL resources
        URL.revokeObjectURL(link.href);
        setDownloadButtonLoader(false)

    }
    const handleUpload = (event) => {
        setUploadButtonLoader(true)
        const file = event.target.files[0];
        const reader = new FileReader();
        const requiredHeaders = Object.keys(priceElasticityDownloadData[0]).filter((key) => keysToDownload.includes(key));

        reader.onload = (e) => {
            const content = e.target?.result as string;;
            const lines = content.split('\n');

            // Extract headers from the first line of the CSV
            const headers = lines[0].trim().split(',');

            // Check if all required headers are present
            const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));

            if (missingHeaders.length > 0) {
                alert('The following fields are missing in the CSV: ' + missingHeaders.join(', '));
                setUploadButtonLoader(false)

            } else {
                let uploadedFile = convertCSVtoJson(content)
                const updatedfile = isValidFileUploaded(priceElasticityDownloadData, uploadedFile)
                if (updatedfile.isValid) {
                    const csvFile = Papa.unparse(updatedfile.data)
                    const formData = new FormData();
                    const fileToUpload = new Blob([csvFile], { type: "text/csv" });
                    formData.append("CsvFile", fileToUpload);
                    formData.append("Uid", uid);
                    dispatch(fetchApi(formData, API_URL.MIXSIMULATION.UPDATE_PRICE_ELASTICITY_GRAPH_DATA)).then((res)=>{

                        if(res.data =="Price elasticity data updated successfully."){
                            dispatch(toast('Validation successful.', true, 2000, 'success'))
                            dispatch(setMixSimulatorPriceElasticityUploadData('updated'))
                        };
                    })
                    setUploadButtonLoader(false)

                } else {
                    dispatch(toast(`Invalid File only pe column is editable`, true, 2000, 'error'));
                    setUploadButtonLoader(false)

                }

            }
        };

        reader.readAsText(file);
    };

    const convertCSVtoJson = (file) => {
        const lines = file.trim().split('\n').map(line => line.trim());
        const headers = lines[0].split(',');
        const jsonData: any = [];
        lines.slice(1).forEach((line, index) => {
            const data = line.split(',');
            const entry = {};

            headers.forEach((header, columnIndex) => {
                entry[header] = data[columnIndex];
            });

            jsonData.push(entry);
        });
        return jsonData;
    }


    const isValidFileUploaded = (downloadedFile, uploadedFile) => {
        console.log(downloadedFile)
        console.log(uploadedFile)
        const target = 'pe';
        const validFile = _.map(downloadedFile, downloadObject => {
            const modifiedObj = _.find(uploadedFile, { 'id': downloadObject.id.toString() });
            console.log(modifiedObj)
            if (modifiedObj) {
                let targetValue = modifiedObj[target];
                if (isNaN(Math.abs(targetValue))) {
                    return {}
                }
                else {
                    let keysToCheck = _.omit(modifiedObj, [target])
                    console.log(keysToCheck)
                    let valuesMatch = _.isEqualWith(_.pick(downloadObject, _.keys(keysToCheck)), keysToCheck, (orignalValue, modifiedValue) => {
                        if (_.isNumber(orignalValue) && _.isString(modifiedValue)) {
                            return orignalValue === parseFloat(modifiedValue);
                        }

                    });

                    console.log(_.pick(downloadObject, _.keys(keysToCheck)))

                    console.log(valuesMatch)
                    if (valuesMatch) {
                        return {
                            ...downloadObject,
                            [target]: modifiedObj[target] !== undefined ? modifiedObj[target] : downloadObject[target],
                        };
                    } else {
                        return {}
                    }
                }
            } else {
                return {}
            }
        })
        console.log("validFile", validFile)

        return { isValid: !_.some(validFile, _.isEmpty), data: validFile }
    }

    useEffect(() => {
        if (priceElasticityData.length) {

            const config = PriceElasticityConfig(priceElasticityData);
            setBubbleData(config);
            console.log("PRICE ELA SDATA", priceElasticityData, config)
        }
        else {
            const config = PriceElasticityConfig([]);
            setBubbleData(config);

        }
    }, [priceElasticityData])

    const DialogBox = () => {
        return <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            maxWidth={'lg'}
            onClose={() => setOpen(false)}
        >
            <Grid container justifyContent='flex-end' padding={'5px'}>
                <Tooltip title='close'>
                    <IconButton onClick={() => setOpen(false)} aria-label="close" size='small'>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid padding={5}>
                <img src={quadrantInfo} alt="quadrant_info" />
            </Grid>
        </Dialog>
    }

    return (
        <Grid spacing={2} marginTop={3}>
            <Grid direction={'row'} justifyContent={'flex-start'} alignItems={'center'} container xs={12} md={12} >
                <Grid item>
                    <Typography color={"white"} variant="h6">
                        Premiumization & TPR Opportunity Assessment
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Quadrant Info">
                        <IconButton onClick={() => setOpen(open => !open)} aria-label="info" size="large" sx={{ color: '#ffff' }}>
                            <InfoIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item marginTop={2}>
                <Card
                    sx={{
                        borderRadius: "5px",
                        position: "relative",
                        backgroundColor: "#F1F5FE",
                    }}>
                    <Indicator position="absolute" show={loaderPriceElasticty} />
                    <Grid padding={5} sx={{ visibility: priceElasticityData.length ? 'visible' : 'hidden' }}>
                        <Grid item marginLeft="20px" alignItems="center" >
                            <i><b>*NOTE</b>: To zoom in, use cursor to highlight the section.</i>
                        </Grid>
                        <Grid item display={'flex'} alignItems='flex-end' justifyContent='flex-end' className="p-r-25" >
                            {
                                <PrimaryBtn style={{ width: '150px' }} disabled={false} className="m-r-20" onClick={handleDownload} startIcon={downloadButtonLoader ? <CircularProgress style={{
                                    width: '20px', height: '20px',
                                }} color="inherit" /> : <FileDownloadOutlinedIcon />}>
                                    Download CSV
                                </PrimaryBtn>
                            }
                            {
                                <PrimaryBtn style={{ width: '150px' }} component="label" disabled={priceElasticityDownloadData.length == 0} color="primary"
                                    startIcon={uploadButtonLoader ? <CircularProgress style={{
                                        width: '20px', height: '20px',
                                    }} color="inherit" /> : <FileUploadOutlinedIcon />}
                                >

                                    Upload File
                                    <VisuallyHiddenInput
                                        onChange={handleUpload}
                                        type="file"
                                        value=""
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </PrimaryBtn>
                            }

                        </Grid>
                        <Grid padding={3}>
                            <HighchartsReact highcharts={Highcharts} options={bubbleData} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            {open ? DialogBox() : null}
        </Grid>
    )
}

export default PriceElasticity