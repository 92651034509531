import React, { useEffect } from 'react';
import { Switch, Route, useHistory, BrowserRouter, Redirect } from 'react-router-dom';

import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from '../util/helper';

import {
  USER_CREATION,
  HOME,
  USER_EDIT,
  PRICE_ARCHITECTURE,
  INTEGRATED_STRATEGY,
  ALERT_AND_NOTIFICATION,
  NOTIFICATION_DETAILS,
  USER_SETTINGS,
  PROFIT_PARABOLA_DASHBOARD,
  API_PREDICTIVE_DASHBOARD,
  SELL_OUT_DASHBOARD,
  SUPER_MARKET,
  HISTORIC_PRICE,
  MMPW_TEST,
  MACRO_ECON,
  USER_LIST,
  COMING_SOON,
  USER_CREATE,
  REVENUE_MAP,
  ELASTICITY_PRICING_TRACK,
  PRICING_POTENTIAL,
  BRAND_LADDER,
  PACK_ECONOMICS,
  PRICE_SETTING_TOOL,
  PRICE_RANGE_PIANO,
  PRICE_LADDER,
  GEO_XREF,
  PROD_XREF,
  GRAMMAGE_PER_PACK_SIZE,
  MIX_MANAGEMENT,
  TRADE_MANAGEMENT,
  DATA_SOURCE_REFRESH,
  LOGIN_CALLBACK,
  USAGE_ACTIVITY_REPORT,
  USAGE_COUNT_REPORT,
  ASSORTMENT_TOOL,
  EXECUTION_TOOL,
  SCENARIO_LIBRARY,
  MIX_SIMULATION_TOOL,
  LOGIN_DP,
  MIX_SIMULATION_SCENARIO_LIBRARY
} from './CONSTANTS';

import UserCreation from '../pages/User/UserCreation';
import Home from '../pages/Home';
import Settings from '../pages/Settings';
import PriceArchitecture from '../pages/PriceArchitecture';
import MixManagement from '../pages/MixManagement';
import IntegratedStrategy from '../pages/IntegratedStrategy';
import TradeManagement from '../pages/TradeManagement';
import AlertAndNotification from '../pages/AlertAndNotification';
import NotificationDetail from '../pages/AlertAndNotification/components/NotificationDetail';
import UserSettings from '../pages/User/UserSettings';
import ProfitParabolaDashboard from '../pages/ProfitParabolaDashboard';
import APIPredictiveDashboard from '../pages/APIPredictiveDashboard';
import SupermarketDashboard from '../pages/SupermarketDashboard';
import HistoricPriceAndCrossPriceElasticity from '../pages/HistoricPriceAndCrossPriceElasticity';
import MMPWTest from '../pages/MMPWTest';
import MacroEconDashboard from '../pages/MacroEconDashboard';
import UserList from '../pages/Admin/UserList';
import CreateUser from '../pages/Admin/UserCreation';
import ComingSoon from '../pages/ComingSoon/ComingSoon';
import RevenueMap from '../pages/RevenueMap';
import SellOutDashboard from '../pages/SellOutDashboard';
import ElasticityPricingTrack from '../pages/ElasticityPricingTrack';
import PricingPotential from '../pages/PricingPotential';
import BrandLadder from '../pages/BrandLadder/BrandLadder';
import PredictivePackEconomics from '../pages/PredictivePackEconomics';
import PriceSettingTool from '../pages/PriceSettingTool';
import PriceRangePiano from '../pages/PriceRangePiano';
import PriceLadder from '../pages/PriceLadder';
import GeoXREF from '../pages/GeoXREF';
import ProductXREF from '../pages/ProductXREF';
import DataSourceRefresh from '../pages/DataSourceRefresh';
import PrivateRoute from './PrivateRoute';
import Indicator from '../components/Loader';
import AuthError from '../components/AuthError/AuthError';
import UsageReport2 from '../pages/UserReport2/UsageReport2';
import Reporting from '../pages/Reporting/Reporting';
import AssortmentTool from '../pages/AssortmentTool';
import ExecutionTool from '../pages/ExecutionTool/components/ExecutionTool';
import ScenarioLibrary from '../pages/AssortmentTool/components/ScenarioLibrary/ScenarioLibrary';
import MixSimulationTool from '../pages/MixSimulation/MixSimulation';
import Login from '../pages/Login/Login';
import MixScenarioLibrary from '../pages/MixSimulation/component/MixScenarioLibrary/MixScenarioLibrary';

const Routes = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const onAuthResume = async () => {
    history.push('/');
  };

  const routes = () => {
    return (
      <>
        <PrivateRoute exact path={USER_CREATION} component={UserCreation} />
        <PrivateRoute uniqueKey='home' exact path='/' component={ScenarioLibrary} />
        <PrivateRoute uniqueKey='mix_scenarios' exact path={MIX_SIMULATION_SCENARIO_LIBRARY} component={MixScenarioLibrary} />
        <PrivateRoute uniqueKey='home' exact path={HOME} component={Home} />
        <PrivateRoute uniqueKey='user_edit' exact path={USER_EDIT} component={CreateUser} />
        <PrivateRoute uniqueKey='user_create' exact path={USER_CREATE} component={CreateUser} />
        <PrivateRoute uniqueKey='price_architecture' exact path={PRICE_ARCHITECTURE} component={PriceArchitecture} />
        <PrivateRoute uniqueKey='integrated_strategy' exact path={INTEGRATED_STRATEGY} component={IntegratedStrategy} />
        <PrivateRoute uniqueKey='trade_management' exact path={TRADE_MANAGEMENT} component={TradeManagement} />
        <PrivateRoute uniqueKey='mix_management' exact path={MIX_MANAGEMENT} component={MixManagement} />
        <PrivateRoute uniqueKey='hpcpe' exact path={HISTORIC_PRICE} component={HistoricPriceAndCrossPriceElasticity} />
        <PrivateRoute uniqueKey='system_alerts_notifications' exact path={ALERT_AND_NOTIFICATION} component={AlertAndNotification} />
        <PrivateRoute uniqueKey='notification_details' path={NOTIFICATION_DETAILS} component={NotificationDetail} />
        <PrivateRoute uniqueKey='profit_parabola' path={PROFIT_PARABOLA_DASHBOARD} component={ProfitParabolaDashboard} />
        <PrivateRoute uniqueKey='pricing_potential' path={PRICING_POTENTIAL} component={PricingPotential} />
        <PrivateRoute uniqueKey='price_setting_tool' path={PRICE_SETTING_TOOL} component={PriceSettingTool} />
        <PrivateRoute uniqueKey='api_vs_volume' path={API_PREDICTIVE_DASHBOARD} component={APIPredictiveDashboard} />
        <PrivateRoute uniqueKey='sellout_price_tracking' path={SELL_OUT_DASHBOARD} component={SellOutDashboard} />
        <PrivateRoute uniqueKey='ept' path={ELASTICITY_PRICING_TRACK} component={ElasticityPricingTrack} />
        <PrivateRoute uniqueKey='revenue_map' path={REVENUE_MAP} component={RevenueMap} />
        <PrivateRoute uniqueKey='macro_econ' path={MACRO_ECON} component={MacroEconDashboard} />
        <PrivateRoute uniqueKey='user_setting' path={USER_SETTINGS} component={UserSettings} />
        <PrivateRoute uniqueKey='sales_by_price_point' path={SUPER_MARKET} component={SupermarketDashboard} />
        <PrivateRoute uniqueKey='mmpw_test' path={MMPW_TEST} component={MMPWTest} />
        <PrivateRoute uniqueKey='user_list' path={USER_LIST} component={UserList} />
        <PrivateRoute uniqueKey='coming_soon' path={COMING_SOON} component={ComingSoon} />
        <PrivateRoute uniqueKey='brand_ladder' path={BRAND_LADDER} component={BrandLadder} />
        <PrivateRoute uniqueKey='pack_economics' path={PACK_ECONOMICS} component={PredictivePackEconomics} />
        <PrivateRoute uniqueKey='price_range_piano' path={PRICE_RANGE_PIANO} component={PriceRangePiano} />
        <PrivateRoute uniqueKey='price_ladder' path={PRICE_LADDER} component={PriceLadder} />
        <PrivateRoute uniqueKey='geo_xref' path={GEO_XREF} component={GeoXREF} />
        <PrivateRoute uniqueKey='prod_xref' path={PROD_XREF} component={ProductXREF} />
        <PrivateRoute uniqueKey='grammage_per_pack_size' path={GRAMMAGE_PER_PACK_SIZE} component={ComingSoon} />
        <PrivateRoute uniqueKey='data_source_refresh' path={DATA_SOURCE_REFRESH} component={DataSourceRefresh} />
        <PrivateRoute uniqueKey='usage_activity_report' path={USAGE_ACTIVITY_REPORT} component={UsageReport2} />
        <PrivateRoute uniqueKey='usage_count_report' path={USAGE_COUNT_REPORT} component={Reporting} />
        <PrivateRoute uniqueKey='assortment_tool' path={ASSORTMENT_TOOL} component={AssortmentTool} />
        <PrivateRoute uniqueKey='execution_tool' path={EXECUTION_TOOL} component={ExecutionTool} />
        <PrivateRoute uniqueKey='scenario_library' path={SCENARIO_LIBRARY} component={ScenarioLibrary} />
        <PrivateRoute uniqueKey='mix_simultion_tool' path={MIX_SIMULATION_TOOL} component={MixSimulationTool} />
        {/* <PrivateRoute uniqueKey='login_dp' path={LOGIN_DP} component={Login} /> */}
        {/* <Redirect from='/' to={ASSORTMENT_TOOL}/> */}
      </>
    );
  };

  return (
    <>
      {process.env.REACT_APP_SSO_ENABLE === 'No' ? (
          <Switch>
            <Route
              path={LOGIN_CALLBACK}
              render={(props) => (
                <LoginCallback {...props} errorComponent={AuthError} loadingElement={<Indicator show={true} />} onAuthResume={onAuthResume} />
              )}
            />
            {routes()}
          </Switch>
      ) : (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>{routes()} </Switch>
        </BrowserRouter>
      )}
    </>
  );
};

export default Routes;
