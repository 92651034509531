import React, { useState, useRef } from 'react';
import {
  Avatar,
  Typography,
  Box,
  Divider,
  Grid,
  Modal,
  Switch,
  Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../../styles/Common.Styled';
import { DeleteBtn, PrimaryBtn } from './UserSetting.Styled';
import UserInfo from './UserInfo';
import { changeTheme } from '../../../store/actions';
import CropEasy from './Crop/CropEasy';
import { deleteUserProfile, updateUser, userProfile } from '../service';
import { stringAvatar } from '../../../util/helper';
import { IUserDetails } from '../../../types/common';
import { toast } from '../../../store/actions/toast.action';
import { loader } from '../../../store/actions/common.action';
import { checkValidation } from '../../../util/validation';
import { fetchUser } from '../../../store/actions/user.action';
import { getCountries } from '../../Admin/service';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { pageNames } from '../../../mocks/common';

const initialState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: null,
  phoneNumberCountryCode: null,
  address: null,
  role: null,
  defaultCountry: '',
  defaultCategory: '',
  permissionCountry: '',
  permissionCategory: '',
  imageUrl: null,
  isDarkMode: null,
  country: null,
};

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  sectionTitle: {
    marginBottom: '30px !important',
    fontWeight: 600,
    fontSize: '18px !important',
  },
  toggleTheme: {
    fontSize: '1.2rem',
  },
}));

const UserSettings: React.FC = () => {
  const classes = useStyles();
  const inputFile = useRef(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [olduserDetail, setOldUserDetails] = useState<IUserDetails>({
    ...initialState,
  });
  const [userDetail, setUserDetails] = useState<IUserDetails>({
    ...initialState,
  });

  const [profilePic, setProfilePic] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );

  const userDetailRedux = useSelector((state: any) => state.User);

  const toggleTheme = () => {
    dispatch(changeTheme(!theme.darkmode));
    userDetail['isDarkMode'] = !userDetail['isDarkMode'];
    setUserDetails({ ...userDetail });
    saveUserHandler();
  };

  const handleClose = () => {
    setOpenCrop(false);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFile(file);
      setOpenCrop(true);
      setImgUrl(URL.createObjectURL(file));
    }
  };

  const updateUserHandler = (data) => {
    if (data?.key) {
      userDetail[data.key] = data.value;
      setUserDetails({
        ...userDetail,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
      });
    }
  };

  const apiCallback = (croppedFile) => {
    const formData = new FormData();
    formData.append('file', croppedFile);
    formData.append('FileName', file.name);
    const payload = {
      formData: formData,
      id: userDetail.id && userDetail.id,
    };
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    userProfile(payload, config).then(()=>{
      dispatch(fetchUser());
    });
  };

  const saveUserHandler = () => {
    setOldUserDetails({ ...userDetail });
    dispatch(loader(true));
    updateUser({
      ...userDetail,
      country: userDetail.country,
      firstName: userDetail.firstName.trim(),
      lastName: userDetail.lastName.trim(),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          dispatch(
            toast('User data updated successfully!', true, 2000, 'success')
          );
          dispatch(fetchUser());
          dispatch(loader(false));
        }
      })
      .catch((err) => {
        dispatch(toast('Something went wrong', true, 2000, 'error'));
        dispatch(loader(false));
      });
  };
  const cancelUserHandler = () => {
    setUserDetails({ ...olduserDetail });
  };

  const onBrowseClick = () => {
    inputFile.current.value = null;
    inputFile.current.click();
  };

  const onDelete = () => {
    if (profilePic || userDetail.imageUrl) {
      const payload = {
        id: userDetail.id && userDetail.id,
      };
      userDetail['imageUrl'] = null;
      userDetail['profile'] = null;
      setUserDetails({ ...userDetail });
      inputFile.current.value = null;
      setProfilePic(null);
      deleteUserProfile(payload).then(()=>{
        dispatch(fetchUser());
      });
    }
  };

  React.useEffect(() => {
    if (userDetailRedux && userDetailRedux.data) {
      setOldUserDetails({ ...userDetailRedux.data });
      setUserDetails(userDetailRedux.data);
      dispatch(changeTheme(userDetailRedux.data.isDarkMode));
      dispatch(loader(false));
    }
  }, [userDetailRedux]);

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        const countries = res.data.map((item) => ({
          label: item,
          value: item,
        }));
        setData(countries);
      })
      .catch(() => {
        dispatch(toast('Something went wrong', true, 2000, 'error'));
      });
    if (userDetailRedux.data) {
      dispatch(loader(false));
    } else {
      dispatch(loader(true));
    }
  }, []);

  return (
    <PageContainer page={pageNames.userSettings}>
      <Box>
        <Title className="m-b-20"> User Settings </Title>
        <Box className={classes.flexCenter}>
          <Avatar
            alt={olduserDetail.firstName.trim() + olduserDetail.lastName.trim()}
            src={profilePic || userDetail.profile}
            {...stringAvatar(
              `${olduserDetail.firstName.trim()} ${olduserDetail.lastName.trim()}`
            )}
            sx={{
              width: 80,
              height: 80,
              background: theme.darkmode ? 'white' : '',
            }}
          />
          <Grid container display="flex" alignItems="center">
            <Grid item className="m-r-10">
              <PrimaryBtn variant="contained" onClick={onBrowseClick}>
                Upload New Picture
              </PrimaryBtn>
              <input
                onChange={onImageChange}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                hidden
                ref={inputFile}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DeleteBtn onClick={onDelete}>Delete</DeleteBtn>
            </Grid>
          </Grid>
        </Box>
        <Divider className="m-b-40 m-t-10" />
        <Typography className={classes.sectionTitle}>
          Account Information
        </Typography>
        <UserInfo
          updateHandler={updateUserHandler}
          saveUserHandler={saveUserHandler}
          cancelUserHandler={cancelUserHandler}
          data={{
            label: 'Name',
            displayValue: `${userDetail.firstName} ${userDetail.lastName}`,
            data: [
              {
                type: 'text',
                key: 'firstName',
                label: 'First Name',
                value: userDetail.firstName,
              },
              {
                type: 'text',
                key: 'lastName',
                label: 'Last Name',
                value: userDetail.lastName,
              },
            ],
          }}
        />
        <UserInfo
          updateHandler={updateUserHandler}
          saveUserHandler={saveUserHandler}
          cancelUserHandler={cancelUserHandler}
          data={{
            label: 'Email Address',
            displayValue: userDetail.emailAddress,
            data: [
              {
                type: 'text',
                key: 'emailAddress',
                label: 'Email Address',
                value: userDetail.emailAddress,
              },
            ],
          }}
        />
        <Typography className={classes.sectionTitle}>My Information</Typography>
        <UserInfo
          updateHandler={updateUserHandler}
          saveUserHandler={saveUserHandler}
          cancelUserHandler={cancelUserHandler}
          data={{
            label: 'Phone Number',
            displayValue: `${
              userDetail.phoneNumberCountryCode + ' ' + userDetail.phoneNumber
            }`,
            data: [
              {
                type: 'phone',
                key: ['phoneNumberCountryCode', 'phoneNumber'],
                label: 'Phone Number',
                value: userDetail.phoneNumberCountryCode,
                value2: userDetail.phoneNumber,
              },
            ],
          }}
        />
        <UserInfo
          updateHandler={updateUserHandler}
          saveUserHandler={saveUserHandler}
          cancelUserHandler={cancelUserHandler}
          data={{
            label: 'Address',
            displayValue: userDetail.address,
            data: [
              {
                type: 'text',
                label: 'Address',
                key: 'address',
                value: userDetail.address,
              },
            ],
          }}
        />
        <UserInfo
          updateHandler={updateUserHandler}
          saveUserHandler={saveUserHandler}
          cancelUserHandler={cancelUserHandler}
          data={{
            label: 'Country',
            displayValue: userDetail.country,
            data: [
              {
                type: 'auto-complete',
                label: 'Country',
                key: 'country',
                availableValues: data,
                value: userDetail.country,
              },
            ],
          }}
        />
        <Typography className={classes.sectionTitle}>
          System Settings
        </Typography>
        <UserInfo
          data={{
            label: "User's Role",
            displayValue: userDetail.role,

            data: [
              {
                type: 'text',
                label: "User's Role",
                key: 'role',
                value: userDetail.role,
              },
            ],
          }}
        />
        <UserInfo
          data={{
            label: 'Country',
            displayValue: userDetail.permissionCountry,
            data: [
              {
                type: 'text',
                label: 'Country',
                key: 'permissionCountry',
                value: userDetail.permissionCountry,
              },
            ],
          }}
        />
        <Box marginTop="50px" className={classes.flexCenter}>
          <Typography className={classes.toggleTheme} fontWeight="bold">
            Dark Mode
          </Typography>
          <Box sx={{ minWidth: 120 }}>
            {/* <FormControl fullWidth>
              <Select
                labelId="theme-select-label"
                id="theme-select"
                value={theme.darkmode ? "Dark" : "Light"}
                label="theme"
                onChange={toggleTheme}
              >
                <MenuItem value={"Dark"}>DARK</MenuItem>
                <MenuItem value={"Light"}>LIGHT</MenuItem>
              </Select>
            </FormControl> */}
            <Switch
              color="default"
              onChange={toggleTheme}
              value={theme.darkmode ? 'Dark' : 'Light'}
              defaultValue={userDetail.isDarkMode ? 'Dark' : 'Light'}
              checked={theme.darkmode ? true : false}
            />
          </Box>
        </Box>
        <Typography
          marginTop="20px"
          maxWidth="1211px"
          marginBottom="100px"
          color={theme.darkmode ? ' #E3EFF8' : '#858C94'}
        >
          Dark themes reduce the luminance emitted by device screens, while
          still meeting minimum color contrast ratios. They help improve visual
          ergonomics by reducing eye strain, adjusting brightness to current
          lighting conditions, and facilitating screen use in dark environments
          – all while conserving battery power.
        </Typography>
      </Box>

      <Modal open={openCrop} onClose={handleClose}>
        <CropEasy
          {...{
            imgUrl,
            setProfilePic,
            setOpenCrop,
            setImgUrl,
            setFile,
            apiCallback,
          }}
        />
      </Modal>
    </PageContainer>
  );
};

export default UserSettings;
