import React, { useEffect } from "react";
import OutputTable from "../OutputTable/OutputTable";
import { tableStyles } from "./GeoLevel.Styled";
import { geoLevelData, levelsForGeoLevel } from "./GeoLevelTableConfig";
import { Card, Grid, Skeleton, Typography } from "@mui/material";
import SelectInput from "../CommonComponents/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchMixPortfolioData } from "../../../../store/actions/MixSimulationTool/mixPortfolio.action";
import Indicator from "../../../../components/Loader/Loader";
import {
  filterableColumns,
  sortableColumns,
  tableColumns,
} from "./GeoLevelTableConfig";

const GeoLevel = () => {
  const classes = tableStyles;
  const dispatch = useDispatch();
  const { geoLevelTableLoader, geoLevelTableData, geoLevel } = useSelector(
    (state: any) => state.MixGeoLevelReducer
  );
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );

  const filterColumns = (data, keys) =>
    keys.length === 1
      ? data.filter(
          (column) =>
            !(keys.includes("region") && column.field === "storeSegment") &&
            !(keys.includes("storeSegment") && column.field === "geoLevel")
        )
      : keys.length === 0
      ? data.filter(
          (column) =>
            column.field !== "storeSegment" && column.field !== "geoLevel"
        )
      : data;

  const updatedColumns = filterColumns(tableColumns, geoLevel);

  return (
    <Grid style={{ marginTop: "10px" }} container spacing={2}>
      <Grid item xs={12} md={6} style={{}}>
        <Typography color={"white"} variant="h6">
          Geo Level
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ whiteSpace: "nowrap", display: "flex", justifyContent: "end" }}
      >
        <Typography style={{ marginTop: "7px", marginRight: "8px", color:"white" }}>
          Level:
        </Typography>
        <SelectInput isMultiple={true} levels={levelsForGeoLevel} />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card
          sx={{
            borderRadius: "5px",
            position: "relative",
            backgroundColor: "#F1F5FE",
          }}
        >
          <Indicator position="absolute" show={geoLevelTableLoader} />

          {geoLevelTableData === null ||
          (geoLevelTableData && geoLevelTableData.length === 0) ? (
            <Grid
              style={{ marginTop: "15px" }}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Skeleton variant="rectangular" width="100%" height={145} />
              <Typography
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Rows
              </Typography>
            </Grid>
          ) : (
            <Grid style={{padding:"1%"}}>
              <OutputTable
                rowData={geoLevelTableData}
                tableColumns={updatedColumns}
                filterableColumns={filterableColumns}
                sortableColumns={sortableColumns}
              />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeoLevel;
