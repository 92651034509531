import { API } from "../../../util/helper";
import {
  FETCH_EXECUTION_SALES_DATA_FAILURE,
  FETCH_EXECUTION_SALES_DATA_SUCCESS,
  FETCH_EXECUTION_OPPORTUNITIES_FAILURE,
  FETCH_EXECUTION_OPPORTUNITIES_SUCCESS,
  FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE,
  FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST,
  FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS,
  FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST,
  FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST,
  LOADER_STATE_FOR_RUN_SIMULATION,
  BACKEND_URL,
  USER_LOGIN_STATE,
  RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE,
} from "../constants";

export const resetExecutionOverviewState = () => ({
  type: RESET_STATE_FOR_EXECUTION_OVERVIEW_SCORE,
});
export const getExecutionOverviewScoreDataRequest = () => ({
  type: FETCH_EXECUTION_OVERVIEW_SCORE_REQUEST,
});

export const loaderStateForRunSimulation = (payload) => ({
  type: LOADER_STATE_FOR_RUN_SIMULATION,
  payload,
});

export const getExecutionOverviewOpportunitiesDataRequest = () => ({
  type: FETCH_EXECUTION_OVERVIEW_OPPORTUNITIES_REQUEST,
});

export const getExecutionOverviewSalesDataRequest = () => ({
  type: FETCH_EXECUTION_OVERVIEW_SALES_DATA_REQUEST,
});

export const getExecutionOverviewDataSuccess = (payload) => ({
  type: FETCH_EXECUTION_OVERVIEW_SCORE_SUCCESS,
  payload,
});

export const getExecutionOverviewDataFailure = (payload) => ({
  type: FETCH_EXECUTION_OVERVIEW_SCORE_FAILURE,
  payload,
});

export const getExecutionOpportunitiesDataSuccess = (payload) => ({
  type: FETCH_EXECUTION_OPPORTUNITIES_SUCCESS,
  payload,
});

export const getExecutionOpportunitiesDataFailure = (payload) => ({
  type: FETCH_EXECUTION_OPPORTUNITIES_FAILURE,
  payload,
});

export const getExecutionSalesCardDataSuccess = (payload) => ({
  type: FETCH_EXECUTION_SALES_DATA_SUCCESS,
  payload,
});
export const getExecutionSalesCardDataFailure = (payload) => ({
  type: FETCH_EXECUTION_SALES_DATA_FAILURE,
  payload,
});

export const userLogin = (payload) => {
  return {
    type: USER_LOGIN_STATE,
    payload,
  };
};

export const fetchExecutionOverviewData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(getExecutionOverviewScoreDataRequest());
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/executionTracker/${apiUrl}`,
        data
      );
      dispatch(getExecutionOverviewDataSuccess(response));
    } catch (error) {
      dispatch(getExecutionOverviewDataFailure(error));
    }
  };
};

export const fetchExecutionOpportunitiesData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(getExecutionOverviewOpportunitiesDataRequest());
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/executionTracker/${apiUrl}`,
        data
      );
      dispatch(getExecutionOpportunitiesDataSuccess(response));
      //   console.log("fetchExecutionOpportunitiesData", response)
    } catch (error) {
      dispatch(getExecutionOpportunitiesDataFailure(error));
    }
  };
};

export const fetchExecutionSalesData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(getExecutionOverviewSalesDataRequest());
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/executionTracker/${apiUrl}`,
        data
      );
      dispatch(getExecutionSalesCardDataSuccess(response));
      //  console.log("fetchExecutionFiltersData", data)
    } catch (error) {
      dispatch(getExecutionSalesCardDataFailure(error));
    }
  };
};
