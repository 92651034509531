import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Box } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import StatCard from "../../../../components/StatCard";
import { IStats } from "../../../../types/common";
import Card from "@mui/material/Card";
import data from "../../../../mocks/20SecMarketCheck";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "../../../../mocks/common";
import { BlackInputTitle } from "../../../../styles/Common.Styled";
import { southAmericaMill } from "@react-jvectormap/southamerica";
import { northAmericaMill } from "@react-jvectormap/northamerica";
import Map from "../Map";
import { fecth20SecMarketCheckData } from "../../../../store/actions/home/20secMarketCheck.action";
import _ from "lodash";
import { getCategoriesByCountry } from "../../../../util/services";
import { convertToInternationalCurrencySingleChar } from "../../../../util/helper";

const useStyles = makeStyles(() => ({
  CountryImage: { width: "auto", height: "130px" },
  countryContainer: {
    padding: "10px",
    height: "100%",
  },
  country: {
    display: "flex",
    justifyContent: "center",
  },
}));

const TwentySecMarketCheck: React.FC<{ onSortChange }> = ({ onSortChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1240px)");
  const [skeleton, setSkeleton] = React.useState<boolean>(true);
  const [southAmericaSelecteeCountries, setSouthAmericaSelectedCountries] =
    React.useState([]);
  const [northAmericaSelecteeCountries, setNorthAmericaSelectedCountries] =
    React.useState([countries[0]]);
  const [southAmericaMapData, setSouthAmericaMapData] = React.useState([]);
  const [northAmericaMapData, setNorthAmericaMapData] = React.useState(["MX"]);

  const handleMapClick = ({ region, selectedRegions }) => {
    const filterCountries = _.isArray(selectedRegions)
      ? countries.filter(
        (x) => selectedRegions.findIndex((y) => y === x.initial) !== -1
      )
      : [];
    if (region === "southAmerica") {
      setSouthAmericaMapData(selectedRegions);
      setSouthAmericaSelectedCountries(filterCountries);
    } else if (region === "northAmerica") {
      setNorthAmericaMapData(selectedRegions);
      setNorthAmericaSelectedCountries(filterCountries);
    }
  };

  const [kpiData1, setKpiData1] = React.useState(data.KPIData1);
  const [kpiData2, setKpiData2] = React.useState(data.KPIData2);
  const [autoCall, setAutoCall] = React.useState(true);

  const [categories, setCategories] = React.useState<any>([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const onChangeCountry = (e) => {
    const result = countries.filter(
      (c) => e.findIndex((x) => x === c.value) !== -1
    );
    const southAmerica = result.filter(
      (x) => x.region === "south_america_mill"
    );
    setSouthAmericaSelectedCountries(southAmerica);
    setSouthAmericaMapData(southAmerica.map((x) => x.initial));

    const northAmerica = result.filter(
      (x) => x.region === "north_america_mill"
    );
    setNorthAmericaSelectedCountries(northAmerica);
    setNorthAmericaMapData(northAmerica.map((x) => x.initial));
    getCategories(e);
  };

  React.useEffect(() => {
    getCategories([northAmericaSelecteeCountries[0].value]);
  }, []);

  const getCategories = (countries) => {
    const payload = { countries };
    getCategoriesByCountry('filter/categories', payload)
      .then((response) => {
        setCategories(response.data);
        const defaultCategory = response.data.filter((x) => x.defaultVal).map(r=> r.value);
        setSelectedCategories(defaultCategory.length ? defaultCategory : ['empty']);
      })
      .catch((error) => {
        // console.log('error', error);
      });
  };

  const onChangeCatrgory = (category) => {
    setSelectedCategories(category);
  };

  const twentySecMarketCheckData = useSelector(
    (state: any) => state.twentySecMarketCheck
  );

  const formatKPIData = (response) => {
    const kpi1 = [...kpiData1];
    const kpi2 = [...kpiData2];
    const selectedCountries = [
      ...northAmericaSelecteeCountries.map((c) => c.value),
      ...southAmericaSelecteeCountries.map((c) => c.value),
    ]
    if (_.isArray(selectedCountries) && selectedCountries.length > 1) {
      kpi1[0].value = "--";
      kpi1[0].raise = undefined;
      kpi1[0].change = "--";
      kpi1[1].raise = undefined;
      kpi1[2].raise = undefined;
      kpi1[3].value = "--";
      kpi1[3].raise = undefined;
      kpi1[3].change = "--";
      kpi2[0].value = "--";
      kpi2[0].raise = undefined;
      kpi2[0].change = "--";
      kpi2[1].raise = undefined;
      kpi2[2].raise = undefined;
    } else {
      kpi1[0].value = response.sales.value
        ? convertToInternationalCurrencySingleChar("$", response.sales.value)
        : "0";
      kpi1[0].raise = response.sales.change ? response.sales.change >= 0 : null;
      kpi1[0].change = response.sales.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.sales.change * 100
        )}%`
        : "0";
      kpi1[1].value = response.volume.value
        ? `${convertToInternationalCurrencySingleChar("", response.volume.value)}`
        : "0";
      kpi1[1].raise = response.volume.change ? response.volume.change >= 0 : null;
      kpi1[1].change = response.volume.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.volume.change * 100
        )}%`
        : "0";
      kpi1[2].value = response.units.value
        ? convertToInternationalCurrencySingleChar("", response.units.value)
        : "0";
      kpi1[2].raise = response.units.change ? response.units.change >= 0 : null;
      kpi1[2].change = response.units.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.units.change * 100
        )}%`
        : "0";
      kpi1[3].value = response.nrr.value
        ? convertToInternationalCurrencySingleChar("$", response.nrr.value)
        : "0";
      kpi1[3].raise = response.nrr.change ? response.nrr.change >= 0 : null;
      kpi1[3].change = response.nrr.change
        ? `${convertToInternationalCurrencySingleChar("", response.nrr.change * 100)}%`
        : "0";
      kpi2[0].value = response.shareSales.value
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareSales.value * 100
        )}%`
        : "0";
      kpi2[0].raise = response.shareSales.change
        ? response.shareSales.change >= 0
        : null;
      kpi2[0].change = response.shareSales.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareSales.change * 100
        )}%`
        : "0";
      kpi2[1].value = response.shareVolume.value
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareVolume.value * 100
        )}%`
        : "0";
      kpi2[1].raise = response.shareVolume.change
        ? response.shareVolume.change >= 0
        : null;
      kpi2[1].change = response.shareVolume.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareVolume.change * 100
        )}%`
        : "0";
      kpi2[2].value = response.shareUnits.value
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareUnits.value * 100
        )}%`
        : "0";
      kpi2[2].raise = response.shareUnits.change
        ? response.shareUnits.change >= 0
        : null;
      kpi2[2].change = response.shareUnits.change
        ? `${convertToInternationalCurrencySingleChar(
          "",
          response.shareUnits.change * 100
        )}%`
        : '0';
      kpi2[3].value = response.nrr.value
        ? convertToInternationalCurrencySingleChar('$', response.nrr.value)
        : '0';
      kpi2[3].raise = response.nrr.change ? response.nrr.change >= 0 : null;
      kpi2[3].change = response.nrr.change
        ? `${convertToInternationalCurrencySingleChar('', response.nrr.change * 100)}%`
        : '0';
    }
    setKpiData1(kpi1);
    setKpiData2(kpi2);
  };

  React.useEffect(() => {
    const countries = [
      ...southAmericaSelecteeCountries,
      ...northAmericaSelecteeCountries,
    ].map((x) => x.value.toUpperCase());
    const payload = {
      countries: countries.length ? countries : null,
      categories: selectedCategories.length ? selectedCategories : null,
      period: onSortChange,
      autoCall
    };
    if (
      payload.countries &&
      payload.countries.length &&
      payload.categories &&
      payload.categories.length &&
      payload.period
    ) {
      setSkeleton(true);
      dispatch(fecth20SecMarketCheckData(payload));
      if(autoCall){
        setAutoCall(false);
      }
    }
  }, [dispatch, selectedCategories, onSortChange]);

  React.useEffect(() => {
    if (
      twentySecMarketCheckData &&
      twentySecMarketCheckData.data &&
      twentySecMarketCheckData.data.sales
    ) {
      setSkeleton(false);
      formatKPIData(twentySecMarketCheckData.data);
    }
    if (twentySecMarketCheckData && twentySecMarketCheckData.error) {
      setSkeleton(false);
    }
  }, [twentySecMarketCheckData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Card className={classes.countryContainer}>
          <Grid container direction="column">
            <Grid className={classes.country}>
              <Grid container>
                <Grid item xs={6}>
                  <Map
                    region="southAmerica"
                    map={southAmericaMill}
                    defaultCountries={southAmericaMapData}
                    onChange={handleMapClick}
                  ></Map>
                </Grid>
                <Grid item xs={6}>
                  <Map
                    region="northAmerica"
                    map={northAmericaMill}
                    defaultCountries={northAmericaMapData}
                    onChange={handleMapClick}
                  ></Map>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} key="filterByCountry">
                <BlackInputTitle>Filter by Country</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={countries}
                  multiple={true}
                  multipleSelectionMsg="Multiple"
                  placeholder="Select Country"
                  allOption={true}
                  defaultOption={[
                    ...northAmericaSelecteeCountries.map((c) => c.value),
                    ...southAmericaSelecteeCountries.map((c) => c.value),
                  ]}
                  onChange={onChangeCountry}
                />
              </Grid>
              <Grid item xs={12} key="filterByCategory">
                <BlackInputTitle>Filter by Category</BlackInputTitle>
                <Dropdown
                  keyText="homepage-country-dropdown"
                  data={categories}
                  placeholder="Select Category"
                  defaultOption={selectedCategories}
                  onChange={onChangeCatrgory}
                  multiple={true}
                  multipleSelectionMsg="Multiple"
                  allOption={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={9}>
        {matches && (
          <Box>
            <Grid container spacing={2}>
              {kpiData1.map((item: IStats, index) => (
                <Grid item key={`stat-card-${index}`} style={{ minWidth: 280 }} className={item.className}>
                  <StatCard
                    key={`StatCard-${index}`}
                    data={item}
                    showSkeleton={skeleton}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2} className={"m-t-20"}>
              {kpiData2.map((item: IStats, index) => (
                <Grid item key={`stat-card1-${index}`} style={{ width: 280 }} className={item.className}>
                  <StatCard
                    key={`StatCard1-${index}`}
                    data={item}
                    showSkeleton={skeleton}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {!matches && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ width: 280 }}>
              {kpiData1.map((item: IStats, index) => (
                <Box
                  key={`StatCard1-${index}`}
                  className={kpiData1.length - 1 !== index && "m-b-20"}
                >
                  <StatCard data={item} showSkeleton={skeleton} />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={6} style={{ width: 280 }}>
              {kpiData2.map((item: IStats, index) => (
                <Box key={`StatCard2-${index}`} className={`m-b-20 ${item.className}`}>
                  <StatCard data={item} showSkeleton={skeleton} />
                </Box>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TwentySecMarketCheck;
