import React from 'react'
import { Grid} from "@mui/material";
import {SummaryPaper, SummaryTitle,SummaryCardValue} from "./SummaryCardStyled";

const SummaryCard = ({
    title,
    icon,
    value,
    iconHeight = 34,
  }: {
    title: string;
    icon: string;
    value: number;
    iconHeight?: number;
  }) => (
    
    <Grid>
      <SummaryPaper
        
        elevation={3}
      >
        <SummaryTitle>{title} </SummaryTitle>
        <Grid container justifyContent="center" alignItems="center">
          <img src={icon} alt={title} height={iconHeight} />
          <SummaryCardValue>{value}</SummaryCardValue>
        </Grid>
      </SummaryPaper>
    </Grid>
  ) 

export default SummaryCard