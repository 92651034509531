import MoreVert from "@material-ui/icons/MoreVert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Popover,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { trim } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DpLogo, logo, whiteLogo } from "../../../assets/images";
import {
  ASSORTMENT_TOOL,
  EXECUTION_TOOL,
  MIX_SIMULATION_SCENARIO_LIBRARY,
  MIX_SIMULATION_TOOL,
  SCENARIO_LIBRARY,
  USER_SETTINGS,
} from "../../../router/CONSTANTS";
import { changeTheme } from "../../../store/actions";
import { loader } from "../../../store/actions/common.action";
import { fetchUser } from "../../../store/actions/user.action";
import { stringAvatar } from "../../../util/helper";
import { AppBar, Avatar, ListItemButton, Toolbar } from "./Appbar.Styled";
import Logout from "./Logout";

const menues = ["User Settings", "Logout"];

const Appbar: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const profilePopoverOpen = Boolean(anchorEl);

  const logoutModal = () => {
    setShowModal(true);
  };

  const handleYes = async () => {
    setShowModal(false);
    dispatch(loader(true));
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}files/deletedatafiles`
    );
    if (response.status === 200) {
      localStorage.clear();
      process.env.REACT_APP_SSO_ENABLE === "Yes"
        ? window.location.replace("https://web.mypepsico.com")
        : window.location.replace("/home");
    } else {
      localStorage.clear();
      history.push("/home");
    }
  };
  const handleNo = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    // dispatch(fetchUser());
  }, []);

  const userDetail = useSelector((state: any) => state.User);

  React.useEffect(() => {
    if (userDetail && userDetail.data) {
      menues[2] = userDetail.data.isDarkMode ? "Light Mode" : "Dark Mode";
      dispatch(changeTheme(userDetail.data.isDarkMode));
    }
  }, [userDetail.data]);

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: "flex", background: " white" }}>
        <Box>
          <img src={DpLogo} alt={logo} height="45px" />
        </Box>
        {/* <Box
          onClick={() => {
            history.push('/home');
          }}
        >
          {theme.darkmode ? <img src={whiteLogo} alt={logo} height='45px' /> : <img src={logo} alt={logo} height='45px' />}
        </Box> */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography component={"span"} variant="body1">
            {userDetail.data && userDetail.data.firstName}{" "}
            {userDetail.data && userDetail.data.lastName}
          </Typography>
          {/* <Avatar
            alt={userDetail.data && userDetail.data.firstName.trim() + userDetail.data.lastName.trim()}
            src={userDetail.data && userDetail.data.profile}
            {...(userDetail.data
              ? stringAvatar(`${trim(userDetail.data && userDetail.data.firstName)} ${trim(userDetail.data && userDetail.data.lastName)}`)
              : '')}
            sx={{ background: theme.darkmode ? 'white' : '' }}
          /> */}
          <IconButton onClick={handleClick}>
            <Typography style={{ color: "black" }}>
              <MoreVert />
            </Typography>
          </IconButton>
          <Popover
            open={profilePopoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List>
              {/* <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(USER_SETTINGS)}>{menues[0]}</ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(SCENARIO_LIBRARY)}>
                  Assortment Prioritization
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(EXECUTION_TOOL)}>Execution Tracker</ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => history.push(MIX_SIMULATION_SCENARIO_LIBRARY)}
                >
                  Mix Simulator Tool
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton onClick={logoutModal}>{menues[1]}</ListItemButton>
              </ListItem> */}
            </List>
          </Popover>
        </Box>
      </Toolbar>
      <Dialog
        open={showModal}
        onClose={setShowModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Logout service"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={theme.darkmode ? "#214e96" : "#858C94"}>
            Do you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {process.env.REACT_APP_SSO_ENABLE === "Yes" ? (
            <Logout text="Yes" onClick={handleYes} />
          ) : (
            <Button onClick={handleYes} autoFocus>
              <Typography color={theme.darkmode ? "#214e96" : "#858C94"}>
                Yes
              </Typography>
            </Button>
          )}
          <Button autoFocus onClick={handleNo}>
            <Typography color={theme.darkmode ? "#214e96" : "#858C94"}>
              No
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default Appbar;
