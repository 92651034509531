import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Title } from '../../../../components/Dashboard/Dashboard.Styled';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup/RadioButtonGroup';
import { RealVsExpectedRadio } from '../../../../mocks/elasticityPricingTrack';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { TopHeading } from '../../ElasticityPricingTrack.Styled';
import { getImageUrl, getRealVsExpectedData } from '../../service';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import PriceElasticityRealVsExpectedComponent from './components/PriceElasticityRealVsExpected/PriceElasticityRealVsExpected';

const PriceElasticityRealVsExpected: React.FC<{
  filters;
  defaultFilters;
  callback;
}> = ({ filters, defaultFilters, callback }) => {
  const [realVsExpectedButtons, setRealVsExpectedButtons] = React.useState(RealVsExpectedRadio.defaultOption.value);
  const [data, setData] = useState<any>([]);
  const [skeleton, setSkeleton] = React.useState(true);
  const [radioSkeleton, setRadioSkeleton] = React.useState(true);

  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      setRealVsExpectedButtons(defaultFilters.variationType ? defaultFilters.variationType : RealVsExpectedRadio.defaultOption.value);
    }
  }, [defaultFilters]);

  const getRealVsExpected = () => {
    getRealVsExpectedData({ ...filters, variationType: realVsExpectedButtons }).then((res) => {
      if (res.status === 200) {
        if (_.isArray(res.data?.brands) && res.data?.brands?.length > 0 && res.data?.allBrands) {
          let response = [...res.data.brands, res.data.allBrands];
          if (_.isArray(response)) {
            Promise.all(
              response?.map(async (item, i) => {
                if (item.parent.imageName) {
                  item.parent.image = await getImageUrl(filters.country, item.parent.imageName);
                } else {
                  i === response.length - 1
                    ? (item.parent.image = await getImageUrl(filters.country, 'All Product(Weighted Average)'))
                    : (item.parent.image = '');
                }
                if (_.isArray(item.child)) {
                  await Promise.all(
                    item.child.map(async (c) => {
                      if (c.imageName) {
                        c.image = await getImageUrl(filters.country, c.imageName);
                      } else {
                        c.image = '';
                      }
                    })
                  );
                }
              })
            ).then(() => {
              setData([...response]);
              setRadioSkeleton(false);
              setSkeleton(false);
            });
          }
        } else {
          setSkeleton(false);
          setRadioSkeleton(true);
        }
      } else {
        setSkeleton(false);
        setRadioSkeleton(true);
      }
    });
  };

  useEffect(() => {
    if (filters && filters.country && realVsExpectedButtons) {
      setSkeleton(true);
      setData([]);
      callback(realVsExpectedButtons);
      getRealVsExpected();
    }
  }, [realVsExpectedButtons]);

  useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      setRadioSkeleton(true);
      setData([]);
      getRealVsExpected();
    }
  }, [filters]);

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <CardContent>
        <Title className='ellipsis'>Price Elasticity Real Vs Expected</Title>
        <Grid container>
          <RadioButtonGroup
            direction='row'
            key={'elasticity-radio-1'}
            color='#000'
            showButton={true}
            showSkeleton={radioSkeleton}
            data={RealVsExpectedRadio.options}
            defaultOption={realVsExpectedButtons}
            select={(data) => setRealVsExpectedButtons(data)}
          />
        </Grid>
        {!skeleton && _.isArray(data) && data.length > 0 ? (
          <>
            <Grid container spacing={2}>
              <Grid item sm={1} />
              <Grid item sm={8}>
                <TopHeading>Trend</TopHeading>
              </Grid>
              <Grid item sm={3}>
                <TopHeading>Accumulated Result</TopHeading>
              </Grid>
            </Grid>
            {_.isArray(data) &&
              data?.map((item, i) => (
                <>
                  <ExpansionPanel
                    parent={{
                      icon: item?.parent.image ? item?.parent.image : null,
                      imageHeight: 100,
                      imageWidth: 100,
                      buttonOffset: 37,
                      name: item?.parent.name,
                      displayName: item?.parent.displayName,
                      component: (
                        <PriceElasticityRealVsExpectedComponent
                          id={`elasticity-pricing-track-price-elasticity-real-vs-expected-parent-${i}`}
                          data={item.parent}
                          unitType={realVsExpectedButtons}
                        />
                      ),
                    }}
                    childrens={item?.child?.map((c, j) => {
                      return {
                        icon: c.image ? c.image : null,
                        imageHeight: 100,
                        imageWidth: 100,
                        height: 725,
                        iconPosition: 200,
                        name: c.name,
                        displayName: c.displayName,
                        component: (
                          <PriceElasticityRealVsExpectedComponent
                            id={`elasticity-pricing-track-price-elasticity-real-vs-expected-child-${j}`}
                            data={c}
                            unitType={realVsExpectedButtons}
                          />
                        ),
                      };
                    })}
                  />
                </>
              ))}
          </>
        ) : !skeleton ? (
          <Grid container display='flex' justifyContent='center' alignItems='center' height={400}>
            <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <Skeleton variant='rectangular' width='auto' height={40} className='m-b-20 m-t-10' />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant='rectangular' width='auto' height={40} className='m-b-20 m-t-10' />
            </Grid>
            <Grid item sm={8}>
              <Skeleton variant='rectangular' width='auto' height={350} className='m-b-20 m-t-10' />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant='rectangular' width='auto' height={350} className='m-b-20 m-t-10' />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default PriceElasticityRealVsExpected;
