import * as React from 'react';
import {
	CardContent,
	Grid,
	Card
} from '@mui/material';
import { Title, CancelButton, SaveButton } from '../../MMPW.Styled';
import ColorPicker from '../ColorPicker';

const ChartBackground: React.FC<{ callback }> = ({ callback }) => {
	const [color, setColor] = React.useState();

	const onSave = () => {
		callback({type: 'ChartConfig', payload: {'chartBackground': color}})
	}

	const onCancel = () => {
		callback({})
	}

	return (
		<Card>
			<CardContent>
				<Grid item xs={12} display="flex" justifyContent="flex-end">
					<SaveButton onClick={onSave}>Save</SaveButton>
					<CancelButton onClick={onCancel}>Cancel</CancelButton>
				</Grid>
				<Grid item xs={12} className="m-b-20">
					<Title>Change Chart Background</Title>
					<hr />
				</Grid>
				<Grid>
					<ColorPicker color={color} callback={setColor} />
				</Grid>
			</CardContent>
		</Card >
	);
};

export default ChartBackground;
