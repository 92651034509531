import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { loader } from '../../store/actions/common.action';
import { toast } from '../../store/actions/toast.action';
import { BlackTitle } from '../../styles/Common.Styled';
import { IDashboardPreviewCard } from '../../types/common';
import { dataFormat } from '../../util/helper';
import { favoriteDashboard, getAllDashboards, removeFavoriteDashboard } from '../../util/services';

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: '12px !important',
  },
  badge: {
    fontSize: '9px',
    height: '16px',
    padding: '0px 5px',
    borderRadius: '5px',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  badgeIcon: {
    fontSize: '9px !important',
  },
}));
const DashboardPreviewCard: React.FC<{
  callback?;
  data?: IDashboardPreviewCard;
  isSaved?;
  isShared?;
  skeleton?;
  onToggleFavorite?;
  showViewCount?;
  showFavorite?;
  isSharedByMe?;
}> = ({
  children,
  data,
  callback,
  isSaved = false,
  isShared = false,
  skeleton = false,
  onToggleFavorite,
  showViewCount = true,
  showFavorite = true,
  isSharedByMe,
}) => {
  const dispatch = useDispatch();
  const { name, viewCount, thumbnailUrl, route, isFavorite, dashboardId, shareByUser, createdOn } = data;
  const theme = useSelector((state: { theme: { darkmode: boolean } }) => state.theme);
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);

  const handleYes = (id) => {
    setShowModal(false);
    dispatch(loader(true));
    handleDelete(id);
  };

  const handleNo = () => {
    setShowModal(false);
    handleClose();
  };

  const toggleFavorite = async () => {
    dispatch(loader(true));
    let dashboardName = '';
    if (isSaved || isShared) {
      const dashboards = await getAllDashboards();
      const dashboardDetail = dashboards.data.find((x) => x.id === dashboardId);
      if (dashboardDetail) dashboardName = dashboardDetail.name;
    } else {
      dashboardName = name;
    }

    if (isFavorite) {
      removeFavoriteDashboard(dashboardName)
        .then((res) => {
          dispatch(toast('Removed Favorited Dashboard!', true, 2000, 'warning'));
          dispatch(loader(false));
          onToggleFavorite();
        })
        .catch(() => {
          dispatch(loader(false));
        });
    } else {
      favoriteDashboard({ dashboardName })
        .then((res) => {
          if (res.status === 200) {
            dispatch(toast('Dashboard Favorited!', true, 2000, 'success'));
            onToggleFavorite();
          } else {
            dispatch(toast('Something went wrong!', true, 2000, 'error'));
          }
          dispatch(loader(false));
        })
        .catch(() => {
          dispatch(loader(false));
        });
    }
  };

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    callback(id);
    handleClose();
  };

  return (
    <>
      {!skeleton ? (
        <Card>
          <Grid data-testid='dashboard-previewCard-container' className='m-10'>
            <Grid container display='flex' justifyContent='space-between' alignItems='center'>
              <BlackTitle onClick={() => history.push(route)} className='cursor-pointer'>
                {name}
                {isShared ? (
                  <BlackTitle className={classes.subTitle}>
                    <span style={{ color: 'gray' }}>Shared {!isSharedByMe && 'by -'} </span>
                    <span>
                      {shareByUser} <span style={{ color: 'gray' }}>On </span>
                      {dataFormat(createdOn, 'dd-MM-yyyy')}
                    </span>
                  </BlackTitle>
                ) : null}
              </BlackTitle>
              <Grid display='flex' justifyContent='space-between' alignItems='center'>
                {!showViewCount && showFavorite ? (
                  <>
                    {isFavorite ? (
                      <FavoriteIcon
                        onClick={toggleFavorite}
                        style={{
                          fill: '#25d4cf',
                          fontSize: '18px',
                          cursor: 'pointer',
                        }}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        style={{
                          fontSize: '18px',
                          color: '#B3BCC7',
                          cursor: 'pointer',
                        }}
                        onClick={toggleFavorite}
                      />
                    )}
                  </>
                ) : null}
                {isSaved || isShared ? (
                  <>
                    <IconButton
                      id='fade-button'
                      aria-controls={open ? 'fade-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleOptions}
                    >
                      <MoreHorizIcon
                        style={{
                          color: 'balck',
                          fontSize: '18px',
                        }}
                      />
                    </IconButton>
                    <Menu
                      id='fade-menu'
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                      <Dialog
                        open={showModal}
                        onClose={() => {
                          setShowModal(false);
                        }}
                        aria-labelledby='responsive-dialog-title'
                      >
                        <DialogTitle id='responsive-dialog-title'>{''}</DialogTitle>
                        <DialogContent>
                          <DialogContentText color={theme.darkmode ? '#214e96' : '#858C94'}>Do you want to delete?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              handleYes(data.id);
                            }}
                            autoFocus
                          >
                            <Typography color={theme.darkmode ? '#214e96' : '#858C94'}>Yes</Typography>
                          </Button>
                          <Button autoFocus onClick={handleNo}>
                            <Typography color={theme.darkmode ? '#214e96' : '#858C94'}>No</Typography>
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Menu>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={8} xl={5}>
                <Grid display='flex' alignItems='center' justifyContent='space-between'>
                  {showViewCount ? (
                    <BlackTitle className={classes.subTitle}>
                      View Count: <span>{viewCount}</span>
                    </BlackTitle>
                  ) : null}
                  {showViewCount && showFavorite ? (
                    <>
                      <BlackTitle> | </BlackTitle>
                      {isFavorite ? (
                        <FavoriteIcon
                          onClick={toggleFavorite}
                          style={{
                            fill: '#25d4cf',
                            fontSize: '18px',
                            cursor: 'pointer',
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            fontSize: '18px',
                            color: '#B3BCC7',
                            cursor: 'pointer',
                          }}
                          onClick={toggleFavorite}
                        />
                      )}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Box onClick={() => history.push(route)} className='cursor-pointer'>
                <img data-testid='image' src={thumbnailUrl} alt={name} style={{ width: '100%', height: '300px' }} />
              </Box>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card>
          <Grid container data-testid='dashboard-previewCard-container' className='m-10'>
            <Grid container display='flex' alignItems='center'>
              <Typography style={{ width: '93%' }}>
                <Skeleton height={40} />
              </Typography>
            </Grid>
            <Grid container>
              <Typography style={{ width: '75%' }}>
                <Skeleton height={30} />
              </Typography>
            </Grid>
            <Grid container>
              <Typography style={{ width: '93%', height: 300 }}>
                <Skeleton height={300} />
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};
export default DashboardPreviewCard;
