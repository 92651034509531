import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CorrelationAnalysisChartConfig } from '../../chartsConfig';

import CustomTable from '../../../../components/CustomTable';
import Dashboard from '../../../../components/Dashboard';
import data from '../../../../mocks/profitParabola';
import { GraphLabel, NoData } from '../../../../styles/Common.Styled';
import _ from 'lodash';
import HighchartContainer from '../../../../components/HighchartContainer';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { loader } from '../../../../store/actions/common.action';
import { messages } from '../../../../util/config';
import { getCorrelationAnalysisData } from '../../service';
import { plotElementsOnChart, separator, setColor } from '../../../../util/helper';

const visualId = 'profit-parabola-correlation-analysis';

const CorrelationAnalysis: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = React.useRef(null);
  const [correlationAnalysisData, setCorrelationAnalysisData] = useState({});
  const [correlation, setCorrelation] = useState(0);
  const [skeleton, setSkeleton] = useState(true);
  const [originalResponse, setOriginalResponse] = useState<any>({});
  const [tableData, setTableData] = useState({ ...data });
  const [defaultPeriodicity, setDefaultPeriodicity] = useState('weekly');

  const formatCorrelationAnalysisData = (data) => {
    if (data.variable1 && data.variable2) {
      setCorrelation(Math.round(data.correlation));
      const dates = data.variable1.map((item) => item.date);
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        setLabels(chart);
        chart.xAxis[0].setCategories(dates);
        chart.series[0].setData(data.variable1.map((item) => item.value));
        chart.series[1].setData(data.variable2.map((item) => item.value));
      }
    }
  };

  const formatCorrelationAnalysisTableData = (response) => {
    if (filters && filters.variable1) {
      data.correlationAnalysisTable.heading[2].title = filters.variable1;
    }
    if (_.isArray(response.variable1) && _.isArray(response.variable1)) {
      data.correlationAnalysisTable.value = response.variable1.map((item, index) => {
        return {
          time: item.date, //dataFormat(item.date, getDataFormat(filters.periodicity)),
          api: separator(response.variable2[index].value.toFixed(2)),
          volume: separator(item.value.toFixed(2)),
        };
      });
    }
    setTableData({ ...data });
  };

  const setLabels = (chart) => {
    if (filters && filters.variable1) {
      chart.yAxis[0].setTitle({ text: filters.variable1 });
      chart.legend.allItems[0].update({
        name: filters.variable1,
      });
    }
  };

  useEffect(() => {
    if (correlationAnalysisData && Object.keys(correlationAnalysisData).length > 0) {
      formatCorrelationAnalysisTableData(correlationAnalysisData);
      setTimeout(() => {
        formatCorrelationAnalysisData(correlationAnalysisData);
      }, 0);
    }
  }, [correlationAnalysisData]);

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
      if (dashbaordData && dashbaordData[visualId]) {
        plotElementsOnChart(dashbaordData[visualId], chart, visualId);
      }
    }
  });

  useEffect(() => {
    if (filters && filters.country) {
      data.correlationAnalysisTable.value = [];
      setCorrelationAnalysisData({});
      setTableData({ ...data });
      setSkeleton(true);
      setOriginalResponse({});
      getCorrelationAnalysisData(filters)
        .then((response) => {
          if (response && response?.data && response.status === 200) {
            setSkeleton(false);
            dispatch(loader(false));
            setOriginalResponse(response.data);
            const periodicities = {
              Week: 'weekly',
              Month: 'monthly',
              Q: 'quarterly',
            };
            const periodicity = periodicities[filters.periodicity];
            setDefaultPeriodicity(periodicity);
            setCorrelationAnalysisData(response.data[periodicity]);
          } else {
            dispatch(loader(false));
            setSkeleton(false);
          }
        })
        .catch((e) => {
          dispatch(loader(false));
          setSkeleton(false);
          console.log(e);
        });
    }
  }, [filters]);

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard
          title='Correlation Analysis'
          showSkeleton={skeleton}
          chartRef={chartRef}
          id={visualId}
          showActionButton={correlationAnalysisData && correlationAnalysisData.variable1}
        >
          {!skeleton ? (
            correlationAnalysisData && correlationAnalysisData.variable1 ? (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    maxHeight: 400,
                    overflow: 'auto',
                    paddingTop: 0,
                    marginTop: 20,
                  }}
                >
                  <CustomTable heading={tableData.correlationAnalysisTable.heading} value={tableData.correlationAnalysisTable.value} />
                </Grid>
                <Grid item xs={12} md={8} key='chart1'>
                  <HighchartContainer chartRef={chartRef} id={visualId}>
                    <HighchartsReact highcharts={Highcharts} options={CorrelationAnalysisChartConfig} ref={chartRef} />
                    <Grid
                      style={{
                        position: 'relative',
                        bottom: 50,
                        left: 30,
                        zIndex: 2,
                        width: '100%',
                      }}
                    >
                      <IncrementDecrement
                        data={originalResponse}
                        selectedResponse={setCorrelationAnalysisData}
                        defaultOption={
                          (localStorage.getItem('periodicity') && JSON.parse(localStorage.getItem('periodicity'))[visualId]?.item) ||
                          defaultPeriodicity ||
                          null
                        }
                        show={originalResponse && Object.keys(originalResponse).length > 0}
                        list={['weekly', 'monthly', 'quarterly']}
                        id={visualId}
                      />
                    </Grid>
                  </HighchartContainer>
                  <Grid container justifyContent='center' className='m-b-20'>
                    <GraphLabel {...setColor(correlation, 'correlation')}>Correlation {correlation}%</GraphLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                <NoData>{messages.noData}</NoData>
              </Box>
            )
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  maxHeight: 350,
                  overflow: 'auto',
                  paddingTop: 0,
                  marginTop: 20,
                }}
              >
                <CustomTable heading={[1, 2, 3]} value={[]} showSkeleton={skeleton} />
              </Grid>
              <Grid item xs={12} md={8} key='skeleton-chart1'>
                <Skeleton variant='rectangular' height={350} />
                <Grid container justifyContent='center'>
                  <Skeleton variant='rectangular' width={150} height={40} className='m-t-20' />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default CorrelationAnalysis;
