import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Popover,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  CustomColumnHeader,
  CustomDataGrid,
  CustomFilterIcon,
  CustomHeaderCell,
} from "./SkuTable.Styled";

import { useDispatch, useSelector } from "react-redux";
import {
  filterableColumns,
  sortableColumns,
  tableColumns,
} from "./TableConfig";
import { setMaxSopValue } from "../../../../../store/actions/AssortmentTool/assortmentDashboard.action";
import DynamicProgressBar from "../../../../../components/ProgressBar/ProgressBar";

const columns = [
  ...tableColumns,
  {
    headerName: "SOP Revenue Mix",
    field: "sopRevenueMix",
    width: 150,
    renderCell: (params) => (
      <div style={{ width: "100%" }}>
        <DynamicProgressBar
          data={params.formattedValue}
          header={params.field}
        />
      </div>
    ),
    align: "center",
  },
  {
    headerName: "SOP NOPBT Mix",
    field: "sopNopbtMix",
    width: 150,
    renderCell: (params) => (
      <div style={{ width: "100%" }}>
        <DynamicProgressBar
          data={params.formattedValue}
          header={params.field}
        />
      </div>
    ),
    align: "center",
  },
];

const SkuTable = ({ rowData }) => {
  const [anchorEls, setAnchorEls] = useState({});
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableRowData, setTableRowData] = useState(rowData);
  const sidebarOpen = useSelector((state) => state.common.sidebarOpen);
  const [originalFilterValues, setOriginalFilterValues] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(rowData);

  useEffect(() => {
    const originalValues = updationOnColumnFilters(columns, rowData);
    setOriginalFilterValues(originalValues);
    setFilterValues(originalValues);
    setTableRowData(rowData);
    const maxValues = computeMaxValues(columns, rowData);
    dispatch(setMaxSopValue(maxValues));
    setFilteredRows(rowData);
  }, [rowData]);

  useEffect(() => {
    const filtered = rowData.filter((row) => {
      for (const [field, values] of Object.entries(selectedFilters)) {
        if (values.length > 0 && !values.includes(row[field])) {
          return false;
        }
      }
      return true;
    });
    setFilteredRows(
      filtered.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [selectedFilters, rowData]);

  const updationOnColumnFilters = (tableColumns, data) => {
    const distinctValues = {};
    tableColumns.forEach((column) => {
      const values = new Set();
      data.forEach((row) => {
        values.add(row[column.field]);
      });
      distinctValues[column.field] = Array.from(values);
    });
    return distinctValues;
  };

  const computeMaxValues = (heading, data) => {
    const maxValues = {};
    heading.forEach((column) => {
      const key = column.field;
      if (key === "sopRevenueMix" || key === "sopProfitMix") {
        const columnValues = data.map((row) => row[key]);
        const maxValue = Math.max(...columnValues);
        maxValues[key] = maxValue;
      }
    });
    return maxValues;
  };

  const handleFilterClick = (event, column) => {
    setAnchorEls({
      ...anchorEls,
      [column.field]: event.currentTarget,
    });
    if (!selectedFilters[column.field]) {
      setSelectedFilters({
        ...selectedFilters,
        [column.field]: [],
      });
    }
  };

  const handleClose = () => {
    setAnchorEls({});
  };

  const handleChangeFilter = (event, column, value) => {
    if (value === "selectAll") {
      setSelectedFilters({
        ...selectedFilters,
        [column.field]: event.target.checked ? filterValues[column.field] : [],
        [`selectAll${column.field}`]: event.target.checked, 
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [column.field]: event.target.checked
          ? [...selectedFilters[column.field], value]
          : selectedFilters[column.field].filter((val) => val !== value),
        [`selectAll${column.field}`]: false, 
      });
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchText(value);

    if (value.trim() === "") {
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.field]: originalFilterValues[column.field].sort(),
      }));
    } else {
      const filteredOptions = originalFilterValues[column.field].filter(
        (option) => option.toLowerCase().includes(value.toLowerCase())
      );
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.field]: filteredOptions.sort(),
      }));
    }
  };

  return (
    <div style={{ height: 700, width: "100%" }}>
      <CustomDataGrid
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            background: "#f1f5fe !important",
          },
          "& .MuiDataGrid-cell": {
            borderRight: "3px solid #D0D2D3",
            borderBottom: "3px solid #D0D2D3 !important",
          },
          "& .MuiDataGrid-columnHeader:not(:last-child)": {
            borderRight: "3px solid #D0D2D3",
          },
          "& .MuiDataGrid-columnHeader": {
            borderBottom: "3px solid #D0D2D3 !important",
          },
          "& .MuiDataGrid-row:nth-child(odd))": {
            background: "black",
          },
          "& .MuiDataGrid-row:nth-child(even)": {
            background: "white",
          },
        }}
        rows={filteredRows}
        className="custom-data-grid"
        columns={columns.map((column) => ({
          ...column,
          headerAlign: "center",
          headerClassName: "custom-column-header",
          headerName: (
            <CustomColumnHeader>
              <CustomHeaderCell>{column.headerName}</CustomHeaderCell>
              {filterableColumns.includes(column.field) && (
                <CustomFilterIcon
                  size="small"
                  aria-label="filter"
                  onClick={(event) => handleFilterClick(event, column)}
                >
                  <FilterListIcon />
                </CustomFilterIcon>
              )}

              {sortableColumns.includes(column.field) && (
                <div style={{ background: " #f1f5fe" }}>
                  {column.sortDirection === "asc" ? (
                    <KeyboardArrowUpIcon />
                  ) : column.sortDirection === "desc" ? (
                    <KeyboardArrowDownIcon />
                  ) : null}
                </div>
              )}
            </CustomColumnHeader>
          ),
          sortable: sortableColumns.includes(column.field),
        }))}
        rowsPerPageOptions={[]}
        disableColumnMenu
        getRowId={(row) => row.rank}
      />
      {columns.map((column) => (
        <Popover
          key={column.field}
          open={Boolean(anchorEls[column.field])}
          anchorEl={anchorEls[column.field]}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ color: "black" }}
        >
          <div
            style={{
              maxHeight: "200px",
              maxWidth: "400px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search..."
              onChange={(e) => handleSearch(e, column)}
              style={{ marginBottom: "8px", padding: "8px" }}
            />

            <FormControl component="fieldset">
              <FormGroup style={{ padding: "8px" }}>
                {filterValues && filterValues[column.field] && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedFilters[`selectAll${column.field}`] ||
                            (selectedFilters[column.field]?.length ===
                              filterValues[column.field]?.length &&
                              filterValues[column.field]?.length > 0)
                          }
                          onChange={(event) =>
                            handleChangeFilter(event, column, "selectAll")
                          }
                          name={`selectAll${column.field}`}
                        />
                      }
                      label={<span style={{ color: "black", marginLeft:"8px" }}>Select All</span>}
                      style={{ margin: 0 }}
                    />
                    {filterValues[column.field].sort().map((value) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            checked={selectedFilters[column.field]?.includes(
                              value
                            )}
                            onChange={(event) =>
                              handleChangeFilter(event, column, value)
                            }
                            name={value}
                            value={value}
                          />
                        }
                        label={
                          <span style={{ color: "black", paddingLeft: "8px" }}>
                            {value}
                          </span>
                        }
                        style={{ margin: 0 }}
                      />
                    ))}
                  </>
                )}
              </FormGroup>
            </FormControl>
          </div>
        </Popover>
      ))}
    </div>
  );
};

export default SkuTable;