import { IconButton, Grid } from "@material-ui/core";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "../ScenarioLibrary/ScenarioLibrary.styles";
import { useHistory } from "react-router-dom";
import { ViewEditButton } from "../../AssortmentTool.Styled";
import { ASSORTMENT_TOOL, MIX_SIMULATION_TOOL } from "../../../../router/CONSTANTS";
import InputSearch from "./InputSearch";
import {
  resetAssortmentFilterState,
  setAssortmentGeoFilterVisibility,
  setAssortmentScenarioName,
  setPenetrationViewMode,
} from "../../../../store/actions//AssortmentTool/assortmentFilters.action";
import { useDispatch } from "react-redux";
import { setViewMode } from "../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import { handleMixEditMode, setMixScenarioName, setMixViewMode, setOutputScreenToDisplay } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";

const SearchScenario = ({ inputValue, onRefresh, isAssortment }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    return inputValue(event?.target.value);
  };

  const hanldeAddNewScenario = () => {
    if(isAssortment){
      dispatch(setAssortmentScenarioName(""));
      dispatch(setPenetrationViewMode(false));
      dispatch(setViewMode(false));
      dispatch(resetAssortmentFilterState());
      dispatch(setAssortmentGeoFilterVisibility(false));
      return history.push(ASSORTMENT_TOOL);
    }else{
      dispatch(setMixScenarioName(""));
      dispatch(setMixViewMode(false));
      dispatch(handleMixEditMode(false))

      dispatch(setOutputScreenToDisplay(false));
      return history.push(MIX_SIMULATION_TOOL);
    }

  };

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <Grid
      className={classes.gridContainer}
      container
      justifyContent="space-between"
    >
      <InputSearch
        handleInputChange={handleInputChange}
        isDisabled={false}
        placeholder={"Search for a scenario by name"}
      />
      <Grid>
        <IconButton type="button" className={classes.customIconButton} onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
        <ViewEditButton onClick={hanldeAddNewScenario}>
          Add New Scenario
        </ViewEditButton>
      </Grid>
    </Grid>
  );
};

export default SearchScenario;
