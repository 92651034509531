import { formatNumber } from "../../util/helper";

const chartConfig = (seriesObject) => {
  return {
    chart: {
      backgroundColor: "transparent",
    },
    title: { text: seriesObject.name },
    xAxis: {
      gridLineWidth: 1,
      title: {
        text: "Dates",
        style: { fontSize: "20px" },
      },
      categories: seriesObject.categories,
      // labels: {
      //   formatter: function () {
      //     return `${this.value}`;
      //   },
      // },
    },
    yAxis: [
      {
        lineWidth: 1,
        title: {
          text: seriesObject.yAxisLabel,
          style: { fontSize: "20px" },
        },
        labels: {
          formatter: function () {
            return this.value;
          },
        },
      },
    ],
    plotOptions: {
      series: { marker: false },
      areaspline: {
        dataLabels: {
          enabled: true,
          style: { fontSize: "15px" },
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [seriesObject],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: false,
  };
};

export { chartConfig };
