import { IColumnAreaData } from '../types/supermarketDash';

const primaryFilter = {
  country: {
    title: 'Country',
    options: [],
  },
  geoLevel: {
    title: 'Geo-Level',
    options: [],
    single: true,
    placeholder: 'Select',
    all: false,
    multiple: false,
  },
  region: {
    title: 'Region',
    options: [],
    placeholder: 'All',
    allOptionText: 'All',
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  city: {
    title: 'City',
    options: [],
    placeholder: 'All',
    allOptionText: 'All',
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  channel: {
    title: 'Channel',
    options: [],
    placeholder: 'All',
    allOptionText: 'All',
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  subChannel: {
    title: 'Sub Channel',
    options: [],
    placeholder: 'All',
    allOptionText: 'All',
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  keyAccounts: {
    title: 'Key Accounts',
    options: [],
    placeholder: 'Null',
    all: false,
    multiple: false,
  },
};

const productFilter = {
  category: {
    title: 'Category',
    options: [],
    placeholder: 'NA',
    all: false,
    multiple: false,
  },
  segment: {
    title: 'Segment',
    options: [],
    placeholder: 'NA',
    all: false,
    multiple: false,
  },
  brand: {
    title: 'Brand',
    options: [],
    placeholder: 'NA',
    all: false,
    multiple: false,
  },
  subBrand: {
    title: 'Sub Brand',
    options: [],
    placeholder: 'NA',
    all: false,
    multiple: false,
  },
  packSize: {
    title: 'Pack Size',
    options: [],
    placeholder: 'NA',
    all: false,
    multiple: false,
  },
};

const productFilterInitialState = {
  data: null,
  loading: false,
  error: null,
  selectedFilters: {
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  },
  disabledFilters: {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  },
};

const saleEveryPointMock = {
  heading: [{ title: 'Price Point ($)' }, { title: 'Unit Sales' }, { title: 'Number of Weeks at This Price Point' }],
  value: [],
};

const avgWeeklyUnitSaleChartMock: IColumnAreaData = {
  xAxisData: [],
  columnData: [],
  areaData: [],
};

const avgWeeklyUnitSaleMock: { heading: { title: string }[]; value: {}[] } = {
  heading: [{ title: 'Price Point ($)' }, { title: 'Weekly Average Units Sales' }, { title: 'Number of Weeks at This Price Point' }],
  value: [],
};

const saleEveryPointChartMock: IColumnAreaData = {
  xAxisData: [],
  columnData: [],
  areaData: [],
};

const combinationChartData = {
  elasticityProjections: [],
  historicalPricePoint: [],
  grossProfitBreakEven: [],
};

const grossProfitTable = {
  heading: [
    {
      title: 'Price Point($)',
      align: 'center',
      baseLine: true,
    },
    { title: 'Gross Profit ABS', align: 'center' },
    { title: 'Gross Profit %', align: 'center' },
    {
      title: 'Gross Profit Breakeven (Additional Unit Sales) %',
      align: 'center',
    },
    { title: 'Elasticity Projections %', align: 'center' },
    {
      title: 'Do We Estimate It Will Pay Off? (Elasticity)',
      align: 'center',
      caseType: 'Upper',
    },
    {
      title: 'Historical Price Point Incrementality %',
      align: 'center',
    },
    {
      title: 'Does Historical Data Suggest It Will Be Paid Off?',
      align: 'center',
      caseType: 'Upper',
    },
  ],
  value: [],
};

const markUpAndMarkDown = {
  title: '',
  options: [
    { label: 'Mark Up', value: 'markup' },
    { label: 'Mark Down', value: 'markdown' },
  ],
  defaultOption: { label: 'Mark Up', value: 'markup' },
};

const exportObject = { primaryFilter, productFilter, markUpAndMarkDown };

export default exportObject;

export {
  saleEveryPointMock,
  saleEveryPointChartMock,
  avgWeeklyUnitSaleMock,
  avgWeeklyUnitSaleChartMock,
  grossProfitTable,
  combinationChartData,
  productFilterInitialState,
};
