import {
  FAILURE_MIX_SIMULATION_DECISION_VARIABLE,
  FAILURE_MIX_SIMULATION_SAVE_SCENARIO,
  FETCH_MIX_SIMULATION_DECISION_VARIABLE,
  REQUEST_MIX_SIMULATION_SAVE_SCENARIO,
  RESET_MIX_USER_INPUTS,
  SET_ALL_MIX_USERINPUT_DETAILS,
  SET_MIX_SCENARIO_NAME,
  SET_MIX_SIMULATION_DECISION_VARIABLE,
  SET_MIX_SIMULATION_GOAL,
  SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID,
  SET_MIX_SIMULATION_UID,
  SET_MIX_VIEW_MODE,
} from "../../actions/constants";

const initialState = {
  scenarioName: "",
  selectedGoal: "",
  uid: "",
  loading: true,
  error: null,
  viewMode: true,
  assortmentScenarios: [],
  selectedAssortmentScenaioUid: ""
};

const mixSimulationUserInput = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MIX_SIMULATION_SAVE_SCENARIO:
      return {
        ...state,
        loading: true,
      };
    case SET_MIX_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };
    case SET_MIX_SIMULATION_SELECTED_ASSORTMENT_SCENARIO_UID:
      return {
        ...state,
        selectedAssortmentScenaioUid: action.payload,
      };

    case FAILURE_MIX_SIMULATION_SAVE_SCENARIO:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_MIX_SIMULATION_DECISION_VARIABLE:
      return {
        ...state,
        loading: true,
      }
    case SET_MIX_SIMULATION_DECISION_VARIABLE:
      return {
        ...state,
        assortmentScenarios: action.payload.data.scenario,
        loading: false,
      }
    case FAILURE_MIX_SIMULATION_DECISION_VARIABLE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case SET_MIX_SIMULATION_GOAL:
      return {
        ...state,
        selectedGoal:
          state.selectedGoal === action.payload ? null : action.payload,
      };

    case SET_MIX_SIMULATION_UID:
      return {
        ...state,
        uid: action.payload.data.uid,
        loading: false,
      };
    case SET_MIX_SCENARIO_NAME:
      return {
        ...state,
        scenarioName: action.payload,
      };
    case SET_ALL_MIX_USERINPUT_DETAILS:
      return {
        ...state,
          scenarioName: action.payload.scenarioName,
          selectedGoal: action.payload.selectedGoal,
          uid: action.payload.uid,
          selectedAssortmentScenaioUid: action.payload.selectedAssortmentScenaioUid
        
      }

    case RESET_MIX_USER_INPUTS:
      return initialState;
    default:
      return state;
  }
};
export default mixSimulationUserInput;
