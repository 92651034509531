import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Typography, Skeleton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Row from './Row';
const useStyles = makeStyles(() => ({
  borderClass: {
    borderLeft: '2px solid #D3D3D3',
    borderBottom: '2px solid #D3D3D3',
  },
  widthClass: {
    borderLeft: '2px solid #D3D3D3',
    borderBottom: '2px solid #D3D3D3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '18%',
  },
  pl2: {
    paddingLeft: '10px',
  },
  headerColor: {
    backgroundColor: '#D3D3D3',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
}));
const MultiLevelTable: React.FC<{
  data: any;
  tableHeader?: any;
  style?;
  level?;
  showSkeleton?: boolean;
  hierarchy,
  hierarchyData?
  filteredData?
  orderByNum?
}> = ({ data, tableHeader, style, level, showSkeleton, hierarchy, hierarchyData, filteredData = null, orderByNum = "name" }) => {
  const classes = useStyles();
  const [levelData, setLevelData] = useState<any>([])

  // const levelData = filteredData ? _.sortBy(filteredData, (item) => item?.orderBy) : _.sortBy(data[level], (item) => item?.orderBy);


  const header = tableHeader?.map((cell, i) => {

    // const levelData = filteredData ? _.sortBy(filteredData, (item) => item?.name) : orderByNum != "name" ? _.orderBy(data[level], [orderByNum], ['desc']) : _.sortBy(data[level], (item) => item?.name);


    return (
      <TableCell
        sx={{ bgcolor: '#D3D3D3' }}
        key={cell?.value}
        align={i === 0 ? 'left' : 'center'}
        className={`${classes.headerColor} ${i === 0 ? classes.borderClass : classes.widthClass}`}
      >
        {cell?.label}
      </TableCell>
    );
  });



  useEffect(() => {
    if (filteredData && orderByNum != "name") {
      setLevelData(_.orderBy(filteredData, [orderByNum], ['desc']))
    } else if (filteredData) {
      setLevelData(_.sortBy(filteredData, (item) => item?.name))
    } else if (orderByNum != "name") {
      setLevelData(_.orderBy(data[level], [orderByNum], ['desc']))
    }
    else {
      setLevelData(_.sortBy(data[level], (item) => item?.name))

    }
  }, [])
  return (
    <>
      <TableContainer component={Paper} style={{ height: style.height, overflow: style.overflow }} className={classes.customTableContainer}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>{header}</TableRow>
          </TableHead>
          {!showSkeleton ? (
            <TableBody>
              {levelData ? (
                levelData?.map((row: any) => (
                  <Row key={row?.name} data={row} originalData={data} level={level} style={style} hierarchy={hierarchy} hierarchyData={hierarchyData} orderByNum={orderByNum} />
                ))
              ) : (
                <Typography className={classes.pl2}>No Data Found</Typography>
              )}
            </TableBody>
          ) : (
            <TableBody>
              {Array.from({ length: 8 }).map(() => (
                <TableRow>
                  {Array.from({ length: tableHeader.length }).map(() => (
                    <TableCell>
                      <Skeleton width="100%" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <Table></Table>
      </TableContainer>
    </>
  );
};
export default MultiLevelTable;
