import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { } from '../../../../../../mocks/elasticityPricingTrack';
import React, { useEffect } from 'react';
import { StyledTableRow } from '../../../../../../styles/Common.Styled';

const VariableTrendInPETable: React.FC<{ Headings; data }> = ({ Headings, data }) => {
  console.log(data)
  return (
    <>
      <TableContainer
        sx={{
          height:'444px',
          paddingBottom: 2,
          maxWidth: "max-content",
          '&::-webkit-scrollbar': {
            height: 8,
          },
        }}
      >
        <Table sx={{ width: "max-content" }} stickyHeader={true}>
          <TableHead>
            <TableRow>
              {Headings.map((head) => (
                <TableCell
                  style={head.value === "avgBeforePricing" || head.value === "avgAfterPricing" || head.value === "varAbs" ? {
                    color: 'white',
                    textAlign: 'center',
                    backgroundColor: '#0093cd',
                    width: 110,
                    // maxWidth: 200
                  } : {
                    color: 'white',
                    textAlign: 'center',
                    backgroundColor: '#0093cd',
                    width: 90,
                    // maxWidth: 150
                  }
                  }
                  className='b-color'
                >
                  {head.label}{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <StyledTableRow>
                {item.map((value, j) => (
                  <>
                    {
                      j === 0 ? (
                        <TableCell
                          style={{
                            color: 'white',
                            textAlign: 'center',
                            backgroundColor: '#25d4cf',
                          }}
                          className='b-color'
                        >
                          {value}
                        </TableCell>
                      ) : (
                        <TableCell className='b-color' style={value === '' ? { background: '#ececec' } : {}}>
                          {value}
                        </TableCell>
                      )
                    }
                  </>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VariableTrendInPETable;
