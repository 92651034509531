import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton } from "@mui/material";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import { getProductFilters } from "../../service";
import {
  mapFilterOptions,
  selectAllOptions,
  disabledFiltersWithVendor,
} from "../../../../util/helper";
import { productFilterConfig, comparisonLevelConfig } from "./config";
import FilterAccordion from "../../../../components/Filters/Filters";
import Indicator from "../../../../components/Loader/Loader";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { fetchFiltersData } from "../../../../util/services";

import data from "../../../../mocks/profitParabola";

const initialProductFilter = {
  vendor: productFilterConfig.vendor.options[0].value,
  category: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
};

const initialDisableProductFilter = {
  vendor: true,
  category: true,
  segment: true,
  brand: true,
  subBrand: true,
  packSize: true,
};

const initialComparisonMapping = {
  vendor: "Vendor",
  category: "Category",
  segment: "Segment",
  brand: "Brand",
  subbrand: "Sub Brand",
  packsize: "Pack Size",
};

const SellVsSuggestedFilter: React.FC<{
  callback;
  globalFilters?;
  defaultFIlters?;
}> = ({ callback, globalFilters, defaultFIlters }) => {
  const [productFilter, setProductFilter] = useState(productFilterConfig);
  const [comparison, setComparison] = useState(
    comparisonLevelConfig.defaultOption.value
  );
  const [skeleton, setSkeleton] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isSave, setisSave] = useState(true);
  let defaultFilterAPIPayload: any = [];

  const [selectedGeoFilter, setSelectedGeoFilter] = React.useState({
    country: null,
    geoLevel: null,
    geoLevel2: null,
    channel: null,
    date: null,
  });

  const [selectedProductFilter, setSelectedProductFilter] = React.useState({
    ...initialProductFilter,
  });

  const [markupOrMarkdown, setMarkupOrMarkdown] = useState(
    data.markUpAndMarkDown.defaultOption.value
  );

  const [disableProductFilter, setDisableProductFilter] = React.useState({
    ...initialDisableProductFilter,
  });

  const [comparisonLevel, setComparisonLevel] = React.useState({
    vendor: false,
    category: false,
    segment: false,
    brand: false,
    subBrand: false,
    packSize: false,
    brandPackSize: false,
  });

  useEffect(() => {
    if (comparison.toLowerCase().replace(/ /g, "") === "brandpacksize") {
      setProductFilter(_.omit(productFilterConfig, "subBrand"));
    } else {
      setProductFilter(productFilterConfig);
    }
    if (comparison) {
      const temp = {
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      switch (comparison) {
        case "Category":
          temp.vendor = true;
          temp.category = true;
          break;
        case "Segment":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          break;
        case "Brand":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          break;
        case "Sub Brand":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          break;
        case "Pack Size":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = true;
          temp.packSize = true;
          break;
        case "Brand Pack Size":
          temp.vendor = true;
          temp.category = true;
          temp.segment = true;
          temp.brand = true;
          temp.subBrand = false;
          temp.packSize = true;
          break;
      }
      if (
        defaultFIlters &&
        _.isObject(defaultFIlters) &&
        Object.keys(defaultFIlters).length > 0
      ) {
        for (let key in temp) {
          if (temp[key]) {
            if (defaultFIlters[key]?.length > 0) {
              defaultFilterAPIPayload.push({
                name: key,
                value: defaultFIlters[key],
                apiCalled: false,
              });
            }
          }
        }
        fetchProductFilters({ ...initialProductFilter }, globalFilters.country);
      } else {
        setSelectedProductFilter({ ...initialProductFilter });
        setDisableProductFilter({
          ...initialDisableProductFilter,
          category: false,
        });
      }
      setComparisonLevel(temp);
    }
  }, [comparison, defaultFIlters]);

  useEffect(() => {
    if (globalFilters && globalFilters.country) {
      setSkeleton(false);
      setSelectedGeoFilter({
        country: globalFilters.country,
        geoLevel: _.isArray(globalFilters.geoLevel)
          ? globalFilters.geoLevel
          : [globalFilters.geoLevel],
        channel: _.isArray(globalFilters.channel)
          ? globalFilters.channel
          : [globalFilters.channel],
        geoLevel2: _.isArray(globalFilters.geoLevel2)
          ? globalFilters.geoLevel2
          : [globalFilters.geoLevel2],
        date: globalFilters.date,
      });
      setDisableProductFilter({
        ...initialDisableProductFilter,
        category: false,
      });

      fetchProductFilters({ ...initialProductFilter }, globalFilters.country);
    }
  }, [globalFilters]);

  useEffect(() => {
    if (
      defaultFIlters &&
      _.isObject(defaultFIlters) &&
      Object.keys(defaultFIlters).length > 0
    ) {
      setComparison(initialComparisonMapping[defaultFIlters?.comparisonLevel]);
      return;
    }
  }, [defaultFIlters]);

  const onChangeProductFilter = (key, value) => {
    const filterValues = { ...selectedProductFilter };
    const disableFilter = { ...disableProductFilter };
    const result = formatValues(key, value, filterValues, disableFilter);
    setSelectedProductFilter({ ...result.filterValues, [key]: value });
    if (value && value.length > 0) {
      if (
        key !== "packSize" &&
        key.toLowerCase() !== comparison.toLowerCase()
      ) {
        fetchProductFilters({ ...result.filterValues, [key]: value });
      }
    }
    setDisableProductFilter({ ...result.disableFilter });
  };

  const fetchProductFilters = (payload, country?) => {
    if (selectedGeoFilter.country || country) {
      setLoader(true);
      // getProductFilters({
      //   country: selectedGeoFilter.country || country,
      //   ...payload,
      // })
      fetchFiltersData("filter/BrandLadderProductFilter", {
        country: selectedGeoFilter.country || country,
        ...payload,
        anchor: { category: null, segment: null },
        isPepsi: true,
        vendor: ["PEPSICO"],
        comparisonLevel: comparison.toLowerCase().replace(/ /g, ""),
      })
        .then((response) => {
          let tempProductFilter;
          tempProductFilter = { ...productFilter };
          if (response && response.data) {
            setLoader(false);
            tempProductFilter = mapFilterOptions(response, tempProductFilter);
            setProductFilter(tempProductFilter);
            setSelectedProductFilter({
              ...payload,
              ...selectAllOptions(response, productFilter),
            });
            const index = defaultFilterAPIPayload.findIndex(
              (filtertemp) => !filtertemp.apiCalled
            );

            if (index !== -1 && isSave) {
              defaultFilterAPIPayload[index].apiCalled = true;
              const key = defaultFilterAPIPayload[index].name;
              const value = defaultFilterAPIPayload[index]["value"];
              const result = disabledFiltersWithVendor({ [key]: value }, true);
              setDisableProductFilter({ ...result });
              if (defaultFilterAPIPayload[index].name !== "packSize")
                fetchProductFilters(
                  { ...payload, [key]: value },
                  defaultFilterAPIPayload
                );
              else {
                setSelectedProductFilter({
                  ...{
                    ...payload,
                    ...selectAllOptions(response, productFilter),
                  },
                  [key]: value,
                });
              }
            }
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const formatValues = (key, value, filterValues, disableFilter) => {
    if (key === "vendor") {
      filterValues.category = null;
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.category = value === null || value.length === 0;
      disableFilter.segment = true;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "category") {
      filterValues.segment = null;
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.segment = value === null || value.length === 0;
      disableFilter.brand = true;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "segment") {
      filterValues.brand = null;
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.brand = value === null || value.length === 0;
      disableFilter.subBrand = true;
      disableFilter.packSize = true;
    } else if (key === "brand") {
      filterValues.subBrand = null;
      filterValues.packSize = null;

      disableFilter.subBrand = value === null || value.length === 0;
      disableFilter.packSize = true;
    } else if (key === "subBrand") {
      filterValues.packSize = null;

      disableFilter.packSize = value === null || value.length === 0;
    }

    if (
      comparison.toLowerCase().replace(/ /g, "") === "brandpacksize" &&
      key === "brand"
    ) {
      filterValues.packSize = null;

      disableFilter.packSize = value === null || value.length === 0;
    }
    return { filterValues, disableFilter };
  };

  const onApplyFilter = () => {
    if (comparison.toLowerCase().replace(/ /g, "") === "brandpacksize") {
      callback(
        {
          ...selectedGeoFilter,
          category: selectedProductFilter.category,
          segment: selectedProductFilter.segment,
          brand: selectedProductFilter.brand,
          packSize: selectedProductFilter.packSize,
          comparisonLevel: comparison.toLowerCase().replace(/ /g, ""),
          isMarkUp: markupOrMarkdown === "markup" ? 1 : 0
        }
      );
    } else {
      callback({
        ...selectedGeoFilter,
        category: selectedProductFilter.category,
        segment: selectedProductFilter.segment,
        brand: selectedProductFilter.brand,
        subBrand: selectedProductFilter.subBrand,
        packSize: selectedProductFilter.packSize,
        comparisonLevel: comparison.toLowerCase().replace(/ /g, ""),
        isMarkUp: markupOrMarkdown === "markup" ? 1 : 0,
      });
    }
  };

  const clearFilter = () => {
    setSelectedProductFilter({ ...initialProductFilter });
    setDisableProductFilter({ ...initialDisableProductFilter });
    setComparisonLevel({
      vendor: false,
      category: false,
      segment: false,
      brand: false,
      subBrand: false,
      packSize: false,
      brandPackSize: false,
    });
    setComparison(comparisonLevelConfig.defaultOption.value);
  };

  const disableApplyFilter = () => {
    const flag: boolean =
      globalFilters.country === "" ||
      globalFilters.country === undefined ||
      (comparison.toLowerCase().replace(/ /g, "") === "category" &&
        !selectedProductFilter.category) ||
      (comparison.toLowerCase().replace(/ /g, "") === "segment" &&
        !selectedProductFilter.segment) ||
      (comparison.toLowerCase().replace(/ /g, "") === "brand" &&
        !selectedProductFilter.brand) ||
      (comparison.toLowerCase().replace(/ /g, "") === "subbrand" &&
        !selectedProductFilter.subBrand) ||
      (comparison.toLowerCase().replace(/ /g, "") === "packsize" &&
        !selectedProductFilter.packSize) ||
      (comparison.toLowerCase().replace(/ /g, "") === "brandpacksize" &&
        !selectedProductFilter.packSize);
    return flag;
  };

  const reduxState = useSelector((state: any) => state);
  const SellOut = useSelector((state: any) => state.SellOutDashboard);

  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={
          !reduxState.common.loader &&
          (SellOut.selloutPriceVsSuggestedPriceDataLoader ||
            SellOut.sopVsSrpDataLoader ||
            loader)
        }
      />
      <CardContent>
        <Grid container spacing={2} className="m-b-10">
          <Grid item>
            <Title>Sell Out Price VS Suggested Retail Price</Title>
          </Grid>
        </Grid>
        {!skeleton ? (
          <>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12} sm={2}>
                <RadioButtonGroup
                  key={`measures-api-filters`}
                  color="#000"
                  showButton={comparisonLevelConfig.showRadioButton}
                  data={comparisonLevelConfig.options}
                  select={(data) => {
                    setComparison(data);
                    setisSave(false);
                  }}
                  defaultOption={comparison}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container display="flex" alignItems="center">
                  <Grid className="m-r-20">
                    <Box style={{ fontSize: 16, fontWeight: 600 }}>
                      MarkUp/MarkDown -
                    </Box>
                  </Grid>
                  <Grid>
                    <RadioButtonGroup
                      key={`markup-and-markdown`}
                      color="#000"
                      showButton={true}
                      data={data.markUpAndMarkDown.options}
                      defaultOption={markupOrMarkdown}
                      select={setMarkupOrMarkdown}
                      direction="row"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FilterAccordion title="PepsiCo">
              <Grid container spacing={2} columns={14} className="m-b-20">
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{productFilter.vendor.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableProductFilter.vendor}
                    data={productFilter.vendor.options}
                    onChange={(val) => onChangeProductFilter("vendor", val)}
                    defaultOption={selectedProductFilter.vendor || ""}
                    search={{ enable: false }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DropdownTitle>{productFilter.category.title}</DropdownTitle>
                  <Dropdown
                    disabled={disableProductFilter.category}
                    data={productFilter.category.options}
                    onChange={(val) => onChangeProductFilter("category", val)}
                    defaultOption={selectedProductFilter.category || ["empty"]}
                    placeholder={productFilter.category.placeholder}
                    multiple={productFilter.category.multiple}
                    allOption={productFilter.category.all}
                    selectionLimit={productFilter.category.selectionLimit}
                  />
                </Grid>
                {comparisonLevel.segment && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.segment.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.segment}
                      data={productFilter.segment.options}
                      onChange={(val) => onChangeProductFilter("segment", val)}
                      defaultOption={selectedProductFilter.segment || ["empty"]}
                      placeholder={productFilter.segment.placeholder}
                      multiple={productFilter.segment.multiple}
                      allOption={productFilter.segment.all}
                      selectionLimit={productFilter.segment.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.brand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>{productFilter.brand.title}</DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.brand}
                      data={productFilter.brand.options}
                      onChange={(val) => onChangeProductFilter("brand", val)}
                      defaultOption={selectedProductFilter.brand || ["empty"]}
                      placeholder={productFilter.brand.placeholder}
                      multiple={productFilter.brand.multiple}
                      allOption={productFilter.brand.all}
                      selectionLimit={productFilter.brand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.subBrand && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {productFilter.subBrand.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.subBrand}
                      data={productFilter.subBrand.options}
                      onChange={(val) => onChangeProductFilter("subBrand", val)}
                      defaultOption={
                        selectedProductFilter.subBrand || ["empty"]
                      }
                      placeholder={productFilter.subBrand.placeholder}
                      multiple={productFilter.subBrand.multiple}
                      allOption={productFilter.subBrand.all}
                      selectionLimit={productFilter.subBrand.selectionLimit}
                    />
                  </Grid>
                )}
                {comparisonLevel.packSize && (
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle>
                      {productFilter.packSize.title}
                    </DropdownTitle>
                    <Dropdown
                      disabled={disableProductFilter.packSize}
                      data={productFilter.packSize.options}
                      onChange={(val) => onChangeProductFilter("packSize", val)}
                      defaultOption={
                        selectedProductFilter.packSize || ["empty"]
                      }
                      placeholder={productFilter.packSize.placeholder}
                      multiple={productFilter.packSize.multiple}
                      allOption={productFilter.packSize.all}
                      selectionLimit={productFilter.packSize.selectionLimit}
                    />
                  </Grid>
                )}
              </Grid>
            </FilterAccordion>

            <Grid className="p-l-16">
              <OrangeBtn
                color="secondary"
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn
                color="primary"
                onClick={onApplyFilter}
                disabled={disableApplyFilter()}
              >
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={2}>
                <Skeleton variant="rectangular" height={35} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={2}>
                <Skeleton variant="rectangular" height={35} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={2}>
                <Skeleton variant="rectangular" height={35} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={2}>
                <Skeleton variant="rectangular" height={35} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={2}>
                <Skeleton variant="rectangular" height={35} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={45} />
              </Grid>
            </Grid>
            <Grid className="p-l-16 m-t-20" container spacing={2}>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={99}
                height={45}
              />
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={110}
                height={45}
              />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SellVsSuggestedFilter;
