import { WebRounded } from "@mui/icons-material";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../../../../components/CustomTable/CustomTable.Styled";
import { StyledTableRow } from "../../../../../../styles/Common.Styled";
import { formatNumber } from "../../../../../../util/helper";

const RegionElasticitiesTable: React.FC<{
    data;
}> = ({ data }) => {


    const [headings, setHeadings] = useState<any>([])
    const [tableValues, setTableData] = useState<any>([])
    console.log(data)


    useEffect(() => {
        if (data) {
            setHeadings([
                "Regions", "Mix Sales", `Real Elasticities`, "R2", "PVALUE", "MAPE", "Model Type *"
            ]);
            const regionsData: Array<any> =
                data.regionCalculation?.map((item) => {
                    return (
                        {
                            region: item.region,
                            mix: `${formatNumber(item.mix * 100)}%`,
                            elasticity: item.elasticity,
                            r2Value: item.r2Value,
                            pValue: item.pValue,
                            mapeValue: item.mapeValue,
                            modelType: item.modelType

                        }
                    )
                })
            if (regionsData.length > 0) {
                const weightedAvg = {};
                Object.entries(data.weightedAvg).map(([key, value]) => {
                    weightedAvg[key] = {
                        value,
                        colorCode: "#cec8c8"
                    }
                })
                setTableData([...regionsData, {
                    region: null,
                    mix: null,
                    elasticity: null,
                    r2Value: null,
                    pValue: null,
                    mapeValue: null,
                    modelType: null
                }, weightedAvg]);

            }
        }
    }, [data])

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headings &&
                            headings.map((mHead) => {
                                return (
                                    <>
                                        <TableCell
                                            style={{
                                                color: "white",
                                                textAlign: "center",
                                                backgroundColor: "#25d4cf",
                                            }}
                                            className="b-color"
                                            align="center"
                                            width="50"
                                        >
                                            {mHead}
                                        </TableCell>
                                    </>
                                );
                            })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableValues.map((row, i) => (
                        <TableRow key={i} data-testid="tableBodyRows">
                            {Object.keys(row).map((key, j) => {
                                let cellData: TableCell = row[key];
                                return (
                                    <StyledTableCell
                                        style={{
                                            backgroundColor:
                                                _.isObject(cellData) && cellData.colorCode,
                                            ...cellData == null ? { borderWidth: "0px" } : {}
                                        }}
                                        align="center"
                                    >
                                        {_.isObject(cellData) ? (_.isNumber(cellData.value) ? formatNumber(cellData.value) : cellData.value) : _.isNumber(cellData) ? formatNumber(cellData) : cellData}

                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>

                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RegionElasticitiesTable;
