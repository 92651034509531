import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, CardContent } from '@mui/material';
import { Typography } from '@material-ui/core';
import {
  BugReportIcon,
  SystemAlertIcon,
  DashboardCreatedIcon,
  DataUpdatingIcon,
} from '../../assets/icons/notification';
import { Title } from '../../pages/AlertAndNotification/components/NotificationListCard/NotificationListCard.Styled';
import { CloseButton } from '../../styles/Common.Styled';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '70px',
    borderRadius: '6px',
  },
  title: {
    fontSize: 16,
    color: '#000',
  },
  content: {
    fontSize: 14,
    color: '#000',
  },
  moreInfo: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  bugTypo: { color: '#FF721E !important' },
  alertTypo: { color: '#CE0065 !important' },
  newTypo: { color: '#0093CD !important' },
  updateTypo: { color: '#00AA52 !important' },
}));

const NotificationCard: React.FC<{ data; callback }> = ({ data, callback }) => {
  const classes = useStyles();
  const { type, title, subTitle, expiration } = data;

  return (
    <Card data-testid='notification-container'>
      <CardContent style={{ position: 'relative' }}>
        <Grid container display='flex' alignItems='center' spacing={2}>
          <Grid item xs={4} sm={2}>
            {type === 'Bug' ? (
              <img src={BugReportIcon} alt='icon' className={classes.icon} />
            ) : type === 'System_Alert' ? (
              <img src={SystemAlertIcon} alt='icon' className={classes.icon} />
            ) : type === 'New_Dashboard_Created' ? (
              <img
                src={DashboardCreatedIcon}
                alt='icon'
                className={classes.icon}
              />
            ) : (
              <img src={DataUpdatingIcon} alt='icon' className={classes.icon} />
            )}
          </Grid>
          <Grid item xs={12} sm={10}>
            {type === 'Bug' ? (
              <Title className={classes.bugTypo}>{title}</Title>
            ) : type === 'System_Alert' ? (
              <Title className={classes.alertTypo}>{title}</Title>
            ) : type === 'New_Dashboard_Created' ? (
              <Title className={classes.newTypo}>{title}</Title>
            ) : (
              <Title className={classes.updateTypo}>{title}</Title>
            )}
            <Typography className={classes.title}>{subTitle}</Typography>
            <Typography className={classes.content}>{data.message}</Typography>
          </Grid>
        </Grid>
        <CloseButton
          style={{ right: 10, top: 10, padding: 5, height: 30, width: 30 }}
          onClick={() => callback({ type: 'dismiss' })}
        >
          X
        </CloseButton>
        {/* <Grid container display="flex" justifyContent="flex-end">
          <Typography className={`${classes.content} ${classes.moreInfo}`}>More Information</Typography>
        </Grid> */}
      </CardContent>
    </Card>
  );
};
export default NotificationCard;
