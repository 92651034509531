import React, { useEffect, useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import _ from "lodash";

const MeasureFilter: React.FC<{
  data;
  selectedMeasureFilters;
  callback;
}> = ({ data, selectedMeasureFilters, callback }) => {

  return (
    <Grid>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Grid container display="flex" alignItems="center">
          <Grid className="m-r-20">
            <Box style={{ fontSize: 14, fontWeight: 600 }}>
              MarkUp/MarkDown -
            </Box>
          </Grid>
          <Grid>
            <RadioButtonGroup
              key={`markup-and-markdown`}
              color="#000"
              showButton={true}
              data={data.markUpAndMarkDown.options}
              defaultOption={selectedMeasureFilters.isMarkUp? data.markUpAndMarkDown.options[0].value : data.markUpAndMarkDown.options[1].value}
              select={(data) => {
                callback({
                  ...selectedMeasureFilters,
                  isMarkUp: data === "markup"? 1 : 0,
                });
              }}
              direction="row"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} columns={16}>
        <Grid item xs={12} sm={3} key={`measures-dropdown-container-1`}>
          <DropdownTitle>{data.absIndex.title}</DropdownTitle>
          <RadioButtonGroup
            key={`measures-filters-1`}
            color="#000"
            showButton={data.absIndex.showRadioButton}
            data={data.absIndex.options}
            defaultOption={selectedMeasureFilters.absIndex}
            select={(data) =>
              callback({ ...selectedMeasureFilters, absIndex: data })
            }
          />
        </Grid>
        <Grid item xs={12} sm={3} key={`measures-dropdown-container-2`}>
          <DropdownTitle>{data.unitVolume.title}</DropdownTitle>
          <RadioButtonGroup
            key={`measures-filters-2`}
            color="#000"
            showButton={data.unitVolume.showRadioButton}
            data={data.unitVolume.options}
            defaultOption={selectedMeasureFilters.unitVolume}
            select={(data) =>
              callback({ ...selectedMeasureFilters, unitVolume: data })
            }
          />
        </Grid>

        <Grid item xs={12} sm={3} key={`measures-textfield-container-1`}>
          <DropdownTitle>{data.taxes.title}</DropdownTitle>
          <TextField InputProps={{ autoComplete: 'off' }}
            type={"number"}
            id="taxes"
            className="w-100"
            onChange={(e) => {
              callback({
                ...selectedMeasureFilters,
                taxes: parseFloat(e.target.value),
              });
            }}
            variant="outlined"
            value={selectedMeasureFilters.taxes}
          />
        </Grid>

        <Grid item xs={12} sm={3} key={`measures-textfield-container-2`}>
          <DropdownTitle>
            {selectedMeasureFilters.isMarkUp? "Markup(%)" : "Markdown(%)"}
          </DropdownTitle>
          <TextField InputProps={{ autoComplete: 'off' }}
            type={"number"}
            id="markup"
            className="w-100"
            onChange={(e) => {
              callback({
                ...selectedMeasureFilters,
                markUp: parseFloat(e.target.value),
              });
            }}
            variant="outlined"
            value={selectedMeasureFilters.markUp}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MeasureFilter;
