import { Grid } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect } from 'react';
import { ColumnDrilldownNewConfig, ColumnDrilldownNewConfigForBar } from './ColumnDrilldownConfig';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const ColumnDrilldown: React.FC<{ data; xAxis?; chartRef;barSeries? }> = ({ data, xAxis, chartRef,barSeries }) => {
  // const highchartOptions = ColumnDrilldownConfig(xAxis, data);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.xAxis[0].setCategories(data?.xAxisData);
        chart.series[0].setData(data?.areaData);
        chart.series[1].setData(data?.columnData);
      }
    }
  }, [data]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <HighchartsReact highcharts={Highcharts} options={ barSeries ? ColumnDrilldownNewConfigForBar : ColumnDrilldownNewConfig} ref={chartRef} />
      </Grid>
    </Grid>
  );
};

export default ColumnDrilldown;
