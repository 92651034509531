import * as React from 'react';
import {
	CardContent,
	List,
	ListItem,
	Grid,
	TextField
} from '@mui/material';
import { Title, StyledCard, ListItemButton, Item } from '../../MMPW.Styled';
import { CloseButton } from '../../../../styles/Common.Styled';

const FontSize: React.FC<{ fontSize?, callback?}> = ({ fontSize, callback }) => {
	const [inputVal, setFontsize] = React.useState(fontSize);
	const fontSizeList = [8, 10, 12, 14, 16, 18];
	const onSelectFont = (fontSize) => {
		callback({ type: "fontSize", fontSize })
	}

	const onSubmit = (e) => {
		if (e.keyCode == 13) {
			onSelectFont(parseInt(e.target.value));
		}
	};
	return (
		<StyledCard>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12} display="flex" alignContent="center">
						<Title>Font Size</Title>
						<CloseButton style={{ right: 10, top: 10, height: 20, width: 20 }} onClick={() => onSelectFont(fontSize)}>x</CloseButton>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<List>
								<TextField
									id='fontSize'
									value={inputVal}
									onKeyDown={onSubmit}
									onChange={(e) => setFontsize(e.target.value)}
								/>
								{fontSizeList.map((size, i) => (
									size === fontSize ?
										<Item style={{width: 50}} key={`selected-fontSize${i}`}>
											{fontSize}
										</Item>
										:
										<ListItem disablePadding onClick={() => onSelectFont(size)}>
											<ListItemButton>{size}</ListItemButton>
										</ListItem>
								))}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	);
};

export default FontSize;
