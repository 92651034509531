import { API } from "../../util/helper";

export const getCategoryData = async (param) => {
  try {
    return await API.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}Categories/${param}`
    );
  } catch (e) {
    return e;
  }
};