import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getSharedDashBoardsToMe, getSharedDashBoardsByMe, deleteSharedDashboard } from '../../../../util/services';
import DashboardPreviewCard from '../../../../components/DashboardPreviewCard';
import { LoadMoreBtn } from '../Dashboard.Styled';
import NoDashboard from '../../../../components/NoDashboard/NoDashboard';
import _ from 'lodash';
import { dashboardCardMock } from '../../../../mocks/common';
import { sortData } from '../../../../util/helper';
import { toast } from '../../../../store/actions/toast.action';
import { useDispatch } from 'react-redux';
import { loader } from '../../../../store/actions/common.action';

const SharedDashboard: React.FC<{ sortOrder }> = ({ sortOrder }) => {
  const [dashboards, setDashboards] = useState([]);
  const [allSharedDashboards, setAllSharedDashboards] = useState([]);
  const [isSharedByMe, setIsSharedByMe] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const dispatch = useDispatch();

  const sortDashboards = (data) => {
    const column = sortOrder === 'newest' ? 'createdOn' : null;
    const sortedData = sortData(sortOrder, data, column);
    setAllSharedDashboards(sortedData);
  };

  React.useEffect(() => {
    if (allSharedDashboards.length) sortDashboards(allSharedDashboards);
  }, [sortOrder]);

  useEffect(() => {
    if (_.isArray(allSharedDashboards) && allSharedDashboards.length) {
      setDashboards([..._.take(allSharedDashboards, 4)]);
    }
  }, [allSharedDashboards]);

  const fetchSharedDashboards = async (sharedByMe) => {
    setSkeleton(true);
    let response;
    if (sharedByMe) {
      response = await getSharedDashBoardsByMe();
    } else {
      response = await getSharedDashBoardsToMe();
    }
    if (response && _.isArray(response.data) && response.data.length) {
      const formattedResponse = response.data.map((s) => ({
        ...s,
        route: `${s.route}?id=${s.id}&shared=${sharedByMe ? 'by-me' : 'to-me'}` + (!sharedByMe ? `&shared-by=${s.shareByUser}` : ''),
      }));
      sortDashboards(formattedResponse);
    }
    setSkeleton(false);
  };

  const loadMore = () => {
    setDashboards([...allSharedDashboards]);
  };

  useEffect(() => {
    setAllSharedDashboards([]);
    setDashboards([]);
    fetchSharedDashboards(isSharedByMe);
  }, [isSharedByMe]);

  const deleteSavedDashboardById = (id) => {
    let type = isSharedByMe ? 'shared-by-me' : 'shared-with-me';
    deleteSharedDashboard({ id, type })
      .then((res) => {
        if (res.status === 200) {
          dispatch(toast('Deleted Successfully!', true, 2000, 'success'));
          fetchSharedDashboards(isSharedByMe);
          dispatch(loader(false));
        } else {
          dispatch(toast('Something went wrong', true, 2000, 'error'));
          dispatch(loader(false));
        }
      })
      .catch((err) => {
        dispatch(toast('Something went wrong', true, 2000, 'error'));
      });
  };

  return (
    <Grid>
      <Grid display='flex' justifyContent='end' alignItems='center'>
        <FormControlLabel
          control={<Switch color='secondary' onChange={() => setIsSharedByMe(!isSharedByMe)} checked={isSharedByMe} />}
          label={isSharedByMe ? 'Shared By Me' : 'Shared With Me'}
        />
      </Grid>
      {skeleton ? (
        <Grid container spacing={2} className='m-b-15'>
          {dashboardCardMock.map((item, index) => (
            <Grid item xs={12} sm={3} md={3} key={`saved-dashboard-cards-${index}`}>
              <DashboardPreviewCard data={item} skeleton={true} />
            </Grid>
          ))}
        </Grid>
      ) : dashboards?.length > 0 ? (
        <Grid container spacing={2} className='m-b-15'>
          {dashboards.map((item, index) => (
            <Grid item xs={12} sm={3} key={`dashboard-cards-${index}`}>
              <DashboardPreviewCard
                callback={deleteSavedDashboardById}
                data={item}
                showFavorite={false}
                showViewCount={false}
                isShared={true}
                isSharedByMe={isSharedByMe}
                onToggleFavorite={fetchSharedDashboards}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} justifyContent='center'>
          <NoDashboard heading='No dashboards available' />
        </Grid>
      )}
      <Box className='m-b-15' display='flex' justifyContent='center' alignItems='center'>
        {dashboards.length !== allSharedDashboards.length ? <LoadMoreBtn onClick={loadMore}>Load More</LoadMoreBtn> : null}
      </Box>
    </Grid>
  );
};

export default SharedDashboard;
