export const chartConfig = {
  chart: {
    type: "column",
    height: 650
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
    align: "left",
  },
  xAxis: {
    labels: {
      rotation: -45
    }
  },
  yAxis: {
    min: 0,
    title: {
      enabled: false,
      text: "Count trophies",
    },
    stackLabels: {
      enabled: false,
      style: {
        fontWeight: "bold",
        color: "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    backgroundColor: "white",
    borderColor: "#CCC",
    borderWidth: 1,
    shadow: false,
    symbolWidth: 16,
    symbolRadius: 0,
    squareSymbol: false,
    maxWidth: 200,
    itemWidth: 120,
    itemMarginBottom: 5
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      pointWidth: 45
    }
  },
  series: [],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
