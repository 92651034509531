import { formatNumber } from '../../../../../../util/helper';

export const priceElasticityConfig = {
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    height: '436px',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    reversed: false,
    opposite: true,
    title: {
      text: '',
      style: {
        fontSize: 18,
        fontWeight: 600,
        marginLeft: -30,
      },
    },
  },
  yAxis: {
    title: {
      text: 'Price and Grams Elasticities',
      style: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    labels: {
      enabled: false,
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 50,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter: function (): string {
          return this.key === 'Difference (VS Prior period)' ? `${this.y.toFixed(2)}%` : `${this.y.toFixed(2)}`;
        },
      },
      tooltip: {
        pointFormatter: function (): string {
          return this.name === 'Difference (VS Prior period)' ? `${this.y.toFixed(2)}%` : `${this.y.toFixed(2)}`;
        },
      },
    },
  },
  series: [
    {
      name: 'Price Elasticity',
      // colorByPoint: true,
      color: '#3B97B2',
      data: [],
    },
    {
      name: 'Gram Elasticity',
      // colorByPoint: true,
      color: '#67BC42',
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
