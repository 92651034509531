import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import CustomTable from '../../../../components/CustomTable';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { dataFormat, rendarImage, separator } from '../../../../util/helper';
import mockData from '../../../../mocks/sellOutDashboard';
import { getDataForSOPAndSRP } from '../../service';
import { useDispatch } from 'react-redux';
import { selloutPriceVsSuggestedPriceTableDataLoader } from '../../store/revenueMap.action';
import Dashboard from '../../../../components/Dashboard';
const SellOutRetailPriceTable: React.FC<{
  globalFilters?;
  selloutVsSuggestedFilters;
  callback;
}> = ({ globalFilters, selloutVsSuggestedFilters, callback }) => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = React.useState(
    selloutVsSuggestedFilters.isMarkUp == 1 ? mockData.sellOutRetailTableHeading : mockData.sellOutRetailMarkDownTableHeading
  );
  const [tableValues, setTableValues] = React.useState([]);
  const [tableSkeleton, setTableSkeleton] = React.useState(true);

  React.useEffect(() => {
    if (selloutVsSuggestedFilters && selloutVsSuggestedFilters.category) {
      dispatch(selloutPriceVsSuggestedPriceTableDataLoader(true));
      setTableSkeleton(true);
      const taxMarkups = getTaxMarkups();
      const payload = {
        ...selloutVsSuggestedFilters,
        taxMarkups,
      };
      callback(payload);
      getTableData(payload);
    }
  }, [selloutVsSuggestedFilters]);

  const getTableData = async (payload) => {
    getDataForSOPAndSRP(payload)
      .then((response) => {
        formatData(response.data);
        dispatch(selloutPriceVsSuggestedPriceTableDataLoader(false));
      })
      .catch(() => {
        dispatch(selloutPriceVsSuggestedPriceTableDataLoader(false));
        setTableSkeleton(false);
      });
  };

  const getTaxMarkups = () => {
    if (selloutVsSuggestedFilters.comparisonLevel === 'category') {
      return (
        selloutVsSuggestedFilters &&
        selloutVsSuggestedFilters.category &&
        selloutVsSuggestedFilters.category.map((x: string) => ({
          taxes: 0.08,
          markup: 0.25,
          productName: x,
          productInfo: x,
          isMarkUP: selloutVsSuggestedFilters.isMarkUp,
        }))
      );
    }
    if (selloutVsSuggestedFilters.comparisonLevel === 'segment') {
      return (
        selloutVsSuggestedFilters &&
        selloutVsSuggestedFilters.segment &&
        selloutVsSuggestedFilters.segment.map((x: string) => ({
          taxes: 0.08,
          markup: 0.25,
          productName: x,
          productInfo: x,
          isMarkUP: selloutVsSuggestedFilters.isMarkUp,
        }))
      );
    } else {
      return (
        selloutVsSuggestedFilters &&
        selloutVsSuggestedFilters.brand &&
        selloutVsSuggestedFilters.brand.map((x: string) => ({
          taxes: 0.08,
          markup: 0.25,
          productName: x,
          productInfo: x,
          isMarkUP: selloutVsSuggestedFilters.isMarkUp,
        }))
      );
    }
  };

  const formatData = (data) => {
    if (_.isArray(data) && data.length > 0) {
      const headings: any =
        selloutVsSuggestedFilters.isMarkUp == 1
          ? [{ title: 'Product' }, { title: 'Taxes' }, { title: 'Mark Up' }, { title: '' }]
          : [{ title: 'Product' }, { title: 'Taxes' }, { title: 'Mark Down' }, { title: '' }];
      let columns = [];
      for (let i = 0; i < data.length; i++) {
        if (columns.length < data[i].priceTrackingProduct.length) {
          columns = data[i].priceTrackingProduct;
        }
      }
      if (columns.length > 0) {
        columns.map((item: any) => {
          headings.push({
            title: dataFormat(item.date, 'MMM') + ' ' + dataFormat(item.date, 'yyyy'),
          });
        });
      }
      const rows: any = [];
      Promise.all(
        data.map(async (item) => {
          item.image = {
            src: await rendarImage(item.productName, globalFilters.country),
            alt: item.productName,
            height: 100,
            width: 100,
            tooltip: item.productInfo,
          };
        })
      ).then(() => {
        data.map(async (item) => {
          let cell1 = {
            name: item.productName,
            productInfo: item.productInfo,
            image: item.image,
            rowspan:
              _.isArray(item.priceTrackingProduct) && item.priceTrackingProduct.length > 0 ? Object.keys(item.priceTrackingProduct[0]).length - 1 : 0,
          };
          const cell2 = [
            {
              editable: true,
              type: 'Text',
              value: item.taxes * 100,
              rowspan: 3,
              key: 'taxes',
            },
            {
              editable: true,
              type: 'Text',
              value: item.markup * 100,
              rowspan: 3,
              key: 'markup',
            },
          ];

          let rowNames: any = [];
          if (_.isArray(item.priceTrackingProduct) && item.priceTrackingProduct.length > 0) {
            rowNames = Object.keys(item.priceTrackingProduct[0]).filter((x) => x !== 'date');
          }

          const row: any = {};
          item.priceTrackingProduct.map((cell) => {
            rowNames.map((key, i) => {
              row[key] = [...(row[key] ? row[key] : []), separator(cell[key], 2)];
            });
          });

          Object.keys(row).map((r, i) => {
            const payload: any = [];
            if (i === 0) {
              payload.push(cell1, ...cell2);
              // payload.push(...cell2)
            }
            rows.push([...payload, replaceString(r), ...row[r]]);
          });
        });
        setTableHeaders(headings);
        setTableValues(rows);
        setTableSkeleton(false);
      });
    }
  };

  const replaceString = (str) => {
    if (str === 'sdv') return 'SDV';
    else if (str === 'srp') return 'SRP';
    else if (str === 'sop') return 'SOP';
    else return str;
  };

  const onCellKeyDown = (payload) => {
    if (payload.event.keyCode === 13) {
      setTableSkeleton(true);
      const result = tableValues.filter((item) => item.find((x) => x.editable));
      let taxMarkups = [];
      result.map((item) => {
        let obj = {};
        item.map((subItem) => {
          if (subItem.name) {
            obj['productName'] = subItem.name;
            obj['productInfo'] = subItem.productInfo;
          }
          if (subItem.editable) obj[subItem.key] = parseFloat(subItem.value) / 100;
        });
        taxMarkups.push(obj);
      });
      const payload = {
        ...selloutVsSuggestedFilters,
        taxMarkups,
      };
      callback(payload);
      getTableData(payload);
    }
  };

  const DataHandler = (payload) => {
    const newData = [...tableValues];
    newData[payload.row][payload.columnName].value = payload.value;
    setTableValues(newData);
  };

  React.useEffect(() => {
    setTableSkeleton(true);
  }, [globalFilters]);
  return (
    <Card>
      <CardContent>
        {tableSkeleton ? (
          <Grid className='m-t-20'>
            <CustomTable heading={mockData.sellOutRetailTableHeading} value={mockData.sellOutRetailTableSkeletonData} showSkeleton={tableSkeleton} />
          </Grid>
        ) : tableValues.length > 0 ? (
          <Dashboard
            title={null}
            showSkeleton={tableSkeleton}
            chartRef={() => {}}
            showActionButton={true}
            actionButtons={{
              mmpw: false,
              screenshot: true,
              editMedia: false,
            }}
            id={null}
          >
            <Grid
              className='m-t-20'
              style={{
                maxWidth: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)',
              }}
            >
              <CustomTable
                heading={tableHeaders}
                value={tableValues}
                callback={DataHandler}
                onCellKeyDown={onCellKeyDown}
                onCellBlur={() => {}}
                isFormatNumber={false}
                showSkeleton={tableSkeleton}
              />
            </Grid>
          </Dashboard>
        ) : (
          <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
            <NoData>{messages.noData}</NoData>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default SellOutRetailPriceTable;
