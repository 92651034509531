import { PRICE_PIANO_MODEL, PRICE_SPECIFIC_MODEL } from './constant';

let initiaState = {
  pricePianoDataLoader: false,
  priceSpecificLoader: false
};

const pianoDataLoader = (state = initiaState, action) => {
  switch (action.type) {
    case PRICE_PIANO_MODEL:
      return {
        ...state,
        pricePianoDataLoader: action.payload,
      };
      case PRICE_SPECIFIC_MODEL:
      return {
        ...state,
        priceSpecificLoader: action.payload,
      };
    default:
      return state;
  }
};

export default pianoDataLoader;
