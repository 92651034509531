import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Box } from '@mui/system';
import _ from 'lodash';
import { TextField, Tooltip } from '@mui/material';

const useStyles = makeStyles(() => ({
  select: {
    width: '146px',
    '& .MuiSelect-select': {
      background: 'none !important', // or 'transparent'
    },
  },
  searchInput: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '2px solid #0000003b',

      },
    },
  },
  searchMenuItem: {
    backgroundColor: '#fff !important',
  },
}));
function getStyles(item, selectOption, theme) {
  return {
    fontWeight: _.isArray(selectOption)
      ? selectOption.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
      : item === selectOption
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}
const AssortmentDropdown: React.FC<{
  data;
  multiple?;
  multipleSelectionMsg?;
  placeholder?;
  defaultOption?;
  keyText?;
  onChange?;
  onClose?;
  disabled?: boolean;
  allOption?: boolean;
  allOptionText?: string;
  defaultSelectAll?: boolean;
  selectionLimit?: number;
  sort?: { enable: boolean; order?: 'asc' | 'desc' };
  maxWidth?;
  search?: { enable: boolean; placeholder?: string; style?: Object };
  onChangeSearchText?;
}> = ({
  data,
  multiple = false,
  multipleSelectionMsg,
  placeholder,
  defaultOption,
  keyText,
  onChange,
  onClose,
  disabled = false,
  allOption = false,
  allOptionText = 'All',
  defaultSelectAll = false,
  selectionLimit,
  sort = { enable: true, order: 'asc' },
  maxWidth = '100%',
  search = { enable: true, placeholder: 'Search...', style: {} },
  onChangeSearchText,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = React.useState<string[] | string>(multiple ? [] : '');
  const [showError, setError] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [originalOptions, setOriginalOptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);
  React.useEffect(() => {
    if (selectionLimit) {
      setError(!(selectedOption.length <= selectionLimit));
    }
  }, [selectedOption]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === 'string' && multiple ? value.split(',') : value;
    const index = _.isArray(val) && val.findIndex((x) => x === allOptionText);
    if (index === -1) {
      if (selectionLimit) {
        if (val.length <= selectionLimit) {
          setSelectedOption(val);
          if (onChange && !multiple) {
            onChange(val);
          }
          setError(false);
        } else {
          setError(true);
        }
      } else {
        setSelectedOption(val);
        if (onChange && !multiple) {
          onChange(val);
        }
      }
    } else if (_.isString(val)) {
      setSelectedOption(val);
      if (onChange && !multiple) {
        onChange([val]);
      }
    }
  };
  const handleClose = () => {
    if (multiple) {
      const withoutEmpty = _.isArray(defaultOption)? defaultOption.filter(d=> d !== 'empty') : [];
      if (_.isArray(selectedOption)) {
        const diff = _.difference(selectedOption, withoutEmpty);
        if ((_.isArray(diff) && diff.length > 0) || selectedOption.length !== withoutEmpty.length) {
          onChange(selectedOption);
        }
      }
    }
    setTimeout(() => {
      setSearchText(null);
      setOptions([...originalOptions]);
    }, 100);
  };
  const onSelectAll = (event) => {
    if (event.target.checked) {
      const allOptions = options.filter(ele => !ele.disable).map((x) => x.value);
      setSelectedOption(allOptions);
      // onChange(allOptions);
    } else {
      setSelectedOption(multiple ? [] : '');
      // onChange(multiple ? [] : '');
    }
  };
  React.useEffect(() => {
    if (defaultOption) {
      if (_.isArray(defaultOption)) {
        if (defaultOption.length === 1 && defaultOption[0] === 'empty') {
          setSelectedOption([]);
        } else {
          setSelectedOption(multiple ? defaultOption : defaultOption[0]);
        }
      } else if (_.isString(defaultOption) && multiple) {
        setSelectedOption([defaultOption]);
      } else if (_.isString(defaultOption)) {
        setSelectedOption(defaultOption);
      }
    }
  }, [defaultOption]);
  React.useEffect(() => {
    if (data && data.length > 0) {
      if (sort && sort.enable) {
        if (data.filter((x) => x.label).length > 0)
          //if there is label key then sort based on label
          data = _.orderBy(data, ['label'], [sort.order]);
        else data = _.orderBy(data, ['value'], [sort.order]);
      }
      setOptions(searchText ? searchOptions(data, searchText) : [...data]);
      setOriginalOptions(data);
      if (defaultSelectAll && selectionLimit > 0) {
        setSelectedOption(data.filter((x, i) => i < selectionLimit).map((x) => x.value));
      } else if (allOption && defaultSelectAll) {
        setSelectedOption(data.map((x) => x.value));
      }
    } else if (data) {
      setOptions(searchText ? searchOptions(data, searchText) : [...data]);
      setOriginalOptions(data);
    }
  }, [data]);
  const onSearch = (e) => {
    const val = e.target.value;
    if (onChangeSearchText) {
      onChangeSearchText(val);
    }
    if (val) {
      setSearchText(val);
      setOptions([...searchOptions(originalOptions, val)]);
    } else {
      setSearchText(null);
      setOptions([...originalOptions]);
    }
  };
  const searchOptions = (data, val) => {
    return data.filter((x) => {
      if (x.label) {
        return x.label.toLowerCase().includes(val.toLowerCase());
      } else {
        return x.value.toLowerCase().includes(val.toLowerCase());
      }
    });
  };
  return (
    <Select
      disabled={disabled}
      multiple={multiple}
      displayEmpty
      value={selectedOption}
      onChange={handleChange}
      onClose={handleClose}
      input={<OutlinedInput />}
      MenuProps={{ autoFocus: false}}
      renderValue={(selected) => {
        if (_.isArray(selected)) {
          if (selected.length > 1) {
            return (
              <span>
                {/* {allOption && selectedOption.length === options.length ? allOptionText : multipleSelectionMsg ? multipleSelectionMsg : selectedOption.join(',') } */}
                {allOption ? selectedOption.join(',') : '' }
              </span>
            );
          } else if (selected.length === 0 && placeholder) {
            return <span style={{ color: '#a6a6a6' }}>{placeholder}</span>;
          }
          if (multiple) {
            const result = options.filter((x) => selected.findIndex((y) => y === x.value) !== -1).map((x) => x.label || x.value);
            return result.join(', ');
          }
        } else if(selected && selected !== 'Empty'){
          const result = options.find((x) => x.value === selected);
          if (result) return result.label || result.value;
          if (disabled) return <span>{selected}</span>
        }else{
          return <span style={{ color: '#a6a6a6' }}>{placeholder}</span>;
        }
      }}
      className={classes.select}
      style={{ width:'146px', height:'32px' }}
      sx={{
        "& .css-vf55j8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input" : {
          padding : "0 14px"
        }
      }}
    >
      {search && search.enable && originalOptions.length > 0 ? (
        <MenuItem classes={{ focusVisible: classes.searchMenuItem }} onKeyDown={(e) => e.stopPropagation()}>
          <TextField InputProps={{ autoComplete: 'off' }}
            id='dropdown-search'
            className={classes.searchInput}
            placeholder={search.placeholder}
            type='text'
            variant='outlined'
            onChange={onSearch}
            onClick={(e) => e.stopPropagation()}
            autoComplete='off'
            value={searchText}
            style={{ width: '100%', ...search.style }}
          />
        </MenuItem>
      ) : null}
      <MenuItem style={{ display: 'none' }} value='' />
      {!allOption && placeholder && (
        <MenuItem disabled value=''>
          <em>{placeholder}</em>
        </MenuItem>
      )}
      {showError && <span style={{ color: 'red', padding: '0 10px' }}>{`Maximum ${selectionLimit} Selections Allowed`}</span>}
      {allOption && options.length > 0 && !searchText && (
        <MenuItem value={allOptionText} style={getStyles(allOptionText, selectedOption, theme)}>
          <Box className='ellipsis' style={{ maxWidth: maxWidth && maxWidth }}>
            <Checkbox style={{color:'#25d4cf'}} disabled={options.length === 0} checked={selectedOption.length === options.filter(ele => !ele.disable).length} onClick={onSelectAll} />
            <span>{allOptionText}</span>
          </Box>
        </MenuItem>
      )}
      {options.length > 0 &&
        options.map((item) => (
          <MenuItem
            key={`${keyText}-${item.id}-${item.value}`}
            value={item.value}
            style={getStyles(item.value, selectedOption, theme)}
            disabled={item?.disable || false}
          >
            {multiple ? (
              <Box className='ellipsis' style={{ maxWidth }}>
                <Checkbox style={{color:'#25d4cf'}} checked={item?.disable ? false : selectedOption.indexOf(item.value) > -1} disabled={item?.disable || false} />
                <Tooltip title={item.label || item.value} placement='left-end'>
                  <span>{item.label || item.value}</span>
                </Tooltip>
              </Box>
            ) : (
              <Box className='ellipsis' style={{ maxWidth }}>
                <Tooltip title={item.label || item.value} placement='left-end'>
                  <span>{item.label || item.value}</span>
                </Tooltip>
              </Box>
            )}
          </MenuItem>
        ))}
    </Select>
  );
};
export default AssortmentDropdown;
