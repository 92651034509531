import { Box, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameBox: { background: 'gray', border: '1px solid #efefef', color: '#fff' },
}));
const ResultTable: React.FC<{ mainHeaders; heading?; subHeading?; data?; showHeader?: boolean }> = ({
  mainHeaders,
  heading,
  data,
  subHeading,
  showHeader = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <Table>
      {mainHeaders.length && heading?.length && subHeading.length && (
        <TableHead>
          <TableRow>
            {mainHeaders &&
              mainHeaders.map((tHead) => {
                return (
                  <>
                    {showHeader ? (
                      <TableCell
                        style={
                          _.isString(tHead.label) && tHead.label.length > 0
                            ? {
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: '#25d4cf',
                                padding: 5,
                              }
                            : { border: 'none' }
                        }
                        className='b-color'
                        align='center'
                        colSpan={tHead.config.colspan}
                        rowSpan={tHead.config.rowspan}
                      >
                        <Grid container display='flex' justifyContent='center' alignItems='center'>
                          <div style={{ width: tHead.config.width }}>{tHead.label}</div>
                        </Grid>
                      </TableCell>
                    ) : null}
                  </>
                );
              })}
          </TableRow>
          <TableRow>
            {heading &&
              heading.map((mHead) => {
                return (
                  <>
                    {showHeader ? (
                      <TableCell
                        style={
                          _.isString(mHead.label) && mHead.label.length > 0
                            ? {
                                color: 'white',
                                textAlign: 'center',
                                backgroundColor: '#25d4cf',
                                padding: 5,
                              }
                            : { border: 'none' }
                        }
                        className='b-color'
                        align='center'
                        colSpan={mHead.config.colspan}
                        rowSpan={mHead.config.rowspan}
                      >
                        <Grid container display='flex' justifyContent='center' alignItems='center'>
                          <div style={{ width: mHead.config.width }}>{mHead.label}</div>
                        </Grid>
                      </TableCell>
                    ) : null}
                  </>
                );
              })}
          </TableRow>
          <TableRow>
            {subHeading &&
              subHeading.map((sHead) =>
                showHeader ? (
                  <TableCell
                    className='b-color'
                    align='center'
                    style={
                      _.isString(sHead.label) && sHead.label.length > 0
                        ? { backgroundColor: '#0093CD', color: 'white', padding: 5 }
                        : { border: 'none', padding: 5 }
                    }
                  >
                    <Grid container display='flex' justifyContent='center' alignItems='center'>
                      <div style={{ width: sHead.config.width }}>{sHead.label}</div>
                    </Grid>
                  </TableCell>
                ) : null
              )}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {
            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div style={{ width: 50, paddingTop: 10, paddingBottom: 5 }}>
                <Grid container display='flex' justifyContent='center' alignItems='center'>
                  {data[data.length - 1][0].value ? (
                    <img style={{ height: 40, width: 40 }} src={data[data.length - 1][0].value} />
                  ) : (
                    <Grid className={classes.nameBox} style={{ height: 40, width: 40, display: 'table' }}>
                      <Box style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                        <span style={{ fontSize: 6 }}>{data[data.length - 1][1].value}</span>
                      </Box>
                    </Grid>
                  )}
                  <IconButton aria-label='expand row' size='small' style={{ padding: 0, margin: 0 }} onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>
              </div>
            </TableCell>
          }
          {/* parent */}
          {data[data.length - 1].map(
            (item, i) =>
              i !== 0 && (
                <TableCell align={_.isString(item.value) && i === 1 ? 'left' : 'center'} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Grid container display='flex' justifyContent='center' alignItems='center'>
                    <div style={{ width: _.isString(item.value) && i === 1 ? '160px' : '8vw' }}>
                      {!open &&
                        (item.config.showColorDot ? (
                          <Grid container display='flex' justifyContent='center' alignItems='center'>
                            <span
                              style={{
                                height: 10,
                                width: 10,
                                background: item.value >= 100 ? 'green' : item.value <= 99 && item.value > 90 ? 'yellow' : 'red',
                                borderRadius: '50%',
                              }}
                            ></span>
                            <span>&nbsp;&nbsp;{item.config.showPercent ? `${item.value}%` : item.value}</span>
                          </Grid>
                        ) : (
                          <span>{item.config.showPercent ? `${item.value}%` : item.value}</span>
                        ))}
                    </div>
                  </Grid>
                </TableCell>
              )
          )}
        </TableRow>
        {/* child */}
        {data.map((item, i) => (
          <TableRow>
            <TableCell style={i === data.length - 1 ? { padding: 0, background: '#e0e0e0' } : { padding: 0 }} colSpan={item.length + 2}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <TableRow style={{ borderTop: '2px solid #e0e0e0' }}>
                  {item.map((recordObj, j) =>
                    j === 0 ? (
                      <TableCell align={_.isString(item) && i === 0 ? 'left' : 'center'}>
                        <Grid container display='flex' justifyContent='center' alignItems='center'>
                          <div style={{ width: 50 }}>
                            {recordObj.value ? (
                              <img style={{ height: 40, width: 40 }} src={recordObj.value} />
                            ) : (
                              <Grid container display='flex' justifyContent='center' alignItems='center' className={'ellipsis'}>
                                <Grid className={classes.nameBox} style={{ height: 40, width: 40, display: 'table' }}>
                                  <Box style={{ verticalAlign: 'middle', textAlign: 'center', width: 40 }} className={'ellipsis'}>
                                    <span style={{ fontSize: 6 }}>{item[1].value}</span>
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </Grid>
                      </TableCell>
                    ) : (
                      <TableCell align={_.isString(recordObj.value) && j === 1 ? 'left' : 'center'} >
                        <Grid container display='flex' justifyContent='center' alignItems='center' className={'ellipsis'}>
                          <div style={{ width: _.isString(recordObj.value) && j === 1 ? '160px' : '8vw' }} className={j !== 1? 'ellipsis' : ''}>
                            {recordObj.config.showColorDot ? (
                              <Grid container display='flex' justifyContent='center' alignItems='center'>
                                <span
                                  style={{
                                    height: 10,
                                    width: 10,
                                    background: recordObj.value >= 100 ? 'green' : recordObj.value <= 99 && recordObj.value > 90 ? 'yellow' : 'red',
                                    borderRadius: '50%',
                                  }}
                                ></span>
                                <span style={i === data.length - 1 ? { fontWeight: 'bolder',color:'black' } : {}}>
                                  &nbsp;&nbsp;{recordObj.config.showPercent ? `${recordObj.value}%` : recordObj.value}
                                </span>
                              </Grid>
                            ) : (
                              <Tooltip title={recordObj.config.showPercent ? `${recordObj.value}%` : recordObj.value} placement='top'>
                                <span style={i === data.length - 1 ? { fontWeight: 'bolder',color:'black' } : j === 1? {whiteSpace: 'break-spaces'} : {}}>
                                  {recordObj.config.showPercent ? `${recordObj.value}%` : recordObj.value}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </Grid>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default ResultTable;
