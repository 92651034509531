import { Box, Card, CardContent, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../../../../components/Dashboard";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { fetchPriceElasticityDonutData } from "../../service";
import { donutChartConfig } from "./DonutConfig";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CustomSwitch } from "../../HistoricPriceAndCrossPriceElasticity.Styled";
import _ from "lodash";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import HighchartContainer from "../../../../components/HighchartContainer/HighchartContainer";

const SourceOfVolumeByYear: React.FC<{ filters }> = ({ filters }) => {
  const chartRef = useRef(null);
  const [skeleton, setSkeleton] = useState(true);
  const [isShowDetail, setShowDetail] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>([]);

  useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      fetchPriceElasticityDonutData({ ...filters })
        .then((response) => {
          if (filters && filters.country) {
            setSkeleton(false);
            formatChartData({ ...response.data[0] });
          }
        })
        .catch((e) => {
          setSkeleton(false);
        });
    }
  }, [filters]);
  const formatChartData = (response) => {
    const payload = response.data.map((item, i) => ({
      name: item.name,
      y: item.value,
    }));
    setChartData(response.data || []);
    if (
      chartRef &&
      chartRef.current &&
      chartRef.current.chart &&
      payload?.length > 0
    ) {
      const chart = chartRef.current.chart;
      chart.setTitle({
        text: response.label,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      });
      chart.series[0].setData(payload);
      showDetail(chart);
    }
  };

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      showDetail(chart);
    }
  }, [isShowDetail]);

  const showDetail = (chart) => {
    chart.update({
      plotOptions: {
        ...donutChartConfig.plotOptions,
        pie: {
          ...donutChartConfig.plotOptions.pie,
          dataLabels: {
            enabled: isShowDetail,
            format: "{point.percentage:.2f} %",
            // distance: -30,
          },
        },
      },
    });
  };

  return (
    <Card className="m-b-20">
      <CardContent>
        <Dashboard
          title="Variable Impact On Volume by Quarter"
          id="historic-price-track-source-of-volume"
          chartRef={chartRef}
          showSkeleton={skeleton}
          showActionButton={_.isArray(chartData) && chartData.length > 0}
        >
          {!skeleton ? (
            <>
              {_.isArray(chartData) && chartData.length ? (
                <>
                  <Box display="flex" alignItems="center">
                    <Title style={{ whiteSpace: "nowrap" }}>
                      Show Detailed Data
                    </Title>
                    <CustomSwitch
                      onChange={(e) => setShowDetail(e.target.checked)}
                      checked={isShowDetail}
                    />
                  </Box>
                  <Box>
                    <HighchartContainer
                      chartRef={chartRef}
                      id={"Variable Impact On Volume by Quarter"}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={donutChartConfig}
                        ref={chartRef}
                      />
                    </HighchartContainer>
                  </Box>
                </>
              ) : (
                <Box
                  style={{ height: 400 }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NoData>{messages.noData}</NoData>
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Skeleton height={400} variant="rectangular"></Skeleton>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};
export default SourceOfVolumeByYear;
