import { API } from "../../util/helper"
export const fetchPriceLadderSrpData = async (data) => {
    try { 
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}PriceLadder/PriceLadderSRP`,
        data
      );
    } catch (e) {
      return e;
    }
};

export const fetchPriceLadderMainCompetitorData = async (data) => {
  try { 
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}PriceLadder/PriceLadderSRP`,
        data
      );
  } catch (e) {
    return e;
  }
}; 