import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  & input {
    box-sizing: border-box;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0; 
    width: 65px;
    height: 25px;
    background: #F0F0F0;
    font-color: black;
    font-size: 10px;
  }
  
  & .MuiInputAdornment-positionEnd {
    margin-right: 0;
  }
`;