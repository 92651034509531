import { SIMULATION_DATA, SIMULATION_VALUE, CORRELATION_CHART_DATA, CORRELATION_TABLE_DATA, OPTIMIZATION_CHART_DATA, G5_CHART_DATA, MULTIPLE_API_DATA } from "./constants";

export const correlationChartDataLoader = (payload) => {
  return {
    type: CORRELATION_CHART_DATA,
    payload,
  };
};

export const correlationTableDataLoader = (payload) => {
  return {
    type: CORRELATION_TABLE_DATA,
    payload,
  };
};

export const optimizationChartDataLoader = (payload) => {
  return {
    type: OPTIMIZATION_CHART_DATA,
    payload,
  };
};

export const G5ChartDataLoader = (payload) => {
  return {
    type: G5_CHART_DATA,
    payload,
  };
};

export const simulationDataLoader = (payload) => {
  return {
    type: SIMULATION_DATA,
    payload,
  };
};

export const simulationValueLoader = (payload) => {
  return {
    type: SIMULATION_VALUE,
    payload,
  };
};

export const multipleAPIDataLoader = (payload) => {
  return {
    type: MULTIPLE_API_DATA,
    payload,
  };
};