import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import FilterAccordion from '../../../../components/Filters/Filters';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import ProductFilters from '../../../../components/ProductFilters';
import ProductFiltersV2 from '../../../../components/ProductFilters-v2';
import MeasureFilter from '../MeasureFilters';
import mockData from '../../../../mocks/sellOutDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { productInitialState } from '../../../../store/reducers/productFilter.reducer';
import _ from 'lodash';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import Indicator from '../../../../components/Loader';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { initialState, dateRange } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import { measureFilter } from '../PriceTrackingAPIFilters/config';
import { dataFormat, enableCompetitorFilter } from '../../../../util/helper';

import { clearProductFilters, fetchProductFiltersData, onChangeProductOption } from '../../../../store/actions/productFilter.action';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import { CustomDropDownTitle } from '../../../../components/ProductFilters-v3/ProductFilters-v3';
import Dropdown from '../../../../components/Dropdown';

const competitorFilterInitialState = {
  data: null,
  loading: false,
  error: null,
  selectedFilters: {
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  },
  disabledFilters: {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  },
};

const productFilterInitialState = {
  data: null,
  loading: false,
  error: null,
  selectedFilters: {
    category: null,
    segment: null,
    brand: null,
    subBrand: null,
    packSize: null,
  },
  disabledFilters: {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  },
};
const TopFilters: React.FC<{
  data;
  callback;
  showSkeleton?: boolean;
  commonLoader;
  defaultFilters?;
}> = ({ data, callback, showSkeleton, commonLoader, defaultFilters }) => {
  const dispatch = useDispatch();
  const geoFilter = useSelector((state: any) => state.geoFilter);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);
  const [clearFilter, setClearFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(defaultFilters ? defaultFilters : initialState);
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    variable1: data.measureFilter.variable1.defaultOption.value || undefined,
    somMeasure: data.measureFilter.SOM.defaultOption.value || undefined,
    somGroup: data.measureFilter.SOMVarConfig.defaultOption.value || undefined,
    pricePer: data.measureFilter.selloutPrices.defaultOption.value || undefined,
  });
  // const productFilter = useSelector((state: any) => state.productFilter);
  // const selectedProductFilters = productFilter && productFilter.selectedFilters;
  // const disabledProductFilters = (productFilter &&
  //   productFilter.disabledFilters) || {
  //   ...productInitialState.disabledFilters,
  // };
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] = useState(competitorFilterInitialState.selectedFilters);
  const [selectedProductFilters, setSelectedProductFilters] = useState(productFilterInitialState.selectedFilters);
  // const onSelectProductFilter = (payload) => {
  //   if (payload.type === "PRODUCT_FILTER") {
  //     const key = Object.keys(payload.filters)[0];
  //     const val = payload.filters[key];
  //     dispatch(onChangeProductOption(key, val));
  //     const requestBody = {
  //       country: selectedGeoFilters.country,
  //       ...selectedProductFilters,
  //       [key]: val,
  //     };
  //     if (
  //       key !== "packSize" &&
  //       ((_.isArray(val) && val.length > 0) ||
  //         (_.isString(val) && val !== null))
  //     ) {
  //       dispatch(fetchProductFiltersData(requestBody, data.productFilter));
  //     }
  //   } else if(payload.type === "COMPETITOR_FILTER") {
  //     setSelectedCompetitorFilters(payload.filters)
  //   }
  // };

  const onSelectFilters = (payload) => {
    if (payload.type === 'PRODUCT_FILTER') {
      setSelectedProductFilters(payload.filters);
    } else if (payload.type === 'COMPETITOR_FILTER') {
      setSelectedCompetitorFilters(payload.filters);
    }
  };
  const disableApplyFilter = () => {
    const flag: boolean =
      (selectedMeasureFilters.variable1
        ? selectedMeasureFilters.variable1 === 'SOM'
          ? selectedMeasureFilters.somGroup === '' || selectedMeasureFilters.somMeasure === ''
          : selectedMeasureFilters.variable1 === 'API'
          ? selectedMeasureFilters.pricePer === ''
          : false
        : true) ||
      !selectedGeoFilters.country ||
      selectedGeoFilters.country === '' ||
      selectedGeoFilters.country === null ||
      !selectedGeoFilters.geoLevel2 ||
      selectedGeoFilters.geoLevel2 === '' ||
      selectedGeoFilters.geoLevel2 === null;
    return flag;
  };
  const onApplyFilter = () => {
    setShowLoader(true);
    const measures = {
      ...selectedMeasureFilters,
    };

    const payload = {
      type: 'FILTER_APPLIED',
      filters: {
        ...measures,
        ...selectedGeoFilters,
        ...selectedProductFilters,
        //competitor payload
        anchorCategory: selectedCompetitorFilters.category,
        anchorSegment: selectedCompetitorFilters.segment,
        anchorBrand: selectedCompetitorFilters.brand,
        anchorSubBrand: selectedCompetitorFilters.subBrand,
        anchorPackSize: selectedCompetitorFilters.packSize,
        date: selectedDate,
      },
    };
    callback(payload);
  };

  const clearFilters = () => {
    setClearFilter(true);
    dispatch(clearProductFilters());
    setSelectedGeoFilters({ ...initialState });
    setSelectedCompetitorFilters({
      ...competitorFilterInitialState.selectedFilters,
    });
    setMeasureFilter({
      variable1: data.measureFilter.variable1.defaultOption.value || undefined,
      somMeasure: data.measureFilter.SOM.defaultOption.value || undefined,
      somGroup: data.measureFilter.SOMVarConfig.defaultOption.value || undefined,
      pricePer: data.measureFilter.selloutPrices.defaultOption.value || undefined,
    });
    data.measureFilter.variable1.options.map((item) => {
      item.disabled = false;
    });
  };
  useEffect(() => {
    if (selectedGeoFilters && selectedGeoFilters.country !== null) {
      setClearFilter(false);
      // dispatch(clearProductFilters());
      // Object.keys(data.productFilter).map(key => data.productFilter[key].options = []); //Clear the product data
      // dispatch(
      //   fetchProductFiltersData(
      //     {
      //       country: selectedGeoFilters.country,
      //       ...productInitialState.selectedFilters,
      //     },
      //     data.productFilter
      //   )
      // );
    }
  }, [selectedGeoFilters.country]);

  // useEffect(() => {
  //   if (defaultFilters && defaultFilters.country) {
  //     setSelectedGeoFilters({
  //       country: defaultFilters.country,
  //       geoLevel: defaultFilters.geoLevel,
  //       geoLevel2: defaultFilters.geoLevel2,
  //       channel: defaultFilters.channel,
  //       periodView: defaultFilters.periodView,
  //     });
  //   }
  // }, [defaultFilters]);

  // useEffect(() => {
  //     if (defaultFilters) {
  //       const measureFilters = {
  //         variable1: defaultFilters?.variable1 || data.measureFilter.variable1.defaultOption.value,
  //         somMeasure: defaultFilters?.SOM|| data.measureFilter.SOM.defaultOption.value,
  //         somGroup: defaultFilters?.SOMVarConfig || data.measureFilter.SOMVarConfig.defaultOption.value,
  //         pricePer: defaultFilters?.selloutPrices || data.measureFilter.selloutPrices.defaultOption.value
  //         }
  //       setMeasureFilter({...measureFilters});
  //       }
  //   },[defaultFilters]);

  //   useEffect(() => {
  //       if (selectedCompetitorFilters && selectedCompetitorFilters.category) {
  //         setSelectedCompetitorFilters({
  //           category: selectedCompetitorFilters.category,
  //           segment: selectedCompetitorFilters.segment,
  //           brand: selectedCompetitorFilters.brand,
  //           subBrand: selectedCompetitorFilters.subBrand,
  //           packSize: selectedCompetitorFilters.packSize,
  //         });
  //       }
  //     }, [selectedCompetitorFilters]);

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        show={
          commonLoader && (showLoader || geoFilter.countriesLoading || geoFilter.geoLevelLoading)
          //|| productFilter.loading
        }
      />
      <CardContent>
        {!showSkeleton ? (
          <>
            <FilterAccordion title='Geo-Filters'>
              <GeoFiltersV2
                key='sell-out-top-filter'
                data={geoFilterV2Config}
                onSelectFilters={setSelectedGeoFilters}
                apiPath='sellout'
                showLoader={setShowLoader}
                defaultFilters={defaultFilters}
                defaultDate={defaultFilters?.date ? defaultFilters?.date.split('-') : null}
                onChangeDate={(dt) => {
                  setClearFilter(false);
                  setSelectedDate(dt);
                }}
                clearFilter={clearFilter}
              />
            </FilterAccordion>
            {/* <FilterAccordion title="Product Filters">
              <ProductFiltersV2
                hideAnchorFilter={true}
                onChange={onSelectFilters}
                data={mockData.productFilter}
                // defaultProductFilters={selectedProductFilters}
                onChangeLoader={setShowLoader}
                selectedGeoFilters={selectedGeoFilters}
                defaultProductFilters={defaultFilters}
                defaultCompetitorFilters={defaultFilters}
                clearFilter={clearFilter}
              />
            </FilterAccordion> */}
            <FilterAccordion title='Product Filters'>
              <ProductFiltersV3
                onChange={(data) => setSelectedProductFilters(data)}
                data={mockData.productFilter}
                onChangeLoader={setShowLoader}
                selectedGeoFilters={selectedGeoFilters.country && selectedGeoFilters.geoLevel2 ? selectedGeoFilters : {}}
                clearFilter={clearFilter}
                apiURL='filter/products'
                defaultFilters={
                  Object.keys(defaultFilters).length > 0
                    ? {
                        category: defaultFilters.category,
                        segment: defaultFilters.segment,
                        brand: defaultFilters.brand,
                        subBrand: defaultFilters.subBrand,
                        packSize: defaultFilters.packSize,
                      }
                    : null
                }
              />
              <ProductFiltersV3
                onChange={setSelectedCompetitorFilters}
                data={mockData.competitorFilter}
                onChangeLoader={setShowLoader}
                selectedGeoFilters={enableCompetitorFilter(selectedGeoFilters) && selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}}
                clearFilter={clearFilter}
                defaultFilters={
                  Object.keys(defaultFilters).length > 0
                    ? {
                        category: defaultFilters.anchorCategory,
                        segment: defaultFilters.anchorSegment,
                        brand: defaultFilters.anchorBrand,
                        subBrand: defaultFilters.anchorSubBrand,
                        packSize: defaultFilters.anchorPackSize,
                      }
                    : null
                }
                apiURL='dashboards/ProductFilter'
                params={{ productFilterFlag: 'competitor' }}
              />
            </FilterAccordion>
            <FilterAccordion title='Measure Filters'>
              <MeasureFilter
                data={data.measureFilter}
                defaultFilters={defaultFilters}
                callback={(e) => setMeasureFilter(e)}
                selectedMeasureFilters={selectedMeasureFilters}
                selectedCompetitorFilters={selectedCompetitorFilters}
              />
            </FilterAccordion>
            <Grid className='p-l-16'>
              <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilters}>
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <FilterAccordion showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} />
                    <Skeleton variant='rectangular' width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </FilterAccordion>
            {/* <FilterAccordion showSkeleton></FilterAccordion> */}
            <Grid className='m-b-20'>
              <FilterAccordion showSkeleton>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </FilterAccordion>
            </Grid>
            <Grid className='p-l-16' container spacing={2}>
              <Skeleton variant='rectangular' className='m-r-20' width={99} height={45} />
              <Skeleton variant='rectangular' className='m-r-20' width={110} height={45} />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TopFilters;
