const measuresFilter = {
  somUnit:'$',
  somValueType: {
    title: "SOM",
    options: [
      { id: "1", label: "Sales", value: "sales" },
      { id: "2", label: "Volume", value: "volume" },
      { id: "3", label: "Units", value: "units" },
    ],
    defaultOption: { id: "1", value: "sales" },
    showRadioButton: true,
  },
  somValueBy: {
    title: "",
    options: [
      { id: "1", label: "Category", value: "category" },
      { id: "2", label: "Segment", value: "segment" }, 
    ],
    defaultOption: { id: "1", value: "category" },
    showRadioButton: true,
  },
  somValueByCatOnly: {
    title: "",
    options: [
      { id: "1", label: "Category", value: "category" },
      { id: "2", label: "Segment", value: "segment", disabled: true }, 
    ],
    defaultOption: { id: "1", value: "category" },
    showRadioButton: true,
  },
  
  apiFilter:{
    title:"API",
    options: [
      { id: "1", label: "KGs (in `000s)", value: "kgs" },
      { id: "2", label: "Units (in `000s)", value: "units"}
    ],
    defaultOption: { id: "1", value: "kgs" }, 
    showRadioButton: true,
  },
  
  measuresFilterSOP : {
    title: "SOP",
    options: [
      {id : "1", label:"Average of Period Selected", value:"avg"},
      {id : "2", label:"Minimum of Period Selected", value:"min"},
      {id : "3", label:"Maximum of Period Selected", value:"max"},
      {id : "4", label:"Average of Last 3 Months", value:"avglast3month"},
    ],
    defaultOption: { id: "1", value: "avg" },
    showRadioButton: true,
  }
};  

const productFilterBy ={
    comparisonLevel: {
        title: "Comparison Level",
        options: [
          { id: "1", label: "Brand Size", value: "brandsize" }, 
          { id: "2", label: "Sub-brand Size", value: "subbrandsize"}, 
        ],
        optionsNonMexico: [
          { id: "1", label: "Brand Size", value: "brandsize" },  
        ],
        defaultOption: { id: "1", value: "brandsize" },
        showRadioButton: true, 
    },

    priceLadderBy: {
        title: "Price Ladder By",
        options: [
          { id: "1", label: "Suggested Retail Price", value: "suggestedretailprice" }, 
          { id: "2", label: "Main Competitor", value: "maincompetitor" }, 
        ],
        defaultOption: { id: "1", value: "suggestedretailprice" }, 
        showRadioButton: true,
    },
};
const productFilter = { 
  category: {
    title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
    defaultSelectAll: false,
    display: true,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
    display: true,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
    display: true,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutationComputation",
    defaultSelectAll: false,
    display: true,
  },
  permutationComputation: {
    title: `Permutation`,
    options: [],
    placeholder: "Select",
    all: true,
    defaultSelectAll: false,
    multiple: true,
    display: true,
    last: true,
  }, 
};
const productFilterSubBrand = { 
  category: {
    title: `Category <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    children: "segment",
    defaultSelectAll: false,
    display: true,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
    display: true,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
    defaultSelectAll: false,
    display: true,
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
    display: true,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(PepsiCo)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutationComputation",
    defaultSelectAll: false,
    display: true,
  },
  permutationComputation: {
    title: `Permutation`,
    options: [],
    placeholder: "Select",
    all: true,
    defaultSelectAll: false,
    multiple: true,
    display: true,
    last: true,
  }, 
};
const competitorFilter = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    defaultSelectAll: false,
    children: "category",
    display: true,
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
    defaultSelectAll: false,
    display: true,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    display: true,
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
    display: true,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutationComputation",
    defaultSelectAll: false, 
    display: true,
  },
  permutationComputation: {
    title: `Permutation`,
    options: [],
    placeholder: "Select",
    all: true,
    defaultSelectAll: false,
    multiple: true,
    display: true,
    last: true,
  }, 
}; 

const competitorFilterSubBrand = {
  vendor: {
    title: `Vendor <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    first: true,
    defaultSelectAll: false,
    children: "category",
    display: true,
  },
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "segment",
    defaultSelectAll: false,
    display: true,
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    display: true,
    all: true,
    multiple: true,
    children: "brand",
    defaultSelectAll: false,
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "subBrand",
    defaultSelectAll: false,
    display: true,
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
    display: true,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutationComputation",
    defaultSelectAll: false, 
    display: true,
  },
  permutationComputation: {
    title: `Permutation`,
    options: [],
    placeholder: "Select",
    all: true,
    defaultSelectAll: false,
    multiple: true,
    display: true,
    last: true,
  }, 
}; 
 
export const periodView = {
  title: "Period View",
  options: [
    { label: "NA", value: "na" },
    { label: "Full Year", value: "FullYear" },
    { label: "Year to Date", value: "YearToDate" },
    { id: "3", label: "Rolling Year", value: "RollingYear" },
  ],
  placeholder: "Select",
}; 
 
 

export const permutationDefaultOptions = {
  list:[]
} 

export const currencySymbols = {
  'ARGENTINA' : '$',
  'BRAZIL' : 'R$',
  'CHILE' : '$',
  'COLOMBIA' : '$',
  'GUATEMALA' : '$Q',
  'MEXICO' : '$'
}
const exportObject = {
  productFilter,
  productFilterSubBrand,
  productFilterBy,
  competitorFilter,
  competitorFilterSubBrand,
  measuresFilter,  
  permutationDefaultOptions, 
  currencySymbols
};
export default exportObject;
