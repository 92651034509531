import { API } from "../../util/helper";

export const fetchGeoXrefColumns = async () => {
  try {
    return await API.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}portal/GetGeoXrefColumns`
    );
  } catch (e) {
    return e;
  }
};

export const fetchGeoXrefTableData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}portal/GetGeoXref`,
      data
    );
  } catch (e) {
    return e;
  }
};
