import { PRICE_LADDER_MODEL } from './constant';

let initiaState = {
  priceLadderDataLoader: false, 
};

const pianoDataLoader = (state = initiaState, action) => {
  switch (action.type) {
    case PRICE_LADDER_MODEL:
      return {
        ...state,
        priceLadderDataLoader: action.payload,
      }; 
    default:
      return state;
  }
};

export default pianoDataLoader;
