import _ from 'lodash';
import { formatNumber } from '../../util/helper';

const chartConfig = {
  chart: {
    backgroundColor: 'transparent',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      }
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      }
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: '#FFFFFF',
        lineWidth: 1,
        lineColor: null,
        symbol: 'circle',
      },
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: '#F5B7D6',
      data: [],
      type: 'area',
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      color: '#0362ab',
      data: [],
      type: 'column',
      yAxis: 1,
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export {
  chartConfig
};