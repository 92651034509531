import { API } from '../../util/helper';

export const getKpiData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}usagereport/getkpidata`, payload);
  } catch (e) {
    return e;
  }
};
export const getReports = async (payload, endPoint) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${endPoint}`, payload);
  } catch (e) {
    return e;
  }
};

export const getUsers = async (payload) => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}users/getallusers?country=${payload}`);
  } catch (e) {
    return e;
  }
};
