import { Box, Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import { LegendBox } from "../../../HistoricPriceAndCrossPriceElasticity.Styled";
import HighchartContainer from "../../../../../components/HighchartContainer";

Highcharts.setOptions({
  lang: {
    numericSymbols: ["K", "M", "B", "T"],
  },
});
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const Increase = "#598ee3"; //Highcharts.getOptions().colors[2];
const Decrease = "#d41165"; // Highcharts.getOptions().colors[3];
const Total = "gray";

const WaterFallChart: React.FC<{
  chartRef;
  data;
  chartConfig;
  id;
}> = ({ chartRef, data, chartConfig, id }) => {
  React.useEffect(() => {
    if (
      chartRef &&
      chartRef.current &&
      chartRef.current.chart &&
      data?.length > 0
    ) {
      const chart = chartRef.current.chart;
      while (chart.series.length) {
        chart.series[0].remove();
      }
      let chartData: any = [];
      data.map((item, i) => {
        if (i === 0 || i === data.length - 1) {
          chartData.push({
            name: item.name,
            y: item.value,
            color: Total,
            isSum: i === data.length - 1,
          });
        } else {
          chartData.push({
            name: item.name,
            y: item.value,
            color: item.value < 0 ? Decrease : Increase,
          });
        }
      });
      chart.addSeries(
        { upColor: Increase, color: Decrease, data: [...chartData] },
        false
      );
      chart.redraw();
    }
  }, [data]);

  return (
    <>
      <Grid
        container
        spacing={2}
        className="m-b-20 m-t-20"
        sx={{ fontSize: 12 }}
        display="flex"
        justifyContent="center"
      >
        <Grid item style={{ display: "flex" }}>
          <LegendBox bgcolor={Increase} />
          <span style={{ marginLeft: 3 }}>Increase</span>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <LegendBox bgcolor={Decrease} />
          <span style={{ marginLeft: 3 }}>Decrease</span>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <LegendBox bgcolor={Total} />
          <span style={{ marginLeft: 3 }}>Total</span>
        </Grid>
      </Grid>
      <HighchartContainer chartRef={chartRef} id={id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartConfig}
          ref={chartRef}
        />
      </HighchartContainer>
    </>
  );
};

export default WaterFallChart;
