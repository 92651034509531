export const elasticityRadio = {
  elasticRadioButton: [
    {
      id: "1",
      label: "Sell Out",
      value: "sellOut",
    },
    {
      id: "2",
      label: "INASE",
      value: "inase",
      disabled: true,
    },
  ],
  defaultOption: { id: "1", value: "sellOut" },
  showRadioButton: true,
};
const productFilter = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    first: true,
    children: "segment",
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "brand",
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "packSize",
    defaultSelectAll: false,
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    children: "permutation",
    defaultSelectAll: false,
  },
  permutation: {
    title: `Permutation Computation`,
    options: [],
    placeholder: "Select",
    all: true,
    defaultSelectAll: false,
    multiple: true,
    last: true,
  },
};
const measuresFilter = {
  monthRange: {
    title: "Select Range of Month",
    options: [
      { id: "1", label: "3 Months", value: "3" },
      { id: "2", label: "6 Months", value: "6" },
      { id: "3", label: "12 Months", value: "12" },
      { id: "4", label: "Last Year", value: "Last Year" },
    ],
    defaultOption: { id: "1", value: "3" },
    showRadioButton: true,
  },
  priceElasticityCurve: {
    title: "Price Elasticity Curve",
    options: [
      { id: "1", label: "No Curve", value: "No Curve" },
      { id: "2", label: "High", value: "High" },
      { id: "3", label: "Medium", value: "Medium" },
      { id: "4", label: "Low", value: "Low" },
    ],
    defaultOption: { id: "1", value: "No Curve" },
    showRadioButton: true,
  },
  gramsElasticityCurve: {
    title: "Grams Elasticity Curve",
    options: [
      { id: "1", label: "No Curve", value: "No Curve" },
      { id: "2", label: "High", value: "High" },
      { id: "3", label: "Medium", value: "Medium" },
      { id: "4", label: "Low", value: "Low" },
    ],
    defaultOption: { id: "1", value: "No Curve" },
    showRadioButton: true,
  },
};
const TableHeadings = [
  {
    label: "Product",
    value: "productName",
    rowspan: 2,
    width: 165,
    color: "#3A99D8",
  },
  {
    label: "Pricing Weeks",
    value: "pricingWeeks",
    colspan: 2,
    width: 300,
    color: "#3A99D8",
  },

  {
    label: "Price Per Unit",
    value: "pricePerUnit",
    colspan: 3,
    width: 400,
    color: "#25d4cf",
  },
  {
    label: "Volume Per Unit",
    value: "gramsPerUnit",
    colspan: 3,
    width: 400,
    color: "#25d4cf",
  },
  {
    label: "COGS Per KGs",
    value: "cogsPerKg",
    colspan: 3,
    width: 400,
    color: "#25d4cf",
  },
  {
    label: "D&A Per Unit",
    value: "d&APerUnit",
    colspan: 3,
    width: 400,
    color: "#25d4cf",
  },
  {
    label: "Price Elasticity",
    value: "priceElasticity",
    colspan: 2,
    width: 250,
    color: "#F77B36",
  },
  {
    label: "Grams Elasticity",
    value: "gramsElasticity",
    colspan: 2,
    width: 250,
    color: "#F77B36",
  },
  {
    label: "Units Variation %",
    value: "unitsVariation",
    rowspan: 2,
    width: 100,
    color: "#F77B36",
  },
  {
    label: "Units",
    value: "units",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Volume(KGs)",
    value: "volume",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Gross Sales",
    value: "grossSales",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Gross Profit",
    value: "grossProfit",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
];
const tableSubHeadings = {
  productName: {
    label: "",
    image: true,
    width: 165,
  },
  pricingStartWeek: {
    label: "Start",
    value: "pricingStartWeek",
    editable: true,
    type: "Text",
    width: 150,
    color: "#3A99D8",
  },
  pricingEndWeek: {
    label: "End",
    value: "pricingEndWeek",
    type: "Text",
    editable: true,
    width: 150,
    color: "#3A99D8",
  },

  ppuFrom: {
    label: "From",
    value: "ppuFrom",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  ppuTo: {
    label: "To",
    value: "ppuTo",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  ppuVariablePer: {
    label: "Var %",
    value: "ppuVariablePer",
    prepend: "%",
    muliply: true,
    width: 100,
    color: "#25d4cf",
  },
  vpuFrom: {
    label: "From",
    value: "vpuFrom",
    editable: true,
    decimal: 3,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.001",
    decimal: 3
  },
  vpuTo: {
    label: "To",
    value: "vpuTo",
    editable: true,
    decimal: 3,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.001",
    decimal: 3
  },
  vpuVariablePer: {
    label: "Var %",
    value: "vpuVariablePer",
    prepend: "%",
    muliply: true,
    width: 100,
    color: "#25d4cf",
  },
  cogsFrom: {
    label: "From",
    value: "cogsFrom",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  cogsTo: {
    label: "To",
    value: "cogsTo",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  cogsVariablePer: {
    label: "Var %",
    value: "cogsVariablePer",
    width: 100,
    muliply: true,
    color: "#25d4cf",
  },
  daFrom: {
    label: "From",
    value: "daFrom",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  daTo: {
    label: "To",
    value: "daTo",
    editable: true,
    type: "Text",
    width: 150,
    color: "#25d4cf",
    step: "0.01",
    decimal: 2
  },
  daVariablePer: {
    label: "Var %",
    value: "daVariablePer",
    width: 100,
    muliply: true,
    color: "#25d4cf",
  },
  priceElasticityTotal: {
    label: "Total",
    value: "priceElasticityTotal",
    editable: true,
    type: "Text",
    width: 150,
    color: "#F77B36",
    step: "0.01",
    decimal: 2
  },
  priceElasticityWeighted: {
    label: "Weighted",
    value: "priceElasticityWeighted",
    width: 100,
    color: "#F77B36",
  },
  gramElasticityTotal: {
    label: "Total",
    value: "gramElasticityTotal",
    editable: true,
    type: "Text",
    width: 150,
    color: "#F77B36",
    step: "0.01",
    decimal: 2
  },
  gramElasticityWeighted: {
    label: "Weighted",
    value: "gramElasticityWeighted",
    width: 100,
    color: "#F77B36",
  },
  unitVariation: {
    label: "",
    value: "unitVariation",
    width: 100,
    muliply: true,
    color: "#F77B36",
  },
  unitFrom: {
    label: "From",
    value: "unitFrom",
    width: 100,
    color: "#15A156",
  },
  unitsTo: {
    label: "To",
    value: "unitsTo",
    width: 100,
    color: "#15A156",
  },
  unitsVarAbs: {
    label: "Var ABS",
    value: "unitsVarAbs",
    width: 100,
    color: "#15A156",
  },
  unitsVarPer: {
    label: "Var %",
    value: "unitsVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  volumeFrom: {
    label: "From",
    value: "volumeFrom",
    width: 100,
    color: "#15A156",
  },
  volumeTo: {
    label: "To",
    value: "volumeTo",
    width: 100,
    color: "#15A156",
  },
  volumeVarAbs: {
    label: "Var ABS",
    value: "volumeVarAbs",
    width: 100,
    color: "#15A156",
  },
  volumeVarPer: {
    label: "Var %",
    value: "volumeVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  grossSalesFrom: {
    label: "From",
    value: "grossSalesFrom",
    width: 100,
    color: "#15A156",
  },
  grossSalesTo: {
    label: "To",
    value: "grossSalesTo",
    width: 100,
    color: "#15A156",
  },
  grossSalesVarAbs: {
    label: "Var ABS",
    value: "grossSalesVarAbs",
    width: 100,
    color: "#15A156",
  },
  grossSalesVarPer: {
    label: "Var %",
    value: "grossSalesVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  grossProfitFrom: {
    label: "From",
    value: "grossProfitFrom",
    width: 100,
    color: "#15A156",
  },
  grossProfitTo: {
    label: "To",
    value: "grossProfitTo",
    width: 100,
    color: "#15A156",
  },
  grossProfitVarAbs: {
    label: "Var ABS",
    value: "grossProfitVarAbs",
    width: 100,
    color: "#15A156",
  },
  grossProfitVarPer: {
    label: "Var %",
    value: "grossProfitVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
};
const collapsedTableHeadings = [
  {
    label: "Product",
    value: "productName",
    rowspan: 2,
    width: 165,
    color: "#3A99D8",
  },
  {
    label: "Pricing Weeks",
    value: "pricingWeeks",
    colspan: 2,
    width: 300,
    color: "#3A99D8",
  },
  {
    label: "Units",
    value: "units",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Volume(KGs)",
    value: "volume",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Gross Sales",
    value: "grossSales",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
  {
    label: "Gross Profit",
    value: "grossProfit",
    colspan: 4,
    width: 400,
    color: "#15A156",
  },
];
const collapsedTableSubHeadings = {
  productName: {
    label: "",
    image: true,
    width: 165,
  },
  pricingStartWeek: {
    label: "Start",
    value: "pricingStartWeek",
    width: 150,
    color: "#3A99D8",
  },
  pricingEndWeek: {
    label: "End",
    value: "pricingEndWeek",
    width: 150,
    color: "#3A99D8",
  },
  unitFrom: {
    label: "From",
    value: "unitFrom",
    width: 100,
    color: "#15A156",
  },
  unitsTo: {
    label: "To",
    value: "unitsTo",
    width: 100,
    color: "#15A156",
  },
  unitsVarAbs: {
    label: "Var ABS",
    value: "unitsVarAbs",
    width: 100,
    color: "#15A156",
  },
  unitsVarPer: {
    label: "Var %",
    value: "unitsVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  volumeFrom: {
    label: "From",
    value: "volumeFrom",
    width: 100,
    color: "#15A156",
  },
  volumeTo: {
    label: "To",
    value: "volumeTo",
    width: 100,
    color: "#15A156",
  },
  volumeVarAbs: {
    label: "Var ABS",
    value: "volumeVarAbs",
    width: 100,
    color: "#15A156",
  },
  volumeVarPer: {
    label: "Var %",
    value: "volumeVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  grossSalesFrom: {
    label: "From",
    value: "grossSalesFrom",
    width: 100,
    color: "#15A156",
  },
  grossSalesTo: {
    label: "To",
    value: "grossSalesTo",
    width: 100,
    color: "#15A156",
  },
  grossSalesVarAbs: {
    label: "Var ABS",
    value: "grossSalesVarAbs",
    width: 100,
    color: "#15A156",
  },
  grossSalesVarPer: {
    label: "Var %",
    value: "grossSalesVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
  grossProfitFrom: {
    label: "From",
    value: "grossProfitFrom",
    width: 100,
    color: "#15A156",
  },
  grossProfitTo: {
    label: "To",
    value: "grossProfitTo",
    width: 100,
    color: "#15A156",
  },
  grossProfitVarAbs: {
    label: "Var ABS",
    value: "grossProfitVarAbs",
    width: 100,
    color: "#15A156",
  },
  grossProfitVarPer: {
    label: "Var %",
    value: "grossProfitVarPer",
    width: 100,
    muliply: true,
    color: "#15A156",
  },
};
const exportObject = {
  elasticityRadio,
  productFilter,
  measuresFilter,
  TableHeadings,
  tableSubHeadings,
  collapsedTableHeadings,
  collapsedTableSubHeadings,
};
export default exportObject;
