import {
  GET_ANCHOR_PRODUCT_FILTERS,
  GET_ANCHOR_PRODUCT_FILTERS_SUCESS,
  GET_ANCHOR_PRODUCT_FILTERS_FAILURE,
  ON_CHANGE_ANCHOR_OPTION,
  CLEAR_ANCHOR_PRODUCT_FILTERS,
} from "./constants";
import { API } from "../../util/helper";
import _ from "lodash";

export const getAnchorProductFilters = () => {
  return {
    type: GET_ANCHOR_PRODUCT_FILTERS,
  };
};

export const getAnchorProductFiltersSucess = (payload: any, filterData) => {
  return {
    type: GET_ANCHOR_PRODUCT_FILTERS_SUCESS,
    payload,
    filterData
  };
};

export const getAnchorProductFiltersFailure = (error) => {
  return {
    type: GET_ANCHOR_PRODUCT_FILTERS_FAILURE,
    payload: error,
  };
};

const getAnchorProductFilter = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}filter/anchorproducts`,
    payload
  );
};

export function fetchAnchorProductFiltersData(filters, filterData) {
  return (dispatch: any) => {
    dispatch(getAnchorProductFilters());
    getAnchorProductFilter(filters)
      .then((response) => {
        dispatch(getAnchorProductFiltersSucess(response, filterData));
      })
      .catch((error) => {
        dispatch(getAnchorProductFiltersFailure(error));
      });
  };
}

export const onChangeAnchorOption = (key, val) => {
  if (_.isArray(val) && val.length===0) {
   val = null;
  }
  return {
    type: ON_CHANGE_ANCHOR_OPTION,
    payload: {[key]: val},
  };
};

export const clearAnchorFilters = () => {
  return {
    type: CLEAR_ANCHOR_PRODUCT_FILTERS,
  };
};
