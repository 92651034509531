import { styled } from "@mui/material/styles";
import { BlackInputTitle } from "../../styles/Common.Styled";
import { Box, Typography } from "@mui/material";

const Title = styled(BlackInputTitle)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));

const ThemeTitle = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));

const FooterBox = styled(Box)<{ color?: string; bgColor?: string }>(
  ({ theme, color, bgColor }) => ({
    marginTop: 10,
    padding: 20,
    color,
    border: "1px solid #858C94",
    borderRadius: 15,
    background: "#edf5fa", //bgColor? bgColor : theme.palette.primary.main
  })
);

export { Title, ThemeTitle, FooterBox };
