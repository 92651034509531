import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import TopFilters from './components/TopFliters/TopFilters';
import DashboardActions from '../../components/DashboardActions';
import VisualizationContainer from './components/VisualizationContainer/VisualizationContainer';
import MacroEconCheckContainer from './components/MacroEconCheckContainer/MacroEconCheckContainer';
import mockData from '../../mocks/macroEconDashboard';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { enableCompetitorFilter, removeMMPWFromLocal, storeMMPWData } from '../../util/helper';
import { pageList } from '../../mocks/common';
import { getEachSaveDashboardData, saveDashBoardData } from '../../util/services';
import _, { remove } from 'lodash';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { useSelector } from 'react-redux';
const sectionsConfig = [
  {
    title: 'Visualization <span style="color:#0093CD;">PepsiCo</span>',
    type: 'pepsi',
    id: 'macro-econ-pepsi-visualization',
    productVariable: mockData.pepsiVariables,
    macroEconVariables: [],
    category: 'PEPSICO',
    defaultFilters: { global: {}, visualizations: [] },
  },
  // {
  //   title: 'Visualization <span style="color:#0093CD;">OTHER</span>',
  //   type: "other",
  //   id: "other-visualization",
  //   productVariable: mockData.otherVariables,
  //   macroEconVariables: [],
  // },
];
const MacroEconDashboard: React.FC<{}> = () => {
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [sections, setSections] = React.useState([]);
  const [modifiedSections, setModifiedSections] = React.useState([]);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [category, setCategory] = useState('');
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();

  const reduxState = useSelector((state: any) => state);

  const MacroEconDashboard = useSelector((state: any) => state.MacroEconDashboard);

  const onApplyFilter = (data) => {
    setDisableDownloadButton(false);
    removeMMPWFromLocal('macro-econ');
    setFilters(data.filters);
    if (enableCompetitorFilter(data.filters)) {
      sectionsConfig.splice(1, 1, {
        title: 'Visualization <span style="color:#0093CD;">Competitors</span>',
        type: 'competitor',
        id: 'macro-econ-competitors-visualization',
        productVariable: mockData.competitorVariables,
        macroEconVariables: [],
        category: 'Competitor',
        defaultFilters: { global: {}, visualizations: [] },
      });
    }
    const config = [];
    sectionsConfig.map((item) => {
      config.push({
        ...item,
        macroEconVariables: data.macroEconVariables,
        defaultFilters: { global: {}, visualizations: [] },
      });
    });
    setSections(config);
    setModifiedSections(config);
  };
  const getVisualizationData = (product, data) => {
    const sectionsPayload = [...sections];
    if (product === 'pepsi') {
      setCategory(data.global.category);
      sectionsPayload[0].defaultFilters.global = data.global;
      sectionsPayload[0].defaultFilters.visualizations = data.visualizations;
    } else if (product === 'competitor') {
      sectionsPayload[1].defaultFilters.global = data.global;
      sectionsPayload[1].defaultFilters.visualizations = data.visualizations;
    }
    setModifiedSections(sectionsPayload);
  };
  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData: any = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      setDisableDownloadButton(false);
      console.log(savedDashboardData);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setFilters(savedDashboardData?.filters?.global);
      setSections(savedDashboardData?.filters.sectionsConfig);
      setModifiedSections(sectionsConfig);
    } else {
      setSections([...sectionsConfig]);
      setModifiedSections(sectionsConfig);
    }
  };

  return (
    <DashboardActions
      title='Macro-Econ Dashboard'
      data={{
        filters: {
          global: { ...appliedFilters },
          sectionsConfig: [...modifiedSections],
        },
        mmpwKey: 'macro-econ',
      }}
      callback={getSaveDashBoardsData}
      dashboardKey={pageList[4].id}
      disableDownloadButton={
        disableDownloadButton ||
        reduxState.common.loader ||
        MacroEconDashboard.varDataSrcLoader ||
        MacroEconDashboard.macroEconLoader ||
        MacroEconDashboard.productFilterLoader ||
        MacroEconDashboard.macroEconCheckLoader
      }
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[4].value} />
      </Grid>
      <Box className='m-b-20'>
        <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} />
      </Box>
      <Box>
        <MacroEconCheckContainer globalFilter={appliedFilters} />
      </Box>
      {appliedFilters.country &&
        _.isArray(sections) &&
        sections.length > 0 &&
        sections.map((item) => <VisualizationContainer globalFilter={appliedFilters} data={item} key={item.id} callback={getVisualizationData} />)}
      <DashboardSourceList dashboardName={pageList[4].id} appliedFilters={appliedFilters} category={category} />
    </DashboardActions>
  );
};
export default MacroEconDashboard;
