import { LINEAR_REGRESSION } from './constants';

let intiState = {
  linearRegression: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case LINEAR_REGRESSION:
      return {
        ...state,
        linearRegression: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
