import {makeStyles } from "@material-ui/core";
import { Button, Switch, Typography, styled } from "@mui/material";
export const useStyles = makeStyles({
    customIconButton: {
        padding: '5px'
    },
    inputBox: {
        width: '350px',
        padding: '2px'
    },
    gridContainer: {
        marginTop: '10px',
        marginBottom: '12px'
    },
    customPadding: {
        padding: '2rem'
    }
});

export const styleForScenarioDataGrid = {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
        '&:focus': {
            outline: 'none !important'
        }
    },
    '.MuiDataGrid-columnHeaders': {
        '&:focus': {
            outline: 'none !important'
        },
        background: '#f1f5fe !important',
        borderBottom: '3px solid #dfdfdf',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        fontSize: '0.8rem'
    },
    '.MuiDataGrid-cell': {
        '&:focus': {
            outline: 'none !important'
        },
        borderColor: '#dfdfdf',
    },
    '.MuiDataGrid-cellContent': {
        textWrap: 'balance',
        textAlign: 'center'
    },
    '.MuiDataGrid-overlay': {
        backgroundColor: '#fff'
    },
    ".scenarios-cell": {
        fontWeight: 600,
        fontSize: '1rem'
    }
}

export const ViewEditButton = styled(Button)`
  background: ${({ disabled }) => (disabled ? '#ccc' : '#25d4cf')};
  color: ${({ disabled }) => (disabled ? '#666' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  
  &:hover {
    color: ${({ disabled }) => (disabled ? '#666' : '#fff')};
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#25d4cf')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  
  font-size: 12px;
  padding: 5px 15px;
`;