export const BoxSlotChartConfig = (data,categories,xTitle) => ({
    chart: {
        type: 'boxplot',
        backgroundColor: ''
    },
    lang : {
        noData : 'No data to display.'
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    title: {
        text: ''
    },
    legend: {
        enabled: false
    },
    xAxis: {
        categories,
        labels: {
            style: {
                color: '#575C5F',
                fontSize: '1.2em'
            },
            // rotation : 290,
            // width : "50%"
        },
        gridLineColor: '',
        gridLineWidth: 2
    },
    yAxis: {
        title: {
            text: xTitle ? xTitle + ' Index' : '',
            margin: 30,
            style: {
                color: '#575C5F',
                cursor: 'default',
                fontSize: '1.5em'
            },
            
        },
        labels: {
            style: {
                fontSize: '1.2em'
            }
        },
        lineColor: '#D6DADD',
        lineWidth: 1,
        lineDashStyle: 'Solid',
        gridLineWidth: 2
    },
    plotOptions: {
        boxplot: {
            fillColor: '#B3BCC6',
            lineWidth: 0,
            medianColor: '#2f5597',
            medianWidth: 3,
            whiskerColor: '#B3BCC6',
            whiskerLength: '0%',
            pointWidth: 50,
            dataLabels: {
                enabled: true
            },
            tooltip: {
                pointFormat: '<span style="color: #2f5597">●</span><br/>High: {point.q3}<br/>' + 'Current ' + xTitle + ': {point.median}<br/>Low : {point.q1}'
            },
        },
    },
    series: [{
        data
    }]
})