import React, { FunctionComponent, useState, useEffect } from "react";
import { TableContainer, TableHead, TableBody, Skeleton, Tooltip, Box, Grid, Typography } from "@mui/material";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "./BaseLineTable.Styled";
import _ from "lodash";
import { separator } from "../../util/helper";

const BaseLineTable: FunctionComponent<{
  heading;
  rowData;
  showSkeleton?;
  pageNumber?;
  offset?;
  baseline?;
  diffVal?
}> = ({ heading, rowData, showSkeleton, pageNumber, offset, baseline, diffVal }) => {
  const [maxRowVal, setMaxRowVal] = useState([]);
  const [hovered, setHovered] = useState(null);
  const [showError, setError] = useState(false);

  useEffect(() => {
    if (rowData && rowData.length > 0 && baseline) {
      let maxVals = [];
      heading.map((item, i) => {
        if (item.baseLine) {
          rowData.map((item, j)=> {
            const val = parseInt(Object.values(item)[i] as string);
            if(Math.round(val) === Math.round(baseline)){
              maxVals.push({ columnNo: i, rowNo: j })
            }
          })
          if(maxVals.length === 0){
            if(rowData && rowData.length){
              let nearestVal;
              for(let j=0; j<rowData.length; j++){
                const diff = Math.abs(parseInt(Object.values(rowData[j])[i] as string) - baseline);
                if(diff <= diffVal){
                  if(!nearestVal || diff < nearestVal.diff){
                    nearestVal = {diff, columnNo: i, rowNo: j}
                  }
                }
              }
              if(nearestVal)
                maxVals.push(nearestVal)
            }            
          }
        }
      })

      if(maxVals.length){
        setMaxRowVal(maxVals);
        setError(false)
      }else{
        setError(true)
      }
    }
  }, [rowData]);

  return (
    <>
    {showError && <Box><Typography color={'#ff0000'} fontStyle={'italic'}>No Baseline information available for this combination.</Typography></Box>}
    <TableContainer>
      <StyledTable>
        {showSkeleton ? (
          <>
            <TableHead>
              <StyledTableRow>
                {heading.map((item) => (
                  <StyledTableCell align="center" colSpan={item.colSpan}>
                    <Skeleton />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row, i) => (
                <StyledTableRow key={i}>
                  {Object.keys(row).map(() => (
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <>
            <TableHead>
              <StyledTableRow style={{ textAlign: "center" }}>
                {heading.map((item) => (
                  <StyledTableCell
                    style={{ color: "black" }}
                    align={item.align}
                  >
                    {item.title}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row, i) => (
                <StyledTableRow key={i} onMouseEnter={()=> setHovered(i)} onMouseLeave={() => setHovered(null)}>
                  {Object.keys(row).map((key, j) => {
                    const maxRow = maxRowVal.find((x) => x.rowNo === i);
                    return (
                      <Tooltip title="Baseline" placement="top"
                        disableHoverListener
                        open={(hovered === i && maxRow && maxRow.columnNo === j && maxRow.rowNo === i) || false}>
                        <StyledTableCell
                          style={{
                            textAlign: "center",
                            color: maxRow ? "black" : "black",
                            backgroundColor: maxRow ?
                              hovered === i ? "#62e9a3" : "#e4fcf0"
                              : ""
                          }}
                        >
                          {_.isObject(row[key])
                            ? _.isNumber(row[key].value) && heading[j].formatNumber
                              ? separator(row[key].value.toFixed(2))
                              : heading[j].separator
                                ? separator(row[key].value)
                                : row[key].value
                            : _.isNumber(row[key]) && heading[j].formatNumber
                              ? separator(row[key].toFixed(2))
                              : heading[j].separator
                                ? separator(row[key])
                                : heading[j].caseType === 'Upper' ? row[key].toUpperCase()
                                : row[key]}
                        </StyledTableCell>
                      </Tooltip>
                    )
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        )}
      </StyledTable>
    </TableContainer>
    </>
  );
};

export default BaseLineTable;