import { Box, Card, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { sectionFilterConfig } from './FiltersConfig'
import { DropdownTitle } from '../../../AssortmentTool/AssortmentTool.Styled'
import AssortmentDropdown from '../../../../components/AssortmentDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchState, fetchApi } from '../../../../store/actions/ExecutionTool/portfolioGeoLevel.action'
import Indicator from '../../../../components/Loader/Loader';
import { getHistoricalData, getPriceElasticityData, loaderForElasticityRange, loaderForHistoricalRange, resetDataForGraph, sectionFilterLoader, setMixSimulatorPriceElasticityDownloadData } from '../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action';
import { toast } from '../../../../store/actions/toast.action'
import { RedBtn } from '../../../AssortmentTool/components/AssortmentOverallFilters/AssortmentOverallFilters.Styled'
import { API_URL } from '../../../../store/actions/constants'

type SectionFilterPayload = {
    country: string[],
    businessUnit: string[],
    channel: string[],
    region: string[],
    brand: string[],
    subBrand: string[],
    segment: string[],
    allFiltersWillCome: boolean
}

const executeSequence = ['channel', 'region', 'segment', 'brand', 'subBrand',]

const SectionFilters = ({ isDistibution }) => {

    const dispatch = useDispatch();

    const selectedSectionFilters = useRef<any>({
        "country": [],
        "businessUnit": [],
        "channel": [],
        "region": [],
        "brand": [],
        "subBrand": [],
        "segment": []
    });

    const filterConfig = useRef<any>(sectionFilterConfig);
    const { selectedBubble, selectedBubbleChartOptions, geoFilterData, selectedFilters, sectionFilterLoaderState, PriceElasticityDataUploaded } = useSelector((state: any) => state.mixSimulationAllFilter);
    const { uid} = useSelector((state: any) => state.mixSimulationUserInput);
    const { portfolioLevel, portfolioTableLoader } =
        useSelector((state: any) => state.MixPortfolioReducer);
    const { geoLevel, geoLevelTableLoader } = useSelector(
        (state: any) => state.MixGeoLevelReducer);
    const historicalRangeApi = () => {
        const applyLoaderHistorical: dispatchState = {
            loaderStateFetch: loaderForHistoricalRange(true)
        }
        console.log(selectedSectionFilters.current)

        dispatch(fetchApi(selectedSectionFilters.current, API_URL.MIXSIMULATION.FETCH_HISTORICAL_RANGE_GRAPH_DATA, applyLoaderHistorical))
            .then(res => {
                dispatch(getHistoricalData({ ...res.data, isDistibution }));
                dispatch(loaderForHistoricalRange(false))
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(loaderForHistoricalRange(false));
                dispatch(toast('Something went wrong', true, 2000, 'error'));
            })
    };
    useEffect(()=>{
        console.log(PriceElasticityDataUploaded)
        if(PriceElasticityDataUploaded=="updated"){
            priceElasticityApi();
        }
    },[PriceElasticityDataUploaded])

    const priceElasticityApi = () => {
        const applyLoaderPriceElasticity: dispatchState = {
            loaderStateFetch: loaderForElasticityRange(true)
        }

        dispatch(fetchApi({...selectedSectionFilters.current,uid:uid}, API_URL.MIXSIMULATION.FETCH_PRICE_ELASTICITY_GRAPH_DATA, applyLoaderPriceElasticity))
            .then(res => {
                dispatch(getPriceElasticityData(res.data.priceElasticity))
         dispatch(setMixSimulatorPriceElasticityDownloadData(res.data.priceElasticity))

                dispatch(loaderForElasticityRange(false));
            })
            .catch(err => {
                dispatch(loaderForElasticityRange(false));
                console.log("ERR", err);
                dispatch(toast('Something went wrong', true, 2000, 'error'));
            })
    };

    const fetchApiForFilter = (key, data) => {

        let currentKeyIndex = executeSequence.indexOf(key);

        filterConfig.current = {
            ...filterConfig.current, [key]: { ...filterConfig.current[key], defaultOption: data }
        }
        if (key == 'subBrand') {
            if (!data.length) {
                return;
            }
            historicalRangeApi();
            priceElasticityApi();
        }
        else if (key !== 'channel') {
            dispatch(resetDataForGraph());
            let modifiedConfig: any = JSON.parse(JSON.stringify(filterConfig.current));

            const findExcutionSequence = executeSequence.slice(currentKeyIndex + 1)
            findExcutionSequence.forEach(ele => {
                selectedSectionFilters.current[ele] = [];
                modifiedConfig[ele] = {
                    ...modifiedConfig[ele], options: [], defaultOption: []
                }
            });

            filterConfig.current = JSON.parse(JSON.stringify(modifiedConfig));
        } else if (key == 'channel') {
            selectedSectionFilters.current['region'] = []
        }

        const applyLoader: dispatchState = {
            loaderStateFetch: sectionFilterLoader(true)
        }

        dispatch(fetchApi({ ...selectedSectionFilters.current, "allFiltersWillCome": false }, "filter/mixOptimizerSectionFilter", applyLoader))
            .then(res => {
                let nextKey = currentKeyIndex === (executeSequence.length - 1) ? executeSequence[currentKeyIndex] : executeSequence[currentKeyIndex + 1];
                console.log("sectionFilterData", filterConfig)
                filterConfig.current = {
                    ...filterConfig.current, [nextKey]: { ...filterConfig.current[nextKey], options: transformOptions(res.data[nextKey]) }
                }
                dispatch(sectionFilterLoader(false))
            })
            .catch(err => {
                console.log(err);
                dispatch(toast('Something went wrong', true, 2000, 'error'));
                dispatch(sectionFilterLoader(false));
            });
    }

    const onChangeSectionLevel = (key, data) => {
        selectedSectionFilters.current = {
            ...selectedSectionFilters.current,
            [key]: data
        };
        console.log("onChangeSectionLevel", data)
        console.log("onChangeSectionLevel", key)
        fetchApiForFilter(key, data);
    }

    const transformOptions = (data) => {
        return data?.map((option, index) => ({
            id: `${option}-${index}-${option}`,
            label: option,
            value: option,
            default: false
        }))
    }

    const getSectionFilterOnBubbleClick = () => {
        if (selectedBubble) {
            const selectedValue = selectedBubble.split('|')
            console.log('selected', selectedValue)
            let subBrand: any = []
            let sectionFilterData: SectionFilterPayload =
            {
                country: selectedFilters['country'],
                businessUnit: selectedFilters['businessUnit'],
                channel: selectedFilters['channel'],
                region: [],
                segment: [],
                brand: [],
                subBrand: [],
                allFiltersWillCome: true

            }
            if (selectedBubbleChartOptions == 'portfolio') {

                sectionFilterData['region'] = ["All Region"];

                switch (portfolioLevel) {
                    case 'brand': {

                        sectionFilterData['brand'] = [selectedValue[1]];
                        sectionFilterData['segment'] = [selectedValue[0]];
                        break;
                    }
                    case 'segment': {
                        sectionFilterData['segment'] = [selectedValue[0]];
                        break;

                    }
                    case 'sku': {
                        sectionFilterData['segment'] = [selectedValue[0]];
                        sectionFilterData['brand'] = [selectedValue[3]];
                        subBrand = [selectedValue[4]];
                        break;
                    }
                    default: {

                    }
                }
            } else {
                sectionFilterData['region'] = [selectedValue[1]];
            }
            console.log("sectionFilterData", portfolioLevel)
            console.log("sectionFilterData", sectionFilterData)
            // fetchApiForFilter(key, data,false);
            sectionFilterApiCallOnBubbleClick(sectionFilterData, subBrand)
        }
    }

    const sectionFilterApiCallOnBubbleClick = (payload, subBrand) => {
        const applyLoader: dispatchState = {
            loaderStateFetch: sectionFilterLoader(true)
        }
        dispatch(fetchApi(payload, API_URL.MIXSIMULATION.OPTIMIZER_SECTION_FILTER, applyLoader))
            .then(res => {
                let keysNotToInclude = ["country", "businessUnit"]
                let data = res.data;
                Object.keys(data).filter((key: string) => !keysNotToInclude.includes(key))
                    .map((key: string) => {
                        filterConfig.current[key].defaultOption = key == "subBrand" ? subBrand : data[key]
                        filterConfig.current[key].options = transformOptions(data[key])

                        if(selectedBubbleChartOptions == 'portfolio' &&portfolioLevel=='sku'){

                            selectedSectionFilters.current[key]  = key == "subBrand" ? subBrand : data[key]
                        }else{
                            selectedSectionFilters.current[key]  = data[key]

                        }
                    })
                console.log("sectionFilterApiCallOnBubbleClick", data);
                console.log("sectionFilterApiCallOnBubbleClick", filterConfig.current);
                console.log("sectionFilterApiCallOnBubbleClick", subBrand);
                console.log("sectionFilterApiCallOnBubbleClick", selectedSectionFilters.current);

                historicalRangeApi();
                priceElasticityApi();
                dispatch(sectionFilterLoader(false));
            })
            .catch(err => {
                console.log(err);
                dispatch(toast('Something went wrong', true, 2000, 'error'));
                dispatch(sectionFilterLoader(false));
            });
    }

    useEffect(() => {
        if (selectedBubble) {
            getSectionFilterOnBubbleClick()
        }
    }, [selectedBubble])

    useEffect(() => {
        filterConfig.current = {
            channel: {
                title: "Channel",
                options: [],
                all: false,
                multiple: false,
                defaultOption : [],
            },
            region: {
                title: "Region",
                options: [],
                all: false,
                multiple: false,
                defaultOption : [],
                defaultSelectAll: false,
            },
            segment: {
                title: "Segment",
                options: [],
                all: true,
                multiple: true,
                defaultOption : []
            },
            brand: {
                title: "Brand",
                options: [],
                all: true,
                multiple: true,
                defaultOption : []
            },
            subBrand: {
                title: "Sub Brand",
                options: [],
                all: true,
                multiple: true,
                defaultOption : []
            },
        };
        selectedSectionFilters.current = {
            ...selectedSectionFilters.current,
            "country": selectedFilters['country'],
            "businessUnit": selectedFilters['businessUnit'],
            "channel": selectedFilters['channel'],
            "region": selectedFilters['region'],
        };
        filterConfig.current = {
            ...filterConfig.current, channel: { ...filterConfig.current['channel'], options: transformOptions(selectedSectionFilters.current['channel']) }
        }
        dispatch(resetDataForGraph());
    }, [selectedFilters]);
    const clearFilter = () => {
        console.log("filterConfig.current", filterConfig.current)

        Object.keys(filterConfig.current).map((key: any) => {
            if (key != "channel") {
                filterConfig.current[key].defaultOption = [];
                filterConfig.current[key].options = transformOptions([]);
                selectedSectionFilters.current[key] = [];
            }
        })
        console.log("selectedSectionFilters.current", selectedSectionFilters.current)
        fetchApiForFilter('channel', filterConfig.current['channel'])
        // sectionFilterApiCallOnBubbleClick({
        //     ...selectedSectionFilters.current,
        //     allFiltersWillCome: false
        // })

        // filterConfig.current = sectionFilterConfig;
    }
    const checkForEmptyValues = (currentState) => {
        const keysToCheck = ["country", "businessUnit", "channel"];
        for (let key of keysToCheck) {
            if (!Array.isArray(currentState[key]) || currentState[key].length === 0) {
                return true;
            }
        }
        return false;
    }

    return (
        <Grid container marginTop={5} spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography color={"white"} variant="h6">
                    POTENTIAL LEVERS TO DRIVE MIX CHANGE
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Divider
                    color="#dfdfdf"
                    style={{ marginBottom: "15px" }}
                    sx={{ height: 0.15, m: 0 }}
                />
            </Grid>
            <Grid item marginTop={1} xs={12} md={12}>
                <Card sx={{
                    borderRadius: "5px",
                    position: "relative",
                    backgroundColor: "#F1F5FE"
                }}>
                    <Indicator position="absolute" show={sectionFilterLoaderState} />
                    <Grid paddingX={'2rem'} paddingY={5}>
                        <Grid item xs={12} md={12} marginBottom={4}>
                            <Typography variant="h6" fontSize={'18px'} fontWeight={600}>
                                Section Filters:
                            </Typography>
                        </Grid>
                        <Grid sx={{
                            pointerEvents: checkForEmptyValues(selectedSectionFilters.current) ? 'none' : 'auto',
                            opacity: checkForEmptyValues(selectedSectionFilters.current) ? '0.5' : '1'
                        }} container spacing={6}>
                            {
                                Object.entries(filterConfig.current).map(([filterKey, configOfFilter]: any, index) => (
                                    <Grid key={`${index}-${filterKey}-${filterKey}`} item>
                                        <DropdownTitle className='m-b-10'>{configOfFilter.title} <span style={{ color: 'red' }}>*</span> </DropdownTitle>
                                        <AssortmentDropdown
                                            data={configOfFilter.options}
                                            multiple={configOfFilter.multiple}
                                            allOption={configOfFilter.all}
                                            defaultOption={configOfFilter.defaultOption}
                                            onChange={(selectionData) => onChangeSectionLevel(filterKey, selectionData)}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Box display='flex' justifyContent="flex-end" marginTop='10px'>
                            {<RedBtn
                                className="m-r-20"
                                onClick={clearFilter}
                            >Clear Filter</RedBtn>}
                        </Box>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default SectionFilters