const productFilterConfig = {
  category: {
    title: `Category <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    first: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Pepsico)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    last: true,
  },
};

const competitorFilterConfig = {
  category: {
    title: `Category <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    first: true,
    multiple: true,
    children: 'segment',
  },
  segment: {
    title: `Segment <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size <span style="color: #858c94;">(Competitor)</span>`,
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    last: true,
  },
};

const pepsiVariables = [
  {
    label: 'Total Sell In',
    value: 'totalSellIn',
    default: false,
  },
  {
    label: 'Total Volume In',
    value: 'totalVolumeIn',
    default: false,
  },
  {
    label: 'Total Units In',
    value: 'totalUnitsIn',
    default: false,
  },
  {
    label: 'Total Gross Profit',
    value: 'totalGrossProfit',
    default: false,
  },
  {
    label: 'Total COGS',
    value: 'totalCogs',
    default: false,
  },
  {
    label: 'Price per Unit(Sell In) ',
    value: 'pricePerUnitSellIn',
    default: false,
  },
  {
    label: 'Price per Volume(Sell In)',
    value: 'pricePerVolumeSellIn',
    default: false,
  },
  {
    label: 'Gross Profit per Unit',
    value: 'grossProfitPerUnit',
    default: false,
  },
  {
    label: 'COGS per Unit',
    value: 'cogsPerUnit',
    default: false,
  },
  {
    label: 'Total Sell Out',
    value: 'totalSellOut',
    default: true,
  },
  {
    label: 'Total Volume Out',
    value: 'totalVolumeOut',
    default: false,
  },
  {
    label: 'Total Units Out',
    value: 'totalUnitsOut',
    default: false,
  },
  {
    label: 'Price per Unit(Sell Out)',
    value: 'pricePerUnitSellOut',
    default: false,
  },
  {
    label: 'Price per Volume(Sell Out)',
    value: 'pricePerVolumeSellOut',
    default: false,
  },
];

const competitorVariables = [
  {
    label: 'Total Sell Out',
    value: 'totalSellOut',
    default: true,
  },
  {
    label: 'Total Volume Out',
    value: 'totalVolumeOut',
    default: false,
  },
  {
    label: 'Total Units Out',
    value: 'totalUnitsOut',
    default: false,
  },
  {
    label: 'Price per Unit(Sell Out)',
    value: 'pricePerUnitSellOut',
    default: false,
  },
  {
    label: 'Price per Volume(Sell Out)',
    value: 'pricePerVolumeSellOut',
    default: false,
  },
];

const otherVariables = [
  {
    label: 'SOP/Unit',
    value: 'SOP/Unit',
    default: false,
  },
  {
    label: 'SOP/Volume',
    value: 'SOP/Volume',
    default: false,
  },
  {
    label: 'Sales',
    value: 'Sales',
    default: true,
  },
  {
    label: 'Units',
    value: 'Units',
    default: false,
  },
  {
    label: 'Volume',
    value: 'Volume',
    default: false,
  },
  {
    label: '$/Unit',
    value: '$/Unit',
    default: false,
  },
  {
    label: '$/Volume',
    value: '$/Volume',
    default: false,
  },
];

export const measureFilterData = {
  period1: [
    { id: '1', label: 'Full Year', value: 'fullYear' },
    { id: '2', label: 'Year to Date', value: 'yearToDate' },
    { id: '3', label: 'Rolling Year', value: 'rollingYear' },
  ],
  period2: [
    { id: '4', label: 'Most Recent Month', value: 'lastMonth' },
    { id: '5', label: 'Most Recent Week', value: 'lastWeek' },
    { id: '6', label: 'Most Recent Day', value: 'lastDay' },
    // { id: "7", label: "Last Year", value: "lastYear" },
  ],
  current: [
    {
      id: '1',
      label: 'Vs Prior Period',
      value: 'immediatePreviousPeriod',
    },
    {
      id: '2',
      label: 'Vs Same Time of Prior Period',
      value: 'samePreviousPeriod',
    },
  ],
};
const saveDashboardData = {
  filters: {
    global: {
      country: 'MEXICO',
      geoLevel: ['REGION-CHAN'],
      geoLevel2: ['MX SOUTHEAST-OT'],
      channel: ['DTS', 'OT', 'WHSL'],
      periodView: 'lastMonth',
      currentVs: 'immediatePreviousPeriod',
      variable: ['GDP_SecondaryActivities', 'GDP_TerciaryActivities', 'GovtEnergyAuthorizedRates'],
      dataSource: ['INEGI - Inflation Rate', 'INEGI - GDP', 'INEGI - CPI', 'INEGI - CCI, House Situation & Country Situation'],
      startDate: '03/01/2021',
      endDate: '12/31/2021',
    },
    // "visualizationInputs": {
    pepsi: {
      country: 'MEXICO',
      geoLevel: ['REGION-CHAN'],
      geoLevel2: ['MX SOUTHEAST-OT'],
      channel: ['DTS', 'OT', 'WHSL'],
      periodView: 'lastMonth',
      currentVs: 'immediatePreviousPeriod',
      variable: ['GDP_SecondaryActivities', 'GDP_TerciaryActivities', 'GovtEnergyAuthorizedRates'],
      dataSource: ['INEGI - Inflation Rate', 'INEGI - GDP', 'INEGI - CPI', 'INEGI - CCI, House Situation & Country Situation'],
      startDate: '03/01/2021',
      endDate: '12/31/2021',
      category: ['SAV-SALTY'],
      segment: ['SAV-POTATO'],
      brand: ['HAPPY SNACKS'],
      subBrand: ['HAPPY SNACKS REGULAR'],
      packSize: ['16X-SUPER SIZE'],
      periodicity: 'Yearly',
    },
    competitor: {
      country: 'MEXICO',
      geoLevel: ['REGION-CHAN'],
      geoLevel2: ['MX SOUTHEAST-OT'],
      channel: ['DTS', 'OT', 'WHSL'],
      periodView: 'lastMonth',
      currentVs: 'immediatePreviousPeriod',
      variable: ['GDP_SecondaryActivities', 'GDP_TerciaryActivities', 'GovtEnergyAuthorizedRates'],
      dataSource: ['INEGI - Inflation Rate', 'INEGI - GDP', 'INEGI - CPI', 'INEGI - CCI, House Situation & Country Situation'],
      startDate: '03/01/2021',
      endDate: '12/31/2021',
      category: ['SAV-SALTY', 'BIS-COOKIES'],
      segment: ['SAV-POTATO', 'SAV-SALTY MIX'],
      brand: ['LAYS', 'MUNCHIES', 'HAPPY SNACKS'],
      subBrand: ['HAPPY SNACKS REGULAR', 'LAYS REGULAR'],
      packSize: ['16X-SUPER SIZE', '11X-COMPARTE'],
      periodicity: 'Monthly',
    },
  },
  // },
  name: 'macro3',
  key: 'macro-econ',
  version: '1.0',
  mmpw: '{"avg-sales-at-every-price-point":{"Text":[{"type":"Text","text":"Test","style":{"color":"#000","fontSize":20,"fontFamily":"Roboto"},"id":"Text-1","x":222.00051887658324,"y":60}]},"sales-at-every-price-point":{"Text":[{"type":"Text","text":"test1","style":{"color":"#000","fontSize":20,"fontFamily":"Roboto"},"id":"Text-1","x":187.0004332129964,"y":69}]}}',
  isShared: false,
  id: 123,
  dashboardId: 0,
};

const exportObject = {
  productFilterConfig,
  competitorFilterConfig,
  pepsiVariables,
  competitorVariables,
  otherVariables,
  saveDashboardData,
};

export default exportObject;
