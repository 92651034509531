import { API } from '../../../util/helper';
import { BACKEND_URL, FETCH_EXECUTION_AVG_DATA_FAILURE, FETCH_EXECUTION_AVG_DATA_REQUEST, FETCH_EXECUTION_AVG_DATA_SUCCESS, FETCH_EXECUTION_USER_INPUT_FAILURE, FETCH_EXECUTION_USER_INPUT_REQUEST, FETCH_EXECUTION_USER_INPUT_SUCCESS, SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA, SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA, SET_EXECUTION_SELECTED_USER_INPUT_DATA, SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT } from '../constants';

export const requestExecutionUserData = () => (
    {
        type: FETCH_EXECUTION_USER_INPUT_REQUEST
    }
)

export const setExecutionUserDataInitialState = () => (
    {
        type: SET_INITIAL_STATE_FOR_EXECUTION_USER_INPUT
    }
)


export const getExecutionUserData = (payload) => (
    {
        type: FETCH_EXECUTION_USER_INPUT_SUCCESS,
        payload
    }
)

export const setSelectedExecutionData = (payload) => (
    {
        type: SET_EXECUTION_SELECTED_USER_INPUT_DATA,
        payload
    }
)

export const setMustHaveUserInput = (payload) => (
    {
        type: SET_EXECUTION_MUST_HAVE_SKU_USER_INPUT_DATA,
        payload
    }
)

export const setGoodToHaveUserInput = (payload) => (
    {
        type: SET_EXECUTION_GOOD_TO_HAVE_SKU_USER_INPUT_DATA,
        payload
    }
)

export const getExecutionUserDataFailure = (payload) => (
    {
        type: FETCH_EXECUTION_USER_INPUT_FAILURE,
        payload
    }
)

export const getExecutionCurrentAvgDataRequest = () => ({
    type: FETCH_EXECUTION_AVG_DATA_REQUEST,
  });
  
  export const getExecutionCurrentAvgDataSuccess = (payload) => ({
    type: FETCH_EXECUTION_AVG_DATA_SUCCESS,
    payload,
  });
  
  export const getExecutionCurrentAvgDataFailure = (payload) => ({
    type: FETCH_EXECUTION_AVG_DATA_FAILURE,
    payload,
  });


export const fetchExecutionInputDataFiltersData = (data, apiUrl) => {
    return async (dispatch) => {
        dispatch(requestExecutionUserData());
        try {
            const response = await API.post(`${BACKEND_URL}/api/filter/${apiUrl}`, data);
            dispatch(getExecutionUserData(response))
        } catch (error) {
            dispatch(getExecutionUserDataFailure(error))
        }
    };
};

export const fetchExecutionCurrentAvgData = (data, apiUrl) => {
    return async (dispatch) => {
      dispatch(getExecutionCurrentAvgDataRequest());
      try {
        const response = await API.post(
          `${BACKEND_URL}/api/output/executionTracker/${apiUrl}`,
          data
        );
        dispatch(getExecutionCurrentAvgDataSuccess(response));
      } catch (error) {
        dispatch(getExecutionCurrentAvgDataFailure(error));
      }
    };
  };