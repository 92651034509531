const primaryFilter = {
  country: {
    title: "Country",
    options: [],
  },
  geoLevel: {
    title: "Geo-Level",
    options: [],
    single: true,
    placeholder: "Select",
    all: false,
    multiple: false,
  },
  region: {
    title: "Region",
    options: [],
    placeholder: "All",
    allOptionText: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  city: {
    title: "City",
    options: [],
    placeholder: "All",
    allOptionText: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  channel: {
    title: "Channel",
    options: [],
    placeholder: "All",
    allOptionText: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  subChannel: {
    title: "Sub Channel",
    options: [],
    placeholder: "All",
    allOptionText: "All",
    all: true,
    multiple: true,
    defaultSelectAll: true,
  },
  keyAccounts: {
    title: "Key Accounts",
    options: [],
    placeholder: "Null",
    all: false,
    multiple: false,
  },
};
const productFilter = {
  category: {
    title: "Category",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    first: true,
    children: "segment",
  },
  segment: {
    title: "Segment",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "brand",
  },
  brand: {
    title: "Brand",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "subBrand",
  },
  subBrand: {
    title: "Sub Brand",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "packSize",
  },
  packSize: {
    title: "Pack Size",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    last: true,
  },
};
const anchorFilter = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    first: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "anchorSubBrand",
  },
  anchorSubBrand: {
    title: "Anchor Sub-brand",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    children: "anchorPackSize",
  },
  anchorPackSize: {
    title: "Anchor Pack-size",
    options: [],
    placeholder: "NA",
    all: false,
    multiple: false,
    last: true,
  },
};
const correlationAnalysisTable = {
  heading: [{ title: "Time" }, { title: "API" }, { title: "" }],
  value: [],
};
const realESTVolumeTable = {
  heading: [
    { title: "Time" },
    { title: "API" },
    { title: "Real Volume" },
    { title: "EST Volume" },
  ],
  value: [],
};
const regressionStatsTable: any = {
  heading: [{ title: "Regression Statistics", colSpan: 2 }],
  value: [
    { statsName: "Multiple R", statsPer: 0 },
    {
      statsName: { bgcolor: null, color: null, value: "R Square" },
      statsPer: { bgcolor: null, color: null, value: 0 },
    },
    { statsName: "Adjusted R Square", statsPer: 0 },
    { statsName: "Standard Error", statsPer: 0 },
    { statsName: "Obervations", statsPer: 0 },
  ],
};
const coefficientTable = {
  heading: [
    { title: "" },
    { title: "Coefficients" },
    { title: "Standard Errors" },
    { title: "T-stat" },
    { title: "P-value" },
    { title: "Lower 95" },
    { title: "Upper 95" },
  ],
  value: [],
};
const parabolaSimulationTable = {
  heading: [
    { title: "Price", formatNumber: true },
    { title: "API", formatNumber: true },
    { title: "Price", formatNumber: true },
    { title: "Units", formatNumber: false, separator: true },
    { title: "Sell Out", gradient: true, formatNumber: false, separator: true },
    {
      title: "Direct Profit",
      gradient: true,
      formatNumber: false,
      separator: true,
    },
  ],
  value: [],
};
const suggestedRetailPriceTable = {
  heading: [{ title: "Suggested Retail Price", colSpan: 2 }],
  value: [],
};
const simulationStatsCard = [
  {
    title: "Gross Sales",
    value: "0",
  },
  {
    title: "Volume (KGS)",
    value: "0",
    raise: false,
    // percent: "0.00",
  },
  {
    title: "Gross Profit",
    value: "0",
  },
  {
    title: "Share Of Market",
    value: "0",
    raise: false,
    // percent: "0.00",
  },
];
const currentValuesTable = {
  heading: [
    { title: "Brand Size" },
    { title: "Packet Size" },
    { title: "Gross Sales (000’s)" },
    { title: "Volume (000’s)" },
    { title: "Gross Profit (000’s)" },
    { title: "Price Per Unit" },
    { title: "Volume Per Unit" },
    { title: "Price Elasticity" },
    { title: "Gram Elasticity" },
  ],
  value: [],
};
const proposedValuesTable = {
  heading: [
    { title: "Brand Size" },
    { title: "Packet Size" },
    { title: "Gross Sales (000’s)" },
    { title: "Volume (000’s)" },
    { title: "Gross Profit (000’s)" },
    { title: "Price Per Unit" },
    { title: "Volume Per Unit" },
    { title: "Price Elasticity" },
    { title: "Gram Elasticity" },
  ],
  value: [],
};
const simulatedValuesTable = {
  heading: [
    { title: "Brand Size" },
    { title: "Packet Size" },
    { title: "Gross Sales (000’s)" },
    { title: "GS % chg" },
    { title: "Volume (000’s)" },
    { title: "Vol % chg" },
    { title: "Gross Profit (000’s)" },
    { title: "GP % chg" },
    { title: "Price Per Unit % chg" },
    { title: "Volume Per Unit % chg" },
  ],
  value: [],
};
const measureFilter = {
  periodicity: {
    title: "Periodicity",
    options: [
      { id: "1", value: "Week" },
      { id: "2", value: "Month" },
      { id: "3", value: "Q" },
    ],
    defaultOption: { id: "3", value: "Q" },
    showRadioButton: true,
  },
  SOMVarConfig: {
    title: "SOM Variable Configuration",
    options: [
      { id: "1", value: "Sales" },
      { id: "2", value: "Volume" },
      { id: "3", value: "Units" },
    ],
    defaultOption: { id: "2", value: "Volume" },
  },
  SOM: {
    title: "SOM/Ctg Sgmnt",
    options: [
      { id: "1", value: "Category" },
      { id: "2", value: "Segment" },
    ],
    defaultOption: { id: "1", value: "Category" },
    showRadioButton: true,
  },
  variable2: {
    title: "API",
    options: [{ id: "1", value: "API" }],
    defaultOption: { id: "1", value: "API" },
  },
  apiUnits: {
    title: "Measure",
    options: [
      { id: "1", value: "KGs ( in '000s )" },
      { id: "2", value: "Units ( in '000s )" },
    ],
    defaultOption: { id: "2", value: "Units ( in '000s )" },
  },
  variable1: {
    title: "Measure filters not applicable for Anchor Products",
    options: [
      { id: "1", value: "Volume" },
      { id: "2", value: "Units" },
    ],
    defaultOption: { id: "1", value: "Volume" },
  },
};
const monthRangeFilter = {
  title: "Select Range of Month",
  options: [
    { id: "1", value: "3 Months" },
    { id: "2", value: "6 Months" },
    { id: "3", value: "12 Months" },
  ],
  defaultOption: { id: "1", value: "3 Months" },
  showRadioButton: true,
};
const markUpAndMarkDown = {
  title: "",
  options: [
    { label: "Mark Up", value: "markup" },
    { label: "Mark Down", value: "markdown" },
  ],
  defaultOption: { label: "Mark Up", value: "markup" },
};
const exportObject = {
  primaryFilter,
  productFilter,
  anchorFilter,
  measureFilter,
  correlationAnalysisTable,
  realESTVolumeTable,
  regressionStatsTable,
  coefficientTable,
  parabolaSimulationTable,
  suggestedRetailPriceTable,
  simulationStatsCard,
  currentValuesTable,
  proposedValuesTable,
  simulatedValuesTable,
  monthRangeFilter,
  markUpAndMarkDown,
};
export default exportObject;
