import { UNIT_VARIATION_EXPECTED } from "./constants";

let intiState = {
    unitVariationExpected: false, 
  };
  
  const topLoader = (state = intiState, action) => {
    switch (action.type) {
      case UNIT_VARIATION_EXPECTED:
        return {
          ...state,
          unitVariationExpected: action.payload,
        };
        default:
      return state;
    }
};

export default topLoader;

