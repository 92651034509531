import { API } from "../../../util/helper";
import {
  MIX_PORTFOLIO_TABLE_DATA_REQUEST,
  MIX_PORTFOLIO_TABLE_DATA_SUCCESS,
  MIX_PORTFOLIO_TABLE_DATA_FAILURE,
  SET_MIX_PORTFOLIO_LEVEL,
  BACKEND_URL,
  RESET_MIX_SIMULATION_PORTFOLIO_TABLE,
} from "../constants";

export const setMixPortfolioLevel = (payload) => ({
  type: SET_MIX_PORTFOLIO_LEVEL,
  payload
});

export const fetchMixPortfolioDataRequest = () => ({
  type: MIX_PORTFOLIO_TABLE_DATA_REQUEST,
});

export const fetchMixPortfolioDataSuccess = (data) => ({
  type: MIX_PORTFOLIO_TABLE_DATA_SUCCESS,
  payload: data,
});

export const fetchMixPortfolioDataFailure = (error) => ({
  type: MIX_PORTFOLIO_TABLE_DATA_FAILURE,
  payload: error,
});
export const resetMixSimulationPortfolioTableData = () => ({
  type: RESET_MIX_SIMULATION_PORTFOLIO_TABLE,
});


export const fetchMixPortfolioData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixPortfolioDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchMixPortfolioDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchMixPortfolioDataFailure(error.message));
    }
  };
};
