import {
    FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
    FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
    FAILURE_MIX_SIMULATION_SAVE_SCENARIO,
    HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER,
    IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE,
    LOADER_FOR_MIX_RUN_SIMULATION,
    LOADER_FOR_RUN_SIMULATION,
    REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
    REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
    REQUEST_MIX_SIMULATION_SAVE_SCENARIO,
    RESET_MIX_CONSTRAINT_TABLE,
    SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
    SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA,
    SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
    SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
    SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA,
    SET_MIX_SIMULATION_GOAL, SET_MIX_SIMULATION_UID, SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, UPLOAD_VALIDATION_SUCCESS,
} from "../../actions/constants";
import _ from 'lodash';

const initialState = {
    tableFilter: {},
    selectedFilters: {
        channel: [],
        region: [],
        storeSegment: []
    },
    dataToDownload:[],
    intermediateTableData: [],
    tableData: [],
    loading: false,
    error: null,
    loaderMixScreen: false,
    isDataChanged: false
};

const mixSimulationConstraintTable = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA:
            return {
                ...state,
                loading: true
            };
        case HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER:
            return {
                ...state,
                loading: action.payload
            };
        case SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA:
            return {
                ...state,
                dataToDownload:action.payload
            };
        case FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA:
            return {
                ...state,
                intermediateTableData: action.payload
            }

        case SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA:
            return {
                ...state,
                tableData: action.payload,
                loading: false
            };
        case IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE:
            return {
                ...state,
                isDataChanged: action.payload,
            };
        case REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER:
            return {
                ...state,
                loading: true
            };
        case FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER:
            return {
                ...state,
                tableFilter: action.payload.default?action.payload.data:{...state.tableFilter,
                    [action.payload.key]:action.payload.data[action.payload.key]},
                loading: false
            };

        case SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER:
            return {
                ...state,
                selectedFilters: { ...state.selectedFilters, [action.payload?.name]: typeof (action.payload.value) === 'string' ? [action.payload.value] : action.payload.value },
                loading: false
            };
        case SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER:
            return {
                ...state,
                selectedFilters: action.payload
            }
        case LOADER_FOR_RUN_SIMULATION:
            return {
                ...state,
                loader: action.payload
            }
        case UPLOAD_VALIDATION_SUCCESS:
            return {
                ...state,
                tableData: action.payload
            }
        case LOADER_FOR_MIX_RUN_SIMULATION:
            return {
                ...state,
                loaderMixScreen: action.payload
            }
        case RESET_MIX_CONSTRAINT_TABLE:
            return initialState;

        default:
            return state;
    }
}
export default mixSimulationConstraintTable;