import { useRef, useState, useEffect } from 'react';
import { Card, CardContent, Grid, Skeleton, Box } from '@mui/material';
import Dashboard from '../../../../components/Dashboard';
import ColumnDrilldown from '../ColumnDrilldown';
import { saleEveryPointChart } from '../../../../mocks/sellOutDashboard';

import _ from 'lodash';
import HighchartContainer from '../../../../components/HighchartContainer';
import React from 'react';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { makeStyles } from '@material-ui/core/styles';
import { plotElementsOnChart } from '../../../../util/helper';

const useStyles = makeStyles(() => ({
  hide: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const SalesValueAndPerformance: React.FC<{
  valueTrend;
  isLoading;
  appliedFilters;
  id;
  title;
  tooltip;
}> = ({ valueTrend, isLoading, appliedFilters, id, title, tooltip }) => {
  const classes = useStyles();
  const chartRef = useRef(null);
  const [salesValueAndSOPData, setsalesValueAndSOPData] = useState({
    ...saleEveryPointChart,
  });

  const formatSalesPricePoint = (response) => {
    const salesValueAndSOPDataTemp = { ...salesValueAndSOPData };
    if (response.length > 0) {
      salesValueAndSOPDataTemp.xAxisData = response.map((item) => item.date);
      salesValueAndSOPDataTemp.areaData = response.map((item) => {
        return item.variable1;
      });
      salesValueAndSOPDataTemp.columnData = response.map((item) => {
        return item.salesValueTrend;
      });
      setsalesValueAndSOPData(salesValueAndSOPDataTemp);
    }
  };

  const onChangePeriodicity = (res) => {
    if (res && res.length) {
      formatSalesPricePoint(res);
    }
  };

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
      if (dashbaordData && dashbaordData[id]) {
        plotElementsOnChart(dashbaordData[id], chart, id);
      }
    }
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid className='m-b-20'>
          <Dashboard title={title} chartRef={chartRef} id={id} showSkeleton={isLoading} tooltip={tooltip}>
            {valueTrend && Object.keys(valueTrend).length > 0 && (
              <Grid
                style={{
                  position: 'absolute',
                  bottom: 10,
                  width: 100,
                  zIndex: 2,
                }}
                className={classes.hide}
              >
                <IncrementDecrement
                  data={valueTrend && Object.keys(valueTrend).length > 0 && valueTrend}
                  selectedResponse={onChangePeriodicity}
                  show={salesValueAndSOPData && salesValueAndSOPData.xAxisData.length > 0 && salesValueAndSOPData}
                  id={id}
                  defaultOption={(localStorage.getItem('periodicity') && JSON.parse(localStorage.getItem('periodicity'))[id]?.item) || null}
                />
              </Grid>
            )}
            {!isLoading && (
              <Box className='m-b-10'>
                <HighchartContainer chartRef={chartRef} id={id}>
                  <ColumnDrilldown
                    data={salesValueAndSOPData && salesValueAndSOPData.xAxisData.length > 0 && salesValueAndSOPData}
                    chartRef={chartRef}
                    y1AxisLable={appliedFilters.variable1}
                  />
                </HighchartContainer>
              </Box>
            )}
            {isLoading && <Skeleton variant='rectangular' height={400} className='m-b-10' />}
          </Dashboard>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SalesValueAndPerformance;
