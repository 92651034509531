import { PRICE_PIANO_MODEL, PRICE_SPECIFIC_MODEL } from './constant';

export const pricePianoDataLoader = (payload) => {
  return {
    type: PRICE_PIANO_MODEL,
    payload,
  };
};

export const priceSpecificLoader = (payload) => {
  return {
    type: PRICE_SPECIFIC_MODEL,
    payload,
  };
};
