import React from 'react';
import TableCtnr from './TableContainer';
const MultiLevelTable: React.FC<{
  data: any;
  tableHeader: any;
  level?;
  type;
  showSkeleton: boolean;
  responseKeys;
  style;
}> = ({ data, tableHeader, level, type, showSkeleton, responseKeys, style }) => {
  return (
    <TableCtnr
      data={data}
      tableHeader={tableHeader}
      type={type}
      style={{ height: 500, overflow: 'auto', cellWidth: style.cellWidth }}
      level={level}
      showSkeleton={showSkeleton}
      responseKeys={responseKeys}
    />
  );
};
export default MultiLevelTable;
