import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import {
  CustomColumnHeader,
  CustomFilterIcon,
  CustomHeaderCell,
} from "../../pages/AssortmentTool/components/CommonComponents/SkuTable/SkuTable.Styled";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
  TextField,
} from "@mui/material";

interface props {
  columns: GridColDef[];
  rows: any;
  disableColumnMenu: boolean;
  style: any;
  autoPageSize: boolean;
  rowCount: number;
  paginationMode?: "server" | "client";
  loading: boolean;
  filterableColumns: any;
  callback: any;
  isScenarioLibrary: boolean;
  sortableColumns :any;
}

const DataGridTables: React.FC<props> = ({
  columns,
  rows,
  disableColumnMenu,
  style,
  autoPageSize,
  rowCount,
  paginationMode = "server",
  loading,
  callback,
  filterableColumns,
  sortableColumns,
  isScenarioLibrary,
}) => {
  const [anchorEls, setAnchorEls] = useState({});
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [originalFilterValues, setOriginalFilterValues] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [searchText, setSearchText] = useState("");
  const [tableRowData, setTableRowData] = useState(rows);
  const [filteredRows, setFilteredRows] = useState(rows);

  useEffect(() => {
    const originalValues = updationOnColumnFilters(columns, rows);
    setOriginalFilterValues(originalValues);
    setFilterValues(originalValues);
    setTableRowData(rows);
    setFilteredRows(rows);
    setSelectedFilters({});
  }, [rows]);

  useEffect(() => {
    const filtered = rows
      .filter((row) => {
        for (const [field, values] of Object.entries(selectedFilters)) {
          if (values.length > 0 && !values.includes(row[field])) {
            return false;
          }
        }
        return true;
      })
      .map((row) => {
        const columnsToUpdate = [
          "shelfAndMerchandizingScore",
          "shelfSpaceScore",
          "exhibitionScore",
          "netPriceRealisationScore",
        ];
        columnsToUpdate.forEach((column) => {
          if (row[column] === 0 || row[column] === "0") {
            row[column] = "-";
          }
        });
        return row;
      });

    setFilteredRows(
      filtered.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  }, [selectedFilters, rows, searchText]);

  const handleFilterClick = (event, column) => {
    setAnchorEls({
      ...anchorEls,
      [column.field]: event.currentTarget,
    });
    if (!selectedFilters[column.field]) {
      setSelectedFilters({
        ...selectedFilters,
        [column.field]: [],
      });
    }
  };

  const updationOnColumnFilters = (tableColumns, data) => {
    const distinctValues = {};
    tableColumns.forEach((column) => {
      const values = new Set();
      data.forEach((row) => {
        values.add(row[column.field]);
      });
      distinctValues[column.field] = Array.from(values);
    });
    return distinctValues;
  };

  const handleClose = () => {
    setAnchorEls({});
  };

  const handleChangeFilter = (event, column, value) => {
    if (value === "selectAll") {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [column.field]: event.target.checked ? filterValues[column.field] : [],
        [`selectAll${column.field}`]: event.target.checked,
      }));
    } else {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [column.field]: event.target.checked
          ? [...(prevFilters[column.field] || []), value]
          : (prevFilters[column.field] || []).filter((val) => val !== value),
        [`selectAll${column.field}`]: false,
      }));
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchText(value);

    if (value.trim() === "") {
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.field]: originalFilterValues[column.field].sort(),
      }));
    } else {
      const filteredOptions = originalFilterValues[column.field].filter(
        (option) => option.toLowerCase().includes(value.toLowerCase())
      );
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        [column.field]: filteredOptions.sort(),
      }));
    }
  };

  const additionalStyles = !isScenarioLibrary
    ? {
        border: "3px solid #D0D2D3 !important",
        ".MuiDataGrid-row:nth-child(odd)": {
          backgroundColor: "white !important",
        },
        "& .MuiDataGrid-row:nth-child(even)": {
          background: "f1f5fe !important",
        },
        "& .MuiDataGrid-root": {
          border: "3px solid red !important",
        },
      }
    : {};

  const mergedStyles = {
    ...style,
    ...additionalStyles,
  };

  const outputTableStyles = !isScenarioLibrary
    ? {
      padding: "15px",
        height: 500,
        width: "100%",
      }
    : { height: 500, width: "100%" };

  return (
    <div style={outputTableStyles}>
      <DataGrid
        rows={filteredRows}
        disableColumnMenu={disableColumnMenu}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        disableVirtualization={true}
        sx={mergedStyles}
        paginationMode={paginationMode}
        onPageChange={(pageNo) => {
          console.log("PAGE NO", pageNo);
        }}
        autoPageSize={autoPageSize}
        rowCount={rowCount}
        loading={loading}
        getRowId={callback}
        columns={columns.map((column) => ({
          ...column,
          headerAlign: "center",
          headerClassName: "custom-column-header",
          headerName: (
            <CustomColumnHeader
              style={{
                backgroundColor: isScenarioLibrary ? "#f1f5fe" : "#f1f5fe",
              }}
            >
              <CustomHeaderCell
                style={{
                  backgroundColor: isScenarioLibrary ? "#f1f5fe" : "#f1f5fe",
                }}
              >
                {column.headerName}
              </CustomHeaderCell>
              {filterableColumns.includes(column.field) && (
                <CustomFilterIcon
                  size="small"
                  aria-label="filter"
                  onClick={(event) => handleFilterClick(event, column)}
                >
                  <FilterListIcon />
                </CustomFilterIcon>
              )}
            </CustomColumnHeader>
          ),
          sortable: sortableColumns.includes(column.field),
        }))}
      />
      {columns.map((column) => (
        <Popover
          key={column.field}
          open={Boolean(anchorEls[column.field])}
          anchorEl={anchorEls[column.field]}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ color: "black" }}
        >
          <div
            style={{
              maxHeight: "200px",
              maxWidth: "400px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search..."
              onChange={(e) => handleSearch(e, column)}
              style={{ marginBottom: "8px", padding: "8px" }}
            />

            <FormControl component="fieldset">
              <FormGroup style={{ padding: "8px" }}>
                {filterValues && filterValues[column.field] && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedFilters[`selectAll${column.field}`] ||
                            (selectedFilters[column.field]?.length ===
                              filterValues[column.field]?.length &&
                              filterValues[column.field]?.length > 0)
                          }
                          onChange={(event) =>
                            handleChangeFilter(event, column, "selectAll")
                          }
                          name={`selectAll${column.field}`}
                        />
                      }
                      label={
                        <span style={{ color: "black", marginLeft: "8px" }}>
                          Select All
                        </span>
                      }
                      style={{ margin: 0 }}
                    />
                    {filterValues[column.field].sort().map((value) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            checked={selectedFilters[column.field]?.includes(
                              value
                            )}
                            onChange={(event) =>
                              handleChangeFilter(event, column, value)
                            }
                            name={value}
                            value={value}
                          />
                        }
                        label={
                          <span style={{ color: "black", paddingLeft: "8px" }}>
                            {value}
                          </span>
                        }
                        style={{ margin: 0 }}
                      />
                    ))}
                  </>
                )}
              </FormGroup>
            </FormControl>
          </div>
        </Popover>
      ))}
    </div>
  );
};

export default DataGridTables;
