import { IColumnAreaData } from "../types/sellOutDashBoard";

const productFilter = {
  category: {
    title: "Category <span class='text-muted'>(PepsiCo)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    first: true,
    children: "segment",
  },
  segment: {
    title: "Segment <span class='text-muted'>(PepsiCo)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "brand",
  },
  brand: {
    title: "Brand <span class='text-muted'>(PepsiCo)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "subBrand",
  },
  subBrand: {
    title: "Sub-Brand <span class='text-muted'>(PepsiCo)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "packSize",
  },
  packSize: {
    title: "Pack-Size <span class='text-muted'>(PepsiCo)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    last: true,
  },
};
const measureFilter = {
  variable1: {
    title: "Variable 1",
    options: [
      { id: "1", value: "SOM", disabled: false },
      { id: "2", value: "Volume", disabled: false },
      { id: "3", value: "Revenue", disabled: false },
      { id: "4", value: "Units", disabled: false },
    ],
    defaultOption: { id: "1", value: "SOM" },
    showRadioButton: true,
  },
  selloutPrices: {
    title: "Sellout Prices",
    options: [
      { id: "1", label: "Price Per Unit", value: "pricePerUnit" },
      { id: "2", label: "Price Per Volume (KGs)", value: "pricePerVolume" },
    ],
    defaultOption: { id: "1", value: "pricePerUnit" },
    showRadioButton: true,
  },

  SOMVarConfig: {
    title: "SOM Variable Configuration",

    options: [
      { id: "1", value: "Sales" },

      { id: "2", value: "Volume" },

      { id: "3", value: "Units" },
    ],

    defaultOption: { id: "1", value: "Sales" },
  },

  SOM: {
    title: "SOM/Ctg Sgmnt",

    options: [
      { id: "1", value: "Category" },

      { id: "2", value: "Segment" },
    ],

    defaultOption: { id: "1", value: "Category" },

    showRadioButton: true,
  },
};

const anchorFilter = {
  anchorCategory: {
    title: "Category <span class='text-muted'>(Anchor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  anchorSegment: {
    title: "Segment <span class='text-muted'>(Anchor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  anchorBrand: {
    title: "Brand <span class='text-muted'>(Anchor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  anchorSubBrand: {
    title: "Sub-brand <span class='text-muted'>(Anchor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  anchorPackSize: {
    title: "Pack-size <span class='text-muted'>(Anchor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
};
const competitorFilter = {
  category: {
    title: "Category <span class='text-muted'>(Competitor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    first: true,
    children: "segment",
  },
  segment: {
    title: "Segment <span class='text-muted'>(Competitor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "brand",
  },
  brand: {
    title: "Brand <span class='text-muted'>(Competitor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "subBrand",
  },
  subBrand: {
    title: "Sub-brand <span class='text-muted'>(Competitor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    children: "packSize",
  },
  packSize: {
    title: "Pack-size <span class='text-muted'>(Competitor)</span>",
    options: [],
    placeholder: "Select",
    all: true,
    multiple: true,
    defaultSelectAll: false,
    last: true,
  },
};

export const saleEveryPoint = {
  heading: [
    { title: "Price Point ($)" },
    { title: "Sales Value" },
    { title: "SOP" },
  ],
  value: [],
};

export const saleEveryPointChart: IColumnAreaData = {
  xAxisData: [],

  areaData: [],
  columnData: [],
};

export const priceTrackingTableHeading = [
  { title: "Product" },
  { title: "" },
  { title: "Jan" },
  { title: "Feb" },
  { title: "Mar" },
  { title: "Apr" },
  { title: "May" },
  { title: "Jun" },
  { title: "Jul" },
  { title: "Aug" },
  { title: "Sep" },
  { title: "Oct" },
  { title: "Nov" },
  { title: "Dec" },
];

export const priceTrackingTableSkeletonData = [
  ["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
  ["", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
  ["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
  ["", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
  ["", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
  ["", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
];

const sellOutRetailTableHeading = [
  { title: "Product" },
  { title: "Taxes" },
  { title: "Mark Up" },
  { title: "" },
  { title: "Jan" },
  { title: "Feb" },
  { title: "Mar" },
  { title: "Apr" },
  { title: "May" },
  { title: "Jun" },
  { title: "Jul" },
  { title: "Aug" },
  { title: "Sep" },
  { title: "Oct" },
  { title: "Nov" },
  { title: "Dec" },
];

const sellOutRetailMarkDownTableHeading = [
  { title: "Product" },
  { title: "Taxes" },
  { title: "Mark Down" },
  { title: "" },
  { title: "Jan" },
  { title: "Feb" },
  { title: "Mar" },
  { title: "Apr" },
  { title: "May" },
  { title: "Jun" },
  { title: "Jul" },
  { title: "Aug" },
  { title: "Sep" },
  { title: "Oct" },
  { title: "Nov" },
  { title: "Dec" },
];

const sellOutRetailTableSkeletonData = [
  ["", "", "", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", "", "", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
  ["", "", "", 20, 22, 12, 14.5, 20, 22, 12, 14.5, 20, 22, 12, 14.5, 0],
  ["", "", "", 10, 1, 4, 15, 10, 1, 4, 15, 10, 1, 4, 15, 0],
  ["", "", "", 50, 51, 8, 16, 50, 51, 8, 16, 50, 51, 8, 16, 0],
];

const saveDashboardData = {
  filters: {
    global: {
      variable1: "SOM",
      somMeasure: "Category",
      somGroup: "Sales",
      pricePer: "pricePerUnit",
      country: "MEXICO",
      geoLevel: ["COUNTRY-CHAN"],
      geoLevel2: ["TTL MEXICO-DTS"],
      channel: ["DTS", "OT", "WHSL"],
      periodView: ["na"],
      category: ["SAV-SALTY"],
      segment: ["SAV-SALTY MIX"],
      brand: ["PAKETAXO"],
      subBrand: ["PAKETAXO MEZCLADITO"],
      packSize: ["04X-CHICO"],
      anchorCategory: ["SAV-SALTY"],
      anchorSegment: ["SAV-SALTY MIX"],
      anchorBrand: ["BARCEL BIG MIX"],
      anchorSubBrand: ["BARCEL BIG MIX REGULAR"],
      anchorPackSize: ["04X-CHICO"],
      date: "01/01/2021-12/31/2021",
    },
    selloutVsSuggestedFilters: {
      country: "MEXICO",
      geoLevel: ["COUNTRY-CHAN"],
      channel: ["DTS", "OT", "WHSL"],
      geoLevel2: ["TTL MEXICO-DTS"],
      date: "01/01/2021-12/31/2021",
      category: ["FDS-OATS"],
      segment: ["FDS-OATS INSTANT"],
      brand: ["QUAKER OATS INSTANT"],
      subBrand: ["QUAKER OATS INSTANT REGULAR"],
      packSize: ["04X-SS LARGE"],
      comparisonLevel: "packsize",
    },
    priceTrackingAPIFilters: {
      country: "MEXICO",
      geoLevel: ["COUNTRY-CHAN"],
      channel: ["DTS", "OT", "WHSL"],
      geoLevel2: ["TTL MEXICO-DTS"],
      date: "01/01/2021-12/31/2021",
      category: ["BIS-COOKIES"],
      segment: ["CKY-CHOC CHIP"],
      brand: ["CHOKIS"],
      subBrand: ["CHOKIS CHOKO"],
      packSize: null,
      competitorVendor: ["BIMBO"],
      competitorCategory: ["SAV-SALTY"],
      competitorSegment: ["SAV-CORN"],
      competitorBrand: ["BARCEL CHURRITOS"],
      competitorSubBrand: ["BARCEL CHURRITOS REGULAR"],
      competitorPackSize: null,
      comparisonLevel: "subbrand",
    },
    SelloutVsSuggestedFilterswithTaxMarkups: {
      country: "MEXICO",
      geoLevel: ["COUNTRY-CHAN"],
      channel: ["DTS", "OT", "WHSL"],
      geoLevel2: ["TTL MEXICO-DTS"],
      date: "01/01/2021-12/31/2021",
      category: ["FDS-OATS"],
      segment: ["FDS-OATS INSTANT"],
      brand: ["QUAKER OATS INSTANT"],
      subBrand: ["QUAKER OATS INSTANT REGULAR"],
      packSize: ["04X-SS LARGE"],
      comparisonLevel: "packsize",
      taxMarkups: [
        {
          taxes: 0.08,
          markup: 0.25,
          productName: "QUAKER OATS INSTANT",
          productInfo: "QUAKER OATS INSTANT",
        },
      ],
    },
  },
  name: "sell224",
  key: "sell-out-dashboard",
  version: "1.0",
  id: 4545,
  dashboardId: 0,
  mmpw: "{}",
};

const markUpAndMarkDown = {
  title: "",
  options: [
    { label: "Mark Up", value: "markup" },
    { label: "Mark Down", value: "markdown" },
  ],
  defaultOption: { label: "Mark Up", value: "markup" },
};

const exportObject = {
  productFilter,
  measureFilter,
  anchorFilter,
  competitorFilter,
  sellOutRetailTableHeading,
  sellOutRetailTableSkeletonData,
  sellOutRetailMarkDownTableHeading,
  saveDashboardData,
  markUpAndMarkDown,
};

export default exportObject;
