import { FunctionComponent, useState, useEffect } from "react";
import { TableContainer, TableHead, TableBody, Skeleton } from "@mui/material";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "./GradientTable.Styled";
import _ from "lodash";
import { paginateTable, separator } from "../../util/helper";

const GradientTable: FunctionComponent<{
  heading;
  rowData;
  showSkeleton?;
  pageNumber?;
  offset?;
}> = ({ heading, rowData, showSkeleton, pageNumber, offset }) => {
  const [rawData, setRawData] = useState([]);
  const [maxRowVal, setMaxRowVal] = useState([]);
  const [data, setData] = useState([]);
  const greenToRed = {
    startColor: {
      red: 247,
      green: 116,
      blue: 109,
    },
    midColor: {
      red: 254,
      green: 235,
      blue: 132,
    },
    endColor: {
      red: 99,
      green: 190,
      blue: 123,
    },
  };

  const colorGradient = (fadeFraction, colorSet) => {
    let color1 = colorSet.startColor;
    let color2 = colorSet.midColor;
    let fade = fadeFraction;

    if (colorSet.endColor) {
      fade = fade * 2;
      if (fade >= 1) {
        fade -= 1;
        color1 = colorSet.midColor;
        color2 = colorSet.endColor;
      }
    }

    const diffRed = color2.red - color1.red;
    const diffGreen = color2.green - color1.green;
    const diffBlue = color2.blue - color1.blue;

    const gradient = {
      red: Math.floor(color1.red + diffRed * fade),
      green: Math.floor(color1.green + diffGreen * fade),
      blue: Math.floor(color1.blue + diffBlue * fade),
    };
    return `rgb(${gradient.red},${gradient.green},${gradient.blue})`;
  };

  const getColor = (data) => {
    let result = [...data];
    let rowColor = [];
    heading.map((item, hIndex) => {
      if (item.gradient) {
        const arr = [];
        result.map((val) => {
          const keys = Object.keys(val);
          const key = keys[hIndex];
          arr.push(val[key]);
        });
        const minArr = Math.min(...arr);
        const maxArr = Math.max(...arr);
        if (minArr && maxArr) {
          result.map((val, index) => {
            const keys = Object.keys(val);
            const key = keys[hIndex];

            if (key === "sellOutRC" || key === "dprc") {
              if (val[key] === maxArr) {
                rowColor.push({
                  rowNo: index,
                  color: "green",
                });
              }
            }
            const fade = getFade(minArr, maxArr, val[key]);
            result[index] = setColor(fade, val, hIndex);
          });
        }
      }
    });
    setMaxRowVal(rowColor);
    return result;
  };

  const getFade = (min, max, val) => {
    return (val - min) / (max - min);
  };

  const setColor = (fade, item, hIndex) => {
    let result;
    const keys = Object.keys(item);
    const key = keys[hIndex];
    const color = colorGradient(fade, greenToRed);
    if (key) {
      result = {
        ...item,
        [key]: {
          value: item[key],
          bgColor: color,
        },
      };
    }
    return result;
  };

  useEffect(() => {
    setRawData(
      heading.findIndex((x) => x.gradient) !== -1 ? getColor(rowData) : rowData
    );
  }, [rowData]);

  useEffect(() => {
    // const result = [...data, ...paginateTable(rawData, offset, pageNumber)];
    setData(rawData);
  }, [pageNumber, rawData]);

  return (
    <TableContainer>
      <StyledTable>
        {showSkeleton ? (
          <>
            <TableHead>
              <StyledTableRow>
                {heading.map((item) => (
                  <StyledTableCell align="center" colSpan={item.colSpan}>
                    <Skeleton />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <StyledTableRow key={i}>
                  {Object.keys(row).map(() => (
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <>
            <TableHead>
              <StyledTableRow style={{ textAlign: "center" }}>
                {heading.map((item) => (
                  <StyledTableCell
                    style={{ color: "black" }}
                    align={item.align}
                  >
                    {item.title}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <StyledTableRow key={i}>
                  {Object.keys(row).map((key, j) => (
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        color:
                          maxRowVal.find((x) => x.rowNo === i) &&
                          key !== "sellOutRC" &&
                          key !== "dprc"
                            ? "#fff"
                            : "black",
                        backgroundColor:
                          (_.isObject(row[key]) && row[key].bgColor) ||
                          (maxRowVal.find((x) => x.rowNo === i) &&
                          key !== "sellOutRC" &&
                          key !== "dprc"
                            ? "green"
                            : ""),
                      }}
                    >
                      {_.isObject(row[key])
                        ? _.isNumber(row[key].value) && heading[j].formatNumber
                          ? separator(row[key].value.toFixed(2))
                          : heading[j].separator
                          ? separator(row[key].value)
                          : row[key].value
                        : _.isNumber(row[key]) && heading[j].formatNumber
                        ? separator(row[key].toFixed(2))
                        : heading[j].separator
                        ? separator(row[key])
                        : row[key]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        )}
      </StyledTable>
    </TableContainer>
  );
};

export default GradientTable;