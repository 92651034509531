import { GET_GEO_LEVEL_SUMMARY_DATA, GET_PORTFOLIO_DATA, LOADER_STATE_GEO_LEVEL, LOADER_STATE_PORTFOLIO, RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA,SET_GEO_LEVEL,SET_PORTFOLIO_LEVEL } from "../../actions/ExecutionTool/constant";

const portfolioGeoLevelSummary = {
    geoLevelSummaryData: [],
    portfolioSummaryData: [],
    errorForPortfolio: {
        errorState: false,
        message: ''
    },
    selectedLevelForPortfolio:'brand',
    selectedLevelForGeoLevelSummary:'segment',
    loaderForPortfolio: false,
    loaderForGeoLevel: false
}

const portfolioGeoLevelReducer = (state = portfolioGeoLevelSummary, { type, payload }) => {
    switch (type) {
        case RESET_STATE_FOR_GEO_LEVEL_SUMMARY_DATA:
            return portfolioGeoLevelSummary
        case GET_PORTFOLIO_DATA:
            return {
                ...state,
                portfolioSummaryData: payload
            }
        case GET_GEO_LEVEL_SUMMARY_DATA:
            return {
                ...state,
                geoLevelSummaryData: payload
            }
        case LOADER_STATE_PORTFOLIO:
            return {
                ...state,
                loaderForPortfolio: payload,
                portfolioSummaryData: []
            }
        case LOADER_STATE_GEO_LEVEL:
            return {
                ...state,
                loaderForGeoLevel: payload,
                geoLevelSummaryData: []
            }
            case SET_GEO_LEVEL:
            return {
                ...state,
                selectedLevelForGeoLevelSummary: payload,
            }
            case SET_PORTFOLIO_LEVEL:
            return {
                ...state,
                selectedLevelForPortfolio: payload,
            }
        default:
            return state
    }
}

export default portfolioGeoLevelReducer