import { Backdrop, Box, Popover, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PieMenu, { Slice, PieCenter } from "react-pie-menu";
import { IMMPWMenu } from "../../types/common";
import { BackBtn } from "./MMPW.Styled";

import UploadIconPicker from "./components/UploadIconPicker";
import IconPicker from "./components/IconPicker";
import ShapePicker from "./components/ShapePicker";
import TextPicker from "./components/TextPicker";
import FooterNotes from "./components/FooterNotes";

import FontFamily from "./components/FontFamily";
import FontSize from "./components/FontSize";
import FontStyle from "./components/FontStyle";
import TextAlign from "./components/TextAlign";

import Flip from "./components/Flip";
import GraphPortions from "./components/GraphPortions";
import HideGrid from "./components/HideGrid";
import RaiseLowerBar from "./components/RaiseLowerBar";
import ShiftAxis from "./components/ShiftAxis";

import PointToPoint from "./components/PointToPoint";
import {
  InsertShape,
  Inserticon,
  TextEdit,
  ChangeGraph,
  ChangeColor,
  Addonsx,
  Comentingx,
  EditText1,
  ChangeClr,
  ShapeIcon1,
  Layout1,
  FooterNotes1,
  ColorPic,
  FontA,
  FontFam,
  FontS,
  TextB,
  TextAs,
  Eye,
  FilterO,
  FlipIcon,
  GridL,
  RaiseB,
  ShiftS,
  BackgroundColor,
  BarChart,
  LineHeight,
  PiaChart,
  SearchIcon,
  LabelIcon,
} from "../../assets/icons/mmpw";
import ColorPicker from "./components/ColorPicker";
import EditLine from "./components/EditLine/EditLine";
import LineBackground from "./components/LineBackground";
import ChartBackground from "./components/ChartBackground";
import SectionColor from "./components/SectionColor";
import _ from "lodash";
import CommentBox from "./components/CommentBox";
import AddLabel from "./components/AddLabel";
import { useDispatch } from "react-redux";
import { removeLabels } from "../../store/actions/common.action";
// import UploadIconPicker from "./components/UploadIconPicker/UploadIconPicker";

const MMPW: React.FC<{
  open?: boolean;
  callback?;
  selectedMenu;
  data?;
  chartRef?;
  dashboardId?;
  config?;
}> = ({
  open,
  callback,
  selectedMenu,
  data,
  chartRef,
  dashboardId,
  config = {},
}) => {
  const centerRadius = "40px";
  const inputFile = useRef(null);
  const [action, setAction] = React.useState<any>();
  const [selectedComponent, setSelectedComponent] = React.useState<any>();
  const [defaultData, setDefaultData] = React.useState<any>({});
  const [chart, setChart] = React.useState<any>(
    chartRef && chartRef.current && chartRef.current.chart
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setDefaultData(data);
    }
  }, [data]);

  const onClick = (e, m) => {
    if (m.subMenu && m.subMenu.length) {
      setMMPWMenus(m.subMenu);
    } else if (
      m.id === "1-3" ||
      m.id === "1-4" ||
      m.id === "1-5" ||
      m.id === "3" ||
      m.id === "1-6" ||
      m.id === "2-3" ||
      m.id === "6"
    ) {
      setSelectedComponent({
        ...m,
        position: {
          x: e.clientX,
          y: e.clientY,
        },
      });
    } else if (m.id === "1-1") {
      setAction({
        ...m,
        position: {
          x: 0,
          y: 0,
        },
      });
      setTimeout(() => {
        inputFile.current.click();
      }, 0);
    } else {
      m.id &&
        setAction({
          ...m,
          position: {
            x: e.clientX,
            y: e.clientY,
          },
        });
    }
  };

  const onHandleClosePopup = () => {
    setAction(null);
    setSelectedComponent(null);
  };

  const onMMPWComponentCallback = (data) => {
    onHandleClosePopup();
    callback(data);
  };

  const onselectTextColor = (previousData, color) => {
    if (_.isArray(previousData)) {
      previousData.map((x) => {
        x.style = {
          ...x.style,
          color,
        };
      });
      setDefaultData(previousData);
    } else {
      const payload = {
        ...defaultData,
        style: {
          ...defaultData.style,
          color,
        },
      };
      setDefaultData(payload);
    }
    callback({ type: "textColor", color });
  };

  const onselectImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      onMMPWComponentCallback({ type: "Image", file: event.target.files[0] });
    }
  };

  const flipAxis = () => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      chart.update({
        chart: {
          inverted: !chart.inverted || false,
        },
      });
      let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
      const mmpwContent =
        dashbaordData && dashbaordData[dashboardId]
          ? dashbaordData[dashboardId]
          : {};
      const payload = {
        ...dashbaordData,
        [dashboardId]: {
          ...mmpwContent,
          chartConfig: {
            ...(mmpwContent && mmpwContent.chartConfig
              ? mmpwContent.chartConfig
              : {}),
            inverted: !chart.inverted || false,
          },
        },
      };
      localStorage.setItem("mmpw", JSON.stringify(payload));
      dispatch(removeLabels(dashboardId));
    }
  };

  const changeMinMax = (axis) => {
    if (axis && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      axis.xAxis.map((x, i) => {
        chart.xAxis[i].update({
          min: _.isString(x.min) ? parseFloat(x.min) : x.min,
          max: _.isString(x.max) ? parseFloat(x.max) : x.max,
        });
      });
      axis.yAxis.map((y, i) => {
        chart.yAxis[i].update({
          min: _.isString(y.min) ? parseFloat(y.min) : y.min,
          max: _.isString(y.max) ? parseFloat(y.max) : y.max,
        });
      });
      let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
      const mmpwContent =
        dashbaordData && dashbaordData[dashboardId]
          ? dashbaordData[dashboardId]
          : {};
      const payload = {
        ...dashbaordData,
        [dashboardId]: {
          ...mmpwContent,
          chartConfig: {
            ...(mmpwContent && mmpwContent.chartConfig
              ? mmpwContent.chartConfig
              : {}),
            axisMinMax: { ...axis },
          },
        },
      };
      localStorage.setItem("mmpw", JSON.stringify(payload));
      window.dispatchEvent(new Event("storage"));
    }
    onHandleClosePopup();
    callback({ type: "close" });
  };

  const getXYMinMax = () => {
    let xAxisData = [];
    const xAxisLables = document
      .getElementById(dashboardId)
      .querySelector(".highcharts-xaxis-labels");
    if (xAxisLables) {
      const xAxisTextTags = Array.from(
        xAxisLables.getElementsByTagName("text")
      );
      if (xAxisTextTags.find((el) => parseFloat(el.innerHTML) == el.innerHTML && !el.innerHTML.toString().includes("<title>"))) {
        xAxisData = chart.xAxis
          .map((x) => ({ min: x.min, max: x.max }))
          .filter((x) => x.min !== undefined && x.max !== undefined);
      }
    }

    let yAxisData = [];
    const yAxisLables = document
      .getElementById(dashboardId)
      .querySelector(".highcharts-yaxis-labels");
    if (yAxisLables) {
      const yAxisTextTags = Array.from(
        yAxisLables.getElementsByTagName("text")
      );
      if (
        yAxisTextTags.findIndex(
          (el) =>
            _.isNaN(parseFloat(el.innerHTML)) &&
            !el.innerHTML.toString().includes("<title>")
        ) === -1
      ) {
        yAxisData = chart.yAxis
          .map((y) => ({ min: y.min, max: y.max }))
          .filter((y) => y.min !== undefined && y.max !== undefined);
      }
    }
    return {
      xAxisData,
      yAxisData,
      disabled: xAxisData.length === 0 && yAxisData.length === 0,
    };
  };

  const allMenus: IMMPWMenu[] = [
    {
      id: "1",
      Icon: Inserticon,
      tooltip: "Insert element",
      disabled:
        config && config.insertElement ? config.insertElement.disabled : false,
      onClick: onClick,
      subMenu: [
        {
          id: "1-1",
          Icon: InsertShape,
          tooltip: "Insert Picture",
          disabled:
            config && config.insertElement && config.insertElement.insertPicture
              ? config.insertElement.insertPicture.disabled
              : false,
          onClick: onClick,
          component: () => {
            return (
              <input
                onChange={onselectImage}
                type="file"
                accept="image/png, image/jpeg"
                hidden
                ref={inputFile}
              />
            );
          },
        },
        {
          id: "1-2",
          Icon: EditText1,
          tooltip: "Upload Icons",
          disabled:
            config && config.insertElement && config.insertElement.uploadIcons
              ? config.insertElement.uploadIcons.disabled
              : false,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <UploadIconPicker callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
        {
          id: "1-3",
          Icon: ChangeClr,
          tooltip: "Icons",
          disabled:
            config && config.insertElement && config.insertElement.icons
              ? config.insertElement.icons.disabled
              : false,
          onClick: onClick,
          component: () => {
            return (
              <Popover
                open={true}
                onClose={onHandleClosePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box style={{ width: 485 }}>
                  <IconPicker callback={onMMPWComponentCallback} />
                </Box>
              </Popover>
            );
          },
        },
        {
          id: "1-4",
          Icon: ShapeIcon1,
          tooltip: "Shapes",
          disabled:
            config && config.insertElement && config.insertElement.shapes
              ? config.insertElement.shapes.disabled
              : false,
          onClick: onClick,
          component: () => {
            return (
              <Popover
                open={true}
                onClose={onHandleClosePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ShapePicker callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
        {
          id: "1-5",
          Icon: Layout1,
          tooltip: "Add Text",
          disabled:
            config && config.insertElement && config.insertElement.addText
              ? config.insertElement.addText.disabled
              : false,
          onClick: onClick,
          component: () => {
            return (
              <Popover
                open={true}
                onClose={onHandleClosePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <TextPicker callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
        {
          id: "1-6",
          Icon: FooterNotes1,
          tooltip: "Footer Notes",
          disabled:
            config && config.insertElement && config.insertElement.footerNotes
              ? config.insertElement.footerNotes.disabled
              : false,
          dimension: {
            width: 80,
            height: 50,
          },
          onClick: onClick,
          component: () => {
            return (
              <Popover
                open={true}
                onClose={onHandleClosePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <FooterNotes callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
      ],
    },
    {
      id: "2",
      Icon: TextEdit,
      tooltip:
        data && _.isArray(data) && data.length > 0
          ? "TextEdit"
          : "TextEdit (Enter the text to apply the text style)",
      disabled: !data || (_.isArray(data) && data.length === 0),
      onClick: onClick,
      subMenu: [
        {
          id: "2-1",
          Icon: FontFam,
          tooltip: "Font Family",
          onClick: onClick,
          component: ({ x, y }, defaultData) => {
            let fontFamily = "Roboto";
            if (_.isArray(defaultData)) {
              let firstFontFamily = defaultData[0].style
                ? defaultData[0].style.fontFamily
                : null;
              if (
                defaultData.findIndex(
                  (x) => x.style && x.style.fontFamily !== firstFontFamily
                ) === -1
              ) {
                fontFamily = firstFontFamily;
              }
            } else if (defaultData.style) {
              fontFamily = defaultData.style.fontFamily;
            }
            return (
              <Popover open={true} style={{ top: y, left: x }}>
                <FontFamily
                  fontFamily={fontFamily}
                  callback={onMMPWComponentCallback}
                />
              </Popover>
            );
          },
        },
        {
          id: "2-2",
          Icon: FontS,
          tooltip: "Font Size",
          onClick: onClick,
          component: ({ x, y }, defaultData) => {
            let fontSize = null;
            if (_.isArray(defaultData)) {
              let firstFontSize = defaultData[0].style
                ? defaultData[0].style.fontSize
                : null;
              if (
                defaultData.findIndex(
                  (x) => x.style && x.style.fontSize !== firstFontSize
                ) === -1
              ) {
                fontSize = firstFontSize;
              }
            } else if (defaultData.style) {
              fontSize = defaultData.style.fontSize;
            }
            return (
              <Popover open={true} style={{ top: y, left: x }}>
                <FontSize
                  fontSize={fontSize}
                  callback={onMMPWComponentCallback}
                />
              </Popover>
            );
          },
        },
        {
          id: "2-3",
          Icon: FontA,
          tooltip: "Font Color",
          onClick: onClick,
          component: (defaultData) => {
            let color = "#000";
            if (_.isArray(defaultData)) {
              let firstColor = defaultData[0].style
                ? defaultData[0].style.color
                : null;
              if (
                defaultData.findIndex(
                  (x) => x.style && x.style.color !== firstColor
                ) === -1
              ) {
                color = firstColor;
              }
            } else if (defaultData.style) {
              color = defaultData.style.color;
            }
            return (
              <Popover
                open={true}
                onClose={onHandleClosePopup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ColorPicker
                  color={color}
                  callback={(color) => onselectTextColor(data, color)}
                />
              </Popover>
            );
          },
        },
        {
          id: "2-4",
          Icon: TextB,
          tooltip: "Font Style",
          onClick: onClick,
          component: ({ x, y }, defaultData) => {
            let fontWeight = "";
            let fontStyle = "";
            let textDecoration = "";
            if (_.isArray(defaultData)) {
              let firstFontWeight = defaultData[0].style
                ? defaultData[0].style.fontWeight
                : null;
              if (
                defaultData.findIndex(
                  (x) => x.style && x.style.fontWeight !== firstFontWeight
                ) === -1
              ) {
                fontWeight = firstFontWeight;
              }

              let firstFontStyle = defaultData[0].style
                ? defaultData[0].style.fontStyle
                : null;
              if (
                defaultData.findIndex(
                  (x) => x.style && x.style.fontStyle !== firstFontStyle
                ) === -1
              ) {
                fontStyle = firstFontStyle;
              }

              let firstTextDecoration = defaultData[0].style
                ? defaultData[0].style.textDecoration
                : null;
              if (
                defaultData.findIndex(
                  (x) =>
                    x.style && x.style.textDecoration !== firstTextDecoration
                ) === -1
              ) {
                textDecoration = firstTextDecoration;
              }
            } else if (defaultData.style) {
              fontWeight = defaultData.style.fontWeight;
              fontStyle = defaultData.style.fontStyle;
              textDecoration = defaultData.style.textDecoration;
            }
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <FontStyle
                  style={{
                    fontWeight,
                    fontStyle,
                    textDecoration,
                  }}
                  callback={onMMPWComponentCallback}
                />
              </Popover>
            );
          },
        },
        {
          id: "2-5",
          Icon: TextAs,
          tooltip: "Text Alignment",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <TextAlign />
              </Popover>
            );
          },
        },
        {
          id: "2-6",
          Icon: ColorPic,
          tooltip: "Highlight Color",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <></>
              </Popover>
            );
          },
        },
      ],
    },
    {
      id: "3",
      Icon: ChangeGraph,
      tooltip: "Visualization Elements",
      onClick: onClick,
      subMenu: [
        {
          id: "3-1",
          Icon: FlipIcon,
          tooltip: "Flip",
          disabled:
            chart.series.findIndex(
              (s) => s.type === "bar" || s.type === "pie"
            ) !== -1 ||
            (_.isArray(chart.yAxis) &&
              chart.yAxis.filter((a) => a.series.length > 0).length > 1),
          onClick: flipAxis,
        },
        {
          id: "3-2",
          Icon: Eye,
          tooltip: "Graph Portions",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <GraphPortions />
              </Popover>
            );
          },
        },
        {
          id: "3-3",
          Icon: GridL,
          tooltip: "Hide Grid Line",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <HideGrid />
              </Popover>
            );
          },
        },
        {
          id: "3-4",
          Icon: RaiseB,
          tooltip: "Raise Lower Bar",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <RaiseLowerBar />
              </Popover>
            );
          },
        },
        {
          id: "3-5",
          Icon: ShiftS,
          tooltip: "Change Min and Max",
          disabled: getXYMinMax().disabled,
          onClick: onClick,
          component: ({ x, y }) => {
            const { xAxisData, yAxisData } = getXYMinMax();
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <ShiftAxis
                  callback={changeMinMax}
                  xAxisData={xAxisData}
                  yAxisData={yAxisData}
                />
              </Popover>
            );
          },
        },
        {
          id: "3-6",
          Icon: FilterO,
          tooltip: "Sort Data",
          disabled: true,
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <></>
              </Popover>
            );
          },
        },
        {
          id: "3-7",
          Icon: LabelIcon,
          tooltip: "Add Label",
          disabled: chart.series[0].type === "pie" ? true : false,
          onClick: onClick,
          component: ({ x, y }) => {
            const payload = [];
            if (chart) {
              chart.series.forEach((item, i) => {
                if (item.visible) {
                  if (
                    item.type === "spline" ||
                    item.type === "scatter" ||
                    item.type === "area" ||
                    item.type === "line" ||
                    item.type === "errorbar" ||
                    item.type === "bar" ||
                    item.type === "bubble" ||
                    (item.type === "column" && !item?.userOptions?.colorByPoint)
                  ) {
                    payload.push({
                      seriesIndex: i,
                      title: item.name,
                    });
                  }
                }
              });
            }
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <AddLabel data={payload} chart={chart} id={dashboardId} />
              </Popover>
            );
          },
        },
      ],
    },
    {
      id: "4",
      Icon: SearchIcon,
      tooltip: "Analytics Add On",
      disabled: true,
      onClick: onClick,
      subMenu: [
        {
          id: "4-1",
          Icon: InsertShape,
          tooltip: "Point to Point",
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <PointToPoint />
              </Popover>
            );
          },
        },
      ],
    },
    {
      id: "5",
      Icon: ChangeColor,
      tooltip: "Add color",
      onClick: onClick,
      subMenu: [
        {
          id: "5-1",
          Icon: LineHeight,
          tooltip:
            chart.series.findIndex((x) => x.options.lineWidth) !== -1
              ? "Line Width"
              : "Not available for this chart",
          disabled: chart.series.findIndex((x) => x.options.lineWidth) === -1,
          onClick: onClick,
          component: ({ x, y }, defaultData) => {
            const payload = [];
            if (chart) {
              chart.series.forEach((item, i) => {
                if (
                  item.visible &&
                  item.options &&
                  (item.options.lineWidth || item.options.borderWidth)
                ) {
                  payload.push({
                    index: i,
                    title: item.name,
                    lineWidth:
                      item.options.borderWidth || item.options.lineWidth,
                  });
                }
              });
            }
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <EditLine data={payload} callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
        {
          id: "5-2",
          Icon: BackgroundColor,
          tooltip:
            chart.series.findIndex((x) => x.options.borderColor) !== -1
              ? "Outline Color"
              : "Only available for bar charts",
          disabled: chart.series.findIndex((x) => x.options.borderColor) === -1,
          onClick: onClick,
          component: ({ x, y }) => {
            const payload = [];
            if (chart) {
              chart.series.forEach((item, i) => {
                if (item.options && item.options.borderColor) {
                  payload.push({
                    index: i,
                    title: item.name,
                    borderColor: item.options.borderColor,
                  });
                }
              });
            }
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <LineBackground
                  data={payload}
                  callback={onMMPWComponentCallback}
                />
              </Popover>
            );
          },
        },
        {
          id: "5-3",
          Icon: BarChart,
          tooltip: "Chart Background",
          onClick: onClick,
          component: ({ x, y }) => {
            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <ChartBackground callback={onMMPWComponentCallback} />
              </Popover>
            );
          },
        },
        {
          id: "5-4",
          Icon: PiaChart,
          tooltip: "Chart section color",
          disabled:
            config && config.addColor && config.addColor.sectionColor
              ? config.addColor.sectionColor.disabled
              : false,
          onClick: onClick,
          component: ({ x, y }) => {
            const payload = [];
            if (chart) {
              chart.series.forEach((item, i) => {
                if (
                  item.visible &&
                  (!Object.keys(config).length ||
                    (Object.keys(config).length &&
                      config.addColor &&
                      config.addColor.sectionColor[item.type]))
                ) {
                  if (
                    item.type === "spline" ||
                    item.type === "scatter" ||
                    item.type === "area" ||
                    item.type === "line" ||
                    item.type === "errorbar" ||
                    item.type === "bar" ||
                    item.type === "bubble" ||
                    (item.type === "column" && !item?.userOptions?.colorByPoint)
                  ) {
                    payload.push({
                      type: "series",
                      title: item.name,
                      color:
                        item.userOptions.color !== undefined
                          ? item.userOptions.color
                          : item.color
                          ? item.color
                          : "",
                    });
                  } else {
                    item.data.map((slice) => {
                      payload.push({
                        type: "series",
                        title: slice.name,
                        color: slice.color,
                      });
                    });
                  }
                }
              });

              //get plotted line from chart on xAxis
              if (_.isArray(chart.xAxis)) {
                chart.xAxis.map((axis, index) => {
                  if (
                    _.isArray(axis.plotLinesAndBands) &&
                    axis.plotLinesAndBands.length > 0
                  ) {
                    axis.plotLinesAndBands.map((item, i) => {
                      payload.push({
                        xAxisIndex: index,
                        lineIndex: i,
                        type: "xAxisPlotLine",
                        title: `X Axis Line ${i + 1}`,
                        color:
                          item.options.color !== undefined
                            ? item.options.color
                            : "",
                      });
                    });
                  }
                });
              }
              //get plotted line from chart on yAxis
              if (_.isArray(chart.yAxis)) {
                chart.yAxis.map((axis, index) => {
                  if (
                    _.isArray(axis.plotLinesAndBands) &&
                    axis.plotLinesAndBands.length > 0
                  ) {
                    axis.plotLinesAndBands.map((item, i) => {
                      payload.push({
                        yAxisIndex: index,
                        lineIndex: i,
                        type: "yAxisPlotLine",
                        title: `Y Axis Line ${i + 1}`,
                        color:
                          item.options.color !== undefined
                            ? item.options.color
                            : "",
                      });
                    });
                  }
                });
              }
            }

            return (
              <Popover
                open={true}
                style={{ top: y, left: x }}
                onClose={onHandleClosePopup}
              >
                <SectionColor
                  data={payload}
                  callback={onMMPWComponentCallback}
                />
              </Popover>
            );
          },
        },
      ],
    },
    {
      id: "6",
      Icon: Comentingx,
      tooltip: "Add Comment",
      onClick: onClick,
      component: () => {
        return (
          <Popover
            open={true}
            onClose={onHandleClosePopup}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <CommentBox
              dashboardId={dashboardId}
              callback={onMMPWComponentCallback}
            />
          </Popover>
        );
      },
    },
  ];

  const getParentMenu = (ids, menus) => {
    if (ids.length > 1) {
      const menu = menus.find((m) => m.id == ids[0]);
      ids.shift();
      return getParentMenu(ids, menu.subMenu);
    }
    return menus;
  };

  const [menus, setMenus] = React.useState(
    getParentMenu(selectedMenu.split("-"), allMenus)
  );
  const [mmpwMenus, setMMPWMenus] = React.useState(menus);

  const onBackBtnClick = () => {
    const ids = mmpwMenus[0].id.split("-");
    if (ids.length === selectedMenu.split("-").length) {
      callback({ type: "close" });
    } else {
      ids.pop();
      const filterMenu = getParentMenu(ids, menus);
      setMMPWMenus(filterMenu);
    }
  };

  const Center = (props) => (
    <PieCenter {...props}>
      <BackBtn onClick={onBackBtnClick} style={{ borderRadius: centerRadius }}>
        {`< Back`}
      </BackBtn>
    </PieCenter>
  );
  return (
    <>
      {!selectedComponent ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <PieMenu centerRadius={centerRadius} Center={Center}>
            {mmpwMenus.map((m, i) => {
              return (
                <Slice
                  backgroundColor={m.disabled ? "#c1c1c1" : "#fff"}
                  highlight={m.disabled ? "#c1c1c1" : "#25d4cf"}
                  key={i}
                >
                  <Tooltip
                    title={m.tooltip}
                    // disableHoverListener={m.disabled}
                    // disableFocusListener={m.disabled}
                  >
                    <Box
                      onClick={(e) => !m.disabled && m.onClick(e, m)}
                      style={{ color: m.disabled ? "#000" : "#fff" }}
                    >
                      <img
                        style={{
                          width: m.dimension ? m.dimension.width : "30px",
                          height: m.dimension ? m.dimension.height : "30px",
                        }}
                        data-testid="image"
                        src={m.Icon}
                        alt="graphCard"
                      />
                    </Box>
                  </Tooltip>
                  <Box style={{ position: "relative" }}>
                    {action &&
                      action.id === m.id &&
                      m.component(action.position, defaultData)}
                  </Box>
                </Slice>
              );
            })}
          </PieMenu>
        </Backdrop>
      ) : (
        selectedComponent.component(defaultData)
      )}
    </>
  );
};
export default MMPW;
