import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  downloadAddManualSku,
  uploadAddManualSku,
} from "../../../../util/services";
import { toast } from "../../../../store/actions/toast.action";
import { API_URL, BACKEND_URL } from "../../../../store/actions/constants";
import Spinner from "../../../AssortmentTool/components/Loader/Loader";
import {
  fetchOrDownloadMixUserConstraintsData,
  uploadCapacitiveComplexityFile,
  uploadMixUserConstraintsData,
  uploadOperativeComplexityFile,
} from "../../../../store/actions/MixSimulationTool/mixUserConstraints.action";
import Papa from "papaparse"
import { closeIcon } from "../../../../assets/icons/common";
import _ from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const responseDownloadWait = "Please wait! Downloading CSV File...";
const responseUploadWait = "Please wait! Uploading CSV File...";

export const UserConstraintsPopup = ({ target, isOpen, setIsOpen }) => {
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();
  const { uid, selectedGoal } = useSelector(
    (state: any) => state.mixSimulationUserInput
  );
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );
  const { constraintsLoader, csvDataForCapacity, csvDataForOperative } =
    useSelector((state: any) => state.MixUserConstraintsReducer);

  const [loaderText, setLoaderText] = useState("");
  const onCloseHandler = () => {
    setIsOpen(false);
  };
  // console.log(target)
  const handleDialog = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleUpload = (event) => {
    setLoaderText(responseUploadWait);
    const file = event.target.files[0];
    const reader = new FileReader();
    const commanHeader = ["id", "Segment", "Brand", "SKU"];
    reader.onload = (e) => {
      const content = e.target?.result as string;;
      const lines = content.split('\n');
      const requiredHeaders = target == "Capacity Constraint" ? [...commanHeader, "Capacity Constraint"] : [...commanHeader, "Operative Complexity"]

      // Extract headers from the first line of the CSV
      const headers = lines[0].trim().split(',');

      // Check if all required headers are present
      const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));

      if (missingHeaders.length > 0) {
        alert('The following fields are missing in the CSV: ' + missingHeaders.join(', '));
      } else {
        let uploadedFile = convertCSVtoJson(content)
        const updatedfile = isValidFileUploaded(target == 'Operative Complexity' ? csvDataForOperative : csvDataForCapacity, uploadedFile)
        if (updatedfile.isValid) {
          const fileWithoutIdColumn = updatedfile.data.map((row) => {
            delete row['id']
            return row
          })
          // console.log("fileWithoutIdColumn", fileWithoutIdColumn)
          const csvFile = Papa.unparse(fileWithoutIdColumn)
          if (target == "Capacity Constraint") {
            dispatch(uploadCapacitiveComplexityFile(csvFile));
          } else {
            dispatch(uploadOperativeComplexityFile(csvFile));
          }
          dispatch(toast('Validation successful.', true, 2000, 'success'));

        } else {
          dispatch(toast(`Invalid File only ${target} column is editable`, true, 2000, 'error'));
        }

      }
    };

    reader.readAsText(file);
    setIsOpen(false);
    // setLoaderText("");
  };
  const isValidFileUploaded = (downloadedFile, uploadedFile) => {
    // console.log(downloadedFile)
    // console.log(uploadedFile)
    const validFile = _.map(downloadedFile, downloadObject => {
      const modifiedObj = _.find(uploadedFile, { 'id': downloadObject.id.toString() });
      console.log(modifiedObj)
      if (modifiedObj) {
        let targetValue = modifiedObj[target];
        if (isNaN(parseFloat(targetValue))) {
          return {}
        }
        else {
          let keysToCheck = _.omit(modifiedObj, [target])
          console.log(keysToCheck)
          let valuesMatch = _.isEqual(_.pick(downloadObject, _.keys(keysToCheck)), keysToCheck)
          console.log(valuesMatch)
          if (valuesMatch) {
            return {
              ...downloadObject,
              [target]: modifiedObj[target] !== undefined ? modifiedObj[target] : downloadObject[target],
            };
          } else {
            return {}
          }
        }
      } else {
        return {}
      }
    })

    return { isValid: !_.some(validFile, _.isEmpty), data: validFile }
  }

  const convertCSVtoJson = (file) => {
    const lines = file.trim().split('\n').map(line => line.trim());
    const headers = lines[0].split(',');
    const jsonData: any = [];
    lines.slice(1).forEach((line, index) => {
      const data = line.split(',');
      const entry = {};

      headers.forEach((header, columnIndex) => {
        entry[header] = data[columnIndex];
      });

      jsonData.push(entry);
    });
    return jsonData;
  }
  const closeDialog = () => {
    setIsOpen(false);
  };

  const downloadExcel = () => {
    setLoaderText(responseDownloadWait);
    let payload = {
      overallFilters: {
        country: selectedFilterData.country[0],
        dataTimePeriod: selectedFilterData.period[0],
        businessUnit: selectedFilterData.businessUnit[0],
      },
      geoFilters: {
        channel: selectedFilterData.channel,
        region: selectedFilterData.region,
        storeSegment: selectedFilterData.storeSegment,
        store: selectedFilterData.store,
      },
      goal: selectedGoal,
    };

    if (target === "Capacity Constraint") {
      dispatch(
        fetchOrDownloadMixUserConstraintsData(
          target,
          payload,
          API_URL.MIXSIMULATION.DOWNLOAD_CSV_FILE_CAPACITY_CONSTRAINT
        )
      );
    } else {
      dispatch(
        fetchOrDownloadMixUserConstraintsData(
          target,
          payload,
          API_URL.MIXSIMULATION.DOWNLOAD_CSV_FILE_OPERATVE_COMPLEXITY
        )
      );
    }
  };



  return (
    <Dialog
      open={isOpen}
      onClose={onCloseHandler}
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-paper": {
          height: "30vh",
        },
        zIndex: 900
      }}
    >
      <DialogTitle
        style={{ position: "relative" }}
        sx={{
          backgroundColor: "#F1F5FE",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {target}
        <img
          style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
          src={closeIcon}
          alt="X"
          onClick={closeDialog}
        />
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#F1F5FE", height: "100px" }}>
        <Grid
          style={{
            position: "relative",
          }}
        >
          {constraintsLoader ? (
            <Grid style={{ marginTop: "80px" }}>
              <Spinner
                position="absolute"
                show={constraintsLoader}
                text={loaderText}
              />
            </Grid>
          ) : (
            <Grid
              container
              sx={{ marginTop: "3rem" }}
              display="flex"
              justifyContent={"center"}
              gap={"3rem"}
            >
              <PrimaryBtn color="primary" onClick={downloadExcel}>
                <FileDownloadOutlinedIcon fontSize="small" />
                Download
              </PrimaryBtn>
              <PrimaryBtn disabled={target == 'Operative Complexity' ? csvDataForOperative.length == 0 : csvDataForCapacity.length == 0} component="label" color="primary">
                <FileUploadOutlinedIcon fontSize="small" />
                Upload File
                <VisuallyHiddenInput
                  onChange={handleUpload}
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </PrimaryBtn>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
