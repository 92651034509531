import { GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS, GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS_SUCESS, GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS_FAILURE } from "../../actions/APIPredictiveDashboard/constants";


let correlationInitialState = {
  data: null,
  loading: true,
  error: null
}

const APIPredictivePepsiVsNonCompetitorsChart = (state = correlationInitialState, action) => {
  switch (action.type) {
    case GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS:
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: null,
      };
    case GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS_SUCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null
      };
    case GET_API_PREDICTIVE_DASH_PEPSI_VS_NON_COMPETITORS_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default APIPredictivePepsiVsNonCompetitorsChart;