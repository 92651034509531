import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighchartContainer from "../../../../components/HighchartContainer";
import ColumnDrilldown from "../../../../components/charts/ColumnDrilldown";
import { useSelector } from "react-redux";
import Indicator from "../../../../components/Loader/Loader";

export const MustHaveCoverage = ({ data, selectedGeoFilters }) => {
  const chartRef = useRef(null);
  const {
    mustHaveSku,
    sizeOfPrice,
    summaryData,
    loaderForMustHaveSku,
    viewMode,
  } = useSelector((state: any) => state.assortmentDashboardReducer);

  const formatValue = (val) => {
    const formattedValue = parseFloat(Math.abs(val).toFixed(0));
    return val < 0 ? -formattedValue : formattedValue;
  };

  const [coverageData, setCoverageData] = useState(data);
  const formatValueToCoverage = (data) => {
    let updatedChartData = {
      columnData: [
        formatValue(data["goodToHaveSalesMix"]),
        formatValue(data["goodToHaveNopbtMix"]),
      ],
      areaData: [
        formatValue(data["mustHaveSalesMix"]),
        formatValue(data["mustHaveNopbtMix"]),
      ],
      xAxisData: ["Revenue Mix", "NOPBT Mix"],
    };
    return updatedChartData;
  };
  useEffect(() => {
    if (mustHaveSku) {
      const data = formatValueToCoverage(mustHaveSku);
      setCoverageData(data);
    }
  }, [mustHaveSku, summaryData, sizeOfPrice]);

  return (
    <Card
      style={{
        marginTop: "1rem",
        backgroundColor: "#F1F5FE",
        position: "relative",
      }}
    >
      <Indicator position="absolute" show={loaderForMustHaveSku} />
      <CardContent>
        {!viewMode ? (
          <Grid
            className="p-l-16"
            style={{ marginTop: "2px" }}
            container
            spacing={2}
          >
            <Skeleton variant="rectangular" width="100%" height={145} />
          </Grid>
        ) : (
          <HighchartContainer
            chartRef={chartRef}
            id={"must_have_good_have_coverage"}
          >
            <ColumnDrilldown
              data={coverageData}
              chartRef={chartRef}
              barSeries={true}
            />
          </HighchartContainer>
        )}
      </CardContent>
    </Card>
  );
};
