import Highcharts from 'highcharts';

const chartConfig = {
  chart: {
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 1,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: "green",
      data: [76, 89, 97, 120, 279, 400, 600],
    },
    {
      color: "red",
      data: [45, 34, 76, 87, 200, 430, 530],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
const pieChartConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "Browser market shares in January, 2018",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
    },
  },
  series: [
    {
      name: "Brands",
      colorByPoint: true,
      data: [
        {
          name: "Chrome",
          y: 61.41,
          sliced: true,
          selected: true,
        },
        {
          name: "Internet Explorer",
          y: 11.84,
        },
        {
          name: "Firefox",
          y: 10.85,
        },
        {
          name: "Edge",
          y: 4.67,
        },
        {
          name: "Safari",
          y: 4.18,
        },
        {
          name: "Sogou Explorer",
          y: 1.64,
        },
        {
          name: "Opera",
          y: 1.6,
        },
        {
          name: "QQ",
          y: 1.2,
        },
        {
          name: "Other",
          y: 2.61,
        },
      ],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const mixChartConfig = {
  chart: {
    backgroundColor: "transparent",
  },
  title: null,
  xAxis: {
    gridLineWidth: 1,
    categories: [],
    labels: {
      formatter: function () {
        return `$${this.value}`;
      },
    },
  },
  yAxis: [
    {
      lineWidth: 1,
      title: {
        text: "Units Variation %",
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(2);
        },
      },
    },
    {
      lineWidth: 1,
      opposite: true,
      title: null,
    },
  ],
  plotOptions: { series: { marker: false } },
  series: [
    {
      type: "column",
      name: "Elasticity Projections",
      data: [100, 150, 200, 250, 320, 350],
      color: "#598ee3",
    },
    {
      type: "column",
      name: "Historical Price Point Incrementality",
      data: [80, 100, 170, 200, 280, 300],
      color: "#d41165",
    },
    {
      type: "line",
      name: "Gross Profit Breakeven",
      data: [120, 160, 210, 280, 290, 320],
      color: "#00AA52",
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: false,
};

const columnDrilldownConfig = {
  chart: {
    backgroundColor: "transparent",
  },
  title: null,
  xAxis: {
    min: 0,
    gridLineWidth: 1,
  },
  yAxis: [
    {
      lineWidth: 1,
      title: {
        text: "Unit Sales",
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      },
    },
    {
      lineWidth: 1,
      opposite: true,
      title: {
        text: "Number of Weeks at This Price Point",
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFF",
        lineWidth: 2,
        lineColor: null,
        symbol: "circle",
      },
    },
    area: {
      color: "#F5B7D6",
    },
    column: {
      color: "#598ee3",
      pointWidth: 20,
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      type: "area",
      name: "Unit Sales",
      data: [100, 120, 135, 146, 189, 210],
    },
    {
      yAxis: 1,
      type: "column",
      name: "Number of Weeks at This Price Point",
      data: [103, 120, 138, 155, 176, 199],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  credits: false,
};

const stackCloumnConfig = {
  chart: {
    type: "column",
    height: 650,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
    align: "left",
  },
  xAxis: {
    categories: ["Jun 2021", "Jul 2021", "Aug 2021", "Sep 2021"],
    labels: {
      rotation: -45,
    },
  },
  yAxis: {
    min: 0,
    title: {
      enabled: false,
      text: "Count trophies",
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: "bold",
        color: "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    backgroundColor: "white",
    borderColor: "#CCC",
    borderWidth: 1,
    shadow: false,
    symbolWidth: 16,
    symbolRadius: 0,
    squareSymbol: false,
  },
  tooltip: {
    headerFormat: "<b>{point.x}</b><br/>",
    pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      pointWidth: 45,
    },
  },
  series: [
    {
      name: "stacked",
      data: [4, 3, 5, 6],
    },
    {
      name: "Lays",
      data: [4, 7, 2, 4],
    },
    {
      name: "Sabritas PC",
      data: [3, 5, 7, 6],
    },
    {
      name: "Ruffles",
      data: [4, 2, 1, 6],
    },
    {
      name: "Doritos",
      data: [4, 3, 2, 1],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

  const waterfallConfig = {
    chart: {
      type: 'waterfall',
      backgroundColor: '#FAFAFA',
    },
    credits: { enabled: false },
    title: {
      text: '',
    },
    tooltip: {
      enable: true,
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      backgroundColor: 'transparent',
      align: 'center',
      verticalAlign: 'top',
      symbolHeight: 20,
      symbolWidth: 20,
      symbolRadius: 0,
      itemStyle: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0
      },
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    series: [{
      upColor: Highcharts.getOptions().colors[2],
      color: Highcharts.getOptions().colors[3],
      data: [{
          name: 'Start',
          y: 120000
      }, {
          name: 'Product Revenue',
          y: 569000
      }, {
          name: 'Service Revenue',
          y: 231000
      }, {
          name: 'Positive Balance',
          isIntermediateSum: true,
          color: Highcharts.getOptions().colors[1]
      }, {
          name: 'Fixed Costs',
          y: -342000,
          color: Highcharts.getOptions().colors[3],
      }, {
          name: 'Variable Costs',
          y: -233000,
          color: Highcharts.getOptions().colors[3],
      }, {
          name: 'Balance',
          isSum: true,
          color: Highcharts.getOptions().colors[1]
      }],
    }],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  }

  const scrollbarChart = {
    chart: {
      type: "bar",
      scrollablePlotArea: {
        minHeight: 60*18,
        opacity: 1,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "center",
      style: {
        display: "none",
      },
    },
    xAxis: {
      categories: [],
      zIndex: 0,
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        enabled: false,
        text: " ",
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointWidth: 30,
      },
      bar: {
        stacking: "normal",
        horizontal: true,
        dataLabels: {
          // allowOverlap: true,
          enabled: true,
        },
      },
    },
    series: [
      {
        color: "#d41165",
        name: "Competitor",
        data: [100, 120, 135, 146, 189, 210, 100, 120, 135, 146, 189, 210, 100, 120, 135, 146, 189, 210],
      },
      {
        color: "#598ee3",
        name: "PepsiCo",
        data: [103, 120, 138, 155, 176, 199, 103, 120, 138, 155, 176, 199, 103, 120, 138, 155, 176, 199],
      },
    ],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  const bubbleChartConfig = {
    chart: {
      type: "bubble",
      // width: window.innerWidth - 160,
      // height: window.innerHeight - 100,
      events: {
        load() {
          let chart = this;
          if (!chart.xAxis[0].tickPositions.includes(0)) {
            chart.update({
              xAxis: {
                min: 0,
              },
            });
          }
          if (!chart.yAxis[0].tickPositions.includes(0)) {
            chart.update({
              yAxis: {
                min: 0,
              },
            });
          }
        },
      },
    },
    credits: { enabled: false },
    title: {
      text: "",
    },  
    legend: {
      enabled: true,
      layout: "horizontal",
      backgroundColor: "transparent",
      align: "center",
      verticalAlign: "top",
      symbolHeight: 20,
      symbolWidth: 20,
      symbolRadius: 0,
      itemStyle: {
        fontSize: "16px",
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
    xAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        enabled: true,
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        enabled: true,
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
  
    series: [{
      data: [
          { x: 95, y: 95, z: 13.8, name: 'BE', country: 'Belgium' },
          { x: 86.5, y: 102.9, z: 14.7, name: 'DE', country: 'Germany' },
          { x: 80.8, y: 91.5, z: 15.8, name: 'FI', country: 'Finland' },
          { x: 80.4, y: 102.5, z: 12, name: 'NL', country: 'Netherlands' },
          { x: 80.3, y: 86.1, z: 11.8, name: 'SE', country: 'Sweden' },
          { x: 78.4, y: 70.1, z: 16.6, name: 'ES', country: 'Spain' },
          { x: 74.2, y: 68.5, z: 14.5, name: 'FR', country: 'France' },
          { x: 73.5, y: 83.1, z: 10, name: 'NO', country: 'Norway' },
          { x: 71, y: 93.2, z: 24.7, name: 'UK', country: 'United Kingdom' },
          { x: 69.2, y: 57.6, z: 10.4, name: 'IT', country: 'Italy' },
          { x: 68.6, y: 20, z: 16, name: 'RU', country: 'Russia' },
          { x: 65.5, y: 126.4, z: 35.3, name: 'US', country: 'United States' },
          { x: 65.4, y: 50.8, z: 28.5, name: 'HU', country: 'Hungary' },
          { x: 63.4, y: 51.8, z: 15.4, name: 'PT', country: 'Portugal' },
          { x: 64, y: 82.9, z: 31.3, name: 'NZ', country: 'New Zealand' }
      ],
      colorByPoint: true
  }],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

export {
  chartConfig,
  pieChartConfig,
  mixChartConfig,
  columnDrilldownConfig,
  stackCloumnConfig,
  waterfallConfig,
  scrollbarChart,
  bubbleChartConfig
};
