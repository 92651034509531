import { API } from "../../../util/helper";
import {
  MIX_SUMMARY_DATA_REQUEST,
  MIX_SUMMARY_DATA_SUCCESS,
  MIX_SUMMARY_DATA_FAILURE,
  BACKEND_URL,
} from "../constants";

export const fetchMixSummaryDataRequest = () => ({
  type: MIX_SUMMARY_DATA_REQUEST,
});

export const fetchMixSummaryDataSuccess = (data) => ({
  type: MIX_SUMMARY_DATA_SUCCESS,
  payload: data,
});

export const fetchMixSummaryDataFailure = (error) => ({
  type: MIX_SUMMARY_DATA_FAILURE,
  payload: error,
});

export const fetchMixSummaryData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchMixSummaryDataRequest());

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/${apiUrl}`,
        data
      );
      dispatch(fetchMixSummaryDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchMixSummaryDataFailure(error.message));
    }
  };
};
