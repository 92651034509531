import { formatNumber, formatNumberToOne } from "../../../../util/helper";

export const chartConfig = {
  chart: {
    type: "bar",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
    align: "center",
    style: {
      display: "none",
    },
  },
  xAxis: {
    categories: [],
    zIndex: 0,
    scrollbar: {
      enabled: true,
    },
  },
  yAxis: {
    title: {
      enabled: false,
      text: " ",
    },
    stackLabels: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    formatter: function () {
      return `<b>${this.x}</b><br/>${this.point.series.name}: ${formatNumber(
        this.point.y,
        1
      )}<br/>Total: ${formatNumber(this.point.stackTotal, 1)}`;
    },
  },
  plotOptions: {
    series: {
      pointWidth: 30,
    },
    bar: {
      stacking: "normal",
      horizontal: true,
      dataLabels: {
        // allowOverlap: true,
        enabled: true,
        formatter: function () {
          return formatNumber(this.y, 1);
        },
      },
    },
  },
  series: [
    {
      color: "#d41165",
      name: "Competitor",
      data: [],
    },
    {
      color: "#598ee3",
      name: "PepsiCo",
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
