import React, { useEffect, useState } from "react";
import DataGridTables from "../../../../components/ExecutionDataTable/DataGridTables";
import { geoLevelSummaryColumn } from "../Portfolio/portfolioTableConfig";
import {
  alignEnd,
  center,
  styleForDataGrid,
} from "../Portfolio/portfolioTableStyled";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCSVDownloader } from "react-papaparse";
import { handleDownloadCSV } from "../helper";
import {
  dispatchState,
  fetchApi,
  geoLevelSummaryLoader,
  geoLevelSummaryResponse,
  setGeoLevel,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";

import GeoLevelFilter from "./GeoLevelFilter";
import Indicator from "../../../../components/Loader/Loader";
import { API_URL } from "../../../../store/actions/constants";

const GeoLevelSummary = () => {
  const filterableColumns = ["chnl", "place", "segment"];
  const sortableColumns = [
    "revenuMix",
    "netRevenueSOP",
    "nonPBT_SOP",
    "avgStorePenetration",
    "executionScore",
    "mustHaveDistributionScore",
    "goodToHaveDistributionScore",
    "assortmentOverall",
    "mixScore",
    "netPriceRealisationScore",
    "priceChangeVsRestOfTheMarketScore",
    "priceChangeVsInflationScore",
    "priceScore",
    "exhibitionScore",
    "shelfSpaceScore",
    "shelfAndMerchandizingScore",
    "revenueMixResult",
    "profitMixResult",
    "volumeMixResult",
  ];
  const overallFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const selectedTableLevelFilters = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.selectedFilters
  );
  const loader = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.geoLoading
  );
  const executionUserInputs = useSelector(
    (state: any) => state.executionUserInputs
  );
  const levels = [
    {
      name: "Channel",
      value: "channel",
    },
    {
      name: "Region",
      value: "region",
    },
    {
      name: "Store Segment",
      value: "segment",
    },
  ];
  const dispatch = useDispatch();

  const [selectedLevel, setSelectedLevel] = useState("segment");
  const { CSVDownloader } = useCSVDownloader();
  const {
    geoLevelSummaryData,
    loaderForGeoLevel,
    selectedLevelForGeoLevelSummary,
  } = useSelector((state: any) => state.portfolioGeoLevelReducer);
  const { loadingStateForAll } = useSelector(
    (state: any) => state.overviewscore
  );
  useEffect(() => {
    setSelectedLevel(selectedLevelForGeoLevelSummary);
  }, [selectedLevelForGeoLevelSummary]);

  const createRunSimulationPayload = (
    overallSelectedFilters,
    userInputFilter,
    level,
    levelFilters
  ) => {
    let filterData = {
      overallFilters: {
        period: overallSelectedFilters.period,
        businessUnit: overallSelectedFilters.businessUnit,
        country: [overallSelectedFilters.country[0].toUpperCase()],
      },
      geo: {
        channel: overallSelectedFilters.channel,
        region: overallSelectedFilters.region,
        storeSegment: overallSelectedFilters.storeSegment,
        store: overallSelectedFilters.store,
      },
      portfolio: {
        Sku: levelFilters.sku,
        Brand: levelFilters.brand,
        Segment: levelFilters.segment,
      },
      userInputFilter: {
        decisionVariable: {
          assortmentScenarioName:
            userInputFilter.selectedData.assortment_scenario,
          mixScenarioName: userInputFilter.selectedData.mix_scenario,
        },
        assortmentsTargets: {
          goodToHaveSkus: [userInputFilter.weights.goodToHaveSku],
          mustHaveSKUs: [userInputFilter.weights.mustHaveSku],
        },
      },
      level: level,
    };
    return filterData;
  };

  const handleLevelChange = (event) => {
    const dispatchActionsForGeoLevel: dispatchState = {
      loaderStateFetch: geoLevelSummaryLoader(true),
    };
    dispatch(setGeoLevel(event.target.value));
    setSelectedLevel(event.target.value);
    let payload = createRunSimulationPayload(
      overallFilters,
      executionUserInputs,
      event.target.value,
      selectedTableLevelFilters
    );

    dispatch(
      fetchApi(
        payload,
        API_URL.EXECUTION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_EXECUTION,
        dispatchActionsForGeoLevel
      )
    )
      .then((res: any) => {
        dispatch(geoLevelSummaryLoader(false));
        dispatch(geoLevelSummaryResponse(res?.data?.geoLevelSummaryData));
      })
      .catch((error) => {
        dispatch(geoLevelSummaryLoader(false));
        console.log("ERROR", error);
      });
  };

  return (
    <Card style={{ border: "none", boxShadow: "none", position: "relative" }}>
      <Indicator position="absolute" show={loader && !loadingStateForAll} />
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Box style={alignEnd} sx={{ padding: "0.5rem" }}>
          <div style={{ ...center, width: "240px" }}>
            <span>Level: </span>
            <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
              <Select
                labelId="select-small-label"
                id="select-small"
                value={selectedLevel}
                label=""
                onChange={handleLevelChange}
              >
                {levels.map((element) => {
                  return (
                    <MenuItem value={element.value}>{element.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <Button
            disabled={!geoLevelSummaryData.length}
            variant="text"
            sx={{
              color: "#2F5597",
              fontWeight: 500,
              border: "1px solid #2F5597",
            }}
          >
            <CSVDownloader
              filename={"execution_geoLevelSummaryData.csv"}
              bom={true}
              config={{
                header: true,
              }}
              data={() => handleDownloadCSV(geoLevelSummaryData, "geo")}
              disable={geoLevelSummaryData.length}
            >
              Download CSV
            </CSVDownloader>
          </Button>
        </Box>
      </Grid>
      <Grid style={{ marginBottom: "10px", marginLeft: "10px" }}>
        <Box style={{ display: "flex" }} sx={{ padding: "0.5rem" }}>
          <GeoLevelFilter />
        </Box>
      </Grid>
      <Divider />
      <DataGridTables
        isScenarioLibrary={false}
        filterableColumns={filterableColumns}
        sortableColumns={sortableColumns}
        loading={loaderForGeoLevel}
        rows={loaderForGeoLevel ? [] : geoLevelSummaryData}
        columns={
          loaderForGeoLevel || !geoLevelSummaryData.length
            ? []
            : geoLevelSummaryColumn
        }
        disableColumnMenu={true}
        style={styleForDataGrid}
        autoPageSize={true}
        rowCount={geoLevelSummaryData.length}
        paginationMode="client"
        callback={(row) => row.uniqueId}
      ></DataGridTables>
    </Card>
  );
};

export default GeoLevelSummary;
