import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import useCollapse from 'react-collapsed';
import { VerticalLine } from '../../ElasticityPricingTrack.Styled';
const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameBox: { background: 'gray', border: '1px solid #efefef', color: '#fff' },
  noImage: { fontSize: 10, padding: 5, overflow: 'hidden' },
}));
const ExpansionPanel: React.FC<{ parent; childrens }> = ({ parent, childrens }) => {
  const { getToggleProps, isExpanded } = useCollapse();
  const classes = useStyles();
  return (
    <Grid style={{ position: 'relative' }} className="m-t-20">
      <Grid container spacing={2}>
        <Grid item style={{ padding: 0 }} sm={1}>
          <Box style={{ position: 'relative', marginLeft: 20 }}>
            {parent.icon ? (
              <img style={{ height: parent.imageHeight, width: parent.imageWidth, borderRadius: '50%' }} src={parent.icon} />
            ) : (
              <Grid className={classes.nameBox} style={{ height: parent.imageHeight, width: parent.imageWidth, display: 'table' }}>
                <Box style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                  <span style={{ fontSize: 10 }}>{parent.displayName}</span>
                </Box>
              </Grid>
            )}
            {isExpanded ? (
              <VerticalLine style={{ height: '100%', left: parent.imageWidth / 2 - 1, top: parent.imageHeight - 1.5 }}></VerticalLine>
            ) : null}
          </Box>
        </Grid>
        {!isExpanded ? (
          <Grid item sm={11}>
            {parent.component}
          </Grid>
        ) : null}
      </Grid>
      {childrens && childrens.length > 0 ? (
        <Box style={{ position: 'absolute', top: parent.imageHeight, zIndex: 99, left: parent.buttonOffset }}>
          <Box style={{ background: 'gray', borderRadius: '50%', height: 35, width: 35, color: '#fff' }}>
            {isExpanded ? (
              <ExpandLessIcon {...getToggleProps()} style={{ height: 35, width: 35 }} />
            ) : (
              <ExpandMoreIcon {...getToggleProps()} style={{ height: 35, width: 35 }} />
            )}
          </Box>
        </Box>
      ) : null}
      {isExpanded ? (
        <Grid container>
          {childrens.map((child, i) => (
            <Grid container spacing={2}>
              <Grid item style={{ padding: 0 }} sm={1}>
                <Box style={{ position: 'relative', marginLeft: 20, height: child.height }}>
                  <Box
                    style={{
                      position: 'absolute',
                      top: child.iconPosition,
                      zIndex: 9,
                    }}
                  >
                    {child.icon ? (
                      <img style={{ height: child.imageHeight, width: child.imageWidth, borderRadius: '50%' }} src={child.icon} />
                    ) : (
                      <Grid className={classes.nameBox} style={{ height: child.imageHeight, width: child.imageWidth, display: 'table' }}>
                        <Box style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                          <span style={{ fontSize: 10 }}>{child.displayName}</span>
                        </Box>
                      </Grid>
                    )}
                  </Box>

                  <VerticalLine
                    style={{
                      height: i < childrens.length - 1 ? '100%' : child.iconPosition / 2 + child.imageHeight,
                      left: child.imageWidth / 2 - 1,
                      top: child.imageHeight,
                    }}
                  ></VerticalLine>
                </Box>
              </Grid>
              <Grid item sm={11}>
                {child.component}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ExpansionPanel;
