import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  background: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.white,
  color: '#08509A'
}));

export { ExpandIcon };

