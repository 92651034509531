import { PRICE_SETTING_TOOL_DATA } from "./constants";

let intiState = {
  priceSettingToolDataLoader: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case PRICE_SETTING_TOOL_DATA:
      return {
        ...state,
        priceSettingToolDataLoader: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
