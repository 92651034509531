import React, { useRef, useState } from "react";
import { CardContent, Grid, Popover } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  StyledCard,
  Title,
  StyledTextField,
  GreyText,
  ColorPickerBox,
} from "../../MMPW.Styled";
import { BlackTextBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import ColorPicker from "../ColorPicker/ColorPicker";

const FooterNotes: React.FC<{ callback: any }> = ({ callback }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = useState("#000");
  const [fillColor, setFillColor] = useState("#D2EBFF");
  const [note, setNote] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeText = (e) => {
    setNote(e.target.value);
  };

  const pickedColor = (color) => {
    setColor(color);
  };

  const pickedFillColor = (fillColor) => {
    setFillColor(fillColor);
  };

  const saveNoteHandler = () => {
    callback({ type: "footer-note", note, color });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const colorPickerOpen = Boolean(anchorEl);

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title>Footer Notes</Title>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <StyledTextField
                  multiline
                  rows={3}
                  variant="outlined"
                  onChange={onChangeText}
                />
              </Grid>
              <Grid item xs={2}>
                <GreyText>Color</GreyText>
                <ColorPickerBox color={color} onClick={handleClick} />
                <Popover
                  anchorEl={anchorEl}
                  open={colorPickerOpen}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ColorPicker color={color} callback={pickedColor} />
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PrimaryBtn
              variant="contained"
              onClick={saveNoteHandler}
              className="m-r-10"
            >
              Add
            </PrimaryBtn>
            <BlackTextBtn
              className="disable"
              onClick={() => {
                callback();
              }}
            >
              Cancel
            </BlackTextBtn>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default FooterNotes;
