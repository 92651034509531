import React from 'react';
import CategoryDahboardList from '../../components/CategoryDashboardList/CartegoryDashboadList';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageList, pageNames } from '../../mocks/common';

const PriceArchitecture: React.FC = () => {
  return <PageContainer page={pageNames.priceArchitecture}>
    <CategoryDahboardList title={'Price Architecture'} categoryKey={'price_architecture'} pageName={pageList[0].value}/>
  </PageContainer>;
};
export default PriceArchitecture;
