import React from 'react';
import CategoryDahboardList from '../../components/CategoryDashboardList/CartegoryDashboadList';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageList, pageNames } from '../../mocks/common';

const MixManagement: React.FC = () => {
  return <PageContainer page={pageNames.mixManagement}>
  <CategoryDahboardList title={'Mix Management'} categoryKey={'mix_management'} pageName={pageList[16].value}/>
  </PageContainer>;
};
export default MixManagement;
