import React, { useEffect, useState } from "react";
import {
  DashedDivider,
  MixFilterName,
  MixFilterValues,
  RunSimulationButton,
  Title,
  UserInputFilterTitle,
  UserInputSubTitle,
  flex_end,
} from "../ExecutionCommon.Styled";
import { Button, Card, Grid, Popover, Typography } from "@mui/material";
import SelectInput from "../CommonComponents/SelectInput";
import TextFieldInput from "../../../../components/UI-components/TextFieldInput/TextFieldInput";
import {
  fetchExecutionOpportunitiesData,
  fetchExecutionOverviewData,
  fetchExecutionSalesData,
  loaderStateForRunSimulation,
} from "../../../../store/actions/ExecutionTool/executionOverview.action";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExecutionInputDataFiltersData,
  setGoodToHaveUserInput,
  setMustHaveUserInput,
} from "../../../../store/actions/ExecutionTool/executionUserInput.action";
import {
  dispatchState,
  fetchApi,
  geoLevelSummaryLoader,
  geoLevelSummaryResponse,
  portfolioSummary,
  portfolioSummaryLoader,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { TypographyTheme } from "../CommonComponents/commonStyled";
import Skeletons from "../../Skeletons/Skeletons";
import { toast } from "../../../../store/actions/toast.action";
import { mapperForKeys } from "./constant";
import PopoverComponent from "./PopoverComponent";
import { API_URL } from "../../../../store/actions/constants";

interface Payload {
  [key: string]: string | string[] | Payload;
}

const includeSpecialCase = ["goodToHaveSkus", "mustHaveSKUs"];

const UserInputComponent = () => {
  const currentAverage = useSelector(
    (state: any) => state.executionUserInputs.currentAverage
  );
  const overallFilters = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const executionUserInputs = useSelector(
    (state: any) => state.executionUserInputs
  );
  const assortmentScenarios = useSelector(
    (state: any) => state.executionUserInputs.data.assortment_scenario
  );
  const mixScenariosData = useSelector(
    (state: any) => state.executionUserInputs.data.mix_scenario
  );
  const selectedMixScenario = useSelector(
    (state: any) => state.executionUserInputs.selectedData.mix_scenario
  );
  const { selectedLevelForPortfolio, selectedLevelForGeoLevelSummary } =
    useSelector((state: any) => state.portfolioGeoLevelReducer);

  const selectedMixScenarioData = mixScenariosData.filter(
    (scenarioData: any) => {
      return scenarioData.scenarioName == selectedMixScenario[0];
    }
  );

  const selectedTableLevelFilters = useSelector(
    (state: any) => state.portfolioGeoLevelFilter.selectedFilters
  );
  const dispatch = useDispatch();
  const [isCurrentAverageVisible, setCurrentAverageVisible] = useState(false);
  useEffect(() => {
    changeCurrentAverageVisibilty();
  }, [currentAverage]);

  const changeCurrentAverageVisibilty = () => {
    if (currentAverage.mustHaveCurrentAverage == "") {
      setCurrentAverageVisible(false);
    } else {
      setCurrentAverageVisible(true);
      getMustHaveSKUWeights(
        Number.parseInt(currentAverage.mustHaveCurrentAverage) + 10
      );
      getGoodToHaveSKUWeights(
        Number.parseInt(currentAverage.mustHaveCurrentAverage) + 10
      );
    }
  };

  const createRunSimulationPayload = (
    overallSelectedFilters,
    userInputFilter
  ) => {
    let filterData = {
      overallFilters: {
        period: overallSelectedFilters.period,
        businessUnit: overallSelectedFilters.businessUnit,
        country: [overallSelectedFilters.country[0].toUpperCase()],
      },
      geo: {
        channel: overallSelectedFilters.channel,
        region: overallSelectedFilters.region,
        storeSegment: overallSelectedFilters.storeSegment,
        store: overallSelectedFilters.store,
      },
      userInputFilter: {
        decisionVariable: {
          assortmentScenarioName:
            userInputFilter.selectedData.assortment_scenario,
          mixScenarioName: userInputFilter.selectedData.mix_scenario,
        },
        assortmentsTargets: {
          goodToHaveSkus: [userInputFilter.weights.goodToHaveSku],
          mustHaveSKUs: [userInputFilter.weights.mustHaveSku],
        },
      },
    };
    return filterData;
  };
  const getMixScenarioNamesFromRawInput = (data: any) => {
    return data.map((item) => item.scenarioName);
  };

  const mixScenarios = getMixScenarioNamesFromRawInput(mixScenariosData);

  const getMustHaveSKUWeights = (value) => {
    dispatch(setMustHaveUserInput(value));
  };

  const getGoodToHaveSKUWeights = (value) => {
    dispatch(setGoodToHaveUserInput(value));
  };

  const findEmptyField = (payload: Payload): string | null => {
    const checkValidity = (obj: Payload): string | null => {
      for (const key in obj) {
        const value = obj[key];
        if (
          (Array.isArray(value) &&
            value.length === 0 &&
            !includeSpecialCase.includes(key)) ||
          (Array.isArray(value) &&
            Number(value[0]) === 0 &&
            includeSpecialCase.includes(key))
        ) {
          return key;
        }

        if (typeof value === "object") {
          const result = checkValidity(value as Payload);
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    return checkValidity(payload);
  };

  const runSimulations = (e) => {
    const payload = createRunSimulationPayload(
      overallFilters,
      executionUserInputs
    );
    const payloadForGeoLevel = {
      ...payload,
      portfolio: {
        Sku: selectedTableLevelFilters.sku,
        Brand: selectedTableLevelFilters.brand,
        Segment: selectedTableLevelFilters.segment,
      },
      level: selectedLevelForGeoLevelSummary,
    };
    const payloadForPortfolioLevel = {
      ...payload,
      portfolioGeo: {
        channel: selectedTableLevelFilters.channel,
        region: selectedTableLevelFilters.region,
        storeSegment: selectedTableLevelFilters.storeSegment,
      },
      level: selectedLevelForPortfolio,
    };
    const isEmptyField = findEmptyField(payload);
    if (isEmptyField) {
      dispatch(
        toast(
          `${mapperForKeys[isEmptyField]} must contain a value and cannot be empty.`,
          true,
          2000,
          "warning"
        )
      );
      return console.log("FIELD IS EMPTY", isEmptyField, payload);
    }

    const dispatchActions: dispatchState = {
      loaderStateFetch: portfolioSummaryLoader(true),
    };

    const dispatchActionsForGeoLevel: dispatchState = {
      loaderStateFetch: geoLevelSummaryLoader(true),
    };

    const runSimulationCalls = [
      dispatch(
        fetchExecutionOverviewData(payload, API_URL.EXECUTION.TRACKER_SIMULATION_DATA)
      ),
      dispatch(
        fetchExecutionOpportunitiesData(
          payload,
          API_URL.EXECUTION.GET_OPPORTUNITIES_DATA_AFTER_EXECUTION
        )
      ),
      dispatch(
        fetchExecutionSalesData(payload, API_URL.EXECUTION.GET_REST_OVERVIEW_DATA_AFTER_EXECUTION)
      ),
      dispatch(
        fetchApi(
          payloadForPortfolioLevel,
          API_URL.EXECUTION.GET_PORTFOLIO_DATA_AFTER_EXECUTION,
          dispatchActions
        )
      )
        .then((res: any) => {
          dispatch(portfolioSummaryLoader(false));
          dispatch(portfolioSummary(res?.data?.portfolioData));
        })
        .catch((error) => {
          dispatch(portfolioSummaryLoader(false));
          console.log("ERROR", error);
        }),
      dispatch(
        fetchApi(
          payloadForGeoLevel,
          API_URL.EXECUTION.GET_GEO_LEVEL_SUMMARY_DATA_AFTER_EXECUTION,
          dispatchActionsForGeoLevel
        )
      )
        .then((res: any) => {
          dispatch(geoLevelSummaryLoader(false));
          dispatch(geoLevelSummaryResponse(res?.data?.geoLevelSummaryData));
        })
        .catch((error) => {
          dispatch(geoLevelSummaryLoader(false));
          console.log("ERROR", error);
        }),
    ];

    Promise.all(runSimulationCalls)
      .then((res) => {
        dispatch(loaderStateForRunSimulation(false));
      })
      .catch((err) => {
        console.log("RUN SIMULAR ERROR", err);
        dispatch(loaderStateForRunSimulation(false));
      });
  };

  return (
    <div>
      <TypographyTheme>User Inputs</TypographyTheme>
      {executionUserInputs.loading ? (
        <Skeletons type={"userinput"} />
      ) : (
        <Card
          style={{
            border: "none",
            boxShadow: "none",
            padding: "30px",
            background: "#F1F5FE",
          }}
        >
          <UserInputSubTitle>Decision Variables</UserInputSubTitle>
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid item xs={2}>
              <UserInputFilterTitle>Assortment Scenario</UserInputFilterTitle>
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                options={assortmentScenarios}
                name={"assortment_scenario"}
                isMultiple={false}
                isFilter={false}
              ></SelectInput>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid item xs={2}>
              <UserInputFilterTitle>Mix Scenario</UserInputFilterTitle>
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                options={mixScenarios}
                name={"mix_scenario"}
                isMultiple={false}
                isFilter={false}
              ></SelectInput>
            </Grid>
            <Grid item xs={2}>
              <PopoverComponent
                id="channel"
                name="Channel"
                options={selectedMixScenarioData[0]?.channel || ""}
                selectedMixScenario={selectedMixScenario}
              />
            </Grid>
            <Grid item xs={2}>
              <PopoverComponent
                id="region"
                name="Region"
                options={selectedMixScenarioData[0]?.region || ""}
                selectedMixScenario={selectedMixScenario}
              />
            </Grid>
            <Grid item xs={3}>
              <PopoverComponent
                id="storeSegment"
                name="Store Segment"
                options={selectedMixScenarioData[0]?.storeSegment || ""}
                selectedMixScenario={selectedMixScenario}
              />
            </Grid>
          </Grid>
          <DashedDivider></DashedDivider>
          <div style={flex_end}>
            <UserInputSubTitle>Assortment Targets (in %)</UserInputSubTitle>
            <Typography>
              <span style={{ fontSize: 10, fontWeight: 700, color: "black" }}>
                *NOTE:{" "}
              </span>
              <span style={{ fontSize: 10, fontWeight: 400, color: "black" }}>
                Assigned Penetration (Distribution) Target can Individually be
                0-100%
              </span>
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="left"
            alignItems="center"
            sx={{ marginTop: "20px" }}
          >
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <UserInputFilterTitle>Must have SKU*</UserInputFilterTitle>
                <TextFieldInput
                  defaultValue={
                    currentAverage.mustHaveCurrentAverage == ""
                      ? Number.parseInt(executionUserInputs.weights.mustHaveSku)
                      : Number.parseInt(currentAverage.mustHaveCurrentAverage) +
                        10
                  }
                  callback={(value: any) => getMustHaveSKUWeights(value)}
                  disabled={false}
                ></TextFieldInput>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <UserInputFilterTitle>Good to have SKU*</UserInputFilterTitle>
                <TextFieldInput
                  defaultValue={
                    currentAverage.goodToHaveCurrentAverage == ""
                      ? Number.parseInt(
                          executionUserInputs.weights.goodToHaveSku
                        )
                      : Number.parseInt(
                          currentAverage.goodToHaveCurrentAverage
                        ) + 10
                  }
                  callback={getGoodToHaveSKUWeights}
                  disabled={false}
                ></TextFieldInput>
              </div>
            </Grid>
          </Grid>
          {isCurrentAverageVisible ? (
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="left"
              alignItems="center"
              sx={{ marginTop: "20px" }}
            >
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <UserInputFilterTitle>Current Average</UserInputFilterTitle>
                  <MixFilterValues style={{ width: "100px", height: "35px" }}>
                    {currentAverage.mustHaveCurrentAverage != null
                      ? Math.round(currentAverage?.mustHaveCurrentAverage)
                      : 0}{" "}
                    %
                  </MixFilterValues>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <UserInputFilterTitle>Current Average</UserInputFilterTitle>
                  <MixFilterValues style={{ width: "100px", height: "35px" }}>
                    {currentAverage.goodToHaveCurrentAverage != null
                      ? Math.round(currentAverage?.goodToHaveCurrentAverage)
                      : 0}{" "}
                    %
                  </MixFilterValues>
                </div>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <RunSimulationButton onClick={runSimulations}>
              Run Simulation
            </RunSimulationButton>
          </div>
        </Card>
      )}
    </div>
  );
};

export default UserInputComponent;
