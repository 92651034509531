import { Box, Card, CardContent, Grid, Skeleton, Slider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import data from '../../../../mocks/supermarketMock';
import { loader } from '../../../../store/actions/common.action';
import { inputRegex, roundDecimal } from '../../../../util/helper';
import { Label } from '../../../ProfitParabolaDashboard/ProfitParabolaDashboard.Styled';
import { getPepsicoBaselineData } from '../../service';
import { elasticityCoefficientLoader } from '../../store/SupermarketDashboard.action';
import { Title } from '../../SupermarketDashboard.Styled';
import AvgSalesPricePoint from '../AvgSalesPricePoint';
import Incrementality from '../Incrementality';
import SalesPricePoint from '../SalesPricePoint';

const initialState = {
  sdv: null,
  cogs: null,
  markup: 25,
  taxes: 8,
  otherCost: 2,
  srp: null,
};
const ElasticityCoefficient: React.FC<{
  filters;
  callback;
  defaultElasticityCoefficientInputs;
}> = ({ filters, callback, defaultElasticityCoefficientInputs }) => {
  const dispatch = useDispatch();
  const [defaultVal] = useState<number>(-2.5);
  const [min] = useState<number>(0.0);
  const [max] = useState<number>(-5.0);
  const [elasticityValue, setElasticityValue] = useState<number>(defaultVal);
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [inputProduct, setInputProduct] = useState(initialState);
  const { sdv, cogs, markup, taxes, otherCost, srp } = inputProduct;
  const [pepsicoBaseline, setPepsicoBaseline] = useState({});
  const [markupOrMarkdown, setMarkupOrMarkdown] = useState(data.markUpAndMarkDown.defaultOption.value);
  useEffect(() => {
    if (defaultElasticityCoefficientInputs && defaultElasticityCoefficientInputs.inputs) {
      const { markup, taxes, ...inputValues } = defaultElasticityCoefficientInputs.inputs;
      setInputProduct({
        ...inputValues,
        markup: markup * 100,
        taxes: taxes * 100,
      });
      setElasticityValue(defaultElasticityCoefficientInputs.elasticityCoefficient);
    }
  }, [defaultElasticityCoefficientInputs]);

  const handleChange = (_: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setElasticityValue(value);
    }
  };
  const inputProductHandler = (type: string, { target: { value } }: any) => {
    setInputProduct((prevInput) => {
      return { ...prevInput, [type]: value };
    });
  };

  const getSRP = () => {
    const payload = {
      ...filters,
      ...inputProduct,
      taxes: taxes / 100,
      markup: markup / 100,
      isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      elasticityCoefficient: elasticityValue,
    };
    setSkeleton(true);
    if (sdv && cogs && markup && taxes && otherCost) {
      getPepsicoBaselineData(payload)
        .then((res) => {
          setPepsicoBaseline({ ...res });
        })
        .catch((e) => {
          setPepsicoBaseline({});
          console.log(e);
        });
    } else if (srp) {
      getPepsicoBaselineData(payload)
        .then((res) => {
          setPepsicoBaseline({ ...res });
        })
        .catch((e) => {
          setPepsicoBaseline({});
          console.log(e);
        });
    }
  };
  const getElasticity = (_: Event, value: number | number[]) => {
    setSkeleton(true);
    if (typeof value === 'number') {
      getPepsicoBaselineData({
        ...filters,
        ...inputProduct,
        taxes: taxes / 100,
        markup: markup / 100,
        elasticityCoefficient: value,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      })
        .then((res) => {
          setPepsicoBaseline(res);
        })
        .catch((e) => {
          setPepsicoBaseline({});
          console.log(e);
        });
    }
  };
  const onChangeInputPepsiCo = (e) => {
    if (e.keyCode == 13) {
      getSRP();
    } else {
      return inputRegex(e);
    }
  };
  const setInputValues = (data) => {
    setInputProduct({
      ...inputProduct,
      sdv: data.sdv > 0 ? data.sdv.toFixed(2) : null,
      cogs: data.cogs > 0 ? data.cogs.toFixed(2) : null,
      otherCost: data.otherCost.toFixed(2),
      srp: roundDecimal(data.srp).toFixed(2),
    });
  };
  useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      let payload = {
        ...filters,
        ...initialState,
        elasticityCoefficient: defaultVal,
        taxes: initialState.taxes / 100,
        markup: initialState.markup / 100,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      };

      if (defaultElasticityCoefficientInputs && defaultElasticityCoefficientInputs.inputs) {
        // setElasticityValue(defaultElasticityCoefficientInputs.elasticityCoefficient);
        // setInputProduct(defaultElasticityCoefficientInputs.inputs);
        payload = {
          ...filters,
          ...defaultElasticityCoefficientInputs.inputs,
          elasticityCoefficient: defaultElasticityCoefficientInputs.elasticityCoefficient,
        };
      } else {
        setElasticityValue(defaultVal);
        setInputProduct(initialState);
      }
      getPepsicoBaselineData(payload)
        .then((res) => {
          setPepsicoBaseline(res);
        })
        .catch((e) => {
          dispatch(loader(false));
          dispatch(elasticityCoefficientLoader(false));
          setPepsicoBaseline({});
          console.log(e);
        });
    }
  }, [filters]);

  useEffect(() => {
    if (pepsicoBaseline && pepsicoBaseline.data && pepsicoBaseline.status === 200) {
      setSkeleton(false);
      dispatch(loader(false));
      dispatch(elasticityCoefficientLoader(false));
      setInputValues(pepsicoBaseline.data);
      callback({
        ...inputProduct,
        taxes: taxes / 100,
        markup: markup / 100,
        elasticityCoefficient: elasticityValue,
        isMarkUp: markupOrMarkdown === 'markup' ? 1 : 0,
      });
    } else {
      dispatch(elasticityCoefficientLoader(false));
      dispatch(loader(false));
    }
  }, [pepsicoBaseline]);
  return (
    <>
      <Grid container spacing={2} className='m-b-20'>
        <Grid item md={12} lg={8}>
          <Card>
            <CardContent>
              {skeleton ? (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Skeleton variant='rectangular' width={250} height={30} className='m-b-10' />
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={5}>
                      <Skeleton height={60} width={250} />
                    </Grid>
                  </Grid>
                  {[...Array(6).keys()].map((i) => (
                    <Grid item xs={12} sm={2} key={i}>
                      <Skeleton height={22} width={'50%'} />
                      <Skeleton variant='rectangular' width={'auto'} height={45} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title className='f-w-5'>PEPSICO PRODUCT BASELINE INPUT</Title>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container display='flex' alignItems='center'>
                      <Grid className='m-r-20'>
                        <Box style={{ fontSize: 16, fontWeight: 600 }}>MarkUp/MarkDown -</Box>
                      </Grid>
                      <Grid>
                        <RadioButtonGroup
                          key={`markup-and-markdown`}
                          color='#000'
                          showButton={true}
                          data={data.markUpAndMarkDown.options}
                          defaultOption={markupOrMarkdown}
                          select={setMarkupOrMarkdown}
                          direction='row'
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>SDV</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='sdv'
                      type='number'
                      size='small'
                      value={sdv || ''}
                      onChange={(e) => inputProductHandler('sdv', e)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>COGS</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='cogs'
                      type='number'
                      size='small'
                      value={cogs || ''}
                      onChange={(e) => inputProductHandler('cogs', e)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>{markupOrMarkdown === 'markup' ? 'Markup(%)' : 'Markdown(%)'}</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='markup'
                      type='number'
                      size='small'
                      value={markup || ''}
                      onChange={(e) => inputProductHandler('markup', e)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>Taxes(%)</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='taxes'
                      type='number'
                      size='small'
                      value={taxes || ''}
                      onChange={(e) => inputProductHandler('taxes', e)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>Other Cost</DropdownTitle>
                    <TextField InputProps={{ autoComplete: 'off' }}
                      id='otherCost'
                      type='number'
                      size='small'
                      value={otherCost || ''}
                      onChange={(e) => inputProductHandler('otherCost', e)}
                      onKeyDown={onChangeInputPepsiCo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <DropdownTitle className='m-r-10'>SRP</DropdownTitle>
                    <Label style={{ backgroundColor: '#dbe7f3', width: 'auto' }}>{inputProduct.srp}</Label>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={4}>
          <Card style={{ height: '500' }}>
            <CardContent sx={{ mt: 7 }}>
              <Grid container alignItems='center' className='m-b-10'>
                <Grid item xs={12}>
                  {skeleton ? <Skeleton height={70} width={200} /> : <Title className='m-b-20 m-r-10 f-w-5'>ELASTICITY COEFFICIENT</Title>}
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems='center'>
                    {skeleton ? (
                      <>
                        <Grid item xs={2}>
                          <Skeleton height={60} width={85} />
                        </Grid>
                        <Grid item xs={10}>
                          <Skeleton height={60} />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={3} md={3}>
                            <Label
                              style={{
                                backgroundColor: '#ebebeb',
                                width: '90%',
                                fontSize: 25,
                                fontWeight: 600,
                              }}
                            >
                              {elasticityValue}
                            </Label>
                          </Grid>
                          <Grid item xs={9} md={9}>
                            <Slider
                              track='inverted'
                              min={max}
                              step={0.1}
                              max={min}
                              value={elasticityValue}
                              onChange={handleChange}
                              onChangeCommitted={getElasticity}
                            />
                            <Grid container spacing={2} display='flex' justifyContent='space-between'>
                              <Grid item>{max}</Grid>
                              <Grid item>{defaultVal}</Grid>
                              <Grid item>{min}</Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SalesPricePoint pepsicoBaseline={pepsicoBaseline} skeleton={skeleton} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AvgSalesPricePoint pepsicoBaseline={pepsicoBaseline} skeleton={skeleton} />
        </Grid>
      </Grid>
      <Incrementality pepsicoBaseline={pepsicoBaseline} skeleton={skeleton} />
    </>
  );
};
export default ElasticityCoefficient;
