import React, { useEffect, useState } from 'react';
import DashboardActions from '../../components/DashboardActions';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { pageList } from '../../mocks/common';
import MultilevelTableContainer from './components/MultilevelTableContainer';
import TopFilters from './components/TopFilters';
import PredictivePackEconChartTableContainer from './PredictivePackEconChartTableContainer';
import { removeMMPWFromLocal } from '../../util/helper';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const PredictivePackEconomics: React.FC = () => {
  const [appliedFilters, setFilters] = useState({} as any);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);

  const reduxState = useSelector((state: any) => state);

  const onApplyFilter = (filters) => {
    setDisableDownloadButton(false);
    removeMMPWFromLocal('pack-economics-predictive-chart');
    setFilters(filters);
  };

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setFilters(savedDashboardData?.filters?.global);
    }
  };

  const PredictivePackEconomics = useSelector((state: any) => state.PredictivePackEconomics);

  return (
    <DashboardActions
      callback={getSaveDashBoardsData}
      title='Pack Economics'
      data={{
        filters: {
          global: { ...appliedFilters },
        },
        mmpwKey: 'pack-economics-predictive-chart',
      }}
      dashboardKey={pageList[11].id}
      disableDownloadButton={
        disableDownloadButton || PredictivePackEconomics.predictivePackEconomics || PredictivePackEconomics.predictivePackEconomicsDetails
      }
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[11].value} />
      </Grid>
      <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} />
      <PredictivePackEconChartTableContainer filters={appliedFilters} />
      <MultilevelTableContainer filters={appliedFilters} />
      <DashboardSourceList category={appliedFilters.category} dashboardName={pageList[11].id} appliedFilters={appliedFilters} />
    </DashboardActions>
  );
};

export default PredictivePackEconomics;
