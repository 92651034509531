import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Dropdown from '../../../../components/Dropdown';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import CustomDateRangePicker from '../../../../components/UI-components/DateRangePicker';
import { dataFormat } from '../../../../util/helper';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { fetchSellPeriodViewDates, getGeoLevel2 } from '../../../../util/services';
import { toast } from '../../../../store/actions/toast.action';
import { useDispatch } from 'react-redux';
import { defaultCalendarDates } from '../../../../util/config';

export const dateRangeSellIn = [defaultCalendarDates.startDate, defaultCalendarDates.endDate];
export const dateRangeSellOut = [defaultCalendarDates.startDateSellOut, defaultCalendarDates.endDateSellOut];
const PeriodViewFilter: React.FC<{
  data;
  onChangeDateSellIn?;
  onChangeDateSellOut?;
  showSkeleton?: boolean;
  defaultDate?;
  onSelectFilters;
  selectedGeoFilters;
  defaultFilters?;
  showPeriodView?: boolean;
  clearFilter?: boolean;
  apiPath?;
  showLoader?;
  selectedProductFilters?;
}> = ({
  data,
  onChangeDateSellIn,
  onChangeDateSellOut,
  defaultDate,
  defaultFilters,
  selectedGeoFilters, 
  onSelectFilters,
  showPeriodView = false,
  clearFilter,
  apiPath,
  showLoader,
  selectedProductFilters,
}) => {
  const dispatch = useDispatch(); 
  const [selectedDateSellIn, setDateSellIn] = React.useState(defaultDate || dateRangeSellIn);
  const [selectedDateSellOut, setDateSellOut] = React.useState(defaultDate || dateRangeSellOut);
   
  const [selectedPeriodView, setPeriodView] = React.useState(  
    defaultFilters && defaultFilters.periodView
      ? _.isArray(defaultFilters.periodView)
        ? defaultFilters.periodView
        : [defaultFilters.periodView]
      : ['na']
  );
  
  useEffect(() => {
    if (clearFilter) {
      setDateSellIn(dateRangeSellIn); 
      setDateSellOut(dateRangeSellOut);
    }
  }, [clearFilter]);

  useEffect( () =>{ 
    if(selectedGeoFilters.country){  
      selectedGeoFilters.periodView = defaultFilters.periodView || false
    }
  }, [selectedGeoFilters])

  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      const periodView=defaultFilters && defaultFilters.periodView
      ? _.isArray(defaultFilters.periodView)
        ? defaultFilters.periodView
        : [defaultFilters.periodView]
      : ['na']
      setPeriodView(periodView);
    }
  }, [defaultFilters]);
  
  const onSelectDateSrp = (date) => {
    if (date.length === 2) {
      setDateSellIn(date);
      const formattedDate = [];
      for (let i = 0; i < date.length; i++) {
        if (date[i]) formattedDate.push(dataFormat(date[i], 'MM/dd/yyyy'));
      }
      onChangeDateSellIn(formattedDate.join('-'));
    }
  };
  
  const onSelectDateSop = (date) => {
    if (date.length === 2) {
      setDateSellOut(date);
      const formattedDate = [];
      for (let i = 0; i < date.length; i++) {
        if (date[i]) formattedDate.push(dataFormat(date[i], 'MM/dd/yyyy'));
      }
      onChangeDateSellOut(formattedDate.join('-'));
    }
  };
 
  useEffect(()=>{
    onChangePeriodView()
  },[selectedProductFilters.category, selectedPeriodView])

  const onChangePeriodView = () => {
    selectedGeoFilters.periodView = selectedPeriodView[0];  
    if (selectedPeriodView[0] !== 'na') {
      showLoader(true);
      const payload = {
        period: selectedPeriodView[0],
        country: selectedGeoFilters.country || defaultFilters.country,
        geoLevel: selectedGeoFilters.geoLevel || defaultFilters.geoLevel,
        geoLevel2: selectedGeoFilters.geoLevel2 || defaultFilters.geoLevel2,
        category: selectedProductFilters.category || defaultFilters.category
      };
      fetchSellPeriodViewDates(apiPath, payload)
        .then((res) => { 
          const response = res.data;
          if (res.status === 200 && response.sellInStartDate && response.sellInEndDate) {
            const startDate = DateTime.fromString(response.sellInStartDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            const endDate = DateTime.fromString(response.sellInEndDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            
            const startDateSellOut = DateTime.fromString(response.sellOutStartDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            const endDateSellOut = DateTime.fromString(response.sellOutEndDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            
            setDateSellIn([startDate, endDate]);
            setDateSellOut([startDateSellOut, endDateSellOut]);

            onChangeDateSellIn(`${startDate}-${endDate}`);
            onChangeDateSellOut(`${startDateSellOut}-${endDateSellOut}`);
          } else {
            dispatch(toast('Dates Unavailable!', true, 2000, 'error'));
          }
          showLoader(false);
        })
        .catch((e) => {
          dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
          showLoader(false);
        });
    }
  };

  return (
    <Grid container spacing={2}>  
        <Grid className='periodViewBlk' item xs={12} md={2}> 
        <DropdownTitle>{data.periodView.title}</DropdownTitle>
          <Dropdown
            disabled={!selectedGeoFilters || (selectedGeoFilters && !selectedGeoFilters.country)}
            data={data.periodView.options}
            onChange={(data) => setPeriodView(data)}
            defaultOption={selectedPeriodView || ['empty']}
            placeholder={data.periodView.placeholder}
            sort={{ enable: false }}
          />
        </Grid>  
        <Grid className='dateBlk' item xs={12} md={2}>
          <DropdownTitle>Sell In Date Range</DropdownTitle>
          <CustomDateRangePicker
            defaultDate={selectedDateSellIn}
            callback={onSelectDateSrp}
            disabled={ selectedPeriodView[0] !== 'na' ? true: false}
          />
        </Grid> 
        <Grid className='dateBlk' item xs={12} md={2}>
          <DropdownTitle>Sell Out Date Range</DropdownTitle>
          <CustomDateRangePicker
            defaultDate={selectedDateSellOut}
            callback={onSelectDateSop}
            disabled={selectedPeriodView[0] !== 'na' ? true: false}
          />
        </Grid> 
    </Grid>
  );
};
export default PeriodViewFilter;
