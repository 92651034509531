import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import { useSelector } from "react-redux";

import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup/CheckBoxGroup";
import _ from "lodash";

const MeasureFilter: React.FC<{ data; selectedMeasureFilters, selectedCompetitorFilters, callback; defaultFilters}> = ({
  data,
  selectedMeasureFilters,
  selectedCompetitorFilters,
  callback,
  defaultFilters
}) => {
  const [variable1, setvariable1] = useState(data.variable1);
  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
       temp[type] = selectedVal;
    callback(temp);
  };

  const productFilter = useSelector((state: any) => state.productFilter);
  const selectedProductFilters = productFilter.selectedFilters;
  const anchorFilter = useSelector((state: any) => state.anchorFilter);
  const selectedAnchorFilters = anchorFilter.selectedFilters;

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
        <DropdownTitle>{variable1.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-filters-1`}
          color="#000"
          showButton={variable1.showRadioButton}
          data={variable1.options}
          defaultOption={selectedMeasureFilters.variable1}
          select={(data) => onSelectMeasure("variable1", data)}
        />
      </Grid>
     
      <Grid item xs={12} sm={5} key={`measures-variable-config`}>
        <DropdownTitle>Variables Configuration</DropdownTitle>
        <Grid container>
          <Grid className="m-r-10 b-r-1">
            <CheckBoxGroup
              single={true}
              data={data.SOMVarConfig.options}
              color="#000"
              direction="row"
              select={(data) => onSelectMeasure("somGroup", data)}
              defaultOption={selectedMeasureFilters.somGroup}
              disabled={
                selectedMeasureFilters.variable1 !== "SOM" &&
                selectedMeasureFilters.variable2 !== "SOM"
              }
            />
          </Grid>
          <Grid>
            <RadioButtonGroup
              key={`measures-filters-som`}
              color="#000"
              showButton={data.SOM.showRadioButton}
              data={data.SOM.options}
              defaultOption={selectedMeasureFilters.somMeasure}
              select={(data) => onSelectMeasure("somMeasure", data)}
              direction="row"
              disabled={
                selectedMeasureFilters.variable1 !== "SOM" &&
                selectedMeasureFilters.variable2 !== "SOM"
              }
            />
          </Grid>
        </Grid>
       
       
      </Grid>
      <Grid item xs={12} sm={4} key={`measures-variable-config`}>
        <DropdownTitle>Sell Out Prices</DropdownTitle>
        <Grid>
            <RadioButtonGroup
              key={`measures-filters-som`}
              color="#000"
              showButton={data.selloutPrices.showRadioButton}
              data={data.selloutPrices.options}
              defaultOption={selectedMeasureFilters.pricePer}
              select={(data) => onSelectMeasure("pricePer", data)}
              direction="row"
              disabled={false}
            />
          </Grid>
        </Grid>
      <Grid item xs={12} sm={2} key={`measures-product-list`}>
        {selectedProductFilters.brand &&
          <DropdownTitle className="m-b-10">PEPSICO</DropdownTitle>}
        {selectedProductFilters.brand && selectedProductFilters.brand.length &&
          <Box style={{ overflow: 'auto', height: 245 }}>
            {selectedProductFilters.brand.map((b) => (
              <Box style={{ color: "#000" }}>{b}</Box>
            ))}
          </Box>
        }
      </Grid>
      <Grid item xs={12} sm={2} key={`measures-competitor-list`}>
        {selectedCompetitorFilters.brand &&
          <DropdownTitle className="m-b-10">COMPETITOR</DropdownTitle>}
        {selectedCompetitorFilters.brand && selectedCompetitorFilters.brand.length &&
          <Box style={{ overflow: 'auto', height: 245 }}>
            {selectedCompetitorFilters.brand.map((b) => (
              <Box style={{ color: "#000" }}>{b}</Box>
            ))}
          </Box>}
      </Grid>
    </Grid>
  );
};

export default MeasureFilter;