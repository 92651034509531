import React from "react";
import { Grid } from "@mui/material";

import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup";

const MeasureFilters: React.FC<{ data; selectedMeasureFilters; callback }> = ({
  data,
  selectedMeasureFilters,
  callback,
}) => {
  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    temp[type] = selectedVal;
    callback(temp);
  };

  return (
    <Grid container spacing={1} columns={16}>
      <Grid item xs={12} sm={2} key={`measures-periodicity`}>
        <DropdownTitle>{data.periodicity.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-periodicity`}
          color="#000"
          showButton={data.periodicity.showRadioButton}
          data={data.periodicity.options}
          defaultOption={selectedMeasureFilters.periodicity}
          select={(data) => onSelectMeasure("periodicity", data)}
        />
      </Grid>
      <Grid item xs={12} sm={3} key={`measures-not-anchor`}>
        <DropdownTitle>{data.variable1.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-not-anchor`}
          color="#000"
          showButton={data.variable1.showRadioButton}
          data={data.variable1.options}
          defaultOption={selectedMeasureFilters.variable1}
          select={(data) => onSelectMeasure("variable1", data)}
        />
      </Grid>
      <Grid item xs={12} sm={2} key={`measures-api`}>
        <DropdownTitle>{data.variable2.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-api`}
          color="#000"
          showButton={data.variable2.showRadioButton}
          data={data.variable2.options}
          defaultOption={data.variable2.defaultOption.value}
          select={(data) => onSelectMeasure("variable2", data)}
        />
      </Grid>
      <Grid item xs={12} sm={4} key={`measures-api-data`}>
        <DropdownTitle>&nbsp;</DropdownTitle>
        <CheckBoxGroup
          key="measure-filter"
          single
          color="#000"
          direction="row"
          data={data.apiUnits.options}
          defaultOption={selectedMeasureFilters.apiUnits}
          select={(data) => onSelectMeasure("apiUnits", data)}
        />
      </Grid>
    </Grid>
  );
};

export default MeasureFilters;
