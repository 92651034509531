import { API } from "../../util/helper";

export const fetchCauseBusinessChangeData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/historicprice/causeOfBusiness`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const fetchPriceElasticityAndModelData = async (payload) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/historicprice/priceElasticity`,
      payload
    );
  } catch (e) {
    return e;
  }
};

export const fetchCrossPriceElasticityChangeData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/api/simulation`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const fetchHistoricPriceFilterData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}filter/historicPriceProductFilter`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const fetchHistoricPriceElasticityFilterData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/historicprice/crossPriceElasticity`,
      data
    );
  } catch (e) {
    return e;
  }
};

export const fetchPriceElasticityDonutData = async (data) => {
  try {
    return await API.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/historicprice/priceElactisityDoughnut`,
      data
    );
  } catch (e) {
    return e;
  }
};
