import styled from 'styled-components';
import { Box, ListItem, Switch } from '@mui/material';
import { Typography } from '@mui/material';

const VerticalLine = styled(Box)(({ theme }) => ({
  borderRight: 3,
  borderRightStyle: 'solid',
  borderRightColor: 'gray',
  width: 3,
  position: 'absolute',
}));

const TopHeading = styled(Box)(({ theme }) => ({
  background: '#25d4cf',
  color: 'white',
  fontWeight: 'bold',
  height: 45,
  width: 'auto',
  textAlign: 'center',
  paddingTop: 12,
  borderRadius: '5px',
}));


export { VerticalLine, TopHeading };
