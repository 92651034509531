import React from 'react';
import { CardContent, Skeleton } from '@mui/material';
import { StyledCard, Title, Value, Profit, Loss } from './GrossVolumeCard.Styled';
import { formatNumber } from '../../../../util/helper';

const GrossVolumeCard: React.FC<{ data: any; showSkeleton?: boolean }> = ({ data, showSkeleton }) => {
  return (
    <StyledCard>
      {!showSkeleton ? (
        <>
          <CardContent style={{ background: 'white', height: '100%' }}>
            <Title data-testid='grossVolumeCardTitle'>{data.title}</Title>
            {!data.isNA ? (
              <>
                <Value data-testid='grossVolumeCardValue' style={{ fontSize: '16px', fontWeight: 600, justifyContent: 'flex-start' }}>
                  <span style={{ marginRight: '10px', float: 'left' }}>
                    From: {data.title === 'Share Of Market' ? (100 * data.valueFrom).toFixed(2) + ' %' : formatNumber(data.valueFrom)}
                  </span>
                  <span style={{ marginLeft: '16px', marginRight: '10px', float: 'right' }}>
                    To: {data.title === 'Share Of Market' ? (100 * data.valueTo).toFixed(2) + ' %' : formatNumber(data.valueTo)}
                  </span>
                </Value>
                <Value style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: '16px' }}>
                  {data.percentage.toString() && data.percentage >= 0 ? (
                    <Profit data-testid='profitIcon'>
                      +{data.percentage.toFixed(2)} {'%'}
                    </Profit>
                  ) : (
                    <Loss data-testid='lossIcon'>
                      {data.percentage.toFixed(2)} {'%'}
                    </Loss>
                  )}
                </Value>
              </>
            ) : (
              <Value display={'flex'} justifyContent={'center'} style={{ fontSize: '16px' }}>
                NA
              </Value>
            )}
          </CardContent>
        </>
      ) : (
        <>
          <Title>
            <Skeleton />
          </Title>
          <Value>
            <Skeleton />
          </Value>
          {data && <Skeleton />}
        </>
      )}
    </StyledCard>
  );
};

export default GrossVolumeCard;
