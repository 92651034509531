import _ from "lodash";
import Highcharts from "highcharts";
import xrange from "highcharts/modules/xrange";
import draggable from "highcharts/modules/draggable-points";
import more from "highcharts/highcharts-more";
import { formatNumber } from "../../../../util/helper";

if (typeof Highcharts === "object") {
  more(Highcharts);
  draggable(Highcharts);
  xrange(Highcharts);
}

const chartConfig: any = {
  chart: {
    type: "bubble",
    // width: window.innerWidth - 160,
    height: window.innerHeight - 100,
    events: {
      load() {
        let chart = this;
        if (!chart.xAxis[0].tickPositions.includes(0)) {
          chart.update({
            xAxis: {
              min: 0,
            },
          });
        }
        if (!chart.yAxis[0].tickPositions.includes(0)) {
          chart.update({
            yAxis: {
              min: 0,
            },
          });
        }
      },
    },
  },
  credits: { enabled: false },
  title: {
    text: "",
  },  
  // colors: [
  //   "#65c07f",
  //   "#1d6c3f",
  //   "#f79969",
  //   "#e46a2b",
  //   "#fdc076",
  //   "#e4452b",
  //   "#004b76",
  //   "#003f23",
  //   "#b6740c",
  //   "#005a64",
  //   "#1b964e",
  //   "#83ccb0",
  //   "#24a1ae",
  //   "#ec9631",
  //   "#147bb6",
  //   "#ff7754",
  //   "#007b5a",
  //   "#6ecbd6",
  //   "#ba0d00",
  //   "#68a9dc",
  // ],
  zoomType: "xy",
  tooltip: {
    enable: true,
    formatter: function () {
      return `<Grid >
          <p>Var Description : <b>${this?.key}</b></p>
          <br/>
          <br/>
          <p>Var X : <b>${formatNumber(this?.x.toFixed(2))}</b></p>
          <br/>
          <br/>
          <p>Var Y : <b>${formatNumber(this?.y.toFixed(2))}</b></p>
          <br/>
          <br/>
          <p>Variable Size : <b>${formatNumber(this?.point?.z.toFixed(2))}</b></p>
        </Grid>`;
    },
  },
  legend: {
    enabled: true,
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 20,
    symbolWidth: 20,
    symbolRadius: 0,
    itemStyle: {
      fontSize: "16px",
    },
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  xAxis: {
    startOnTick: false,
    endOnTick: false,
    title: {
      enabled: true,
      margin: 30,
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    plotLines: [
      {
        id: "x",
        color: "#faa",
        width: 3,
        value: 0,
        zIndex: 3,
      },
    ],
  },
  yAxis: {
    startOnTick: false,
    endOnTick: false,
    title: {
      enabled: true,
      margin: 30,
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    plotLines: [
      {
        id: "y",
        color: "#faa",
        width: 3,
        value: 0,
        zIndex: 3,
      },
    ],
  },

  series: [],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export { chartConfig };
