export const geoFilterConfig = {
    country: {
        title: "Country",
        options: [],
    },
    geoLevel: {
        title: "Geo-Level",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: false,
    },
    region: {
        title: "Region",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    city: {
        title: "City",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    channel: {
        title: "Channel",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    },
    subChannel: {
        title: "Sub Channel",
        options: [],
        placeholder: "Select",
        all: true,
        multiple: true,
        defaultSelectAll: true
    }
};
export const productFilterConfig = {
    vendor: {
        title: "Vendor",
        options: [{ label: "PepsiCo", value: "PepsiCo" }],
        placeholder: "Select",
        all: true,
        multiple: true
    },
    category: {
        title: "Category",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: true,
        selectionLimit: 5
    },
    segment: {
        title: "Segment",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: true,
        selectionLimit: 5
    },
    brand: {
        title: "Brand",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: true,
        selectionLimit: 5
    },
    subBrand: {
        title: "Sub Brand",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: true,
        selectionLimit: 5
    },
    packSize: {
        title: "Pack Size",
        options: [],
        placeholder: "Select",
        all: false,
        multiple: true,
        selectionLimit: 5
    },
}

export const anchorFilterConfig = {
    vendor: {
        title: "Vendor",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
    category: {
        title: "Category",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
    segment: {
        title: "Segment",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
    brand: {
        title: "Brand",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
    subBrand: {
        title: "Sub-brand",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
    packSize: {
        title: "Pack-size",
        options: [],
        placeholder: "Select",
        allOptionText: "All",
        all: true,
        multiple: true,
        defaultSelectAll: false
    },
}

export const comparisonLevelConfig = {
    options: [
        { id: "1", value: "Category" },
        { id: "2", value: "Segment" },
        { id: "3", value: "Brand" },
        { id: "4", value: "Sub Brand" },
        { id: "5", value: "Pack Size" },
        { id: "6", value: "Brand Pack Size" },
    ],
    defaultOption: { id: "1", value: "Category" },
    showRadioButton: true,
}