import {
  LOADER,
  OPEN_SIDE_MENU,
  ADDREMOVELABEL,
  REMOVELABEL,
  REMOVESERIESLABEL,
} from "../actions/constants";

let intiState = {
  sidebarOpen: false,
  loader: false,
  highChartTooltips: {},
};

const common = (state = intiState, action) => {
  switch (action.type) {
    case OPEN_SIDE_MENU:
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case ADDREMOVELABEL:
      return {
        ...state,
        highChartTooltips: action.payload,
      };
    case REMOVELABEL:
      return {
        ...state,
        highChartTooltips: { ...state.highChartTooltips, [action.payload]: [] },
      };
    case REMOVESERIESLABEL:
      const payload = {
        ...state.highChartTooltips,
        [action.payload.id]: state.highChartTooltips[action.payload.id].filter(
          (i) => action.payload.pointIds.findIndex((id) => id === i.id) === -1
        ),
      };
      return {
        ...state,
        highChartTooltips: { ...payload },
      };
    default:
      return state;
  }
};

export default common;
