import {
  FETCH_ASSORTMENT_SIMULATION_REQUEST,
  FETCH_ASSORTMENT_SIMULATION_SUCCESS,
  FETCH_ASSORTMENT_SIMULATION_FAILURE,
  FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS,
  FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS,
  FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS,
  FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE,
  FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST,
  FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS,
  FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE,
  SET_VIEW_MODE,
  SET_MAX_SOP_MIX_BAR_VAL
} from "../../actions/constants";

// Initial state
const initialState = {
  summaryData: null,
  sizeOfPrice: null,
  mustHaveSku: null,
  quadrantData: null,
  assortmentData: null,
  loaderForSummaryData: false,
  loaderForSimulation: false,
  loaderForSizeOfPrize: false,
  loaderForMustHaveSku: false,
  loaderForQuadrantChart: false,
  loaderForListTable: false,
  viewMode: false,
  sopMaxValue: 1.0
};

const assortmentDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSORTMENT_SIMULATION_REQUEST:
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    case FETCH_ASSORTMENT_SUMMARY_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForSummaryData: true,
      };
    case FETCH_ASSORTMENT_LIST_TABLE_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForListTable: true,
      };
    case FETCH_ASSORTMENT_QUADRANT_CHART_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loaderForQuadrantChart: true,
      };
    case FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS:
      return {
        ...state,
        quadrantData: action.payload,
        loaderForQuadrantChart: false,
      };
    case FETCH_ASSORTMENT_SIMULATION_SUCCESS:
      return {
        ...state,
        summaryData: action.payload,
      };
    case FETCH_ASSORTMENT_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        loaderForSummaryData: false,
        summaryData: action.payload,
      };
    case FETCH_ASSORTMENT_SIZE_OF_PRICE_SUCCESS:
      return {
        ...state,
        sizeOfPrice: action.payload.sizeOfPrize,
        assortmentData: action.payload.tabledata,
        loaderForSizeOfPrize: false,
        loaderForListTable: false,
      };
    case FETCH_ASSORTMENT_MUST_HAVE_SKU_SUCCESS:
      return {
        ...state,
        mustHaveSku: action.payload,
        loaderForMustHaveSku: false,
      };
    case FETCH_ASSORTMENT_QUADRANT_CHART_DATA_SUCCESS:
      return {
        ...state,
        quadrantData: action.payload,
        loaderForQuadrantChart: false,
      };
    case FETCH_ASSORTMENT_LIST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        assortmentData: action.payload,
        loaderForListTable: false,
      };
    case FETCH_ASSORTMENT_SIMULATION_FAILURE:
      return {
        ...state,
        loaderForSimulation: false,
        loaderForSizeOfPrize: false,
        loaderForMustHaveSku: false,
        loaderForQuadrantChart: false,
        loaderForListTable: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_LIST_TABLE_DATA_FAILURE:
      return {
        ...state,
        loaderForListTable: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        loaderForSummaryData: false,
        error: action.payload,
      };
    case FETCH_ASSORTMENT_QUADRANT_CHART_DATA_FAILURE:
      return {
        ...state,
        loaderForQuadrantChart: false,
        error: action.payload,
      };
    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };
      case SET_MAX_SOP_MIX_BAR_VAL:
      return {
        ...state,
        sopMaxValue: action.payload,
      };
    default:
      return state;
  }
};
export default assortmentDashboardReducer;
