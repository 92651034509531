import { makeStyles } from '@material-ui/core/styles';
import { Add, Remove } from '@mui/icons-material';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { separator } from '../../../../util/helper';
import TableCtnr from './TableContainer';
const useStyles = makeStyles(() => ({
  borderClass: {
    borderLeft: '1px solid #F5F5F5',
  },
  cellPadding: {
    padding: '0px 0px 0px 5px !important',
  },
  flexEvenly: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  overflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0px 0px 0px 0px',
  },
  dFlex: {
    display: 'flex !important',
    alignItems: 'center',
  },
  tdWidth: {
    width: '180px !important',
  },
  collapseSignColor: {
    color: '#25d4cf',
  },
}));
const Row: React.FC<{ row: any; data: any; level; type; responseKeys; style }> = ({ row, data, level, type, responseKeys = [], style }) => {
  const [open, setOpen] = useState(false);
  const [nextLevel, setNextLevel] = useState('');
  const [groupName, setGroupName] = useState('');
  const classes = useStyles();
  const isFirstCell = (idx) => idx === 0;
  const isLastLevel = () => {
    return level === 'pepsiPackSizes' || level === 'competitorPackSizes';
  };
  const [vendor, setVendor] = useState(null);
  const setLevel = (payload) => {
    setGroupName(payload?.name);
    if (type === 'pepsico') {
      switch (level) {
        case 'vendor':
          setNextLevel('pepsiCategory');
          break;
        case 'pepsiCategory':
          setNextLevel('pepsiSegment');
          break;
        case 'pepsiSegment':
          setNextLevel('pepsiBrands');
          break;
        case 'pepsiBrands':
          setNextLevel('pepsiSubBrands');
          break;
        case 'pepsiSubBrands':
          setNextLevel('pepsiPackSizes');
          break;
        default:
          break;
      }
    } else if (type === 'competitor') {
      setVendor(payload?.vendor);
      switch (level) {
        case 'competitorVendor':
          setNextLevel('competitorCategory');
          break;
        case 'competitorCategory':
          setNextLevel('competitorSegment');
          break;
        case 'competitorSegment':
          setNextLevel('competitorBrands');
          break;
        case 'competitorBrands':
          setNextLevel('competitorSubBrands');
          break;
        case 'competitorSubBrands':
          setNextLevel('competitorPackSizes');
          break;
        default:
          break;
      }
    }
  };
  const rowData = responseKeys?.map((attr, idx) => (
    <TableCell
      align={isFirstCell(idx) ? 'left' : 'center'}
      className={`${!isFirstCell(idx) && classes.borderClass} ${isFirstCell(idx) && classes.dFlex} ${!isFirstCell(idx) && classes.tdWidth} ${
        classes.overflowEllipsis
      }`}
      key={attr}
      style={{ width: isFirstCell(idx) ? '100%' : '18%' }}
    >
      {isFirstCell(idx) && !isLastLevel() && (
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setLevel(row);
            setOpen((prev) => !prev);
          }}
        >
          {open ? <Remove className={classes.collapseSignColor} /> : <Add className={classes.collapseSignColor} />}
        </IconButton>
      )}
      {isFirstCell(idx) && isLastLevel() && <IconButton style={{ width: '34px' }}></IconButton>}
      {
        <p
          className={classes.overflowEllipsis}
          title={row[attr]}
          style={{ width: isFirstCell(idx) ? `calc(${style.cellWidth} - ${row.index * 5}px)` : '100%' }}
        >
          {separator(row[attr], 2)}
        </p>
      }
    </TableCell>
  ));

  return (
    <React.Fragment>
      {rowData}
      <TableRow style={{ overflow: 'hidden' }}>
        <TableCell className={classes.cellPadding} colSpan={responseKeys.length}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <>
              {!isLastLevel() && nextLevel && (
                <TableCtnr
                  style={{ overflow: 'hidden', cellWidth: style.cellWidth }}
                  data={data}
                  level={nextLevel}
                  type={type}
                  groupName={groupName}
                  vendor={vendor}
                  responseKeys={responseKeys}
                />
              )}
            </>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default Row;
