import React from 'react';
import CategoryDahboardList from '../../components/CategoryDashboardList/CartegoryDashboadList';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageList, pageNames } from '../../mocks/common';

const IntegratedStrategy: React.FC = () => {
  return <PageContainer page={pageNames.integratedStrategy}>
    <CategoryDahboardList title={'Integrated Strategy'} categoryKey={'integrated_strategy'} pageName={pageList[17].value}/>
  </PageContainer>;
};
export default IntegratedStrategy;
