import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import FilterAccordion from '../../../../components/Filters/Filters';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import MeasureFilter from '../MeasureFilters';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import Indicator from '../../../../components/Loader';
import { dataFormat } from '../../../../util/helper';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { initialState, dateRange } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import mockData from '../../../../mocks/historyPriceMock';
import { priceElasticityDataLoader } from '../../store/historicPrice.action';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';

const TopFilters: React.FC<{
  callback;
  showSkeleton?: boolean;
  defaultFilters?;
}> = ({ callback, showSkeleton, defaultFilters }) => {
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    comparisonLevel: mockData.measureFilter.comparisonLevel.defaultOption.value || undefined,
    priceFormula: mockData.measureFilter.priceFormula.defaultOption.value || undefined,
  });
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(`${dataFormat(dateRange[0], 'MM/dd/yyyy')}-${dataFormat(dateRange[1], 'MM/dd/yyyy')}`);
  const dispatch = useDispatch();
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState({
    ...initialState,
  });
  const [selectedAnchorFilters, setSelectedAnchorFilters] = useState<any>({});
  const [comparisionLevel, setComparisonLevel] = useState(mockData.measureFilter.comparisonLevel.defaultOption.value);

  const [prevComparisonLevel, setPrevComparisonLevel] = useState(mockData.measureFilter.comparisonLevel.defaultOption.value);

  const [productMockData, setProductMockData] = useState<any>({});

  const disableApplyFilter = () => {
    const flag: boolean =
      !comparisionLevel ||
      !selectedMeasureFilters.priceFormula ||
      selectedGeoFilters.country === '' ||
      selectedGeoFilters.country === null ||
      selectedGeoFilters.geoLevel2 === '' ||
      selectedGeoFilters.geoLevel2 === null ||
      (comparisionLevel === 'brand' ? !selectedAnchorFilters.anchorBrand : !selectedAnchorFilters.anchorPackSize);
    return flag;
  };

  const onApplyFilter = async () => {
    dispatch(priceElasticityDataLoader(true));
    if (selectedGeoFilters.periodView) {
      delete selectedGeoFilters.periodView;
    }
    const payload = {
      ...selectedGeoFilters,
      ...selectedAnchorFilters,
      ...selectedMeasureFilters,
      comparisonLevel: comparisionLevel,
      date: selectedDate,
    };
    if (comparisionLevel === 'brand') {
      delete payload.anchorSubBrand;
      delete payload.anchorPackSize;
    }
    callback(payload);
  };

  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setSelectedAnchorFilters({
      ...selectedAnchorFilters,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setMeasureFilter({
      comparisonLevel: mockData.measureFilter.comparisonLevel.defaultOption.value || undefined,
      priceFormula: mockData.measureFilter.priceFormula.defaultOption.value || undefined,
    });
  };

  const selectanchorLogic = (e) => {
    setSelectedAnchorFilters({ ...e });
    clearGeoFilter(false);
  };

  useEffect(() => {
    if (comparisionLevel === 'brand') {
      setProductMockData({ ...mockData.anchorFiltersBrand });
    } else {
      setProductMockData({ ...mockData.anchorFilter });
    }
  }, [comparisionLevel]);

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setComparisonLevel(defaultFilters?.comparisonLevel);
      setTimeout(() => {
        setPrevComparisonLevel(defaultFilters?.comparisonLevel);
      }, 0);
    }
  }, [defaultFilters]);

  const HistoricPrice = useSelector((state: any) => state.HistoricPrice);
  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator position='absolute' show={loader || HistoricPrice.priceElasticityDataLoader} />
      <CardContent>
        {!showSkeleton ? (
          <>
            <FilterAccordion title='Geo-Filters'>
              <GeoFiltersV2
                key='api-predictive-top-filter'
                data={geoFilterV2Config}
                onChangeDate={(dt) => {
                  clearGeoFilter(false);
                  setSelectedDate(dt);
                }}
                onSelectFilters={setSelectedGeoFilters}
                clearFilter={clearFilterData}
                apiPath='ApiPredictive'
                showLoader={setLoader}
                defaultFilters={defaultFilters}
                defaultDate={defaultFilters?.date ? defaultFilters?.date.split('-') : null}
              />
            </FilterAccordion>
            <FilterAccordion title='Additional Filters'>
              <Grid key={`measures-dropdown-container-2`}>
                <DropdownTitle>Comparision Level</DropdownTitle>
                <RadioButtonGroup
                  key={`measures-filters-2`}
                  color='#000'
                  showButton={mockData.measureFilter.comparisonLevel.showRadioButton}
                  data={mockData.measureFilter.comparisonLevel.options}
                  defaultOption={comparisionLevel}
                  select={(data) => {
                    setComparisonLevel(data);
                    setTimeout(() => {
                      setPrevComparisonLevel(data);
                    }, 0);
                  }}
                />
              </Grid>
            </FilterAccordion>
            <FilterAccordion title='Product Filters'>
              <ProductFiltersV3
                onChange={(e) => selectanchorLogic(e)}
                data={productMockData}
                onChangeLoader={setLoader}
                selectedGeoFilters={comparisionLevel === prevComparisonLevel && selectedGeoFilters.country ? selectedGeoFilters : {}}
                clearFilter={clearFilterData}
                apiURL='filter/anchorproducts'
                defaultFilters={
                  defaultFilters
                    ? {
                        anchorCategory: defaultFilters.anchorCategory,
                        anchorSegment: defaultFilters.anchorSegment,
                        anchorBrand: defaultFilters.anchorBrand,
                        anchorSubBrand: defaultFilters.anchorSubBrand,
                        anchorPackSize: defaultFilters.anchorPackSize,
                      }
                    : null
                }
              />
            </FilterAccordion>
            <FilterAccordion title='Measure Filters'>
              <MeasureFilter
                data={mockData.measureFilter}
                selectedGeoFilters={selectedGeoFilters}
                callback={(e) => setMeasureFilter(e)}
                selectedMeasureFilters={selectedMeasureFilters}
                defaultFilters={
                  defaultFilters
                    ? {
                        comparisonLevel: defaultFilters.comparisonLevel,
                        priceFormula: defaultFilters.priceFormula,
                      }
                    : null
                }
              />
            </FilterAccordion>
            <Grid className='p-l-16'>
              <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <FilterAccordion showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} />
                    <Skeleton variant='rectangular' width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </FilterAccordion>
            {/* <FilterAccordion showSkeleton></FilterAccordion> */}
            <Grid className='m-b-20'>
              <FilterAccordion showSkeleton>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </FilterAccordion>
            </Grid>
            <Grid className='p-l-16' container spacing={2}>
              <Skeleton variant='rectangular' className='m-r-20' width={99} height={45} />
              <Skeleton variant='rectangular' className='m-r-20' width={110} height={45} />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TopFilters;
