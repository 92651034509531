import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { Grid, Skeleton, Typography } from "@mui/material";
import {
  QuadrantChartConfig,
  formatValue,
  data,
  colors,
  getRandomHexColor,
} from "./QuadrantChartConfig";
import QuadrantGraphPopup from "../../../pages/AssortmentTool/components/QuadrantGraph/QuadrantGraphPopup";
import { hasNullOrBlankValue } from "../../../pages/AssortmentTool/components/CommonComponents/utils";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

const dataOptions = QuadrantChartConfig;

const QuadrantChart = ({ chartRef, quadrantFilters, selectedGeoFilters }) => {
  const [averagePlotLineValues, setAveragePlotLineValues] = useState({
    averageXAxis: 76.64408429229961,
    averageYAxis: 14.125883779105019,
  });

  const [segmentColorLabel, setSegmentColorLabel] = useState({});

  const AssortmentQuadrantData = useSelector(
    (state) => state.assortmentDashboardReducer
  );

  const { defaultQuadrantFilters } =
  useSelector((state: any) => state.AssortmentFiltersReducer);

  const [labelValues, setLabelValues] = useState({
    xAxisLabel: "GrossMargin",
    xAxisPlotLineLabel: "Average GrossMargin",
    yAxisLabel: "Penetration",
    yAxisPlotLineLabel: "Average Penetration",
  });
  const [bubbleChartData, setBubbleChartData] = useState(dataOptions);

  const options = {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zoomType: "xy",
      backgroundColor: "#F1F5FE",
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: true,
    },
    xAxis: {
      showLastLabel: true,
      endOnTick: true,
      lineColor: "transparent",
      allowDecimals: true,
      title: {
        text: `${labelValues.xAxisLabel}`,
      },
      maxPadding: 0.2,
      labels: {
        formatter: function () {
          const value = this.value;
          const absValue = Math.abs(value);

          if (absValue >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 1) + "B";
          } else if (absValue >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 1) + "M";
          } else if (absValue >= 1e3) {
            return Highcharts.numberFormat(value / 1e3, 1) + "K";
          } else {
            return Highcharts.numberFormat(value, 1);
          }
        },
      },
      plotLines: [
        {
          color: "#2F5597",
          width: 2,
          value: averagePlotLineValues.averageXAxis,
          label: {
            style: {
              fontStyle: "italic",
            },
            text: `${labelValues.xAxisPlotLineLabel} (${formatValue(
              averagePlotLineValues.averageXAxis
            )})`,
          },
        },
      ],
    },
    yAxis: {
      allowDecimals: true,
      title: {
        text: `${labelValues.yAxisLabel}`,
      },
      showLastLabel: true,
      endOnTick: true,
      labels: {
        formatter: function () {
          const value = this.value;
          const absValue = Math.abs(value);

          if (absValue >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 1) + "B";
          } else if (absValue >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 1) + "M";
          } else if (absValue >= 1e3) {
            return Highcharts.numberFormat(value / 1e3, 1) + "K";
          } else {
            return Highcharts.numberFormat(value, 1);
          }
        },
      },
      maxPadding: 0.2,

      plotLines: [
        {
          color: "#2F5597",

          width: 2,
          value: averagePlotLineValues.averageYAxis,
          label: {
            align: "right",
            style: {
              fontStyle: "italic",
            },
            text: `${labelValues.yAxisPlotLineLabel} (${formatValue(
              averagePlotLineValues.averageYAxis
            )})`,
          },
          zIndex: 3,
        },
      ],
      accessibility: {
        rangeDescription: "",
      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: "<table>",
      pointFormatter: function () {
        const formattedZ = formatValue(this.z);
        return (
          '<tr><th colspan="2"><h3>' +
          this.name +
          "</h3></th></tr>" +
          "<tr><td></td><td>" +
          formattedZ +
          "</td></tr>"
        );
      },
      footerFormat: "</table>",
      followPointer: true,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
      },
    },
    series: [
      {
        data: data,
        colorByPoint: false,
        color: "#9483FB",
      },
    ],
  };

  useEffect(() => {
    let data;

    data = bubbleChartData["series"][0]["data"];

    setBubbleChartData({
      ...bubbleChartData,
      xAxis: {
        ...bubbleChartData.xAxis,
      },
      yAxis: {
        ...bubbleChartData.yAxis,
      },
    });
  }, []);

  useEffect(() => {
    if (AssortmentQuadrantData.quadrantData) {
      const { chartData, averageData } = AssortmentQuadrantData.quadrantData;
      setAveragePlotLineValues(averageData[0]);
      changeResponseToBubbleData(chartData, averageData);
    }
  }, [AssortmentQuadrantData.quadrantData]);

  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;

      const xAxisPlotLine = {
        color: "#2F5597",
        width: 2,
        value: Math.floor(averagePlotLineValues.averageXAxis * 100) / 100,
        label: {
          rotation: 0,
          style: {
            fontStyle: "italic",
          },
          text: `Average ${quadrantFilters["xAxis"]} (${formatValue(
            Math.floor(averagePlotLineValues.averageXAxis * 100) / 100
          )})`,
        },
        zIndex: 3,
      };

      let data;
      data = bubbleChartData["series"][0]["data"];

      const yAxisPlotLine = {
        color: "#2F5597",
        width: 2,
        value: Math.floor(averagePlotLineValues.averageYAxis * 100) / 100,
        label: {
          align: "right",
          style: {
            fontStyle: "italic",
          },
          text: `Average ${quadrantFilters["yAxis"]} (${formatValue(
            Math.floor(averagePlotLineValues.averageYAxis * 100) / 100
          )})`,
        },
        zIndex: 3,
      };

      chart.xAxis[0].update({
        plotLines: [xAxisPlotLine],
      });

      chart.yAxis[0].update({
        plotLines: [yAxisPlotLine],
      });
      chart.redraw();
    }
  }, [averagePlotLineValues, quadrantFilters]);

  useEffect(() => {
    setLabelValues({
      xAxisLabel: `${quadrantFilters["xAxis"]}`,
      xAxisPlotLineLabel: `Average ${quadrantFilters["xAxis"]}`,
      yAxisLabel: `${quadrantFilters["yAxis"]}`,
      yAxisPlotLineLabel: `Average ${quadrantFilters["yAxis"]}`,
    });
  }, [quadrantFilters]);

  useEffect(() => {
    let data;
    data = bubbleChartData["series"][0]["data"];

    setBubbleChartData({
      ...bubbleChartData,
      xAxis: {
        ...bubbleChartData.xAxis,
        title: {
          text: `${labelValues.xAxisLabel}`,
        },
        plotLines: [
          {
            ...bubbleChartData.xAxis.plotLines[0],
            label: {
              ...bubbleChartData.xAxis.plotLines[0].label,
              text: `${labelValues.xAxisPlotLineLabel} (${formatValue(
                averagePlotLineValues.averageXAxis
              )})`,
            },
          },
        ],
      },
      yAxis: {
        ...bubbleChartData.yAxis,
        title: {
          text: `${labelValues.yAxisLabel}`,
        },
        plotLines: [
          {
            ...bubbleChartData.yAxis.plotLines[0],
            label: {
              ...bubbleChartData.yAxis.plotLines[0].label,
              text: `${labelValues.yAxisPlotLineLabel} (${formatValue(
                averagePlotLineValues.averageYAxis
              )})`,
            },
          },
        ],
      },
    });
  }, [labelValues]);

  useEffect(() => {
    let data;
    data = bubbleChartData["series"][0]["data"];
    setBubbleChartData({
      ...bubbleChartData,
      xAxis: {
        ...bubbleChartData.xAxis,
      },
      yAxis: {
        ...bubbleChartData.yAxis,
      },
    });
  }, []);

  const changeResponseToBubbleData = (result, avgData) => {
    const segmentColorMapping: Record<string, string> = {};

    const uniqueSegments = [
      ...new Set(result.map((item: { segment: string }) => item.segment)),
    ];
    const sortedColors = colors.sort();
    const sortedSegments = uniqueSegments.sort();

    sortedSegments.forEach((segment: string, index) => {
      if (index < sortedColors.length) {
        segmentColorMapping[segment] = sortedColors[index];
      } else {
        const randomColor = getRandomHexColor();
        segmentColorMapping[segment] = randomColor;
      }
    });

    const response = result.map((ele, i) => {
      return {
        x: ele.xAxis,
        y: ele.yAxis,
        z: ele.bubbleSize,
        name: ele.sku,
        segment: ele.segment,
        color: segmentColorMapping[ele.segment],
        xAxis: defaultQuadrantFilters.xAxis,
        yAxis: defaultQuadrantFilters.yAxis,
        bubbleSize: defaultQuadrantFilters.bubbleSize
      };
    });

    setSegmentColorLabel(segmentColorMapping);

    if (
      chartRef &&
      chartRef.current &&
      chartRef.current.chart &&
      chartRef.current.chart.series &&
      chartRef.current.chart.series[0]
    ) {
      chartRef.current.chart.series[0].setData(response);
      let output: any = [
        {
          data: response,
          colorByPoint: false,
          color: "#9483FB",
        },
      ];
      setBubbleChartData({
        ...bubbleChartData,
        series: output,
      });
    }
  };

  const [quadrantPopup, setQuadrantPopup] = useState(false);

  const quadrantPopupDialog = () => {
    setQuadrantPopup(true);
  };

  return (
    <>
      <div
        onDoubleClick={quadrantPopupDialog}
        style={{ backgroundColor: "#F1F5FE", color: "#fff" }}
      >
        {AssortmentQuadrantData.quadrantData === null ||
        hasNullOrBlankValue(quadrantFilters) ||
        hasNullOrBlankValue(selectedGeoFilters) ? (
          <Grid className="p-l-16" style={{marginTop:"10px"}} container spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={145} />
          </Grid>
        ) : (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={bubbleChartData}
              ref={chartRef}
            />
            <Grid style={{display:"flex",marginTop:"5px",justifyContent:"center"}} container spacing={1}>
              {Object.entries(segmentColorLabel).map(([segment, color]) => (
                <Grid item key={segment} >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: color as string,
                        height: "15px",
                        width: "15px",
                        textAlign: "center",
                        marginRight: "8px",
                      }}
                    ></div>
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#575C5F",
                      }}
                    >
                      {segment}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
      {quadrantPopup && (
        <QuadrantGraphPopup
          onCloseHandler={() => setQuadrantPopup(false)}
          quadrantData={bubbleChartData["series"][0]["data"]}
        />
      )}
    </>
  );
};

export default QuadrantChart;
