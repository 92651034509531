import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import FilterAccordion from '../../../../components/Filters/Filters';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import GeoFiltersV2 from '../../../../components/GeoFilters-V2';
import PeriodViewFilter from '../PeriodViewFilter';
import Indicator from '../../../../components/Loader';
import { geoFilterV2Config } from '../../../../mocks/geoFilters';
import { dateRange, initialState } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import filterMock, { periodView } from '../../../../mocks/priceLadderMock';
import { OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import { dataFormat, enableCompetitorFilter } from '../../../../util/helper';
import MeasuresFilters from '../MeasuresFilters/MeasuresFilters';
import { priceLadderDataLoader } from '../../store/PriceLadder.action';
import mockData from '../../../../mocks/priceLadderMock';
import { CustomDropDownTitle } from '../../../../components/ProductFilters-v3/ProductFilters-v3';
import Dropdown from '../../../../components/Dropdown';
import { dateRangeSellIn, dateRangeSellOut } from '../PeriodViewFilter/PeriodViewFilter';
import '../priceLadder.css';

const TopFilters: React.FC<{ callback; topClearCallBack; defaultFilters?}> = ({ callback, topClearCallBack, defaultFilters }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [clearMeasure, setClearMeasure] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedPepsicoFilters, setSelectedPepsicoFilters] = React.useState<any>({});
  const [defaultPepsicoFilters, setDefaultPepsicoFilters] = useState({});
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] = React.useState<any>({});
  const [defaultCompetitorFilters, setDefaultCompetitorFilters] = useState({});
  const [selectedPcOptions, setPcOptions] = React.useState([]);
  const [selectedProductFiltersComparisonLevel, setProductFiltersComparisonLevel] = useState({
    comparisonLevel: filterMock.productFilterBy.comparisonLevel.defaultOption.value || undefined,
  });
  const [prevComparisonLevel, setPrevComparisonLevel] = useState(filterMock.productFilterBy.comparisonLevel.defaultOption.value || undefined);
  const [selectedMeasureFilters, setMeasureFilters] = useState({
    somValueType: filterMock.measuresFilter.somValueType.defaultOption.value || undefined,
    somValueBy: filterMock.measuresFilter.somValueBy.defaultOption.value || undefined,
    apiFilter: filterMock.measuresFilter.apiFilter.defaultOption.value || undefined,
    measuresFilterSOP: filterMock.measuresFilter.measuresFilterSOP.defaultOption.value || undefined,
    markup: '',
    taxes: '',
  });

  const [selectedSellInDate, setSelectedSellInDate] = useState(
    `${dataFormat(dateRangeSellIn[0], 'MM/dd/yyyy')}-${dataFormat(dateRangeSellIn[1], 'MM/dd/yyyy')}`
  );

  const [selectedSellOutDate, setSelectedSellOutDate] = useState(
    `${dataFormat(dateRangeSellOut[0], 'MM/dd/yyyy')}-${dataFormat(dateRangeSellOut[1], 'MM/dd/yyyy')}`
  );

  const [selectedProductFiltersPriceLadderBy, setProductFiltersPriceLadderBy] = useState({
    priceLadderBy: filterMock.productFilterBy.priceLadderBy.defaultOption.value || undefined,
  });

  const [productMockData, setProductMockData] = useState<any>({});
  const [compitetorMockData, setcompitetorMockData] = useState<any>({});

  const handleCompetitorSelections = (selectedValues, filterCon) => {
    setSelectedCompetitorFilters(selectedValues);
    if (filterCon !== undefined) {
      let PcOptions = filterCon.permutationComputation.options;
      let PcOptionsSelected = selectedValues.permutationComputation;
      const tempArr = PcOptions.filter((item) => PcOptionsSelected?.indexOf(item.value) !== -1);
      setPcOptions(tempArr);
    }
  };

  const clearFilter = () => {
    topClearCallBack();
    clearGeoFilter(true);
    setClearMeasure(true);
    setSelectedGeoFilters({ ...initialState });
    setMeasureFilters({
      somValueType: filterMock.measuresFilter.somValueType.defaultOption.value || undefined,
      somValueBy: filterMock.measuresFilter.somValueBy.defaultOption.value || undefined,
      apiFilter: filterMock.measuresFilter.apiFilter.defaultOption.value || undefined,
      measuresFilterSOP: filterMock.measuresFilter.measuresFilterSOP.defaultOption.value || undefined,
      markup: '',
      taxes: '',
    });

    setProductFiltersPriceLadderBy({
      priceLadderBy: filterMock.productFilterBy.priceLadderBy.defaultOption.value || undefined,
    });

    setProductFiltersComparisonLevel({
      comparisonLevel: filterMock.productFilterBy.comparisonLevel.defaultOption.value || undefined,
    });
    setPrevComparisonLevel(filterMock.productFilterBy.comparisonLevel.defaultOption.value || undefined);
    setSelectedPepsicoFilters({});
    setSelectedCompetitorFilters({});
  };

  const setSelectedGeoFiltersLogic = (e) => {
    setProductFiltersComparisonLevel({ comparisonLevel: defaultFilters.comparisonLevel || 'brandsize' });
    setPrevComparisonLevel(defaultFilters.comparisonLevel || 'brandsize');

    setSelectedGeoFilters({ ...e });
    if (selectedGeoFilters.periodView) {
      setSelectedGeoFilters({ ...e, periodView: 'null' });
    }
    if (clearFilterData) {
      clearGeoFilter(false);
    }
  };

  const onApplyFilter = async () => {
    dispatch(priceLadderDataLoader(true));
    let PcOptions = [];
    if (selectedProductFiltersComparisonLevel.comparisonLevel === 'brandsize') {
      PcOptions = mockData.competitorFilter.permutationComputation.options;
    } else if (selectedProductFiltersComparisonLevel.comparisonLevel === 'subbrandsize') {
      PcOptions = mockData.competitorFilterSubBrand.permutationComputation.options;
    }

    let PcOptionsSelected = selectedCompetitorFilters.permutationComputation;
    const tempArr = PcOptions.filter((item) => PcOptionsSelected?.indexOf(item.value) !== -1);
    setPcOptions(tempArr);
    callback({
      filters: {
        ...selectedGeoFilters,
        ...selectedMeasureFilters,
        ...selectedProductFiltersPriceLadderBy,
        ...selectedProductFiltersComparisonLevel,
        permutationOptions: [...tempArr],
        pepsi: { ...selectedPepsicoFilters, vendor: ['PEPSICO'] },
        competitor: { ...selectedCompetitorFilters },
        sellInStartDate: selectedSellInDate.split('-')[0],
        sellInEndDate: selectedSellInDate.split('-')[1],
        sellOutStartDate: selectedSellOutDate.split('-')[0],
        sellOutEndDate: selectedSellOutDate.split('-')[1],
      },
    });
  };

  const disableApplyFilter = () => {
    const flag =
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel2 ||
      !selectedPepsicoFilters.packSize ||
      !selectedPepsicoFilters.permutationComputation ||
      (enableCompetitorFilter(selectedGeoFilters) && !selectedCompetitorFilters.permutationComputation);
    return flag;
  };

  const PriceLadderDataLoader = useSelector((state: any) => state.PriceLadderDataLoader);

  useEffect(() => {
    if (selectedProductFiltersComparisonLevel.comparisonLevel === 'brandsize') {
      setProductMockData({ ...filterMock.productFilter });
      setcompitetorMockData({ ...filterMock.competitorFilter });
    } else if (selectedProductFiltersComparisonLevel.comparisonLevel === 'subbrandsize') {
      setProductMockData({ ...filterMock.productFilterSubBrand });
      setcompitetorMockData({ ...filterMock.competitorFilterSubBrand });
    }
  }, [selectedProductFiltersComparisonLevel.comparisonLevel]);

  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      setProductFiltersComparisonLevel({ comparisonLevel: defaultFilters.comparisonLevel });
      setTimeout(() => {
        setPrevComparisonLevel(defaultFilters.comparisonLevel);
      }, 0);
      setMeasureFilters({
        somValueType: defaultFilters.somValueType,
        somValueBy: defaultFilters.somValueBy,
        apiFilter: defaultFilters.apiFilter,
        measuresFilterSOP: defaultFilters.measuresFilterSOP,
        markup: defaultFilters.markup,
        taxes: defaultFilters.taxes,
      });
      setProductFiltersPriceLadderBy({ priceLadderBy: defaultFilters.priceLadderBy });
      setSelectedPepsicoFilters({ ...defaultFilters.pepsi });
      setDefaultPepsicoFilters({ ...defaultFilters.pepsi });
      setSelectedCompetitorFilters({ ...defaultFilters.competitor });
      setDefaultCompetitorFilters({ ...defaultFilters.competitor });
    }
  }, [defaultFilters]);

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator position='absolute' show={loader || PriceLadderDataLoader.priceLadderDataLoader} />
      <CardContent>
        <>
          <FilterAccordion title='Geo-Filters'>
            <Grid container spacing={2} columns={16}>
              <Grid item sm={9} className='geo_filters_left'>
                <GeoFiltersV2
                  key='price-ladder-top-filter'
                  data={geoFilterV2Config}
                  onChangeDate={(dt) => {
                    clearGeoFilter(false);
                  }}
                  onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
                  clearFilter={clearFilterData}
                  apiPath='PriceLadder'
                  showLoader={setLoader}
                  defaultFilters={defaultFilters}
                  showDatePicker={false}
                />
              </Grid>
            </Grid>
          </FilterAccordion>

          <FilterAccordion title='Products Filters'>
            <Grid container display='flex' alignItems='center' justifyContent='space-between'>
              <Grid container spacing={3} xs={12} sm={8}>
                <Grid item xs={12} sm={4}>
                  <Typography variant='subtitle2'>Comparison Level</Typography>
                  <Grid>
                    <RadioButtonGroup
                      key={`product-filters-1`}
                      color='#000'
                      showButton={filterMock.productFilterBy.comparisonLevel.showRadioButton}
                      data={
                        selectedGeoFilters.country === 'MEXICO' || !selectedGeoFilters.country
                          ? filterMock.productFilterBy.comparisonLevel.options
                          : filterMock.productFilterBy.comparisonLevel.optionsNonMexico
                      }
                      defaultOption={selectedProductFiltersComparisonLevel.comparisonLevel}
                      select={(data) => {
                        setProductFiltersComparisonLevel({ comparisonLevel: data });
                        setTimeout(() => {
                          setPrevComparisonLevel(data);
                        }, 0);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {/* Price Ladder By */}
                  <Typography variant='subtitle2'>Price Ladder By</Typography>
                  <Grid>
                    <RadioButtonGroup
                      key={`product-filters-2`}
                      color='#000'
                      showButton={filterMock.productFilterBy.priceLadderBy.showRadioButton}
                      data={filterMock.productFilterBy.priceLadderBy.options}
                      defaultOption={selectedProductFiltersPriceLadderBy.priceLadderBy}
                      select={(data) => {
                        setProductFiltersPriceLadderBy({ priceLadderBy: data });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={Object.keys(productMockData).length + 1}>
                <Grid item xs={1} className='productFilterLevels'>
                  <>
                    <CustomDropDownTitle title={'Vendor <span style="color: #858c94;">(Pepsico)</span>'} />
                    <Dropdown disabled={true} data={[{ lable: 'PEPSICO', value: 'PEPSICO' }]} defaultOption={['PEPSICO']} placeholder='Select' />
                  </>
                </Grid>
                <Grid item xs={Object.keys(productMockData).length}>
                  <ProductFiltersV3
                    onChange={setSelectedPepsicoFilters}
                    data={productMockData}
                    onChangeLoader={setLoader}
                    selectedGeoFilters={
                      selectedProductFiltersComparisonLevel.comparisonLevel === prevComparisonLevel &&
                        selectedGeoFilters.country &&
                        selectedGeoFilters.geoLevel2
                        ? selectedGeoFilters
                        : {}
                    }
                    clearFilter={clearFilterData}
                    defaultFilters={{
                      category: defaultPepsicoFilters.category,
                      segment: defaultPepsicoFilters.segment,
                      brand: defaultPepsicoFilters.brand,
                      subBrand: defaultPepsicoFilters.subBrand,
                      packSize: defaultPepsicoFilters.packSize,
                      permutationComputation: defaultPepsicoFilters.permutationComputation,
                    }}
                    apiURL='filter/multipleapiproductswithPC?type=pepsi'
                    params={{
                      comparisonLevel: selectedProductFiltersComparisonLevel.comparisonLevel,
                      vendor: ['PEPSICO'],
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={12}>
                <ProductFiltersV3
                  onChange={(selectedValues, filterCon) => {
                    handleCompetitorSelections(selectedValues, filterCon);
                  }}
                  data={compitetorMockData}
                  onChangeLoader={setLoader}
                  selectedGeoFilters={
                    enableCompetitorFilter(selectedGeoFilters) &&
                      selectedProductFiltersComparisonLevel.comparisonLevel === prevComparisonLevel &&
                      selectedGeoFilters.country &&
                      selectedGeoFilters.geoLevel2
                      ? selectedGeoFilters
                      : {}
                  }
                  clearFilter={clearFilterData}
                  defaultFilters={{
                    vendor: defaultCompetitorFilters.vendor,
                    category: defaultCompetitorFilters.category,
                    segment: defaultCompetitorFilters.segment,
                    brand: defaultCompetitorFilters.brand,
                    subBrand: defaultCompetitorFilters.subBrand,
                    packSize: defaultCompetitorFilters.packSize,
                    permutationComputation: defaultCompetitorFilters.permutationComputation,
                  }}
                  apiURL='filter/multipleapiproductswithPC?type=competitor'
                  params={{
                    comparisonLevel: selectedProductFiltersComparisonLevel.comparisonLevel,
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12}>
                <b>Note:</b> Selecting more than 10 products for PEPSICO and Competitors, will impact the dashboard performance and the quality of the
                visualization.
              </Grid>
            </Grid>
          </FilterAccordion>
          <FilterAccordion title='Date Filters'>
            <Grid sx={{ mb: 4 }}>
              <PeriodViewFilter
                key='periodView-top-filter'
                data={{ ...geoFilterV2Config, periodView }}
                onChangeDateSellIn={(dt) => {
                  clearGeoFilter(false);
                  setSelectedSellInDate(dt);
                }}
                onChangeDateSellOut={(dt) => {
                  clearGeoFilter(false);
                  setSelectedSellOutDate(dt);
                }}
                onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
                clearFilter={clearFilterData}
                apiPath='PriceLadder'
                showLoader={setLoader}
                defaultFilters={defaultFilters}
                selectedGeoFilters={selectedGeoFilters.geoLevel2 !== null ? selectedGeoFilters : {}}
                selectedProductFilters={selectedPepsicoFilters}
              />
            </Grid>
          </FilterAccordion>
          <FilterAccordion title='Measure Filters'>
            <MeasuresFilters
              callback={setMeasureFilters}
              clearFilters={clearMeasure}
              selectedMeasuresFilters={selectedMeasureFilters}
              filterCallBack={setClearMeasure}
              priceLadderBy={selectedProductFiltersPriceLadderBy.priceLadderBy}
              selectedPepsicoFilters={selectedPepsicoFilters}
              selectedCompetitorFilters={selectedCompetitorFilters}
            />
          </FilterAccordion>
          <Grid className='p-l-16'>
            <OrangeBtn color='secondary' className='m-r-20' onClick={clearFilter}>
              Clear Filter
            </OrangeBtn>
            <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
              Apply Filter
            </PrimaryBtn>
          </Grid>
        </>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
