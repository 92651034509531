import axios from 'axios';
import {
  BACKEND_URL,
  CLEAR_EXECUTION_FILTERS_REQUEST,
  FETCH_EXECUTION_FILTERS_FAILURE,
  FETCH_EXECUTION_FILTERS_REQUEST,
  FETCH_EXECUTION_FILTERS_SUCCESS,
  SELECTED_EXECUTION_FILTER,
  SET_LAST_TRIGGERED_KEY,
  SET_PREVIOUSLY_SELECTED_FILTER,
  SET_TIMEPERIOD,
  UPDATE_EXECUTION_FILTER
} from "./constants";
import { API } from '../../util/helper';
import { setExecutionUserDataInitialState } from './ExecutionTool/executionUserInput.action';
import { resetExecutionOverviewState } from './ExecutionTool/executionOverview.action';
import { resetPortfolioSummaryState } from './ExecutionTool/portfolioGeoLevel.action';


export const selectedFilters = (payload) => ({
  type: SELECTED_EXECUTION_FILTER,
  payload
});

export const setTimePeriod = (payload) => ({
  type: SET_TIMEPERIOD,
  payload
});

export const updateFilters = (payload) => ({
  type: UPDATE_EXECUTION_FILTER,
  payload
});

export const fetchExecutionFiltersRequest = () => ({
  type: FETCH_EXECUTION_FILTERS_REQUEST,
});

export const fetchExecutionFiltersSuccess = (data) => ({
  type: FETCH_EXECUTION_FILTERS_SUCCESS,
  payload: data,
});
export const clearExecutionFiltersSuccess = (data) => ({
  type: CLEAR_EXECUTION_FILTERS_REQUEST,
  payload: data,
});
export const fetchExecutionFiltersFailure = (error) => ({
  type: FETCH_EXECUTION_FILTERS_FAILURE,
  payload: error,
});

export const setPreviouslySelectedFilters = () => ({
  type: SET_PREVIOUSLY_SELECTED_FILTER,
});
export const setLastTriggeredKey = (data) => ({
  type: SET_LAST_TRIGGERED_KEY,
  payload:data
});

export const fetchExecutionFiltersData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(fetchExecutionFiltersRequest());
    try {
      const response = await API.post(`${BACKEND_URL}/api/filter/${apiUrl}`, data);
      console.log("fetchExecutionFiltersData", data)
      dispatch(setPreviouslySelectedFilters())
      if (data.default) {
        dispatch(clearExecutionFiltersSuccess(response));
        dispatch(setExecutionUserDataInitialState())
        dispatch(resetExecutionOverviewState())
        dispatch(resetPortfolioSummaryState())
      } else {
        dispatch(fetchExecutionFiltersSuccess(response));
        
      }
    } catch (error) {
      dispatch(fetchExecutionFiltersFailure(error.message));
    }
  };
};
