import { API } from "../../util/helper"

export const fetchBrandLadderAndTableData = async (data) => {
    try {
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/brandladder/getbranddata`,
        data
      );
    } catch (e) {
      return e;
    }
  };

  export const fetchFilterMonthData = async (data) => {
    try {
      return await API.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}filter/GetBrandLadderMaxDate`,
        data
      );
    } catch (e) {
      return e;
    }
  };