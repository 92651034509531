import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from '../../../../components/Dashboard';
import { default as data, default as mockData } from '../../../../mocks/revenueMap';
import { BlackTitle, NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { uniqueColors } from '../../../../util/helper';
import MultiLevelTable from '../../../../components/MultiLevelTable';
import { getQuadrantIntensity } from '../../service';
import { quadrantIntensityLoader } from '../../store/revenueMap.action';
import BubbleChart from '../BubbleChart/BubbleChart';
import { chartConfig } from '../QuadrantIntensityGraph/chartConfig';

const QuadrantIntensityGraph: React.FC<{ filters? }> = ({ filters }) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const [quadrantIntensityTableData, setQuadrantIntensityTableData] = React.useState<any>([]);
  const [quadrantIntensityChartData, setQuadrantIntensityChartData] = React.useState<any>([]);
  const [skeleton, setSkeleton] = useState(true);
  const [otherData, setOtherData] = useState(0);
  const [unitInfo, setUnitInfo] = useState({ xUnit: null, yUnit: null });
  const [minXVal, setMinXVal] = useState(false);
  const [minYVal, setMinYVal] = useState(false);
  const [legendColors, setLegendColors] = useState([]);

  const [level, setLevel] = useState('');
  const [hierarchy, setHierarchy] = useState({});
  const [multiLevelTableData, setMultiLavelTableData] = useState({});

  const [tableHeaders, setTableHeaders] = useState<any>([]);

  const fetchQuadrantIntensityData = async () => {
    let colors: any = [];
    const response = await getQuadrantIntensity(filters);
    switch (filters.viewType) {
      case 'manufacturer':
        setHierarchy({
          vendor: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          segment: { filterBy: ['vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          brands: { filterBy: ['segment', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          subBrands: { filterBy: ['brands', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          packSizes: { filterBy: ['subBrands', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        [
          { child: 'segment', parent: 'vendor' },
          { child: 'brands', parent: 'segment' },
          { child: 'subBrands', parent: 'brands' },
          { child: 'packSizes', parent: 'subBrands' },
        ].map((d) => {
          response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
        });
        break;
      case 'packageSize':
        setHierarchy({
          packSizes: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          vendor: { filterBy: ['packSizes'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          segment: { filterBy: ['packSizes', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          brands: { filterBy: ['packSizes', 'segment', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          subBrands: { filterBy: ['packSizes', 'brands', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        [
          { child: 'vendor', parent: 'packSizes' },
          { child: 'segment', parent: 'vendor' },
          { child: 'brands', parent: 'segment' },
          { child: 'subBrands', parent: 'brands' },
        ].map((d) => {
          response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
        });
        break;
      case 'brand':
        setHierarchy({
          brands: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          subBrands: { filterBy: ['brands'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          packSizes: { filterBy: ['subBrands'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        [
          { child: 'subBrands', parent: 'brands' },
          { child: 'packSizes', parent: 'subBrands' },
        ].map((d) => {
          response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
        });
        break;
      case 'segment':
        setHierarchy({
          segment: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          vendor: { filterBy: ['segment'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          brands: { filterBy: ['segment', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          subBrands: { filterBy: ['brands', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          packSizes: { filterBy: ['subBrands', 'vendor'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        [
          { child: 'vendor', parent: 'segment' },
          { child: 'brands', parent: 'segment' },
          { child: 'subBrands', parent: 'brands' },
          { child: 'packSizes', parent: 'subBrands' },
        ].map((d) => {
          response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
        });
        break;
      case 'brandSize':
        setHierarchy({
          brandSize: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
          subBrands: { filterBy: ['brandSize'], responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        [{ child: 'subBrands', parent: 'brandSize' }].map((d) => {
          response.data.tableData[d.child] = response.data.tableData[d.child].map((item) => ({ ...item, [d.parent]: item.parentName }));
        });
        break;
      case 'subBrandSize':
        setHierarchy({
          subBrandSize: { responseKeys: ['name', 'somValue', 'xValue', 'yValue'] },
        });
        break;
      default:
        break;
    }
    setMultiLavelTableData({ ...response.data.tableData });
    let grandTotal: any = { sumOfSomValue: 0, sumOfxValue: [], sumOfyValue: [] };
    let minXValFlag = false,
      minYValFlag = false;
    if (response?.data && response.data.values) {
      let formatValues: any = response.data.values?.map((item, index) => {
        if (!minXValFlag) {
          minXValFlag = item.xValue < 0;
        }
        if (!minYValFlag) {
          minYValFlag = item.yValue < 0;
        }
        if (
          filters.viewX === 'perGrowthSOMByYear' ||
          filters.viewX === 'perSalesAmountVsYAGO' ||
          filters.viewX === 'perSalesUnitVsYAGO' ||
          filters.viewX === 'perSalesVolumeVsYAGO'
        ) {
          item.xValue = item.xValue * 100;
        }
        if (
          filters.viewY === 'perGrowthSOMByYear' ||
          filters.viewY === 'perSalesAmountVsYAGO' ||
          filters.viewY === 'perSalesUnitVsYAGO' ||
          filters.viewY === 'perSalesVolumeVsYAGO'
        ) {
          item.yValue = item.yValue * 100;
        }
        grandTotal.sumOfSomValue += item.somValue;
        if (item.xValue !== 0) {
          grandTotal.sumOfxValue.push(item.xValue);
        }

        if (item.yValue !== 0) {
          grandTotal.sumOfyValue.push(item.yValue);
        }
        colors.push(uniqueColors[index]);
        return {
          name: item.name,
          z: item.somValue,
          x: item.xValue,
          y: item.yValue,
        };
      });
      setMinXVal(minXValFlag);
      setMinYVal(minYValFlag);
      setLegendColors(colors);
      setQuadrantIntensityChartData([...formatValues]);

      formatValues.push({
        name: 'Grand Total',
        sumOfSomValue: grandTotal.sumOfSomValue,
        sumOfxValue: grandTotal.sumOfxValue.length ? grandTotal.sumOfxValue.reduce((a, b) => a + b) / grandTotal.sumOfxValue.length : 0,
        sumOfyValue: grandTotal.sumOfyValue.length ? grandTotal.sumOfyValue.reduce((a, b) => a + b) / grandTotal.sumOfyValue.length : 0,
      });
      setQuadrantIntensityTableData([...formatValues]);
      setSkeleton(false);
      setOtherData(response.data?.others);
      setUnitInfo({ xUnit: response.data?.xUnit, yUnit: response.data?.yUnit });
    }
    dispatch(quadrantIntensityLoader(false));
  };

  useEffect(() => {
    if (filters && filters.country) {
      setQuadrantIntensityChartData([]);
      setSkeleton(true);
      fetchQuadrantIntensityData();
      switch (filters.viewType) {
        case 'manufacturer':
          setLevel('vendor');
          break;
        case 'packageSize':
          setLevel('packSizes');
          break;
        case 'brand':
          setLevel('brands');
          break;
        case 'segment':
          setLevel('segment');
          break;
        case 'brandSize':
          setLevel('brandSize');
          break;
        case 'subBrandSize':
          setLevel('subBrandSize');
          break;
        default:
          break;
      }
      let newTableHeaders: any = [
        mockData.viewType.find((x) => x.value === filters.viewType),
        mockData.viewSize.find((x) => x.value === filters.viewSize),
        [...mockData.sellIn, ...mockData.sellOut].find((x) => x.value === filters.viewX),
        [...mockData.sellIn, ...mockData.sellOut].find((x) => x.value === filters.viewY),
      ];
      setTableHeaders([...newTableHeaders]);
    }
  }, [filters]);

  const getTitle = (value) => {
    let title = '';
    mockData.sellIn.map((x) => {
      if (x.value === value) title = x.label;
    });
    mockData.sellOut.map((x) => {
      if (x.value === value) title = x.label;
    });
    return title;
  };

  const headings = [
    {
      title: 'Var Top Table',
    },
    {
      title: 'Bubble Size',
      subTitle: `SOM by ${mockData.viewSize.find((x) => x.value === filters.viewSize)?.label}`,
    },
    {
      title: 'X',
      subTitle: getTitle(filters.viewX),
    },
    {
      title: 'Y',
      subTitle: getTitle(filters.viewY),
    },
  ];

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Grid>
          {skeleton ? (
            <Dashboard title='Quadrant Intensity Graph' chartRef={null} id='revenue-map-quadrant-intensity-graph' showSkeleton={true}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton variant='rectangular' height={400} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton variant='rectangular' height={400} />
                </Grid>
              </Grid>
            </Dashboard>
          ) : quadrantIntensityTableData?.length > 1 ? (
            <Dashboard title='Quadrant Intensity Graph' showSkeleton={false} chartRef={chartRef} id='revenue-map-quadrant-intensity-graph'>
              <Grid container alignItems='center' justifyContent='center' textAlign={'center'} mt={2}>
                <Grid item md={12}>
                  <BlackTitle variant='h5'>
                    The Unit Information shown by variables is: X: {unitInfo.xUnit}, Y: {unitInfo.yUnit}
                  </BlackTitle>
                </Grid>
                <Grid item md={8}>
                  <Box sx={{ backgroundColor: '#E8E8E8' }} m={2}>
                    <BlackTitle p={2}>
                      {`Size: ${mockData.viewSize.find((x) => x.value === filters.viewSize)?.label} -> VAR TYPE: ${
                        mockData.viewType.find((x) => x.value === filters.viewType)?.label
                      }
                      -> VAR X: ${getTitle(filters.viewX)} -> VAR Y: ${getTitle(filters.viewY)} -> VAR TOP: ${filters.viewTop}`}
                    </BlackTitle>
                  </Box>
                </Grid>
                <Grid item md={8}>
                  <Box m={2}>
                    <BlackTitle p={2}>
                      OTHERS NOT IN TOP {filters.viewTop} = <span style={{ color: 'blue' }}>{otherData.toFixed(2)}%</span>
                    </BlackTitle>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  {quadrantIntensityChartData.length ? (
                    <BubbleChart
                      chartRef={chartRef}
                      filters={filters}
                      chartConfig={chartConfig}
                      data={[...quadrantIntensityChartData]}
                      draggableBubble={false}
                      draggableLines={true}
                      minXVal={minXVal}
                      minYVal={minYVal}
                      legendColors={legendColors}
                      id='revenue-map-quadrant-intensity-graph'
                    />
                  ) : null}
                </Grid>
                {/* <BlackTitle className='m-b-20' style={{ fontSize: '20px', fontWeight: 500 }}>
                  Quadrant Intensity Table
                </BlackTitle> */}
                {/* <Grid item sm={12}>
                  <CustomTable
                    headerStyle={{ fontSize: '18px' }}
                    headingBgColor='#ECECEC'
                    headingColor='#1E1E1F'
                    heading={headings}
                    value={[...quadrantIntensityTableData]}
                    showSkeleton={skeleton}
                    dataAlign='center'
                  />
                </Grid> */}
                <Grid item sm={12}>
                  <Grid container spacing={0}>
                    {multiLevelTableData && (
                      <MultiLevelTable
                        data={{ ...multiLevelTableData }}
                        tableHeader={tableHeaders}
                        showSkeleton={false}
                        level={level}
                        hierarchy={hierarchy}
                        style={{ height: 500, overflow: 'auto' }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Dashboard>
          ) : (
            <Dashboard title='Quadrant Intensity Graph' chartRef={null} showActionButton={false} id='revenue-map-quadrant-intensity-graph'>
              <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
                <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
              </Grid>
            </Dashboard>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default QuadrantIntensityGraph;
