import { styled } from "@mui/material/styles";
import DateRangePicker from'@mui/lab/DateRangePicker'; 
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
    '&>div':{
        'background-color': theme.palette.mode === 'dark'? '#EDF5FA' : theme.palette.common.white,
        'color': theme.palette.common.black
    },
    '& .MuiSvgIcon-root, .MuiTypography-root, .MuiDateRangePickerDay-day':{
        color: theme.palette.common.black
    }
}));

const CalendarIcon = styled(CalendarTodayIcon)`
    color: black;
`

export { StyledDateRangePicker, CalendarIcon};