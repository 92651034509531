import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
} from "./constants";
import { API } from "../../../util/helper";

export const resetNotification = () => {
  return {
    type: DELETE_NOTIFICATION,
  };
};

export const deleteNotificationSuccess = (payload: any) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const deleteNotificationFailure = (error) => {
  return {
    type: DELETE_NOTIFICATION_FAILURE,
    payload: error,
  };
};

const deleteNotificationData = (payload) => {
  return API.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}notifications?notificationId=${payload.id}`
  );
};

export function deleteNotification(payload) {
  return (dispatch: any) => {
    dispatch(resetNotification());
    deleteNotificationData(payload)
      .then((response) => {
        dispatch(deleteNotificationSuccess(payload));
      })
      .catch((error) => {
        dispatch(deleteNotificationFailure(error));
      });
  };
}