import React, { useEffect, useState } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { BrowserRouter } from "react-router-dom";

import rootReducer from "./store/reducers";
import Routes from "./router/RouterConfig";
import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Indicator from "./components/Loader";
import Toast from "./components/Toast";
import ErrorBoundary from "./components/ErrorBoundary";
// import { OktaAuth } from '@okta/okta-auth-js';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  // applyMiddleware(thunk),
  composeEnhancers(applyMiddleware(thunk))
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
  //   (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

const App: React.FC<{}> = () => {
  const [tokenFlag, setTokenFlag] = useState(false);

  function getCookie(name) {
    const ca = decodeURIComponent(document.cookie).split(";");
    const caLen = ca.length;
    const cookieName = `${name}=`;
    let c;
  
    for (let i = 0; i < caLen; i++) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }
  

//   useEffect(() => {
//     const token = getCookie("hubble_access_token");

//     if (!token) {
//         Swal.fire({
//             icon: "warning",
//             title: "Invalid Access Token, Redirecting to Hubble !!!",
//             showConfirmButton: false,
//             timer: 4000,
//         }).then(() => (window.location.href = "https://hubble.decisionpoint.in/"));
//     } else {
//         axios.post("https://hubble-be-new.azurewebsites.net/usersDetails", {}, {
//             headers: {
//                 "Content-Type": "application/json",
//                 "token": token
//             }
//         })
//         .then((res:any) => {
//             if (res.data.code !== 200) {
//                 Swal.fire({
//                     icon: "warning",
//                     title: res.data.err_msg + " ,Redirecting to Hubble !!!",
//                     showConfirmButton: false,
//                     timer: 4000,
//                 }).then(() => (window.location.href = "https://hubble.decisionpoint.in/"));
//             }
//         })
//         .catch((err) => {
//             Swal.fire({
//                 icon: "warning",
//                 title: "An Error Occurred ,Redirecting to Hubble !!!",
//                 showConfirmButton: false,
//                 timer: 4000,
//             }).then(() => (window.location.href = "https://hubble.decisionpoint.in/"));
//         });
//     }
// }, []);


  async function tokenValidation(hubble_access_token) {
    const baseUrl = 'https://hubble-be-new.azurewebsites.net/usersDetails';

    const headers = new Headers({
        "Content-Type": "application/json",
        "token": hubble_access_token,
    });

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({}), // You can pass any body if required
    };

    try {
        const response = await fetch(baseUrl, options);
        const data = await response.json();
        return data;
    } catch (error) {
        // Handle errors here
        console.error('Error:', error);
        throw error;
    }
}

  // useEffect(() => {
  //   let token: any = localStorage.getItem('okta-token-storage');
  //   console.log("token",token)
  //   if (!token && process.env.REACT_APP_DEFAULT_OKTA_LOGIN === 'Yes' && process.env.REACT_APP_SSO_ENABLE === 'Yes') {
  //     const oktaAuth: OktaAuth = new OktaAuth({ issuer: process.env.REACT_APP_AUTH_URL, clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID });

  //     // Open the Okta widget
  //     oktaAuth.token.getWithRedirect({
  //       scopes: ['openid', 'email', 'profile', 'offline_access'],
  //       responseType: 'id_token',
  //       redirectUri: process.env.REACT_APP_REDIRECT_URL,
  //     }).then((token) => {
  //       console.log("then",token)
  //       // oktaAuth.tokenManager.setTokens(token.tokens);
  //       setTokenFlag(true);
  //     }).catch(function (err) {
  //               console.error(err);
  //             });;

  //   } else {
  //     setTokenFlag(true);
  //   }
  // }, []);
  // useEffect(() => {
  //   setTokenFlag(true)
  //   // let token: any = localStorage.getItem('okta-token-storage');
  //   // if (!token && process.env.REACT_APP_DEFAULT_OKTA_LOGIN === 'Yes' && process.env.REACT_APP_SSO_ENABLE === 'Yes') {
  //   //   const oktaAuth: OktaAuth = new OktaAuth({ issuer: process.env.REACT_APP_AUTH_URL, clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID });
  //   //   oktaAuth
  //   //     .signInWithCredentials({
  //   //       username: process.env.REACT_APP_DEFAULT_USERNAME,
  //   //       password: process.env.REACT_APP_DEFAULT_PASSWORD,
  //   //     })
  //   //     .then((transaction) => {
  //   //       if (transaction.status === 'SUCCESS') {
  //   //         const { sessionToken } = transaction;
  //   //         oktaAuth.token
  //   //           .getWithoutPrompt({
  //   //             sessionToken,
  //   //             scopes: ['openid', 'email', 'profile', 'offline_access'],
  //   //             responseType: 'id_token',
  //   //             redirectUri: process.env.REACT_APP_REDIRECT_URL,
  //   //           })
  //   //           .then((token) => {
  //   //             oktaAuth.tokenManager.setTokens(token.tokens);
  //   //             setTokenFlag(true);
  //   //           });
  //   //       } else {
  //   //         throw 'We cannot handle the ' + transaction.status + ' status';
  //   //       }
  //   //     })
  //   //     .catch(function (err) {
  //   //       console.error(err);
  //   //     });
  //   // } else {
  //   //   setTokenFlag(true);
  //   // }
  // }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <Toast />
        <Indicator />
        <ErrorBoundary>
          <Routes></Routes>
        </ErrorBoundary>
        {/* <ErrorBoundary>{! <Routes></Routes> : null}</ErrorBoundary> */}
      </Provider>
    </BrowserRouter>
  );
};

export default App;
