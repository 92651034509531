import { GridColDef } from "@mui/x-data-grid";
import { formatNumber } from "../../../../util/helper";

export const columnsForPortfolio: GridColDef[] = [
  // {
  //   "field": "bussiness_unit",
  //   "headerName": "Bussiness unit",
  //   "minWidth": 250,
  //   "align": "center",
  //   "flex": 1,
  //   "headerAlign": "center",
  //   "type": "string"
  // },
  // {
  //   "field": "category",
  //   "headerName": "Category",
  //   "minWidth": 250,
  //   "align": "center",
  //   "flex": 1,
  //   "headerAlign": "center",
  //   "type": "string",
  // },
  {
    field: "segments",
    headerName: "Segment",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "brand",
    headerName: "Brand",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "subBrand",
    headerName: "Sub Brand",
    minWidth: 230,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "flavor",
    headerName: "Flavor",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "packSize",
    headerName: "Pack Size",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "sku",
    headerName: "SKU",
    minWidth: 450,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "checkoutSku",
    headerName: "Checkout SKU",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "revenuMix",
    headerName: "Revenue Mix",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "parseFloat",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}%` : value,
  },
  {
    field: "netRevenueSOP",
    headerName: "SOP Revenue",
    minWidth: 160,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "nonPBT_SOP",
    headerName: "SOP NOPBT",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "avgStorePenetration",
    headerName: "Penetration(Distribution)",
    minWidth: 230,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}%` : value,
  },
  {
    field: "executionScore",
    headerName: "Execution Score",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "mustHaveDistributionScore",
    headerName: "Must have distribution score",
    minWidth: 270,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "goodToHaveDistributionScore",
    headerName: "Good to have distribution score",
    minWidth: 280,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "assortmentOverall",
    headerName: "Assortment Score",
    minWidth: 180,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  //
  {
    field: "volumeMixResult",
    headerName: "Volume score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "revenueMixResult",
    headerName: "Revenue score",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "profitMixResult",
    headerName: "NOPBT score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  //
  {
    field: "mixScore",
    headerName: "Mix score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  
  // {
  //   "field": "promotionScore",
  //   "headerName": "Promotion Score",
  //   "minWidth": 150,
  //   "align": "center",
  //   "flex": 1,
  //   "headerAlign": "center",
  //   "type": "number",
  //   valueFormatter: ({ value }) => !isNaN(value) ? `${parseFloat((parseFloat(value)?.toFixed(2)))}%` : value
  // },
  {
    field: "netPriceRealisationScore",
    headerName: "Net price realisation score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceChangeVsRestOfTheMarketScore",
    headerName: "Price change Vs Rest of the market score",
    minWidth: 350,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceChangeVsInflationScore",
    headerName: "Price change Vs Inflation score",
    minWidth: 280,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceScore",
    headerName: "Price Score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "exhibitionScore",
    headerName: "Exhibition score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "shelfSpaceScore",
    headerName: "Shelf space score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "shelfAndMerchandizingScore",
    headerName: "Shelf & Merchandise score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
];

export const geoLevelSummaryColumn: GridColDef[] = [
  {
    field: "chnl",
    headerName: "Channel",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "place",
    headerName: "Region",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "segment",
    headerName: "Store Segment",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "string",
  },
  {
    field: "revenuMix",
    headerName: "Revenue Mix",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "parseFloat",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}%` : value,
  },
  {
    field: "netRevenueSOP",
    headerName: "SOP Revenue",
    minWidth: 160,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "nonPBT_SOP",
    headerName: "SOP NOPBT",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "avgStorePenetration",
    headerName: "Penetration(Distribution)",
    minWidth: 230,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}%` : value,
  },
  {
    field: "executionScore",
    headerName: "Execution Score",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "mustHaveDistributionScore",
    headerName: "Must have distribution score",
    minWidth: 270,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "goodToHaveDistributionScore",
    headerName: "Good to have distribution score",
    minWidth: 280,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "assortmentOverAll",
    headerName: "Assortment Score",
    minWidth: 180,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  //
  {
    field: "volumeMixResult",
    headerName: "Volume score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "revenueMixResult",
    headerName: "Revenue score",
    minWidth: 170,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "profitMixResult",
    headerName: "NOPBT score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  //
  {
    field: "mixScore",
    headerName: "Mix score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  // {
  //   "field": "promotionScore",
  //   "headerName": "Promotion Score",
  //   "minWidth": 150,
  //   "align": "center",
  //   "flex": 1,
  //   "headerAlign": "center",
  //   "type": "number",
  //   valueFormatter: ({ value }) => !isNaN(value) ? `${parseFloat((parseFloat(value)?.toFixed(2)))}%` : value
  // },
  {
    field: "netPriceRealisationScore",
    headerName: "Net price realisation score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceChangeVsRestOfTheMarketScore",
    headerName: "Price change Vs Rest of the market score",
    minWidth: 350,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceChangeVsInflationScore",
    headerName: "Price change Vs Inflation score",
    minWidth: 280,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "priceScore",
    headerName: "Price Score",
    minWidth: 150,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "exhibitionScore",
    headerName: "Exhibition score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "shelfSpaceScore",
    headerName: "Shelf space score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
  {
    field: "shelfAndMerchandizingScore",
    headerName: "Shelf & Merchandise score",
    minWidth: 250,
    align: "center",
    flex: 1,
    headerAlign: "center",
    type: "number",
    valueFormatter: ({ value }) =>
      !isNaN(value) ? `${formatNumber(value, 1)}` : value,
  },
];
