import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Title } from '../../styles/Common.Styled';
import TwentySecMarketCheck from './components/20SecMarketCheck/20SecMarketCheck';
import Dropdown from '../../components/Dropdown';
import { pageList, pageNames, standardSortOption } from '../../mocks/common';
import data from '../../mocks/20SecMarketCheck';
import _ from 'lodash';
import TabView from './components/Tabs/Tabs';
import PageContainer from '../../components/PageContainer/PageContainer';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const Home: React.FC<{}> = () => {
  const [defaultSort] = useState<string>(data.sortingDropdown[0].value);
  const [selectedSort, setSelectedSort] = useState(null);
  const [sortOptions, setSortOptions] = React.useState(standardSortOption);
  const [selectedOrder, setSelectedOrder] = React.useState(standardSortOption[2].value);

  const onSelectSorting = (sort: string) => {
    setSelectedSort(sort);
  };

  useEffect(() => {
    onSelectSorting(data.sortingDropdown[0].value);
  }, []);

  const onTabChange = (index) => {
    if (index === 0) {
      setSortOptions(standardSortOption);
      setSelectedOrder(standardSortOption[2].value);
    } else if (index === 1) {
      const options = standardSortOption.filter((s, i) => i !== 1 && i !== 2);
      setSortOptions(options);
      setSelectedOrder(standardSortOption[0].value);
    } else {
      const options = standardSortOption.filter((s, i) => i !== 2);
      setSortOptions(options);
      setSelectedOrder(standardSortOption[0].value);
    }
  };

  return (
    <PageContainer page={pageNames.home}>
      <Box>
        <Grid container spacing={3} justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Title data-testid='main-title'>20 Second Market Check</Title>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Dropdown
              data={data.sortingDropdown}
              onChange={(val) => onSelectSorting(val[0])}
              defaultOption={defaultSort}
              sort={{ enable: false }}
              search={{ enable: false }}
            />
          </Grid>
        </Grid>
        <TwentySecMarketCheck onSortChange={selectedSort} />
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[18].value} />
        </Grid>
        <Grid container className='m-t-30' justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={3}>
            <Title>Your Dashboards</Title>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Dropdown
              onChange={(data) => setSelectedOrder(data[0])}
              defaultOption={selectedOrder || ['empty']}
              keyText='homepage-order-filter'
              data={sortOptions}
              placeholder='Sort By'
              search={{ enable: false }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabView callback={onTabChange} sortOrder={selectedOrder} />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Home;
