import {
  GET_YOUR_SAVED_DASH,
  GET_YOUR_SAVED_DASH_SUCCESS,
  GET_YOUR_SAVED_DASH_FAILURE,
} from "../../actions/YourDashboard/constant";

let savedDashInitialState = {
  data: null,
  loading: true,
  error: null,
};

const SavedDash = (
  state = savedDashInitialState,
  action
) => {
  switch (action.type) {
    case GET_YOUR_SAVED_DASH:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case GET_YOUR_SAVED_DASH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GET_YOUR_SAVED_DASH_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SavedDash;
