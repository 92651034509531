import styled from "@emotion/styled";
import { StyledComponent } from "styled-components";
import { TableCell, TableRow, tableCellClasses, Button } from "@mui/material";

export const StyledPorfolioTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F5FE",
  },
}));

export const StyledPortfolioTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F5FE",
  },
  alignContent: "center",
}));

export const center = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
export const alignEnd = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

export const styleForDataGrid = {
  ".MuiDataGrid-columnHeaders": {
    background: "#f1f5fe !important",
    borderBottom: "3px solid #D0D2D3 !important",
  },
  ".MuiDataGrid-cell": {
    borderRight: "3px solid #D0D2D3",
    borderBottom: "3px solid #D0D2D3 !important",
  },
  ".MuiDataGrid-columnHeader:not(:last-child)": {
    borderRight: "3px solid #D0D2D3",
  },
  ".MuiDataGrid-cellContent": {
    textWrap: "balance",
    textAlign: "center",
  },
  ".MuiDataGrid-overlay": {},
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
  },
  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
};
interface BlueBtnProps {
  theme?: any;
}
export const BlueBtn = styled(Button)<BlueBtnProps>`
  background: #08509a;
  color: white;
  height: 32px;
  width: 78px;
  padding: 8px 10px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 10px;
  font-family: Montserrat, sans-serif !important;
  font-weight: 700;
  line-height: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: #08509a;
    color: white;
  }
`;
