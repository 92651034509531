import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import Dashboard from "../../../../../../components/Dashboard";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import _ from "lodash";
import { priceElasticityConfig } from "./priceElasticityConfig";
import { makeStyles } from "@material-ui/styles";
import { BlackTitle, NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import HighchartContainer from "../../../../../../components/HighchartContainer";

const useStyles = makeStyles(() => ({
  center: {
    fontWeight: 550,
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}));

const PriceElasticity: React.FC<{
  data;
  skeleton;
}> = ({ data, skeleton }) => {
  const chartRef = useRef(null);
  const classes = useStyles();
  const [priceElasticityIsImputed, setPriceElasticityIsImputed] = useState("");
  const [gramElasticityIsImputed, setGramElasticityIsImputed] = useState("");
  const [model, setModel] = useState("");

  useEffect(() => {
    try {
      if (
        data &&
        _.isArray(data?.priceElasticityColumnData?.barData) &&
        chartRef &&
        chartRef.current &&
        chartRef.current.chart
      ) {
        const chart = chartRef.current.chart;
        const categories = data.priceElasticityColumnData.barData.map(
          (x) => x.name
        );
        chart.xAxis[0].setCategories(categories);
        chart.series[0].setData(data.priceElasticityColumnData.barData);
        chart.series[1].setData(data.gramElasticityColumnData.barData);

        const getFormatedTitle = () => {
          let str1 = `For last model (${
            data.priceElasticityColumnData.barData[
              data.priceElasticityColumnData.barData.length - 2
            ].name
          }) :`;
          setModel(str1);
          let str2 = "This visual is showing ";
          data.priceElasticityColumnData.isImputed
            ? (str2 += "imputed")
            : (str2 += "actual");
          str2 += " price elasticity";
          setPriceElasticityIsImputed(str2);
          let str3 = "This visual is showing ";
          data.gramElasticityColumnData.isImputed
            ? (str3 += "imputed")
            : (str3 += "actual");
          str3 += " gram elasticity";
          setGramElasticityIsImputed(str3);
        };

        chart.update({
          title: {
            style: { fontSize: 16 },
            align: "left",
            verticalAlign: "bottom",
            text: getFormatedTitle(),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <Grid className="m-b-20">
      <Dashboard
        showSkeleton={skeleton}
        id="historic-price-Elasticity"
        title="Price and Grams Elasticities"
        chartRef={chartRef}
        showActionButton={
          _.isArray(data?.priceElasticityColumnData?.barData) &&
          data?.priceElasticityColumnData?.barData.length > 0
        }
      >
        {!skeleton ? (
          <>
            {_.isArray(data?.priceElasticityColumnData?.barData) &&
            data?.priceElasticityColumnData?.barData.length ? (
              <>
                <BlackTitle className={classes.center}>
                  Price and Grams Elasticities by Quarterly Model Updated
                </BlackTitle>
                <BlackTitle className={classes.center}>{data.title}</BlackTitle>
                <HighchartContainer
                  chartRef={chartRef}
                  id={"historic-price-Elasticity"}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={priceElasticityConfig}
                    ref={chartRef}
                  />
                </HighchartContainer>
                <Grid style={{ fontSize: 14, fontWeight: 600 }}>
                  <Box>
                    <p>{model}</p>
                    <p>{priceElasticityIsImputed}</p>
                    <p> {gramElasticityIsImputed}</p>
                  </Box>
                </Grid>
              </>
            ) : (
              <Box
                style={{ height: 400 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )}
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Skeleton variant="rectangular" height={500} className="m-b-10" />
            </Grid>
          </Grid>
        )}
      </Dashboard>
    </Grid>
  );
};

export default PriceElasticity;
