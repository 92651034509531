import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import FilterAccordion from "../../../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import Indicator from "../../../../components/Loader";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import {
  dateRange,
  initialState,
} from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import ProductFiltersV3 from "../../../../components/ProductFilters-v3";
import filterMock, { periodView } from "../../../../mocks/priceRangePianoMock";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { dataFormat, enableCompetitorFilter } from "../../../../util/helper";
import MeasuresFilters, {
  generateIndexTextControl,
} from "../MeasuresFilters/MeasuresFilters";
import { pricePianoDataLoader } from "../../store/PriceRangePiano.action";
import mockData from "../../../../mocks/priceRangePianoMock";
import { CustomDropDownTitle } from "../../../../components/ProductFilters-v3/ProductFilters-v3";
import Dropdown from "../../../../components/Dropdown";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import CustomDateRangePicker from "../../../../components/UI-components/DateRangePicker";
import { fetchPeriodViewDates } from "../../../../util/services";
import { DateTime } from 'luxon';
import { toast } from "../../../../store/actions/toast.action";
import { defaultCalendarDates } from "../../../../util/config";

const TopFilters: React.FC<{ callback; defaultFilters?}> = ({
  callback,
  defaultFilters,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedPepsicoFilters, setSelectedPepsicoFilters] =
    React.useState<any>({});
  const [selectedCompetitorFilters, setSelectedCompetitorFilters] =
    React.useState<any>({});

  const [selectedMeasureFilters, setMeasureFilters] = useState({
    viewShareby: filterMock.measuresFilter.viewShareby.defaultOption.value,
    indexes: generateIndexTextControl(mockData.priceRanges[2].value),
  });

  const [selectedDate, setDate] = React.useState(defaultFilters?.date ? defaultFilters?.date.split("-") : [defaultCalendarDates.startDate, defaultCalendarDates.endDate]);

  useEffect(() => {
    if (Object.keys(defaultFilters).length > 0) {
      setSelectedGeoFilters(defaultFilters ? defaultFilters : initialState);
      setMeasureFilters({
        viewShareby:
          (defaultFilters && defaultFilters?.viewShareby) ||
          filterMock.measuresFilter.viewShareby.defaultOption.value,
        indexes:
          defaultFilters &&
            _.isArray(defaultFilters?.indexes) &&
            defaultFilters?.indexes.length > 0
            ? [
              { start: defaultFilters.indexes[0], end: null },
              ...defaultFilters.indexes.map((x, i) => ({
                start: i < defaultFilters.indexes.length - 1 ? x : null,
                end: x,
              })),
            ]
            : generateIndexTextControl(mockData.priceRanges[2].value),
      });
    }
  }, [defaultFilters]);

  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setMeasureFilters({
      viewShareby:
        filterMock.measuresFilter.viewShareby.defaultOption.value || undefined,
      indexes: generateIndexTextControl(mockData.priceRanges[2].value),
    });
    setSelectedPepsicoFilters({});
    setSelectedCompetitorFilters({});
  };

  const setSelectedGeoFiltersLogic = (e) => {
    setSelectedGeoFilters({ ...e });
    // if (selectedGeoFilters.periodView) {
    //   setSelectedGeoFilters({ ...e, periodView: "null" });
    // }
    if (clearFilterData) {
      clearGeoFilter(false);
    }
  };

  const onApplyFilter = async () => {
    dispatch(pricePianoDataLoader(true));
    callback({
      filters: {
        ...selectedGeoFilters,
        ...selectedMeasureFilters,
        indexes: selectedMeasureFilters.indexes
          .map((x) => x.start)
          .filter((y) => y),
        pepsi: { ...selectedPepsicoFilters, vendor: ["PEPSICO"] },
        competitor: { ...selectedCompetitorFilters },
        startDate: selectedDate[0],
        endDate: selectedDate[1],
      },
    });
  };

  const disableApplyFilter = () => {
    const flag =
      !selectedGeoFilters.country ||
      !selectedGeoFilters.geoLevel2 ||
      !selectedPepsicoFilters.category ||
      (enableCompetitorFilter(selectedGeoFilters) &&
        !selectedCompetitorFilters.category);
    return flag;
  };

  useEffect(() => {
    onChangePeriodView()
  }, [selectedGeoFilters, selectedPepsicoFilters.category])


  const onChangePeriodView = () => {
    if (selectedGeoFilters.periodView[0] !== 'na' && selectedGeoFilters.country && selectedGeoFilters.geoLevel && selectedGeoFilters.geoLevel2 && selectedPepsicoFilters.category) {
      setLoader(true);
      const payload = {
        period: selectedGeoFilters.periodView[0],
        country: selectedGeoFilters.country,
        geoLevel: selectedGeoFilters.geoLevel,
        geoLevel2: selectedGeoFilters.geoLevel2,
        category: selectedPepsicoFilters.category
      };
      fetchPeriodViewDates('PriceRangePiano', payload)
        .then((res) => {
          const response = res.data;
          if (res.status === 200 && response.startDate && response.endDate) {
            const startDate = DateTime.fromString(response.startDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            const endDate = DateTime.fromString(response.endDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            setDate([startDate, endDate]);
          } else {
            dispatch(toast('Dates Unavailable!', true, 2000, 'error'));
          }
          setLoader(false);
        })
        .catch((e) => {
          dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
          setLoader(false);
        });
    }
  };

  const PricePianoDataLoader = useSelector(
    (state: any) => state.PricePianoDataLoader
  );

  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={loader || PricePianoDataLoader.pricePianoDataLoader}
      />
      <CardContent>
        <>
          <FilterAccordion title="Geo-Filters">
            <GeoFiltersV2
              key="price-range-top-filter"
              data={{ ...geoFilterV2Config }}
              onChangeDate={(dt) => {
                clearGeoFilter(false);
              }}
              onSelectFilters={(e) => setSelectedGeoFiltersLogic(e)}
              clearFilter={clearFilterData}
              apiPath="PriceRangePiano"
              showLoader={setLoader}
              defaultFilters={defaultFilters}
              showDatePicker={false}
            />
          </FilterAccordion>

          <FilterAccordion title="Products Filters">
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                container
                spacing={2}
                columns={Object.keys(mockData.productFilter).length + 1}
              >
                <Grid item xs={1}>
                  <>
                    <CustomDropDownTitle
                      title={
                        'Vendor <span style="color: #858c94;">(Pepsico)</span>'
                      }
                    />
                    <Dropdown
                      disabled={true}
                      data={[{ lable: "PEPSICO", value: "PEPSICO" }]}
                      defaultOption={["PEPSICO"]}
                      placeholder="Select"
                      search={{ enable: false }}
                    />
                  </>
                </Grid>
                <Grid item xs={Object.keys(mockData.productFilter).length}>
                  <ProductFiltersV3
                    onChange={setSelectedPepsicoFilters}
                    data={filterMock.productFilter}
                    onChangeLoader={setLoader}
                    selectedGeoFilters={
                      selectedGeoFilters.geoLevel2 !== null
                        ? selectedGeoFilters
                        : {}
                    }
                    clearFilter={clearFilterData}
                    defaultFilters={
                      defaultFilters && defaultFilters.pepsi
                        ? {
                          category: defaultFilters.pepsi.category,
                          segment: defaultFilters.pepsi.segment,
                          brand: defaultFilters.pepsi.brand,
                          subBrand: defaultFilters.pepsi.subBrand,
                          packSize: defaultFilters.pepsi.packSize,
                        }
                        : {}
                    }
                    apiURL="filter/multipleapiproducts?type=pepsi"
                    params={{
                      vendor: ["PEPSICO"],
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} sm={12}>
                <ProductFiltersV3
                  onChange={setSelectedCompetitorFilters}
                  data={filterMock.competitorFilter}
                  onChangeLoader={setLoader}
                  selectedGeoFilters={
                    enableCompetitorFilter(selectedGeoFilters) &&
                      selectedGeoFilters.geoLevel2 !== null
                      ? selectedGeoFilters
                      : {}
                  }
                  clearFilter={clearFilterData}
                  defaultFilters={defaultFilters.competitor}
                  apiURL="filter/multipleapiproducts?type=competitor"
                />
              </Grid>
            </Grid>
          </FilterAccordion>
          <FilterAccordion title="Date Filters">
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} md={2}>
                <DropdownTitle>{periodView.title}</DropdownTitle>
                <Dropdown
                  data={periodView.options}
                  onChange={(periodView) => { setSelectedGeoFilters({ ...selectedGeoFilters, periodView }) }}
                  defaultOption={selectedGeoFilters.periodView || ['empty']}
                  placeholder={periodView.placeholder}
                  sort={{ enable: false }}
                  search={{ enable: false }}
                />
              </Grid>
              <Grid item xs={12} md={2} sm={2} lg={2}>
                <DropdownTitle>Date Range</DropdownTitle>
                <CustomDateRangePicker
                  defaultDate={selectedDate}
                  callback={setDate}
                  disabled={selectedGeoFilters.periodView[0] === 'na' ? false : true}
                />
              </Grid>
            </Grid>
          </FilterAccordion>
          <FilterAccordion title="Measure Filters">
            <MeasuresFilters
              callback={setMeasureFilters}
              selectedMeasuresFilters={selectedMeasureFilters}
            />
          </FilterAccordion>
          <Grid className="p-l-16">
            <OrangeBtn
              color="secondary"
              className="m-r-20"
              onClick={clearFilter}
            >
              Clear Filter
            </OrangeBtn>
            <PrimaryBtn
              disabled={disableApplyFilter()}
              color="primary"
              onClick={onApplyFilter}
            >
              Apply Filter
            </PrimaryBtn>
          </Grid>
        </>
      </CardContent>
    </Card>
  );
};

export default TopFilters;
