import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import TextField from "@mui/material/TextField";
import Dropdown from "../../../../components/Dropdown";
import filterMock from "../../../../mocks/priceLadderMock";
import { inputRegex } from "../../../../util/helper";

const MeasuresFilters: React.FC<{
  clearFilters;
  selectedMeasuresFilters;
  callback;
  filterCallBack;
  priceLadderBy;
  selectedPepsicoFilters;
  selectedCompetitorFilters;
}> = ({ clearFilters, selectedMeasuresFilters, callback, filterCallBack, priceLadderBy, selectedPepsicoFilters, selectedCompetitorFilters }) => { 
  const [markup, setMarkup] = useState({})   
  selectedMeasuresFilters.somValueBy = (selectedPepsicoFilters?.segment?.length > 1 || selectedCompetitorFilters?.segment?.length >1) ? 'category': selectedMeasuresFilters.somValueBy
  
  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        key={`api-price-piano-measures-variable-config`}
        style={{ alignItems: "baseline" }}
      >
        <Grid container alignItems="center" spacing={3} xs={12} sm={12}> 
            <Grid item xs={1} >
              <Typography variant="subtitle2">{filterMock.measuresFilter.somValueType.title}</Typography>
            </Grid> 
            <Grid container alignItems="center" item xs={8} sm={8}>
            <Typography>
              <RadioButtonGroup
                key={`measures-filters-somType`}
                color="#000"
                showButton={
                  filterMock.measuresFilter.somValueType.showRadioButton
                }
                data={filterMock.measuresFilter.somValueType.options}
                defaultOption={selectedMeasuresFilters.somValueType}
                select={(data) =>
                  callback({ ...selectedMeasuresFilters, somValueType: data })
                }
                direction={"row"}
              /> 
              </Typography> 
              <Typography style={{marginLeft:'15px', marginRight:'30px'}}>|</Typography>
              <Typography>
              <RadioButtonGroup  
                key={`measures-filters-somBy`}
                color="#000"
                showButton={
                  filterMock.measuresFilter.somValueBy.showRadioButton
                }
                data={ (selectedPepsicoFilters?.segment?.length > 1 || selectedCompetitorFilters?.segment?.length >1)?
                  filterMock.measuresFilter.somValueByCatOnly.options :
                  filterMock.measuresFilter.somValueBy.options
                }
                defaultOption={ selectedMeasuresFilters.somValueBy }
                select={(data) =>
                  callback({ ...selectedMeasuresFilters, somValueBy: data })
                }
                direction={"row"}
              />
              </Typography>
            </Grid> 
          
        </Grid>

        <Grid container alignItems="top" spacing={1} xs={12} sm={12}> 
            <Grid item xs={1} >
              <Typography style={{paddingTop:'10px'}} variant="subtitle2">{filterMock.measuresFilter.apiFilter.title}</Typography>
            </Grid> 
            <Grid item xs={4} sm={4}>
              <RadioButtonGroup
                key={`measures-filters-api`}
                color="#000"
                disabled={(priceLadderBy !== 'maincompetitor') }
                showButton={ filterMock.measuresFilter.apiFilter.showRadioButton }
                data={filterMock.measuresFilter.apiFilter.options}
                defaultOption={selectedMeasuresFilters.apiFilter}
                select={(data) =>
                  callback({ ...selectedMeasuresFilters, apiFilter: data })
                }
                direction={"row"}
              />  
            </Grid> 
        </Grid>

        <Grid container alignItems="top" spacing={3} xs={12} sm={12}> 
            <Grid item xs={1}>
              <Typography  style={{paddingTop:'10px'}} variant="subtitle2">{filterMock.measuresFilter.measuresFilterSOP.title}</Typography>
            </Grid> 
            <Grid item xs={11} sm={11}>
              <RadioButtonGroup
                key={`measures-filters-sop`}
                color="#000"
                showButton={ filterMock.measuresFilter.measuresFilterSOP.showRadioButton }
                data={filterMock.measuresFilter.measuresFilterSOP.options}
                defaultOption={selectedMeasuresFilters.measuresFilterSOP}
                select={(data) =>
                  callback({ ...selectedMeasuresFilters, measuresFilterSOP: data })
                }
                direction={"row"}
              />  
            </Grid> 
        </Grid>

        <Grid container mt={1} mb={1} spacing={2} xs={12} sm={12}>
        <Grid item xs={2} sm={2}>
          <Typography variant="subtitle2">Markup(%)</Typography>
          <Grid display="flex" alignItems="center">
          <TextField InputProps={{ autoComplete: 'off' }}
            id='markup'
            type='number'
            inputProps={{
              autoComplete: 'off' 
            }}
            placeholder="0%"
            onChange={(e) => {
              callback({ ...selectedMeasuresFilters, markup: e.target.value })
             }}
            value={selectedMeasuresFilters.markup}
            InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: 'off' }}
            onKeyDown={inputRegex}
          /> 
          </Grid>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Typography variant="subtitle2">Taxes(%)</Typography>
          <Grid display="flex" alignItems="center">
          <TextField InputProps={{ autoComplete: 'off' }}
            id='taxes'
            type="number"
            inputProps={{
              autoComplete: 'off' 
            }}
            placeholder="0%" 
            onChange={(e) => {
              callback({ ...selectedMeasuresFilters, taxes: e.target.value })
             }}
            value={selectedMeasuresFilters.taxes}
            InputProps={{ inputProps: { min: "0", step: "1" }, autoComplete: 'off' }}
            onKeyDown={inputRegex}
          /> 
          </Grid>
        </Grid>
        </Grid>  
      </Grid>
    </>
  );
};

export default MeasuresFilters;
