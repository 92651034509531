import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Popover,
  TextareaAutosize,
} from "@mui/material";

import { Stage, Layer, Text, Image } from "react-konva";

import {
  convertSVGToBase64,
  loadImage,
  loadImageFromBase64,
  plotElementsOnChart,
  removeElementsOnChart,
} from "../../util/helper";
import DraggableImage from "./components/Image";
import MMPW from "../MMPW/MMPW";
import ColorPicker from "../MMPW/components/ColorPicker";
import { ColorLens, MoveIcon, DeleteIcon } from "../../assets/icons/dashboard";
import { Edit } from "@mui/icons-material";
import { icons, shapes } from "../MMPW/config";
import _ from "lodash";

const Canvas: React.FC<{
  chartRef?;
  close;
  data;
  id;
  mode?;
}> = ({ chartRef, close, data, id, mode }) => {
  const [items, setItems] = useState<any>({});
  const [MMPWOpen, setMMPWOpen] = useState(false);
  const [selectedMenu, setMMPWMenu] = useState("");
  const [openKonva, setOpenKonva] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [chart, setChart] = React.useState<any>(
    chartRef && chartRef.current && chartRef.current.chart
  );
  const chartType = _.isArray(chart.series) && chart.series[0].type;
  const [currentChartConfig, setCurrentChartConfig] = useState<any>({});

  const getSeries = () => {
    const series = [];
    chart.series.map((item) => {
      if (
        item.type === "spline" ||
        item.type === "scatter" ||
        item.type === "area" ||
        item.type === "line" ||
        item.type === "errorbar" ||
        item.type === "bar" ||
        item.type === "bubble" ||
        (item.type === "column" && !item?.userOptions?.colorByPoint)
      ) {
        series.push({
          color: item.color,
          lineWidth: item.options.lineWidth,
          borderColor: item.options.borderColor,
          borderWidth: item.borderWidth,
        });
      } else {
        item.data.map((s) => {
          series.push({
            color: s.color,
            lineWidth: s.options.lineWidth,
            borderColor: s.options.borderColor,
            borderWidth: s.borderWidth,
          });
        });
      }
    });
    return series;
  };

  useEffect(() => {
    if (mode === "add") {
      if (_.isObject(data)) {
        mapData({ ...data }, "Image");
        mapData({ ...data }, "Icon");
        mapData({ ...data }, "Shape");
        mapData({ ...data }, "Text");
        mapData({ ...data }, "Comment");
        mapData({ ...data }, "CustomIcon");
      }
    }
    if (mode === "edit" || mode === "global-edit") {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        removeElementsOnChart(id);
      }

      setOpenKonva(false);
      let editData;
      let storedData = JSON.parse(localStorage.getItem("mmpw"));
      if (storedData && storedData[id]) {
        editData = { ...storedData[id] };
      }
      if (_.isObject(data)) {
        editData = data;
      }

      if (_.isObject(editData)) {
        mapData({ ...editData }, "Text");
        loadImageData({ ...editData }, "Image");
        loadImageData({ ...editData }, "Icon");
        loadImageData({ ...editData }, "Shape");
        loadImageData({ ...editData }, "Comment");
        loadImageData({ ...editData }, "CustomIcon");
      }
    }
    if (data && data.chartConfig) {
      if (chart) {
        setCurrentChartConfig({
          ...currentChartConfig,
          backgroundColor: chart.backgroundColor
            ? chart.backgroundColor
            : "#fff",
        });
        chart.update({
          chart: {
            backgroundColor:
              data.chartConfig && data.chartConfig.chartBackground
                ? data.chartConfig.chartBackground
                : "#fff",
          },
        });
      }
      setItems({
        ...items,
        chartConfig: data.chartConfig,
      });
    }
    if (data && data.series) {
      if (chart) {
        let xPlotLines = [];
        chart.xAxis.map((item, i) => {
          const plotLines = _.cloneDeep(item.plotLinesAndBands);
          plotLines.map((x) => {
            x.xAxisIndex = i;
          });
          xPlotLines = [...xPlotLines, ...plotLines];
        });

        let yPlotLines = [];
        chart.yAxis.map((item, i) => {
          const plotLines = _.cloneDeep(item.plotLinesAndBands);
          plotLines.map((x) => {
            x.yAxisIndex = i;
          });
          yPlotLines = [...yPlotLines, ...plotLines];
        });

        setCurrentChartConfig({
          ...currentChartConfig,
          series: getSeries(),
          xAxisPlotLines: xPlotLines,
          yAxisPlotLines: yPlotLines,
        });
        const seriesColors = data.series.filter((x) => x.type === "series");
        let index = 0;
        chart.series.map((series, i) => {
          if (
            (_.isArray(seriesColors) &&
              seriesColors.length &&
              series.type === "spline") ||
            series.type === "scatter" ||
            series.type === "area" ||
            series.type === "line" ||
            // series.type === "errorbar" ||
            series.type === "bar" ||
            series.type === "bubble" ||
            (series.type === "column" && !series?.userOptions?.colorByPoint)
          ) {
            const item = seriesColors[index];
            chart.series[index].update({
              color: item && item.color ? item.color : series.color,
              // visible: true,
              // showInLegend: false,
            });
            index++;
          } else if (_.isArray(seriesColors) && seriesColors.length) {
            series.data.map((x, j) => {
              const item = seriesColors[index];
              if (item && item.color) {
                chart.series[i].data[j].update({
                  color: item.color,
                });
              }
              index++;
            });
          } else {
            index++;
          }
        });
        const lineWidths = data.series.filter((x) => x.lineWidth !== undefined);
        const borderColors = data.series.filter((x) => x.borderColor);
        const borderWidths = data.series.filter(
          (x) => x.borderWidth !== undefined
        );
        let lineWidthIndex = 0,
          borderColorIndex = 0,
          borderWidthIndex = 0;
        chart.series.map((series, i) => {
          if (
            series.type === "spline" ||
            series.type === "scatter" ||
            series.type === "area" ||
            series.type === "line" ||
            series.type === "errorbar" ||
            series.type === "bar" ||
            series.type === "bubble" ||
            (series.type === "column" && !series?.options?.colorByPoint)
          ) {
            const lineWidth = lineWidths[lineWidthIndex]
              ? lineWidths[lineWidthIndex].lineWidth
              : series.options.lineWidth;
            const borderColor = borderColors[borderColorIndex]
              ? borderColors[borderColorIndex].borderColor
              : series.options.borderColor;
            const borderWidth = borderWidths[borderWidthIndex]
              ? borderWidths[borderWidthIndex].borderWidth
              : series.options.borderWidth;
            if (lineWidth) {
              chart.series[lineWidthIndex].update({
                ...(series.type === "column"
                  ? { borderWidth: lineWidth }
                  : { lineWidth }),
              });
              lineWidthIndex++;
            }
            if (borderColor) {
              chart.series[borderColorIndex].update({ borderColor });
              borderColorIndex++;
            }
            if (borderWidth) {
              chart.series[borderWidthIndex].update({ borderWidth });
              borderWidthIndex++;
            }
          }
        });
        let xIndex = 0,
          yIndex = 0;
        chart.xAxis.map((x, i) => {
          chart.xAxis[i].plotLinesAndBands.map((line) => {
            chart.xAxis[i].removePlotLine(line.id);
          });
        });
        chart.yAxis.map((y, i) => {
          chart.yAxis[i].plotLinesAndBands.map((line) => {
            chart.yAxis[i].removePlotLine(line.id);
          });
        });
        data.series.map((item) => {
          if (item.type === "xAxisPlotLine") {
            const plotLine = xPlotLines[xIndex];
            chart.xAxis[plotLine.xAxisIndex].addPlotLine({
              ...plotLine.options,
              color: item.color,
            });
            xIndex++;
          } else if (item.type === "yAxisPlotLine") {
            const plotLine = yPlotLines[yIndex];
            chart.yAxis[plotLine.yAxisIndex].addPlotLine({
              ...plotLine.options,
              color: item.color,
            });
            yIndex++;
          }
        });
      }
      setItems({
        ...items,
        series: data.series,
      });
    }
  }, [data]);

  const mapData = async (payload, type) => {
    if (_.isArray(payload[type])) {
      const result = [];
      let length = 0;
      let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
      if (
        dashbaordData &&
        dashbaordData[id] &&
        _.isArray(dashbaordData[id][type])
      ) {
        length = dashbaordData[id][type].length;
      }

      await Promise.all(
        payload[type].map(async (item, i) => {
          if ((type === "Image" || type === "CustomIcon") && item.file) {
            await loadImage(item).then((response: any) => {
              item = {
                ...item,
                ...response,
              };
            });
          }
          item = {
            ...item,
            id: item.id
              ? mode === "add"
                ? `${item.id}-${length + i + 1}`
                : item.id
              : `${item.type}-${length + i + 1}`,
            x: item.x ? item.x : 0,
            y: item.y ? item.y : 0,
          };
          result.push(item);
        })
      );
      payload[type] = result;
      setItems(payload);
    }
  };

  const loadImageData = async (payload, type) => {
    if (_.isArray(payload[type])) {
      const result = [];
      await Promise.all(
        payload[type].map(async (item, i) => {
          if (
            (item.type === "Image" ||
              item.type === "Icon" ||
              item.type === "Shape" ||
              item.type === "Comment" ||
              item.type === "CustomIcon") &&
            item.base64
          ) {
            await loadImageFromBase64(item).then((response: any) => {
              item = {
                ...item,
                ...response,
              };
            });
          }
          result.push(item);
        })
      );
      payload[type] = result;
      setItems(payload);
    }
  };

  const ondrop = (e, item) => {
    let result = {
      ...item,
      x: item.type !== "Comment" ? e.target.x() + 10 : e.target.x(),
      y: item.type !== "Comment" ? e.target.y() + 10 : e.target.y(),
    };
    const payload = [...items[item.type]];
    const index = payload.findIndex((j) => j.id === item.id);
    if (index !== -1) {
      payload[index] = result;
    } else {
      payload.push(result);
    }
    setItems({
      ...items,
      [item.type]: payload,
    });
  };

  const resizeImage = (e, item) => {
    const images = [...items[item.type]];
    images.map((img, i) => {
      if (img.id === item.id) {
        if (e.e) {
          img.x = e.e.target.x();
          img.y = e.e.target.y();
        } else {
          img.width = e.width;
          img.height = e.height;
        }
      }
    });
    setItems({
      ...items,
      [item.type]: images,
    });
  };

  const onOpenColorPicker = (event, item, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem({ index, data: item });
  };

  const onOpenMMPW = (item, index) => {
    setMMPWMenu("2-1");
    setMMPWOpen(true);
    setSelectedItem({ index, data: item });
  };

  const mmpwCallback = (data) => {
    if (data.type === "close") {
      setMMPWOpen(false);
      setMMPWMenu("");
    } else {
      const newItems = [...items.Text];
      if (
        data.type === "fontFamily" ||
        data.type === "fontSize" ||
        data.type === "textColor" ||
        data.type === "fontStyle" ||
        data.type === "fontWeight" ||
        data.type === "textDecoration"
      ) {
        newItems[selectedItem.index].style.fontFamily =
          data.type === "fontFamily"
            ? data.fontFamily
              ? data.fontFamily
              : ""
            : newItems[selectedItem.index].style.fontFamily;
        newItems[selectedItem.index].style.fontSize =
          data.type === "fontSize"
            ? data.fontSize
              ? data.fontSize
              : 8
            : newItems[selectedItem.index].style.fontSize;
        newItems[selectedItem.index].style.fontStyle =
          data.type === "fontStyle"
            ? data.fontStyle
              ? data.fontStyle
              : ""
            : newItems[selectedItem.index].style.fontStyle;
        newItems[selectedItem.index].style.fontWeight =
          data.type === "fontWeight"
            ? data.fontWeight
              ? data.fontWeight
              : ""
            : newItems[selectedItem.index].style.fontWeight;
        newItems[selectedItem.index].style.textDecoration =
          data.type === "textDecoration"
            ? data.textDecoration
              ? data.textDecoration
              : ""
            : newItems[selectedItem.index].style.textDecoration;
        newItems[selectedItem.index].style.color = data.color
          ? data.color
          : newItems[selectedItem.index].style.color;
      }
      setItems({
        ...items,
        Text: newItems,
      });
    }
  };

  const onSelectColor = async (color) => {
    const allItems = [...items[selectedItem.data.type]];
    const item = allItems[selectedItem.index];
    if (item.type === "Text" && item.style && item.style.color) {
      allItems[selectedItem.index].style.color = color;
    } else if (
      item.type === "Shape" ||
      item.type === "Icon" ||
      item.type === "CustomIcon"
    ) {
      item.color = color;
    }
    setItems({
      ...items,
      [item.type]: allItems,
    });
    if (
      item.type === "Shape" ||
      item.type === "Icon" ||
      item.type === "CustomIcon"
    ) {
      await convertSVGToBase64(item.id, item.width, item.height).then(
        (response: any) => {
          allItems[selectedItem.index].base64 = response.base64;
          setItems({
            ...items,
            [item.type]: allItems,
          });
        }
      );
    }
  };

  const deleteElement = (item, index) => {
    const newItems = [...items[item.type]];
    newItems.splice(index, 1);
    setItems({
      ...items,
      [item.type]: newItems,
    });
  };

  const onChangeText = (val, i) => {
    const newItems = [...items.Text];
    newItems[i].text = val;
    setItems({
      ...items,
      Text: newItems,
    });
  };

  const renderControllers = (item) => {
    switch (item.type) {
      case "Text":
        return (
          <Text
            text={item.text}
            draggable
            fill={item.style.color}
            fontSize={item.style.fontSize}
            fontFamily={`${item.style.fontFamily} !important`}
            fontStyle={item.style.fontStyle}
            fontWeight={item.style.fontWeight}
            textDecoration={item.style.textDecoration}
            backround={"red"}
            onDragStart={() => {}}
            onDragEnd={(e) => {
              ondrop(e, item);
            }}
            x={item.x}
            y={item.y}
          />
        );
      case "Image":
        return (
          <DraggableImage
            isSelected={true}
            onSelect={(event) => {
              console.log("event ", event);
            }}
            onChange={(newAttrs) => {
              resizeImage(newAttrs, item);
            }}
            data={{ ...item }}
          />
          // <Image
          //   width={item.width}
          //   height={item.height}
          //   image={item.image}
          //   x={item.x}
          //   y={item.y}
          //   draggable
          //   onDragEnd={(e) => {
          //     ondrop(e, item);
          //   }}
          // />
        );
      case "Icon":
        return (
          <DraggableImage
            isSelected={true}
            onSelect={() => {}}
            onChange={(newAttrs) => {
              resizeImage(newAttrs, item);
            }}
            data={{ ...item }}
          />
        );
      case "Shape":
        return (
          <DraggableImage
            isSelected={true}
            onSelect={() => {}}
            onChange={(newAttrs) => {
              resizeImage(newAttrs, item);
            }}
            data={{ ...item }}
          />
        );
      case "Comment":
        return (
          <Image
            width={item.width}
            height={item.height}
            image={item.image}
            x={item.x}
            y={item.y}
            draggable
            onDragEnd={(e) => {
              ondrop(e, item);
            }}
          />
        );
      case "CustomIcon":
        return (
          <DraggableImage
            isSelected={true}
            onSelect={(event) => {
              console.log("event ", event);
            }}
            onChange={(newAttrs) => {
              resizeImage(newAttrs, item);
            }}
            data={{ ...item }}
          />
        );
    }
  };

  const renderElements = (item, index) => {
    return (
      <Card
        style={{
          position: "absolute",
          top: `${item.y - 50}px`,
          left: `${item.x - 50}px`,
          backgroundColor: "#fff",
          padding: 10,
        }}
      >
        <CardContent>
          {item.type === "Text" ? (
            <TextareaAutosize
              minRows={1}
              value={item.text}
              style={{
                backgroundColor: "#EDF5FA",
                padding: 10,
                color: item.style.color,
                fontSize: item.style.fontSize,
                fontStyle: item.style.fontStyle,
                fontWeight: item.style.fontWeight,
                textDecoration: item.style.textDecoration,
              }}
              ref={(element) => {
                if (element)
                  element.style.setProperty(
                    "font-family",
                    item.style.fontFamily,
                    "important"
                  );
              }}
              onChange={(e) => onChangeText(e.target.value, index)}
            />
          ) : item.type === "Image" ||
            item.type === "Icon" ||
            item.type === "Shape" ||
            item.type === "Comment" ||
            item.type === "CustomIcon" ? (
            <img src={item.base64} width={item.width} height={item.height} />
          ) : (
            <></>
          )}
        </CardContent>
        <Box>
          {item.type === "Text" ? (
            <IconButton
              style={{ margin: 0, color: "#858C94" }}
              onClick={() => onOpenMMPW(item, index)}
            >
              <Edit />
            </IconButton>
          ) : null}
          {mode === "edit" ? (
            <IconButton
              style={{ margin: 0, color: "#858C94" }}
              onClick={() => setOpenKonva(true)}
            >
              <MoveIcon width="20" />
            </IconButton>
          ) : null}
          {item.type === "Text" ||
          item.type === "Shape" ||
          item.type === "Icon" ? (
            <IconButton
              style={{ color: "#858C94" }}
              onClick={(event) => onOpenColorPicker(event, item, index)}
            >
              <ColorLens width="20" />
            </IconButton>
          ) : null}
          <IconButton
            style={{ color: "#858C94" }}
            onClick={() => deleteElement(item, index)}
          >
            <DeleteIcon width="20" />
          </IconButton>
        </Box>
      </Card>
    );
  };

  const onSaveMMPW = async () => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
      if (mode === "add") {
        removeElementsOnChart(id);
      }

      let finalData = items;
      if (mode === "add" && dashbaordData && dashbaordData[id]) {
        finalData = {
          Text: [
            ...(dashbaordData[id].Text ? dashbaordData[id].Text : []),
            ...(items.Text ? items.Text : []),
          ],
          Image: [
            ...(dashbaordData[id].Image ? dashbaordData[id].Image : []),
            ...(items.Image ? items.Image : []),
          ],
          Icon: [
            ...(dashbaordData[id].Icon ? dashbaordData[id].Icon : []),
            ...(items.Icon ? items.Icon : []),
          ],
          Shape: [
            ...(dashbaordData[id].Shape ? dashbaordData[id].Shape : []),
            ...(items.Shape ? items.Shape : []),
          ],
          CustomIcon: [
            ...(dashbaordData[id].CustomIcon
              ? dashbaordData[id].CustomIcon
              : []),
            ...(items.CustomIcon ? items.CustomIcon : []),
          ],
          Comment: [
            ...(dashbaordData[id].Comment ? dashbaordData[id].Comment : []),
            ...(items.Comment ? items.Comment : []),
          ],
          chartConfig: {
            ...(dashbaordData[id].chartConfig
              ? dashbaordData[id].chartConfig
              : {}),
            ...finalData.chartConfig,
          },
          series: items.series,
        };
      }
      await plotElementsOnChart(finalData, chart, id);
      let payload = {
        ...dashbaordData,
        [id]: finalData,
      };
      localStorage.setItem("mmpw", JSON.stringify(payload));
      window.dispatchEvent(new Event("storage"));
      close();
      hideshowPeriodicityBtn(false)
    }
  };

  const onCancel = () => {
    let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
    if (dashbaordData && dashbaordData[id]) {
      setItems(dashbaordData[id]);
      setTimeout(() => {
        if (chartRef && chartRef.current && chartRef.current.chart) {
          const chart = chartRef.current.chart;
          plotElementsOnChart(dashbaordData[id], chart, id);
        }
      });
    }
    if (Object.keys(currentChartConfig).length > 0) {
      if (currentChartConfig.backgroundColor) {
        chart.update({
          chart: {
            backgroundColor: currentChartConfig.backgroundColor,
          },
        });
      }
      if (currentChartConfig.series) {
        let index = 0;
        chart.series.map((item, i) => {
          if (
            item.type === "spline" ||
            item.type === "scatter" ||
            item.type === "area" ||
            item.type === "line" ||
            item.type === "errorbar" ||
            item.type === "bar" ||
            item.type === "bubble" ||
            (item.type === "column" && !item?.userOptions?.colorByPoint)
          ) {
            const s = currentChartConfig.series[index];
            chart.series[i].update({
              color: s.color,
              lineWidth: s.lineWidth,
              borderColor: s.borderColor,
              borderWidth: s.borderWidth,
            });
            index++;
          } else {
            item.data.map((subItem, j) => {
              const s = currentChartConfig.series[index];
              chart.series[i].data[j].update({
                color: s.color,
                lineWidth: s.lineWidth,
                borderColor: s.borderColor,
                borderWidth: s.borderWidth,
              });
              index++;
            });
          }
        });
      }

      if (_.isArray(currentChartConfig.xAxisPlotLines)) {
        chart.xAxis.map((x, i) => {
          chart.xAxis[i].plotLinesAndBands.map((line) => {
            chart.xAxis[i].removePlotLine(line.id);
          });
        });
        currentChartConfig.xAxisPlotLines.map((x) => {
          chart.xAxis[x.xAxisIndex].addPlotLine({ ...x.options });
        });
      }

      if (_.isArray(currentChartConfig.yAxisPlotLines)) {
        chart.yAxis.map((y, i) => {
          chart.yAxis[i].plotLinesAndBands.map((line) => {
            chart.yAxis[i].removePlotLine(line.id);
          });
        });
        currentChartConfig.yAxisPlotLines.map((y) => {
          chart.yAxis[y.yAxisIndex].addPlotLine({ ...y.options });
        });
      }
    }
    setCurrentChartConfig({});
    setOpenKonva(false);
    close();
    hideshowPeriodicityBtn(false)
  };

  const hideshowPeriodicityBtn = (hide) =>{
    const btn = document.getElementById('periodicity-buttons');
      if(btn)
        btn.style.display = hide? 'node' : 'flex';
  }

  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  });
  React.useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      hideshowPeriodicityBtn(true)
      setDimensions({
        width: chartRef.current.container.current.clientWidth,
        height: chartRef.current.container.current.clientHeight,
        left: chartRef.current.container.current.offsetLeft,
        top: chartRef.current.container.current.offsetTop,
      });
    }
  }, [chartRef]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const colorPickerOpen = Boolean(anchorEl);

  return (
    <div
      style={{
        position: "absolute",
        height: dimensions.height,
        width: dimensions.width,

        top: dimensions.top,
        backgroundColor: "rgba(0,0,0,0.5)",
        left: dimensions.left,
        padding: 10,
        zIndex: 2,
      }}
    >
      {openKonva ? (
        <Stage width={dimensions.width} height={dimensions.height}>
          <Layer>
            {items &&
              _.isArray(items.Text) &&
              items.Text.map((item, i) => renderControllers(item))}
            {items &&
              _.isArray(items.Icon) &&
              items.Icon.map((item, i) => renderControllers(item))}
            {items &&
              _.isArray(items.Shape) &&
              items.Shape.map((item, i) => renderControllers(item))}
            {items &&
              _.isArray(items.Comment) &&
              items.Comment.map((item, i) => renderControllers(item))}
            {items &&
              _.isArray(items.Image) &&
              items.Image.map((item, i) => renderControllers(item))}
            {items &&
              _.isArray(items.CustomIcon) &&
              items.CustomIcon.map((item, i) => renderControllers(item))}
          </Layer>
        </Stage>
      ) : (
        <div>
          {items &&
            _.isArray(items.Text) &&
            items.Text.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
          {items &&
            _.isArray(items.Icon) &&
            items.Icon.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
          {items &&
            _.isArray(items.Shape) &&
            items.Shape.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
          {items &&
            _.isArray(items.Comment) &&
            items.Comment.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
          {items &&
            _.isArray(items.Image) &&
            items.Image.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
          {items &&
            _.isArray(items.CustomIcon) &&
            items.CustomIcon.map((item, i) => (
              <Box key={`${item.id}-${i}`}>{renderElements(item, i)}</Box>
            ))}
        </div>
      )}
      {MMPWOpen && (
        <MMPW
          data={{ ...selectedItem.data }}
          open={MMPWOpen}
          callback={mmpwCallback}
          selectedMenu={selectedMenu}
        />
      )}
      <Popover
        anchorEl={anchorEl}
        open={colorPickerOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ColorPicker
          color={
            selectedItem &&
            (selectedItem.data.style
              ? selectedItem.data.style.color
              : selectedItem.data.color)
          }
          callback={(c) => onSelectColor(c)}
        />
      </Popover>
      <Box style={{ position: "absolute", bottom: 10 }}>
        <Button
          className="m-r-20"
          variant="contained"
          color="primary"
          onClick={onSaveMMPW}
        >
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
      <div style={{ display: "none" }}>
        {items &&
          _.isArray(items.Shape) &&
          items.Shape.map((i) => {
            let id = i.id.split("-");
            id.pop();
            id = id.join("-");
            const shape = shapes.find((x) => x.id === id);
            return (
              <shape.Icon
                id={i.id}
                key={i.id}
                style={{
                  height: i.height && i.height,
                  width: i.width && i.width,
                  fill: i.color,
                }}
              />
            );
          })}
        {items &&
          _.isArray(items.Icon) &&
          items.Icon.map((i) => {
            let id = i.id.split("-");
            id.pop();
            id = id.join("-");
            const icon = icons.find((x) => x.id === id);
            if (icon) {
              return (
                <icon.Icon
                  id={i.id}
                  key={i.id}
                  style={{
                    height: i.height && i.height,
                    width: i.width && i.width,
                    fill: i.color,
                  }}
                />
              );
            }
          })}
        {items &&
          _.isArray(items.CustomIcon) &&
          items.CustomIcon.map((i) => (
            <svg
              id={i.id}
              key={i.id}
              xmlns="http://www.w3.org/2000/svg"
              width={i.width ? i.width + 30 : 80}
              viewBox="0 0 40 38"
            >
              <image
                height={i.height && i.height}
                width={i.width && i.width}
                href={i.base64}
              />
            </svg>
          ))}
      </div>
    </div>
  );
};
export default Canvas;
