import { formatNumber } from "../../../../util/helper";

export const lineChartConfig = {
    chart: {
        type: "line",
        backgroundColor: "#f9f9f9",
    },
    credits: {
        enabled: false,
    },
    title:{
        text: ''
    },
    subtitle: {
        text: 'Cross Price Elasticity Over Time'
    },
    xAxis: {
        crosshair: false
    },
    yAxis: {
        labels: {
            formatter: function () {
              return formatNumber(this.value.toFixed(2));
            },
          },
        title: {
            text: 'Cross Price Elasticity',
            margin: 10,
        }
    },
    plotOptions: {
        series: {
            marker: {
              fillColor: "#FFFFFF",
              lineWidth: 2,
              lineColor: null,
              symbol: "circle",
            },
        },
    },
    legend: {
        enabled: false
    },
    series: [
        {
          data: [],
          tooltip: {
            pointFormatter: function () {
              return formatNumber(this.y);
            },
          },
        }
      ],
    navigation: {
        buttonOptions: {
          enabled: false,
        },
    },
}