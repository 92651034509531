import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { closeIcon } from "../../../../assets/icons/common";

const DataSourcePopup = ({ isOpen, setIsOpen, data }) => {
  const closeDialog = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };
  
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCloseHandler}
        style={{ zIndex: 1, minHeight: "80vh", maxHeight: "80vh" }}
      >
        <DialogTitle>
          <img
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            src={closeIcon}
            alt="X"
            onClick={closeDialog}
          />
        </DialogTitle>
        <DialogContent>
          {data.map((item) => (
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "12px",
                color:"#000000"
              }}
              key={item.id}
            >
              {item.label} --{">"} {item.value}
            </Typography>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DataSourcePopup;
