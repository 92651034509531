import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { removeLabels, removeSeriesLabels } from '../../store/actions/common.action';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'flex',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const IncrementDecrement: React.FC<{ list?; defaultOption?; callback?; data?; selectedResponse?; chartRef?; show?; id? }> = ({
  list = ['weekly', 'monthly', 'quarterly', 'yearly'],
  defaultOption,
  callback,
  data,
  selectedResponse,
  chartRef,
  show = true,
  id,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {    
    if (defaultOption) {
      const index = list.findIndex((x) => x === defaultOption);
      if (index !== -1) {
        setSelectedItem(index);
      }
    } else {
      setSelectedItem(0);
    }
  }, [defaultOption, data]);

  // useEffect(() => {
  //   if (chartRef && chartRef.current && chartRef.current.chart) {
  //     const chart = chartRef.current.chart;
  //     chart.update({
  //       xAxis: {
  //         labels: {
  //           formatter: function () {
  //             return DateTime.fromJSDate(new Date(this.value)).toFormat(getDataFormat(list[selectedItem]));
  //           },
  //         }
  //       }
  //     });
  //   }
  // }, [chartRef, selectedItem]);

  useEffect(() => {
    if (selectedItem !== null) {
      dispatch(removeLabels(id));
      let result = null;
      if (_.isObject(data) && Object.keys(data).length > 0) {
        result = data[list[selectedItem]];
      } else if (_.isArray(data) && data.length > 0) {
        const index = data.findIndex((x) => {
          result = x[list[selectedItem]];
        });
        const key = list[selectedItem];
        result = data[index][key];
      }
      if (callback) {
        callback({ index: selectedItem, item: list[selectedItem] });
      }
      localStorage.setItem(
        'periodicity',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('periodicity') ? localStorage.getItem('periodicity') : '{}'),
          [id]: { index: selectedItem, item: list[selectedItem] },
        })
      );
      if (selectedResponse) selectedResponse(result);
    }
  }, [selectedItem]);

  const increment = () => {
    if (selectedItem + 1 < list.length) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.series.map((s, i) => {
          dispatch(removeSeriesLabels([...chart.series[i].data], id));
        });
      }
      setSelectedItem(selectedItem + 1);
    }
  };

  const decrement = () => {
    if (selectedItem > 0) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.series.map((s, i) => {
          dispatch(removeSeriesLabels([...chart.series[i].data], id));
        });
      }
      setSelectedItem(selectedItem - 1);
    }
  };

  const getDataFormat = (periodicity) => {
    if (periodicity === 'yearly') return 'yyyy';
    else if (periodicity === 'quarterly') return 'yyyy Qq';
    else if (periodicity === 'monthly') return 'MMM yy';
    else if (periodicity === 'weekly') return 'yyyy-MM-dd';
    else return 'yyyy-MM-dd';
  };

  return (
    <>
      {show && list.length > 1 ? (
        <Grid container className={classes.hide} id='periodicity-buttons'>
          <Grid>
            <IconButton onClick={decrement} size='small'>
              <RemoveCircle style={{ color: '#25d4cf' }} fontSize='small' />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton onClick={increment} size='small'>
              <AddCircle style={{ color: '#25d4cf' }} fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
export default IncrementDecrement;
