import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import Dashboard from '../../../../components/Dashboard';
import mockData from '../../../../mocks/apiPredictiveDashboard';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import {
  checkForMultiSelections,
  dataFormat,
  plotImages,
  rendarImage,
  separator,
  setRSquareColor,
  windowResizeListner,
} from '../../../../util/helper';
import { Title } from '../../APIPredictiveDashboard.Styled';
import { G5ChartConfig } from '../../chartsConfig';
import HighchartContainer from '../../../../components/HighchartContainer';
import { getG5ChartData } from '../../service';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { G5ChartDataLoader } from '../../store/apiPredictive.action';
import { useDispatch } from 'react-redux';
const defaultPeriodicity = 'weekly';
const visualId = 'api-predictive-dash-g5-container';
const G5: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = useRef<any>(null);
  const [skeleton, setSkeleton] = useState(true);
  const [productImages, setProductImages] = useState<any>([]);
  const [originalG5DataResponse, setOriginalG5DataResponse] = useState<any>({});
  const [G5Data, setG5Data] = useState<any>([]);
  const [tableData, setTableData] = useState(_.cloneDeep(mockData));
  React.useEffect(() => {
    if (G5Data && checkForMultiSelections(filters)) {
      formatG5Data(G5Data);
      formatG5ChartData(G5Data);
    }
  }, [G5Data]);
  const formatG5Data = (response) => {
    const data = { ...tableData };
    data.regressionStatsTable.value[0].statsPer = `${Math.round(response.multipleR * 100) + '%'}`;
    data.regressionStatsTable.value[1].statsName = {
      ...data.regressionStatsTable.value[1].statsName,
      ...setRSquareColor(response.rSquare * 100),
    };
    data.regressionStatsTable.value[1].statsPer = {
      ...setRSquareColor(response.rSquare * 100),
      value: `${Math.round(response.rSquare * 100) + '%'}`,
    };
    data.regressionStatsTable.value[2].statsPer = `${Math.round(response.adjustedRSquare * 100) + '%'}`;
    data.regressionStatsTable.value[3].statsPer = response.standardError;
    data.regressionStatsTable.value[4].statsPer = response.observations;
    if (_.isArray(response.data)) {
      data.realESTVolumeTable.value = response.data.map((item) => {
        return {
          api: separator(item.api.toFixed(2)),
          realVol: separator(item.realVolume.toFixed(2)),
          estVol: separator(item.estVolume.toFixed(2)),
        };
      });
    }
    if (_.isArray(response.standardErrors)) {
      const sortedStandardErrors = _.orderBy(response.standardErrors, 'displayOrder', 'asc');
      data.coefficientTable.value = sortedStandardErrors.map((item) => {
        return {
          name: item.name,
          value1: separator(item.value1.toFixed(2)),
          value2: separator(item.value2.toFixed(2)),
          tStat: separator(item.tStat.toFixed(2)),
          pValue: separator(item.pValue.toFixed(2)),
          lower95: separator(item.lower95.toFixed(2)),
          upper95: separator(item.upper95.toFixed(2)),
        };
      });
    }
    setTableData(data);
  };
  const formatG5ChartData = async (response) => {
    if (response.data) {
      const data = response.data;
      const dates = data.map((item) => item.date);
      // const formattedDate = dataFormat(dates, "MM/yy");
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        chart.xAxis[0].setCategories(dates);
        const variable1 = data.map((item) => item.realVolume);
        const variable2 = data.map((item) => item.estVolume);
        chart.series[0].setData(variable1);
        chart.series[1].setData(variable2);
        setLabels(chart);
      }
      if (response.productName || response.anchorProductName) {
        let product1: any = null;
        let product2: any = null;
        if (response.productName) {
          product1 = {
            base64: await rendarImage(response.productName, filters.country),
          };
        }
        if (response.anchorProductName) {
          product2 = {
            base64: await rendarImage(response.anchorProductName, filters.country),
          };
        }
        if (product1 && product1.base64 && product2 && product2.base64) {
          setProductImages([product1, product2]);
        } else if (product1 && product1.base64) {
          setProductImages([product1]);
        } else if (product2 && product2.base64) {
          setProductImages([product2]);
        }
      }
    }
  };
  useEffect(() => {
    if (productImages.length > 0 && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const config = {
        productImages: productImages,
        id: 'api-predictive-dash-g5-image',
        imgPosition: { x: 200, y: 280 },
        imgDimension: { width: 75, height: 60 },
        nameDimension: { width: 50, height: 55 },
      };
      windowResizeListner(chart, config);
      plotImages(chart, config);
    }
  }, [productImages]);
  const setLabels = (chart) => {
    chart.legend.allItems[0].update({
      name: 'Real Volume',
    });
    chart.legend.allItems[1].update({
      name: 'Est Volume',
    });
  };
  React.useEffect(() => {
    if (filters && filters.country) {
      setSkeleton(true);
      dispatch(G5ChartDataLoader(true));
      setOriginalG5DataResponse({});
      setTableData(_.cloneDeep(mockData));
      setG5Data({});
      getG5ChartData(filters)
        .then((response) => {
          if (response && response.data) {
            setOriginalG5DataResponse(response.data);
            setSkeleton(false);
            dispatch(G5ChartDataLoader(false));
            setG5Data(response.data[defaultPeriodicity]);
          }
        })
        .catch((error) => {
          dispatch(G5ChartDataLoader(false));
          setSkeleton(false);
        });
    }
  }, [filters]);
  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard title='G5' showSkeleton={skeleton} chartRef={chartRef} id={visualId}>
          {!skeleton ? (
            filters && filters.packSize && filters.anchorPackSize && checkForMultiSelections(filters) ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Title className='m-b-20'>Data Table</Title>
                    <Grid container spacing={2} className='m-b-20'>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        style={{
                          maxHeight: 400,
                          overflow: 'auto',
                          paddingTop: 0,
                          marginTop: 16,
                        }}
                      >
                        <CustomTable
                          heading={tableData.realESTVolumeTable.heading}
                          value={tableData.realESTVolumeTable.value}
                          isFormatNumber={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} style={{ maxHeight: 400, overflow: 'auto' }}>
                        <CustomTable heading={tableData.regressionStatsTable.heading} value={tableData.regressionStatsTable.value} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} display='flex' alignItems='center'>
                    <Grid item xs={12}>
                      {G5Data && !_.isEmpty(G5Data) ? (
                        <HighchartContainer chartRef={chartRef} id={visualId}>
                          <HighchartsReact highcharts={Highcharts} options={G5ChartConfig} ref={chartRef} />
                          <Grid style={{ position: 'relative', bottom: 50, left: -5, zIndex: 2, width: '100%' }}>
                            <IncrementDecrement
                              data={originalG5DataResponse}
                              selectedResponse={setG5Data}
                              show={originalG5DataResponse && Object.keys(originalG5DataResponse).length > 0}
                              id={visualId}
                              defaultOption={
                                (localStorage.getItem('periodicity') &&
                                  JSON.parse(localStorage.getItem('periodicity'))[visualId] &&
                                  JSON.parse(localStorage.getItem('periodicity'))[visualId]?.item) ||
                                null
                              }
                            />
                          </Grid>
                        </HighchartContainer>
                      ) : (
                        <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                          <NoData>{messages.noData}</NoData>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={'m-t-10'} style={{ maxHeight: 325, overflowY: 'auto' }}>
                  <CustomTable heading={tableData.coefficientTable.heading} value={tableData.coefficientTable.value} />
                </Grid>
              </>
            ) : filters && filters.packSize && filters.anchorPackSize && !checkForMultiSelections(filters) ? (
              <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                <NoData>{messages.noData}</NoData>
              </Box>
            ) : (
              <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
                <NoData>{messages.incorrectFilters}</NoData>
              </Box>
            )
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Skeleton variant='rectangular' width={150} height={30} className='m-b-10 m-t-10' />
                  <Grid container spacing={2} className='m-b-20'>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        maxHeight: 400,
                        overflow: 'auto',
                        paddingTop: 0,
                        marginTop: 16,
                      }}
                    >
                      <CustomTable heading={tableData.realESTVolumeTable.heading} value={[]} showSkeleton={true} />
                    </Grid>
                    <Grid item xs={12} md={5} style={{ maxHeight: 400, overflow: 'auto' }}>
                      <CustomTable heading={tableData.regressionStatsTable.heading} value={[]} showSkeleton={true} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} display='flex' alignItems='center'>
                  <Grid item xs={12}>
                    <Skeleton variant='rectangular' height={350} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={'m-t-10'} style={{ maxHeight: 325, overflowY: 'auto' }}>
                <CustomTable heading={tableData.coefficientTable.heading} value={[]} showSkeleton={true} />
              </Grid>
            </>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};
export default G5;
