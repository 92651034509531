import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography, Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { StyledTableRow } from '../../../../styles/Common.Styled';
import { formatNumber } from '../../../../util/helper';

const WeeklyTable: React.FC<{ heading; data; title?; showPercent?; unitType?}> = ({ heading, data, title, showPercent = false, unitType }) => {
  return (
    <>
      <Grid container mt={4}>
        <Grid item sm={12} width={800}>
          <TableContainer
            sx={{
              '&::-webkit-scrollbar': {
                height: 8,
              },
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell className='b-color'>{title ? title : null}</TableCell>
                  {heading.map((item) => (
                    <TableCell className='b-color'>
                      <span style={{ whiteSpace: 'nowrap', fontWeight: 800 }}>{item}</span>
                    </TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {_.isArray(data)
                  ? data.map((item) => (
                    <StyledTableRow>
                      <TableCell
                        className='b-color'
                        style={{ backgroundColor: '#ECECEC', whiteSpace: 'nowrap', width: item.width ? item.width : null }}
                      >
                        {item.title}
                      </TableCell>
                      {item.data.map((value) => (
                        <TableCell className='b-color' style={{ whiteSpace: 'nowrap' }}>
                          {_.isNumber(value) ? formatNumber(value.toFixed(2)) : value}
                          {/* {`${showPercent && unitType !== 'unit' ? '%' : ''}`} */}
                          {`${showPercent ? '%' : ''}`}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default WeeklyTable;
