import React, { useEffect, useState } from "react";
import _ from "lodash"
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import {
  OrangeBtn,
  PrimaryBtn,
} from "../../../../styles/Common.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup/CheckBoxGroup";
import { measureFilter } from "./config";
import FilterAccordion from "../../../../components/Filters/Filters";
import Indicator from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";

const APISimulationFilter: React.FC<{
  callback;
  globalFilters?;
  defaultFilters
}> = ({ callback, globalFilters, defaultFilters }) => {
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    variable1: measureFilter.api.defaultOption.value,
    variable2: measureFilter.variable.defaultOption.value,
    somMeasure: measureFilter.SOMConfig.defaultOption.value,
    somGroup: measureFilter.variableConfig.defaultOption.value,
    apiUnits: measureFilter.apiVariableConfig.defaultOption.value,
  });
  const [skeleton, setSkeleton] = useState(true);
  const onSelectMeasureFilter = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    if (type === "variable2" && selectedVal !== 'SOM') {
      temp.somGroup = undefined;
      temp.somMeasure = undefined;
    }
    temp[type] = selectedVal;
    setMeasureFilter(temp);
  };

  React.useEffect(() => {
    if (globalFilters && globalFilters.country) {
      setSkeleton(false);
    }
  }, [globalFilters]);

  useEffect(() => {
    if (defaultFilters && _.isObject(defaultFilters) && defaultFilters?.type === 'local' && Object.keys(defaultFilters).length > 0) {
      setMeasureFilter({
        ...defaultFilters.data,
        apiUnits: defaultFilters?.data?.apiUnits === "Volume" ? "KGs ( in '000s )" : "Units ( in '000s )"
      })
    }
  }, [defaultFilters])

  const onApplyFilter = () => {
    callback({
      ...selectedMeasureFilters,
      apiUnits: selectedMeasureFilters.apiUnits === "KGs ( in '000s )" ? "Volume" : "Units"
    });
  };

  const clearFilter = () => {
    setMeasureFilter({
      variable1: measureFilter.api.defaultOption.value,
      variable2: measureFilter.variable.defaultOption.value,
      somMeasure: measureFilter.SOMConfig.defaultOption.value,
      somGroup: measureFilter.variableConfig.defaultOption.value,
      apiUnits: measureFilter.apiVariableConfig.defaultOption.value,
    });
  };

  const disableApplyFilter = () => {
    const flag: boolean =
      (selectedMeasureFilters.variable1
        ? selectedMeasureFilters.variable1 === "API"
          ? selectedMeasureFilters.apiUnits === "" || selectedMeasureFilters.apiUnits === undefined
          : false
        : true) ||
      (selectedMeasureFilters.variable2
        ? selectedMeasureFilters.variable2 === "SOM"
          ? (selectedMeasureFilters.somGroup === "" || selectedMeasureFilters.somGroup === undefined ||
            selectedMeasureFilters.somMeasure === "" || selectedMeasureFilters.somMeasure === undefined)
          : false
        : true)
    return flag;
  };

  const reduxState = useSelector((state: any) => state.ApiPredictive);
  return (
    <Card className="m-b-20" style={{ position: 'relative' }}>
      <Indicator position="absolute" show={reduxState.simulationDataLoader || reduxState.simulationValueLoader} />
      <CardContent>
        {!skeleton ? (
          <FilterAccordion title="Measure Filters">
            <Grid container spacing={2} className="m-b-10">
              <Grid item xs={12} sm={2}>
                <DropdownTitle>{measureFilter.api.title}</DropdownTitle>
                <RadioButtonGroup
                  key={`measures-api-simulation-filters`}
                  color="#000"
                  showButton={measureFilter.api.showRadioButton}
                  data={measureFilter.api.options}
                  defaultOption={selectedMeasureFilters.variable1}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DropdownTitle>{measureFilter.apiVariableConfig.title}</DropdownTitle>
                <CheckBoxGroup
                  data={measureFilter.apiVariableConfig.options}
                  color="#000"
                  direction="row"
                  single={true}
                  defaultOption={selectedMeasureFilters.apiUnits}
                  select={(data) => onSelectMeasureFilter("apiUnits", data)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="m-b-30">
              <Grid item xs={12} sm={2}>
                <DropdownTitle>{measureFilter.variable.title}</DropdownTitle>
                <RadioButtonGroup
                  key={`measures-api-simulation-filters`}
                  color="#000"
                  showButton={measureFilter.variable.showRadioButton}
                  data={measureFilter.variable.options}
                  defaultOption={selectedMeasureFilters.variable2}
                  select={(data) => onSelectMeasureFilter("variable2", data)}
                />
              </Grid>
              <Grid item>
                <DropdownTitle>{measureFilter.variableConfig.title}</DropdownTitle>
                <Grid container>
                  <Grid item className="m-r-10 b-r-1">
                    <CheckBoxGroup
                      data={measureFilter.variableConfig.options}
                      color="#000"
                      direction="row"
                      single={true}
                      defaultOption={selectedMeasureFilters.somGroup}
                      select={(data) => onSelectMeasureFilter("somGroup", data)}
                      disabled={
                        selectedMeasureFilters.variable2 !== "SOM"
                      }
                    />
                  </Grid>
                  <Grid item>
                    <RadioButtonGroup
                      key={`measures-simulation-filters-som`}
                      color="#000"
                      showButton={measureFilter.SOMConfig.showRadioButton}
                      data={measureFilter.SOMConfig.options}
                      defaultOption={selectedMeasureFilters.somMeasure}
                      select={(data) => onSelectMeasureFilter("somMeasure", data)}
                      direction="row"
                      disabled={
                        selectedMeasureFilters.variable2 !== "SOM"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <OrangeBtn
                color="secondary"
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn color="primary" onClick={onApplyFilter} disabled={disableApplyFilter()}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </FilterAccordion>
        )
          :
          <>
            <Grid container spacing={2} className="m-b-20">
              <Grid item xs={12}>
                <Skeleton variant="rectangular" height={45} />
              </Grid>
            </Grid>
            <Grid className="p-l-16 m-t-20" container spacing={2}>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={99}
                height={45}
              />
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={110}
                height={45}
              />
            </Grid>
          </>}
      </CardContent>
    </Card>
  );
};
export default APISimulationFilter;