import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../store/actions/common.action";
import { fetchNotifications, getNotificationSuccess } from "../../store/actions/AlertAndNotification/getNotification.action";
import NotificationCard from "../NotificationCard/NotificationCard";
import { dissmissNotification } from "../../store/actions/AlertAndNotification/dismissNotification.action";

const GlobalNotifications: React.FC<{ pageName }> = ({ pageName }) => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState<any>({});

  const notificationData = useSelector(
    (state: any) => state.GetAllNotification
  );

  const dismissNotification = useSelector(
    (state: any) => state.DismissNotification
  );

  useEffect(()=>{
    dispatch(loader(true));
    dispatch(fetchNotifications(pageName));
  }, [])

  useEffect(()=>{
    if(dismissNotification && dismissNotification.data && notification){
      if(notificationData.data && notificationData.data.length){
        const notifications = [...notificationData.data];
        dispatch(getNotificationSuccess(notifications.filter(x=> x.id !== notification.id)));
      }
    }
  }, [dismissNotification])

  useEffect(()=>{
    if(notificationData && notificationData.data){
      dispatch(loader(false));
    }else if(notificationData.error){
      dispatch(loader(false));
    }
  }, [notificationData])

  const onNotificationAction = (payload, notification) =>{
    if(payload.type === 'dismiss'){
      setNotification(notification);
      dispatch(dissmissNotification(notification));
    }
  }

  return (
    <>
    {notificationData.data && notificationData.data.map((item, index) => {
      return (
        <Grid item xs={12} sm={6} xl={4} key={`price-architecture-notification-${index}`}>
          <NotificationCard data={item} callback={(e)=> onNotificationAction(e, item)}/>
        </Grid>
      );
    })}
    </>
  );
};
export default GlobalNotifications;
