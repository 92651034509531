import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomTable from '../../../../components/CustomTable';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import GrossVolumeCard from '../../../../components/GrossVolumeCard/GrossVolumeCard';
import CheckBoxGroup from '../../../../components/UI-components/CheckBoxGroup';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import data from '../../../../mocks/profitParabola';
import { loader } from '../../../../store/actions/common.action';
import { BlackInputTitle, NoData } from '../../../../styles/Common.Styled';
import { Title } from '../../ProfitParabolaDashboard.Styled';

import _ from 'lodash';
import { messages } from '../../../../util/config';
import {
  convertToInternationalCurrency,
  separator,
  setSimulationTableColor,
} from '../../../../util/helper';
import { getFinalSimulationValueData } from '../../service';
import Dashboard from '../../../../components/Dashboard';

const FinalSimulation: React.FC<{ filters; callback; defaultFilters }> = ({
  filters,
  callback,
  defaultFilters,
}) => {
  const dispatch = useDispatch();

  const [skeleton, setSkeleton] = useState(true);
  const [kpiSkeleton, setKPISkeleton] = useState(true);
  const [valueSkeleton, setValueSkeleton] = useState(true);
  const [simulatedSkeleton, setSimulatedSkeleton] = useState(true);
  const [simulationStatsCard, setSimulationStatsCardData] = useState(data.simulationStatsCard);

  const [selectedMeasureFilters, setMeasureFilter] = useState({
    somMeasure: 'Category',
    somGroup: 'Volume',
  });
  const [selectedMonthRange, setSelectedMonthRange] = useState('3 Months');
  const [proposedValueHeading, setProposedValueHeading] = useState(
    data.proposedValuesTable.heading
  );
  const [proposedValues, setProposedValues] = useState(data.proposedValuesTable.value);
  const [simulatedValues, setSimulatedValues] = useState(data.simulatedValuesTable.value);
  const [simulationValueData, setSimulationValueData] = useState({});
  const formatSimulationValueData = (response) => {
    const simulationStatsCardData = [...simulationStatsCard];
    setKPISkeleton(false);
    simulationStatsCardData[0].value = response.grossSales
      ? convertToInternationalCurrency('$', response.grossSales)
      : '0';
    simulationStatsCardData[1].value = response.volume
      ? convertToInternationalCurrency('', response.volume)
      : '0';
    // simulationStatsCardData[1].percent = response.diffGrossProfit
    //   ? response.diffGrossProfit.toFixed(2)
    //   : 0;
    simulationStatsCardData[1].raise = response.diffGrossProfit && response.diffGrossProfit > 0;

    simulationStatsCardData[2].value = response.grossProfit
      ? convertToInternationalCurrency('$', response.grossProfit)
      : '0';
    simulationStatsCardData[3].value = response.som ? `${(response.som * 100).toFixed(2)}%` : '0';
    // simulationStatsCardData[3].percent = response.diffShareVolume
    //   ? response.diffShareVolume.toFixed(2)
    //   : 0;
    simulationStatsCardData[3].raise = response.diffShareVolume && response.diffShareVolume > 0;

    setSimulationStatsCardData(simulationStatsCardData);
    setSkeleton(false);
    setValueSkeleton(false);
  };

  const formatFinalSimulationTableData = (response) => {
    if (_.isArray(response.currentValues)) {
      data.currentValuesTable.value = response.currentValues.map((item) => {
        return {
          brandSize: { value: item.brandSize, width: 170 },
          packSize: { value: item.packetSize, width: 115 },
          grossSales: separator((item.grossSales / 1000).toFixed(2)),
          volume: separator((item.volume / 1000).toFixed(2)),
          grossProfit: separator((item.grossProfit / 1000).toFixed(2)),
          pricePerUnit: separator(item.pricePerUnit.toFixed(2)),
          volumePerUnit: separator(item.volumePerUnit.toFixed(2)),
          priceElasticity: separator(item.priceElasticty.toFixed(2)),
          gramElasticity: separator(item.gramElasticty.toFixed(2)),
        };
      });
    }
    if (_.isArray(response.proposedValues)) {
      const newData = response.proposedValues.map((item) => {
        return {
          brandSize: { value: item.brandSize, width: 168 },
          packSize: { value: item.packetSize, width: 115 },
          grossSales: separator((item.grossSales / 1000).toFixed(2)),
          volume: separator((item.volume / 1000).toFixed(2)),
          grossProfit: separator((item.grossProfit / 1000).toFixed(2)),
          pricePerUnit: {
            editable: true,
            type: 'Text',
            value: separator(item.pricePerUnit.toFixed(2)),
          },
          volumePerUnit: {
            editable: true,
            type: 'Text',
            value: separator(item.volumePerUnit.toFixed(2)),
          },
          priceElasticity: {
            editable: true,
            type: 'Text',
            value: separator(item.priceElasticty.toFixed(2)),
          },
          gramElasticity: {
            editable: true,
            type: 'Text',
            value: separator(item.gramElasticty.toFixed(2)),
          },
        };
      });
      setProposedValues(newData);
    }
  };

  // const simulationValueData = useSelector((state: any) => state.ProfitParabolaFinalSimulationValues);

  useEffect(() => {
    if (simulationValueData && simulationValueData.data && !isSimulatedAPICalled) {
      dispatch(loader(false));
      setKPISkeleton(false);
      if (defaultFilters?.finalSimulations) {
        formatFinalSimulationTableData(defaultFilters?.finalSimulations);
      } else {
        formatFinalSimulationTableData(simulationValueData.data);
      }
      setTimeout(() => {
        formatSimulationValueData(simulationValueData.data);
      }, 0);
    }
    if (simulationValueData && simulationValueData.error) {
      dispatch(loader(false));
      console.log(simulationValueData.error);
    }
  }, [simulationValueData]);

  useEffect(() => {
    if (filters && filters.country) {
      setProposedValues([]);
      data.currentValuesTable.value = [];
      setSimulatedValues([]);
      setAPIFlag(false);
      onChangeDateRange(selectedMonthRange);
    }
  }, [filters]);

  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    temp[type] = selectedVal;
    setMeasureFilter(temp);
    setKPISkeleton(true);
    callback({
      finalSimulations: simulationValueData?.data,
      ...selectedMeasureFilters,
      monthRange: selectedMonthRange,
    });
    getFinalSimulationValueData({ ...filters, ...temp })
      .then((response) => {
        setSimulationValueData(response);
      })
      .catch((err) => {
        setSkeleton(false);
        setSimulatedAPIFlag(false);
        console.log(err);
      });
  };

  const onChangeDateRange = (value) => {
    let monthRange = null;
    switch (value) {
      case '3 Months':
        monthRange = 3;
        setSelectedMonthRange(value);
        break;
      case '6 Months':
        monthRange = 6;
        setSelectedMonthRange(value);
        break;
      case '12 Months':
        monthRange = 12;
        setSelectedMonthRange(value);
        break;
    }
    if (filters && monthRange) {
      filters.monthRange = monthRange;
      setSkeleton(true);
      setSimulatedAPIFlag(true);
      callback({
        finalSimulations: simulationValueData?.data,
        ...selectedMeasureFilters,
        monthRange: monthRange,
      });
      getFinalSimulationValueData({ ...filters, ...selectedMeasureFilters })
        .then((response) => {
          setSimulationValueData(response);
        })
        .catch((err) => {
          setSkeleton(false);
          setSimulatedAPIFlag(false);
          console.log(err);
        });
    }
  };

  const [isSimulatedAPICalled, setAPIFlag] = useState(false);
  const [isSimulatedResultAPICalled, setSimulatedAPIFlag] = useState(false);

  const proposedPriceHandler = (payload) => {
    setAPIFlag(false);
    const newData = [...proposedValues];
    newData[payload.row][payload.columnName].value = payload.value;
    setProposedValues(newData);
  };

  const getSimulatedData = (payload) => {
    if (!isSimulatedAPICalled) {
      setAPIFlag(true);
      setSimulatedAPIFlag(true);
      const requestPayload = {
        pricePerUnit: parseFloat(proposedValues[payload.row].pricePerUnit.value) || 0,
        priceElasticity: parseFloat(proposedValues[payload.row].priceElasticity.value) || 0,
        volumePerUnit: parseFloat(proposedValues[payload.row].volumePerUnit.value) || 0,
        gramElasticity: parseFloat(proposedValues[payload.row].gramElasticity.value) || 0,
      };
      if (payload.columnName === 'pricePerUnit') {
        requestPayload.pricePerUnit = parseFloat(payload.value);
      }
      if (payload.columnName === 'priceElasticity') {
        requestPayload.priceElasticity = parseFloat(payload.value);
      }
      if (payload.columnName === 'volumePerUnit') {
        requestPayload.volumePerUnit = parseFloat(payload.value);
      }
      if (payload.columnName === 'gramElasticity') {
        requestPayload.gramElasticity = parseFloat(payload.value);
      }
      setSimulatedSkeleton(true);
      setSimulatedValues(data.simulatedValuesTable.value);
      getFinalSimulationValueData({ ...filters, ...requestPayload })
        .then((response) => {
          setSimulationValueData(response);
        })
        .catch((err) => {
          setSkeleton(false);
          setSimulatedAPIFlag(false);
          console.log(err);
        });
    }
  };

  const onCellKeyDown = (payload) => {
    if (payload.event.keyCode === 13) {
      getSimulatedData(payload);
    }
  };

  const onCellBlur = (payload) => {
    getSimulatedData(payload);
  };

  const formatSimulatedTableData = (response) => {
    if (_.isArray(response.simulatedValues)) {
      const simulatedValuesTable = response.simulatedValues.map((item) => {
        return {
          brandSize: { value: item.brandSize, width: 210 },
          packSize: { value: item.packetSize, width: 177 },
          grossSales: separator((item.grossSales / 1000).toFixed(2)),
          grossSalesPer: {
            ...setSimulationTableColor(item.grossSalesPer),
            value: item.grossSalesPer ? separator((item.grossSalesPer * 100).toFixed(2)) + '%' : '',
          },
          volume: separator((item.volume / 1000).toFixed(2)),
          volumePer: {
            ...setSimulationTableColor(item.volumePer),
            value: item.volumePer ? separator((item.volumePer * 100).toFixed(2)) + '%' : '',
          },
          grossProfit: separator((item.grossProfit / 1000).toFixed(2)),
          grossProfitPer: {
            ...setSimulationTableColor(item.grossProfitPer),
            value: item.grossProfitPer
              ? separator((item.grossProfitPer * 100).toFixed(2)) + '%'
              : '',
          },
          pricePerUnitPer: {
            ...setSimulationTableColor(item.pricePerUnitPer),
            value: item.pricePerUnitPer
              ? separator((item.pricePerUnitPer * 100).toFixed(2)) + '%'
              : '',
          },
          volumePerUnitPer: {
            ...setSimulationTableColor(item.volumePerUnitPer),
            value: item.volumePerUnitPer
              ? separator((item.volumePerUnitPer * 100).toFixed(2)) + '%'
              : '0',
          },
        };
      });
      setSimulatedAPIFlag(false);
      setSimulatedValues(simulatedValuesTable);
    }
    setSimulatedSkeleton(false);
  };

  useEffect(() => {
    if (isSimulatedAPICalled && simulationValueData.data) {
      callback({
        finalSimulations: simulationValueData?.data,
        ...selectedMeasureFilters,
        monthRange: selectedMonthRange,
      });
      formatSimulatedTableData(simulationValueData.data);
      setSimulatedSkeleton(false);
      setKPISkeleton(false);
    }
  }, [isSimulatedAPICalled, simulationValueData]);

  useEffect(() => {
    if (
      defaultFilters &&
      defaultFilters?.finalSimulations?.proposedValues &&
      Object.keys(defaultFilters?.finalSimulations?.proposedValues).length > 0
    ) {
      setMeasureFilter({
        somGroup: defaultFilters.somGroup,
        somMeasure: defaultFilters.somMeasure,
      });
      setSelectedMonthRange(`${defaultFilters.monthRange} Months`);
      setProposedValues(defaultFilters?.finalSimulations?.proposedValues);
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (
      defaultFilters &&
      defaultFilters?.finalSimulations?.proposedValues &&
      Object.keys(defaultFilters?.finalSimulations?.proposedValues).length > 0 &&
      proposedValues[0]?.volumePerUnit?.value
    ) {
      getSimulatedData({
        row: 0,
        column: 6,
        columnName: 'volumePerUnit',
        value: proposedValues[0]?.volumePerUnit?.value,
      });
    }
  }, [proposedValues]);

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard
          title={'Final Simulation'}
          showSkeleton={skeleton}
          chartRef={() => {}}
          showActionButton={true}
          actionButtons={{
            mmpw: false,
            screenshot: true,
            editMedia: false,
          }}
          id={null}
        >
          <Grid container justifyContent='space-between' className='m-b-10'>
            <Grid item>
              <Grid container spacing={0} alignItems='center' className='m-b-10'>
                <Grid item className='m-r-20'>
                  {skeleton ? (
                    <Skeleton width={150} height={22} />
                  ) : (
                    <DropdownTitle>SOM Variable Configuration</DropdownTitle>
                  )}
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid className='m-r-10 b-r-1'>
                      <CheckBoxGroup
                        key='final-simulation-SOM-VarConfig'
                        data={data.measureFilter.SOMVarConfig.options}
                        color='#000'
                        direction='row'
                        select={(data) => onSelectMeasure('somGroup', data)}
                        defaultOption={selectedMeasureFilters.somGroup}
                        showSkeleton={skeleton}
                        single={true}
                      />
                    </Grid>
                    <Grid>
                      <RadioButtonGroup
                        key={`measures-filters-som`}
                        color='#000'
                        showButton={data.measureFilter.SOM.showRadioButton}
                        data={data.measureFilter.SOM.options}
                        defaultOption={selectedMeasureFilters.somMeasure}
                        select={(data) => onSelectMeasure('somMeasure', data)}
                        direction='row'
                        showSkeleton={skeleton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={0} alignItems='center' className='m-b-10'>
                <Grid item className='m-r-20'>
                  {skeleton ? (
                    <Skeleton width={150} height={22} />
                  ) : (
                    <DropdownTitle>{data.monthRangeFilter.title}</DropdownTitle>
                  )}
                </Grid>
                <Grid item>
                  <RadioButtonGroup
                    key={`measures-filters-month-range`}
                    color='#000'
                    showButton={data.monthRangeFilter.showRadioButton}
                    data={data.monthRangeFilter.options}
                    direction='row'
                    showSkeleton={skeleton}
                    defaultOption={selectedMonthRange}
                    select={onChangeDateRange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className='m-b-20'>
            {simulationStatsCard.map((item) => (
              <Grid item xs={6} md={3}>
                <GrossVolumeCard data={item} showSkeleton={skeleton || kpiSkeleton} />
              </Grid>
            ))}
          </Grid>
          {skeleton ? (
            <>
              <Grid className='m-b-20'>
                <Skeleton variant='rectangular' width={150} height={30} className='m-b-10' />
                <CustomTable
                  heading={data.currentValuesTable.heading}
                  value={[]}
                  showSkeleton={skeleton}
                />
              </Grid>
              <Grid className='m-b-20'>
                <Skeleton variant='rectangular' width={150} height={30} className='m-b-10' />
                <CustomTable heading={proposedValueHeading} value={[]} showSkeleton={skeleton} />
              </Grid>
              <Grid>
                {isSimulatedAPICalled && simulatedSkeleton && (
                  <Skeleton variant='rectangular' width={150} height={30} className='m-b-10' />
                )}
                <CustomTable
                  heading={data.simulatedValuesTable.heading}
                  value={[]}
                  showSkeleton={skeleton}
                />
              </Grid>
            </>
          ) : filters &&
            filters.packSize &&
            _.isArray(filters.packSize) &&
            filters.anchorPackSize ? (
            (_.isArray(data.currentValuesTable.value) &&
              data.currentValuesTable.value.length > 0) ||
            (_.isArray(proposedValues) && proposedValues.length > 0) ? (
              <>
                {_.isArray(data.currentValuesTable.value) &&
                  data.currentValuesTable.value.length > 0 && (
                    <Grid className='m-b-20'>
                      <BlackInputTitle className='m-b-10'>Current Values</BlackInputTitle>
                      <CustomTable
                        heading={data.currentValuesTable.heading}
                        value={data.currentValuesTable.value}
                        isFormatNumber={false}
                      />
                    </Grid>
                  )}
                {_.isArray(proposedValues) && proposedValues.length > 0 && (
                  <Grid className='m-b-20'>
                    <BlackInputTitle className='m-b-10'>Proposed Values</BlackInputTitle>
                    <CustomTable
                      heading={proposedValueHeading}
                      value={proposedValues}
                      callback={proposedPriceHandler}
                      onCellKeyDown={onCellKeyDown}
                      onCellBlur={onCellBlur}
                      isFormatNumber={false}
                    />
                  </Grid>
                )}
                <Grid>
                  <BlackInputTitle className='m-b-10'>Simulated Results</BlackInputTitle>
                  {isSimulatedResultAPICalled || simulatedSkeleton ? (
                    <CustomTable
                      heading={data.simulatedValuesTable.heading}
                      value={[]}
                      showSkeleton={true}
                    />
                  ) : _.isArray(simulatedValues) && simulatedValues.length > 0 ? (
                    <CustomTable
                      heading={data.simulatedValuesTable.heading}
                      value={simulatedValues}
                      isFormatNumber={false}
                    />
                  ) : (
                    <Box
                      style={{ height: 50 }}
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <NoData>{messages.noData}</NoData>
                    </Box>
                  )}
                </Grid>
              </>
            ) : (
              <Box
                style={{ height: 50 }}
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )
          ) : (
            <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.incorrectFilters}</NoData>
            </Box>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default FinalSimulation;
