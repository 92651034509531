import React, { useState } from "react";
import { Button, Grid, Popover } from "@mui/material";
import { MixFilterName, MixFilterValues } from "../ExecutionCommon.Styled";

const PopoverComponent = ({ id, name, options, selectedMixScenario }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid item xs={2}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <MixFilterName>{name}</MixFilterName>
        <div>
          <MixFilterValues
            onClick={handleClick}
            style={{ border: "1px solid white" }}
          >
            {selectedMixScenario.length > 0 ? options.split(",")[0] : ""}
          </MixFilterValues>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div style={{ padding: "8px" }}>
              {selectedMixScenario.length > 0 &&
                options.split(",").map((item, index) => (
                  <div key={index} style={{ cursor: "pointer" }}>
                    {item}
                  </div>
                ))}
            </div>
          </Popover>
        </div>
      </div>
    </Grid>
  );
};

export default PopoverComponent;
