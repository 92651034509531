import { Hidden } from '@mui/material';
import { formatNumber } from '../../../../util/helper';

export const donutChartConfig = {
  chart: {
    backgroundColor: 'transparent',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  legend: {
    itemMarginBottom: 8,
    floating: true,
    align: 'left',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      fontSize: '18px',
      marginBottom: 10,
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      width: '350px',
      'white-space': 'nowrap',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      borderColor: '#ffffff',
      borderWidth: '0',
      cursor: true,
      innerSize: '50%',
      dataLabels: {
        enabled: false,
        format: '{point.percentage:.2f} %',
      },
      center: ['50%', '50%'],
      showInLegend: true,
    },
    series: {
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          opacity: 1,
        },
      },
      tooltip: {
        enabled: true,
        pointFormatter: function (): string {
          return formatNumber(this.options.y.toFixed(2));
        },
      },
    },
  },
  series: [
    {
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
