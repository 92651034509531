import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
} from "./constants";
import { API } from "../../../util/helper";

export const CreateNotification = () => {
  return {
    type: CREATE_NOTIFICATION,
  };
};

export const CreateNotificationSuccess = (payload: any) => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const CreateNotificationFailure = (error) => {
  return {
    type: CREATE_NOTIFICATION_FAILURE,
    payload: error,
  };
};

const CreateNotificationData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}notifications`,
    payload
  );
};

export function postCreateNotification(payload) {
  return (dispatch: any) => {
      dispatch(CreateNotification());
      CreateNotificationData(payload)
        .then((response) => {
          dispatch(CreateNotificationSuccess(response.data));
        })
        .catch((error) => {
          dispatch(CreateNotificationFailure(error));
        });
  };
}
