import { makeStyles } from '@material-ui/core';
import { Box, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Title } from '../../../../../../components/Dashboard/Dashboard.Styled';
import IncrementDecrement from '../../../../../../components/IncrementDecrement/IncrementDecrement';
import { NoData } from '../../../../../../styles/Common.Styled';
import { messages } from '../../../../../../util/config';
import DcsElasticitesTable from '../DcsElasticitiesTable/DcsElasticitesTable';
import MultipleChart from '../MultipleChart';
import RegionElasticitiesTable from '../RegionElasticitiesTable';

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hide: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  nameBox: { background: 'gray', border: '1px solid #efefef', color: '#fff' },
}));

const defaultPeriodicity = 'weekly';

const VariableTrendContainer: React.FC<{
  filters: any;
  index;
  originalResponse;
  productdcElasticities;
  regionElasticities;
}> = ({ filters, originalResponse, productdcElasticities, regionElasticities, index }) => {
  const [skeleton, setSkeleton] = useState(true);
  const [chartTableData, setTableData] = useState<any>({});
  const classes = useStyles();

  const formatChartData = (product) => {
    try {
      let chartData: any = [];
      let record: any = [];
      let chartType = [
        { type: 'column', color: '#38ad6f' },
        { type: 'area', color: '#fc9e66' },
        { type: 'area', color: '#6FBAE3' },
        { type: 'column', color: '#0186e0' },
      ];
      let modelVariables: any = [];

      if (filters.dataSource === 'inase') {
        modelVariables = ['units', 'sales', 'volume'];
      } else {
        modelVariables = ['units', 'vpu', 'sales', 'volume'];
      }
      if (product['weeklyData'] !== null) {
        modelVariables = [...modelVariables, ...Object.keys(product['weeklyData'])];
      }
      chartData = [];
      modelVariables.map((key, i) => {
        record = [];
        // record.push(key);
        if (filters.dataSource === 'inase') {
          if (key !== 'units' && key !== 'volume' && key !== 'sales') {
            chartData.push({
              type: 'area', //chartType[i % chartType.length].type,
              color: chartType[i % chartType.length].color,
              title: product[key].label,
              pricing: product['weeklyData'][key].findIndex((x) => x.date === product.pricingDate),
              data: product['weeklyData'][key].map(({ date: name, value: y, ...rest }) => ({ name, y, ...rest })),
              // ...product.outlier? { outlier: product["weeklyData"][key].map(x=> x.date === '2021-12-24'? getMaxPoint(product["weeklyData"][key]).value : null)} : {}
            });
          }
        } else {
          if (key !== 'units' && key !== 'vpu' && key !== 'distribution' && key !== 'volume' && key !== 'sales') {
            chartData.push({
              type: chartType[i % chartType.length].type,
              color: chartType[i % chartType.length].color,
              title: product[key].label,
              pricing: product['weeklyData'][key].findIndex((x) => x.date === product.pricingDate),
              data: product['weeklyData'][key].map(({ date: name, value: y, ...rest }) => ({ name, y, ...rest })),
              // ...product.outlier? {outlier: product["weeklyData"][key].map(x=> x.date === '2021-12-24'? getMaxPoint(product["weeklyData"][key]).value : null)} : {}
            });
          }
        }
      });
      if (filters.dataSource === 'sellOut') {
        const i = chartData.findIndex((item) => item.title === 'PPU');
        if (i !== -1 && i !== 0) {
          const ppu = chartData.splice(i, 1);
          chartData.unshift(ppu[0]);
        }
      }
      return {
        image: product.image,
        imageHeight: 100,
        imageWidth: 100,
        name: product.name,
        parentName: product.parentName,
        chartData,
      };
    } catch (error) {
      setSkeleton(false);
    }
  };

  const getMaxPoint = (data) => {
    return _.maxBy(data, function (o: any) {
      return o.value;
    });
  };

  const onSelectPeriodicity = (item) => {
    if (item && item?.item && Object.keys(originalResponse).length > 0) {
      const payload = {
        ..._.omit(originalResponse, 'weekly', 'monthly', 'quarterly', 'yearly'),
        weeklyData: originalResponse[item.item],
        // outlier: true
      };
      const formattedData = { ...formatChartData(payload) };
      setTableData(formattedData);
    }
  };

  useEffect(() => {
    if (Object.keys(originalResponse).length > 0) {
      const payload = {
        ..._.omit(originalResponse, 'weekly', 'monthly', 'quarterly', 'yearly'),
        weeklyData: originalResponse[defaultPeriodicity],
        // outlier: true
      };
      const formattedData = { ...formatChartData(payload) };
      setTableData(formattedData);
      setSkeleton(false);
    }
  }, [originalResponse]);

  return (
    <Grid container className={'m-b-20 m-t-20'}>
      {Object.keys(chartTableData).length > 0 ? (
        <Grid container className='m-t-10'>
          <Grid item sm={6.8}>
            <Title className='ellipsis'>Model Variable Trend</Title>
            <Grid
              style={{
                border: '3px solid #cad0d8',
                borderRadius: 10,
                padding: 20,
                position: 'relative',
              }}
            >
              <Grid
                style={{
                  position: 'absolute',
                  bottom: 20,
                  width: '100%',
                  left: 15,
                  zIndex: 2,
                }}
                className={classes.hide}
              >
                <IncrementDecrement
                  data={originalResponse}
                  callback={(data) => {
                    onSelectPeriodicity(data);
                  }}
                  id={'variable-trend-container-multiple-chart' + index}
                  defaultOption={
                    (localStorage.getItem('periodicity') &&
                      JSON.parse(localStorage.getItem('periodicity'))['variable-trend-container-multiple-chart' + index]?.item) ||
                    null
                  }
                />
              </Grid>
              {chartTableData.chartData.length > 0 ? (
                chartTableData.chartData.map((item, j) => (
                  <MultipleChart
                    chartType={item.type}
                    chartColor={item.color}
                    data={item.data}
                    axisTitle={{ x: item.title, y: item.title }}
                    pricing={item.pricing}
                    id={`multiplechart-${j}`}
                    xAxisLabel={j === chartTableData.chartData.length - 1}
                    outlier={item.outlier}
                  />
                ))
              ) : (
                <Box
                  style={{
                    height: 100,
                    display: 'table-cell',
                    verticalAlign: 'middle',
                  }}
                >
                  <NoData>{messages.noData}</NoData>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item sm={0.2}></Grid>
          <Grid item sm={5}>
            {/* {
                productdcElasticities && productdcElasticities.dcElasticities && productdcElasticities.dcElasticities.length ?
                  <>
                    <Title className="ellipsis">DC Elasticities</Title>
                    <DcsElasticitesTable
                      data={productdcElasticities.dcElasticities || []}
                    />
                  </>
                  : null
              } */}

            {regionElasticities && regionElasticities?.regionCalculation?.length ? (
              <>
                <Grid style={{ marginTop: 30 }}>
                  <Title className='ellipsis'>Region Elasticities</Title>
                  <RegionElasticitiesTable data={regionElasticities || []} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} className='m-t-10'>
          <Title className='ellipsis'>Model Variable Trend</Title>
          <Grid container display='flex' justifyContent='center' alignItems='center' height={400}>
            <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default VariableTrendContainer;
