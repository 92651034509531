import MuiToolbar from '@mui/material/Toolbar';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { drawerWidth } from '../../Layout.Styled';
import { ListItem } from '@mui/material';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main 
}));

const Avatar = styled(MuiAvatar)`
   && { 
       margin: 0 10px 0 15px;
    }
`;

const ListItemButton = styled(ListItem)`
&:hover {
    color: #007FE0;
    cursor: pointer;
  }
`;


export {
    Toolbar,
    Avatar,
    AppBar,
    ListItemButton
}