import { SELLOUT_PRICE_VS_SUGGESTED_PRICE_TABLE, SOP_VS_SRP} from './constants';

export const selloutPriceVsSuggestedPriceTableDataLoader = (payload) => {
  return {
    type: SELLOUT_PRICE_VS_SUGGESTED_PRICE_TABLE,
    payload,
  };
};

export const sopVsSrpDataLoader = (payload) => {
  return {
    type: SOP_VS_SRP,
    payload,
  };
};
