import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Dashboard from "../../components/Dashboard";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
  chartConfig,
  pieChartConfig,
  mixChartConfig,
  columnDrilldownConfig,
  waterfallConfig,
  stackCloumnConfig,
  scrollbarChart,
  bubbleChartConfig,
} from "./chartsConfig";
import HighchartContainer from "../../components/HighchartContainer";
import PageContainer from "../../components/PageContainer/PageContainer";
import { pageNames } from "../../mocks/common";

const MMPWTest: React.FC = () => {
  const chartRef = React.useRef(null);
  const data = [
    {
      id: "mmpw-test-line-chart",
      title: "Line Chart",
      type: "line",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-spline-chart",
      title: "Spline Chart",
      type: "spline",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-bar-chart",
      title: "Bar Chart",
      type: "bar",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-column-chart",
      title: "Column Chart",
      type: "column",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-mix-chart",
      title: "Mix Chart",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-column-drilldown-chart",
      title: "Column Drilldown Chart",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-waterfall-chart",
      title: "Waterfall Chart",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-stacked-column",
      title: "Stacked columns",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-bar-scroll",
      title: "Scroll bar",
      chartRef: React.useRef(null),
    },
    {
      id: "mmpw-test-bubble-chart",
      title: "Bubble Chart",
      chartRef: React.useRef(null),
    },
  ];

  useEffect(()=>{
    const ref = data[8].chartRef;
    if(ref && ref.current && ref.current.chart){
      const chart = ref.current.chart;
      let xAxis = chart.xAxis[0];
      xAxis.setExtremes(xAxis.max +20);
    }
  },[data[8].chartRef])

  useEffect(() => {
    if (data[0].chartRef) {
      if (
        data[0].chartRef &&
        data[0].chartRef.current &&
        data[0].chartRef.current.chart &&
        data?.length > 0
      ) {
        const chart = data[0].chartRef.current.chart;
        chart.yAxis[0].addPlotLine({
          color: "green",
          width: 2,
          value: 350,
          dashStyle: "ShortDash",
          zIndex: 1,
          type: "line",
          id: "1",
        });

        chart.xAxis[0].addPlotLine({
          color: "#d41165",
          width: 2,
          value: 3,
          dashStyle: "ShortDash",
          zIndex: 1,
          type: "line",
          id: "2",
        });

        chart.xAxis[0].addPlotLine({
          color: "black",
          width: 2,
          value: 5,
          dashStyle: "ShortDash",
          zIndex: 1,
          type: "line",
          id: "3",
        });
      }
    }
  }, [data[0].chartRef]);

  return (
    <PageContainer page={pageNames.mmpwTest}>
      <Grid>
        {data.map((item) => (
          <Grid container className="m-b-20">
            <Dashboard
              title={item.title}
              showSkeleton={false}
              chartRef={item.chartRef}
              id={item.id}
            >
              <HighchartContainer chartRef={item.chartRef} id={item.id}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={
                    item.id === "mmpw-test-mix-chart"
                      ? { ...mixChartConfig }
                      : item.id === "mmpw-test-column-drilldown-chart"
                      ? { ...columnDrilldownConfig }
                      : item.id === "mmpw-test-stacked-column"
                      ? { ...stackCloumnConfig }
                      : item.id === "mmpw-test-waterfall-chart"
                      ? { ...waterfallConfig }
                      : item.id === "mmpw-test-bar-scroll"
                      ? {...scrollbarChart}
                      : item.id === "mmpw-test-bubble-chart"
                      ? {...bubbleChartConfig}
                      : {
                          ...chartConfig,
                          chart: {
                            type: item.type,
                          },
                        }
                  }
                  ref={item.chartRef}
                />
              </HighchartContainer>
            </Dashboard>
          </Grid>
        ))}
        <Grid container className="m-b-40">
          <Dashboard
            title="Pie Chart"
            showSkeleton={false}
            chartRef={chartRef}
            id="mmpw-test-pie-chart"
          >
            <HighchartContainer chartRef={chartRef} id={"mmpw-test-pie-chart"}>
              <HighchartsReact
                highcharts={Highcharts}
                options={pieChartConfig}
                ref={chartRef}
              />
            </HighchartContainer>
          </Dashboard>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default MMPWTest;
