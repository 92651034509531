import { Card, CardContent, Grid } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { fetchPriceElasticityAndModelData } from '../../service';
import { priceElasticityDataLoader } from '../../store/historicPrice.action';
import Donut from './components/Donut';
import ModelActualVsPredicted from './components/ModelActualVsPredicted';
import PriceElasticity from './components/PriceElasticity';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  hide: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const PriceElasticityAndModelContainer: React.FC<{ filters }> = ({ filters }) => {
  const classes = useStyles();
  const [priceElasticityColumnData, setPriceElasticityColumnData] = useState({});
  const [gramElasticityColumnData, setGramElasticityColumnData] = useState({});
  const [modelActualPredictedData, setModelActualPredictedData] = useState({});
  const [originalResponse, setOriginalResponse] = useState<any>({});
  const [donutData, setDonutData] = useState({});
  const [skeleton, setSkeleton] = useState(true);
  const dispatch = useDispatch();

  const getPriceElasticityandModelData = () => {
    setSkeleton(true);
    setOriginalResponse({});
    fetchPriceElasticityAndModelData({ ...filters })
      .then((response) => {
        if (response?.data && response?.data && response?.data?.predictedActual && response?.data?.macroEconVariables) {
          dispatch(priceElasticityDataLoader(false));
          setOriginalResponse(response.data);
          setPriceElasticityColumnData(
            formatElasticityData(
              response.data?.priceAndGramsElasticity?.priceElasticity,
              'priceElasticity',
              response.data?.priceAndGramsElasticity?.isImputed
            )
          );
          setGramElasticityColumnData(
            formatElasticityData(
              response.data?.priceAndGramsElasticity?.gramElasticity,
              'gramElasticity',
              response.data?.priceAndGramsElasticity.isImputedGE
            )
          );
          formatDonutData(response.data.macroEconVariables);
          setSkeleton(false);
        } else {
          setSkeleton(false);
          dispatch(priceElasticityDataLoader(false));
        }
      })
      .catch((e) => {
        setSkeleton(false);
      });
  };

  const formatElasticityData = (response, elasticityType, isImputed) => {
    // const setColor = (item) => {
    //   if (item.label == 'Average') {
    //     return elasticityType === 'priceElasticity' ? '#FFA07A' : '#67BC42';
    //   } else if (item.label == 'Difference') {
    //     return item.value < 0 ? '#FF0000' : '#67BC42';
    //   } else {
    //     return elasticityType === 'priceElasticity' ? '#3B97B2' : '#67BC42';
    //   }
    // };

    const barData = response.map((item) => {
      return {
        name: item.label !== 'Difference' ? item.label : 'Difference (VS Prior period)',
        y: item.value,
        label: item.value,
        // color: setColor(item),
      };
    });

    const title =
      filters && filters.anchorPackSize
        ? `${filters.anchorSubBrand} ${filters.anchorPackSize}`
        : filters.anchorSubBrand
        ? filters.anchorSubBrand
        : filters?.anchorBrand;
    return {
      title,
      barData,
      isImputed,
    };
  };

  const formatDonutData = (response) => {
    const variables = response.variables || {};

    const rSquareValue = [
      {
        name: '',
        y: parseFloat((response.rSquare * 100).toFixed(2)),
        enableTooltip: true,
      },
      {
        name: '',
        y: parseFloat((100 - response.rSquare * 100).toFixed(2)),
        enableTooltip: false,
      },
    ];
    setDonutData({
      chartTitle: response.isImputed ? 'Imputed' : 'Actual',
      title: response.label,
      rSquareValue,
      variables,
    });
  };

  useEffect(() => {
    if (filters && filters.country) {
      setPriceElasticityColumnData({});
      setModelActualPredictedData({});
      setDonutData({});
      getPriceElasticityandModelData();
    }
  }, [filters]);

  // useEffect(()=>{
  // getPriceElasticityandModelData();

  // },[])

  const setModelActualPredictedResponse = (selectedDataIndex) => {
    if (
      originalResponse &&
      Object.keys(originalResponse).length > 0 &&
      Object.keys(originalResponse.predictedActual[selectedDataIndex.item]).length > 0 &&
      originalResponse.predictedActual[selectedDataIndex.item].predicted.length > 0 &&
      Object.keys(originalResponse.macroEconVariables).length > 0
    ) {
      setModelActualPredictedData({
        basicUnits: originalResponse.predictedActual[selectedDataIndex.item].basicUnits,
        predicted: originalResponse.predictedActual[selectedDataIndex.item].predicted,
        actual: originalResponse.predictedActual[selectedDataIndex.item].actual,
        y2DataPoints: originalResponse.predictedActual[selectedDataIndex.item].y2DataPoints,
        y1DataPoints: originalResponse.predictedActual[selectedDataIndex.item].y1DataPoints,
        title: originalResponse.macroEconVariables.label,
        yAxisTitle: filters.priceFormula === 'ppu' ? 'Units' : 'Volume',
        chartTitle: originalResponse.predictedActual[selectedDataIndex.item].isImputed ? 'imputed' : 'actual',
      });
    }
  };

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <PriceElasticity
              skeleton={skeleton}
              data={{
                priceElasticityColumnData: priceElasticityColumnData,
                gramElasticityColumnData: gramElasticityColumnData,
              }}
            />
          </Grid>
          <Grid item md={5}>
            {originalResponse && Object.keys(originalResponse).length > 0 && (
              <Grid
                style={{
                  position: 'absolute',
                  marginTop: '460px',
                  paddingRight: '550px',
                  zIndex: 5,
                }}
                className={classes.hide}
              >
                <IncrementDecrement
                  data={originalResponse.predictedActual}
                  callback={setModelActualPredictedResponse}
                  id='historic-price-track-model-actual-predicted'
                  defaultOption={
                    (localStorage.getItem('periodicity') &&
                      JSON.parse(localStorage.getItem('periodicity'))['historic-price-track-model-actual-predicted']?.item) ||
                    null
                  }
                />
              </Grid>
            )}
            <ModelActualVsPredicted skeleton={skeleton} data={{ ...modelActualPredictedData }} />
          </Grid>
          <Grid item md={2}>
            <Donut skeleton={skeleton} data={{ ...donutData }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PriceElasticityAndModelContainer;
