import mocksData from "../../../../mocks/assortmentMocks";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubSummaryPaper } from "./AssortmentSummary.Styled";
import { useSelector } from "react-redux";
import SummaryCard from "../../../../components/SummaryCard/SummaryCard";
import Indicator from "../../../../components/Loader/Loader";
import Skeletons from "../../Skeletons/Skeletons";

const Summary = () => {
  const [SummaryCardData, setSummaryCardData] = useState([
    ...mocksData.SummaryCardData,
  ]);

  const { loaderForSummaryData, summaryData, viewMode } = useSelector(
    (state) => state.assortmentDashboardReducer
  );

  useEffect(() => {
    if (summaryData) {
      const updatedData = [...SummaryCardData];
      const mapping = {
        "Store Universe": "storeUniverse",
        "Total SKUs Sold": "totalSKUSold",
        "Recommended Must have SKU": "mustHaveSKU",
        "Recommended Good to have SKU": "goodToHaveSKU",
      };

      updatedData.forEach((item) => {
        const key = mapping[item.title];
        if (key) {
          item.value = summaryData[key];
        }
      });

      setSummaryCardData(updatedData);
    }
  }, [summaryData]);

  const handleSummaryData = () => {
    try {
      const response = assortsummarySectionData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid style={{ marginTop: "20px" }}>
        <Typography
          variant="h5"
          align="center"
          style={{
            color: "white",
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Summary
        </Typography>
      </Grid>

      <Grid
        style={{
          height: "150px",
          position: "relative",
        }}
        item
        xs={12}
      >
        <Indicator position="absolute" show={loaderForSummaryData} />

        <SubSummaryPaper elevation={3}>
          <Grid item>
            {!viewMode ? (
              <Skeletons type={"Summary"} />
            ) : (
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
              >
                {SummaryCardData.map((item: any) => {
                  return (
                    <>
                      <SummaryCard
                        title={item.title}
                        icon={item.icon}
                        value={item.value}
                        iconHeight={34}
                      />
                    </>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </SubSummaryPaper>
      </Grid>
    </Grid>
  );
};

export default Summary;
