import React, { useEffect, useState } from "react";
import mocksData from "../../../../mocks/assortmentMocks";
import { CardContent, Grid, Skeleton } from "@mui/material";
import listTableStyles from "./AssortmentListTableSKU.Styled";
import { LightBlueBtn } from "../../../../styles/Common.Styled";
import { StyledCard } from "../../../../components/MMPW/MMPW.Styled";
import { useSelector } from "react-redux";
import { StyledTypography } from "../../AssortmentTool.Styled";
import Indicator from "../../../../components/Loader/Loader";
import { columnsToDowloadListTableSKU } from "./AssortmentListTableConfig";
import { DownloadIcon } from "../../../../assets/icons/assorticons";
import {
  convertToCSVWithKeys,
  downloadInExcelCsvFormat,
} from "../CommonComponents/utils";
import SkuTable from "../CommonComponents/SkuTable/SkuTable";

const AssortmentListTableSKU = () => {
  const { assortmentData, viewMode } = useSelector(
    (state: any) => state.assortmentDashboardReducer
  );
  const [tableValues, setTableValues] = useState([
    ...mocksData.tableSkeletonData,
  ]);
  const classes = listTableStyles;

  useEffect(() => {
    if (assortmentData) {
      setTableValues(assortmentData["assortmentData"]);
    }
  }, [assortmentData]);

  const { loaderForListTable } = useSelector(
    (state: any) => state.assortmentDashboardReducer
  );

  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

  const downloadAssortmentTableData = () => {
    const keysArray = columnsToDowloadListTableSKU.map(
      (column) => column.field
    );

    const csvData = convertToCSVWithKeys(
      assortmentData["assortmentData"],
      keysArray,
      columnsToDowloadListTableSKU
    );
    downloadInExcelCsvFormat(csvData, "Assortment_SKU_Table_Data");
  };

  return (
    <Grid>
      <Grid
        container
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Grid item xs={12} md={6} style={{}}>
          <StyledTypography>Assortment List</StyledTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {!viewMode ? (
            <>
              <Skeleton variant="rectangular" width={170} height={30} />
            </>
          ) : (
            <>
              <LightBlueBtn onClick={downloadAssortmentTableData}>
                <img style={{ marginRight: "5px" }} src={DownloadIcon} alt="" />
                Download
              </LightBlueBtn>
            </>
          )}
        </Grid>
      </Grid>
      <StyledCard style={{ marginTop: "20px" }}>
        <CardContent style={{ background: "#F1F5FE", position: "relative" }}>
          <Indicator position="absolute" show={loaderForListTable} />
          {!viewMode ? (
            <Grid style={{ marginTop: "15px" }} container spacing={2}>
              <Skeleton variant="rectangular" width="100%" height={145} />
            </Grid>
          ) : (
            <Grid>
              <div
                style={{
                  backgroundColor: "#F1F5FE",
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <Grid style={{ marginTop: "20px" }}>
                  <SkuTable rowData={tableValues} />
                </Grid>
              </div>
            </Grid>
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

export default AssortmentListTableSKU;
