import { BRAND_LADDER_MODEL, BRAND_LADDER_LOCAL_FILTER } from './constants';

export const brandLadderDataLoader = (payload) => {
  return {
    type: BRAND_LADDER_MODEL,
    payload,
  };
};

export const brandLadderLocalFilter = (payload) =>{
  return {
    type: BRAND_LADDER_LOCAL_FILTER,
    payload
  }
}
