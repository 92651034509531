import React, { useEffect } from 'react';
import DashboardActions from '../../components/DashboardActions';
import PackPriceIndexPiano from './components/PackPriceIndexPiano';
import TopFilters from './components/TopFilters/TopFilters';
import ShareOfMarketPiano from './components/ShareOfMarketPiano';
import { pageList } from '../../mocks/common';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { removeMMPWFromLocal } from '../../util/helper';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const PriceRangePiano: React.FC<{}> = () => {
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [localFilters, setLocalFilters] = React.useState<any>({});
  const [defaultFilters, setDefaultFilters] = React.useState<any>({});
  const [disableDownloadButton, setDisableDownloadButton] = React.useState(true);
  const [chartPeriodicity, setChartPeriodicity] = React.useState<any>();

  const onApplyFilter = (data) => {
    if (data && data.filters) {
      setDisableDownloadButton(false);
      removeMMPWFromLocal('price-range');
      setFilters(data.filters);
    }
    setDefaultFilters({});
  };

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      let savedDashboardData: any = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setFilters(savedDashboardData?.filters?.global);
      setLocalFilters(savedDashboardData?.filters?.local);
    }
  };

  const PricePianoDataLoader = useSelector((state: any) => state.PricePianoDataLoader);

  const isDisabled: boolean = false;
  return (
    <>
      <DashboardActions
        title='Price Range Piano'
        disabled={isDisabled}
        data={{
          filters: {
            global: { ...appliedFilters },
            local: { ...localFilters },
          },
          mmpwKey: 'price-range',
        }}
        dashboardKey={pageList[13].id}
        callback={getSaveDashBoardsData}
        disableDownloadButton={disableDownloadButton || PricePianoDataLoader.pricePianoDataLoader}
      >
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[14].value} />
        </Grid>
        <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} />
        <ShareOfMarketPiano filters={appliedFilters} defaultFilters={defaultFilters} />
        <PackPriceIndexPiano
          callback={setLocalFilters}
          filters={appliedFilters}
          defaultFilters={{
            global: { ...defaultFilters },
            local: { ...localFilters },
          }}
        />
        <DashboardSourceList dashboardName={pageList[13].id} appliedFilters={appliedFilters} category={appliedFilters?.pepsi?.category} />
      </DashboardActions>
    </>
  );
};

export default PriceRangePiano;
