import React, { FC,useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Box} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";
import { userLogin } from "../../../../store/actions/ExecutionTool/executionOverview.action"
import { SCENARIO_LIBRARY } from "../../../../router/CONSTANTS";

const useStyles = makeStyles((theme) => ({
  abc: {
      backgroundColor: "#fff",
      lineHeight: '5rem'
  }
}));

export interface LoginFormValues {
  email: string;
  password: string;
}

const UserLogin: React.FC = ()  => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string()
      .test('match-username', 'Username does not match', function (value) {
        return value === 'demouser';
      })
      .required(),
    password: yup
      .string()
      .required()
      .test('match-password', 'Password does not match', function (value) {
        return value === '#45tduke';
      }),
  });
  
    const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          sessionStorage.setItem('authenticatedUser','true')
          dispatch(userLogin(true))
          history.push(SCENARIO_LIBRARY);
        }
      });
    
    return (
        <Box  display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="70vh"
        className={classes.abc}>
          <form onSubmit={formik.handleSubmit}>
            <TextField InputProps={{ autoComplete: 'off' }}
              fullWidth
              id="email"
              name="email"
              label="Username"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              placeholder="Enter username"
            />
            <TextField InputProps={{ autoComplete: 'off' }}
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"              
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              style={{marginBottom:10}}
              placeholder="Enter password"
            />
            <Button color="primary" variant="contained"  type="submit" fullWidth size='medium'>
              Login
            </Button>
          </form>
        </Box>
      );
}

export default UserLogin

