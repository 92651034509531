import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import AssortmentDropdown from '../../../../components/AssortmentDropdown/AssortmentDropdown';
import { DropdownTitle } from '../../../../components/GeoFilters-V4/GeoFilters-V4.Styled';
import { useSelector } from 'react-redux';

interface props {
    filtersConfig: any;
    onChangeFilter: any
}

const DynamicFilter: React.FC<props> = ({
    filtersConfig,
    onChangeFilter
}) => {
    const {selectedFilters,isEditMode} = useSelector((state:any)=>state.mixSimulationAllFilter)
    const [level, setLevel] = useState('');
    const onChangeGeoLevel = (key, data) => {
        if (key.toLowerCase() === "level") {
            setLevel(data[0]);
        }
        // console.log("onChangeGeoLevel",key);
        // console.log("onChangeGeoLevel",data);
        onChangeFilter(key, data)
    }

    const isMultiple = (title): boolean => {
        console.log("onChangeGeoLevel", title);
        console.log("onChangeGeoLevel", level);
        if (title === "level") {
            return false
        } else if (title === "channel") {
           if(isEditMode){
            if (selectedFilters.level[0].toLowerCase() === "within channel") {
                return false;
            } else {
                return true
            }
           }else{
            if (level.toLowerCase() === "within channel") {
                return false;
            } else {
                return true
            }
           }
        } else {
            return true;
        }
    }

    return (
        <Grid container spacing={8}>
            {
                Object.entries(filtersConfig).map(([filterKey, configOfFilter]: any, index) => (
                    <Grid item key={`${filterKey}-${index}-${filterKey}`}>
                        <DropdownTitle className='m-b-10'>{configOfFilter.title} <span style={{ color: 'red' }}>*</span> </DropdownTitle>
                        <AssortmentDropdown
                            data={configOfFilter.options}
                            multiple={isMultiple(configOfFilter.title.toLowerCase())}
                            allOption={isMultiple(configOfFilter.title.toLowerCase())}
                            defaultOption={configOfFilter.defaultOption}
                            onChange={(selectionData) => onChangeGeoLevel(filterKey, selectionData)}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default DynamicFilter