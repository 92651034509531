import { formatNumber } from '../../util/helper';

const CorrelationAnalysisChartConfig = {
  chart: {
    backgroundColor: 'transparent',
    type: 'spline',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    min: 0,
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      opposite: true,
      title: {
        text: 'API',
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: '#FFFFFF',
        lineWidth: 2,
        lineColor: null,
        symbol: 'circle',
      },
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: '#598ee3',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: 'API',
      color: '#d41165',
      data: [],
      yAxis: 1,
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const LinearRegressionLineChartConfig = {
  chart: {
    backgroundColor: 'transparent',
    type: 'spline',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    gridLineWidth: 1,
    lineColor: 'transparent',
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      lineColor: 'transparent',
      title: {
        text: 'Volume',
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 1,
      gridLineWidth: 1,
      lineColor: 'transparent',
      opposite: true,
      title: {
        text: '',
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: '#FFFFFF',
        lineWidth: 2,
        lineColor: null,
        symbol: 'circle',
      },
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      name: 'Real Volume',
      color: '#598ee3',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: 'Est Volume',
      color: '#d41165',
      data: [],
      yAxis: 1,
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const ParabolaSimulationConfig = {
  chart: {
    backgroundColor: 'transparent',
    type: 'spline',
    height: '500px',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    gridLineWidth: 0,
    lineColor: 'transparent',
    categories: [],
    title: {
      style: {
        fontSize: 18,
        fontWeight: 600,
        marginTop: 20,
      },
    },
  },
  yAxis: [
    {
      lineWidth: 0,
      gridLineWidth: 1,
      lineColor: 'transparent',
      title: {
        text: 'Direct Profit',
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      lineWidth: 0,
      gridLineWidth: 0,
      lineColor: 'transparent',
      opposite: true,
      title: {
        text: 'Sell Out',
        style: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      name: 'Sell Out',
      color: '#598ee3',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: 'Direct Profit',
      color: '#d41165',
      data: [],
      yAxis: 1,
      labels: {
        formatter: function () {
          return formatNumber(this.y);
        },
      },
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

export { CorrelationAnalysisChartConfig, LinearRegressionLineChartConfig, ParabolaSimulationConfig };
