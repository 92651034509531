import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import Dropdown from "../../../components/Dropdown";
import { EditBtn } from "./UserSetting.Styled";
import { PrimaryBtn } from "../../../styles/Common.Styled";
import { checkValidation } from "../../../util/validation";
import { allCountries } from "../../../util/config";
import { checkPermission } from "../../../util/helper";

const UserInfo: React.FC<{
  data: object;
  updateHandler?: Function;
  saveUserHandler?: Function;
  cancelUserHandler?: Function;
}> = (props) => {
  const { label, displayValue, data } = props.data;
  const [errorMessage, setErrorMessage] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage(null);
  };

  const onkeydown = (value, key) => {
    const validation = checkValidation({ value, key });
    if (!validation.valid) {
      const oldError = {
        ...errorMessage,
        [key]: validation,
      };
      setErrorMessage(oldError);
    } else {
      const oldError = {
        ...errorMessage,
      };
      delete oldError[key];
      setErrorMessage(oldError);
    }
  };

  const getField = (item) => {
    switch (item?.type) {
      case "text":
        return (
          <>
            <TextField InputProps={{ autoComplete: 'off' }}
              className="w-100"
              onChange={(e) => {
                props.updateHandler({
                  key: item.key,
                  value: e.target.value,
                });
                onkeydown(e.target.value, item.key);
              }}
              variant="outlined"
              value={item.value}
            />
          </>
        );
      case "phone":
        return (
          <Grid container xs={12}>
            <Grid item xs={3} sm={3} md={3} className="m-r-10">
              <TextField InputProps={{ autoComplete: 'off' }}
                className="w-100"
                onChange={(e) => {
                  props.updateHandler({
                    key: item.key[0],
                    value: e.target.value,
                  });
                }}
                onKeyUp={(e) => onkeydown(e.target.value, item.key[0])}
                variant="outlined"
                value={item.value}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <TextField InputProps={{ autoComplete: 'off' }}
                className="w-100"
                onChange={(e) => {
                  props.updateHandler({
                    key: item.key[1],
                    value: e.target.value,
                  });
                }}
                onKeyUp={(e) => onkeydown(e.target.value, item.key[1])}
                variant="outlined"
                value={item.value2}
              />
            </Grid>
          </Grid>
        );
      case "dropdown":
        return (
          <Dropdown
            onChange={(data) => {
              if (data[0]) {
                props.updateHandler({
                  key: item.key,
                  value: data.join(","),
                });
              }
            }}
            placeholder={item.value}
            defaultOption={item.value && item.value.split(",")}
            data={item.availableValues}
            multiple={false}
            search={{ enable: false }}
          ></Dropdown>
        );
      case "auto-complete":
        return (
          <FormControlLabel
            style={{ width: "100%" }}
            value={item.value}
            label=""
            control={
              <Autocomplete
                style={{ width: "100%" }}
                className="auto-complete"
                options={allCountries}
                onChange={(e, newValue) => {
                  props.updateHandler({
                    key: item.key,
                    value: newValue.label
                  });
                }}
                renderInput={(params) => <TextField sx={{ paddingTop: '2px' }} {...params} />}
              />
            }></FormControlLabel>
        )
      default:
        return null;
    }
  };

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={4}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={10} sm={6}>
          <Typography>{displayValue}</Typography>
        </Grid>
        <Grid item xs={2} sm={2}>
          {checkPermission('user_setting_edit') && props.updateHandler && <EditBtn onClick={handleOpen}>edit</EditBtn>}
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="m-10"
        >
          <Grid item xs={8} sm={6} md={4}>
            <Card>
              <CardContent>
                {data.map((item) => {
                  return (
                    <Grid item xs={12} className="m-b-10">
                      <Typography padding="3px 2px" color="#555A60">
                        {item.label}
                      </Typography>
                      {getField(item)}
                      {errorMessage &&
                        Object.keys(errorMessage).map((key) => (
                          <Box style={{ color: "red", fontStyle: "italic" }}>
                            {errorMessage[key].error}
                          </Box>
                        ))}
                    </Grid>
                  );
                })}
                <PrimaryBtn
                  className="m-t-10 m-r-20"
                  onClick={() => {
                    props.saveUserHandler();
                    handleClose();
                  }}
                  disabled={
                    errorMessage &&
                    Object.keys(errorMessage).findIndex(
                      (key) => !errorMessage[key].valid
                    ) !== -1
                  }
                >
                  Save
                </PrimaryBtn>
                <PrimaryBtn
                  className="m-t-10"
                  onClick={() => {
                    props.cancelUserHandler();
                    handleClose();
                  }}
                >
                  Cancel
                </PrimaryBtn>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Modal>

      <Divider className="m-b-40" />
    </Box>
  );
};

export default UserInfo;
