import { Box, Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import { SimulationRadio } from "../../../../mocks/elasticityPricingTrack";
import SimulationChart from "./components/SimulationChart";
import { getImageUrl, getSimulationDetailsData } from "../../service";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { NoData } from "../../../../styles/Common.Styled";
import { messages } from "../../../../util/config";
import VariableTrendContainer from "./components/VariableTrendContainer";
import Dashboard from "../../../../components/Dashboard";

const useStyles = makeStyles(() => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameBox: { background: "gray", border: "1px solid #efefef", color: "#fff" },
}));

const ModelDetails: React.FC<{ filters; defaultFilters; callback }> = ({ filters, defaultFilters, callback }) => {
  const classes = useStyles();
  const [simulationButtons, setSimulationButtons] = React.useState(SimulationRadio.defaultOption.value);
  const [data, setData] = useState<any>([]);
  const [skeleton, setSkeleton] = useState(true);
  const [radioSkeleton, setRadioSkeleton] = useState(true);

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0) {
      setSimulationButtons(defaultFilters.simulationDetail ? defaultFilters.simulationDetail : SimulationRadio.defaultOption.value);
    }
  }, [defaultFilters]);

  const getSimulationDetails = () => {
    getSimulationDetailsData({
      ...filters,
      filtervisual4units: simulationButtons,
    })
      .then((res) => {
        if (res.status === 200 && res.data.brands && res.data.brands.length > 0) {
          const brands = res.data.brands.map((brand) => {
            const index = res.data.variableTrends.findIndex((v) => v?.name === brand?.name);
            if (index !== -1) {
              const variableTrend = res.data.variableTrends[index] || [];

              const productdcElasticities = res.data.productdcElasticities.find((v) => v?.productName === brand?.name);
              Object.entries(variableTrend).forEach(([k, v]) => {
                if (_.isObject(v)) {
                  Object.keys(v).map((key) => {
                    if (v[key] === null) {
                      delete v[key];
                    }
                  });
                } else if (v === null) {
                  delete variableTrend[k];
                }
              });
              
              let regionElasticities = {};
              if (res.data.regionElasticities && Object.keys(res.data.regionElasticities).length > 0)
                regionElasticities = res.data.regionElasticities.find((r) => r?.productName === brand?.name) || {};

              return {
                ...brand,
                variableTrend,
                regionElasticities: regionElasticities
                  ? {
                      ...regionElasticities,
                    }
                  : {},
                productdcElasticities: productdcElasticities ? { ...productdcElasticities } : {},
              };
            }
            return brand;
          });
          let response = [...brands, res.data.allBrands];
          if (_.isArray(response)) {
            Promise.all(
              response?.map(async (product: any, i) => {
                product.keyEvents = res.data.simulationKeyEventsResponses;
                if (product.imageName) {
                  product.image = await getImageUrl(filters.country, product.imageName);
                } else {
                  i === response.length - 1
                    ? (product.image = await getImageUrl(filters.country, "All Product(Weighted Average)"))
                    : (product.image = "");
                }
              })
            )
              .then(() => {
                setData([...response]);
                setSkeleton(false);
                setRadioSkeleton(false);
              })
              .catch((e) => {
                setSkeleton(false);
                setRadioSkeleton(true);
              });
          }
        } else {
          setSkeleton(false);
          setRadioSkeleton(true);
        }
      })
      .catch((e) => {
        setSkeleton(false);
        setRadioSkeleton(true);
      });
  };

  useEffect(() => {
    if (filters && filters.country && simulationButtons) {
      setData([]);
      setSkeleton(true);
      getSimulationDetails();
      callback(simulationButtons);
    }
  }, [simulationButtons]);

  useEffect(() => {
    if (filters && filters.country) {
      setData([]);
      setSkeleton(true);
      getSimulationDetails();
    }
  }, [filters]);



  const Container = ({ item, i }) => {
    const chartRef = React.useRef(null);
    return (
      <Dashboard id={`elasticity-pricing-track-simulation-detail-chart1-${i}`} chartRef={chartRef} actionButtons={{ screenshot: true }}>
        <Grid container spacing={2} className="m-b-20 m-t-20">
          <Grid item sm={1} className={classes.center}>
            <Box style={{ display: "table", height: "100%" }}>
              <Box
                style={{
                  position: "relative",
                  display: "table-cell",
                  verticalAlign: "middle",
                }}
              >
                {item.image ? (
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: "50%",
                    }}
                    src={item.image}
                  />
                ) : (
                  <Grid
                    className={classes.nameBox}
                    style={{
                      height: 100,
                      width: 100,
                      display: "table",
                    }}
                  >
                    <Box
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: 10 }}>{item.name}</span>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={11}>
            <Grid container>
              <SimulationChart
                id={`elasticity-pricing-track-simulation-detail-chart1-${i}`}
                index={i}
                data={item}
                filters={filters}
                chartRef={chartRef}
              />
            </Grid>
            {item.name !== "All Product(Weighted Average)" ? (
              <Grid container>
                <VariableTrendContainer
                  filters={filters}
                  index={i}
                  originalResponse={item.variableTrend || {}}
                  productdcElasticities={item.productdcElasticities}
                  regionElasticities={item.regionElasticities}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <hr />
      </Dashboard>
    );
  };

  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <CardContent>
        <Title className="ellipsis">Model Details</Title>
        <Grid container>
          <RadioButtonGroup
            direction="row"
            key={"elasticity-radio-1"}
            color="#000"
            showButton={true}
            showSkeleton={radioSkeleton}
            data={SimulationRadio.options}
            defaultOption={simulationButtons}
            select={(data) => setSimulationButtons(data)}
          />
        </Grid>

        {!skeleton ? (
          _.isArray(data) && data.length > 0 ? (
            <>
              {data?.map((item, i) => (
                <Container item={item} i={i} />
              ))}
              <Typography sx={{ fontWeight: 700, mt: 2,color:'#000', fontStyle: "italic" }}>
                * Please refer to the G3 model document available in Support & Training to understand the details of each experiment/model.
              </Typography>
            </>
          ) : (
            <Grid container display="flex" justifyContent="center" alignItems="center" height={400}>
              <NoData sx={{ color: "black" }}>{messages.noData}</NoData>
            </Grid>
          )
        ) : (
          <Grid container spacing={2}>
            <Grid item sm={1} className={classes.center}>
              <Box style={{ display: "table", height: "100%" }}>
                <Box
                  style={{
                    position: "relative",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  <Skeleton variant="rectangular" height={100} width={100} />
                </Box>
              </Box>
            </Grid>
            <Grid item sm={11}>
              <Grid container spacing={2}>
                <Grid item sm={7} className="m-t-20">
                  <Skeleton variant="rectangular" height={400} className="m-b-10" />
                </Grid>
                <Grid item sm={5} className="m-t-20">
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  <Skeleton variant="rectangular" height={300} className="m-b-10" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <Skeleton variant="rectangular" height={400} className="m-b-10" />
                </Grid>
                <Grid item sm={5}>
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ModelDetails;
