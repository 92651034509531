import { API } from '../../util/helper'

export const getCountries = () => {
    return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/countries`);
};

export const createUser = (request) => {
    return API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}Users`, request);
};

export const getUser = (id) => {
    return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}Users/${id}`);
};

export const updateUser = (request) => {
    return API.put(`${process.env.REACT_APP_BACKEND_BASE_URL}Users`, request);
};

export const deleteUser = (id) => {
    return API.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}users/${id}`);
};

export const getUsers = () => {
    return API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}Users`);
};

export const getUserList = async (skip: number, take: number) => {
    try {
      return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}users?skip=${skip}&take=${take}`);
    } catch (e) {
      return e;
    }
};
const base_url = '${BACKEND_URL}/';
export const getExecutionFilters = (payload) => {
    return API.post(`${base_url}api/filter/executionTrackerFilter`,payload);
}; 