import { Box, Grid, Skeleton, tooltipClasses } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { messages } from "../../../../../../util/config";
import React, { useEffect, useRef } from "react";
import Dashboard from "../../../../../../components/Dashboard";
import { NoData } from "../../../../../../styles/Common.Styled";
import { chartConfig } from "./stackChartConfig";
import _ from "lodash";
import {
  formatNumber,
  plotElementsOnChart,
} from "../../../../../../util/helper";
import HighchartContainer from "../../../../../../components/HighchartContainer";

const StackChart: React.FC<{
  stackData;
  skeleton;
  title;
  tooltip;
  id;
}> = ({ stackData, skeleton, title, tooltip, id }) => {
  const chartRef = useRef(null);
  const colorPallet = [
    "#6C88B7",
    "#486492",
    "#3984A3",
    "#4DADB5",
    "#62AC5A",
    "#92D050",
    "#F1C058",
    "#E08428",
    "#DC3E4E",
    "#E6F14D",
    "#D0CECE",
    "#757171",
  ];

  const formattedData = stackData.map((item) => {
    return {
      data: item.data.map((item) => {
        return Number(item);
      }),
      name: item.name,
    };
  });

  useEffect(() => {
    if (stackData) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        console.log("stackchart");
        while (chart.series.length) {
          chart.series[0].remove();
        }

        formattedData.map((item, index) => {
          console.log("formatDataStackhart");
          chart.addSeries({
            ...item,
            color: colorPallet[stackData.length - index - 1],
            dataLabels: {
              formatter: function () {
                return formatNumber(this.y, 3);
              },
            },
            tooltip: {
              pointFormatter: function () {
                return `${this.series.name}: ${formatNumber(this.y, 3)}`;
              },
            },
          });
        });

        let dashbaordData = JSON.parse(localStorage.getItem("mmpw"));
        if (dashbaordData && dashbaordData[id]) {
          plotElementsOnChart(dashbaordData[id], chart, id);
        }
      }
    }
  }, [stackData]);

  return (
    <Grid className="m-b-20">
      <Dashboard
        showSkeleton={skeleton}
        id={id}
        title={title}
        chartRef={chartRef}
        showActionButton={_.isArray(stackData) && stackData.length > 0}
        // mmpwConfig={{ disableRetriveMMPW: true }}
      >
        {!skeleton ? (
          <>
            {_.isArray(stackData) && stackData.length ? (
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12}>
                  <Grid style={{ width: "100%" }}>
                    <HighchartContainer chartRef={chartRef} id={id}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartConfig}
                        ref={chartRef}
                      />
                    </HighchartContainer>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box
                style={{ height: 400 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )}
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Skeleton variant="rectangular" height={600} className="m-b-10" />
            </Grid>
          </Grid>
        )}
      </Dashboard>
    </Grid>
  );
};

export default StackChart;
