import Highcharts from "highcharts";
import { formatNumber } from "../../../../util/helper";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

export const ColumnDrilldownConfig = (xAxis, data, y1AxisLable) => {
  return {
    chart: {
      backgroundColor: "transparent",
    },
    title: null,
    xAxis: {
      min: 0,
      gridLineWidth: 1,
      categories: data?.xAxisData,
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
    yAxis: [
      {
        lineWidth: 1,
        title: {
          enabled: true,
          text: y1AxisLable ? y1AxisLable : "",
          style: {
            fontSize: 18,
            fontWeight: 600,
          },
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value.toFixed(1));
          },
        },
      },
      {
        lineWidth: 1,
        opposite: true,
        title: {
          text: "SOP",
          style: {
            fontSize: 18,
            fontWeight: 600,
          },
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value.toFixed(1));
          },
        },
      },
    ],
    plotOptions: {
      series: {
        marker: {
          fillColor: "#F5B7D6",
          lineWidth: 4,
          lineColor: null,
          // symbol: "square",
        },
      },
      spline: {
        color: "#F5B7D6",
      },
      column: {
        color: "#598ee3",
        pointWidth: 30,
      },
    },
    legend: {
      layout: "horizontal",
      backgroundColor: "transparent",
      align: "center",
      verticalAlign: "top",
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
    },
    series: [
      {
        name: y1AxisLable ? y1AxisLable : "",
        type: "column",
        data: data?.columnData,
        color: "#598ee3",
        tooltip: {
          pointFormatter: function () {
            return formatNumber(this.y);
          },
        },
      },
      {
        yAxis: 1,
        type: "spline",
        name: "SOP",
        data: data?.areaData,
        color: "#F5B7D6",
        tooltip: {
          pointFormatter: function () {
            return formatNumber(this.y);
          },
        },
      },
    ],
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: false,
  };
};
