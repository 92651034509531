import React, { useEffect, useRef } from "react";
import { Grid, Box, Skeleton } from "@mui/material";
import Dashboard from "../../../../../../components/Dashboard";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { lineChartConfig } from "./lineChartConfig";
import _ from "lodash";
import { NoData } from "../../../../../../styles/Common.Styled";
import { messages } from "../../../../../../util/config";
import { formatNumber } from "../../../../../../util/helper";
import HighchartContainer from "../../../../../../components/HighchartContainer";

const LineChart: React.FC<{
  data;
  skeleton;
  image;
  chartTitle;
  seriesName;
  id;
  variation;
}> = ({ data, chartTitle, skeleton, image, seriesName, id, variation }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data) {
      const ppu = data.map((item) => item.ppu);
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;

        chart.xAxis[0].setCategories(ppu);
        chart.series[0].setName(seriesName[0]);
        chart.series[1].setName(seriesName[1]);
        chart.series[0].setData(data.map((item) => item.estimatedValue));
        chart.series[1].setData(data.map((item) => item.actualValue));
        chart.update({
          title: {
            style: {
              fontSize: 14,
              fontWeight: 600,
            },
            align: "left",
            verticalAlign: "bottom",
            text:
              "Approximate unit variation between the benchmark and simulated scenario is " +
              formatNumber(variation) +
              "%",
          },
        });
      }
    }
  }, [data]);

  return (
    <Grid className="m-b-20">
      <Dashboard
        showSkeleton={skeleton}
        id={id}
        title={chartTitle}
        chartRef={chartRef}
        showActionButton={_.isArray(data) && data.length > 0}
      >
        {!skeleton ? (
          <>
            {_.isArray(data) && data.length ? (
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={10}>
                  <Grid style={{ width: "100%" }}>
                    <HighchartContainer
                      chartRef={chartRef}
                      id={`pricing-potential-chart1`}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={lineChartConfig}
                        ref={chartRef}
                      />
                    </HighchartContainer>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container display="flex" justifyContent="center">
                    <b>{image.name}</b>
                  </Grid>
                  <Grid container display="flex" justifyContent="center">
                    {image.src ? (
                      <img src={image.src} height={100} width={100} />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box
                style={{ height: 400 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData>{messages.noData}</NoData>
              </Box>
            )}
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Skeleton variant="rectangular" height={400} className="m-b-10" />
            </Grid>
          </Grid>
        )}
      </Dashboard>
    </Grid>
  );
};

export default LineChart;
