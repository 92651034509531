import {
  GET_TRENDING_DASH,
  GET_TRENDING_DASH_SUCCESS,
  GET_TRENDING_DASH_FAILURE,
  POST_TRENDING,
  POST_TRENDING_SUCCESS,
  POST_TRENDING_FAILURE,
} from "./constant";
import { API } from "../../../util/helper";
import _ from "lodash";

export const getTrendingDashboard = () => {
  return {
    type: GET_TRENDING_DASH,
  };
};

export const getTrendingDashboardSuccess = (payload: any) => {
  return {
    type: GET_TRENDING_DASH_SUCCESS,
    payload,
  };
};

export const getTrendingDashboardFailure = (error) => {
  return {
    type: GET_TRENDING_DASH_FAILURE,
    payload: error,
  };
};

export const getTrendingDashboardData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/gettrendingdashboard`,
    payload
  );
};

export function fetchTrendingDashboardData() {
  return (dispatch: any) => {
    dispatch(getTrendingDashboard());
    getTrendingDashboardData({type: 'dashboard'})
       .then((response) => {
         dispatch(getTrendingDashboardSuccess(response.data));
       })
       .catch((error) => {
         dispatch(getTrendingDashboardFailure(error));
       });
  };
}

export const postTrendingPage = () => {
  return {
    type: POST_TRENDING,
  };
};

export const postTrendingPageSuccess = (payload: any) => {
  return {
    type: POST_TRENDING_SUCCESS,
    payload,
  };
};

export const postTrendingPageFailure = (error) => {
  return {
    type: POST_TRENDING_FAILURE,
    payload: error,
  };
};

const postTrendingPageData = (payload) => {
  return API.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/createtrend`,
    payload
  );
};

export function saveTrendingPageData(payload) {
  return (dispatch: any) => {
    dispatch(postTrendingPage());
    postTrendingPageData(payload)
       .then((response) => {
         dispatch(postTrendingPageSuccess(response.data));
       })
       .catch((error) => {
         dispatch(postTrendingPageFailure(error));
       });
  };
}