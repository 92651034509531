import {
  MIX_PORTFOLIO_TABLE_DATA_REQUEST,
  MIX_PORTFOLIO_TABLE_DATA_SUCCESS,
  MIX_PORTFOLIO_TABLE_DATA_FAILURE,
  SET_MIX_PORTFOLIO_LEVEL,
  RESET_MIX_SIMULATION_PORTFOLIO_TABLE,
} from "../../actions/constants";

// Initial state
const initialState = {
  portfolioTableData: null,
  portfolioTableLoader: false,
  portfolioLevel: "brand",
};

const MixPortfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case MIX_PORTFOLIO_TABLE_DATA_REQUEST:
      return {
        ...state,
        error: null,
        portfolioTableLoader: true,
      };

    case MIX_PORTFOLIO_TABLE_DATA_SUCCESS:
      return {
        ...state,
        portfolioTableData: action.payload,
        portfolioTableLoader: false,
      };

    case MIX_PORTFOLIO_TABLE_DATA_FAILURE:
      return {
        ...state,
        portfolioTableData: null,
        portfolioTableLoader: false,
        error: action.payload,
      };
    case SET_MIX_PORTFOLIO_LEVEL:
      return {
        ...state,
        portfolioLevel: action.payload,
        portfolioTableLoader: false,
      };
      case RESET_MIX_SIMULATION_PORTFOLIO_TABLE:
        return initialState;
    default:
      return state;
  }
};

export default MixPortfolioReducer;
