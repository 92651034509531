import { Box, CardContent, Grid, Stack } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import {
  ThemeTitlePL,
  SrpSopPrice,
  SomBlk,
  SrpSopBlk,
  SrpSopBlueSec,
  SrpSopPinkSecRight,
  SrpSopBlueSecLeft,
  SopPinkList,
  SrpBlueList,
  SrpSopImageSec,
  SrpSopImageName,
  SrpSopContentSec,
  SrpSopContenttitle,
  toolTipButton,
  greenSmallText,
  redSmallText,
  graySmallText,
  labeSmallText,
  cursorMove,
} from '../priceLadder.Styled';
import { BlackTitle, OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
//import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VendorsList from '../VendorsList';
import filterMock from '../../../../mocks/priceLadderMock';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';
import { rendarImage, valueBasedOnColor } from '../../../../util/helper';

const PriceLadderSRP: React.FC<{
  filters;
  defaultFilters?;
  vendors;
  SRPData;
  setSRPData;
  initApiRes;
}> = ({ filters, defaultFilters, vendors, SRPData, setSRPData, initApiRes }) => {
  const [figerLevels, setFigerLevels] = useState({
    volume: true,
    ppv: true,
    somChange: true,
    sop: true,
  });
  const [priceTierError, setPriceTierError] = useState('');
  const [newPriceTierValue, setNewPriceTierValue] = useState<any>();
  const img_base_url = `${process.env.REACT_APP_BACKEND_BASE_URL}`;

  const addNewPriceTier = () => {
    if (newPriceTierValue === null || newPriceTierValue === undefined || newPriceTierValue === '') {
      setPriceTierError('Enter price tier value');
      return false;
    }

    const found = SRPData.find((obj) => {
      return obj.srp === parseFloat(newPriceTierValue) ? obj.srp : null;
    });
    if (found) {
      setPriceTierError('Price tier already exists!');
      return false;
    } else {
      setPriceTierError('');
    }

    setSRPData([
      ...SRPData,
      {
        srp: parseFloat(newPriceTierValue),
        sompepsico: 0,
        sompepsicopp: 0,
        somcompetitor: 0,
        somcompetitorpp: 0,
        products: [],
      },
    ]);
  };

  const handleLevels = (e) => {
    setFigerLevels({ ...figerLevels, [e.target.value]: e.target.checked });
  };

  const [openNew, setOpenNew] = React.useState(false);
  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);
  const styleNew = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height: 'inherit',
    backgroundColor: '#fff',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius: '8px',
    padding: '20px',
  };

  useEffect(() => {
    if (newPriceTierValue) {
      const numAscending = [...SRPData].sort((a, b) => b.srp - a.srp);
      setSRPData(numAscending);
      setNewPriceTierValue(null);
      setOpenNew(false);
    }
  }, [SRPData]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: '#000',
      maxWidth: 240,
      border: '1px solid #dadde9',
      fontSize: theme.typography.pxToRem(15),
      borderRadius: '6px',
      boxShadow: `2px 2px 6px rgba(0, 0, 0, 0.28)`,
    },
  }));
  const getIdAndIndex = (node) => ({
    id: node.getAttribute?.('data-id'),
    index: node.getAttribute?.('data-index'),
  });

  const initialState = {
    dragId: null,
    dragIndex: null,
    dragOverId: null,
    dragOverIndex: null,
  };

  const onDragStart = (ev, productName, rowIndex, productIndex, productType) => {
    ev.dataTransfer.setData('productName', productName);
    ev.dataTransfer.setData('productType', productType);
    ev.dataTransfer.setData('rowIndex', rowIndex);
    ev.dataTransfer.setData('productIndex', productIndex);
    let el = document.getElementsByClassName('ToolTopText');
    el[0].style.position = 'unset';
    //console.log('ev-',  el  )
    //document.body.classList.remove('ToolTopText')
  };

  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = async (ev, indx) => {
    let productType = ev.dataTransfer.getData('productType');
    let rowIndex = ev.dataTransfer.getData('rowIndex');
    let productIndex = ev.dataTransfer.getData('productIndex');

    let transferedProduct = SRPData[rowIndex].products[productIndex];

    if (productType === 'pepsi') {
      //set srp value
      transferedProduct.srp = SRPData[indx].srp;

      //SOM deduction from
      SRPData[rowIndex].sompepsico = (parseFloat(SRPData[rowIndex].sompepsico) - parseFloat(transferedProduct.som)).toFixed(2);
      SRPData[rowIndex].sompepsicopp = (parseFloat(SRPData[rowIndex].sompepsicopp) - parseFloat(transferedProduct.ppvpp)).toFixed(2);

      //SOM addition
      SRPData[indx].sompepsico = (parseFloat(SRPData[indx].sompepsico) + parseFloat(transferedProduct.som)).toFixed(2);
      SRPData[indx].sompepsicopp = (parseFloat(SRPData[indx].sompepsicopp) + parseFloat(transferedProduct.ppvpp)).toFixed(2);
    } else {
      //set sop value
      transferedProduct.sop = SRPData[indx].srp;

      //SOM deduction from
      SRPData[rowIndex].somcompetitor = (parseFloat(SRPData[rowIndex].somcompetitor) - parseFloat(transferedProduct.som)).toFixed(2);
      SRPData[rowIndex].somcompetitorpp = (parseFloat(SRPData[rowIndex].somcompetitorpp) - parseFloat(transferedProduct.ppvpp)).toFixed(2);

      //SOM addition
      SRPData[indx].somcompetitor = (parseFloat(SRPData[indx].somcompetitor) + parseFloat(transferedProduct.som)).toFixed(2);
      SRPData[indx].somcompetitorpp = (parseFloat(SRPData[indx].somcompetitorpp) + parseFloat(transferedProduct.ppvpp)).toFixed(2);
    }

    //set ppv value
    if (transferedProduct.gr > 0) {
      transferedProduct.ppv = ((parseFloat(SRPData[indx].srp) / transferedProduct.gr) * 1000).toFixed(2);
    } else {
      transferedProduct.ppv = 0;
    }

    SRPData[indx].products.push(transferedProduct);

    //Remove product from previous tier
    const tempProducts = SRPData[rowIndex].products?.filter((el, indx) => {
      return indx != productIndex;
    });

    SRPData[rowIndex].products = tempProducts;
    setSRPData([...SRPData]);
  };

  const removePriceTier = (data, srpValue) => {
    const newSRPData = SRPData.filter((el) => {
      return el.srp !== srpValue;
    });
    setSRPData(newSRPData);
  };

  //Handle missing images
  const handleMissingImages = (event) => {
    const brandName = event.target.nextElementSibling;
    event.target.style.display = 'none';
    brandName.style.display = 'block';
  };

  const resetChanges = async () => {
    const originalData = JSON.parse(localStorage.getItem('srpGraphData'));
    await Promise.all(
      originalData.map(async (g, i) => {
        await Promise.all(
          g.products.map(async (p, j) => {
            let img;
            img = await rendarImage(p.img.split('/')[p.img.split('/').length - 1], filters.country);
            p['imagBlob'] = img;
          })
        );
      })
    );
    setSRPData(originalData);
  };

  // Set SOM unit according to filter options
  let somUnit = '';
  if (filters.somValueType === 'sales') {
    somUnit = filters.country ? filterMock.currencySymbols[filters.country] : '$';
  }
  if (filters.somValueType === 'volume') {
    somUnit = 'GR';
  }
  if (filters.somValueType === 'units') {
    somUnit = 'Units';
  }

  return (
    <>
      {vendors.length > 0 && <VendorsList vendors={vendors} filters={filters} />}
      {vendors.length > 0 && (
        <Grid container spacing={0} minHeight={60}>
          <Grid xs display='flex' justifyContent='flex-start' alignItems='center' className='m-b-10'>
            <BlackTitle fontSize={18} fontWeight={600}>
              SOM ({somUnit})
            </BlackTitle>
          </Grid>
          <Grid xs display='flex' justifyContent='center' alignItems='center' className='m-b-10'>
            <Grid alignItems='center' display='flex' justifyContent='flex-end'>
              <KeyboardBackspaceIcon />
            </Grid>
            <Grid alignItems='center'>
              <BlackTitle fontSize={18} fontWeight={600}>
                SRP / SOP
              </BlackTitle>
            </Grid>
            <Grid alignItems='center' display='flex' justifyContent='flex-start'>
              <EastRoundedIcon />
            </Grid>
          </Grid>
          <Grid xs display='flex' justifyContent='flex-end' alignItems='center' className='m-b-10'>
            <BlackTitle fontSize={18} fontWeight={600}>
              SOM ({somUnit})
            </BlackTitle>
          </Grid>

          {/* Price Tier */}
          <Grid container spacing={0}>
            {SRPData.map((e, i) => {
              return (
                <Grid item xs={12} key={'price-tier-' + i}>
                  <Grid sx={{ display: 'flex', width: '100%', flexDirection: 'row', mb: '5px' }}>
                    <Grid style={SrpSopBlk}>
                      <BlackTitle sx={{ width: '60px' }}>
                        <SomBlk sx={{ marginLeft: '0' }}>
                          <BlackTitle sx={{ fontWeight: '600' }}>{e.sompepsico}</BlackTitle>
                          {
                            <BlackTitle
                              sx={{
                                fontWeight: 'normal',
                                fontSize: '12px',
                                color: valueBasedOnColor(parseFloat(e.sompepsicopp)),
                              }}
                            >
                              {parseFloat(e.sompepsicopp) > 0
                                ? '+' + (parseFloat(e.sompepsicopp).toFixed(2) + 'pp')
                                : '' + (parseFloat(e.sompepsicopp).toFixed(2) + 'pp')}
                            </BlackTitle>
                          }
                        </SomBlk>
                      </BlackTitle>

                      <BlackTitle style={SrpSopBlueSec} className='srpLeft' flex={1}>
                        <List
                          style={SrpSopBlueSecLeft}
                          onDragOver={(e) => {
                            onDragOver(e);
                          }}
                          onDrop={(e) => {
                            onDrop(e, i);
                          }}
                        >
                          {e.products.map((p, ip) => {
                            if (p.ispepsico) {
                              return (
                                <ListItem
                                  key={'card-pepsi-' + ip}
                                  style={SrpBlueList}
                                  alignItems='center'
                                  onDragStart={(e) => {
                                    onDragStart(e, p.name, i, ip, 'pepsi');
                                  }}
                                  draggable
                                >
                                  <HtmlTooltip
                                    placement='top'
                                    arrow
                                    className='ToolTopText'
                                    title={
                                      <React.Fragment>
                                        <Grid container>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            {filters.comparisonLevel === 'brandsize' ? 'Brand' : 'Sub Brand'} = {p.name}​
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            PackSize = {p.packsize}
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            SRP = ${p.srp}
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            Volume = {p.gr.toFixed(2)} GR​
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            PPV = ${p.ppv}
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            SOM = {p.som}
                                          </Grid>
                                          <Grid container style={labeSmallText} color='inherit'>
                                            <Grid item>% change in SOM = </Grid>
                                            <Grid
                                              item
                                              style={labeSmallText}
                                              m={parseFloat(p.ppvpp) > 0 ? greenSmallText : parseFloat(p.ppvpp) < 0 ? redSmallText : graySmallText}
                                            >
                                              {p.ppvpp > 0 ? '+' + parseFloat(p.ppvpp).toFixed(2) : parseFloat(p.ppvpp).toFixed(2)}pp
                                            </Grid>
                                            ​
                                          </Grid>
                                          <Grid xs={12} style={labeSmallText} color='inherit'>
                                            SOP = ${p.sop}
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    }
                                  >
                                    <Button data-rowIndex={i} data-productIndex={ip} style={{ ...toolTipButton, ...cursorMove }}>
                                      <CardContent style={SrpSopImageSec}>
                                        {p.imagBlob ? (
                                          <CardMedia
                                            draggable={false}
                                            component='img'
                                            sx={{ width: 40 }}
                                            image={p.imagBlob}
                                            // onError={(event) => handleMissingImages(event)}
                                          />
                                        ) : (
                                          <Typography fontSize={'8px'} style={SrpSopImageName} color='text.primery'>
                                            {p.name}
                                          </Typography>
                                        )}
                                        <Typography fontSize={'8px'} style={SrpSopImageName} color='text.primery'>
                                          {p.packsize}
                                        </Typography>
                                      </CardContent>
                                      <CardContent className='pro_con' style={SrpSopContentSec}>
                                        {figerLevels.volume && <Typography style={SrpSopContenttitle}>{p.gr.toFixed(2)} GR</Typography>}
                                        {figerLevels.ppv && <Typography style={SrpSopContenttitle}>${p.ppv}</Typography>}
                                        {figerLevels.somChange && (
                                          <Typography
                                            style={SrpSopContenttitle}
                                            m={parseFloat(p.ppvpp) > 0 ? greenSmallText : parseFloat(p.ppvpp) < 0 ? redSmallText : ''}
                                          >
                                            {p.ppvpp > 0 ? '+' + parseFloat(p.ppvpp).toFixed(2) : parseFloat(p.ppvpp).toFixed(2)}pp
                                          </Typography>
                                        )}
                                        {figerLevels.sop && <Typography style={SrpSopContenttitle}>${p.sop}</Typography>}
                                      </CardContent>
                                    </Button>
                                  </HtmlTooltip>
                                </ListItem>
                              );
                            }
                          })}
                        </List>
                      </BlackTitle>

                      <Typography component='div' className='srpSopPrice'>
                        <SrpSopPrice>
                          {e.products.length === 0 && (
                            <Link
                              style={{ color: '#fff', position: 'absolute', top: '0', left: '3px' }}
                              to={undefined}
                              onClick={(data) => removePriceTier(data, e.srp)}
                            >
                              <CloseIcon style={{ width: '0.77em' }} />
                            </Link>
                          )}
                          ${e.srp}
                        </SrpSopPrice>
                      </Typography>

                      <Typography style={SrpSopBlueSec} component='div' className='sopRight' flex={1}>
                        <List
                          style={SrpSopPinkSecRight}
                          onDragOver={(e) => {
                            onDragOver(e);
                          }}
                          onDrop={(e) => {
                            onDrop(e, i);
                          }}
                        >
                          {e.products &&
                            e.products.map((p, ip) => {
                              if (!p.ispepsico) {
                                return (
                                  <ListItem
                                    key={'card-competitor-' + ip}
                                    style={SopPinkList}
                                    alignItems='stretch'
                                    onDragStart={(e) => {
                                      onDragStart(e, p.name, i, ip, 'competitor');
                                    }}
                                    draggable
                                  >
                                    <HtmlTooltip
                                      placement='top'
                                      arrow
                                      className='ToolTopText'
                                      title={
                                        <React.Fragment>
                                          <Grid container>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              {filters.comparisonLevel === 'brandsize' ? 'Brand' : 'Sub Brand'} = {p.name}​
                                            </Grid>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              PackSize = {p.packsize}
                                            </Grid>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              Volume = {p.gr.toFixed(2)} GR​
                                            </Grid>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              PPV = ${p.ppv}
                                            </Grid>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              SOM = {p.som}
                                            </Grid>
                                            <Grid container style={labeSmallText} color='inherit'>
                                              <Grid item>% change in SOM = </Grid>
                                              <Grid
                                                item
                                                style={labeSmallText}
                                                m={parseFloat(p.ppvpp) > 0 ? greenSmallText : parseFloat(p.ppvpp) < 0 ? redSmallText : graySmallText}
                                              >
                                                {p.ppvpp > 0 ? '+' + parseFloat(p.ppvpp).toFixed(2) : parseFloat(p.ppvpp).toFixed(2)}pp
                                              </Grid>
                                              ​
                                            </Grid>
                                            <Grid xs={12} style={labeSmallText} color='inherit'>
                                              SOP = ${p.sop}
                                            </Grid>
                                          </Grid>
                                        </React.Fragment>
                                      }
                                    >
                                      <Button style={{ ...toolTipButton, ...cursorMove }}>
                                        <CardContent style={SrpSopImageSec}>
                                          <Typography color='text.primery'>
                                            {p.imagBlob ? (
                                              <CardMedia
                                                draggable={false}
                                                component='img'
                                                sx={{ width: 40 }}
                                                image={p.imagBlob}
                                                onError={(event) => handleMissingImages(event)}
                                              />
                                            ) : (
                                              <Typography fontSize={'8px'} style={SrpSopImageName} color='text.primery'>
                                                {p.name}
                                              </Typography>
                                            )}

                                            <Typography fontSize={'8px'} style={SrpSopImageName} color='text.primery'>
                                              {p.packsize}
                                            </Typography>
                                          </Typography>
                                        </CardContent>
                                        <CardContent className='pro_con' style={SrpSopContentSec}>
                                          {figerLevels.volume && <Typography style={SrpSopContenttitle}>{p.gr.toFixed(2)} GR</Typography>}
                                          {figerLevels.ppv && <Typography style={SrpSopContenttitle}>${p.ppv}</Typography>}
                                          {figerLevels.somChange && (
                                            <Typography
                                              style={SrpSopContenttitle}
                                              m={parseFloat(p.ppvpp) > 0 ? greenSmallText : parseFloat(p.ppvpp) < 0 ? redSmallText : ''}
                                            >
                                              {p.ppvpp > 0 ? '+' + parseFloat(p.ppvpp).toFixed(2) : parseFloat(p.ppvpp).toFixed(2)}pp
                                            </Typography>
                                          )}
                                          {figerLevels.sop && <Typography style={SrpSopContenttitle}>${p.sop}</Typography>}
                                        </CardContent>
                                      </Button>
                                    </HtmlTooltip>
                                  </ListItem>
                                );
                              }
                            })}
                        </List>
                      </Typography>

                      <BlackTitle component='div' sx={{ width: '60px' }}>
                        <SomBlk sx={{ marginRight: '0' }}>
                          <BlackTitle sx={{ fontWeight: '600' }}>{e.somcompetitor}</BlackTitle>
                          {
                            <BlackTitle
                              sx={{
                                fontWeight: 'normal',
                                fontSize: '12px',
                                color: valueBasedOnColor(parseFloat(e.somcompetitorpp)),
                              }}
                            >
                              {parseFloat(e.somcompetitorpp) > 0
                                ? '+' + (parseFloat(e.somcompetitorpp).toFixed(2) + 'pp')
                                : '' + (parseFloat(e.somcompetitorpp).toFixed(2) + 'pp')}
                            </BlackTitle>
                          }
                        </SomBlk>
                      </BlackTitle>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          {/* Add button section start */}
          <Grid item xs={12} display='flex' justifyContent='center'>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <Fab color='secondary' aria-label='add' onClick={handleOpenNew}>
                <AddIcon />
              </Fab>
            </Box>
            <Modal open={openNew} onClose={handleCloseNew} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
              <Box m={styleNew} className='modal' height={'inherit'}>
                <BlackTitle display={'flex'} justifyContent={'space-between'}>
                  <BlackTitle id='modal-modal-title' variant='h5' component='h3'>
                    Add New Price Tier
                  </BlackTitle>
                  <IconButton aria-label='Close' onClick={handleCloseNew}>
                    <CloseIcon />
                  </IconButton>
                </BlackTitle>
                <BlackTitle className='modal-body' id='modal-modal-description' sx={{ mt: 1 }}>
                  <BlackTitle>
                    <TextField
                      id='outlined-number'
                      placeholder='SRP/SOP'
                      onChange={(e) => setNewPriceTierValue(e.target.value)}
                      value={newPriceTierValue}
                      type='number'
                      InputLabelProps={{ shrink: true }}
                    />
                  </BlackTitle>
                  <BlackTitle sx={{ color: '#fb1111' }}>{priceTierError}</BlackTitle>
                  <BlackTitle mt={1}>
                    <PrimaryBtn onClick={addNewPriceTier}>Add</PrimaryBtn>
                  </BlackTitle>
                </BlackTitle>
              </Box>
            </Modal>
          </Grid>
          {/* Add button section end */}

          {/* Checkbox section start */}
          <Grid item xs={12} display='flex' justifyContent='center'>
            <FormControl component='fieldset'>
              <FormGroup aria-label='position' row>
                <FormControlLabel
                  className='checkbox_icon'
                  value='volume'
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleLevels(e);
                      }}
                      color='primary'
                      defaultChecked
                    />
                  }
                  label={<span style={{ color: 'black' }}>Volume</span>}
                  labelPlacement='end'
                />

                <FormControlLabel
                  className='checkbox_icon'
                  value='ppv'
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleLevels(e);
                      }}
                      defaultChecked
                    />
                  }
                  label={<span style={{ color: 'black' }}>PPV</span>}
                  labelPlacement='end'
                />

                <FormControlLabel
                  className='checkbox_icon'
                  value='somChange'
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleLevels(e);
                      }}
                      defaultChecked
                    />
                  }
                  label={<span style={{ color: 'black' }}>% change in SOM</span>}
                  labelPlacement='end'
                />

                <FormControlLabel
                  className='checkbox_icon'
                  value='sop'
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleLevels(e);
                      }}
                      defaultChecked
                    />
                  }
                  label={<span style={{ color: 'black' }}>SOP</span>}
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>
          </Grid>
          {/* Checkbox section end */}

          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Stack direction='row' spacing={1}>
              <OrangeBtn onClick={resetChanges}>Reset Changes</OrangeBtn>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PriceLadderSRP;
