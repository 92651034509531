import _ from "lodash";
import { formatNumber, separator } from "../../../../util/helper";
const chartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "column",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "Inflation",
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      lineWidth: 1,
      gridLineWidth: 1,
      title: {
        margin: 30,
        style: {
          fontSize: 18,
          fontWeight: 600,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
      },
      labels: {
        formatter: function () {
          return separator(this.value,2);
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        fillColor: "#FFFFFF",
        lineWidth: 1,
        lineColor: null,
        symbol: "circle",
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: "#0362ab",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
export { chartConfig };
