import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableHead, Skeleton, Tooltip, TableContainer } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { NoData } from '../../../../styles/Common.Styled';
import { getSRPVsSOPGraphData } from '../../service';
import { rendarImage, separator } from '../../../../util/helper';
import { StyledTableCell, StyledTableRow } from '../../../../components/CustomTable/CustomTable.Styled';
import { messages } from '../../../../util/config';
import Draggable from 'react-draggable';
import { Title } from '../../../../components/Dashboard/Dashboard.Styled';
import Dashboard from '../../../../components/Dashboard';
const useStyles = makeStyles(() => ({
  imageFallbackBlock: {
    border: '2px solid #25d4cf',
    borderRadius: '2px',
    padding: '4px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '120px',
    height: '70px',
    textAlign: 'center',
  },
  tableHead: {
    border: '1px solid #aaa !important',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const SOPvsSRPGraph: React.FC<{ globalFilters; sopAndSrpFiltersWithTaxMarkups }> = ({ globalFilters, sopAndSrpFiltersWithTaxMarkups }) => {
  const classes = useStyles();
  const [indexes, setIndexes] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [noData, setNoData] = useState(false);
  const fetchSopVsSrpData = async () => {
    setSkeleton(true);
    setIndexes([]);
    setNoData(false);
    try {
      const response = await getSRPVsSOPGraphData(sopAndSrpFiltersWithTaxMarkups);
      if (response?.data) {
        if (_.isArray(response?.data) && response?.data.length > 0) {
          formatData(response?.data);
          setNoData(false);
        } else {
          setNoData(false);
          setNoData(true);
        }
      }
    } catch (err) {
      setNoData(true);
    }
  };
  useEffect(() => {
    if (Object.keys(sopAndSrpFiltersWithTaxMarkups || {}).length > 0) {
      fetchSopVsSrpData();
    }
  }, [sopAndSrpFiltersWithTaxMarkups]);
  const formatData = (SOPVsSRPData) => {
    if (_.isArray(SOPVsSRPData) && SOPVsSRPData.length > 0) {
      let indexes = [];
      Promise.all(
        SOPVsSRPData?.map(async (item) => {
          item.image = await rendarImages(item);
        })
      ).then(() => {
        const srpGroup = _.groupBy(SOPVsSRPData, (x) => x.srp.toFixed(2));
        const sopGroup = _.groupBy(SOPVsSRPData, (x) => x.sop.toFixed(2));
        Object.entries(srpGroup).map(([key, val]) => {
          indexes.push({
            index: parseFloat(key),
            srpProducts: [...val],
          });
        });
        Object.entries(sopGroup).map(([key, val]) => {
          const existingIndex = indexes.findIndex((x) => x.index === parseFloat(key));
          if (existingIndex !== -1) {
            indexes[existingIndex]['sopProducts'] = [...(indexes[existingIndex].sopProducts ? indexes[existingIndex].sopProducts : []), ...val];
          } else {
            indexes.push({
              index: parseFloat(key),
              sopProducts: [...val],
            });
          }
        });
        indexes = _.orderBy(indexes, ['index'], ['desc']);
        setIndexes(indexes);
        setSkeleton(false);
      });
    }
  };
  React.useEffect(() => {
    if (globalFilters && globalFilters.country) {
      setSkeleton(true);
      setIndexes([]);
    }
  }, [globalFilters]);
  const rendarImages = async (data) => {
    if (data) {
      const sopTooltipTitle = (
        <div style={{ fontSize: 12 }}>
          <div>Name: {data.productInfo}</div>
          <div>Mix $: {data.mix.toFixed(2)}</div>
          <div>PPU: {data.pricePerUnit.toFixed(2)}</div>
          <div>SRP: ${data.srp.toFixed(2)}</div>
        </div>
      );
      try {
        const response = await rendarImage(data.productName, globalFilters.country);
        if (response) {
          const imageBlobToUrl = response;
          return (
            <Tooltip title={sopTooltipTitle}>
              <img src={imageBlobToUrl} alt={data.productName} height='70' width='120' data-parent='ladder' />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={sopTooltipTitle}>
              <div className={`${classes.imageFallbackBlock} ${data.productName.length < 30 ? classes.center : 'three-line-ellipsis'}`}>
                {data.productName || ''}
              </div>
            </Tooltip>
          );
        }
      } catch (err) {
        return (
          <Tooltip title={sopTooltipTitle}>
            <div className={`${classes.imageFallbackBlock} ${data.productName.length < 30 ? classes.center : 'three-line-ellipsis'}`}>
              {data.productName || ''}
            </div>
          </Tooltip>
        );
      }
    }
  };
  return (
    <>
      <Dashboard
        title={'SOP VS SRP'}
        showSkeleton={skeleton}
        chartRef={() => {}}
        showActionButton={true}
        actionButtons={{
          mmpw: false,
          screenshot: true,
          editMedia: false,
        }}
        id={null}
      >
        {noData ? (
          <NoData>{messages.noData}</NoData>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ width: '8%', textAlign: 'center' }} className={classes.tableHead}>
                    Index
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '46%', textAlign: 'center' }} className={classes.tableHead}>
                    SOP
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '46%', textAlign: 'center' }} className={classes.tableHead}>
                    SRP
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {!skeleton ? (
                <TableBody>
                  {indexes.map((item, i) => (
                    <StyledTableRow key={`sopVssrpTable${i}`}>
                      <StyledTableCell style={{ color: '#25d4cf', fontWeight: 'bold', textAlign: 'center' }}>
                        <span>{separator(item.index)}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {item.sopProducts
                            ? item.sopProducts.map((item) => (
                                <Draggable axis='x' bounds='parent'>
                                  <span style={{ marginRight: 35, marginBottom: 10 }} className='cursor-pointer'>
                                    {item.image}
                                    <div style={{ color: '#929292', width: 120, textAlign: 'center' }}>{item.productInfo}</div>
                                  </span>
                                </Draggable>
                              ))
                            : null}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {item.srpProducts
                            ? item.srpProducts.map((item) => (
                                <Draggable axis='x' bounds='parent'>
                                  <span style={{ marginRight: 35, marginBottom: 10 }} className='cursor-pointer'>
                                    {item.image}
                                    <div style={{ color: '#929292', width: 120, textAlign: 'center' }}>{item.productInfo}</div>
                                  </span>
                                </Draggable>
                              ))
                            : null}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => (
                    <StyledTableRow key={`sopVssrpTable${i}`}>
                      <StyledTableCell align='center'>
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Dashboard>
    </>
  );
};
export default SOPvsSRPGraph;
