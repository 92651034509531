import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";



const Title = styled(Typography)(() => ({
  color: "#7E7E7E",
  fontSize: 14,
  fontFamily:"Mulish, sans-serif",
  fontWeight: "700"
}));

const SectionTitle = styled(Typography)(() => ({
    color: "#0B0E1E",
    fontSize: 13,
    fontFamily:"Montserrat, sans-serif",
    fontWeight: "500"
  }));

  const SkuAvgValue = styled(Typography)(() => ({
    color: "black",
    background:"#D0D2D3",
    fontSize: 13,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    border: "1px solid white",
    width: "100px",
    height: "35px",
    borderRadius: "4px",
    display:"flex",
    justifyContent:"center",
    alignContent:"center",
    alignItems:"center",
    marginLeft:"5px"
  }));

export { Title,SectionTitle,SkuAvgValue };
