import styled from "styled-components";

interface RemarkChipProps {
  status: 'Good' | 'Okay' | 'Low' | string;
}


export const flex_end={
  padding: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const Title = styled.h1`
color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 132%;
`;
const StyledSelect = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  outline: none;
  cursor: pointer;
`;

const FilterLabel = styled.div`
color: #000;
font-family: Mulish;
font-size: 0.8rem;
font-style: normal;
font-weight: 700;
line-height: normal;
opacity: 0.6;
margin-bottom : 5px
`;
const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;
const StyledOption = styled.option`
  background-color: #f0f0f0;
  padding: 8px;

  &:hover {
    background-color: #ccc;
  }
`;

const StyledHeading = styled.h1`
  color: var(--dark-neutrals-gray-1, #777);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
const StyledScore = styled.h1`
color: var(--dark-neutrals-dark, #333);
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.044px;
`;
const StyleTotalScore = styled.h1`
color: var(--dark-neutrals-gray-2, #B2B2B2);
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.032px;
;`

const RemarkChip = styled.div<RemarkChipProps>`
border-radius: 4px;
padding:10px;
background: ${(props: any) => {
    switch (props.status) {
      case 'Good':
        return 'rgba(22, 199, 154, 0.10)';
      case 'Okay':
        return 'rgba(248, 189, 14, 0.10)';
      case 'Low':
        return 'rgba(254, 238, 238, 1)';
      default:
        return 'rgba(0, 0, 0, 0.10)';
    }
  }};
`;
const RemarkChipTitle = styled.h1<RemarkChipProps>`
text-align: center;
font-family: Arial Nova;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 116.667% */
color:${(props: any) => {
    switch (props.status) {
      case 'Good':
        return 'rgba(39, 175, 141, 1)';
      case 'Okay':
        return 'rgba(248, 189, 14, 1)';
      case 'Low':
        return 'rgba(250, 66, 66, 1)';
      default:
        return 'rgba(0, 0, 0, 0.10)';
    }
  }};
`;
const StyledKPIheading = styled.div`
color: #414B5A;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
opacity: 0.6;
`;

const StyledKPIValue = styled.div`
color: var(--dark-neutrals-dark, #333);
text-align: center;
font-family: Arial;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  gap: 5px;
`;
const SalesCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin:10px 0px;
`;

const SubTitle = styled.h1`
color: #000;
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
letter-spacing: 0.6px;
text-transform: uppercase;
margin:5px;
`;
const SalesCardTitle = styled.h1`
color: var(--dark-neutrals-gray-1, #777);
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
`;
const SalesCardValue = styled.div`
color: var(--text-primary, #0F2744);
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0px 5px;
letter-spacing: 0.04px;`;

const SalesCardPercentageIncrease = styled.div`
color: var(--success-dark, #27AF8D);
text-align: center;
font-family: Arial Nova;
font-size: 12px;
margin: 0px 5px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 116.667% */`;

const SalesCardPercentageDecrease = styled.div`
color: var(--success-dark, #FA4242);
text-align: center;
font-family: Arial Nova;
font-size: 12px;
margin: 0px 5px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 116.667% */`;

const ClearButton = styled.button`
  border-radius: 8px;
  padding: 5px 15px;
  background: #E81C0E;
  border:none;
  font-family: Roboto;
  color: #fff;
  margin:5px 30px;
  cursor: pointer;
  &:hover {
    background: #FF473A; 
  }
`;

const DashedDivider = styled.div`
background: #08509A;
width: 100%;
height: 1px; 
margin: 20px 0px; 
background: linear-gradient(90deg, transparent 5px, #08509A 5px, #08509A 15px);
background-size: 15px 1px;
`;

const UserInputSubTitle = styled.h3`
color: #7E7E7E;
font-family: Mulish;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.4px;
`;
const UserInputFilterTitle = styled.h3`
color: #000;
font-family: Mulish;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.4px;
`;

const MixFilterName = styled.h3`
color: #000;
font-family: Mulish;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
opacity: 0.6;
white-space:nowrap;
`;
const MixFilterValues = styled.div`
display:flex;
justify-content:center;
align-items:center;
border-radius: 7px;
padding:5px 10px;
border: 1px solid rgba(237, 241, 244, 0.40);
opacity: 0.65;
background: #FFF;
color: black;
margin-left: 5px;
white-space: nowrap;
font-size: 12px;
`;
const MixFilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 20px;
`;
const RunSimulationButton = styled.button`
cursor:pointer;
border:none;
border-radius: 3px;
padding:5px 30px;
background: #08509A;
color: #FEFEFE;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin 10px 0px;
`;

export {
  Title,
  StyledSelect,
  FilterLabel,
  FilterGrid,
  StyledOption,
  StyledHeading,
  StyledScore,
  StyleTotalScore,
  RemarkChip,
  RemarkChipTitle,
  StyledKPIheading,
  StyledKPIValue,
  CardGrid,
  SalesCardGrid,
  SubTitle,
  SalesCardTitle,
  SalesCardValue,
  SalesCardPercentageIncrease,
  SalesCardPercentageDecrease,
  ClearButton,
  DashedDivider,
  UserInputSubTitle,
  UserInputFilterTitle,
  MixFilterName,
  MixFilterGrid,
  MixFilterValues,
  RunSimulationButton
};