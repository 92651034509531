import { ChromePicker } from "react-color";

const ColorPicker: React.FC<{ color: string; callback: any }> = ({
  color,
  callback,
}) => {
  const colorHandler = (c) => {
    callback(c.hex);
  };

  return (
    <ChromePicker color={color} onChangeComplete={colorHandler} disableAlpha />
  );
};

export default ColorPicker;
