import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import Dropdown from '../../../../components/Dropdown';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import mockData from '../../../../mocks/revenueMap';

const MeasureFilters: React.FC<{ onChange; selectedMeasureFilters; defaultFilters }> = ({ onChange, selectedMeasureFilters, defaultFilters }) => {
  const [axis, setAxis] = useState('');
  const [viewX, setViewX] = useState([]);
  const [viewY, setViewY] = useState([]);

  useEffect(() => {
    if (axis) {
      if (axis === 'sellOut') {
        setViewX(mockData.sellOut);
        setViewY(mockData.sellOut);
      } else {
        setViewX(mockData.sellIn);
        setViewY(mockData.sellIn);
      }
    }
  }, [axis]);

  useEffect(() => {
    if (defaultFilters && defaultFilters?.country && defaultFilters?.data) {
      setAxis(defaultFilters?.data);
    }
  }, [defaultFilters]);

  return (
    <Grid container className='m-b-20' spacing={2} columns={17}>
      <Grid item container className='m-b-20' spacing={2} columns={17}>
        <Grid item>
          <Typography variant='subtitle2'>Var Top&nbsp;</Typography>
          <Grid display='flex' alignItems='center'>
            <TextField
              type='number'
              id='view-top'
              onChange={(e) => {
                onChange({ key: 'viewTop', val: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) });
              }}
              value={selectedMeasureFilters.viewTop}
              InputProps={{ inputProps: { min: '0', step: '1' }, autoComplete: 'off' }}
            />
            <span style={{ marginLeft: 10 }}>
              <b>Note:</b> Selecting more than 30 in the Var Top field, will impact the dashboard performance and the quality of the visualization.
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className='m-b-20' spacing={2} columns={17}>
        <Grid item xs={12} sm={2}>
          <DropdownTitle>Data</DropdownTitle>
          <Dropdown
            data={mockData.Data}
            onChange={(data) => {
              setAxis(data[0]);
              onChange({ key: 'data', val: data[0] });
            }}
            defaultOption={selectedMeasureFilters.data || ['empty']}
            search={{ enable: false }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownTitle>View X</DropdownTitle>
          <Dropdown
            data={viewX}
            disabled={!axis}
            onChange={(data) => {
              onChange({ key: 'viewX', val: data[0] });
            }}
            defaultOption={selectedMeasureFilters.viewX || ['empty']}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownTitle>View Y</DropdownTitle>
          <Dropdown
            data={viewY}
            disabled={!axis}
            onChange={(data) => {
              onChange({ key: 'viewY', val: data[0] });
            }}
            defaultOption={selectedMeasureFilters.viewY || ['empty']}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownTitle>View Size</DropdownTitle>
          <Dropdown
            data={mockData.viewSize}
            disabled={!axis}
            onChange={(data) => {
              onChange({ key: 'viewSize', val: data[0] });
            }}
            defaultOption={selectedMeasureFilters.viewSize || ['empty']}
            search={{ enable: false }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DropdownTitle>View Type </DropdownTitle>

          <Dropdown
            data={mockData.viewType}
            disabled={!axis}
            onChange={(data) => {
              onChange({ key: 'viewType', val: data[0] });
            }}
            defaultOption={selectedMeasureFilters.viewType || ['empty']}
            search={{ enable: false }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MeasureFilters;
