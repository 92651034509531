import { GEO_XREF_DATA } from "./constants";

let intiState = {
  geoxrefDataLoader: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case GEO_XREF_DATA:
      return {
        ...state,
        geoxrefDataLoader: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
