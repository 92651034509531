import { keysToDownload, keysToDownloadMapper } from "../../../pages/MixSimulation/component/MixTable/MixTableDownloadConfig";
import { API } from "../../../util/helper";
import { BACKEND_URL, FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA, FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER, IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE, LOADER_FOR_MIX_RUN_SIMULATION, LOADER_FOR_RUN_SIMULATION, REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA, REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, REQUEST_MIX_SIMULATION_SAVE_SCENARIO, RESET_MIX_CONSTRAINT_TABLE, SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, SET_MIX_SIMULATION_CONSTRAINT_DOWNLOAD_TABLE_DATA, SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA, SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA, SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA, SET_MIX_SIMULATION_GOAL, SET_MIX_SIMULATION_UID, SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER, UPLOAD_VALIDATION_SUCCESS } from "../constants";
import { toast } from "../toast.action";
import _ from "lodash"
import Papa from 'papaparse';



export const requestMixSimulationConstraintTableData = () => ({
  type: REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
});
export const handleMixConstraintTableLoader = (payload) => ({
  type: HANDLE_MIX_SIMULATION_CONSTRAINT_TABLE_LOADER,
  payload
});

export const setMixSimulationConstraintTableData = (payload) => ({
  type: SET_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
  payload
});
export const setMixSimulationConstraintDownloadTableData = (payload) => ({
  type: SET_MIX_SIMULATION_CONSTRAINT_DOWNLOAD_TABLE_DATA,
  payload
});
export const setMixSimulationConstraintWholeDownloadTableData = (payload) => ({
  type: SET_MIX_SIMULATION_CONSTRAINT_TABLE_WHOLE_DOWNLOAD_DATA,
  payload
})
export const setMixSimulationConstraintIntermediateTableData = (payload) => ({
  type: SET_MIX_SIMULATION_CONSTRAINT_INTERMEDIATE_TABLE_DATA,
  payload
});

export const failureMixSimulationConstraintTableData = (payload) => ({
  type: FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_DATA,
  payload
});

export const requestMixSimulationConstraintTableFilter = () => ({
  type: REQUEST_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
});
export const setMixSimulationConstraintTableFilter = (payload) => ({
  type: SET_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
  payload
});

export const setSelectedMixSimulationConstraintTableFilter = (payload) => ({
  type: SET_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
  payload
});
export const isMixSimulationConstraintTableDataChanged = (payload) => ({
  type: IS_DATA_CHANGED_IN_MIX_SIMULATION_TABLE,
  payload
});
export const failureMixSimulationConstraintTableFilter = (payload) => ({
  type: FAILURE_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
  payload
});

export const loaderForRunSimulation = (payload) => ({
  type: LOADER_FOR_RUN_SIMULATION,
  payload
});

export const uploadValidationSuccess = (payload) => ({
  type: UPLOAD_VALIDATION_SUCCESS,
  payload
});

export const loaderForMixScreen = (payload) => ({
  type: LOADER_FOR_MIX_RUN_SIMULATION,
  payload
});
export const setAllMixConstraintTableFilter = (payload) => ({
  type: SET_ALL_SELECTED_MIX_SIMULATION_CONSTRAINT_TABLE_FILTER,
  payload
})
export const resetMixConstraintTable = () => ({
  type: RESET_MIX_CONSTRAINT_TABLE,
});


export const fetchMixSimulationConstraintTableData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(requestMixSimulationConstraintTableData());
    try {
      const response = await API.post(`${BACKEND_URL}/api/output/MixSimulation/${apiUrl}`, data);
       console.log("setMixConstraintData", response)
       let dataArray = response.data
    //    _.forEach(dataArray, (obj) => {
    //     obj.min_mix = _.round(obj.min_mix, 2);
    //     obj.max_mix = _.round(obj.max_mix, 2);
    // });
      dispatch(setMixSimulationConstraintTableData(dataArray))

    } catch (error) {
      dispatch(failureMixSimulationConstraintTableData(error.message));
    }
  };
};


export const fetchMixSimulationConstraintTableFilter = (data, key?): any => {
  return async (dispatch) => {
    dispatch(requestMixSimulationConstraintTableFilter());
    try {
      const response = await API.post(`${BACKEND_URL}/api/filter/getMixConstraintTableFilter`, data);
      // console.log("setMixConstraintFilter", response)
      let payload = {
        data: response.data,
        default: data.default,
        key: key
      }
      dispatch(setMixSimulationConstraintTableFilter(payload));
      return response
    } catch (error) {
      dispatch(failureMixSimulationConstraintTableFilter(error.message));
    }
  };
};



export const downloadMixSimulationConstraintTableData = (data, apiUrl): any => {
  return async (dispatch) => {
    // dispatch(requestMixSimulationConstraintTableData());
    try {
      const response = await API.post(`${BACKEND_URL}/api/output/MixSimulation/${apiUrl}`, data);
      const csvData = response.data;
      // console.log(csvData)
      dispatch(setMixSimulationConstraintWholeDownloadTableData(csvData))
      // Extracting header from the first object in the array
      
      


      const header = Object.keys(csvData[0]).filter((key) => keysToDownload.includes(key));
      const ModifiedHeader = header.map(item=>{return keysToDownloadMapper[item]})
      // console.log(header)
      // Convert array of objects to a CSV string
      const csvContent = ModifiedHeader.join(",") + "\n" +
        csvData.map(obj => header.map(key => obj[key]).join(",")).join("\n");

      // console.log(csvContent)

      const file = new Blob([csvContent], { type: "text/csv" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "constraint_data_download_csv.csv";

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click event to start the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      // Release the object URL resources
      URL.revokeObjectURL(link.href);
      // dispatch(setMixSimulationConstraintTableData(response.data))
      return response.data
    } catch (error) {
      dispatch(failureMixSimulationConstraintTableData(error.message));
    }
  };
};




export const downloadMixSimulationCSVData = (data, apiUrl) => {
  return async (dispatch) => {
    dispatch(requestMixSimulationConstraintTableData());
    try {
      const response = await API.post(
        `${BACKEND_URL}/api/output/mix/${apiUrl}`,
        data
      );
      const csvData = response.data;
      const file = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "constraint_data_download_csv.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // dispatch(fetchAssortmentManualCSVDataFailure(error.message));
      dispatch(
        toast(
          "Something went wrong, please try again later.",
          true,
          3000,
          "error"
        )
      );
      console.error(error);
    }
  };
};

export const uploadMixSimulationConstraintTableData = (data, apiUrl): any => {
  // console.log(data)
  return async (dispatch) => {
    dispatch(requestMixSimulationConstraintTableData);

    try {
      const response = await API.post(
        `${BACKEND_URL}/api/${apiUrl}`,
        data
      );
      // console.log(response)
      // console.log("response", data)

      if (response.status == 200) {
        try {
          const csvData = await parseCsv(data.get('CsvFile'));
          dispatch(setMixSimulationConstraintTableData(csvData))
          console.log('csvData', csvData);
          // Process csvData as needed
        } catch (error) {
          console.error('Error parsing CSV:', error);
        }

      }
      // dispatch(fetchAssortmentUploadCSVDataSuccess(response.data));
      dispatch(toast("File upload successfully!", true, 3000, "success"));
    } catch (error) {
      dispatch(failureMixSimulationConstraintTableData(error.message));
      dispatch(
        toast(
          "Something went wrong, please try again later.",
          true,
          3000,
          "error"
        )
      );
    }
  };
};

const parseCsv = (file) => {

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        resolve(result.data);
      },
      error: (error) => {
        reject(error);
      }
    });
  });
};