import {
  PRICING_POTENTIAL_SLIDER_DATA,
  PRICING_POTENTIAL_CHART_DATA,
} from "./constants";

export const pricingPotentialSliderDataLoader = (payload) => {
  return {
    type: PRICING_POTENTIAL_SLIDER_DATA,
    payload,
  };
};

export const pricingPotentialChartDataLoader = (payload) => {
  return {
    type: PRICING_POTENTIAL_CHART_DATA,
    payload,
  };
};
