import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  dispatchState,
  fetchApi,
} from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import {
  fetchMixScenarioData,
  loaderForScenario,
} from "../../../../store/actions/ScenarioLibrary/scenario.action";
import { toast } from "../../../../store/actions/toast.action";
import {
  handleMixEditMode,
  resetAllMixFilters,
  setMixScenarioName,
  setMixViewMode,
  setOutputScreenToDisplay,
} from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { MIX_SIMULATION_TOOL } from "../../../../router/CONSTANTS";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DataGridTables from "../../../../components/ExecutionDataTable/DataGridTables";
import {
  ViewEditButton,
  styleForScenarioDataGrid,
} from "./MixScenarioLibrary.styles";
import Indicator from "../../../../components/Loader/Loader";
import ConfirmDialog from "../../../AssortmentTool/components/confirmDialog/ConfirmDialog";
import SearchScenario from "../../../AssortmentTool/components/SearchScenario/SearchScenario";
import { resetMixUserInputs } from "../../../../store/actions/MixSimulationTool/mixsimulationUserInputs.action";
import { resetMixConstraintTable } from "../../../../store/actions/MixSimulationTool/mixSimulationConstraintTable.action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity } from "../../../../store/actions/MixSimulationTool/mixUserConstraints.action";
import { resetMixSimulationGeoLevelTableData } from "../../../../store/actions/MixSimulationTool/mixGeoLevel.action";
import { resetMixSimulationPortfolioTableData } from "../../../../store/actions/MixSimulationTool/mixPortfolio.action";
import { API_URL } from "../../../../store/actions/constants";
const MixScenarioLibrary = () => {
  const { mixScenarioLibraryData, loading } = useSelector(
    (state: any) => state.ScenarioLibraryReducer
  );
  const [scenarios, setScenarios] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [uid, setUid] = useState("");
  const [deleteText, setDeleteText] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setOutputScreenToDisplay(false));
  });

  const handleInputChange = (value) => {
    const lowerCaseValue = value.toLowerCase();
    setScenarios((prev) => {
      const filteredData = mixScenarioLibraryData
        .filter((ele) =>
          ele?.scenarioName.toLowerCase().includes(lowerCaseValue)
        )
        .map((entry) => ({
          ...entry,
          status: mapStatus(entry.status),
        }));
      return filteredData;
    });
  };

  const handleClose = (isDelete) => {
    setOpen(false);

    if (isDelete) {
      const dispatchActions: dispatchState = {
        loaderStateFetch: loaderForScenario(true),
      };
      console.log("constant", API_URL.MIXSIMULATION.SCENARIO_DELETE+"?Uid="+uid, API_URL.MIXSIMULATION.SCENARIO_DELETE,uid)
      dispatch(
        fetchApi(
          null,
          API_URL.MIXSIMULATION.SCENARIO_DELETE+"?Uid="+uid,
          dispatchActions,
          "GET"
        )
      )
        .then((res) => {
          dispatch(
            toast(
              `${res.data.scenarioName} deleted Successfully!`,
              true,
              4000,
              "success"
            )
          );
          getAllScenarios();
        })
        .catch((err) => {
          dispatch(toast("Something went wrong!", true, 2000, "error"));
        });
    }
  };

  function handleDelete(params) {
    setUid(params.id);
    setDeleteText(params.row.scenarioName);
    return setOpen(true);
  }

  function handleEdit(id, val) {
    const { uid, scenarioName } = id.row;
    dispatch(setMixScenarioName(scenarioName));
    dispatch(setMixViewMode(false));
    val === "output" && dispatch(setOutputScreenToDisplay(true));
    dispatch(handleMixEditMode(true));
    history.push(MIX_SIMULATION_TOOL, { id: uid, scenarioName });
  }

  function getAllScenarios() {
    const dispatchActions: dispatchState = {
      loaderStateFetch: loaderForScenario(true),
    };
    dispatch(
      fetchApi(null, API_URL.MIXSIMULATION.SCENARIO_LIST, dispatchActions, "GET")
    )
      .then((res) => {
        dispatch(loaderForScenario(false));
        dispatch(fetchMixScenarioData(res.data));
      })
      .catch((err) => {
        dispatch(loaderForScenario(false));
        dispatch(toast("Something went wrong!", true, 2000, "error"));
      });
  }

  useEffect(() => {
    dispatch(resetAllMixFilters());
    dispatch(resetMixUserInputs());
    dispatch(resetMixConstraintTable());
    dispatch(resetMixSimulationGeoLevelTableData());
    dispatch(resetMixSimulationPortfolioTableData());
    dispatch(
      resetMixConstrainCsvDataOfCapacityConstraintAndOperativeComplexity()
    );
    getAllScenarios();
  }, []);

  function isViewDisabled(status) {
    switch (status) {
      case "Failed":
        return true;
      case "In Progress":
        return true;
      case "Initiated":
        return true;
      case "Completed":
        return false;
      case "Initiating":
        return true;
      default:
        return true;
    }
  }
  function isEditDisabled(status) {
    switch (status) {
      case "Failed":
        return true;
      case "In Progress":
        return true;
      case "Initiated":
        return false;
      case "Completed":
        return false;
      case "Initiating":
        return true;
      default:
        return true;
    }
  }

  function mapStatus(status) {
    switch (status) {
      case 0:
        return "Failed";
      case 1:
        return "Completed";
      case 2:
        return "In Progress";
      case 3:
        return "Initiated";
      case 4:
        return "Initiating";
      default:
        return "Failed";
    }
  }

  function mapSenarioStatus(data) {
    return data.map((entry) => ({
      ...entry,
      status: mapStatus(entry.status),
    }));
  }

  useEffect(() => {
    setScenarios(mapSenarioStatus(mixScenarioLibraryData));
  }, [mixScenarioLibraryData]);

  const columnsInScenario: any = [
    {
      field: "scenarioName",
      headerAlign: "center",
      headerName: "Scenario Name",
      flex: 1,
      align: "center",
      type: "string",
      cellClassName: "scenarios-cell",
    },
    {
      field: "createdOn",
      headerName: "Created on",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "dateTime",
    },
    {
      field: "dateTimePeriod",
      headerName: "Time Period",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Actions",
      align: "center",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Grid
            container
            justifyContent={"center"}
            display={"flex"}
            style={{ gap: "0.75rem" }}
          >
            <Grid item>
              <IconButton
                disabled={isViewDisabled(params.row.status)}
                onClick={() => handleEdit(params, "output")}
              >
                <VisibilityIcon
                  style={{
                    color: isViewDisabled(params.row.status)
                      ? "grey"
                      : "inherit",
                  }}
                />
              </IconButton>

              {/* <ViewEditButton disabled={isEnable(params.row.status)} onClick={() => handleEdit(params)}> View/Edit </ViewEditButton> */}
            </Grid>
            <Grid item>
              <IconButton
                disabled={isEditDisabled(params.row.status)}
                onClick={() => handleEdit(params, "edit")}
              >
                <EditIcon
                  style={{
                    color: isEditDisabled(params.row.status)
                      ? "grey"
                      : "inherit",
                  }}
                />
              </IconButton>
              {/* <Divider color='#dfdfdf' sx={{ height: 5, m: 1 }} orientation="vertical" /> */}
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => handleDelete(params)}
                style={{ color: "#25d4cf" }}
                aria-label="delete"
                size="small"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ backgroundColor: "#f1f5fe", position: "relative" }}>
        <Indicator position="absolute" show={loading} />
        <CardContent>
          <Typography variant="h6" sx={{ color: "black" }}>
            Mix Simulator
          </Typography>
          <SearchScenario
            inputValue={handleInputChange}
            placeholder={"Search for a scenario by name"}
            onRefresh={getAllScenarios}
            isAssortment={false}
          />
          <DataGridTables
            isScenarioLibrary={true}
            filterableColumns={[]}
            sortableColumns = {[]}
            rows={scenarios}
            columns={columnsInScenario}
            disableColumnMenu={true}
            style={styleForScenarioDataGrid}
            autoPageSize={true}
            rowCount={scenarios.length}
            paginationMode="client"
            callback={(row) => row.uid}
            loading={false}
          ></DataGridTables>
        </CardContent>
        <ConfirmDialog
          deleteText={deleteText}
          open={open}
          handleClose={handleClose}
        />
      </Card>
    </>
  );
};

export default MixScenarioLibrary;
