export const mapperForKeys = {
  uniqueId: "Sr No.",
  chnl: "Channel",
  place: "Region",
  segment: "Store Segment",
  segments: "Segment",
  subBrand: "Sub Brand",
  packSize: "Pack Size",
  revenuMix: "Revenue Mix(%)",
  assortmentOverAll: "Assortment score(%)",
  assortmentOverall: "Assortment score (%)",
  mustHaveDistributionScore: "Must have distribution score(%)",
  goodToHaveDistributionScore: "Good to have distribution score(%)",
  netRevenueSOP: "SOP Revenue",
  nonPBT_SOP: "SOP NOPBT",
  // "promotionScore": "Promotion score(%)",
  priceScore: "Price score(%)",
  shelfAndMerchandizingScore: "Shelf & Merchandise score(%)",
  volumeMixResult: "Volume score (%)",
  revenueMixResult: "Revenue score (%)",
  profitMixResult: "NOPBT score (%)",
  mixScore: "Mix score(%)",
  executionScore: "Execution score(%)",
  brand: "Brand",
  avgStorePenetration: "Penetration(Distribution)(%)",
  sku: "SKU",
  checkoutSku: "Checkout Sku",
  flavor: "Flavor",
  netPriceRealisationScore: "Net price realisation score(%)",
  priceChangeVsRestOfTheMarketScore:
    "Price change Vs Rest of the market score(%)",
  priceChangeVsInflationScore: "Price change Vs Inflation score(%)",
  shelfSpaceScore: "Shelf space score(%)",
  exhibitionScore: "Exhibition score(%)",
};

export const portfolioHeaderOrder = [
  "Segment",
  "Brand",
  "Sub Brand",
  "Flavor",
  "Pack Size",
  "SKU",
  "Checkout SKU",
  "Assortment score (%)",
  "Must have distribution score(%)",
  "Good to have distribution score(%)",
  "Volume score (%)",
  "Revenue score (%)",
  "NOPBT score (%)",
  "Mix score(%)",
  "Revenue Mix(%)",
  "SOP Revenue",
  "SOP NOPBT",
  "Penetration(Distribution)(%)",
  "Execution score(%)",
  "Price score(%)",
  "Net price realisation score(%)",
  "Price change Vs Rest of the market score(%)",
  "Price change Vs Inflation score(%)",
  "Shelf & Merchandise score(%)",
  "Shelf space score(%)",
  "Exhibition score(%)",
];

export const geoHeaderOrder = [
  "Channel",
  "Region",
  "Store Segment",
  "Assortment score(%)",
  "Must have distribution score(%)",
  "Good to have distribution score(%)",
  "Volume score (%)",
  "Revenue score (%)",
  "NOPBT score (%)",
  "Mix score(%)",
  "Revenue Mix(%)",
  "SOP Revenue",
  "SOP NOPBT",
  "Penetration(Distribution)(%)",
  "Execution score(%)",
  "Price score(%)",
  "Net price realisation score(%)",
  "Price change Vs Rest of the market score(%)",
  "Price change Vs Inflation score(%)",
  "Shelf & Merchandise score(%)",
  "Shelf space score(%)",
  "Exhibition score(%)",
];

export const scoreColumns = [""];

export const handleDownloadCSV = (data, val) => {
  const transformHeader = data.map((ele) => {
    const requiredHeaders = {};
    const headerOrder = val === "geo" ? geoHeaderOrder : portfolioHeaderOrder;
    for (let key of headerOrder) {
      const mappedKey = Object.keys(mapperForKeys).find(
        (k) => mapperForKeys[k] === key
      );
      if (mappedKey) {
        if (mappedKey === "uniqueId") {
          continue;
        } else if (scoreColumns.includes(mappedKey)) {
          requiredHeaders[key] = formatNumber(ele[mappedKey], 0);
        } else {
          requiredHeaders[key] = ele[mappedKey];
        }
      }
    }
    return requiredHeaders;
  });
  return transformHeader;
};

export const formatNumber = (value, decimalPoints) => {
  let absValue = Math.abs(value);
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixIndex = 0;

  while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
    absValue /= 1000;
    suffixIndex++;
  }

  const formattedValue = value >= 0 ? absValue : -absValue;
  const suffix = suffixes[suffixIndex];

  return `${formattedValue.toFixed(decimalPoints)} ${suffix}`;
};

export const splitArray = (allKeys, lastTriggeredKey) => {
  const index = allKeys.findIndex((item) => item === lastTriggeredKey);
  if (index !== -1) {
    const unionKeys = allKeys.slice(0, index + 1);
    return unionKeys;
  }
  return allKeys;
};

export const checkCsv = (csv: any) => {
  return false;
};
