import {
  GET_GEO_FILTERS,
  GET_GEO_FILTERS_SUCESS,
  GET_GEO_FILTERS_FAILURE,
  CLEAR_GEO_FILTERS,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCESS,
  GET_COUNTRIES_FAILURE,
  USER_LOGIN_STATE,
} from "../actions/constants";
import _ from "lodash";

const isUserLogin =true

export let geoInitialState = {
  countries: null,
  countriesLoading: false,
  countriesError: null,
  userLogin: isUserLogin,
  geoLevel: null,
  geoLevelLoading: false,
  geoLevelError: null,
};

const geoFilter = (state = geoInitialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: null,
        countriesLoading: true,
        countriesError: null,
      };
    case GET_COUNTRIES_SUCESS:
      return {
        ...state,
        countries: action.payload,
        countriesLoading: false,
        countriesError: null,
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: null,
        countriesLoading: false,
        countriesError: action.payload,
      };
    case GET_GEO_FILTERS:
      return {
        ...state,
        geoLevel: null,
        geoLevelLoading: true,
        geoLevelError: null,
      };
    case GET_GEO_FILTERS_SUCESS:
      return {
        ...state,
        geoLevels: action.payload,
        geoLevelLoading: false,
        geoLevelError: null,
      };
    case GET_GEO_FILTERS_FAILURE:
      return {
        ...state,
        geoLevel: null,
        geoLevelLoading: false,
        geoLevelError: action.payload,
      };
    case CLEAR_GEO_FILTERS:
      return {
        ...geoInitialState,
        countries: state.countries,
      };
    case USER_LOGIN_STATE:
      return {
        ...state,
        userLogin: action.payload,
      };

    default:
      return state;
  }
};

export default geoFilter;
