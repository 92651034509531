export const chartConfigForAssortment: any = {
  chart: {
    type: "waterfall",
    height: 250,
    backgroundColor: "#F1F5FE",
  },
  credits: { enabled: false },
  title: {
    text: "",
  },
  tooltip: {
    pointFormatter: function (): string {
      const suffixes = ["", "K", "M", "B", "T"];
      const tier = (Math.log10(Math.abs(this.y)) / 3) | 0;
      if (tier === 0) return this.y.toFixed(2);

      let suffix = suffixes[tier];
      const scale = Math.pow(10, tier * 3);
      const scaled = this.y / scale;
      if (suffix === undefined) {
        suffix = "";
      }
      let checkBarName = this.name;
      const level = this.level;
      let returnVal;
      if (
        level != "marketShare" &&
        (checkBarName === "Good to have SKUs Incremental" ||
          checkBarName === "Must have SKUs Incremental")
      ) {
        returnVal =
          `${this.name}<br>` +
          `${scaled.toFixed(2)}${suffix}\n` +
          `(${(Number(this.percentVal) * 100).toFixed(2)}%)`;
        console.log("kkkk 1", returnVal);
      } else {
        returnVal = `${this.name}<br>` + scaled.toFixed(2) + suffix;
        console.log("kkkk 2", returnVal);
      }
      return returnVal;
    },
  },
  legend: {
    enabled: false,
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 20,
    symbolWidth: 20,
    symbolRadius: 0,
    itemStyle: {
      fontSize: "16px",
    },
  },
  plotOptions: {
    series: {
      pointWidth: 200,
      borderWidth: 0,
      tooltip: {
        pointFormatter: function (): string {
          const suffixes = ["", "K", "M", "B", "T"];
          const tier = (Math.log10(Math.abs(this.y)) / 3) | 0;
          if (tier === 0) return this.y.toFixed(2);
          let suffix = suffixes[tier];
          const scale = Math.pow(10, tier * 3);
          const scaled = this.y / scale;
          if (suffix === undefined) {
            suffix = "";
          }
          return scaled.toFixed(2) + suffix;
        },
      },
      dataLabels: {
        enable: false,
      },
      column: {
        borderRadius: 5,
      },
    },
    waterfall: {
      dataLabels: {
        enabled: true,
        formatter: function (): string {
          if (this.y === 0 && ((Math.log10(Math.abs(this.y)) / 3) | 0) === 0) {
            return "0";
          }
          
          const suffixes = ["", "K", "M", "B", "T"];
          const tier = (Math.log10(Math.abs(this.y)) / 3) | 0;
          if (tier === 0) {
            if (this.point.level === "marketShare") {
              return this.y.toFixed(2) + "%";
            } else {
              return this.y.toFixed(2);
            }
          }
      
          let suffix = suffixes[tier];
          const scale = Math.pow(10, tier * 3);
          const scaled = this.y / scale;
          if (suffix === undefined) {
            suffix = "";
          }
          let checkBarName = this.point.options.name;
          const level = this.point.level;
          let returnVal;
          if (
            level != "marketShare" &&
            (checkBarName === "Good to have SKUs Incremental" ||
              checkBarName === "Must have SKUs Incremental")
          ) {
            returnVal =
              scaled.toFixed(2) +
              suffix +
              ` (${(Number(this.point.percentVal) * 100).toFixed(2)} %)`;
          } else {
            returnVal = scaled.toFixed(2) + suffix;
          }
          return returnVal;
        },
      },
      
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    title: {
      enabled: false,
    },
    gridLineWidth: 0,
    visible: false,
  },
  series: [],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
