import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  AccordianSubTitle,
  AccordianTitle,
  ExpandIcon,
} from "./AssortmentFiltersAccordian.Styled";
import { BlackTitle } from "../../../src/styles/Common.Styled";

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: "0 !important",
    boxShadow: "none !important",
    "&:before": {
      backgroundColor: "#F1F5FE",
      opacity: 0.1,
    },
  },
  summary: {
    backgroundColor: "#F1F5FE",
  },
}));

const AssortmentFiltersAccordian: React.FC<{
  title?;
  children;
  showSkeleton?: boolean;
  defaultOpen?: boolean;
  subTitle?;
}> = ({
  title,
  children,
  showSkeleton = false,
  defaultOpen = false,
  subTitle,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      data-testid="accordion"
      className={classes.accordion}
      defaultExpanded={defaultOpen}
    >
      <AccordionSummary className={classes.summary} expandIcon={<ExpandIcon />}>
        {showSkeleton ? (
          <Skeleton variant="rectangular" width={210} height={40} />
        ) : (
          <>
            <AccordianTitle>{title}</AccordianTitle>
            <AccordianSubTitle style={{marginTop:"3px", marginLeft:"10px"}}>{subTitle}</AccordianSubTitle>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.summary}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AssortmentFiltersAccordian;
