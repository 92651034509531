import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import ProductFiltersV4 from "../../../../components/ProductFilters-v4/ProductFilters-v4";
import Indicator from "../../../../components/Loader/Loader";
import mockData from "../../../../../src/mocks/mixMocks";
import { RedBtn } from "../../../AssortmentTool/components/AssortmentOverallFilters/AssortmentOverallFilters.Styled";
import MixFiltersAccordian from "../../../../components/MixFiltersAccordian/MixFiltersAccordian";
import { useDispatch, useSelector } from "react-redux";
import MixFilterSelectInput from "../MixFilterSelectInput/MixFilterSelectInput";
import { FilterGrid, FilterLabel } from "../../../ExecutionTool/components/ExecutionCommon.Styled";
import MixSkeletons from "../../MixSkeletons/MixSkeletons";
import { DropdownTitle } from "../../../../components/GeoFilters-V4/GeoFilters-V4.Styled";
import AssortmentDropdown from "../../../../components/AssortmentDropdown";
import { fetchMixSimulationFiltersData, fetchMixSimulationFiltersRequest, getGeoFilterData, selectedMixSimulationFilters, setSelectedDateTimeperiodDetails } from "../../../../store/actions/MixSimulationTool/mixSimulatorFilter.action";
import { dispatchState, fetchApi } from "../../../../store/actions/ExecutionTool/portfolioGeoLevel.action";
import { toast } from "../../../../store/actions/toast.action";
import { BlackFilledInfo } from "../../../../assets/icons/assorticons";
import DataSourcePopup from "../../../AssortmentTool/components/CommonComponents/DataSourcePopup";
import { API_URL } from "../../../../store/actions/constants";

const FilterLabelMapper = {
  country: 'Country',
  period: 'Date Time Period',
  businessUnit: 'Business Unit',
  channel: 'Channel',
  region: 'Region',
  storeSegment: 'Store Segment',
  store: 'Store'
}
const OVER_ALL = "overall";

const MixOverallFilters: React.FC<{
  showSkeleton?: boolean;
  isOutputScreen?: boolean;


}> = ({ showSkeleton, isOutputScreen }) => {

  const mixDataSource = [
    { id: 1, label: "SMKT", value: "RSI" },
    { id: 2, label: "DTS/C-Stores", value: "INASE" },
    { id: 3, label: "WHSL", value: "ISCAM" },
    { id: 4, label: "Industry Data", value: "NST/NRI" },
    { id: 5, label: "Financial Data", value: "BPC" },
  ];


  const filters = useSelector((state: any) => state.mixSimulationAllFilter.data.overall);
  const filtersOptions = useSelector((state: any) => state.mixSimulationAllFilter.selectedFilters);
  const { loading, selectedFilters, isEditMode } = useSelector((state: any) => state.mixSimulationAllFilter);
  const dispatch = useDispatch();
  const { outputScreen } = useSelector(
    (state: any) => state.mixSimulationAllFilter
  );


  const [accordianTimePeriod, setAccordianTimePeriod] = useState("");
  const onChangeGeoLevel = (filterName, selectionData) => {

    const overallFilters = {
      "country": selectedFilters.country,
      "period": selectedFilters.period.length > 1 ? selectedFilters.period[0] : "",
      "businessUnit": selectedFilters.businessUnit
    }

    const geo = {
      "channel": selectedFilters.channel,
      "region": selectedFilters.region,
      "storeSegment": selectedFilters.storeSegment
    };


    dispatch(
      selectedMixSimulationFilters({ name: filterName, value: selectionData })
    );

    const payload = {
      overallFilters: { ...overallFilters, [filterName]: filterName == 'period' ? selectionData[0] : selectionData },
      geo,
      default: false
    }

    switch (filterName) {
      case 'country':
        dispatch(selectedMixSimulationFilters({ name: 'period', value: [] }));
        dispatch(selectedMixSimulationFilters({ name: 'businessUnit', value: [] }));
        dispatch(fetchMixSimulationFiltersData(payload, API_URL.MIXSIMULATION.OPTIMIZER_FILTER));
        return;
      case 'period':
        dispatch(fetchMixSimulationFiltersData(payload, API_URL.MIXSIMULATION.OPTIMIZER_FILTER));
        dispatch(selectedMixSimulationFilters({ name: 'businessUnit', value: [] }));
        return;
      default:
        // const payloadOfDynamicFilter = {
        //   "availableDynamicFilters": [
        //     "level",
        //     "sub_chnl",
        //     "geo_level",
        //     "store_segment"
        //   ],
        //   "availableDynamicFiltersSelectedValues": {
        //     "level":[],
        //     "sub_chnl": [],
        //     "geo_level": [],
        //     "store_segment": []
        //   },
        //   "overallFiltersSelectedValues": {
        //     "country": selectedFilters.country,
        //     "time_period": selectedFilters.period,
        //     "business_unit": payload.overallFilters.businessUnit
        //   },
        //   "toolName": "mix_optimizer",
        //   "pageName": "mix_optimizer"
        // };

        // let dispatchAction: dispatchState = {
        //   loaderStateFetch: fetchMixSimulationFiltersRequest()
        // }

        // dispatch(fetchApi(payloadOfDynamicFilter,"filter/mixOptimizerDynamicFilter",dispatchAction))
        // .then(res => {
        //   dispatch(getGeoFilterData(res.data))
        // })
        // .catch(err => {
        //   dispatch(toast('Something Went Wrong!', true, 2000, 'error'));
        // })
        return
    }


  }
  const [open, setOpen] = useState(false);
  const openDataSourceInfo = () => {
    setOpen((isOpen) => !isOpen);
  };
  const getAccordianTitle = (res) => {
    const {startMonth , endMonth, startYear, endYear} = res.data;
    console.log(res)
    return `${startMonth} ${startYear}-${endMonth} ${endYear}`;
  };
  useEffect(() => {
    const { period, country } = filtersOptions;
    const payload =
    {
      "overallFilters": {
        "country": 
          country,
        "period": period[0],
    
      }
    }
    console.log(payload);
    if(period.length>0){
      dispatch(
        fetchApi(payload, API_URL.MIXSIMULATION.OPTIMIZER_DATA_PERIOD, null)
      ).then((res) => {
        if (res["status"] === 200) {
          dispatch(setSelectedDateTimeperiodDetails(res.data))
          setAccordianTimePeriod(getAccordianTitle(res));
        } else {
          console.log("error", res);
        }
      });
    }
  }, [filtersOptions.period]);

  return (
    <Card
      className="m-b-20"
      style={{
        marginTop: "3%",
        position: "relative",
        backgroundColor: "#F1F5FE",
      }}
    >

      <Indicator position="absolute" show={loading} />
      {open && (
        <DataSourcePopup isOpen={open} setIsOpen={setOpen} data={mixDataSource}></DataSourcePopup>
      )}
      <CardContent style={{ pointerEvents: isEditMode ? 'none' : 'auto', opacity: isEditMode ? '0.7' : '1' }}>
        {(
          <>
            {/* <Grid
          style={{
            position: "absolute",
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17.57px",
            letterSpacing: "0.4px",
            right: "6px",
            zIndex: 10,
            color: "#7E7E7E",
          }}
        >
          Data Sources{" "}
          <img
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={openDataSourceInfo}
            src={BlackFilledInfo}
            alt=""
          />
        </Grid> */}
            <MixFiltersAccordian
              title="Overall-Filters:"
              subTitle={accordianTimePeriod}
              defaultExpanded={true}>
              <div style={{ marginTop: "1rem", }}>

                {/* <FilterGrid>
                        {Object.keys(filters).map((item) => {
                            return <>
                            {showSkeleton ? (
                              <MixSkeletons type={'filter'}/>
                            ) : (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FilterLabel>{FilterLabelMapper[item]}<span style={{ color: 'red' }}>*</span></FilterLabel>
                                    <MixFilterSelectInput isOutputScreen={isOutputScreen} options={filters[item]} name={item} isMultiple={false} isFilter={true} ></MixFilterSelectInput>
                                </div>
                            )}
                            </>
                        })}
              </FilterGrid> */}

                <Grid container spacing={5}>
                  {Object.keys(filters).map((item) => {
                    return <>
                      {showSkeleton ? (
                        <FilterGrid>
                          <MixSkeletons type={'filter'} />
                        </FilterGrid>
                      ) : (
                        <Grid item>
                          <DropdownTitle className='m-b-10'>{FilterLabelMapper[item]} <span style={{ color: 'red' }}>*</span> </DropdownTitle>

                          <AssortmentDropdown
                            data={filters[item].map((ele, index) => ({
                              id: `${index}-${ele}-${index}`,
                              label: ele,
                              value: ele
                            }))}
                            multiple={false}
                            allOption={false}
                            onChange={(selectionData) => onChangeGeoLevel(item, selectionData)}
                            defaultOption={
                              filtersOptions[item] ? filtersOptions[item] : []
                            }
                            disabled={outputScreen || isEditMode}
                          />
                        </Grid>
                      )}
                    </>
                  })}
                </Grid>
                {/* <ProductFiltersV4
                  data={{ ...mockData.productFilterV2Config }}
                  defaultFilters={defaultFilters}
                  showLoader={setLoader}
                  clearFilter={clearFilterData}
                  onSelectFilters={handleMixFiltersChange}
                  setClearFilterData={setClearFilterData}
                /> */}
              </div>
            </MixFiltersAccordian>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MixOverallFilters;


