import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveLabel, removeLabels, removeSeriesLabels } from '../../store/actions/common.action';
const HighchartContainer: React.FC<{
  chartRef;
  id;
  children;
}> = ({ chartRef, id, children }) => {
  const dispatch = useDispatch();
  const [clickEventData, setClickEventData] = useState<any>({});
  const updateXarrow = useXarrow();
  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      chart.update({
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: function () {
                  console.log(this);

                  setClickEventData({ ...this });
                },
              },
            },
            events: {
              legendItemClick: function (e) {
                dispatch(removeSeriesLabels([...chart.series[e.target.index].data], id));
                console.log(chart);
              },
            },
          },
        },
      });
    }
  }, [chartRef]);
  useEffect(() => {
    if (Object.keys(clickEventData).length > 0) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        dispatch(addRemoveLabel([clickEventData], id, tooltips, chart));
        setClickEventData({});
      }
    }
  }, [clickEventData]);

  const open = useSelector((state: any) => state.common.sidebarOpen);
  const tooltips = useSelector((state: any) => state.common.highChartTooltips);

  useEffect(() => {
    return () => {
      dispatch(removeLabels(id));
    };
  }, []);

  useEffect(() => {
    dispatch(removeLabels(id));
  }, [open]);

  useEffect(() => {
    window.addEventListener('resize', hideToolTip);
    return () => {
      window.removeEventListener('resize', hideToolTip);
    };
  }, []);

  const hideToolTip = () => {
    dispatch(removeLabels(id));
  };

  return (
    <Xwrapper>
      <div style={{ position: 'relative' }}>
        {Object.keys(tooltips).length > 0 &&
          tooltips[id] &&
          tooltips[id].map((t, i) => (
            <div>
              <div
                id={t.id}
                style={{
                  position: 'absolute',
                  left: t.x,
                  top: t.y,
                  zIndex: 1,
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  background: t.chart.series.type === 'pie' || t.chart.series.type === 'bubble' ? t.chart.color : t.chart.series.color,
                }}
              ></div>
              <Draggable onDrag={updateXarrow} onStop={updateXarrow}>
                <div
                  className='tooltip'
                  id={`${t.id}-${i}`}
                  style={{
                    position: 'absolute',
                    left: t.x - 40,
                    top: t.y - 70,
                    // left: t.x,
                    // top: t.y,
                    zIndex: 1,
                    borderColor: t.chart.series.type === 'pie' || t.chart.series.type === 'bubble' ? t.chart.color : t.chart.series.color,
                    fontSize: '12px',
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: t.tooltip }}></span>
                </div>
              </Draggable>
              <Xarrow
                start={`${t.id}-${i}`}
                end={t.id}
                zIndex={1}
                color={t.chart.series.type === 'bubble' || t.chart.series.type === 'pie' ? t.chart.color : t.chart.series.color}
                showHead={false}
                strokeWidth={2}
                dashness={true}
                headShape={'circle'}
              />
            </div>
          ))}
      </div>
      {children}
    </Xwrapper>
  );
};
export default HighchartContainer;
