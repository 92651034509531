import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { saveTrendingPageData } from '../../store/actions/YourDashboard/trendingPage.action';

const PageContainer: React.FC<{
  page;
}> = ({ page, children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if(page && page.title){
      dispatch(saveTrendingPageData({pageName: page.title, type: 'portal'}))
    }
  }, []);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};
export default PageContainer;
