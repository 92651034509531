import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from '../../../../components/Dashboard/Dashboard.Styled';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { measureFilterData } from '../../../../mocks/macroEconDashboard';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { getMacroEconCheckData } from '../../service';
import { MacroEconCheckData } from '../../store/macroEcon.action';
import MacroEconCard from '../MacroEconCard/MacroEconCard';
import MacroEconCheck from '../MacroEconCheck/MacroEconCheck';
import { makeStyles } from '@material-ui/core/styles';
import { removeLabels } from '../../../../store/actions/common.action';
const useStyles = makeStyles(() => ({
  hide: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
}));
const MacroEconCheckContainer: React.FC<{ globalFilter: any }> = ({ globalFilter }) => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const dispatch = useDispatch();
  const [macroEconData, setMacroEconData] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [originalResponse, setOriginalResponse] = useState<any>({});
  const [periodicityList, setPeriodicityList] = useState([]);
  const [defaultPeriodicity, setDefaultPeriodicity] = useState('monthly');

  useEffect(() => {
    if (globalFilter && globalFilter.country) {
      dispatch(MacroEconCheckData(true));
      setSkeleton(true);
      setChartData({});
      setOriginalResponse({});
      setMacroEconData([]);
      setPeriodicityList([]);
      getMacroEconCheckData({ ...globalFilter })
        .then((response) => {
          if (response && response.status === 200 && Object.keys(response?.data).length > 0) {
            const periodicities = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].filter((p) => {
              if (response?.data && _.isArray(response?.data[p]) && response?.data[p].length > 0) return true;
              else {
                delete response?.data[p];
                return false;
              }
            });
            setPeriodicityList(periodicities);
            setOriginalResponse(response.data);
            setTimeout(() => {
              if (periodicities.length > 0) {
                const index = periodicities.findIndex((p) => p === 'monthly');
                const periodicity = index !== -1 ? 'monthly' : periodicities[0];
                setDefaultPeriodicity(periodicity);
              }
            }, 0);
          } else {
            setMacroEconData([]);
          }
          dispatch(MacroEconCheckData(false));
          setSkeleton(false);
        })
        .catch((err) => {
          console.log('error', err);
          dispatch(MacroEconCheckData(false));
          setSkeleton(false);
        });
    }
  }, [globalFilter]);

  const getPeriodView = () => {
    if (globalFilter && globalFilter.periodView && globalFilter.periodView) {
      let index = measureFilterData.period1.findIndex((x) => x.value === globalFilter.periodView);
      if (index !== -1) return measureFilterData.period1[index].label;
      index = measureFilterData.period2.findIndex((x) => x.value === globalFilter.periodView);
      if (index !== -1) return measureFilterData.period2[index].label;
    }
    return '';
  };

  const getCurrentView = () => {
    if (globalFilter && globalFilter.currentVs && globalFilter.currentVs) {
      let index = measureFilterData.current.findIndex((x) => x.value === globalFilter.currentVs);
      if (index !== -1) return measureFilterData.current[index].label;
    }
    return '';
  };

  const macroEconCheckLoader = useSelector((state: any) => state.MacroEconDashboard.macroEconCheckLoader);

  const [chartData, setChartData] = useState<any>({});
  const onClick = (item, i) => {
    if (chartData && chartData.data && chartData.data.id) dispatch(removeLabels(chartData.data.id));

    macroEconData.map((me: any) => {
      me.selected = false;
    });
    item.selected = true;
    setChartData({ data: item, index: i });
  };

  const setMacroEconInfletionData = (selectedDataIndex) => {
    if (chartData && chartData.data && chartData.data.id) dispatch(removeLabels(chartData.data.id));

    if (originalResponse && originalResponse[selectedDataIndex.item]) {
      const result: any = [];
      originalResponse[selectedDataIndex.item].map((item: any, i) => {
        result.push({
          ...item,
          id: `macro-econ-${item.title}`,
          percentDiff: _.isNumber(item.percentDiff)
            ? item.variableUnit === '%'
              ? item.percentDiff
              : item.percentDiff * 100
            : item.variableUnit === '%'
            ? parseFloat(item.percentDiff)
            : parseFloat(item.percentDiff) * 100,
          title: item.title.toString().toUpperCase(),
          xAxis: item.values.map((item) => item.xaxis),
          yAxis: item.values.map((item) => item.percent),
          periodView: getPeriodView(),
          currentView: getCurrentView(),
          selected: chartData && (chartData.index !== undefined || chartData.index !== null) ? chartData.index === i : i === 0,
        });
      });
      if (chartData && chartData.index !== undefined && chartData.index !== null) {
        setChartData({ data: result[chartData.index], index: chartData.index });
      } else if (result.length > 0) {
        setChartData({ data: result[0], index: 0 });
      }
      setMacroEconData(result);
    } else {
      setMacroEconData([]);
    }
  };

  return (
    <Box>
      <Card className='m-b-20' style={{ position: 'relative' }}>
        <CardContent>
          <Title style={{ font: 'normal normal 600 24px Poppins' }} className='m-b-20'>
            Macro-Economics Check
          </Title>
          {originalResponse && Object.keys(originalResponse).length > 0 && (
            <Grid style={{ position: 'absolute', marginTop: '820px', paddingRight: '820px', zIndex: 5 }} className={classes.hide}>
              <IncrementDecrement
                data={originalResponse}
                list={periodicityList}
                callback={setMacroEconInfletionData}
                defaultOption={
                  (localStorage.getItem('periodicity') && JSON.parse(localStorage.getItem('periodicity'))['macro-econ-check-container-chart']?.item) ||
                  defaultPeriodicity ||
                  null
                }
                id='macro-econ-check-container-chart'
              />
            </Grid>
          )}
          {!skeleton && !macroEconCheckLoader ? (
            _.isArray(macroEconData) && macroEconData.length > 0 ? (
              <>
                <Grid
                  display='inline-flex'
                  justifyContent={macroEconData.length === 5 && 'space-between'}
                  style={{ overflowX: 'auto', width: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)' }}
                  className='m-b-20'
                >
                  {macroEconData.map((item, i) => (
                    <Grid
                      item
                      sx={{ width: '400px' }}
                      onClick={() => {
                        onClick(item, i);
                      }}
                      className={`cursor-pointer m-b-20 ${macroEconData.length < 5 && 'm-r-20'}`}
                    >
                      <MacroEconCard data={item} skeleton={skeleton} />
                    </Grid>
                  ))}
                </Grid>
                {chartData && chartData.data ? <MacroEconCheck data={{ ...chartData.data }} skeleton={skeleton} /> : null}
              </>
            ) : (
              <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
                <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
              </Grid>
            )
          ) : (
            <>
              <Grid
                display='inline-flex'
                justifyContent='space-between'
                className='m-b-20'
                style={{ overflowX: 'auto', width: sidebarOpen ? `calc(100vw - 323px)` : 'calc(100vw - 156px)' }}
              >
                {[1, 2, 3, 4, 5].map(() => (
                  <Grid display='inline-flex' className='m-b-20 m-r-10'>
                    <Skeleton variant='rectangular' height={400} width={400} />
                  </Grid>
                ))}
              </Grid>

              <Box className='m-b-20'>
                <MacroEconCheck data={1} skeleton={skeleton} />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default MacroEconCheckContainer;
