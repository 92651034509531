import React, { useState } from "react";
import {
  Grid,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TableSortLabel,
  Skeleton,
  TextField,
  Tooltip,
  TablePagination,
} from "@mui/material";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "./CustomTable.Styled";
import _ from "lodash";
import { TableCell } from "../../types/common";
import { formatNumber } from "../../util/helper";
import { Box } from "@material-ui/core";

const CustomTable: React.FC<{
  heading;
  value;
  showSkeleton?: boolean;
  headingBgColor?;
  headingColor?;
  headerAlign?;
  dataAlign?;
  callback?;
  onCellKeyDown?;
  onCellBlur?;
  isFormatNumber?: boolean;
  ellipsis?: boolean;
  stickyHeader?: boolean;
  customTableCellStyle?;
  headerStyle?;
  maxHeight?;
  pagination?; 
  totalRows?; 
  paginationCallback?
}> = ({
  heading,
  value,
  showSkeleton,
  headingBgColor,
  headingColor,
  headerAlign = "center",
  dataAlign = "center",
  callback,
  onCellKeyDown,
  onCellBlur,
  isFormatNumber = true,
  ellipsis = false,
  stickyHeader = false,
  customTableCellStyle,
  headerStyle,
  maxHeight,
  pagination={
    enable: false,
    rowsPerPage: 20
  }, 
  totalRows, 
  paginationCallback
}) => {
  const [direction, setDirection] = useState("asc");
  const onDirection = () => {
    if (direction === "asc") {
      setDirection("desc");
    } else {
      setDirection("asc");
    }
  };

  const renderControls = (type, value, row, column, columnName, width?) => {
    if (type === "Text") {
      return (
        <TextField InputProps={{ autoComplete: 'off' }}
          id="proposed-price"
          type="number"
          size="small"
          value={_.isNumber(value) ? value.toFixed(2) : value}
          onKeyDown={(e: any) => {
            onCellKeyDown({
              row,
              column,
              columnName,
              value: (e.target && e.target.value) || 0,
              event: e,
            });
          }}
          onBlur={() =>
            onCellBlur({
              row,
              column,
              columnName,
              value,
            })
          }
          onChange={(e) => {
            const { value } = e.target;
            // check if value includes a decimal point
            if (value.match(/\./g)) {
              const [, decimal] = value.split(".");
              if (decimal?.length > 2) {
                return;
              }
            }
            callback({
              row,
              column,
              columnName,
              value,
            });
          }}
          style={{ width: width ? width : 90 }}
        />
      );
    }
  };

  const fetchImage = (payload, name) => {
    if (payload.src) {
      return (
        <Tooltip
          disableHoverListener={!payload.tooltip}
          title={payload.tooltip}
        >
          <div>
            <img
              src={payload.src}
              height={payload.height}
              width={payload.width}
              alt={payload.alt}
            />
            {payload.tooltip ? (
              <div style={{ color: "#929292" }}>{payload.tooltip}</div>
            ) : null}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          disableHoverListener={!payload.tooltip}
          title={payload.tooltip}
        >
          <Box className="img-error-box">
            <span>{name}</span>
            {payload.tooltip ? (
              <div style={{ color: "#929292" }}>{payload.tooltip}</div>
            ) : null}
          </Box>
        </Tooltip>
      );
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.enable? pagination?.rowsPerPage : 20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginationCallback({page: newPage, rowsPerPage});
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    paginationCallback({page: 0, rowsPerPage: parseInt(event.target.value)});
  };

  return (
    <>
    <TableContainer style={{ maxHeight: maxHeight ? maxHeight : null }}>
      <StyledTable stickyHeader={stickyHeader}>
        {!showSkeleton ? (
          <>
            <TableHead>
              <TableRow>
                {heading.map((item) => (
                  <StyledTableCell
                    align={headerAlign}
                    colSpan={item.colSpan}
                    data-testid="tableHeader"
                    style={{
                      backgroundColor: headingBgColor,
                      color: headingColor,
                      whiteSpace: "nowrap",
                      padding: item.padding ? item.padding : null,
                    }}
                  >
                    {false ? (
                      <TableSortLabel
                        // direction={direction}
                        onClick={onDirection}
                      >
                        <Grid display="flex" justifyContent="space-between">
                          <Grid>{item.title}</Grid>
                          {item.subTitle && (
                            <Grid style={{ fontSize: 10, color: "gray" }}>
                              {item.subTitle}
                            </Grid>
                          )}
                        </Grid>
                      </TableSortLabel>
                    ) : (
                      <>
                        <Tooltip title={item.title}>
                          <Grid
                            style={{
                              width:
                                headerAlign === "center"
                                  ? "100%"
                                  : item.width
                                  ? item.width
                                  : "",
                              ...(headerAlign === "center"
                                ? {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }
                                : {}),
                              fontSize: headerStyle ? headerStyle.fontSize : "",
                            }}
                          >
                            <span
                              style={
                                ellipsis
                                  ? { width: item.width ? item.width : "150px" }
                                  : {}
                              }
                              className={ellipsis ? "ellipsis" : ""}
                            >
                              {item.title}
                            </span>
                          </Grid>
                        </Tooltip>
                        {item.subTitle && (
                          <Grid style={{ fontSize: "16px", color: "gray" }}>
                            {item.subTitle}
                          </Grid>
                        )}
                      </>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map((row, i) => (
                <StyledTableRow key={i} data-testid="tableBodyRows">
                  {Object.keys(row).map((key, j) => {
                    let cellData: TableCell = row[key];
                    return (
                      <StyledTableCell
                        style={{
                          backgroundColor:
                            _.isObject(cellData) && cellData.bgcolor,
                          color: _.isObject(cellData) && cellData.color,
                          maxWidth: _.isObject(cellData) && cellData.width,
                          ..._.isObject(cellData) && cellData.style? cellData.style : {}
                        }}
                        align={dataAlign}
                        rowSpan={
                          cellData?.rowspan && cellData.rowspan
                            ? cellData.rowspan
                            : null
                        }
                        colSpan={
                          cellData?.colspan && cellData.colspan
                            ? cellData.colspan
                            : null
                        }
                        className={customTableCellStyle}
                      >
                        {_.isObject(cellData)
                          ? cellData.editable
                            ? renderControls(
                                cellData.type,
                                cellData.value,
                                i,
                                j,
                                key,
                                cellData.width
                              )
                            : _.isNumber(cellData.value) && isFormatNumber
                            ? formatNumber(cellData.value)
                            : cellData.image
                            ? fetchImage(cellData.image, cellData.name)
                            : cellData.value
                          : _.isNumber(cellData) && isFormatNumber
                          ? formatNumber(cellData)
                          : _.isNumber(cellData)
                          ? Math.round(cellData)
                          : cellData}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                {heading.map((item) => (
                  <StyledTableCell
                    align="center"
                    colSpan={item.colSpan}
                    style={{
                      backgroundColor: headingBgColor,
                      color: headingColor,
                    }}
                  >
                    <Skeleton />
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map((row, i) => (
                <StyledTableRow key={i}>
                  {Object.keys(row).map(() => (
                    <StyledTableCell align="center">
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </>
        )}
      </StyledTable>
    </TableContainer>
    {pagination && pagination.enable && !showSkeleton?
      <TablePagination
        component="Grid"
        count={totalRows}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    : null }
    </>
  );
};

export default CustomTable;
