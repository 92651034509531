import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { GraphSubTitle } from "../../HistoricPriceAndCrossPriceElasticity.Styled";
import CrossPriceEleasticityChart from "./CrossPriceEleasticityChart";
import { fetchHistoricPriceElasticityFilterData } from "../../service";
import { useDispatch } from "react-redux";
import { priceElasticityLocalFilter } from "../../store/historicPrice.action";
import { BlackTitle } from "../../../../styles/Common.Styled";

const CrossPriceEleasticityContainer: React.FC<{
  localFilter;
  filters;
}> = ({ localFilter, filters }) => {
  const [pepsiProd, setpepsiProducts] = useState([]);
  const [compeProducts, setcompeProducts] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [title, setTitle] = useState("");
  const [isImputed, setIsImputed] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    setpepsiProducts([]);
    setcompeProducts([]);
    setSkeleton(true);
    if (
      Object.keys(localFilter).length !== 0 &&
      (localFilter.category !== null || localFilter.competitorCategory !== null)
    ) {
      if (
        filters &&
        localFilter &&
        Object.keys(filters).length > 0 &&
        Object.keys(localFilter).length > 0
      ) {
        fetchCrossPriceProductData();
        const title =
          filters && filters.anchorPackSize
            ? `${filters.anchorSubBrand} ${filters.anchorPackSize}`
            : filters.anchorSubBrand
            ? filters.anchorSubBrand
            : filters?.anchorBrand;
        setTitle(title);
      }
    }
  }, [localFilter, filters]);

  const fetchCrossPriceProductData = async () => {
    const payload = { ...filters, ...localFilter };
    await fetchHistoricPriceElasticityFilterData(payload)
      .then((response) => {
        if (
          typeof response.data.pepsi !== "undefined" &&
          typeof response.data.competitor !== "undefined"
        ) {
          setpepsiProducts([...response.data.pepsi]);
          setcompeProducts([...response.data.competitor]);
          setIsImputed({
            pepsiImputed: response.data.pepsi.isImputed,
            competitorImputed: response.data.competitor.isImputed,
          });
          setSkeleton(false);
          dispatch(priceElasticityLocalFilter(false));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    setSkeleton(true);
  }, [filters]);

  return (
    <Grid>
      <BlackTitle sx={{ fontSize: 20, fontWeight: 600, mb: 3 }}>
        Cross Price Elasticity by Quarterly Model Updated: VS
        <Typography
          component="span"
          sx={{
            color: "#25d4cf",
            fontSize: 20,
          }}
        >
          {" "}
          {title}
        </Typography>
      </BlackTitle>
      <Grid>
        <GraphSubTitle>Pepsi Products</GraphSubTitle>
        <CrossPriceEleasticityChart
          data={pepsiProd}
          id="historic-price-track-cross-eleasticity-pepsi"
          isImputed={isImputed.pepsiImputed}
          skeleton={skeleton}
          seriesColor={"#0c98cf"}
        />
      </Grid>
      <Grid className="m-t-30">
        <GraphSubTitle>Competitor Products</GraphSubTitle>
        <CrossPriceEleasticityChart
          data={compeProducts}
          id="historic-price-track-cross-eleasticity-competitor"
          isImputed={isImputed.competitorImputed}
          skeleton={skeleton}
          seriesColor={"#ce0467"}
        />
      </Grid>
    </Grid>
  );
};

export default CrossPriceEleasticityContainer;
