import _ from 'lodash';
import { DateTime } from 'luxon';
import $ from 'jquery';
import { OktaAuth, IdxStatus, urlParamsToObject, hasErrorInUrl } from '@okta/okta-auth-js';
import { toast } from '../store/actions/toast.action';
import { loader } from '../store/actions/common.action';
import { IDefaultFiltersAPIPayload } from '../types/common';
import permissions from './roleConfig.json';
const axios = require('axios');

export const test = process.env.REACT_APP_AUTH_URL;
export const oktaAuth: OktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_AUTH_URL,
  clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
});

export const uniqueColors = [
  '#00FF00',
  '#0000FF',
  '#FF0000',
  '#01FFFE',
  '#FFA6FE',
  '#FFDB66',
  '#006401',
  '#010067',
  '#29d682',
  '#95003A',
  '#007DB5',
  '#9b7b5c',
  '#56f565',
  '#FF00F6',
  '#774D00',
  '#90FB92',
  '#0076FF',
  '#D5FF00',
  '#FF937E',
  '#6A826C',
  '#FF029D',
  '#FE8900',
  '#7A4782',
  '#7E2DD2',
  '#85A900',
  '#FF0056',
  '#4b7c52',
  '#A42400',
  '#5ee5f0',
  '#00AE7E',
  '#683D3B',
  '#c5e344',
  '#BDC6FF',
  '#263400',
  '#BDD393',
  '#00B917',
  '#9E008E',
  '#001544',
  '#C28C9F',
  '#FF74A3',
  '#01D0FF',
  '#004754',
  '#E56FFE',
  '#788231',
  '#0E4CA1',
  '#91D0CB',
  '#BE9970',
  '#968AE8',
  '#BB8800',
  '#43002C',
  '#DEFF74',
  '#00FFC6',
  '#FFE502',
  '#620E00',
  '#008F9C',
  '#98FF52',
  '#7544B1',
  '#0e4c6b',
  '#B500FF',
  '#00FF78',
  '#FF6E41',
  '#005F39',
  '#6B6882',
  '#5FAD4E',
  '#A75740',
  '#e22b7e',
  '#A5FFD2',
  '#FFB167',
  '#009BFF',
  '#E85EBE',
];

export const isAuthenticated = (route) => {
  // return sessionStorage.getItem("token");
  return true;
  // return checkPermission(route);
};

const symbolArr = ['e', 'E', '+', '-'];
export const inputRegex = (event) => {
  return symbolArr.includes(event.key) && event.preventDefault();
};

export const getNotificationTemplate = (data, content) => {
  let startIndex = content.indexOf('{{');
  let endIndex = content.indexOf('}}');

  if (startIndex === -1 || endIndex === -1) {
    return content;
  }
  let key = content.substring(startIndex + 2, endIndex);
  content = content.replace(`{{${key}}}`, data[key] || '');
  return getNotificationTemplate(data, content);
};

export const paginateTable = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const setColor = (val, type): { bgcolor: string; color: string; padding?: string } => {
  if (val < 0) {
    // to make it positive
    val = val * -1;
  }
  switch (type) {
    case 'correlation':
      if (val < 50) {
        return {
          bgcolor: 'red',
          color: '#fff',
          padding: '8px',
        };
      } else if (val >= 50 && val <= 70) {
        return {
          bgcolor: '#f5f524',
          color: '#000',
          padding: '8px',
        };
      } else if (val > 70) {
        return {
          bgcolor: 'green',
          color: '#fff',
          padding: '8px',
        };
      }
      break;
  }
};

export const setRSquareColor = (val): { bgcolor: string; color: string; padding?: string } => {
  if (val < 4.99) {
    return {
      bgcolor: 'red',
      color: '#fff',
    };
  } else if (val >= 5 && val <= 17) {
    return {
      bgcolor: '#f5f524',
      color: '#000',
    };
  } else if (val > 18) {
    return {
      bgcolor: 'green',
      color: '#fff',
    };
  }
};

export const setSimulationTableColor = (val): { color: string; bgcolor: string } => {
  if (val <= 0) {
    return {
      color: '#F00',
      bgcolor: null,
    };
  }
  if (val > 0) {
    return {
      color: 'green',
      bgcolor: null,
    };
  }
};

export const dataFormat = (data, format) => {
  if (_.isArray(data)) {
    return data.map((item) => DateTime.fromISO(item).toFormat(format));
  } else if (!_.isString(data)) {
    return DateTime.fromJSDate(data).toFormat(format);
  } else {
    return DateTime.fromJSDate(new Date(data)).toFormat(format);
  }
};

export var API = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

// API.interceptors.request.use(
//   function (config) {
//     if (process.env.REACT_APP_SSO_ENABLE === 'Yes') config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.status);
    if (process.env.REACT_APP_SSO_ENABLE === 'Yes') {
      oktaAuth.tokenManager
        .renew('accessToken')
        .then(function (session) {
          // existing session is now refreshed
          console.log('success', session);
        })
        .catch(function (err) {
          console.log('failed', err);
          // there was a problem refreshing (the user may not have an existing session)
        });
    }

    return Promise.reject(error);
  }
);

export const mapFilterOptions = (response, data) => {
  if (response && response.data) {
    Object.keys(response.data).map((key) => {
      if (response.data[key] && data[key]) {
        data[key].options = response.data[key];
      }
    });
  }

  return data;
};

export const disabledFiltersWithVendor = (payload, isPepsico) => {
  let data = {
    vendor: isPepsico,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  };
  const key = Object.keys(payload)[0];
  let val = payload[key];
  if (_.isArray(val) && val.length === 0) {
    val = null;
  }

  switch (key) {
    case 'vendor':
      data = {
        ...data,
        vendor: isPepsico,
        category: val === null,
      };
      break;
    case 'category':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: val === null,
      };
      break;
    case 'segment':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: val === null,
      };
      break;
    case 'brand':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: val === null,
      };
      break;
    case 'subBrand':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: val === null,
      };
      break;
    case 'packSize':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      break;
  }
  return data;
};
export const formatDefaultFilters = (keys, defaultFilters) => {
  // if(keys[0]==='competitorVendor')
  let defaultFilterAPIPayload: IDefaultFiltersAPIPayload[] = [];
  keys.forEach((key) => {
    if (defaultFilters[key]?.length > 0) {
      defaultFilterAPIPayload.push({
        name: key,
        value: defaultFilters[key],
        apiCalled: false,
        key: key,
      });
    }
  });
  return defaultFilterAPIPayload;
};
export const selectAllOptions = (response, condition?) => {
  let result = {};
  if (response && response.data) {
    Object.keys(response.data).map((key) => {
      if (response.data[key] && response.data[key].length > 0) {
        if (condition && condition[key] && condition[key].defaultSelectAll) {
          result = {
            [key]:
              condition[key].selectionLimit && condition[key].selectionLimit > 0
                ? response.data[key].filter((x, i) => i < condition[key].selectionLimit).map((x) => x.value)
                : response.data[key].map((x) => x.value),
          };
        } else {
          result = {};
        }
      }
    });
  }
  return result;
};
let chartRefData = {};
export const plotElementsOnChart = async (elements, chart, id?) => {
  const chartType = _.isArray(chart.series) && chart.series[0].type;
  const data = { ...elements };
  let Texts = [];
  const result = {};
  _.isArray(data.Text) &&
    data.Text.map((item) => {
      let ref = chart.renderer
        .label(item.text, item.x, item.y)
        .css({
          color: item.style.color,
          fontSize: item.style.fontSize,
          fontFamily: `${item.style.fontFamily} !important`,
          fontStyle: item.style.fontStyle,
          fontWeight: item.style.fontWeight,
          textDecoration: item.style.textDecoration,
        })
        .add()
        .attr({ zIndex: 5 });
      Texts.push({ item, ref });
    });
  if (_.isArray(data.Text)) {
    result['Text'] = Texts;
  }

  let Images = [];
  _.isArray(data.Image) &&
    data.Image.map((item) => {
      if (item.base64) {
        let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
        Images.push({ item, ref });
      }
    });
  if (_.isArray(data.Image)) {
    result['Image'] = Images;
  }

  let Icons = [];
  _.isArray(data.Icon) &&
    (await Promise.all(
      data.Icon.map(async (item) => {
        if (item.base64) {
          if ($(`#${item.id}`)[0]) {
            return await convertSVGToBase64(item.id, item.width, item.height).then((response: any) => {
              item.base64 = response.base64;
              let ref = chart.renderer.image(response.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
              Icons.push({ item, ref });
            });
          } else {
            let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
            Icons.push({ item, ref });
            return;
          }
        }
      })
    ));
  if (_.isArray(data.Icon)) {
    result['Icon'] = Icons;
  }

  let Shapes = [];
  _.isArray(data.Shape) &&
    (await Promise.all(
      data.Shape.map(async (item) => {
        if (item.base64) {
          let ref;
          if ($(`#${item.id}`)[0]) {
            await convertSVGToBase64(item.id, item.width, item.height).then((response: any) => {
              item.base64 = response.base64;
              ref = chart.renderer.image(response.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
            });
          } else {
            ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
          }
          Shapes.push({ item, ref });
        }
      })
    ));
  if (_.isArray(data.Shape)) {
    result['Shape'] = Shapes;
  }

  let Comments = [];
  _.isArray(data.Comment) &&
    data.Comment.map((item, i) => {
      if (item.base64) {
        let ref = chart.renderer
          .image(item.base64, item.x + 10, item.y + 10, item.width, item.height)
          .add()
          .attr({ zIndex: 5 });
        Comments.push({ item, ref });
      }
    });
  if (_.isArray(data.Comment)) {
    result['Comment'] = Comments;
  }

  if (data.chartConfig && data.chartConfig.chartBackground) {
    chart.update({
      chart: {
        backgroundColor: data.chartConfig.chartBackground,
      },
    });
  }
  if (_.isArray(data.series)) {
    const seriesColors = data.series.filter((x) => x.type === 'series');
    let index = 0;
    chart.series.map((series, i) => {
      if (
        series.type === 'spline' ||
        series.type === 'scatter' ||
        series.type === 'area' ||
        series.type === 'line' ||
        // series.type === "errorbar" ||
        // series.type === "waterfall" ||
        series.type === 'bar' ||
        series.type === 'bubble' ||
        (series.type === 'column' && !series?.userOptions?.colorByPoint)
      ) {
        const item = seriesColors[index];
        chart.series[index].update({
          color: item && item.color ? item.color : chart.series[i].color,
          // visible: true,
          // showInLegend: false,
          lineWidth: item && item.lineWidth ? item.lineWidth : chart.series[i].options.lineWidth,
          borderColor: item && item.borderColor ? item.borderColor : chart.series[i].options.borderColor,
          borderWidth: item && item.borderWidth ? item.borderWidth : chart.series[i].borderWidth,
        });
        index++;
      } else if (series.type === ' errorbar') {
        index++;
      } else {
        series.data.map((x, j) => {
          const item = seriesColors[index];
          if (item && item.color) {
            chart.series[i].data[j].update({
              color: item.color,
            });
          }
          index++;
        });
      }
    });

    let xPlotLines = [];
    chart.xAxis.map((item, i) => {
      const plotLines = _.cloneDeep(item.plotLinesAndBands);
      plotLines.map((x) => {
        x.xAxisIndex = i;
      });
      xPlotLines = [...xPlotLines, ...plotLines];
    });

    let yPlotLines = [];
    chart.yAxis.map((item, i) => {
      const plotLines = _.cloneDeep(item.plotLinesAndBands);
      plotLines.map((x) => {
        x.yAxisIndex = i;
      });
      yPlotLines = [...yPlotLines, ...plotLines];
    });

    let xIndex = 0,
      yIndex = 0;
    chart.xAxis.map((y, i) => {
      chart.xAxis[i].plotLinesAndBands.map((line) => {
        chart.xAxis[i].removePlotLine(line.id);
      });
    });
    chart.yAxis.map((y, i) => {
      chart.yAxis[i].plotLinesAndBands.map((line) => {
        chart.yAxis[i].removePlotLine(line.id);
      });
    });
    data.series.map((item) => {
      if (item.type === 'xAxisPlotLine') {
        const plotLine = xPlotLines[xIndex];
        chart.xAxis[plotLine.xAxisIndex].addPlotLine({
          ...plotLine.options,
          color: item.color,
        });
        xIndex++;
      } else if (item.type === 'yAxisPlotLine') {
        const plotLine = yPlotLines[yIndex];
        chart.yAxis[plotLine.yAxisIndex].addPlotLine({
          ...plotLine.options,
          color: item.color,
        });
        yIndex++;
      }
    });
  }

  let CustomIcons = [];
  _.isArray(data.CustomIcon) &&
    data.CustomIcon.map((item) => {
      if (item.base64) {
        let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
        CustomIcons.push({ item, ref });
      }
    });
  if (_.isArray(data.CustomIcon)) {
    result['CustomIcon'] = CustomIcons;
  }

  if (id) {
    let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
    chartRefData = {
      ...dashbaordData,
      ...chartRefData,
      [id]: result ? result : dashbaordData ? dashbaordData[id] : null,
    };
  }
  return;
};

export const removeElementsOnChart = (id) => {
  if (chartRefData && chartRefData[id]) {
    const chartData = chartRefData[id];
    if (_.isObject(chartData)) {
      if (chartData && chartData.Text) {
        chartData.Text.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
      if (chartData && chartData.Image) {
        chartData.Image.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
      if (chartData && chartData.Icon) {
        chartData.Icon.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
      if (chartData && chartData.Shape) {
        chartData.Shape.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
      if (chartData && chartData.Comment) {
        chartData.Comment.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
      if (chartData && chartData.CustomIcon) {
        chartData.CustomIcon.map((x) => {
          if (x.ref) {
            x.ref.destroy();
            delete x.ref;
          }
        });
      }
    }
  }
};

export const convertToInternationalCurrency = (currency, labelValue, initial?, decimal?) => {
  if (!decimal) {
    decimal = 2;
  }
  // // Nine Zeroes for Billions
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? `${currency}${separator((Math.abs(Number(labelValue)) / 1.0e9).toFixed(2))} Billion`
  //   : // Six Zeroes for Millions
  //   Math.abs(Number(labelValue)) >= 1.0e6
  //     ? `${currency}${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(2)} Million`
  //     : // Three Zeroes for Thousands
  //     Math.abs(Number(labelValue)) >= 1.0e3
  //       ? `${currency}${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(2)} Thousand`
  //       : `${currency}${Math.abs(Number(labelValue)).toFixed(2)}`;

  const sign = 0 > Number(labelValue) ? '-' : '';
  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    const number = (Math.abs(Number(labelValue)) / 1.0e9).toFixed(decimal);
    return `${currency}${sign}${separator(number)} ${initial ? 'B' : 'Billion'}`;
  } else if (Math.abs(Number(labelValue)) >= 1.0e6) {
    const number = (Math.abs(Number(labelValue)) / 1.0e6).toFixed(decimal);
    return `${currency}${sign}${separator(number)} ${initial ? 'M' : 'Million'}`;
  } else if (Math.abs(Number(labelValue)) >= 1.0e3) {
    const number = (Math.abs(Number(labelValue)) / 1.0e3).toFixed(decimal);
    return `${currency}${sign}${separator(number)} ${initial ? 'K' : 'Thousand'}`;
  } else {
    const number = Math.abs(Number(labelValue)).toFixed(decimal);
    return `${currency}${sign}${separator(number)}`;
  }
};

export const convertToInternationalCurrencySingleChar = (currency, labelValue) => {
  const sign = _.isNative(labelValue) ? '-' : '';
  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    const number = (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2);
    return `${currency}${sign}${separator(number)}B`;
  } else if (Math.abs(Number(labelValue)) >= 1.0e6) {
    const number = (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2);
    return `${currency}${sign}${separator(number)}M`;
  } else if (Math.abs(Number(labelValue)) >= 1.0e3) {
    const number = (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2);
    return `${currency}${sign}${separator(number)}T`;
  } else {
    const number = Math.abs(Number(labelValue)).toFixed(2);
    return `${currency}${sign}${separator(number)}`;
  }
};

export const getbase64Image = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const reader: any = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  return reader.result;
};

export const getbase64ImageOrNameBox = async (url, productName) => {
  try {
    const response = await fetch(url);
    if (response && response.status === 200) {
      const blob = await response.blob();
      const reader: any = new FileReader();
      await new Promise((resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      return { base64: reader.result };
    } else {
      return { productName };
    }
  } catch (e) {
    return { productName };
  }
};

var _URL = window.URL || window.webkitURL;
export const loadImage = (data, width?, height?) => {
  return new Promise((resolve, reject) => {
    var file = data.file,
      img;
    img = new Image();
    img.onload = async (dt) => {
      resolve({
        base64: await getbase64Image(img.src),
        width: width || img.width,
        height: height || img.height,
        image: img,
      });
    };
    img.onerror = function () {
      reject();
    };
    img.src = data.file ? _URL.createObjectURL(file) : data;
  });
};

export const convertSVGToBase64V1 = async (id, width?, height?) => {
  let svg = $(`#${id}`)[0];
  if (!svg) {
    svg = document.getElementById(id);
  }
  svg.style.background = 'none';
  var xml = new XMLSerializer().serializeToString(svg);
  var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(xml)));
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  canvas.width = width || 20;
  canvas.height = height || 20;

  var image = new Image();
  // later when image loads run this
  let result;
  image.src = svgData;
  await new Promise((resolve) => {
    image.onload = async () => {
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/png');
      // pass png data URL to callback
      const data = await fetch(pngData);
      const blob: any = await data.blob();
      const reader: any = new FileReader();
      await new Promise((resolve1, reject1) => {
        reader.onload = resolve1;
        reader.onerror = reject1;
        reader.readAsDataURL(blob);
      });
      result = {
        base64: reader.result,
        width: canvas.width,
        height: canvas.height,
        image: image,
      };
      resolve(true);
    };
  });
  return result;
};

export const convertSVGToBase64 = async (id, width?, height?) => {
  let svg = $(`#${id}`)[0];
  if (!svg) {
    svg = document.getElementById(id);
  }
  svg.style.background = 'none';
  var xml = new XMLSerializer().serializeToString(svg);
  var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(xml)));
  var image = new Image();
  // later when image loads run this
  let result;
  image.src = svgData;
  await new Promise((resolve) => {
    image.onload = async () => {
      result = {
        base64: svgData,
        width: width || 20,
        height: height || 20,
        image: image,
      };
      resolve(true);
    };
  });
  return result;
};

export const loadImageFromBase64 = (data) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = async (dt) => {
      resolve({
        base64: data.base64,
        width: data.width,
        height: data.height,
        image: img,
      });
    };
    img.onerror = function () {
      reject();
    };
    img.src = data.base64;
  });
};

export const formatNumber = (number, decimal?) => {
  return convertToInternationalCurrency('', number, 'en-US', decimal);
};

export const formatNumberToOne = (number) => {
  return convertToInternationalCurrency('', number, 'en-US');
};

export const separator = (number, decimalPoint?) => {
  if (isNaN(number)) return number;
  if (decimalPoint && !Number.isInteger(number)) {
    number = number.toFixed(decimalPoint);
  }
  const str = number.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
};

export const getDataFormat = (periodicity) => {
  if (periodicity === 'Year' || periodicity === 'yearly' || periodicity === 'Yearly') return 'yyyy';
  else if (periodicity === 'Q' || periodicity === 'quarterly' || periodicity === 'Quarterly') return 'yyyy Qq';
  else if (periodicity === 'Month' || periodicity === 'monthly' || periodicity === 'Monthly') return 'MMM yy';
  else if (periodicity === 'Week' || periodicity === 'weekly' || periodicity === 'Weekly') return 'yyyy-MM-dd';
  else if (periodicity === 'daily' || periodicity === 'Daily') return 'dd/MM';
};

export const stringAvatar = (name: string) => {
  name = name.trim();
  name = name.replace(/\s\s+/g, ' ');
  if (!name) {
    return '';
  }
  const nameSplit = name.split(' ');
  return {
    children:
      nameSplit.length > 1
        ? `${nameSplit[0][0]}${nameSplit[1][0]}`
        : nameSplit.length === 1 && nameSplit[0].length > 1
        ? `${nameSplit[0][0]}${nameSplit[0][1]}`
        : nameSplit.length === 1
        ? `${nameSplit[0][0]}`
        : '',
  };
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const checkForMultiSelections = (filters) => {
  if (filters) {
    if (_.isArray(filters.category) && filters.category.length > 1) return false;
    if (_.isArray(filters.segment) && filters.segment.length > 1) return false;
    if (_.isArray(filters.brand) && filters.brand.length > 1) return false;
    if (_.isArray(filters.subBrand) && filters.subBrand.length > 1) return false;
    if (_.isArray(filters.packSize) && filters.packSize.length > 1) return false;
    if (_.isArray(filters.anchorCategory) && filters.anchorCategory.length > 1) return false;
    if (_.isArray(filters.anchorSegment) && filters.anchorSegment.length > 1) return false;
    if (_.isArray(filters.anchorBrand) && filters.anchorBrand.length > 1) return false;
    if (_.isArray(filters.anchorSubBrand) && filters.anchorSubBrand.length > 1) return false;
    if (_.isArray(filters.anchorPackSize) && filters.anchorPackSize.length > 1) return false;

    return true;
  }
};

export const splitText = (str) => {
  const result = str.match(/.{1,6}/g);
  if (result.length > 5) {
    result.splice(5, result.length);
    result[4] = `${result[4].split('', 3).join('')}...`;
  }
  const length = result.length;
  const startY = length === 1 ? 24 : length === 5 || length === 4 ? 8 : 16;
  return (
    <text transform='translate(20)' x='0' y='50' fontSize='20' style={{ zIndex: 9 }}>
      {result.map((item, index) => (
        <tspan x={0} y={startY + 8 * index} text-anchor='middle' style={{ fontSize: 7 }}>
          {item}
        </tspan>
      ))}
    </text>
  );
};

export const dynamicSvg = (productName) => {
  if (productName) {
    return (
      <svg viewBox='0 0 40 45' width='40' height='45' id={productName} style={{ display: 'none' }}>
        <rect width='40' height='45' style={{ fill: '#fff', stroke: '#000', strokeWidth: 2 }} />
        {splitText(productName)}
      </svg>
    );
  }
};
export const roundDecimal = (num) => {
  num = num + 'e+2';
  return +(Math.round(num) + 'e-2');
};

export const camelCaseToNormalCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatValues = (key, value, filterValues, disableFilter) => {
  if (key === 'vendor') {
    filterValues.category = null;
    filterValues.segment = null;
    filterValues.brand = null;
    filterValues.subBrand = null;
    filterValues.packSize = null;

    disableFilter.category = value === null || value.length === 0;
    disableFilter.segment = true;
    disableFilter.brand = true;
    disableFilter.subBrand = true;
    disableFilter.packSize = true;
  } else if (key === 'category') {
    filterValues.segment = null;
    filterValues.brand = null;
    filterValues.subBrand = null;
    filterValues.packSize = null;

    disableFilter.segment = value === null || value.length === 0;
    disableFilter.brand = true;
    disableFilter.subBrand = true;
    disableFilter.packSize = true;
  } else if (key === 'segment') {
    filterValues.brand = null;
    filterValues.subBrand = null;
    filterValues.packSize = null;

    disableFilter.brand = value === null || value.length === 0;
    disableFilter.subBrand = true;
    disableFilter.packSize = true;
  } else if (key === 'brand') {
    filterValues.subBrand = null;
    filterValues.packSize = null;

    disableFilter.subBrand = value === null || value.length === 0;
    disableFilter.packSize = true;
  } else if (key === 'subBrand') {
    filterValues.packSize = null;

    disableFilter.packSize = value === null || value.length === 0;
  }
  return { filterValues, disableFilter };
};

export const formatAnchorValues = (key, value, filterValues, disableFilter) => {
  if (key === 'anchorVendor') {
    filterValues.anchorCategory = null;
    filterValues.anchorSegment = null;
    filterValues.anchorBrand = null;
    filterValues.anchorSubBrand = null;
    filterValues.anchorPackSize = null;

    disableFilter.anchorCategory = value === null || value.length === 0;
    disableFilter.anchorSegment = true;
    disableFilter.anchorBrand = true;
    disableFilter.anchorSubBrand = true;
    disableFilter.anchorPackSize = true;
  } else if (key === 'anchorCategory') {
    filterValues.anchorSegment = null;
    filterValues.anchorBrand = null;
    filterValues.anchorSubBrand = null;
    filterValues.anchorPackSize = null;

    disableFilter.anchorSegment = value === null || value.length === 0;
    disableFilter.anchorBrand = true;
    disableFilter.anchorSubBrand = true;
    disableFilter.anchorPackSize = true;
  } else if (key === 'anchorSegment') {
    filterValues.anchorBrand = null;
    filterValues.anchorSubBrand = null;
    filterValues.anchorPackSize = null;

    disableFilter.anchorBrand = value === null || value.length === 0;
    disableFilter.anchorSubBrand = true;
    disableFilter.anchorPackSize = true;
  } else if (key === 'anchorBrand') {
    filterValues.anchorSubBrand = null;
    filterValues.anchorPackSize = null;

    disableFilter.anchorSubBrand = value === null || value.length === 0;
    disableFilter.anchorPackSize = true;
  } else if (key === 'anchorSubBrand') {
    filterValues.anchorPackSize = null;

    disableFilter.anchorPackSize = value === null || value.length === 0;
  }
  return { filterValues, disableFilter };
};

export const disabledFilters = (payload) => {
  let data = {
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  };
  const key = Object.keys(payload)[0];
  let val = payload[key];
  if (_.isArray(val) && val.length === 0) {
    val = null;
  }

  switch (key) {
    case 'category':
      data = {
        ...data,
        category: false,
        segment: val === null,
      };
      break;
    case 'segment':
      data = {
        ...data,
        category: false,
        segment: false,
        brand: val === null,
      };
      break;
    case 'brand':
      data = {
        ...data,
        category: false,
        segment: false,
        brand: false,
        subBrand: val === null,
      };
      break;
    case 'subBrand':
      data = {
        ...data,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: val === null,
      };
      break;
    case 'packSize':
      data = {
        ...data,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      break;
  }
  return data;
};
export const disabledAnchorFilters = (payload) => {
  let data = {
    anchorCategory: true,
    anchorSegment: true,
    anchorBrand: true,
    anchorSubBrand: true,
    anchorPackSize: true,
  };
  const key = Object.keys(payload)[0];
  let val = payload[key];
  if (_.isArray(val) && val.length === 0) {
    val = null;
  }

  switch (key) {
    case 'anchorCategory':
      data = {
        ...data,
        anchorCategory: false,
        anchorSegment: val === null,
      };
      break;
    case 'anchorSegment':
      data = {
        ...data,
        anchorCategory: false,
        anchorSegment: false,
        anchorBrand: val === null,
      };
      break;
    case 'anchorBrand':
      data = {
        ...data,
        anchorCategory: false,
        anchorSegment: false,
        anchorBrand: false,
        anchorSubBrand: val === null,
      };
      break;
    case 'anchorSubBrand':
      data = {
        ...data,
        anchorCategory: false,
        anchorSegment: false,
        anchorBrand: false,
        anchorSubBrand: false,
        anchorPackSize: val === null,
      };
      break;
    case 'anchorPackSize':
      data = {
        ...data,
        anchorCategory: false,
        anchorSegment: false,
        anchorBrand: false,
        anchorSubBrand: false,
        anchorPackSize: false,
      };
      break;
  }
  return data;
};

export const disabledFiltersWithVendor1 = (payload, isPepsico) => {
  let data = {
    vendor: isPepsico,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  };
  const key = Object.keys(payload)[0];
  let val = payload[key];
  if (_.isArray(val) && val.length === 0) {
    val = null;
  }

  switch (key) {
    case 'vendor':
      data = {
        ...data,
        vendor: isPepsico,
        category: val === null,
      };
      break;
    case 'category':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: val === null,
      };
      break;
    case 'segment':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: val === null,
      };
      break;
    case 'brand':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: val === null,
      };
      break;
    case 'subBrand':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: val === null,
      };
      break;
    case 'packSize':
      data = {
        ...data,
        vendor: isPepsico,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      break;
  }
  return data;
};

export const FiltersWithVendor = (payload) => {
  let data = {
    vendor: true,
    category: true,
    segment: true,
    brand: true,
    subBrand: true,
    packSize: true,
  };
  const key = Object.keys(payload)[0];
  let val = payload[key];
  if (_.isArray(val) && val.length === 0) {
    val = null;
  }

  switch (key) {
    case 'vendor':
      data = {
        ...data,
        vendor: false,
        category: val === null,
      };
      break;
    case 'category':
      data = {
        ...data,
        vendor: false,
        category: false,
        segment: val === null,
      };
      break;
    case 'segment':
      data = {
        ...data,
        vendor: false,
        category: false,
        segment: false,
        brand: val === null,
      };
      break;
    case 'brand':
      data = {
        ...data,
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: val === null,
      };
      break;
    case 'subBrand':
      data = {
        ...data,
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: val === null,
      };
      break;
    case 'packSize':
      data = {
        ...data,
        vendor: false,
        category: false,
        segment: false,
        brand: false,
        subBrand: false,
        packSize: false,
      };
      break;
  }
  return data;
};

export const storeMMPWData = (mmpwData) => {
  if (_.isString(mmpwData)) {
    mmpwData = JSON.parse(mmpwData);
  }
  if (_.isObject(mmpwData)) {
    Object.keys(mmpwData).map((id) => {
      let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
      if (dashbaordData && dashbaordData[id]) {
        dashbaordData[id] = { ...dashbaordData[id], ...mmpwData[id] };
        localStorage.setItem('mmpw', JSON.stringify(dashbaordData));
      } else {
        localStorage.setItem('mmpw', JSON.stringify({ ...dashbaordData, [id]: mmpwData[id] }));
      }
    });
  }
};
export const storeMMPWFooterData = (mmpwDataFooterData) => {
  if (_.isString(mmpwDataFooterData)) {
    mmpwDataFooterData = JSON.parse(mmpwDataFooterData);
  }
  if (_.isObject(mmpwDataFooterData)) {
    Object.keys(mmpwDataFooterData).map((id) => {
      let dashbaordFooterData = JSON.parse(localStorage.getItem('mmpw-footer'));
      if (dashbaordFooterData && dashbaordFooterData[id]) {
        dashbaordFooterData[id] = [...dashbaordFooterData[id], ...mmpwDataFooterData[id]];
        localStorage.setItem('mmpw-footer', JSON.stringify(dashbaordFooterData));
      } else {
        localStorage.setItem('mmpw-footer', JSON.stringify({ ...dashbaordFooterData, [id]: mmpwDataFooterData[id] }));
      }
    });
  }
};

export const travelChild = (config, key, value, selectedValues, disableFilters) => {
  if (value) {
    selectedValues[key] = value;
  }
  if (config[key].children) {
    disableFilters[config[key].children] = value === null || value.length === 0;
    selectedValues[config[key].children] = null;
    return travelChild(config, config[key].children, null, selectedValues, disableFilters);
  }
  return { selectedValues, disableFilters };
};

export const imageTagFromUrl = (url) => {
  if (!url) return null;
  var img = new Image();
  img.src = url;
  return img;
};

const getFormattedBreadcrumbString = (item, displayListCount) => {
  const remainingItems = item.length > displayListCount ? item.splice(displayListCount, item.length) : [];
  if (item.length > 0) return `${item.join(', ')} ${remainingItems.length > 0 ? `and ...${remainingItems.length}more ` : ''}`;
  else return '';
};

export const getBreadcrumbs = (data, keys, displayListCount = 3) => {
  let breadCrumb: any = [];
  if (_.isArray(keys) && keys.length > 0 && data && Object.keys(data).length > 0) {
    keys.map((key) => {
      const str = getFormattedBreadcrumbString([...(_.isArray(data[key]) ? data[key] : data[key] ? [data[key]] : [])], displayListCount);
      if (str) breadCrumb.push(str);
    });
  }
  return breadCrumb.join('> ');
};

export const getValueFromObject = (object, key) => {
  return key.split('.').reduce((o, k) => (k && o[k] && o[k] !== null ? o[k] : ''), object);
};

const productImages = {};

export const rendarImage = async (key, country?) => {
  const concatKey = (country ? `${country}-${key}` : key).replace(/\s/g, '-');
  if (productImages && productImages[concatKey]) {
    return productImages[concatKey];
  } else {
    try {
      let response: any = { status: 404 };
      try {
        response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}files/products/${country}/${key}`, {
          headers: {
            'Content-Type': 'image/png',
            ...(process.env.REACT_APP_SSO_ENABLE === 'Yes' ? { Authorization: `Bearer ${oktaAuth.getAccessToken()}` } : {}),
          },
        });
      } catch (error) {
        // console.log(error);
      }
      if (response?.status === 200) {
        productImages[concatKey] = await blobToBase64(await response.blob());
        return productImages[concatKey];
        // const imageBlobToUrl = URL.createObjectURL(await response.blob());
        // productImages[concatKey] = imageBlobToUrl;
        // return imageBlobToUrl;
      }
      return null;
    } catch (err) {
      productImages[concatKey] = null;
      return null;
    }
  }
};
export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const valueBasedOnColor = (val) => {
  if (val < 0) {
    return '#fb1111';
  } else if (val > 0) {
    return '#2dbe04';
  } else {
    return 'black';
  }
};

export const removeMMPWFromLocal = (id) => {
  if (id === 'All') {
    localStorage.removeItem('mmpw');
    localStorage.removeItem('mmpw-footer');
    localStorage.removeItem('periodicity');
  } else {
    let dashbaordData = JSON.parse(localStorage.getItem('mmpw'));
    if (dashbaordData && Object.keys(dashbaordData).length > 0) {
      Object.keys(dashbaordData).map((key, i) => {
        if (key.includes(id)) {
          delete dashbaordData[key];
          localStorage.setItem('mmpw', JSON.stringify(dashbaordData));
        }
      });
    }
    dashbaordData = JSON.parse(localStorage.getItem('mmpw-footer'));
    if (dashbaordData && Object.keys(dashbaordData).length > 0) {
      Object.keys(dashbaordData).map((key, i) => {
        if (key.includes(id)) {
          delete dashbaordData[key];
          localStorage.setItem('mmpw-footer', JSON.stringify(dashbaordData));
        }
      });
    }
    localStorage.removeItem('periodicity');
  }
  window.dispatchEvent(new Event('removed-storage'));
};

export const enableCompetitorFilter = (filter) => {
  return !(
    filter.country === 'MEXICO' &&
    filter.geoLevel &&
    (_.isArray(filter.geoLevel)
      ? filter.geoLevel.length > 0 && (filter.geoLevel[0] === 'CHAIN-TTL' || filter.geoLevel[0] === 'CHAIN-FORMAT')
      : filter.geoLevel === 'CHAIN-TTL' || filter.geoLevel === 'CHAIN-FORMAT')
  );
};

export const plotImages = async (chart, config) => {
  const payload: any = { Image: [] };
  const allProductImages = [...config.productImages];
  await Promise.all(
    allProductImages.map(async (item: any, i) => {
      if (item.base64) {
        payload.Image.push({
          type: 'Image',
          base64: item.base64,
          x: chart.chartWidth - config.imgPosition.x + i * -100,
          y: config.imgPosition.y,
          width: config.imgPosition.width,
          height: config.imgDimension.height,
        });
        return;
      } else {
        return await convertSVGToBase64(item.productName, 50, 55).then((response: any) => {
          payload.Image.push({
            type: 'Image',
            base64: response.base64,
            x: chart.chartWidth - config.imgPosition.x + i * -70,
            y: config.imgPosition.y,
            width: config.nameDimension.width,
            height: config.nameDimension.height,
          });
        });
      }
    })
  );
  plotElementsOnChart(payload, chart, config.id);
};

export const windowResizeListner = (chart, config) => {
  let flag = true;
  $(window).resize(function () {
    if (flag) {
      setTimeout(() => {
        flag = true;
        if (chart) {
          removeElementsOnChart(config.id);
          plotImages(chart, config);
        }
      }, 1000);
      flag = false;
    }
  });
};

export const pascaleCase = (str, char) => {
  return str
    .split(char)
    .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
    .join(' ');
};

export const copyScreenshotToClipboard = async (base64, dispatch) => {
  try {
    const data = await fetch(base64);
    const blob: any = await data.blob();
    const item = new ClipboardItem({ [blob.type]: blob });
    await navigator.clipboard
      .write([item])
      .then(
        function () {
          dispatch(toast('Copied to clipboard successfully!', true, 3000, 'success'));
          dispatch(loader(false));
        },
        function (error) {
          dispatch(toast('Something went wrong, please try again', true, 3000, 'error'));
          dispatch(loader(false));
        }
      )
      .catch(() => {
        dispatch(loader(false));
      });
  } catch (error) {
    dispatch(toast('Something went wrong, please try again', true, 3000, 'error'));
    dispatch(loader(false));
  }
};

export const compileHTMLTemplate = (object, data) => {
  let html = data.series ? data.series[object.series.name] : data.html;
  Object.entries(data.variables).map(([key, value]: [string, any]) => {
    let val = getValueFromObject(object, key);
    if (value.isFormat) {
      val = formatNumber(val, value.decimal);
    }
    html = html.replace(`{{${key}}}`, val);
  });
  return html;
};

export const checkPermission = (key) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user && user.role ? permissions[user.role][key] : false;
};

export const downloadFile = async (url) => {
  try {
    return await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        ...(process.env.REACT_APP_SSO_ENABLE === 'Yes' ? { Authorization: `Bearer ${oktaAuth.getAccessToken()}` } : {}),
      },
    });
  } catch (error) {
    // console.log(error);
  }
};

export const sortData = (order, data, column?) => {
  if (_.isArray(data) && data.length) {
    let sortedData = [];
    switch (order) {
      case 'newest':
        const key = column ? column : 'date';
        sortedData = _.orderBy(data, key, 'desc');
        break;
      case 'most-popular':
        sortedData = _.orderBy(data, 'viewCount', 'desc');
        break;
      case 'a-z':
        sortedData = _.orderBy(data, [(item) => item.name.toLowerCase()]);
        break;
      case 'z-a':
        sortedData = _.orderBy(data, [(item) => item.name.toLowerCase()], 'desc');
        break;
    }
    return sortedData;
  }
  return [];
};

export const isValidDate = (ExpiryDate) => {
  var objDate, // date object initialized from the ExpiryDate string
    mSeconds, // ExpiryDate in milliseconds
    day, // day
    month, // month
    year; // year
  if (ExpiryDate.length !== 10) {
    return false;
  }
  if (ExpiryDate.substring(2, 3) !== '/' || ExpiryDate.substring(5, 6) !== '/') {
    return false;
  }
  month = ExpiryDate.substring(0, 2) - 1;
  day = ExpiryDate.substring(3, 5) - 0;
  year = ExpiryDate.substring(6, 10) - 0;
  if (year < 1000 || year > 3000) {
    return false;
  }
  mSeconds = new Date(year, month, day).getTime();
  objDate = new Date();
  objDate.setTime(mSeconds);

  if (objDate.getFullYear() !== year || objDate.getMonth() !== month || objDate.getDate() !== day) {
    return false;
  }
  return true;
};

export const getToken = () => {
  return process.env.REACT_APP_SSO_ENABLE === 'Yes' ? `Bearer ${oktaAuth.getAccessToken()}` : '';
};

export const multiplyValuesBy100 = (obj) => {
  const multipliedObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = parseFloat(obj[key]) * 100;
      multipliedObj[key] = value.toString();
    }
  }
  return multipliedObj;
}
