import React, { useState } from 'react';
import DashboardActions from '../../components/DashboardActions';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { pageList } from '../../mocks/common';
import GraphContainer from './components/GraphContainer';
import TopFilters from './components/TopFilters';
import { visualizationIds } from './config';
import { removeMMPWFromLocal } from '../../util/helper';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const PricingPotetial: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [sliderData, setSliderData] = useState({});
  const [defaultFilters, setDefaultFilters] = useState({
    global: {},
    slider: {},
  });
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);

  const onApplyFilter = (filters) => {
    setDisableDownloadButton(false);
    removeMMPWFromLocal('pricing-potential-chart');
    setAppliedFilters(filters);
    setDefaultFilters({
      global: {},
      slider: {},
    });
  };

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      //use this after api development
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      setDefaultFilters(savedDashboardData?.filters);
      setAppliedFilters(savedDashboardData?.filters?.global);
    }
  };

  const reduxState = useSelector((state: any) => state);

  return (
    <DashboardActions
      title='Pricing Potential'
      data={{
        filters: {
          global: { ...appliedFilters },
          slider: sliderData,
        },
        mmpwKey: 'pricing-potential-chart',
      }}
      dashboardKey={pageList[9].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={
        disableDownloadButton ||
        reduxState.common.loader ||
        reduxState.geoFilter.geoLevelLoading ||
        reduxState.PricingPotentialLocalFilter.pricingPotentialSliderLoader ||
        reduxState.PricingPotentialLocalFilter.pricingPotentialChartLoader
      }
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[14].value} />
      </Grid>
      <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters?.global} />
      <GraphContainer callback={setSliderData} filters={appliedFilters} defaultFilters={defaultFilters.slider} />
      <DashboardSourceList dashboardName={pageList[9].id} appliedFilters={appliedFilters} category={appliedFilters?.anchorCategory} />
    </DashboardActions>
  );
};

export default PricingPotetial;
