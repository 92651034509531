import { formatNumber } from "../../../../../../util/helper";

export const lineChartConfig = {
  chart: {
    backgroundColor: "transparent",
    type: "spline",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  xAxis: {
    title: {
      text: "EDRP ",
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    labels: {
      style: {},
      enabled: true,
    },
    categories: [],
  },
  yAxis: {
    title: {
      text: "",
      style: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    labels: {
      enabled: true,
      formatter: function () {
        return formatNumber(this.value.toFixed(2));
      },
    },
  },

  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    layout: "horizontal",
    backgroundColor: "transparent",
    align: "center",
    verticalAlign: "top",
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: "#598ee3",
      name: "",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      color: "#d41165",
      name: "",
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
