import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import Dashboard from "../../../../components/Dashboard/Dashboard";
import { Box, Card, CardContent } from "@mui/material";
import TopFilters from "./components/TopFliters";
import Section from "./components/Section";
const initialFilters = {
  category: null,
  segment: null,
  brand: null,
  subBrand: null,
  packSize: null,
  periodicity: "Quarterly",
};

const VisualizationContainer: React.FC<{ data; globalFilter?; callback }> = ({
  data,
  globalFilter,
  callback,
}) => {
  const [loader, setLoader] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [visualizations, setVisualizations] = React.useState<any>([]);

  const [selectedFilter, setSelectedFilter] = React.useState({
    ...initialFilters,
  });

  useEffect(() => {
    const flag = !selectedFilter.category || !globalFilter.country;
    setSkeleton(flag)
  }, [selectedFilter])

  useEffect(() => {
    if (
      globalFilter.variable &&
      _.isArray(globalFilter.variable) &&
      Object.values(data?.defaultFilters.global).length === 0
    ) {
      setSelectedFilter({ ...initialFilters, ...globalFilter });
      setVisualizations([]);
      setTimeout(() => {
        const variableVisualizations: any = [];
        globalFilter.variable.map((item, index) => {
          variableVisualizations.push({
            id: `${data.id}-${index + 1}`,
            chartRef: null,
            loaclFilters: { macroEconVar: item, productVar: null },
          });
        });
        setVisualizations(variableVisualizations);
      }, 0);
    }
  }, [globalFilter]);

  useEffect(() => {
    if (
      data &&
      data?.defaultFilters &&
      Object.values(data?.defaultFilters.global).length > 0
    ) {
      setSelectedFilter({ ...globalFilter, ...data?.defaultFilters.global });
      setVisualizations(data?.defaultFilters.visualizations);
    }
  }, [data]);

  const addVisualization = () => {
    setVisualizations([
      ...visualizations,
      {
        id: `${data.id}-${visualizations.length + 1}`,
        chartRef: null,
        loaclFilters: { macroEconVar: null, productVar: null },
      },
    ]);
  };

  const setChartRef = (chartRef, index) => {
    const allVisualizations: any = [...visualizations];
    allVisualizations[index].chartRef = chartRef;
    setVisualizations(allVisualizations);
  };

  const setLocalFilters = (loaclFilters, index) => {
    const allVisualizations: any = [...visualizations];
    allVisualizations[index].loaclFilters.macroEconVar =
      loaclFilters.macroEconVar;
    allVisualizations[index].loaclFilters.productVar = loaclFilters.productVar;
    setVisualizations(allVisualizations);
  };

  const setSelectedFilters = (filters) => {
    setVisualizations([]);
    setTimeout(() => {
      const variableVisualizations: any = [];
      globalFilter.variable.map((item, index) => {
        variableVisualizations.push({
          id: `${data.id}-${index + 1}`,
          chartRef: null,
          loaclFilters: { macroEconVar: item, productVar: null },
        });
      });
      setVisualizations(variableVisualizations);
    }, 0);
    setSelectedFilter({ ...globalFilter, ...filters });
  };

  useEffect(() => {
    if (
      visualizations &&
      _.isArray(visualizations) &&
      visualizations.length > 0
    ) {
      let newVisualizations = visualizations.map((item) => {
        return _.omit(item, "chartRef");
      });
      callback(data.type, {
        global: selectedFilter,
        visualizations: newVisualizations,
      });
    }
  }, [visualizations]);

  return (
    <Card className="m-b-20">
      <CardContent>
        <Title className="m-b-20">
          <span
            style={{ font: "normal normal 600 24px Poppins" }}
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></span>
        </Title>
        <Box className="m-b-20">
          <TopFilters
            showLoader={loader}
            geoFilter={globalFilter}
            callback={setSelectedFilters}
            type={data.type}
            title={data?.category}
            defaultFilters={data?.defaultFilters.global}
            id={data.id}
          />
        </Box>
        <hr />
        {visualizations.map((item, i) => (
          <Box key={`sections-${item.id}`}>
            <Box className="m-t-20 m-b-20">
              <Dashboard
                title={null}
                showSkeleton={!globalFilter.country}
                chartRef={item.chartRef}
                id={item.id}
                key={item.id}
              >
                <Section
                  selectedFilter={selectedFilter}
                  lastSection={i === visualizations.length - 1}
                  display={visualizations.length < 5}
                  addVisualization={addVisualization}
                  productVariables={data.productVariable}
                  macroEconVariables={data.macroEconVariables}
                  selectedMacroEconVar={item.loaclFilters.macroEconVar}
                  defaultFilters={
                    item &&
                    item.loaclFilters.macroEconVar &&
                    item.loaclFilters.productVar && {
                      macroEconVar: item.loaclFilters.macroEconVar,
                      productVar: item.loaclFilters.productVar,
                    }
                  }
                  callback={(loaclFilters) => {
                    setChartRef(loaclFilters.chartRef, i);
                    setLocalFilters(loaclFilters, i);
                  }}
                  type={data.type}
                  title={data?.category}
                  id={item.id}
                  skeleton={skeleton}
                />
              </Dashboard>
            </Box>
            {i !== visualizations.length - 1 && <hr />}
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default VisualizationContainer;
