import { Button, styled } from "@mui/material";

const DownloadButton = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  fontSize: 13,
  color: "#305597",
  width: "162px",
  height: "28px",
  font: "Montserrat",
  Weight: 600,
  background: theme.palette.common.white,
  whiteSpace:"nowrap",
  '&:hover': {
    background: theme.palette.common.white,
  },
}));

export { DownloadButton };
