import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const Label = styled(Box)(() => ({
  color: "#000", 
  backgroundColor: "#fff", 
  height: 46, 
  borderRadius: 5, 
  width: 197, 
  display: "flex", 
  alignItems: "center", 
  fontSize: "1rem", 
  padding: "8px 14px"
}));


export { Label };
