import React, { useEffect, useState } from "react";
import { ClearButton, StyledSelect, Title } from "./ExecutionCommon.Styled";
import { Card, Grid, ThemeProvider } from "@mui/material";
import FilterComponent from "./FilterComponent/FilterComponent";
import OverviewComponent from "./OverviewComponent/OverviewComponent";
import { useSelector, useDispatch } from "react-redux";
import { fetchExecutionFiltersData } from "../../../store/actions/allFilter.action";
import {
  MandatoryInformationContainer,
  TypographyTheme,
} from "./CommonComponents/commonStyled";
import UserInputComponent from "./UserInputComponent/UserInputComponent";
import Portfolio from "./Portfolio/Portfolio";
import GeoLevelSummary from "./GeoLevelSummary/GeoLevelSummary";
import { darkThemeForExecution, lightThemeForExecution } from "./Theme/theme";
import Indicator from "../../../components/Loader/Loader";
import Spinner from "../../AssortmentTool/components/Loader/Loader";
import DataSourcePopup from "../../AssortmentTool/components/CommonComponents/DataSourcePopup";
import { BlackFilledInfo } from "../../../assets/icons/assorticons";
import { executionDataSource } from "./CommonComponents/utils";
import { API_URL } from "../../../store/actions/constants";

const ExecutionTool = () => {
  useEffect(() => {
    dispatch(
      fetchExecutionFiltersData({ default: true }, API_URL.EXECUTION.EXECUTION_TRACKER_FILTER)
    );
  }, []);

  const filterData = useSelector((state: any) => state.allFilter.data);
  const selectedFilterData = useSelector(
    (state: any) => state.allFilter.selectedFilters
  );
  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );
  const { loadingStateForAll } = useSelector(
    (state: any) => state.overviewscore
  );
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.allFilter.loading);

  const clearFilters = (e) => {
    let payload = {
      default: true,
      overallFilters: {
        country: selectedFilterData.country,
        period: selectedFilterData.period[0],
        businessUnit: selectedFilterData.businessUnit,
      },
      geo: {
        channel: selectedFilterData.channel,
        region: selectedFilterData.region,
        storeSegment: selectedFilterData.storeSegment,
        store: selectedFilterData.store,
      },
    };
    dispatch(fetchExecutionFiltersData(payload, API_URL.EXECUTION.EXECUTION_TRACKER_FILTER));
  };

  const [open, setOpen] = useState(false);
  const openDataSourceInfo = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <ThemeProvider
      theme={theme.darkmode ? darkThemeForExecution : lightThemeForExecution}
    >
      <Spinner
        position="fixed"
        show={loadingStateForAll}
        text={"Please wait until the calculations are done"}
      />
      <MandatoryInformationContainer>
        <span>*Mandatory</span>
      </MandatoryInformationContainer>
      <Card style={{ border: "none", boxShadow: "none", position: "relative" }}>
        <Indicator position="absolute" show={loading} />
        {open && (
          <DataSourcePopup isOpen={open} setIsOpen={setOpen} data={executionDataSource}></DataSourcePopup>
        )}
        <Grid
          style={{
            position: "absolute",
            fontFamily: "Mulish",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17.57px",
            letterSpacing: "0.4px",
            right: "6px",
            zIndex: 10,
            color: "#7E7E7E",
            marginTop:"10px"
          }}
        >
          Data Sources{" "}
          <img
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={openDataSourceInfo}
            src={BlackFilledInfo}
            alt=""
          />
        </Grid>
        {Object.keys(filterData).map((filterItem: any) => {
          return (
            <FilterComponent
              filter={filterData[filterItem]}
              title={filterItem}
              level={''}
            ></FilterComponent>
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#F1F5FE",
          }}
        >
          <ClearButton onClick={clearFilters}>Clear Filters</ClearButton>
        </div>
      </Card>
      <UserInputComponent></UserInputComponent>
      <OverviewComponent></OverviewComponent>
      <TypographyTheme>Portfolio : </TypographyTheme>
      <Card sx={{ borderRadius: "5px", backgroundColor: "#F1F5FE" }}>
        <Portfolio />
      </Card>
      <TypographyTheme>Geo Level Summary :</TypographyTheme>
      <Card sx={{ borderRadius: "5px", backgroundColor: "#F1F5FE" }}>
        <GeoLevelSummary />
      </Card>
    </ThemeProvider>
  );
};

export default ExecutionTool;
