import React from "react";
import { Grid, Box, IconButton } from "@mui/material";
import { useHistory } from "react-router";
import data from "../../../../mocks/alertAndNotification";
import NotificationListCard from "../NotificationListCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NotificationTitle } from "../../AlertAndNotification.Styled";
import { ALERT_AND_NOTIFICATION } from "../../../../router/CONSTANTS";
import { Title } from "../../../../styles/Common.Styled";
import { pageNames } from "../../../../mocks/common";
import PageContainer from "../../../../components/PageContainer/PageContainer";

const NotificationDetail: React.FC = () => {
  const history = useHistory();
  const queryParam = new URLSearchParams(history.location.search);
  const notificationId = queryParam.get("id");
  const notification = data.notifications.filter(
    (item) => item.id === notificationId
  )[0];

  return (
    <PageContainer page={pageNames.notificationDetail}>
      <Box>
        <Grid container alignItems="center">
          <IconButton
            data-testid="notificationDetailsBackButton"
            onClick={() => history.push(ALERT_AND_NOTIFICATION)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Title>Notification Details</Title>
        </Grid>
        <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
          <NotificationListCard data={notification} />
        </Grid>
      </Box>
    </PageContainer>
  );
};
export default NotificationDetail;
