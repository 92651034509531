import AdapterLuxon from '@mui/lab/AdapterLuxon';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownTitle } from '../../../../components/DashboardFilters/DashboardFilters.Styled';
import Dropdown from '../../../../components/Dropdown';
import FilterAccordion from '../../../../components/Filters/Filters';
import { initialState } from '../../../../components/GeoFilters-V2/GeoFilters-V2';
import GeoFiltersV3 from '../../../../components/GeoFilters-V3';
import Indicator from '../../../../components/Loader';
import ProductFiltersV3 from '../../../../components/ProductFilters-v3';
import RadioButtonGroup from '../../../../components/UI-components/RadioButtonGroup';
import mockData, { elasticityRadio, primaryFilter } from '../../../../mocks/elasticityPricingTrack';
import { BlackInputTitle, OrangeBtn, PrimaryBtn } from '../../../../styles/Common.Styled';
import { dataFormat } from '../../../../util/helper';
import { fetchFilterMonthData } from '../../../BrandLadder/service';
import { getAdditionalFilterData, getInaseMaxDate } from '../../service';
import { unitVariationLoader } from '../../store/elasticityPricing.action';
import AdjustableMeasureFilters from './components/AdjustableMeasureFilters';
const TopFilters: React.FC<{ defaultFilters; callback }> = ({ defaultFilters = {}, callback }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(initialState);
  const [selectedProductFilters, setSelectedProductFilters] = useState<any>({});
  const [clearTopFilters, setClearTopFilters] = useState(false);
  const [clearAdjustableMeasureFilters, setClearAdjustableMeasureFilters] = useState(false);
  const [adjustableMeasureFilters, setAdjustableMeasureFilters] = useState<any>({});
  const [dataSource, setDataSource] = React.useState(elasticityRadio.defaultOption.value);
  const [selectedSellOutAndInase, setSelectedSellOutAndInase] = React.useState({ regions: null });
  const [disabledSellOutAndInaseFilters, setDisabledSellOutAndInaseFilters] = React.useState({ regions: true });
  const geoFilter = useSelector((state: any) => state.geoFilter);
  const [pricingDate, setPricingDate] = React.useState(null);
  const [formattedDate, setFormattedDate] = React.useState('');
  const [sellOutAndInaseFiltersOptions, setSellOutAndInaseFiltersOptions] = React.useState({
    ...mockData.sellOutAndInaseFilters,
  });
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [lastUpdatedRecordDateApiFlag, setLastUpdatedRecordDateApiFlag] = useState(false);

  const onSelectProduct = (data) => {
    setSelectedProductFilters({ ...data });
    setClearTopFilters(false);
    setClearAdjustableMeasureFilters(false);
  };

  const getMaxDateEndPoint = (payload) => {
    return dataSource === 'inase' ? getInaseMaxDate({ country: payload.country }) : fetchFilterMonthData(payload);
  };

  const getLastUpdatedRecordDate = async (payload) => {
    setLoader(true);

    await getMaxDateEndPoint(payload)
      .then((response) => {
        if (response && response.data) {
          setLoader(false);
          setLastUpdatedRecordDateApiFlag(true);
          if (!pricingDate && defaultFilters && defaultFilters.pricingDate) {
            setPricingDate(defaultFilters.pricingDate);
            setFormattedDate(defaultFilters.date);
          } else {
            setPricingDate(dataFormat(response.data.lastRefreshDate, 'MM/dd/yyyy'));
            setFormattedDate(dataFormat(response.data.lastRefreshDate, 'MM/dd/yyyy'));
          }
          setMaxDate(DateTime.fromFormat(response.data.lastRefreshDate, 'yyyy-MM-dd').toLocal());
          setMinDate(DateTime.fromFormat(response.data.lastRefreshDate, 'yyyy-MM-dd').minus({ week: 53 }).toLocal());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onApplyFilter = () => {
    dispatch(unitVariationLoader(true));
    callback({
      type: 'FILTER_APPLIED',
      filters: {
        ..._.omit(selectedGeoFilters, 'periodView'),
        dataSource,
        ...selectedSellOutAndInase,
        ...selectedProductFilters,
        measureFilters: _.isArray(adjustableMeasureFilters.tableData) ? [...adjustableMeasureFilters.tableData] : [],
        date: adjustableMeasureFilters.date,
        pricingDate,
      },
    });
  };

  const onChangeRegion = (data) => {
    setSelectedSellOutAndInase({ regions: data });
    const filterData = { ...sellOutAndInaseFiltersOptions };
    const regions = filterData.regions.options.filter((x) => data && data.findIndex((y) => y === x.value) !== -1);
    // let stages = [];

    // regions.map((x) => {
    //   stages = [...stages, ...x.stage];
    // });

    // filterData.stages.options = _.uniqBy(stages, (item) => item.value);
    // filterData.division.options = [];
    // filterData.dc.options = [];
    // setSellOutAndInaseFiltersOptions(filterData);
    setDisabledSellOutAndInaseFilters({ regions: false });
  };

  const disableApplyFilter = () => {
    const flag: boolean = geoFilter.loading || !selectedGeoFilters.country || !selectedProductFilters.packSize || !pricingDate;
    return flag;
  };

  const clearFilter = (clearDataSource = false) => {
    setLastUpdatedRecordDateApiFlag(false);
    setSelectedGeoFilters({ ...initialState });
    setClearTopFilters(true);
    setClearAdjustableMeasureFilters(true);
    if (clearDataSource) {
      setDataSource(null);
      setTimeout(() => {
        setDataSource(elasticityRadio.defaultOption.value);
      }, 0);
    }
    setPricingDate(null);
    setFormattedDate('');
    setSelectedSellOutAndInase({ regions: null });
    setDisabledSellOutAndInaseFilters({ regions: true });
    setAdjustableMeasureFilters({});
  };

  const getFilterData = async (payload) => {
    return new Promise((resolve) => {
      setLoader(true);
      getAdditionalFilterData(payload)
        .then((response) => {
          const data = response.data;
          const filterData = { ...sellOutAndInaseFiltersOptions };
          filterData.regions.options = [...data];
          setSellOutAndInaseFiltersOptions(filterData);
          setDisabledSellOutAndInaseFilters({ regions: false });
          setLoader(false);
          resolve(true);
        })
        .catch((e) => {
          resolve(true);
          console.log(e);
        });
    });
  };

  const onChangeDataSource = (source, clearfilters = false) => {
    if (selectedGeoFilters && selectedGeoFilters.country === 'MEXICO' && source === 'inase') {
      if (clearfilters) {
        clearFilter();
      }
      setSelectedGeoFilters({
        country: 'MEXICO',
        geoLevel: ['DC-MIX'],
        channel: ['DTS'],
        geoLevel2: null,
      });
      if (!defaultFilters || (defaultFilters && defaultFilters.dataSource !== 'inase'))
        getFilterData({
          country: 'MEXICO',
          geoLevel: ['DC-MIX'],
          channel: ['DTS'],
          geoLevel2: null,
        });
    } else {
      if (clearfilters) {
        clearFilter();
      }
      setSelectedGeoFilters({
        country: 'MEXICO',
        geoLevel: ['REGION-CHAN'],
        channel: ['DTS', 'OT', 'WHSL'],
        geoLevel2: null,
      });
    }
    setDataSource(source);
  };

  useEffect(() => {
    if (dataSource === 'inase') {
      getFilterData({
        country: 'MEXICO',
        geoLevel: ['DC-MIX'],
        channel: ['DTS'],
        geoLevel2: null,
      });
      setSelectedGeoFilters({
        country: 'MEXICO',
        geoLevel: ['DC-MIX'],
        channel: ['DTS'],
        geoLevel2: null,
      });
    }
  }, [dataSource]);

  React.useEffect(() => {
    setClearAdjustableMeasureFilters(true);
    setPricingDate(null);
    setFormattedDate('');
    setSelectedProductFilters({});
    setLastUpdatedRecordDateApiFlag(false);
  }, [selectedGeoFilters.geoLevel]);

  useEffect(() => {
    if (
      selectedGeoFilters.country &&
      selectedProductFilters.permutation &&
      selectedProductFilters.permutation.length > 0 &&
      !lastUpdatedRecordDateApiFlag
    ) {
      getLastUpdatedRecordDate({
        country: selectedGeoFilters.country,
        geoLevel: selectedGeoFilters.geoLevel,
        geoLevel2: selectedGeoFilters.geoLevel2,
        category: selectedProductFilters.category
      });
    }
  }, [selectedProductFilters]);

  const setInaseDefaultFilters = () => {
    if (selectedSellOutAndInase && _.isEmpty(selectedSellOutAndInase.regions)) {
      onChangeRegion(defaultFilters.regions);
    } 
    // else if (
    //   sellOutAndInaseFiltersOptions.regions.options.length > 0 &&
    //   selectedSellOutAndInase &&
    //   selectedSellOutAndInase.regions &&
    //   _.isEmpty(selectedSellOutAndInase.stages)
    // ) {
    //   onChangeStage(defaultFilters.stages);
    // } else if (
    //   selectedSellOutAndInase &&
    //   selectedSellOutAndInase.regions &&
    //   selectedSellOutAndInase.stages &&
    //   _.isEmpty(selectedSellOutAndInase.division)
    // ) {
    //   onChangeDivision(defaultFilters.division);
    // } else if (
    //   selectedSellOutAndInase &&
    //   selectedSellOutAndInase.regions &&
    //   selectedSellOutAndInase.stages &&
    //   selectedSellOutAndInase.division &&
    //   _.isEmpty(selectedSellOutAndInase.dc)
    // ) {
    //   setSelectedSellOutAndInase({
    //     ...selectedSellOutAndInase,
    //     dc: defaultFilters?.dc || null,
    //   });
    // }
  };

  useEffect(() => {
    if (defaultFilters && Object.keys(defaultFilters).length > 0 && defaultFilters.country && defaultFilters.regions !== null) {
      setInaseDefaultFilters();
    }
  }, [selectedSellOutAndInase, sellOutAndInaseFiltersOptions]);

  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setSelectedGeoFilters({
        ...initialState,
        country: defaultFilters.country,
        geoLevel: defaultFilters.geoLevel,
        geoLevel2: defaultFilters.geoLevel2,
        channel: defaultFilters.channel,
      });
      setSelectedProductFilters({
        category: defaultFilters.category,
        segment: defaultFilters.segment,
        brand: defaultFilters.brand,
        subBrand: defaultFilters.subBrand,
        packSize: defaultFilters.packSize,
      });
      onChangeDataSource(defaultFilters.dataSource, false);
    }
  }, [defaultFilters]);

  const ElasticityPricingTrack = useSelector((state: any) => state.ElasticityPricingTrack);

  return (
    <Card className='m-b-20' style={{ position: 'relative' }}>
      <Indicator
        position='absolute'
        // show={false}
        show={loader || ElasticityPricingTrack.unitVariationExpected}
      />
      <CardContent>
        <FilterAccordion title='Additional Filters'>
          <BlackInputTitle sx={{ mb: 2, fontSize: '15px' }}>Data Sources</BlackInputTitle>
          <Grid container spacing={2} columns={17}>
            <Grid item>
              <RadioButtonGroup
                key={'elasticity-radio-1'}
                color='#000'
                showButton={true}
                data={elasticityRadio.dataSource}
                defaultOption={dataSource}
                select={(source) => onChangeDataSource(source, true)}
              />
            </Grid>
          </Grid>
          {dataSource === 'inase' ? (
            <Grid container spacing={2} marginTop={0.5}>
              <Grid item xs={12} md={3} sm={3} lg={2}>
                <DropdownTitle>{sellOutAndInaseFiltersOptions.regions.title}</DropdownTitle>
                <Dropdown
                  disabled={disabledSellOutAndInaseFilters.regions}
                  data={sellOutAndInaseFiltersOptions.regions.options}
                  onChange={(data) => onChangeRegion(data)}
                  defaultOption={selectedSellOutAndInase.regions || ['empty']}
                  placeholder={sellOutAndInaseFiltersOptions.regions.placeholder}
                  multiple={sellOutAndInaseFiltersOptions.regions.multiple}
                  allOption={sellOutAndInaseFiltersOptions.regions.all}
                />
              </Grid>
            </Grid>
          ) : null}
        </FilterAccordion>
        {dataSource === 'sellOut' ? (
          <FilterAccordion title='Geo-Filters'>
            <GeoFiltersV3
              key='elasticity-pricing-track-top-filter'
              data={primaryFilter}
              onSelectFilters={setSelectedGeoFilters}
              clearFilter={clearTopFilters}
              apiPath='ElasticityPricingTrack'
              showLoader={setLoader}
              showDatePicker={false}
              dataSource={dataSource}
              defaultFilters={defaultFilters}
            />
            {selectedGeoFilters &&
              selectedGeoFilters.geoLevel &&
              _.isArray(selectedGeoFilters.geoLevel) &&
              selectedGeoFilters.geoLevel[0] === 'DC-MIX' && (
                <Box marginTop={2}>
                  <span style={{ fontSize: 16 }}>
                    <b>NOTE :</b> 5A DCMIX is not a valid selection for sell-out data sources.
                  </span>
                </Box>
              )}
          </FilterAccordion>
        ) : null}

        <FilterAccordion title='Product Filters'>
          <ProductFiltersV3
            onChange={(e) => onSelectProduct(e)}
            data={mockData.productFilter}
            onChangeLoader={setLoader}
            selectedGeoFilters={
              (selectedGeoFilters.country && selectedGeoFilters.geoLevel2) || (selectedSellOutAndInase.regions && selectedSellOutAndInase.regions.length > 0)
                ? selectedGeoFilters
                : {}
            }
            clearFilter={clearTopFilters}
            defaultFilters={
              defaultFilters && Object.keys(defaultFilters).length > 0
                ? {
                  category: defaultFilters.category,
                  segment: defaultFilters.segment,
                  brand: defaultFilters.brand,
                  subBrand: defaultFilters.subBrand,
                  packSize: defaultFilters.packSize,
                  permutation: defaultFilters.permutation,
                }
                : null
            }
            params={
              dataSource === 'inase' ? { dataSource: dataSource, regions: selectedSellOutAndInase.regions } : { dataSource: dataSource, ...selectedGeoFilters }
            }
            apiURL='filter/elasticityProducts'
          />
        </FilterAccordion>
        <FilterAccordion title='Date Filters'>
          <BlackInputTitle sx={{ mb: 2, fontSize: '15px' }}>Pricing Date</BlackInputTitle>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label=''
              value={pricingDate}
              onChange={(newValue) => {
                setPricingDate(newValue);
                setFormattedDate(dataFormat(new Date(newValue.ts), 'MM/dd/yyyy'));
              }}
              minDate={minDate}
              maxDate={maxDate}
              renderInput={(params) => <TextField InputProps={{ autoComplete: 'off' }} {...params} onKeyDown={(e) => e.preventDefault()} />}
            />
          </LocalizationProvider>
        </FilterAccordion>
        <FilterAccordion title='Adjustable Measure Filters'>
          <BlackInputTitle sx={{ mb: 2, fontSize: '15px' }}>Elasticity Pricing Tracker</BlackInputTitle>
          <AdjustableMeasureFilters
            filters={
              (selectedGeoFilters && selectedGeoFilters.geoLevel && selectedGeoFilters.geoLevel2) ||
                (selectedSellOutAndInase.regions &&
                  selectedSellOutAndInase.regions.length > 0 &&
                  Object.keys(selectedProductFilters) &&
                  selectedProductFilters.permutation &&
                  selectedProductFilters.permutation.length > 0)
                ? Object.keys(selectedGeoFilters).length > 0 && {
                  ..._.omit(selectedGeoFilters, 'periodView'),
                  ...selectedProductFilters,
                  ...selectedSellOutAndInase,
                  date: formattedDate,
                  dataSource,
                }
                : {}
            }
            defaultFilters={defaultFilters && Object.keys(defaultFilters).length > 0 ? defaultFilters.measureFilters : {}}
            callback={setAdjustableMeasureFilters}
            clearFilter={clearAdjustableMeasureFilters}
            showLoader={setLoader}
          />
        </FilterAccordion>
        <Grid className='p-l-16'>
          <OrangeBtn color='secondary' className='m-r-20' onClick={() => clearFilter(true)}>
            Clear Filter
          </OrangeBtn>
          <PrimaryBtn color='primary' onClick={onApplyFilter} disabled={disableApplyFilter()}>
            Apply Filter
          </PrimaryBtn>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TopFilters;
