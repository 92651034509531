import * as React from "react";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRange } from "@mui/lab/DateRangePicker";
import { Grid, Typography } from "@mui/material";
import {
  StyledDateRangePicker,
  CalendarIcon,
} from "./CustomDateRangePicker.Styled";
import { useSelector } from "react-redux";
import { dataFormat, isValidDate } from "../../../util/helper";

const CustomDateRangePicker: React.FC<{ defaultDate?; disabled?, callback }> = ({
  defaultDate,
  callback,
  disabled = false
}) => {
  const [value, setValue] = React.useState<DateRange<Date>>(defaultDate);
  const [isValid, setIsvalid] = React.useState(false)

  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );



  React.useEffect(() => {
    if (defaultDate) {
      setValue(defaultDate);
    }
  }, [defaultDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDateRangePicker
        label="Advanced keyboard"
        value={value}
        onChange={(val) => {
          if (isValidDate(dataFormat(val[0], "MM/dd/yyyy")) && isValidDate(dataFormat(val[1], "MM/dd/yyyy"))) {
            setValue(val);
            setIsvalid(false)
          } else {
            setValue(val);
            setIsvalid(true)
          }
          console.log(val)
          callback(val);
        }}
        disabled={disabled}
        renderInput={(startProps, endProps) => (
          <Grid
            container
            style={{
              height: 46,
              border: `1px solid`,
              borderColor: `${theme.darkmode ? "transparent" : "#b9b9b9"}`,
              background: "#fff",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Grid item xs={5} display="flex">
              <input
                style={{
                  border: "none",
                  borderRadius: 5,
                  width: "100%",
                  textAlign: "center",
                }}
                ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                {...startProps.inputProps}
              />
            </Grid>
            <Box style={{ color: "#000", display: 'flex', alignItems: "center" }}><Box>-</Box></Box>
            <Grid item xs={5} display="flex">
              <input
                style={{
                  border: "none",
                  borderRadius: 5,
                  width: "100%",
                  textAlign: "center",
                }}
                ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                {...endProps.inputProps}
              />
            </Grid>
            <Grid item xs={1}>
              <CalendarIcon />
            </Grid>
            {
              isValid ? (<Grid style={{ marginTop: 5 }}>
                <Typography sx={{ color: "red", fontSize: 14 }}>
                  Date format should be in MM/DD/YYYY
                </Typography>
              </Grid>) : (<></>)
            }
          </Grid>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
