import styled from "styled-components";
import { Typography } from "@mui/material";

const Container = styled.div`
  background-color: #f1f5fe;
  color: #fff;
`;

const ChartContainer = styled.div`
  width:100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;
`;

const StyledTypography = styled(Typography)`
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #575c5f;
`;

const SegmentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 0 0 auto;
  
  & > div {
    height: 15px;
    width: 15px;
    text-align: center;
    margin-right: 8px;
    
  }

  ${StyledTypography} {
    /* Styling applied to Typography component */
  }
`;

const SectionTitle = styled(Typography)(() => ({
    color: "#0B0E1E",
    fontSize: 13,
    fontFamily:"Montserrat, sans-serif",
    fontWeight: "500"
  }));

export { Container, ChartContainer, SegmentContainer, StyledTypography,SectionTitle };
