import styled from 'styled-components';
import { TableCell } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ }) => ({
    color: '#000',
    border: "1px solid #D3D3D3",
    textAlign: 'center',
    padding: 0
  }));

  const StyledTableHeadCell = styled(TableCell)(({ }) => ({
    color: '#000',
    border: "1px solid #D3D3D3",
    textAlign: 'center',
    padding: 5
  }));

export { StyledTableCell, StyledTableHeadCell };
