export const mapperForKeys = {
    "period": "Period",
    "businessUnit": "Bussiness Unit",
    "country": "Country",
    "channel": "Channel",
    "region": "Region",
    "storeSegment": "Store Segment",
    "store": "Store",
    "assortmentScenarioName": "Assortment Scenario",
    "mixScenarioName": "Mix Scenario",
    "goodToHaveSkus": "Good to have SKU",
    "mustHaveSKUs": "Must have SKU"
}