import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const TotalSumTitle = styled(Typography)(() => ({
  color: "#0B0E1E",
  fontSize: 13,
  fontFamily: "Mulish, sans-serif",
  fontWeight: 600,
}));

const TotalSumValue = styled(Typography)(() => ({
  color: "black",
  background:"white",
  fontSize: 13,
  fontFamily: "Montserrat, sans-serif",
  fontWeight: 500,
  border: "1px solid white",
  width: "100px",
  height: "35px",
  borderRadius: "4px",
  display:"flex",
  justifyContent:"center",
  alignContent:"center",
  alignItems:"center",
  marginLeft:"5px"
}));

export { TotalSumTitle, TotalSumValue };
