import React from "react";

const filterableColumns = ["segment", "brand", "sku"];

const sortableColumns = [
  "currentMix",
  "idealMix",
  "mixDelta",
  "currentMarketShare",
  "idealMarketShare",
  "netRevenuePerKG",
  "noPbtPerKg",
  "revenueSop",
  "noPBTSop",
  "shareSOP",
];

const tableColumns = [
  {
    headerName: "Segment",
    field: "segment",
    width: 180,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Brand",
    field: "brand",
    width: 250,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "SKU",
    field: "sku",
    width: 450,
    align: "center",
    renderCell: (params) => (
      <div style={{ whiteSpace: "normal", textAlign: "center" }}>
        {params.value}
      </div>
    ),
  },
  {
    headerName: "Current Mix",
    field: "currentMix",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Ideal Mix",
    field: "idealMix",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Mix Delta",
    field: "mixDelta",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)} %` : "0.00 %";
    },
    align: "center",
  },
  {
    headerName: "Current Market Share",
    field: "currentMarketShare",
    width: 200,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
  {
    headerName: "Ideal Market Share",
    field: "idealMarketShare",
    width: 160,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
  {
    headerName: "Net Revenue Per Kg",
    field: "netRevenuePerKG",
    width: 200,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)}` : "0.00";
    },
    align: "center",
  },
  {
    headerName: "NOPBT Per Kg",
    field: "noPbtPerKg",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? `${params.value.toFixed(2)}` : "0.00";
    },
    align: "center",
  },
  {
    headerName: "Revenue SOP",
    field: "revenueSop",
    width: 160,
    valueFormatter: (params) => {
      return formatNumberWithSuffix(params.value);
    },
    align: "center",
  },
  {
    headerName: "NOPBT SOP",
    field: "noPBTSop",
    width: 160,
    valueFormatter: (params) => {
      return formatNumberWithSuffix(params.value);
    },
    align: "center",
  },
  {
    headerName: "Share SOP",
    field: "shareSOP",
    width: 160,
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      } else {
        return `${formatNumberWithSuffix(params.value)} %`;
      }
    },
    align: "center",
  },
];

const formatNumberWithSuffix = (value) => {
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const baseFactor = 1000;

  let numericValue;
  if (value === "-") {
      return value;
  } else {
      numericValue = typeof value === "string" ? parseFloat(value) : value;
  }

  if (!isNaN(numericValue)) {
      let isNegative = numericValue < 0;
      if (isNegative) {
          numericValue = Math.abs(numericValue);
      }

      let suffixIndex = 0;
      while (numericValue >= baseFactor && suffixIndex < suffixes.length - 1) {
          numericValue /= baseFactor;
          suffixIndex++;
      }

      numericValue = parseFloat(numericValue.toFixed(2)).toString();
      numericValue += suffixes[suffixIndex];

      if (isNegative) {
          numericValue = "-" + numericValue;
      }
  }
  return numericValue;
};

export { filterableColumns, sortableColumns, tableColumns };

export const columnsForPortfolioToDownload = [
  { title: "Segment", key: "segment" },
  { title: "Brand", key: "brand" },
  { title: "SKU", key: "sku" },
  { title: "Current Mix(%)", key: "currentMix" },
  { title: "Ideal Mix(%)", key: "idealMix" },
  { title: "Mix Delta(%)", key: "mixDelta" },
  { title: "Current Market Share(%)", key: "currentMarketShare" },
  { title: "Ideal Market Share(%)", key: "idealMarketShare" },
  { title: "Net Revenue per KG", key: "netRevenuePerKG" },
  { title: "NOPBT per KG", key: "noPbtPerKg" },
  { title: "Revenue SOP", key: "revenueSop" },
  { title: "NOPBT SOP", key: "noPBTSop" },
  { title: "Share SOP(%)", key: "shareSOP" },
];

export const levelsForPortFolio = [
  {
    id: 1,
    name: "Brand",
  },
  {
    id: 2,
    name: "Segment",
  },
  {
    id: 3,
    name: "SKU",
  },
];

export const portfolioData = [
  {
    id: 0,
    uid: "d60f2dc1-d4ef-40f4-b8b6-09fbaacd5b32",
    brand: "ARCOIRIS",
    sku: null,
    segment: null,
    level: "brand",
    skuVolume: 9460.44,
    skuSales: 66683.12,
    grossProfit: 11567.48,
    optimisedRevenue: 182432.58,
    optimisedProfit: 31646.469,
    skuOptimisedVolume: 25882,
    currentMix: 6.74791773170675,
    idealMix: 2.8492669319025,
    mixDelta: -3.899,
    penetration: 0.04,
    netRevenuePerKG: 7.048627,
    gpPerKG: 1.222721,
    revenueSop: 115749.5,
    profitSop: 20078.99,
    currentMarketShare: 0.0,
    idealMarketShare: 0.0,
  },
  {
    id: 0,
    uid: "d60f2dc1-d4ef-40f4-b8b6-09fbaacd5b32",
    brand: "CRACKETS",
    sku: null,
    segment: null,
    level: "brand",
    skuVolume: 9576.561,
    skuSales: 290477.7,
    grossProfit: 33734.12,
    optimisedRevenue: 1883139,
    optimisedProfit: 225565.73,
    skuOptimisedVolume: 62328,
    currentMix: 6.83074357936163,
    idealMix: 6.86149097178035,
    mixDelta: 0.031,
    penetration: 0.08,
    netRevenuePerKG: 30.21337,
    gpPerKG: 3.619011,
    revenueSop: 1592661,
    profitSop: 191831.6,
    currentMarketShare: 0.0,
    idealMarketShare: 0.0,
  },
];
