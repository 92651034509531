import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import data from "../../mocks/supermarketMock";
import DashboardActions from "../../components/DashboardActions";
import GlobalNotifications from "../../components/GlobalNotificationContainer";
import { pageList } from "../../mocks/common";
import TopFilters from "./components/TopFilters";
import ElasticityCoefficient from "./components/ElasticityCoefficient";
import SalesPricePoint from "./components/SalesPricePoint";
import AvgSalesPricePoint from "./components/AvgSalesPricePoint";
import Incrementality from "./components/Incrementality";
import { visualizationIds } from "./config";
import DashboardSourceList from "../../components/DashboardSourceList/DashboardSourceList";
import { removeMMPWFromLocal } from "../../util/helper";
import { useSelector } from "react-redux";

const SupermarketDashboard: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = useState({} as any);
  const [inputFilters, setInputFilters] = useState({});
  const [defaultFilters, setDefaultFilters] = useState({});
  const [
    defaultElasticityCoefficientInputs,
    setDefaultElasticityCoefficientInputs,
  ] = useState({});
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const onApplyFilter = (data) => {
    if (data.type === "FILTER_APPLIED") {
      setDisableDownloadButton(false);
      removeMMPWFromLocal("supermarket");
      setAppliedFilters(data.filters);
    }
  };

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      //use this after api development
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      const { elasticityCoefficient, ...inputs } =
        savedDashboardData?.filters?.inputs;
      setDefaultFilters(savedDashboardData?.filters?.global);
      setAppliedFilters(savedDashboardData?.filters?.global);
      setDefaultElasticityCoefficientInputs({
        elasticityCoefficient,
        inputs,
      });
    }
  };

  const reduxState = useSelector((state: any) => state);

  return (
    <DashboardActions
      title="Sales By Price Point"
      data={{
        filters: {
          global: { ...appliedFilters },
          inputs: { ...inputFilters },
        },
        mmpwKey: 'supermarket-',
      }}
      dashboardKey={pageList[3].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={disableDownloadButton || reduxState.SupermarketPepsicoBaseline.elasticityCoefficient}
    >
      <Grid container spacing={2} className="m-b-20">
        <GlobalNotifications pageName={pageList[3].value} />
      </Grid>
      <TopFilters
        callback={onApplyFilter}
        data={data}
        defaultFilters={defaultFilters}
      />
      <ElasticityCoefficient
        filters={appliedFilters}
        callback={setInputFilters}
        defaultElasticityCoefficientInputs={defaultElasticityCoefficientInputs}
      />
      <DashboardSourceList
        category={appliedFilters.category}
        dashboardName={pageList[3].id}
        appliedFilters={appliedFilters}
      />
    </DashboardActions>
  );
};
export default SupermarketDashboard;
