import React from 'react';
import BrandLadderContainer from './components/BrandLadderContainer';
import TopFilters from '../BrandLadder/components/TopFilters';
import DashboardActions from '../../components/DashboardActions';
import { pageList } from '../../mocks/common';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { useDispatch, useSelector } from 'react-redux';
import { brandLadderDataLoader } from './store/brandLadder.action';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const BrandLadder: React.FC<{}> = () => {
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [indexes, setIndexes] = React.useState({});
  const [savedIndexes, setSavedIndexes] = React.useState({});
  const [simulation, setSimulation] = React.useState({});
  const [defaultSimulation, setDefaultSimulation] = React.useState({});
  const [defaultFilters, setDefaultFilters] = React.useState<any>({});
  const [disableDownloadButton, setDisableDownloadButton] = React.useState(true);

  const dispatch = useDispatch();

  const onApplyFilter = (data) => {
    if (data && data.filters) {
      setDisableDownloadButton(false);
      setFilters(data.filters);
    }
    setIndexes(data.indexes);
    setDefaultFilters({});
    setSavedIndexes({});
    setSimulation([]);
  };

  const getSaveDashBoardsData = (data) => {
    dispatch(brandLadderDataLoader(true));
    if (data.filters) {
      let savedDashboardData: any = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      setDefaultSimulation(savedDashboardData?.filters?.simulation);
      setFilters(savedDashboardData?.filters?.global);
      setDefaultFilters(savedDashboardData?.filters?.global);
      setIndexes(savedDashboardData?.filters?.indexes);
      setSavedIndexes(savedDashboardData?.filters?.indexes);
    }
  };

  const isDisabled: boolean = false;
  const BrandLadderLoader = useSelector((state: any) => state.BrandLadderLoader);

  return (
    <>
      <DashboardActions
        title='Brand Ladder'
        disabled={isDisabled}
        data={{
          filters: {
            global: { ...appliedFilters },
            indexes: { ...indexes },
            simulation,
          },
        }}
        dashboardKey={pageList[10].id}
        callback={getSaveDashBoardsData}
        disableDownloadButton={disableDownloadButton || BrandLadderLoader.brandLadderDataLoader}
      >
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[10].value} />
        </Grid>
        <TopFilters callback={onApplyFilter} defaultFilters={defaultFilters} savedIndexes={savedIndexes} />
        <BrandLadderContainer filters={appliedFilters} defaultFilters={defaultSimulation} indexes={indexes} callback={setSimulation} />
        <DashboardSourceList
          dashboardName={pageList[10].id}
          appliedFilters={appliedFilters}
          sellInSellOutData={appliedFilters.data}
          category={appliedFilters?.pepsi?.category}
        />
      </DashboardActions>
    </>
  );
};

export default BrandLadder;
