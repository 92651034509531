import { Grid } from "@material-ui/core";
import React from "react";
import AssortmentDropdown from "../../../../components/AssortmentDropdown/AssortmentDropdown";
import { DropdownTitle } from "../../../../components/GeoFilters-V4/GeoFilters-V4.Styled";

interface props {
  filtersConfig: any;
  onChangeFilter: any;
}

const filterMapper = {
  Sku: "SKU",
  StoreSegment:"Store Segment",
  Channel:"Channel",
  Segment:"Segment",
  Brand:"Brand",
  Region:"Region"
}

const DynamicFilter: React.FC<props> = ({ filtersConfig, onChangeFilter }) => {
  const onChangeGeoLevel = (key, data) => {
    onChangeFilter(key, data);
  };

  const isMultiple = (title): boolean => {
    return true;
  };

  const getCheckedLabels = (data: any) => {
    const checkedLabels: string[] = [];

    data.forEach((option: { checked: boolean; label: string }) => {
      if (!option.checked) {
        checkedLabels.push(option.label);
      }
    });

    return checkedLabels;
  };

  return (
    <Grid style={{ display: "flex", whiteSpace: "nowrap" }}>
      {Object.entries(filtersConfig).map(
        ([filterKey, configOfFilter]: any, index) => (
          <Grid
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              marginRight: "20px",
            }}
            item
            key={`${filterKey}-${index}-${filterKey}`}
          >
            <DropdownTitle style={{ marginTop: "10px", marginRight: "5px" }}>
              {filterMapper[configOfFilter.title]} <span style={{ color: "red" }}>*</span>{" "}
            </DropdownTitle>
            <AssortmentDropdown
              data={configOfFilter.options}
              multiple={isMultiple(configOfFilter.title.toLowerCase())}
              allOption={isMultiple(configOfFilter.title.toLowerCase())}
              defaultOption={getCheckedLabels(configOfFilter.options)}
              onChange={(selectionData) =>
                onChangeGeoLevel(filterKey, selectionData)
              }
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default DynamicFilter;
