import { formatNumber } from '../../../../../../util/helper';

export const columnChartConfig = {
  chart: {
    type: 'column',
  },
  title: {
    align: 'left',
    text: '',
  },
  data: {
    table: 'datatable',
  },
  exporting: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    labels: {
      formatter: function () {
        return `${this.value}%`;
      },
    },
    title: {
      enabled: false,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    symbolRadius: 3,
    symbolHeight: 15,
    align: 'center',
    verticalAlign: 'top',
  },
  series: [
    {
      name: 'Price Effect',
      color: '#1F9456',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: 'Gram Effect',
      color: ' #ED6A2D',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      name: 'Total Effect',
      color: ' #A6BAAF',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
};
