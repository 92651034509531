import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import CorrelationChart from './components/CorrelationChart/CorrelationChart';
import APIOptimization from './components/APIOptimization/APIOptimization';
import G5 from './components/G5/G5';
import APISimulation from './components/APISimulation/APISimulation';
import MultipleAPI from './components/MultipleAPI/MultipleAPI';
import PepsiVsNonCompetitors from './components/PepsiVsNonCompetitors/PepsiVsNonCompetitors';
import TopFilters from './components/TopFilters';
import MultipleAPIFilters from './components/MultipleAPIFilters';
import data from '../../mocks/apiPredictiveDashboard';
import NonCompetitorFilters from './components/NonCompetitorFilters/NonCompetitorFilters';
import { useDispatch } from 'react-redux';
import { loader } from '../../store/actions/common.action';
import DashboardActions from '../../components/DashboardActions';
import { DropdownTitle } from '../../components/DashboardFilters/DashboardFilters.Styled';
import CheckBoxGroup from '../../components/UI-components/CheckBoxGroup';
import APISimulationFilter from './components/APISimulationFilter';
import GlobalNotifications from '../../components/GlobalNotificationContainer';
import { pageList } from '../../mocks/common';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { removeMMPWFromLocal } from '../../util/helper';
import { useSelector } from 'react-redux';

const APIPredictiveDashboard: React.FC = () => {
  const [defaultFilters, setDefaultFilters] = React.useState({} as any);
  const [defaultAPISimulatorFilters, setDefaultAPISimulatorFilters] = React.useState({} as any);
  const [defaultAPISimulatorBottomFilters, setDefaultAPISimulatorBottomFilters] = React.useState({} as any);
  const [appliedFilters, setFilters] = React.useState({} as any);
  const [apiSimulationBottomFilters, setAPISimulationBottomFilters] = useState({});
  const [apiSimulationFilter, setAPISimulationFilter] = React.useState({});
  const [apiOptimizationG5Filter, setAPIOptimizationG5Filter] = React.useState({});
  const [multipleAPIFilter, setMultipleAPIFilters] = React.useState({
    type: '',
    data: null,
  });
  const [multipleAPIFilterConfig, setMultipleAPIFiltersConfig] = React.useState({ ...data });
  const [pepsiVsNonCompetitorsFilter, setPepsiVsNonCompetitorsFilter] = React.useState({
    type: '',
    data: null,
  });
  const [defaultMultipleAPIFilter, setdefaultMultipleAPIFilters] = React.useState({
    type: '',
    data: null,
  });
  const measuresData = [
    { id: '1', value: "KGs ( in '000s )" },
    { id: '2', value: "Units ( in '000s )" },
  ];
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    variable1: 'VolumeIndex',
    variable2: 'API',
    somMeasure: null,
    somGroup: null,
    apiUnits: measuresData[0].value,
  });
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [chartPeriodicity, setChartPeriodicity] = useState<any>();

  const onApplyFilter = (data) => {
    if (data) {
      setDisableDownloadButton(false);
      removeMMPWFromLocal('api-predictive-dash');
      setFilters(data);
      setAPISimulationFilter({ type: 'global', data });
      setMultipleAPIFilters({ type: 'global', data });
      setPepsiVsNonCompetitorsFilter({ type: 'global', data });
      setMultipleAPIFiltersConfig({ ...data });
      setAPIOptimizationG5Filter({
        ...data,
        ...selectedMeasureFilters,
        apiUnits: selectedMeasureFilters.apiUnits === "KGs ( in '000s )" ? 'Volume' : 'Units',
      });
    }
  };

  const onApplyAPISimulationFilter = (data) => {
    removeMMPWFromLocal('api-predictive-dash-api-simulation-container');
    setAPISimulationFilter({
      type: 'local',
      data: { ...appliedFilters, ...data },
    });
    setDefaultAPISimulatorBottomFilters({});
  };

  const onApplyMultipleAPIFilter = (data) => {
    removeMMPWFromLocal('api-predictive-dash-multiple-api-container');
    setMultipleAPIFilters({ type: 'local', data });
  };

  const onApplyPepsiVsNonCompetitorsFilter = (data) => {
    setPepsiVsNonCompetitorsFilter({
      type: 'local',
      data,
    });
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loader(false));
  }, []);

  React.useEffect(() => {
    if (selectedMeasureFilters && Object.keys(appliedFilters).length && selectedMeasureFilters.apiUnits) {
      setAPIOptimizationG5Filter({
        ...appliedFilters,
        ...selectedMeasureFilters,
        apiUnits: selectedMeasureFilters.apiUnits === "KGs ( in '000s )" ? 'Volume' : 'Units',
      });
    }
  }, [selectedMeasureFilters]);

  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      //use this after api development
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setChartPeriodicity(savedDashboardData.filters.periodicity);
      localStorage.setItem('periodicity', savedDashboardData.filters.periodicity || '{}');
      setDisableDownloadButton(false);
      const defaultMesureFilters: any = {
        variable1: savedDashboardData?.filters?.global?.variable1,
        variable2: savedDashboardData?.filters?.global?.variable2,
        somMeasure: savedDashboardData?.filters?.global?.somMeasure,
        somGroup: savedDashboardData?.filters?.global?.somGroup,
        apiUnits: savedDashboardData?.filters?.global?.apiUnits === 'Volume' ? "KGs ( in '000s )" : "Units ( in '000s )",
      };
      setDefaultFilters(savedDashboardData?.filters?.global);
      setFilters(savedDashboardData?.filters?.global);
      setMeasureFilter(defaultMesureFilters);
      // setAPISimulationFilter({ type: 'global', data: data?.saveDashBoardData?.filters?.apiSimulationFilter });
      setDefaultAPISimulatorFilters(savedDashboardData?.filters?.apiSimulationFilter);
      setDefaultAPISimulatorBottomFilters(savedDashboardData?.filters?.apiSimulationBottomFilters || {});
      setMultipleAPIFilters({
        ...savedDashboardData?.filters?.multipleAPIFilter,
        data: {
          ...savedDashboardData?.filters?.multipleAPIFilter.data,
          apiUnits: savedDashboardData?.filters?.multipleAPIFilter?.data?.apiUnits === 'Volume' ? "KGs ( in '000s )" : "Units ( in '000s )",
        },
      });
      setAPISimulationFilter({
        ...savedDashboardData?.filters?.apiSimulationFilter,
        data: {
          ...savedDashboardData?.filters?.global,
          ...savedDashboardData?.filters?.apiSimulationFilter.data,
        },
      });
      setdefaultMultipleAPIFilters(savedDashboardData?.filters?.multipleAPIFilter);
    }
  };

  const reduxState = useSelector((state: any) => state);

  return (
    <DashboardActions
      title='API VS Volume Dashboard'
      data={{
        filters: {
          global: { ...appliedFilters },
          apiSimulationFilter: { ...apiSimulationFilter },
          apiSimulationBottomFilters: { ...apiSimulationBottomFilters },
          multipleAPIFilter: { ...multipleAPIFilter },
          pepsiVsNonCompetitorsFilter: { ...pepsiVsNonCompetitorsFilter },
        },
        mmpwKey: 'api-predictive-dash',
      }}
      dashboardKey={pageList[1].id}
      callback={getSaveDashBoardsData}
      disableDownloadButton={
        disableDownloadButton ||
        reduxState.common.loader ||
        reduxState.ApiPredictive.correlationChartDataLoader ||
        reduxState.ApiPredictive.correlationTableDataLoader ||
        reduxState.ApiPredictive.optimizationChartData ||
        reduxState.ApiPredictive.g5ChartData ||
        reduxState.ApiPredictive.simulationDataLoader ||
        reduxState.ApiPredictive.simulationValueLoader ||
        reduxState.ApiPredictive.multipleAPIDataLoader
      }
    >
      <Grid container spacing={2} className='m-b-20'>
        <GlobalNotifications pageName={pageList[1].value} />
      </Grid>
      <Box className='m-b-20'>
        <TopFilters callback={onApplyFilter} data={data} defaultFilters={defaultFilters} />
      </Box>
      <CorrelationChart filters={appliedFilters} />
      <Card className='m-b-20'>
        <CardContent>
          {appliedFilters && appliedFilters.country ? (
            <Grid container display='flex' alignItems='center' key={`api-optimization-measures-variable-config`}>
              <DropdownTitle className='m-r-20'>API Variable Configurations</DropdownTitle>
              <CheckBoxGroup
                data={measuresData}
                color='#000'
                direction='row'
                single={true}
                select={(data) => {
                  setMeasureFilter({
                    ...selectedMeasureFilters,
                    apiUnits: data,
                  });
                }}
                defaultOption={selectedMeasureFilters.apiUnits}
              />
            </Grid>
          ) : (
            <Grid className='p-l-16 m-t-20' container spacing={2}>
              <Skeleton variant='rectangular' className='m-r-20' width={210} height={45} />
              <Skeleton variant='rectangular' width={310} height={45} />
            </Grid>
          )}
        </CardContent>
      </Card>
      <APIOptimization filters={apiOptimizationG5Filter} />
      <G5 filters={apiOptimizationG5Filter} />
      <APISimulationFilter callback={onApplyAPISimulationFilter} globalFilters={appliedFilters} defaultFilters={defaultAPISimulatorFilters} />
      <APISimulation filters={apiSimulationFilter} callback={setAPISimulationBottomFilters} defaultFilters={defaultAPISimulatorBottomFilters} />
      <MultipleAPIFilters
        data={{ ...multipleAPIFilterConfig }}
        callback={onApplyMultipleAPIFilter}
        globalFilters={Object.keys(appliedFilters).length > 0 && appliedFilters.country ? appliedFilters : {}}
        defaultFilters={
          Object.keys(appliedFilters).length > 0 && appliedFilters.country && Object.keys({ ...defaultMultipleAPIFilter?.data }).length > 0
            ? defaultMultipleAPIFilter
            : {}
        }
      />
      <MultipleAPI filters={Object.keys(appliedFilters).length > 0 && appliedFilters.country ? multipleAPIFilter : {}} />
      {/* <NonCompetitorFilters
        callback={onApplyPepsiVsNonCompetitorsFilter}
        data={data}
        globalFilters={appliedFilters}
      />
      <PepsiVsNonCompetitors filters={pepsiVsNonCompetitorsFilter} /> */}
      <DashboardSourceList dashboardName={pageList[1].id} appliedFilters={appliedFilters} category={appliedFilters.category} />
    </DashboardActions>
  );
};

export default APIPredictiveDashboard;
