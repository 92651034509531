import React, { useEffect } from "react";
import Cards from "./Cards";
import { Card, Grid, Skeleton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchMixSummaryData } from "../../../../store/actions/MixSimulationTool/mixSummary.action";
import Indicator from "../../../../components/Loader/Loader";

const OutputSummary = () => {
  const { summaryLoader, summaryData } = useSelector(
    (state: any) => state.MixSummaryReducer
  );
  const selectedFilterData = useSelector(
    (state: any) => state.mixSimulationAllFilter.selectedFilters
  );
  const dispatch = useDispatch();

  const createCardDataFromBackendResponse = (response) => {
    const createCardData = (section, title) => {
      const data = {
        Title: title,
        netRevenue: title=='Percent Change (%)'?formatNumber(response[`${section}NetRevenue`]) + "%":formatNumber(response[`${section}NetRevenue`]),
        grossProfit: title=='Percent Change (%)'?formatNumber(response[`${section}NoPBT`]) + "%":formatNumber(response[`${section}NoPBT`]),
        grossProfitPercentage:response[`${section}NoPBTPercent`] == "-" ? "-" : formatNumber(
          response[`${section}NoPBTPercent`]
        ) + "%",
        // marketShare: formatNumber(response[`${section}MarketShare`]) + "%",
        marketShare: response[`${section}MarketShare`] == "-" ? "-" : formatNumber(
          response[`${section}MarketShare`]
        ) + "%",
      };
      return [data];
    };

    const baseScenarioData = createCardData("base", "Base Scenario");
    const userScenarioData = createCardData("user", "User Scenario");
    const absIncrementalData = createCardData(
      "absIncremental",
      "Abs. Incremental"
    );
    const percentChangeData = createCardData(
      "percentChange",
      "Percent Change (%)"
    );

    return [
      ...baseScenarioData,
      ...userScenarioData,
      ...absIncrementalData,
      ...percentChangeData,
    ];
  };

  const formatNumber = (value) => {
    let absValue = Math.abs(value);
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixIndex = 0;

    while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
      absValue /= 1000;
      suffixIndex++;
    }

    const formattedValue = value >= 0 ? absValue : -absValue;
    const suffix = suffixes[suffixIndex];

    return `${formattedValue.toFixed(2)} ${suffix}`;
  };

  const backendResponse = {
    uid: "d60f2dc1-d4ef-40f4-b8b6-09fbaacd5b32",
    baseNetRevenue: 2411763,
    userNetRevenue: 16398200,
    absIncrementalNetRevenue: 13986439,
    percentChangeNetRevenue: 579.926,
    baseGrossProfit: 315161.9688,
    userGrossProfit: 2123896.5,
    absIncrementalGrossProfit: 1808734.5,
    percentChangeGrossProfit: 573.9063,
    baseMarginPercent: 13,
    userMarginPercent: 12,
    absIncrementalMarginPercent: 0,
    percentChangeMarginPercent: "-",
    baseMarketShare: 83,
    userMarketShare: 83,
    absIncrementalMarketShare: 0,
    percentageChangeMarketShare: "-",
  };

  return (
    <>
      <Typography color={"white"} variant="h6">
        Summary
      </Typography>
      {summaryData === null || (summaryData && summaryData.length === 0) ? (
        <Grid container spacing={2}>
          {createCardDataFromBackendResponse(backendResponse).map(
            (data, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Card
                  sx={{
                    borderRadius: "5px",
                    position: "relative",
                    backgroundColor: "white",
                  }}
                >
                  <Indicator position="absolute" show={summaryLoader} />
                  <Grid
                    container
                    style={{ height: "300px" }}
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Skeleton variant="rectangular" width="100%" height={300} />
                    <Typography
                      sx={{
                        zIndex: 1,
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {createCardDataFromBackendResponse(summaryData).map((data, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <Card
                sx={{
                  borderRadius: "5px",
                  position: "relative",
                  backgroundColor: "white",
                }}
              >
                <Indicator position="absolute" show={summaryLoader} />
                <Cards data={data} loading={summaryLoader} />
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default OutputSummary;
