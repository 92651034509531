export const sectionFilterConfig = {
    channel: {
        title: "Channel",
        options: [],
        all: false,
        multiple: false,
        defaultOption : [],
    },
    region: {
        title: "Region",
        options: [],
        all: false,
        multiple: false,
        defaultOption : [],
        defaultSelectAll: false,
    },
    segment: {
        title: "Segment",
        options: [],
        all: true,
        multiple: true,
        defaultOption : []
    },
    brand: {
        title: "Brand",
        options: [],
        all: true,
        multiple: true,
        defaultOption : []
    },
    subBrand: {
        title: "Sub Brand",
        options: [],
        all: true,
        multiple: true,
        defaultOption : []
    },
    // segment: {
    //     title: "Segment",
    //     options: [],
    //     all: true,
    //     multiple: true,
    //     defaultOption : []
    // },
};