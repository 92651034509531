import { splitArray } from "../../../pages/ExecutionTool/components/helper";
import { selectedFilters } from "../../actions/allFilter.action";
import {
  CLEAR_EXECUTION_FILTERS_REQUEST,
  FETCH_EXECUTION_FILTERS_FAILURE,
  FETCH_EXECUTION_FILTERS_REQUEST,
  FETCH_EXECUTION_FILTERS_SUCCESS,
  SELECTED_EXECUTION_FILTER,
  SET_LAST_TRIGGERED_KEY,
  SET_PREVIOUSLY_SELECTED_FILTER,
  SET_TIMEPERIOD,
  UPDATE_EXECUTION_FILTER,
} from "../../actions/constants";

const initialState = {
  data: {
    overall: {
      country: [],
      period: [],
      businessUnit: [],
    },
    geo: {
      channel: [],
      region: [],
      storeSegment: [],
    },
  },
  lastTriggeredKey: "",
  default: true,
  loading: false,
  error: null,
  previouslyselectedFilters: {
    country: [],
    period: [],
    businessUnit: [],
    channel: [],
    region: [],
    storeSegment: [],
  },
  selectedFilters: {
    country: [],
    period: [],
    businessUnit: [],
    channel: [],
    region: [],
    storeSegment: [],
  },
  timePeriod: "",
};

const allFilter = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXECUTION_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_EXECUTION_FILTERS_SUCCESS:
      const { overall, geo } = action.payload.data;
      const existingOverall = state.data.overall;
      const existingGeo = state.data.geo;
      const lastTriggeredKey = state.lastTriggeredKey;

      const updateAllFilters = (existingFilters, incomingFilters) => {
        let updatedFilter = {};
        const allKeys = Array.from(
          new Set([
            ...Object.keys(existingFilters),
            ...Object.keys(incomingFilters),
          ])
        );
        console.log("allKeys", allKeys);
        const unionKeys = splitArray(allKeys, lastTriggeredKey);
        console.log("unionKeys", unionKeys);
        console.log("unionKeys", lastTriggeredKey);

        allKeys.forEach((key) => {
          if (unionKeys.includes(key)) {
            updatedFilter[key] = unionArraysForKey(
              existingFilters,
              incomingFilters,
              key
            );
          } else {
            console.log("incomingFilters", incomingFilters, key);

            updatedFilter[key] = incomingFilters[key];
          }
        });
        return updatedFilter;
      };

      const unionArraysForKey = (existingObject, incomingObject, key) => {
        const set = new Set([...existingObject[key], ...incomingObject[key]]);
        return Array.from(set);
      };
      const updateSelectedFilters = (existingFilters, newFilters) => {
        const updatedFilters = { ...existingFilters };
        const newFiltersToBeUpated = splitArray(
          Object.keys(newFilters),
          lastTriggeredKey
        );
        console.log("lastTriggeredKey", lastTriggeredKey);
        for (const key in newFilters) {
          if (newFiltersToBeUpated.includes(key)) {
            if (
              Object.hasOwnProperty.call(newFilters, key) &&
              newFilters[key].length == 0
            ) {
              updatedFilters[key] = newFilters[key];
            }
          } else {
            updatedFilters[key] = [];
          }
        }
        console.log("lastTriggeredKey", updatedFilters);
        return updatedFilters;
      };
      const updatedAllFilterData: any = updateAllFilters(
        { ...existingOverall, ...existingGeo },
        { ...overall, ...geo }
      );
      return {
        ...state,
        data: {
          ...state.data,
          overall: {
            ...state.data.overall,
            country: updatedAllFilterData.country,
            period: updatedAllFilterData.period,
            businessUnit: updatedAllFilterData.businessUnit,
          },
          geo: {
            ...state.data.geo,
            channel: updatedAllFilterData.channel,
            region: updatedAllFilterData.region,
            storeSegment: updatedAllFilterData.storeSegment,
          },
        },

        selectedFilters: updateSelectedFilters(state.selectedFilters, {
          ...overall,
          ...geo,
        }),

        default: false,
        loading: false,
        error: null,
      };
    case SET_LAST_TRIGGERED_KEY:
      return {
        ...state,
        lastTriggeredKey: action.payload,
      };
    case SET_PREVIOUSLY_SELECTED_FILTER:
      const updatePreviouslySelectedFilters = (selectedFilters) => {
        const lastTriggeredKey = state.lastTriggeredKey;
        const updatedPreviousFilters = { ...state.previouslyselectedFilters };
        const newFiltersToBeUpated = splitArray(
          Object.keys(selectedFilters),
          lastTriggeredKey
        );
        for (const key in selectedFilters) {
          if (newFiltersToBeUpated.includes(key)) {
            if (
              Object.hasOwnProperty.call(selectedFilters, key) &&
              selectedFilters[key].length != 0
            ) {
              updatedPreviousFilters[key] = selectedFilters[key];
            }
          } else {
            updatedPreviousFilters[key] = [];
          }
        }
        return updatedPreviousFilters;
      };
      return {
        ...state,
        previouslyselectedFilters: updatePreviouslySelectedFilters(
          state.selectedFilters
        ),
      };
    case CLEAR_EXECUTION_FILTERS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          overall: action.payload.data.overall,
          geo: action.payload.data.geo,
        },
        selectedFilters: {
          ...state.selectedFilters,
          country: [],
          period: [],
          businessUnit: [],
          channel: [],
          region: [],
          storeSegment: [],
        },
        previouslyselectedFilters: {
          ...state.previouslyselectedFilters,
          country: [],
          period: [],
          businessUnit: [],
          channel: [],
          region: [],
          storeSegment: [],
        },
        default: false,
        loading: false,
        error: null,
      };
    case FETCH_EXECUTION_FILTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SELECTED_EXECUTION_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload?.name]:
            typeof action.payload.value === "string"
              ? [action.payload.value]
              : action.payload.value,
        },
      };
    case UPDATE_EXECUTION_FILTER:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case SET_TIMEPERIOD:
      return {
        ...state,
        timePeriod: action.payload,
      };
    default:
      return state;
  }
};
export default allFilter;
