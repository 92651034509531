import { Grid, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import CustomDateRangePicker from '../../components/UI-components/DateRangePicker/CustomDateRangePicker';
import { countries, periodicities } from '../../mocks/common';
import data from '../../mocks/reportingMock';
import { PrimaryBtn, Title } from '../../styles/Common.Styled';
import { defaultCalendarDates } from '../../util/config';
import Report from './Components/Report';
import StatCard from './StatCard/StatCard';
import { convertToInternationalCurrencySingleChar, dataFormat } from '../../util/helper';
import { getKpiData, getUsers } from './service';
import { getCountries } from '../UserReport2/service';
import { loader } from '../../store/actions/common.action';
import { useDispatch } from 'react-redux';
const Reporting: React.FC<{}> = () => {
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [kpiData, setKpiData] = React.useState(data.KPIData1);
  const [kpiSkeleton, setKpiSkeleton] = React.useState(true);
  const [users, setUsers] = useState<any>([]);
  const [filters, setFilters] = React.useState({});
  const [selectedPriodicity, setSelectedPriodicity] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const chartRef = useRef(null);
  const [selectedDate, setDate] = React.useState([new Date(), new Date()]);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  const onChangeCountry = (data) => {
    setSelectedCountries(data);
    setSelectedUsers([]);
    getUsers(data.join(','))
      .then((res) => {
        setUsers(
          res.data.map((item) => ({
            ...item,
            label: item.fullName,
            value: item.emailAddress,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disableApplyFilter = () => {
    if (selectedPriodicity && countries.length > 0) {
      return false;
    }
    return true;
  };

  const onApplyFilter = () => {
    let userIds = selectedUsers.map((item) => {
      return String(users.filter((x) => x.value === item)[0].id);
    });
    const payload = {
      country: selectedCountries,
      date: `${dataFormat(selectedDate[0], 'MM/dd/yyyy')}-${dataFormat(selectedDate[1], 'MM/dd/yyyy')}`,
      periodicity: selectedPriodicity,
      users: userIds,
    };
    setFilters(payload);
  };

  const onChangeUser = (data) => {
    setSelectedUsers(data);
  };

  useEffect(() => {
    if (filters && filters?.country && filters?.country.length > 0) {
      getKpiData(filters)
        .then((res) => {
          const newKpiData = { ...kpiData };
          Object.keys(newKpiData).map((key) => {
            newKpiData[key].value = res?.data[key].value ? res?.data[key].value : '0';
            newKpiData[key].raise = res?.data[key].change ? res?.data[key].change >= 0 : null;
            newKpiData[key].change = res?.data[key].change ? `${convertToInternationalCurrencySingleChar('', res?.data[key].change)}%` : '0';
          });
          setKpiData(newKpiData);
          setKpiSkeleton(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filters]);

  useEffect(() => {
    dispatch(loader(true));
    getCountries()
      .then((response) => {
        formatCountries(response.data);
        dispatch(loader(false));
      })
      .catch((e) => {
        dispatch(loader(false));
        console.log(e);
      });
  }, []);

  const formatCountries = (data) => {
    const newarr = data.map((item, i) => {
      return {
        id: i,
        label: item,
        value: item,
      };
    });
    setCountries(newarr);
  };

  return (
    // <Grid display={'flex'} justifyContent={'space-between'}>
    //   <BlackTitle style={{ fontSize: 24, fontWeight: 600 }}>Net Revenue Realization Dashboard Reports</BlackTitle>
    // </Grid>
    <>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={12} md={4}>
          <Tooltip title={'Net Revenue Realization Dashboard Reports'} placement='top'>
            <Title data-testid='main-title' className='ellipsis'>
              {/* Net Revenue Realization Dashboard Reports */}
              Usage Count Report
            </Title>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item md={2.5} sm={2.5}>
              <Dropdown
                keyText='report-country-dropdown'
                data={countries}
                multiple={true}
                multipleSelectionMsg='Multiple'
                placeholder='Select Country'
                allOption={true}
                defaultOption={selectedCountries}
                onChange={onChangeCountry}
              />
            </Grid>
            <Grid item md={2} sm={2}>
              <Dropdown
                keyText='report-user-dropdown'
                data={users}
                multiple={true}
                multipleSelectionMsg='Multiple'
                placeholder='Select Users'
                allOption={true}
                defaultOption={selectedUsers}
                onChange={(data) => {
                  onChangeUser(data);
                }}
              />
            </Grid>
            <Grid item md={2.5} sm={2.5}>
              <Dropdown
                keyText='report-periodicity-dropdown'
                data={periodicities}
                multiple={false}
                placeholder='Select Periodicity'
                defaultOption={selectedPriodicity}
                onChange={(data) => {
                  setSelectedPriodicity(data[0]);
                }}
              />
            </Grid>
            <Grid item md={3} sm={3}>
              <CustomDateRangePicker defaultDate={selectedDate} callback={setDate} />
            </Grid>
            <Grid item md={1.5} sm={1.5} textAlign={'end'}>
              <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid md={12} className='m-t-20'>
          <Grid container spacing={2} justifyContent={'start'}>
            {Object.keys(kpiData).map((key, index) => (
              <Grid item key={`stat-card-${index}`} md={2.4}>
                <StatCard key={`StatCard-${index}`} data={kpiData[key]} showSkeleton={kpiSkeleton} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(data?.series).map((key, index) => (
        <Report seriesData={{ item: data?.series[key], index }} filters={filters} />
      ))}
    </>
  );
};

export default Reporting;
