import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable';
import Dashboard from '../../../../components/Dashboard';
import data from '../../../../mocks/apiPredictiveDashboard';
import { loader } from '../../../../store/actions/common.action';
import { GraphLabel, NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { dataFormat, plotImages, rendarImage, separator, setColor, windowResizeListner } from '../../../../util/helper';
import { correlationGraphConfig } from '../../chartsConfig';
import { getCorrelationChartData, getCorrelationTableData } from '../../service';
import CorrelationTable from '../CorrelationTable';
import HighchartContainer from '../../../../components/HighchartContainer';
import IncrementDecrement from '../../../../components/IncrementDecrement/IncrementDecrement';
import { correlationChartDataLoader, correlationTableDataLoader } from '../../store/apiPredictive.action';
const defaultPeriodicity = 'weekly';
const visualId = 'api-predictive-dash-correlation-chart-container';
const CorrelationChart: React.FC<{ filters }> = ({ filters }) => {
  const dispatch = useDispatch();
  const chartRef = React.useRef<any>(null);
  const [correlation, setCorrelation] = React.useState(0);
  const [correlationTableSkeleton, setCorrelationTableSkeleton] = React.useState(true);
  const [correlationChartSkeleton, setCorrelationChartSkeleton] = React.useState(true);
  const [productImages, setProductImages] = useState<any>([]);
  const [originalCorrelationTableResponse, setOriginalCorrelationTableResponse] = useState<any>({});
  const [correlationTableData, setCorrelationTableData] = useState<any>([]);
  const [originalCorrelationChartResponse, setOriginalCorrelationChartResponse] = useState<any>({});
  const [correlationChartData, setCorrelationChartData] = useState<any>({});

  const getAvgTableFields = () => {
    return {
      heading: [{ title: '' }],
      value: [
        {
          title: 'Average PepsiCo Sell Out',
        },
        {
          title: 'Growth Average PepsiCo Sell Out',
        },
        {
          title: 'Average Anchor Sell Out',
        },
        {
          title: 'Growth Average Anchor Sell Out',
        },
        {
          title: 'PepsiCo Sales Amount',
        },
        {
          title: "Growth PepsiCo Sales Amount (in 000's)",
        },
        {
          title: "PepsiCo Sales Units (in 000's)",
        },
        {
          title: "Growth PepsiCo Sales Units (in 000's)",
        },
        {
          title: "PepsiCo Sales Volume (in 000's)",
        },
        {
          title: "Growth PepsiCo Sales Volume (in 000's)",
        },
      ],
    };
  };

  const formatCorrelationChartData = async (data) => {
    if (data.variable1 && data.variable2) {
      setCorrelation(Math.round(data.correlation));
      const dates = data.variable1.map((item) => item.date);
      // const formattedDate = dataFormat(dates, "MM/yy");
      if (chartRef && chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;

        setLabels(chart);
        chart.xAxis[0].setCategories(dates);
        chart.series[0].setData(data.variable1.map((item) => item.value));
        chart.series[1].setData(data.variable2.map((item) => item.value));
      }

      if (data.productName || data.anchorProductName) {
        let product1: any = null;
        let product2: any = null;
        if (data.productName) {
          product1 = {
            base64: await rendarImage(data.productName, filters.country),
          };
        }
        if (data.anchorProductName) {
          product2 = {
            base64: await rendarImage(data.anchorProductName, filters.country),
          };
        }
        if (product1 && product1.base64 && product2 && product2.base64) {
          setProductImages([product1, product2]);
        } else if (product1 && product1.base64) {
          setProductImages([product1]);
        } else if (product2 && product2.base64) {
          setProductImages([product2]);
        }
      }
    }
  };

  const [correlationPepsiTableData, setCorrelationPepsiTableData] = React.useState(data.correlationPepsiCoTable);
  const [correlationAnchorTableData, setCorrelationAnchorTableData] = React.useState(data.correlationAnchorTable);
  const [correlationYearTable, setCorrelationYearTable] = React.useState(getAvgTableFields());

  const formatCorrelationTableData = (data, tableData) => {
    tableData.map((p) => {
      p.values = [];
    });
    if (data && _.isArray(data) && data.length > 0) {
      data.map((item) => {
        let colSpan = 0;
        item.packSizeData.map((subItem) => {
          colSpan += _.isArray(subItem.grammage) ? subItem.grammage.length : 0;
          tableData[1].values.push({
            filed: subItem.name,
            colSpan: _.isArray(subItem.grammage) ? subItem.grammage.length : 0,
          });
          tableData[2].values = [...tableData[2].values, ...subItem.grammage.map((g) => ({ filed: g }))];
          tableData[3].values = [...tableData[3].values, ...subItem.grammagePercentage.map((g) => ({ filed: g }))];
        });
        tableData[0].values.push({
          filed: item.dateString, //dataFormat(item.date, "MMM/yy"),
          colSpan,
        });
      });
    }
    return tableData;
  };

  const formatCorrelationYearTableData = (response) => {
    const tableData = getAvgTableFields();
    if (response && _.isArray(response) && response.length) {
      response.map((item) => {
        tableData.heading.push({ title: item.year });
        tableData.value[0][`field${item.year}`] = separator(item.avgSelloutPepsi.toFixed(2));
        tableData.value[1][`field${item.year}`] = item.growthAvgSelloutPepsi.toFixed(2) + ' %';
        tableData.value[2][`field${item.year}`] = separator(item.avgSelloutAnchor.toFixed(2));
        tableData.value[3][`field${item.year}`] = item.growthAvgSelloutAnchor.toFixed(2) + ' %';
        tableData.value[4][`field${item.year}`] = separator(item.salesAmountPepsi.toFixed(2));
        tableData.value[5][`field${item.year}`] = item.growthSalesAmountPepsi.toFixed(2) + ' %';
        tableData.value[6][`field${item.year}`] = separator(item.pepsicoSaleVolumeunit.toFixed(2));
        tableData.value[7][`field${item.year}`] = item.growthPepsicoSaleVolumeunit.toFixed(2) + ' %';
        tableData.value[8][`field${item.year}`] = separator(item.pepsicoSaleVolumekg.toFixed(2));
        tableData.value[9][`field${item.year}`] = item.growthPepsicoSaleVolumekg.toFixed(2) + ' %';
      });
    }
    return tableData;
  };

  React.useEffect(() => {
    if (correlationTableData && Object.keys(correlationTableData).length) {
      const pepsiCorrelationTable = formatCorrelationTableData(correlationTableData.pepsico, [...correlationPepsiTableData]);
      setCorrelationPepsiTableData(pepsiCorrelationTable);
      const anchorCorrelationTable = formatCorrelationTableData(correlationTableData.anchor, [...correlationAnchorTableData]);
      setCorrelationAnchorTableData(anchorCorrelationTable);
      const correlationYearTableData = formatCorrelationYearTableData(correlationTableData.avg);
      setCorrelationYearTable(correlationYearTableData);
    }
  }, [correlationTableData]);

  React.useEffect(() => {
    if (correlationChartData?.variable1) {
      formatCorrelationChartData(correlationChartData);
    }
  }, [correlationChartData]);

  const onChangePeriodicity = (data) => {
    if (data.item && !_.isEmpty(originalCorrelationChartResponse)) {
      setCorrelationChartData(originalCorrelationChartResponse[data.item]);
    }
    if (data.item && !_.isEmpty(originalCorrelationTableResponse)) {
      const payload = {
        pepsico: originalCorrelationTableResponse.pepsico[data.item],
        anchor: originalCorrelationTableResponse.anchor[data.item],
        avg: originalCorrelationTableResponse.avg[data.item],
      };
      setCorrelationTableData(payload);
    }
  };

  React.useEffect(() => {
    if (filters && filters.country) {
      dispatch(correlationChartDataLoader(true));
      resetData();
      getCorrelationChartData(filters)
        .then((response) => {
          setCorrelationChartSkeleton(false);
          if (response && response.data) {
            dispatch(correlationChartDataLoader(false));
            setOriginalCorrelationChartResponse(response.data);
            setCorrelationChartSkeleton(false);
            setCorrelationChartData(response.data[defaultPeriodicity]);
          }
        })
        .catch((error) => {
          setCorrelationChartSkeleton(false);
          dispatch(correlationChartDataLoader(false));
        });

      dispatch(correlationTableDataLoader(true));
      getCorrelationTableData(filters)
        .then((response) => {
          setCorrelationTableSkeleton(false);
          dispatch(correlationTableDataLoader(false));
          if (response && response.data) {
            setOriginalCorrelationTableResponse(response.data);
            const payload = {
              pepsico: response.data.pepsico[defaultPeriodicity],
              anchor: response.data.anchor[defaultPeriodicity],
              avg: response.data.avg[defaultPeriodicity],
            };
            setCorrelationTableData(payload);
          }
        })
        .catch((error) => {
          dispatch(correlationTableDataLoader(false));
          setCorrelationTableSkeleton(false);
          dispatch(loader(false));
        });
    }
  }, [filters]);

  const setLabels = (chart) => {
    if (filters && filters.variable1 && filters.variable2) {
      chart.yAxis[0].setTitle({ text: filters.variable1 });
      chart.yAxis[1].setTitle({ text: filters.variable2 });
      chart.legend.allItems[0].update({
        name: filters.variable1,
      });
      chart.legend.allItems[1].update({
        name: filters.variable2,
      });
    }
  };

  useEffect(() => {
    if (productImages.length > 0 && chartRef && chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const config = {
        productImages: productImages,
        id: 'api-predictive-dash-correlation-chart-image',
        imgPosition: { x: 220, y: 280 },
        imgDimension: { width: 75, height: 60 },
        nameDimension: { width: 50, height: 55 },
      };
      windowResizeListner(chart, config);
      plotImages(chart, config);
    }
  }, [productImages]);

  const resetData = () => {
    setCorrelation(0);
    setCorrelationTableSkeleton(true);
    setCorrelationChartSkeleton(true);
    setProductImages([]);
    setOriginalCorrelationTableResponse({});
    setCorrelationTableData([]);
    setOriginalCorrelationChartResponse({});
    setCorrelationChartData({});
    setCorrelationPepsiTableData(data.correlationPepsiCoTable);
    setCorrelationAnchorTableData(data.correlationAnchorTable);
    setCorrelationYearTable(getAvgTableFields());
  };

  const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);
  const correlationReduxState = useSelector((state: any) => state.ApiPredictive);

  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard title='Correlation Chart' showSkeleton={correlationChartSkeleton} chartRef={chartRef} id={visualId}>
          {correlationChartSkeleton ? (
            <>
              <Grid item xs={12}>
                <Skeleton variant='rectangular' height={350} />
              </Grid>
              <Grid container justifyContent='center' className='m-b-20'>
                <Skeleton variant='rectangular' width={150} height={40} className='m-t-20' />
              </Grid>
            </>
          ) : !_.isEmpty(correlationChartData) && !_.isEmpty(correlationChartData.variable1) && !_.isEmpty(correlationChartData.variable2) ? (
            <>
              <HighchartContainer chartRef={chartRef} id={visualId}>
                <HighchartsReact highcharts={Highcharts} options={correlationGraphConfig} ref={chartRef} />
                <Grid style={{ position: 'relative', bottom: 50, left: 30, zIndex: 2, width: '100%' }}>
                  <IncrementDecrement
                    callback={onChangePeriodicity}
                    show={!correlationReduxState.correlationChartDataLoader && !correlationReduxState.correlationTableDataLoader}
                    id={visualId}
                    defaultOption={
                      (localStorage.getItem('periodicity') &&
                        JSON.parse(localStorage.getItem('periodicity'))[visualId] &&
                        JSON.parse(localStorage.getItem('periodicity'))[visualId]?.item) ||
                      null
                    }
                  />
                </Grid>
              </HighchartContainer>

              <Grid container justifyContent='center' className='m-b-20'>
                <GraphLabel {...setColor(correlation, 'correlation')}>Correlation {correlation}%</GraphLabel>
              </Grid>
            </>
          ) : (
            <Box style={{ height: 400 }} display='flex' justifyContent='center' alignItems='center'>
              <NoData>{messages.noData}</NoData>
            </Box>
          )}
          {correlationTableSkeleton ||
          (!correlationTableSkeleton &&
            correlationTableData &&
            correlationTableData.anchor &&
            correlationTableData.pepsico &&
            correlationTableData.avg) ? (
            <Grid
              container
              style={{
                minWidth: '200px',
                maxWidth: sidebarOpen ? '78vw' : '89vw',
              }}
            >
              <Grid item xs={12} className='m-b-20'>
                <CorrelationTable data={correlationPepsiTableData} showSkeleton={correlationTableSkeleton} />
              </Grid>
              <Grid item xs={12}>
                <CorrelationTable data={correlationAnchorTableData} showSkeleton={correlationTableSkeleton} isFormatNumber={false} />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {correlationTableSkeleton ||
          (!correlationTableSkeleton &&
            correlationTableData &&
            correlationTableData.anchor &&
            correlationTableData.pepsico &&
            correlationTableData.avg) ? (
            <Grid
              container
              className='m-t-20'
              style={{
                width: 'fit-content',
                minWidth: '300px',
                maxWidth: correlationYearTable.heading.length > 1 ? (sidebarOpen ? '78vw' : '89vw') : '300px',
              }}
            >
              <Grid item xs={12}>
                <CustomTable
                  headingBgColor='#25d4cf'
                  headingColor='#fff'
                  heading={correlationYearTable.heading}
                  value={correlationYearTable.value}
                  showSkeleton={correlationTableSkeleton}
                  dataAlign='left'
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};

export default CorrelationChart;
