import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";
import _ from "lodash";

const MeasureFilter: React.FC<{
  data;
  selectedGeoFilters;
  selectedMeasureFilters;
  defaultFilters?;
  callback;
}> = ({
  data,
  selectedGeoFilters,
  defaultFilters,
  selectedMeasureFilters,
  callback,
}) => {
  useEffect(() => {
    if (selectedGeoFilters.country) {
      data.comparisonLevel.options[1].label =
        selectedGeoFilters.country !== "MEXICO"
          ? "Brand Pack Size"
          : "Sub-Brand/Pack-Size";
    }
  }, [selectedGeoFilters.country]);

  return (
    <Grid container spacing={2} columns={16}>
      {/* <Grid item xs={12} sm={3} key={`measures-dropdown-container-1`}>
        <DropdownTitle>{data.comparisonLevel.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-filters-1`}
          color="#000"
          showButton={data.comparisonLevel.showRadioButton}
          data={data.comparisonLevel.options}
          defaultOption={
         selectedMeasureFilters.comparisonLevel
          }
          select={(data) =>
          callback({ ...selectedMeasureFilters, comparisonLevel: data })
          }
        />
      </Grid> */}
      <Grid item xs={12} sm={6} key={`measures-dropdown-container-2`}>
        <Grid item xs={12} sm={12}>
          <DropdownTitle>
            Price formula ( Model is created based on EDRP)
          </DropdownTitle>
        </Grid>

        <RadioButtonGroup
          key={`measures-filters-2`}
          color="#000"
          showButton={data.priceFormula.showRadioButton}
          data={data.priceFormula.options}
          defaultOption={selectedMeasureFilters.priceFormula}
          select={(data) =>
            callback({ ...selectedMeasureFilters, priceFormula: data })
          }
        />
      </Grid>
    </Grid>
  );
};

export default MeasureFilter;
