import { Box, Card, CardContent, Grid, Skeleton, Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable';
import Dropdown from '../../components/Dropdown';
import CustomDateRangePicker from '../../components/UI-components/DateRangePicker';
import { BlackTitle, NoData, PrimaryBtn, Title } from '../../styles/Common.Styled';
import { defaultCalendarDates, messages } from '../../util/config';
import { dataFormat } from '../../util/helper';
import MultilevelTableContainer from './MultilevelTableContainer/MultilevelTableContainer';
import { getCountries, getCountryAccess } from './service';
import { loader } from '../../store/actions/common.action';
import { useDispatch } from 'react-redux';
import { getUsers } from '../Reporting/service';

const multiLevelTableConfig = {
  countryActivity: {
    title: 'Country Activity',
    endPoint: 'UsageReport/getUsageReportCountryActivitywithCountry',
    headers: [
      { label: 'Name', value: 'name' },
      { label: 'View Count', value: 'viewCount' },
      { label: 'Save Count', value: 'saveCount' },
      { label: 'Shared Count', value: 'sharedCount' },
    ],
    child: 'country',
  },
  // dashboardActivity: {
  //   title: 'Dashboard Activity',
  //   endPoint: 'UsageReport/getUsageReportDashboardActivity',
  //   headers: [
  //     { label: 'Name', value: 'name' },
  //     { label: 'View Count', value: 'viewCount' },
  //     { label: 'Favories Count', value: 'viewCount' },
  //     { label: 'Save Count', value: 'saveCount' },
  //     { label: 'Shared Count', value: 'sharedCount' },
  //   ],
  //   child: "dashboard"
  // },
  userActivity: {
    title: 'User Activity',
    endPoint: 'UsageReport/getUsageReportUserActivityWithCountry',
    headers: [
      { label: 'Name', value: 'name' },
      { label: 'Dashboard', value: 'dashboard' },
      { label: 'Date', value: 'date' },
    ],
    child: 'activity',
  },
};

const UsageReport2: React.FC = () => {
  const [countries, setCountries] = useState([]);
  const [tableHeadings, setTableHeadings] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [selectedDate, setDate] = React.useState<any>([new Date(), new Date()]);
  const [filters, setFilters] = useState({});
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const [skeleton, setSkeleton] = useState<any>(true);
  const [users, setUsers] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const dispatch = useDispatch();

  const userList = () => {
    dispatch(loader(true));
    getCountries()
      .then((response) => {
        formatUsers(response.data);
        dispatch(loader(false));
      })
      .catch((e) => {
        dispatch(loader(false));
        console.log(e);
      });
  };

  const onChangeCountry = (data) => {
    setSelectedCountry(data);
    setSelectedUsers([]);
    getUsers(data.join(','))
      .then((res) => {
        setUsers(
          res.data.map((item) => ({
            ...item,
            label: item.fullName,
            value: item.emailAddress,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountryAccessData = (userIds) => {
    dispatch(loader(true));
    setSkeleton(true);
    getCountryAccess({
      country: selectedCountry,
      users: userIds,
    })
      .then((response) => {
        const tableHead = response.data[0].countries.map((item) => {
          return { title: item.country, key: item.country };
        });
        setTableHeadings([{ title: 'User Name', key: 'fullName' }, ...tableHead]);
        const values = response.data.map((item) => {
          const obj = {};
          if (_.isArray(response.data)) {
            item.countries.map((val) => {
              obj[val.country] = val.isAccessible === true ? 'yes' : 'No';
            });
          }
          return { fullName: item.fullName, ...obj };
        });
        setTableData(values);
        setSkeleton(false);
        dispatch(loader(false));
      })
      .catch((e) => {
        console.log(e);
        dispatch(loader(false));
        setSkeleton(false);
      });
  };

  const onApplyFilter = () => {
    let userIds = selectedUsers.map((item) => {
      return String(users.filter((x) => x.value === item)[0].id);
    });
    const startDate = dataFormat(selectedDate[0], 'MM/dd/yyyy');
    const endDate = dataFormat(selectedDate[1], 'MM/dd/yyyy');
    setFilters({
      country: selectedCountry,
      date: startDate + '-' + endDate,
      users: userIds,
      // "startDate": startDate.toString(),
      // "endDate": endDate.toString(),
    });
    getCountryAccessData(userIds);
  };

  const disableApplyFilter = () => {
    if (selectedCountry.length > 0) {
      return false;
    }
    return true;
  };

  const formatUsers = (data) => {
    const newarr = data.map((item, i) => {
      return {
        id: i,
        label: item,
        value: item,
        default: false,
      };
    });
    setCountries(newarr);
  };

  const onChangeUser = (data) => {
    setSelectedUsers(data);
  };

  useEffect(() => {
    userList();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} className='m-b-20'>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={12} sm={12} md={5}>
              <Tooltip title={'Net Revenue Realization Dashboard Reports'} placement='top'>
                <Title data-testid='main-title' className='ellipsis'>
                  Usage Activity Report
                </Title>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item md={3} sm={3}>
                  <Dropdown
                    keyText='Report-country-dropdown'
                    data={countries}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    placeholder='Select Country'
                    allOption={true}
                    onChange={(data) => {
                      onChangeCountry(data);
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={3}>
                  <Dropdown
                    keyText='report-user-dropdown'
                    data={users}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    placeholder='Select Users'
                    allOption={true}
                    defaultOption={selectedUsers}
                    onChange={(data) => {
                      onChangeUser(data);
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={3}>
                  <CustomDateRangePicker defaultDate={selectedDate} callback={setDate} />
                </Grid>
                <Grid item md={2} sm={2} textAlign={'end'}>
                  <PrimaryBtn disabled={disableApplyFilter()} color='primary' onClick={onApplyFilter}>
                    Apply Filter
                  </PrimaryBtn>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className='m-b-20'>
          <Card>
            <CardContent>
              <BlackTitle style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>Country Access</BlackTitle>
              {skeleton ? (
                <>
                  <Grid className='m-t-20'>
                    <CustomTable
                      heading={[1, 2, 3, 4, 5, 6]}
                      value={[
                        [1, 2, 3, 4, 5, 6],
                        [1, 2, 3, 4, 5, 6],
                        [1, 2, 3, 4, 5, 6],
                      ]}
                      showSkeleton={skeleton}
                    />
                  </Grid>
                </>
              ) : tableData?.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item md={12} width={'50vw'}>
                    <CustomTable heading={tableHeadings} value={tableData} stickyHeader={true} maxHeight={400} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
                  <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12}>
          {Object.keys(multiLevelTableConfig).map((item: any) => (
            <MultilevelTableContainer
              headers={multiLevelTableConfig[item].headers}
              endPoint={multiLevelTableConfig[item].endPoint}
              title={multiLevelTableConfig[item].title}
              filters={filters}
              child={multiLevelTableConfig[item].child}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default UsageReport2;
