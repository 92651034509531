import { formatNumber } from '../../../../../../util/helper';

export const lineChartConfig = {
  chart: {
    backgroundColor: 'transparent',
    type: 'spline',
    height: '435px',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    labels: {
      style: {},
      enabled: true,
    },
    gridLineWidth: 1,
    categories: [],
  },
  yAxis: [
    {
      title: {
        enabled: false,
        text: '',
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
    {
      opposite: true,
      title: {
        enabled: false,
        text: '',
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value.toFixed(2));
        },
      },
    },
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    layout: 'horizontal',
    backgroundColor: 'transparent',
    align: 'center',
    verticalAlign: 'top',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
  },
  series: [
    {
      color: 'red',
      name: 'Base Units',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      color: '#4aa156',
      name: 'Predicted',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      color: '#0993cd',
      name: 'Actual',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      yAxis: 1,
      color: '#ffc000',
      name: 'PPU',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
    {
      yAxis: 1,
      color: 'violet',
      name: 'EDRP',
      data: [],
      tooltip: {
        pointFormatter: function () {
          return formatNumber(this.y);
        },
      },
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
