import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Dropdown from "../../../components/Dropdown";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { pageNames } from "../../../mocks/common";
import { loader } from "../../../store/actions/common.action";
import { toast } from "../../../store/actions/toast.action";
import {
  PrimaryBtn,
  Title
} from "../../../styles/Common.Styled";
import { allCountries } from "../../../util/config";
import { getCategoriesByCountry } from "../../../util/services";
import { checkValidation } from "../../../util/validation";
import { createUser, getCountries, getUser, updateUser } from "../service";



const initalState = {
  id: null,
  firstName: '',
  lastName: '',
  emailAddress: '',
  country: '',
  defaultCountry: null,
  defaultCategory: null,
  permissionCountry: null,
  permissionCategory: null,
  role: null,
};

const CreateUser: React.FC = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState(initalState);
  const [countries, setCountires] = React.useState([]);
  // const [defaultCategories, setDefaultCategories] = React.useState([]);
  const [permissionCategories, setPermissionCategories] = React.useState([]);
  const [mode, setMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState<any>();
  const params = useParams();

  useEffect(() => {
    dispatch(loader(true));

    if (params && params.id) {
      setMode('edit');
    }

    getCountries()
      .then((res) => {
        const result = res.data.map((item) => ({ value: item, label: item }));

        setCountires(result);

        dispatch(loader(false));

        if (params && params.id) {
          getUserDetail(params.id);
        }
      })

      .catch((err) => {
        console.log('error', err);

        dispatch(loader(false));
      });
  }, []);

  const getUserDetail = (id) => {
    dispatch(loader(true));

    getUser(id)
      .then((res) => {
        setUserData({
          ...res.data,

          defaultCountry: res.data.defaultCountry.split(','),

          defaultCategory: res.data.defaultCategory.split(','),

          permissionCountry: res.data.permissionCountry.split(','),

          permissionCategory: res.data.permissionCategory.split(','),
        });

        dispatch(loader(false));
      })

      .catch((err) => {
        console.log('err', err);

        dispatch(loader(false));
      });
  };

  const onChangeInput = (val, key) => {
    const data = { ...userData };

    data[key] = val;

    if (key === 'permissionCountry') {
      data['defaultCountry'] = null;
      data['defaultCategory'] = null;
    }
    setUserData({ ...data });
  };

  React.useEffect(() => {
    if (userData.permissionCountry) {
      getPermissionCategories(userData.permissionCountry);
    }
  }, [userData.permissionCountry]);

  const getPermissionCategories = (countries) => {
    const payload = { countries };

    dispatch(loader(true));

    getCategoriesByCountry('filter/categories?all=true', payload)
      .then((response) => {
        setPermissionCategories(response.data);

        dispatch(loader(false));
      })

      .catch((error) => {
        dispatch(loader(false));
      });
  };

  const history = useHistory();

  const onSubmit = () => {
    const payload = {
      ...userData,

      firstName: userData.firstName.trim(),

      lastName: userData.lastName.trim(),

      permissionCountry: userData.permissionCountry.join(','),

      permissionCategory: userData.permissionCategory.join(','),

      defaultCountry: userData.defaultCountry.join(','),

      defaultCategory: userData.defaultCategory.join(','),
    };

    dispatch(loader(true));

    if (mode === 'edit') {
      updateUser({ ...payload })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(toast('User data updated successfully!', true, 3000, 'success'));
            dispatch(loader(false));
          } else {
            dispatch(toast(res?.data?.userMessage || 'Something went wrong', true, 3000, 'error'));
            dispatch(loader(false));
          }
          history.push(`/users`);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            dispatch(toast(err?.response?.data?.userMessage, true, 3000, 'error'));
          } else {
            dispatch(toast('Something went wrong', true, 3000, 'error'));
          }
          dispatch(loader(false));
        });
    } else {
      payload.id = 0;
      createUser({ ...payload })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(toast('User created successfully!', true, 3000, 'success'));
            setUserData(initalState);
            dispatch(loader(false));
          } else {
            dispatch(toast(res?.data?.userMessage || 'Something went wrong', true, 3000, 'error'));
            dispatch(loader(false));
          }
          history.push(`/users`);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            dispatch(toast(err?.response?.data?.userMessage, true, 3000, 'error'));
          } else {
            dispatch(toast('Something went wrong', true, 3000, 'error'));
          }
          dispatch(loader(false));
        });
    }
  };

  const disableApplyFilter = () => {
    return (
      !userData.firstName ||
      !userData.lastName ||
      !userData.emailAddress ||
      !userData.country ||
      !userData.defaultCountry ||
      !userData.defaultCategory ||
      !userData.permissionCountry ||
      !userData.permissionCategory
    );
  };

  const onkeydown = (value, key) => {
    const validation = checkValidation({ value, key });

    if (!validation.valid) {
      setErrorMessage(validation);
    } else {
      setErrorMessage(null);
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <PageContainer page={mode === 'edit' ? pageNames.editUser : pageNames.createUser}>
      <Box>
        <Title sx={{ mb: 2 }}>User Management</Title>
        <Grid container>
          <Grid item xs={6}>
            {/* <Grid container alignItems="center" justifyContent="space-between">
              <Typography>All bug report notifications</Typography>
              <Switch color="secondary" />
            </Grid>
            <Divider sx={{ m: 1 }} />
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>New dashboard being created</Typography>
              <Switch color="secondary" defaultChecked />
            </Grid>
            <Divider sx={{ m: 1 }} />
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography>
                Get a notification if someone shares a report with you

              </Typography>
              <Switch color="secondary" />
            </Grid> */}

            {/* <Divider sx={{ m: 1 }} className="m-b-20" /> */}

            <Title sx={{ mb: 2 }} style={{ fontSize: '20px' }}>
              User Information
            </Title>
            <Grid container display='flex' columns={13} justifyContent='space-between' className='p-b-16'>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Typography>First Name</Typography>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value={userData.firstName}
                    name='firstName'
                    label=''
                    id='firstName'
                    control={<TextField InputProps={{ autoComplete: 'off' }} placeholder='Enter First Name' fullWidth />}
                    onChange={(e) => onChangeInput(e.target.value, 'firstName')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Typography>Last Name</Typography>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value={userData.lastName}
                    name='lastName'
                    id='lastName'
                    label=''
                    control={<TextField InputProps={{ autoComplete: 'off' }} placeholder='Enter Last Name' fullWidth />}
                    onChange={(e) => onChangeInput(e.target.value, 'lastName')}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columns={13} display='flex' justifyContent='space-between' className='p-b-16'>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Typography>Email Address</Typography>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value={userData.emailAddress}
                    name='emailAddress'
                    id='emailAddress'
                    label=''
                    control={<TextField InputProps={{ autoComplete: 'off' }} placeholder='Enter email address' fullWidth />}
                    onChange={(e) => {
                      onChangeInput(e.target.value, 'emailAddress');

                      onkeydown(e.target.value, 'emailAddress');
                    }}
                  />
                </FormControl>
                {errorMessage && <Box style={{ color: 'red', fontStyle: 'italic' }}>{errorMessage.error}</Box>}
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Typography>Country</Typography>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value={userData.country}
                    name='country'
                    label=''
                    control={
                      <Autocomplete
                        style={{ width: '100%' }}
                        className='auto-complete'
                        options={allCountries}
                        onChange={(event: any, newValue) => onChangeInput(newValue.label, 'country')}
                        renderOption={(props, options) => {
                          return (
                            <span {...props} style={{ color: 'black' }}>
                              {options.label}
                            </span>
                          );
                        }}
                        id='country'
                        renderInput={(params) => <TextField sx={{ paddingTop: '2px' }} {...params} />}
                      />
                    }
                  ></FormControlLabel>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Title sx={{ mb: 2 }} style={{ fontSize: '20px' }}>
                User Permission Settings
              </Title>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} key='filterByRole'>
                  <Typography>Role Permissions</Typography>
                  <Dropdown
                    keyText='homepage-role-dropdown'
                    data={[
                      { label: 'Admin', value: 'admin' },
                      { label: 'User', value: 'user' },
                    ]}
                    placeholder='Select Role'
                    defaultOption={userData.role || ['empty']}
                    onChange={(e) => onChangeInput(e[0], 'role')}
                  />
                </Grid>
                <Grid item xs={6} key='filterByCountry'>
                  <Typography>Country Permissions</Typography>
                  <Dropdown
                    keyText='homepage-country-dropdown'
                    data={countries}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    allOption={true}
                    placeholder='Select Country'
                    defaultOption={userData.permissionCountry || ['empty']}
                    onChange={(e) => onChangeInput(e, 'permissionCountry')}
                  />
                </Grid>
                <Grid item xs={6} key='filterByCategory'>
                  <Typography>Category Permissions</Typography>
                  <Dropdown
                    keyText='homepage-country-dropdown'
                    data={permissionCategories}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    allOption={true}
                    placeholder='Select Category'
                    defaultOption={userData.permissionCategory || ['empty']}
                    onChange={(e) => onChangeInput(e, 'permissionCategory')}
                  />
                </Grid>
              </Grid>
              <Title sx={{ mb: 2 }} style={{ fontSize: '20px' }}>
                Default Permission Settings
              </Title>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ mb: 4 }} key='filterByCountry'>
                  <Typography>Country Permissions</Typography>
                  <Dropdown
                    keyText='homepage-country-dropdown'
                    data={
                      _.isArray(userData.permissionCountry)
                        ? userData.permissionCountry.map((item) => ({
                            value: item,
                            label: item,
                          }))
                        : []
                    }
                    allOption={true}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    placeholder='Select Country'
                    defaultOption={userData.defaultCountry || ['empty']}
                    onChange={(e) => onChangeInput(e, 'defaultCountry')}
                  />
                </Grid>
                <Grid item xs={6} key='filterByCategory'>
                  <Typography>Category Permissions</Typography>
                  <Dropdown
                    keyText='homepage-country-dropdown'
                    data={
                      _.isArray(userData.permissionCategory)
                        ? userData.permissionCategory.map((item) => ({
                            value: item,
                            label: item,
                          }))
                        : []
                    }
                    allOption={true}
                    multiple={true}
                    multipleSelectionMsg='Multiple'
                    placeholder='Select Category'
                    defaultOption={userData.defaultCategory || ['empty']}
                    onChange={(e) => onChangeInput(e, 'defaultCategory')}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl color="secondary" sx={{ mb: 4 }}>
                  <FormControlLabel

                    value="send-details-to-email"

                    control={<Checkbox color="secondary" />}

                    label="Send users details to your email address."

                    labelPlacement="end"

                  />
                </FormControl>
              </Grid> */}

              <Grid item xs={12} className='m-b-40'>
                <PrimaryBtn onClick={onSubmit} disabled={disableApplyFilter()} className='m-b-40'>
                  {mode === 'edit' ? <span>Update Account</span> : <span>Create Account</span>}
                </PrimaryBtn>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default CreateUser;
