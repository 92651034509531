import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, Skeleton, Box, Tooltip } from '@mui/material';
import { Title } from '../../SupermarketDashboard.Styled';
import Dashboard from '../../../../components/Dashboard';
import { avgWeeklyUnitSaleChartMock, avgWeeklyUnitSaleMock } from '../../../../mocks/supermarketMock';
import ColumnDrilldown from '../../../../components/charts/ColumnDrilldown';
import CustomTable from '../../../../components/CustomTable';
import { loader } from '../../../../store/actions/common.action';
import _ from 'lodash';
import { NoData } from '../../../../styles/Common.Styled';
import { messages } from '../../../../util/config';
import { separator } from '../../../../util/helper';
import HighchartContainer from '../../../../components/HighchartContainer';

const AvgSalesPricePoint: React.FC<{ pepsicoBaseline; skeleton }> = ({ pepsicoBaseline, skeleton = true }) => {
  // const { heading, value } = avgWeeklyUnitSale;
  const [avgWeeklyUnitSale, setAvgWeeklyUnitSale] = useState({ ...avgWeeklyUnitSaleMock });
  const [avgWeeklyUnitSaleChart, setAvgWeeklyUnitSaleChart] = useState({ ...avgWeeklyUnitSaleChartMock });
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const formatAvgSalesPricePoint = (response) => {
    if (_.isArray(response.avgWeeklyUnitSale)) {
      avgWeeklyUnitSaleChart.xAxisData = response.avgWeeklyUnitSale.map((item) => {
        return item.pricePoint;
      });
      avgWeeklyUnitSaleChart.areaData = response.avgWeeklyUnitSale.map((item) => {
        return item.weeklyAvgUnitSale;
      });
      avgWeeklyUnitSaleChart.columnData = response.avgWeeklyUnitSale.map((item) => {
        return item.amountWeek;
      });
      avgWeeklyUnitSale.value = response.avgWeeklyUnitSale.map((item) => {
        return {
          pricePoint: separator(item.pricePoint.toFixed(2)),
          weeklyAvgUnitSale: separator(item.weeklyAvgUnitSale.toFixed(2)),
          amountWeek: separator(item.amountWeek.toFixed(2)),
        };
      });
      setAvgWeeklyUnitSaleChart({ ...avgWeeklyUnitSaleChart });
      setTimeout(() => {
        setAvgWeeklyUnitSale({ ...avgWeeklyUnitSale });
      }, 0);
    }
  };

  useEffect(() => {
    if (pepsicoBaseline && pepsicoBaseline.data) {
      dispatch(loader(false));
      formatAvgSalesPricePoint(pepsicoBaseline.data);
    } else {
      avgWeeklyUnitSaleChart.xAxisData = [];
      avgWeeklyUnitSaleChart.areaData = [];
      avgWeeklyUnitSaleChart.columnData = [];
      avgWeeklyUnitSale.value = [];
      setAvgWeeklyUnitSaleChart({ ...avgWeeklyUnitSaleChart });
      setAvgWeeklyUnitSale({ ...avgWeeklyUnitSale });
      dispatch(loader(false));
    }
  }, [pepsicoBaseline]);

  return (
    <Grid className='m-b-20'>
      <Dashboard
        title='Average Sales at Every Price Point($) Per Week'
        chartRef={chartRef}
        themeTitle
        id='supermarket-avg-sales-at-every-price-point-2'
        showSkeleton={skeleton}
      >
        <Card>
          <CardContent>
            {!skeleton && (
              <Box className='m-b-10'>
                {_.isArray(avgWeeklyUnitSaleChart.xAxisData) && avgWeeklyUnitSaleChart.xAxisData.length > 0 ? (
                  <HighchartContainer chartRef={chartRef} id={'supermarket-avg-sales-at-every-price-point-2'}>
                    <ColumnDrilldown data={avgWeeklyUnitSaleChart} chartRef={chartRef} />
                  </HighchartContainer>
                ) : (
                  <Grid container display='flex' justifyContent='center' alignItems='center' height={400}>
                    <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
                  </Grid>
                )}
              </Box>
            )}
            {skeleton && <Skeleton variant='rectangular' height={400} className='m-b-z0' />}
            {skeleton ? (
              <>
                <Skeleton variant='rectangular' width={400} height={30} className='m-b-20 m-t-10' />
                <CustomTable heading={avgWeeklyUnitSale.heading} value={avgWeeklyUnitSale.value} showSkeleton={skeleton} />
              </>
            ) : (
              _.isArray(avgWeeklyUnitSale.value) &&
              avgWeeklyUnitSale.value.length > 0 && (
                <Tooltip title={'Avg. Weekly Unit Sales by Price Point($)'}>
                  <Title className='m-b-20 ellipsis'>Avg. Weekly Unit Sales by Price Point($)</Title>
                </Tooltip>
              )
            )}
            {_.isArray(avgWeeklyUnitSale.value) && avgWeeklyUnitSale.value.length > 0 && (
              <CustomTable heading={avgWeeklyUnitSale.heading} value={avgWeeklyUnitSale.value} showSkeleton={skeleton} ellipsis={true} />
            )}
          </CardContent>
        </Card>
      </Dashboard>
    </Grid>
  );
};

export default AvgSalesPricePoint;
