const topProductFilterConfig = {
  category: {
    title: 'Category',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  vendor: {
    title: 'Vendor',
    options: [{ value: 'PEPSICO', label: 'PEPSICO' }],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  segment: {
    title: 'Segment',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  brand: {
    title: 'Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  subBrand: {
    title: 'Sub Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  packSize: {
    title: 'Pack Size',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
};

const productFilter = {
  category: {
    title: `Category`,
    options: [],
    placeholder: 'Select',
    defaultSelectAll: false,
    all: true,
    multiple: true,
    first: true,
    children: 'vendor',
  },
  vendor: {
    title: 'Vendor',
    options: [],
    placeholder: 'Select',
    defaultSelectAll: false,
    all: true,
    multiple: true,
    children: 'segment',
  },
  segment: {
    title: `Segment`,
    options: [],
    placeholder: 'Select',
    defaultSelectAll: false,
    all: true,
    multiple: true,
    children: 'brand',
  },
  brand: {
    title: `Brand `,
    options: [],
    placeholder: 'Select',
    all: true,
    defaultSelectAll: false,
    multiple: true,
    children: 'subBrand',
  },
  subBrand: {
    title: `Sub Brand `,
    options: [],
    placeholder: 'Select',
    all: true,
    defaultSelectAll: false,
    multiple: true,
    children: 'packSize',
  },
  packSize: {
    title: `Pack Size`,
    options: [],
    placeholder: 'Select',
    defaultSelectAll: false,
    all: true,
    multiple: true,
    last: true,
  },
};

export const competitorFilterConfig = {
  category: {
    title: 'Category',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  vendor: {
    title: 'Vendor',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  segment: {
    title: 'Segment',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  brand: {
    title: 'Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  subBrand: {
    title: 'Sub Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  packSize: {
    title: 'Pack Size',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
};

const localProductFilterConfig = {
  category: {
    title: 'Category',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  vendor: {
    title: 'Vendor',
    options: [{ value: 'PEPSICO', label: 'PEPSICO' }],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  segment: {
    title: 'Segment',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  brand: {
    title: 'Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  subBrand: {
    title: 'Sub Brand',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
  packSize: {
    title: 'Pack Size',
    options: [],
    placeholder: 'Select',
    all: true,
    multiple: true,
    defaultSelectAll: false,
  },
};

const HeadingData = [
  {
    label: 'Current Period',
    value: 'currentPeriod',
    initial: 'CP',
  },
  {
    label: 'Previous Period',
    value: 'lastPeriod',
    initial: 'LP',
  },
  {
    label: 'Current Period VS Previous Period (%)',
    value: 'periodCompare',
    initial: 'LPCP',
  },
  {
    label: 'ABS Current Period VS Previous Period',
    value: 'absPeriodCompare',
    initial: 'ACP',
  },
];

//measure filter data
const varTop = null;
const Data = [
  {
    label: 'Sell In Data',
    value: 'sellIn',
  },
  {
    label: 'Sell Out Data',
    value: 'sellOut',
  },
];
const viewSize = [
  {
    label: 'MIX Sales Amount',
    value: 'somSalesAmount',
  },
  {
    label: 'MIX Sales Volume',
    value: 'somSalesVolume',
  },
  {
    label: 'MIX Sales Unit',
    value: 'somSalesUnit',
  },
];
const viewType = [
  {
    label: 'Manufacturer',
    value: 'manufacturer',
  },
  {
    label: 'Package Size',
    value: 'packageSize',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Segment',
    value: 'segment',
  },
  {
    label: 'Brand Size',
    value: 'brandSize',
  },
  {
    label: 'Subbrand Size',
    value: 'subBrandSize',
  },
];
const sellIn = [
  {
    label: 'Sales Amount VS Previous Period ',
    value: 'salesAmountVsYAGO',
  },
  {
    label: '%Sales Amount VS Previous Period',
    value: 'perSalesAmountVsYAGO',
  },
  {
    label: 'Sales Unit VS Previous Period',
    value: 'salesUnitVsYAGO',
  },
  {
    label: '%Sales Unit VS Previous Period',
    value: 'perSalesUnitVsYAGO',
  },
  {
    label: 'Sales Volume VS Previous Period ',
    value: 'salesVolumeVsYAGO',
  },
  {
    label: '%Sales Volume VS Previous Period',
    value: 'perSalesVolumeVsYAGO',
  },
  {
    label: 'Profit Per Unit',
    value: 'profitByUnit',
  },
  {
    label: 'Profit Per Volume',
    value: 'profitByVolume',
  },
  {
    label: 'Price Per Volume',
    value: 'priceByVolume',
  },
  {
    label: 'Price Per Unit (SDV)',
    value: 'priceByUnitSDV',
  },
  {
    label: 'D&A Per Unit',
    value: 'dandAByUnit',
  },
  {
    label: 'COGS Per Unit',
    value: 'cogSByUnit',
  },
  {
    label: 'COGS Per Volume',
    value: 'cogSByVolume',
  },
  {
    label: 'Grams Per Unit',
    value: 'gramsPerUnit',
  },
];
const sellOut = [
  {
    label: 'Growth MIX VS Previous Period',
    value: 'growthSOMByYear',
  },
  {
    label: '% Growth MIX VS Previous Period',
    value: 'perGrowthSOMByYear',
  },
  {
    label: 'Sales Amount VS Previous Period',
    value: 'salesAmountVsYAGO',
  },
  {
    label: '%Sales Amount VS Previous Period',
    value: 'perSalesAmountVsYAGO',
  },
  {
    label: 'Sales Unit VS Previous Period',
    value: 'salesUnitVsYAGO',
  },
  {
    label: '%Sales Unit VS Previous Period',
    value: 'perSalesUnitVsYAGO',
  },
  {
    label: 'Sales Volume VS Previous Period',
    value: 'salesVolumeVsYAGO',
  },
  {
    label: ' %Sales Volume VS Previous Period',
    value: 'perSalesVolumeVsYAGO',
  },
  {
    label: 'Price Per Volume',
    value: 'priceByVolume',
  },
  {
    label: 'Price Per Unit',
    value: 'priceByUnit',
  },
  {
    label: 'Grams Per Unit',
    value: 'gramsPerUnit',
  },
];
const sellInVariableData = [
  {
    label: 'Sales Amount',
    value: 'salesAmount',
  },
  {
    label: 'Sales Units',
    value: 'salesUnits',
  },
  {
    label: 'Sales Volume',
    value: 'salesVolume',
  },
  {
    label: 'Grams Per Unit',
    value: 'gramsPerUnit',
  },
  {
    label: 'Price Per Unit',
    value: 'priceByUnit',
  },
  {
    label: 'Price Per Volume',
    value: 'priceByVolume',
  },
];

const sellOutVariableData = [
  {
    label: 'MIX Sales Amount',
    value: 'somSalesAmount',
  },
  {
    label: 'MIX Sales Units',
    value: 'somSalesUnits',
  },
  {
    label: 'MIX Sales Volume',
    value: 'somSalesVolume',
  },
  {
    label: 'Sales Amount',
    value: 'salesAmount',
  },
  {
    label: 'Sales Units',
    value: 'salesUnits',
  },
  {
    label: 'Sales Volume',
    value: 'salesVolume',
  },
  {
    label: 'Grams Per Unit',
    value: 'gramsPerUnit',
  },
  {
    label: 'Price Per Unit',
    value: 'priceByUnit',
  },
  {
    label: 'Price Per Volume',
    value: 'priceByVolume',
  },
];

const ViewXLocal = [
  {
    label: 'Price Per Unit',
    value: 'pricePerUnit',
  },
  {
    label: 'Volume Per Unit',
    value: 'volumePerUnit',
  },
];

const ViewYLocal = [
  {
    label: 'Price Per Unit',
    value: 'pricePerUnit',
  },
  {
    label: 'Price Per Volume',
    value: 'pricePerVolume',
  },
  {
    label: 'Volume Per Unit',
    value: 'volumePerUnit',
  },
];

const AdjustPrice = [
  {
    label: 'Adjust Graph Price',
    value: 'adjustGraphPrice',
  },
  {
    label: 'Enter Parameters In The Table',
    value: 'enterParametersInTheTable',
  },
];

const measureFilter = {
  SOMVarConfig: {
    title: 'SOM Variable Configuration',
    options: [
      { id: '1', value: 'Sales' },
      { id: '2', value: 'Volume' },
      { id: '3', value: 'Units' },
    ],
    defaultOption: { id: '1', value: 'Sales' },
  },
  SOM: {
    title: 'SOM/Ctg Sgmnt',
    options: [
      { id: '1', value: 'Category' },
      { id: '2', value: 'Segment' },
    ],
    defaultOption: { id: '1', value: 'Category' },
    showRadioButton: true,
  },
};

const monthRangeFilter = {
  title: 'Select Range of Month',
  options: [
    { id: '1', value: '3 Months' },
    { id: '2', value: '6 Months' },
    { id: '3', value: '12 Months' },
  ],
  defaultOption: { id: '1', value: '3 Months' },
  showRadioButton: true,
};
const simulationSellOutStatsCard = [
  {
    title: 'Gross Sales',
    value: '0',
  },
  {
    title: 'Volume (KGS)',
    value: '0',
  },
  {
    title: 'Share Of Market',
    value: '0',
  },
];

const simulationSellInStatsCard = [
  {
    title: 'Gross Sales',
    value: '0',
  },
  {
    title: 'Volume (KGS)',
    value: '0',
  },
  {
    title: 'Gross Profit',
    value: '0',
  },
];

export const periodView = {
  title: 'Period View',
  options: [
    { label: 'NA', value: 'na' },
    { label: 'Full Year', value: 'FullYear' },
    { label: 'Year to Date', value: 'YearToDate' },
    { id: '3', label: 'Rolling Year', value: 'RollingYear' },
  ],
  placeholder: 'Select',
};

export const simulationTableHeadings = [
  {
    title: 'View Type',
    value: 'productName',
  },
  {
    title: 'Sales Current ',
    value: 'salesCurrent',
  },
  {
    title: 'Sales Simulated',
    value: 'salesSimulations',
  },
  {
    title: 'Volume Current',
    value: 'volumeCurrent',
  },
  {
    title: 'Volume Simulated',
    value: 'volumeSimulations',
  },
  {
    title: 'Units Current',
    value: 'unitsCurrent',
  },
  {
    title: 'Units Simulated',
    value: 'unitsSimulations',
  },
  {
    title: 'PPU Current',
    value: 'ppuCurrent',
  },
  {
    title: 'PPU Simulation',
    value: 'ppuSimulations',
    editable: true,
  },
  {
    title: 'Elasticity PPU Simulation',
    value: 'elasticityPPUSimulations',
    editable: true,
  },
  {
    title: 'VPU Current',
    value: 'vpuCurrent',
  },
  {
    title: 'VPU Simulation',
    value: 'vpuSimulations',
    editable: true,
  },
  {
    title: 'Elasticity VPU Simulation',
    value: 'elasticityVPUSimulations',
    editable: true,
  },
  {
    title: 'PPV Current',
    value: 'ppvCurrent',
  },
  {
    title: 'PPV Simulation',
    value: 'ppvSimulations',
    editable: true,
  },
  {
    title: 'Elasticity PPV Current',
    value: 'elasticityPPVCurrent',
  },
  {
    title: 'Elasticity PPV Simulation',
    value: 'elasticityPPVSimulations',
    editable: true,
  },
  {
    title: 'SOM Current',
    value: 'somCurrent',
  },
  {
    title: 'SOM Simulated',
    value: 'somSimulations',
  },
];

const ViewYViewXMapper = {
  pricePerUnit: 'ppuSimulations',
  pricePerVolume: 'ppvSimulations',
  volumePerUnit: 'vpuSimulations',
};

const exportObject = {
  varTop,
  Data,
  viewSize,
  viewType,
  sellIn,
  sellOut,
  sellInVariableData,
  sellOutVariableData,
  ViewXLocal,
  ViewYLocal,
  AdjustPrice,
  productFilter,
  measureFilter,
  monthRangeFilter,
  simulationSellOutStatsCard,
  simulationSellInStatsCard,
  topProductFilterConfig,
  localProductFilterConfig,
  HeadingData,
  periodView,
  ViewYViewXMapper,
};
export default exportObject;
