import { formatNumber } from "../util/helper";
const KPIData1 = {
  trend: {
    // icon: faCoins,
    color: "#008cca",
    title: "Trend",
    value: "2342",
    raise: false,
    change: "0.01%",
  },
  userView: {
    // icon: faCoins,
    color: "#008cca",
    title: "Users View",
    value: "2342",
    raise: false,
    change: "0.01%",
  },
  favorited: {
    // icon: faScaleUnbalancedFlip,
    color: "#00a84d",
    title: "Favorited",
    value: "03h 45m",
    raise: true,
    change: "-4.11%",
  },
  shared: {
    //icon: faCartShopping,
    color: "#ff6910",
    title: "Shared",
    value: "27",
    raise: false,
    change: "0.01%",
  },
  saved: {
    //icon: NetRevenue,
    title: "Saved",
    value: "89",
    raise: false,
    change: "0%",
    // className: "row1NetRevenue",
  },
};
const series = {
  trend: {
    type: "areaspline",
    name: "Trending Dashboard Over Time",
    fillOpacity: 0.3,
    data: [],
    color: "#f77b36",
    tooltip: {
      pointFormatter: function () {
        return formatNumber(this.y.toFixed(2));
      },
    },
    categories: [],
    yAxisLabel: "# Of Views",
    endPoint: "usagereport/gettrendingusagereport",
    tableData: { show: true, headers: [], values: [] },
  },
  userView: {
    type: "areaspline",
    name: "Dashboard Viewed Over Time",
    fillOpacity: 0.3,
    data: [],
    color: "#f75fef",
    tooltip: {
      pointFormatter: function () {
        return formatNumber(this.y.toFixed(2));
      },
    },
    categories: [],
    yAxisLabel: "# Of Views",
    endPoint: "usagereport/getuserviewusagereport",
    tableData: { show: true, headers: [], values: [] },
  },
  favorited: {
    type: "areaspline",
    name: "Dashboard Saved Over Time",
    fillOpacity: 0.3,
    data: [],
    color: "#0093cd",
    tooltip: {
      pointFormatter: function () {
        return formatNumber(this.y.toFixed(2));
      },
    },
    categories: [],
    yAxisLabel: "# Of Saves",
    endPoint: "UsageReport/getSaveUsageReport",
    tableData: { show: true, headers: [], values: [] },
  },
  shared: {
    type: "areaspline",
    name: "Dashboard Shared Over Time",
    fillOpacity: 0.3,
    data: [],
    color: "#00aa52",
    tooltip: {
      pointFormatter: function () {
        return formatNumber(this.y.toFixed(2));
      },
    },
    categories: [],
    yAxisLabel: "# Of Shares",
    endPoint: "usagereport/getsharedusagereport",
    tableData: { show: true, headers: [], values: [] },
  },
  saved: {
    type: "areaspline",
    name: "Dashboard Favorited Over Time",
    fillOpacity: 0.3,
    data: [],
    color: "#25d4cf",
    tooltip: {
      pointFormatter: function () {
        return formatNumber(this.y.toFixed(2));
      },
    },
    categories: [],
    yAxisLabel: "# Of Favorites",
    endPoint: "UsageReport/getFavoriteUsageReport",
    tableData: { show: true, headers: [], values: [] },
  },
};
const exportObject = { KPIData1, series };
export default exportObject;
