import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup";

import CheckBoxGroup from "../../../../components/UI-components/CheckBoxGroup/CheckBoxGroup";
import _ from "lodash";

const MeasureFilter: React.FC<{ data; selectedMeasureFilters, callback; selectedProductFilters; selectedAnchorFilters}> = ({
  data,
  selectedMeasureFilters,
  callback,
  selectedProductFilters,
  selectedAnchorFilters
}) => {
  const [variable1, setvariable1] = useState(data.variable1);
  const [variable2, setvariable2] = useState(data.variable2);

  const onSelectMeasure = (type, selectedVal) => {
    let temp = { ...selectedMeasureFilters };
    if (type === "variable1") {
      const variable2Temp = { ...variable2 };
      variable2Temp.options[0].disabled = selectedVal === "SOM";
      variable2Temp.options[1].disabled = selectedVal === "API";
      setvariable2(variable2Temp);
      temp.apiUnits = selectedVal === "API" ? "" : temp.apiUnits;
      temp.somGroup = selectedVal === "SOM" ? "" : temp.somGroup;
      temp.somMeasure = selectedVal === "SOM" ? "" : temp.somMeasure;
    }

    if (type === "variable2") {
      const variable1Temp = { ...variable1 };
      variable1Temp.options[0].disabled = selectedVal === "SOM";
      variable1Temp.options[1].disabled = selectedVal === "API";
      setvariable1(variable1Temp);
      temp.apiUnits = selectedVal === "API" ? "" : temp.apiUnits;
      temp.somGroup = selectedVal === "SOM" ? "" : temp.somGroup;
      temp.somMeasure = selectedVal === "SOM" ? "" : temp.somMeasure;
    }
    temp[type] = selectedVal;
    callback(temp);
  };

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={12} sm={2} key={`measures-dropdown-container-1`}>
        <DropdownTitle>{variable1.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-filters-1`}
          color="#000"
          showButton={variable1.showRadioButton}
          data={variable1.options}
          defaultOption={selectedMeasureFilters.variable1}
          select={(data) => onSelectMeasure("variable1", data)}
        />
      </Grid>
      <Grid item xs={12} sm={2} key={`measures-dropdown-container-2`}>
        <DropdownTitle>{variable2.title}</DropdownTitle>
        <RadioButtonGroup
          key={`measures-filters-2`}
          color="#000"
          showButton={variable2.showRadioButton}
          data={variable2.options}
          defaultOption={selectedMeasureFilters.variable2}
          select={(data) => onSelectMeasure("variable2", data)}
        />
      </Grid>
      <Grid item xs={12} sm={8} key={`measures-variable-config`}>
        <DropdownTitle>Variable Configuration</DropdownTitle>
        <Grid container>
          <Grid className="m-r-10 b-r-1">
            <CheckBoxGroup
              single={true}
              data={data.SOMVarConfig.options}
              color="#000"
              direction="row"
              select={(data) => onSelectMeasure("somGroup", data)}
              defaultOption={selectedMeasureFilters.somGroup}
              disabled={
                selectedMeasureFilters.variable1 !== "SOM" &&
                selectedMeasureFilters.variable2 !== "SOM"
              }
            />
          </Grid>
          <Grid>
            <RadioButtonGroup
              key={`measures-filters-som`}
              color="#000"
              showButton={data.SOM.showRadioButton}
              data={data.SOM.options}
              defaultOption={selectedMeasureFilters.somMeasure}
              select={(data) => onSelectMeasure("somMeasure", data)}
              direction="row"
              disabled={
                selectedMeasureFilters.variable1 !== "SOM" &&
                selectedMeasureFilters.variable2 !== "SOM"
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <CheckBoxGroup
            data={data.measure.options}
            color="#000"
            direction="row"
            single={true}
            select={(data) => onSelectMeasure("apiUnits", data)}
            defaultOption={selectedMeasureFilters.apiUnits}
            disabled={
              selectedMeasureFilters.variable1 !== "API" &&
              selectedMeasureFilters.variable2 !== "API"
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={2} key={`measures-product-list`}>
        {selectedProductFilters.brand &&
          <DropdownTitle className="m-b-10">PEPSICO</DropdownTitle>}
        {selectedProductFilters.brand && selectedProductFilters.brand.length &&
          <Box style={{ overflow: 'auto', height: 245 }}>
            {selectedProductFilters.brand.map((b) => (
              <Box style={{ color: "#000" }}>{b}</Box>
            ))}
          </Box>
        }
      </Grid>
      <Grid item xs={12} sm={2} key={`measures-anchor-list`}>
        {selectedAnchorFilters.anchorBrand &&
          <DropdownTitle className="m-b-10">ANCHOR</DropdownTitle>}
        {selectedAnchorFilters.anchorBrand && selectedAnchorFilters.anchorBrand.length &&
          <Box style={{ overflow: 'auto', height: 245 }}>
            {selectedAnchorFilters.anchorBrand.map((b) => (
              <Box style={{ color: "#000" }}>{b}</Box>
            ))}
          </Box>}
      </Grid>
    </Grid>
  );
};

export default MeasureFilter;
