import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardActions from '../../components/DashboardActions';
import DashboardSourceList from '../../components/DashboardSourceList/DashboardSourceList';
import { pageList } from '../../mocks/common';
import data from '../../mocks/revenueMap';
import { removeMMPWFromLocal } from '../../util/helper';
import QuadrantIntensityGraph from './components/QuadrantIntensityGraph';
import RevenueMapByType from './components/RevenueMapByType/RevenueMapByType';
import TopFilters from './components/TopFilters';
import { quadrantIntensityLoader } from './store/revenueMap.action';
import { Grid } from '@mui/material';
import GlobalNotifications from '../../components/GlobalNotificationContainer/GlobalNotificationContainer';

const RevenueMap: React.FC<{}> = () => {
  const [appliedFilters, setAppliedFilters] = React.useState({} as any);
  const [selectedLocalFilters, setSelectedLocalFilters] = React.useState({});
  const [appliedLocalFilters, setAppliedLocalFilters] = React.useState({} as any);
  const [localFilterFlag, setLocalFilterFlag] = React.useState(false);
  const [defaultFilters, setDefaultFilters] = React.useState({});
  const [disableDownloadButton, setDisableDownloadButton] = React.useState(true);

  const dispatch = useDispatch();

  const reduxState = useSelector((state: any) => state);
  const RevenueMap = useSelector((state: any) => state.RevenueMap);

  const onApplyFilter = async (filters) => {
    setDisableDownloadButton(false);
    setDefaultFilters({});
    dispatch(quadrantIntensityLoader(true));
    removeMMPWFromLocal('revenue-map-quadrant-intensity-graph');
    setSelectedLocalFilters({});
    setAppliedFilters(filters);
  };

  const onApplyLocalFilter = async (localFilters) => {
    setDefaultFilters({});
    setLocalFilterFlag(true);
    setAppliedLocalFilters(localFilters);
  };
  const isDisabled: boolean = false;
  const getSaveDashBoardsData = (data) => {
    if (data.filters) {
      //use this after api development
      console.log('revenue save filters', JSON.parse(data.filters));
      let savedDashboardData = { filters: JSON.parse(data.filters) };
      setDisableDownloadButton(false);
      console.log('savedDashboardData revenue', savedDashboardData);
      setDefaultFilters({
        ...savedDashboardData?.filters?.global,
        views: savedDashboardData?.filters.localFilters?.views,
        variables: savedDashboardData?.filters.localFilters?.variables,
      });
      setAppliedFilters(savedDashboardData?.filters?.global);
      // storeMMPWData(savedDashboardData?.mmpw);
    }
  };

  return (
    <>
      <DashboardActions
        disabled={isDisabled}
        title='Revenue Map'
        dashboardKey={pageList[6].id}
        callback={getSaveDashBoardsData}
        data={{
          filters: {
            global: { ...appliedFilters },
            localFilters: { ...appliedLocalFilters },
          },
          mmpwKey: 'revenue-map-quadrant-intensity-graph',
        }}
        disableDownloadButton={disableDownloadButton || reduxState.common.loader || RevenueMap.quadrantIntensityLoader}
      >
        <Grid container spacing={2} className='m-b-20'>
          <GlobalNotifications pageName={pageList[14].value} />
        </Grid>
        <TopFilters callback={onApplyFilter} data={data} defaultFilters={defaultFilters} />
        <QuadrantIntensityGraph filters={appliedFilters} />
        <RevenueMapByType filters={appliedFilters} defaultFilters={defaultFilters} callback={onApplyLocalFilter} />

        {/* <QuadrantIntensitySimulationFilter filters={{ ...appliedFilters }} callback={setSelectedLocalFilters} />
        <QuadrantIntensityGraphSimulationValues
          globalFilters={{ ...appliedFilters }}
          localFilters={selectedLocalFilters ? { ...selectedLocalFilters } : {}}
        /> */}
        <DashboardSourceList category={appliedFilters.category} dashboardName={pageList[6].id} appliedFilters={appliedFilters} />
      </DashboardActions>
    </>
  );
};

export default RevenueMap;
