import { FETCH_MIX_SCENARIO_DATA, FETCH_SCENARIO_DATA, LOADER_STATE_SCENARIO_DATA } from "../../actions/ScenarioLibrary/constant"

const initialState = {
    loading: false,
    error: false,
    scenarioLibraryData: [],
    mixScenarioLibraryData: []
}


export const ScenarioLibraryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SCENARIO_DATA:
            return {
                ...state,
                scenarioLibraryData: payload?.scenarios
            }
        case FETCH_MIX_SCENARIO_DATA:
            return {
                ...state,
                mixScenarioLibraryData: payload?.scenarios
            }
        case LOADER_STATE_SCENARIO_DATA:
            return {
                ...state,
                loading: payload
            }
        default:
            return state
    }
}