const anchorFilter = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "Select",
    first: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "Select",

    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "Select",

    children: "anchorSubBrand",
  },
  anchorSubBrand: {
    title: "Anchor Sub-brand",
    options: [],
    placeholder: "Select",

    children: "anchorPackSize",
  },
  anchorPackSize: {
    title: "Anchor Pack-size",
    options: [],
    placeholder: "Select",

    children: "anchorPermutation",
  },
  anchorPermutation: {
    title: `Permutation Computation <span style="color: red;">*</span>`,
    options: [],
    placeholder: "Select",

    last: true,
  },
};

const anchorFiltersBrand = {
  anchorCategory: {
    title: "Anchor Category",
    options: [],
    placeholder: "Select",
    first: true,
    children: "anchorSegment",
  },
  anchorSegment: {
    title: "Anchor Segment",
    options: [],
    placeholder: "Select",

    children: "anchorBrand",
  },
  anchorBrand: {
    title: "Anchor Brand",
    options: [],
    placeholder: "Select",

    children: "anchorPermutation",
  },
  anchorPermutation: {
    title: `Permutation Computation <span style="color: red;">*</span>`,
    options: [],
    placeholder: "Select",

    last: true,
  },
};

const measureFilter = {
  comparisonLevel: {
    title: "Comparison Level",
    options: [
      { id: "1", label: "Brand", value: "brand" },
      { id: "2", label: "Sub-Brand/Pack-Size", value: "subBrandPackSize" },
    ],
    defaultOption: { id: "1", value: "brand" },
    showRadioButton: true,
  },
  benchMark: {
    title: "Benchmark of Macro Econ Variables",
    options: [
      { id: "1", label: "Average of last two years", value: "average" },
      { id: "2", label: "Most recent value", value: "recent" },
    ],
    defaultOption: { id: "1", value: "average" },
    showRadioButton: true,
  },
};

const slidersMockData = [
  {
    label: "Inflation Rate",
    defaultValue: 11,
    minValue: -20,
    maxValue: 20,
  },
  {
    label: "Discount",
    defaultValue: -10,
    minValue: -30,
    maxValue: -5,
  },
  {
    label: "PPV",
    defaultValue: 34,
    minValue: 10,
    maxValue: 45,
  },
  {
    label: "VPU",
    defaultValue: 47,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 47,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 30,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 20,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 27,
    minValue: 10,
    maxValue: 40,
  },
  {
    label: "VPU",
    defaultValue: 47,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 47,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 23,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 30,
    minValue: 0,
    maxValue: 50,
  },
  {
    label: "VPU",
    defaultValue: 20,
    minValue: 10,
    maxValue: 40,
  },
  {
    label: "VPU",
    defaultValue: 10,
    minValue: 2,
    maxValue: 20,
  },
  {
    label: "VPU",
    defaultValue: 20,
    minValue: 10,
    maxValue: 40,
  },
];

const predectedSalesUnitMock = {
  predSalesUnitOnlyCoefPPU: [
    {
      actualValue: 2200,
      estimatedValue: 600,
      ppu: 12,
    },
    {
      actualValue: 2500,
      estimatedValue: 500,
      ppu: 14,
    },
    {
      actualValue: 2000,
      estimatedValue: 400,
      ppu: 16,
    },
    {
      actualValue: 1500,
      estimatedValue: 350,
      ppu: 18,
    },
    {
      actualValue: 1300,
      estimatedValue: 600,
      ppu: 20,
    },
    {
      actualValue: 1200,
      estimatedValue: 700,
      ppu: 22,
    },
    {
      actualValue: 1000,
      estimatedValue: 300,
      ppu: 24,
    },
  ],
  predSalesUnitWithAllCoef: [
    {
      actualValue: 3000,
      estimatedValue: 566,
      ppu: 12,
    },
    {
      actualValue: 800,
      estimatedValue: 600,
      ppu: 14,
    },
    {
      actualValue: 700,
      estimatedValue: 400,
      ppu: 16,
    },
    {
      actualValue: 600,
      estimatedValue: 500,
      ppu: 18,
    },
    {
      actualValue: 500,
      estimatedValue: 300,
      ppu: 20,
    },
    {
      actualValue: 450,
      estimatedValue: 200,
      ppu: 22,
    },
    {
      actualValue: 450,
      estimatedValue: 100,
      ppu: 24,
    },
  ],
};

export { slidersMockData, predectedSalesUnitMock };

const exportObject = {
  anchorFilter,
  measureFilter,
  anchorFiltersBrand,
};

export default exportObject;
