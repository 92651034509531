import { API } from './helper';

export const getCategoriesByCountry = async (url, payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`, payload);
  } catch (e) {
    return e;
  }
};
export const getGeoLevel2 = async (dashboardKey) => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/${dashboardKey}/geolevels2`);
  } catch (e) {
    return e;
  }
};
export const fetchCompetitorProductFiltersData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/ProductFilter`, payload);
  } catch (e) {
    return e;
  }
};

export const fetchProductFiltersData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/products`, payload);
  } catch (e) {
    return e;
  }
};

export const fetchAnchorFiltersData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/anchorproducts`, payload);
  } catch (e) {
    return e;
  }
};

export const fetchFiltersData = async (url, payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`, payload);
  } catch (e) {
    return e;
  }
};

export const fetchPeriodViewDates = async (dashboardKey, payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/${dashboardKey}/periodView`, payload);
  } catch (e) {
    return e;
  }
};

export const saveDashBoardData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/saves`, payload);
  } catch (e) {
    return e;
  }
};

export const getAllSavedDashboards = async () => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/saves`);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const getDashboardData = async (dashboardId, type) => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/saves/${dashboardId}?type=${type}`, dashboardId);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const getTrendingDashboard = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/gettrendingdashboard`, payload);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const deleteSavedDashboard = async (id) => {
  try {
    return await API.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/saves/${id}`);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const onShareDashboard = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/shares`, payload);
  } catch (e) {
    return e;
  }
};

export const getSharedDashBoardsToMe = async () => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/shares`);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const getSharedDashBoardsByMe = async () => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/shares/by-me`);
  } catch (e) {
    alert('some thing went wrong');
  }
};

export const getAllDashboards = async () => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards`);
  } catch (e) {
    alert('some thing went wrong');
  }
};

// New services for price ladder dashboard
export const fetchSellPeriodViewDates = async (dashboardKey, payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}filter/${dashboardKey}/SellPeriodView`, payload);
  } catch (e) {
    return e;
  }
};

export const getFavoriteDashboards = async () => {
  try {
    return await API.get(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/favoriteDashboard`);
  } catch (e) {
    return e;
  }
};

export const favoriteDashboard = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/favorite`, payload);
  } catch (e) {
    return e;
  }
};

export const removeFavoriteDashboard = async (dashboardKey) => {
  try {
    return await API.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/favorite?dashboardName=${dashboardKey}`);
  } catch (e) {
    return e;
  }
};
export const deleteSharedDashboard = async (params) => {
  try {
    return await API.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/shares?type=${params.type}&id=${params.id}`);
  } catch (e) {
    return e;
  }
};

export const downloadAddManualSku = async(url, payload) => {
  try {
    return await API.post(url, payload)
  }
  catch (error){
    throw error
  }
}

export const uploadAddManualSku = async(url,payload) => {
  try {
    return await API.post(url,payload)
  }
  catch (error){
    throw error
  }
}
