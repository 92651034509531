import { API } from '../../util/helper';

export const getPackEconomicsData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/packeconomics/graph`, payload);
  } catch (e) {
    return e;
  }
};

export const getPackEconomicsDetailsData = async (payload) => {
  try {
    return await API.post(`${process.env.REACT_APP_BACKEND_BASE_URL}dashboards/packeconomics/detail`, payload);
  } catch (e) {
    return e;
  }
};
