import React, { useEffect } from 'react';
import { Avatar, Box, Grid, IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { PrimaryBtn, Title } from '../../../styles/Common.Styled';
import { getUserList } from '../service';
import _, { trim } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteUser } from '../service';
import { USER_CREATE } from '../../../router/CONSTANTS';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../../store/actions/toast.action';
import { stringAvatar } from '../../../util/helper';
import { loader } from '../../../store/actions/common.action';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { pageNames } from '../../../mocks/common';
import { fetchProfileImage } from '../../../store/actions/user.action';
const OFFSET = 30;

const UserList: React.FC = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [totalRecords, setTotalRecord] = React.useState<number>();
  const [recordCount, setRecordCount] = React.useState<number>(0);

  const [pageNo, setPageNumber] = React.useState(1);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [queryPageNo, setQueryPageNumber] = React.useState(
    parseInt(query.get('pageNo'))
  );

  const theme = useSelector(
    (state: { theme: { darkmode: boolean } }) => state.theme
  );

  useEffect(() => {
    getUserListData(pageNo);
  }, [pageNo]);

  const getUserListData = (pageNumber) => {
    const skip = (pageNumber - 1) * OFFSET;
    dispatch(loader(true));
    getUserList(skip, OFFSET)
      .then(async (res: any) => {
        if (_.isArray(res.data.users)) {
          setRecordCount(res.data.totalUsers);
          if (queryPageNo) {
            if (queryPageNo !== pageNo) {
              if (queryPageNo * OFFSET <= res.data.totalUsers) {
                setPageNumber(queryPageNo);
              } else {
                const newPageNumber = Math.ceil(res.data.totalUsers / OFFSET);
                setPageNumber(newPageNumber);
                setQueryPageNumber(newPageNumber);
                window.history.replaceState(
                  null,
                  '',
                  `?pageNo=${newPageNumber}`
                );
              }
            } else if (queryPageNo * OFFSET > res.data.totalUsers) {
              const newPageNumber = Math.ceil(res.data.totalUsers / OFFSET);
              setPageNumber(newPageNumber);
              setQueryPageNumber(newPageNumber);
              window.history.replaceState(null, '', `?pageNo=${newPageNumber}`);
            }
          } else {
            setQueryPageNumber(pageNo);
            window.history.replaceState(null, '', `?pageNo=${pageNo}`);
          }
          setTotalRecord(res.data.users);
          const payload = [];
          await Promise.all(
            res.data.users.map(async (item) => {
              payload.push({
                id: item.id,
                user: {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  emailAddress: item.emailAddress,
                  imageUrl: item.imageUrl,
                  profile: item.imageUrl ? await fetchProfileImage(item.imageUrl) : null
                },
                defaultCountry: item.defaultCountry,
                defaultCategory: item.defaultCategory,
                role: item.role.charAt(0).toUpperCase() + item.role.slice(1),
              })
            })
          );
          setData(payload);
        }
        dispatch(loader(false));
      })
      .catch((e) => {
        if (pageNumber - 1 >= 0) {
          setQueryPageNumber(pageNumber - 1);
          window.history.replaceState(null, '', `?pageNo=${pageNumber - 1}`);
          getUserListData(pageNumber - 1);
        }
      });
  };

  const history = useHistory();
  const onEdit = (id) => {
    history.push(`user/${id}`);
  };

  const onDelete = (id) => {
    dispatch(loader(true));
    deleteUser(id)
      .then((res) => {
        dispatch(toast('User deleted successfully!', true, 2000, 'success'));
        getUserListData(pageNo);
        dispatch(loader(false));
      })
      .catch((e) => {
        dispatch(loader(false));
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'User',
      renderCell: (params: any) => {
        console.log("params", params)
        return (
          <Box className="p-10">
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item className="m-r-10">
                <Box>
                  <Avatar
                    alt={params.value.firstName + params.value.lastName}
                    src={params.value.profile}
                    {...stringAvatar(
                      `${params.value.firstName.trim()} ${params.value.lastName.trim()}`
                    )}
                    sx={{
                      width: 50,
                      height: 50,
                      background: theme.darkmode ? '#25d4cf' : '',
                      color: theme.darkmode ? 'white' : '',
                    }}
                  />
                  {/* <img
                    src={params.value.imageUrl}
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                  /> */}
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  {params.value.firstName} {params.value.lastName}
                </Box>
                <Box>{params.value.emailAddress}</Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      flex: 30,
      sortable: false,
    },
    {
      field: 'defaultCountry',
      headerName: 'Default Country',
      flex: 20,
      sortable: false,
    },
    {
      field: 'defaultCategory',
      headerName: 'Default Category',
      flex: 30,
      sortable: false,
    },
    { field: 'role', headerName: 'Roles', flex: 10, sortable: false },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <div>
          <IconButton component="span" onClick={() => onEdit(params.id)}>
            <EditIcon />
          </IconButton>
          <IconButton component="span" onClick={() => onDelete(params.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <PageContainer page={pageNames.userList}>
      <Box>
        <Grid container display="flex" justifyContent="space-between">
          <Grid item className="m-b-20">
            <Title>User Management </Title>
            {/* <Box>A total of {totalRecords} user accounts were created</Box> */}
          </Grid>
          <Grid item>
            <PrimaryBtn color="primary" onClick={() => history.push(USER_CREATE)}>
              Add New User
            </PrimaryBtn>
          </Grid>
        </Grid>
        <Box sx={{ background: '#EDF5FA' }}>
          <div style={{ height: '75vh', width: '100%' }}>
            <DataGrid
              rowHeight={65}
              pagination={true}
              rows={data}
              rowCount={recordCount}
              columns={columns}
              checkboxSelection={false}
              disableColumnMenu={true}
              paginationMode="server"
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No users available
                  </Stack>
                ),
              }}
              pageSize={OFFSET}
              onPageChange={(no) => {
                window.history.replaceState(null, '', `?pageNo=${no + 1}`);
                setQueryPageNumber(no + 1);
                setPageNumber(no + 1);
              }}
              rowsPerPageOptions={[10]}
              page={pageNo - 1}
            />
          </div>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default UserList;
