import { GET_PEPSICO_FILTERS, GET_PEPSICO_FILTERS_SUCESS, GET_PEPSICO_FILTERS_FAILURE, ON_CHANGE_PEPSICO_OPTION, CLEAR_PEPSICO_FILTERS } from '../actions/constants';
import { mapFilterOptions, selectAllOptions } from '../../util/helper';
import _ from 'lodash';

export let pepsicoInitialState = {
    data: null,
    loading: false,
    error: null,
    selectedFilters: {
      vendor: null,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    },
    disabledFilters: {
      vendor: true,
      category: true,
      segment: true,
      brand: true,
      subBrand: true,
      packSize: true,
    }
  }

  const pepsicoFilter = (state = pepsicoInitialState, action) => {
    switch (action.type) {
        case GET_PEPSICO_FILTERS:
          return {
            ...state,
            data: null,
            loading: true,
            error: null,
        };
        case ON_CHANGE_PEPSICO_OPTION:
        return {
            ...state,
            selectedFilters: setValues(state.selectedFilters, action.payload),
        }
        default:
            return state;
    }
  }

  export default pepsicoFilter

  export const setValues = (previous, payload) => {
    const key = Object.keys(payload)[0];  
    const val = payload[key];
    switch (key) {
        case 'category':
          payload = {
            vendor: val,
            category: null,
            segment: null,
            brand: null,
            subBrand: null,
            packSize: null
          }
          break;
        }
  }

