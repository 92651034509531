import {
  PRICING_POTENTIAL_SLIDER_DATA,
  PRICING_POTENTIAL_CHART_DATA,
} from "./constants";

let intiState = {
  pricingPotentialSliderLoader: false,
  pricingPotentialChartLoader: false,
};

const topLoader = (state = intiState, action) => {
  switch (action.type) {
    case PRICING_POTENTIAL_CHART_DATA:
      return {
        ...state,
        pricingPotentialChartLoader: action.payload,
      };
    case PRICING_POTENTIAL_SLIDER_DATA:
      return {
        ...state,
        pricingPotentialSliderLoader: action.payload,
      };
    default:
      return state;
  }
};

export default topLoader;
