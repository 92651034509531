export const measureFilter = {
    api: {
        title: "API",
        options: [
            { id: "1", value: "API" },
        ],
        defaultOption: { id: "1", value: "API" },
        showRadioButton: true,
    },
    apiVariableConfig: {
        title: "Variable Configuration",
        options: [
            { id: "1", value: "KGs ( in '000s )" },
            { id: "2", value: "Units ( in '000s )" },
        ],
        defaultOption: { id: "1", value: "KGs ( in '000s )" },
        showRadioButton: true,
    },
    variable: {
        title: "Variable",
        options: [
            { id: "1", value: "SOM" },
            { id: "2", value: "Volume" },
            { id: "43", value: "Revenue" },
            { id: "4", value: "Units" },
        ],
        defaultOption: { id: "1", value: "SOM" },
        showRadioButton: true,
    },
    variableConfig: {
        title: "Variable Configuration",
        options: [
            { id: "1", value: "Sales" },
            { id: "2", value: "Volume" },
            { id: "3", value: "Units" },
        ],
        defaultOption: { id: "1", value: "Sales" },
    },
    SOMConfig: {
        options: [
            { id: "1", value: "Category" },
            { id: "2", value: "Segment" },
        ],
        defaultOption: { id: "1", value: "Category" },
        showRadioButton: true,
    },
}