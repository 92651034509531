import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from '../../../components/Dashboard';
import mockData from '../../../mocks/pricePackEconomicsDashboardMock';
import { NoData } from '../../../styles/Common.Styled';
import { messages } from '../../../util/config';
import { formatNumber } from '../../../util/helper';
import { getPackEconomicsData } from '../service';
import { predictivePackEconomics } from '../store/PredictivePackEconomics.action';
import { chartConfig } from './chartsConfig';
import PackEconTable from './components/packEconTable';
import HighchartContainer from './../../../components/HighchartContainer';
const chartColors = ['#ff9900', '#ffcc00', '#25d4cf', '#ffcc00', '#ffcc00', '#ff9900', '#ff3399', '#00b300', '#ff9999', '#d279d2'].reverse();
const PredictivePackEconChartTableContainer: React.FC<{ filters }> = ({ filters }) => {
  const [skeleton, setSkeleton] = useState(true);
  const chartRef = useRef();
  const [mockTableData, setMockTableData] = useState([...mockData.tableData]);
  const [apiTableData, setTableData] = useState(mockTableData);
  const [originalResponse, setOriginalResponse] = useState({});
  const dispatch = useDispatch();
  const getFormattedString = (item) => {
    const remainingItems = item.length > 3 ? item.splice(3, item.length) : [];
    return `${item.join(', ')} ${remainingItems.length > 0 ? `and ...${remainingItems.length}more ` : ''}`;
  };
  const getBooleanValue = (key) => {
    return key === 'srp' || key === 'ptr' || key === 'listPrice' || key === 'gp' || key === 'ptc';
  };
  const getBreadcrumbs = () => {
    let breadCrumb: any = [];
    if (filters && filters.country) {
      filters?.category && breadCrumb.push(getFormattedString([...filters?.category]));
      filters?.segment && breadCrumb.push(getFormattedString([...filters?.segment]));
      filters?.brand && breadCrumb.push(getFormattedString([...filters?.brand]));
      filters?.subBrand && breadCrumb.push(getFormattedString([...filters?.subBrand]));
      filters?.packSize && breadCrumb.push(getFormattedString([...filters?.packSize]));
    }
    return breadCrumb.join('> ');
  };
  const formatChartTableData = (response) => {
    let series1: any = [];
    let series2: any = [];
    let tableData: any = { avg: [], min: [], max: [] };
    Object.keys(response).map((item, i) => {
      series1.push({ name: item, value: response[item].avg });
      tableData.avg.push(response[item].avg);
      tableData.min.push(response[item].min);
      tableData.max.push(response[item].max);
    });
    Object.keys(response).map((item, i) => {
      if (!getBooleanValue(item)) {
        if (i === 4) {
          series2.push([
            tableData.avg[i - 2] - tableData.avg[i - 1] - (tableData.avg[i] - tableData.min[i]),
            tableData.avg[i - 2] - tableData.avg[i - 1] + (tableData.max[i] - tableData.avg[i]),
          ]);
        } else {
          series2.push([tableData.avg[i - 1] - (tableData.avg[i] - tableData.min[i]), tableData.avg[i - 1] + (tableData.max[i] - tableData.avg[i])]);
        }
      } else {
        series2.push([tableData.min[i], tableData.max[i]]);
      }
    });
    let formatedTableData = [...mockTableData];
    formatedTableData[1].values = tableData.max;
    formatedTableData[2].values = tableData.avg;
    formatedTableData[3].values = tableData.min;
    setTableData([...formatedTableData]);
    series1 = series1.reverse();
    series2 = series2.reverse();
    if (chartRef && chartRef.current && chartRef.current.chart && series1?.length > 0) {
      const chart = chartRef.current.chart;
      let chartData: any = [];
      let categories: any = [];
      series1.map((item, i) => {
        if (i === 0 || i === series1.length - 1) {
          chartData.push({
            name: mockData.titleMapper[item.name].title,
            y: item.value,
            color: chartColors[i],
            isSum: i === series1.length - 1,
          });
        } else {
          chartData.push({
            name: mockData.titleMapper[item.name].title,
            y: item.value,
            color: chartColors[i],
            isSum: getBooleanValue(item.name),
          });
        }
        categories.push(mockData.titleMapper[item.name].title);
      });
      chart.xAxis[0].setCategories(categories);
      chart.series[0].setData(chartData);
      chart.series[1].setData(series2);
    }
  };
  useEffect(() => {
    if (filters && filters.country) {
      const tableData = [...mockTableData];
      tableData[0].values[4] = filters.isMarkUp === 1 ? 'MarkUp' : 'MarkDown';
      setMockTableData(tableData);
      setOriginalResponse({});
      setTableData(mockTableData);
      setSkeleton(true);
      getPackEconomicsData(filters)
        .then((response) => {
          setTimeout(() => {
            if (response) {
              dispatch(predictivePackEconomics(false));
              setSkeleton(false);
              setOriginalResponse({ ...response.data });
              formatChartTableData(response.data);
            } else {
              dispatch(predictivePackEconomics(false));
              setSkeleton(false);
              formatChartTableData({});
            }
          }, 0);
        })
        .catch((e) => {
          console.log();
          dispatch(predictivePackEconomics(false));
          setSkeleton(false);
          formatChartTableData({});
        });
    }
  }, [filters]);

  const onTooltipHover = (index) => {
    const keys = Object.keys(originalResponse).reverse();
    const key = keys[index];
    return `MIN: ${formatNumber(originalResponse[key].min)} - MAX: ${formatNumber(originalResponse[key].max)}`;
  };
  return (
    <Card className='m-b-20'>
      <CardContent>
        <Dashboard title='Pack Economics' showSkeleton={skeleton} chartRef={chartRef} id={''} showActionButton={false}>
          {skeleton ? (
            <>
              <Grid item xs={12} md={12} key='skeleton-chart' className='m-b-20'>
                <Skeleton variant='rectangular' height={500} />
              </Grid>
              <Grid item xs={12} md={12} key='skeleton-table'>
                <PackEconTable data={[...apiTableData].splice(1, 3)} showSkeleton={skeleton} />
              </Grid>
            </>
          ) : !_.isEmpty(apiTableData[1].values) ? (
            <>
              <Grid item xs={12} className='m-b-10'>
                <Typography sx={{ fontSize: 16 }}>
                  <span style={{ fontWeight: 'bold', color: "#000" }}>Filters: </span>
                  <span style={{ color: 'gray' }}>{getBreadcrumbs()}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 16 }}>
                  <span style={{ fontWeight: 'bold', color: "#000" }}>Date Range: </span>
                  <span style={{ fontStyle: 'italic', color: 'gray' }}>{filters.date}</span>
                </Typography>
              </Grid>
              <Dashboard
                title={null}
                showSkeleton={skeleton}
                chartRef={chartRef}
                id='pack-economics-predictive-chart'
                mmpwConfig={{
                  addColor: {
                    sectionColor: { errorbar: false, waterfall: true },
                  },
                }}
              >
                <Grid item xs={12} md={12} border={'1px solid #DCDCE3'} borderRadius={'5px'}>
                  <HighchartContainer chartRef={chartRef} id={'pack-economics-predictive-chart'}>
                    <HighchartsReact highcharts={Highcharts} options={chartConfig(onTooltipHover)} ref={chartRef} />
                  </HighchartContainer>
                </Grid>
                <Grid item xs={12} md={12} border={'1px solid #DCDCE3'} borderRadius={'5px'} className='m-t-20'>
                  <PackEconTable data={apiTableData} showSkeleton={skeleton} />
                </Grid>
              </Dashboard>
            </>
          ) : (
            <Grid container display='flex' justifyContent='center' alignItems='center' style={{ height: 400 }}>
              <NoData sx={{ color: 'black' }}>{messages.noData}</NoData>
            </Grid>
          )}
        </Dashboard>
      </CardContent>
    </Card>
  );
};
export default React.memo(PredictivePackEconChartTableContainer);
