import { PREDICTIVE_PACK_ECONOMICS,PREDICTIVE_PACK_ECONOMICS_DETAILS } from './constants';

export const predictivePackEconomics = (payload) => {
  return {
    type: PREDICTIVE_PACK_ECONOMICS,
    payload,
  };
};

export const predictivePackEconomicsDetails = (payload) => {
  return {
    type: PREDICTIVE_PACK_ECONOMICS_DETAILS,
    payload,
  };
};
