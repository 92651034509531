import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import FilterAccordion from "../../../../components/Filters/Filters";
import GeoFiltersV2 from "../../../../components/GeoFilters-V2";
import {
  dateRange,
  initialState,
} from "../../../../components/GeoFilters-V2/GeoFilters-V2";
import Indicator from "../../../../components/Loader";
import ProductFiltersV3 from "../../../../components/ProductFilters-v3";
import { geoFilterV2Config } from "../../../../mocks/geoFilters";
import mockData from "../../../../mocks/pricePackEconomicsDashboardMock";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { dataFormat } from "../../../../util/helper";
import {
  predictivePackEconomics,
  predictivePackEconomicsDetails,
} from "../../store/PredictivePackEconomics.action";
import MeasureFilter from "../MeasureFilters";

const TopFilters: React.FC<{
  callback;
  showSkeleton?: boolean;
  defaultFilters?;
}> = ({ callback, showSkeleton, defaultFilters }) => {
  const [selectedMeasureFilters, setMeasureFilter] = useState({
    absIndex: mockData.measureFilter.absIndex.defaultOption.value || undefined,
    unitVolume:
      mockData.measureFilter.unitVolume.defaultOption.value || undefined,
    taxes: mockData.measureFilter.taxes.defaultValue.value || undefined,
    markUp: mockData.measureFilter.markUp.defaultValue.value || undefined,
    isMarkUp: mockData.measureFilter.markUpAndMarkDown.defaultOption.value === 'markup'? 1 : 0
  });
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    `${dataFormat(dateRange[0], "MM/dd/yyyy")}-${dataFormat(
      dateRange[1],
      "MM/dd/yyyy"
    )}`
  );
  const dispatch = useDispatch();
  const [clearFilterData, clearGeoFilter] = useState(false);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState({
    ...initialState,
  });
  const [selectedProductFilters, setSelectedProductFilters] = useState<any>({});

  const reduxState = useSelector((state: any) => state);

  const disableApplyFilter = () => {
    const flag: boolean =
      !selectedMeasureFilters.absIndex ||
      !selectedMeasureFilters.unitVolume ||
      selectedGeoFilters.country === "" ||
      selectedGeoFilters.country === null ||
      selectedGeoFilters.geoLevel2 === "" ||
      selectedGeoFilters.geoLevel2 === null ||
      !selectedProductFilters.category;
    return flag;
  };

  const onApplyFilter = async () => {
    dispatch(predictivePackEconomics(true));
    dispatch(predictivePackEconomicsDetails(true));
    if (selectedGeoFilters.periodView) {
      delete selectedGeoFilters.periodView;
    }
    const payload = {
      ...selectedGeoFilters,
      ...selectedProductFilters,
      ...selectedMeasureFilters,
      date: selectedDate,
    };
    callback(payload);
  };

  const clearFilter = () => {
    clearGeoFilter(true);
    setSelectedGeoFilters({ ...initialState });
    setSelectedProductFilters({
      ...selectedProductFilters,
      category: null,
      segment: null,
      brand: null,
      subBrand: null,
      packSize: null,
    });
    setMeasureFilter({
      absIndex:
        mockData.measureFilter.absIndex.defaultOption.value || undefined,
      unitVolume:
        mockData.measureFilter.unitVolume.defaultOption.value || undefined,
      taxes: mockData.measureFilter.taxes.defaultValue.value || undefined,
      markUp: mockData.measureFilter.markUp.defaultValue.value || undefined,
      isMarkUp: mockData.measureFilter.markUpAndMarkDown.defaultOption.value === 'markup'? 1 : 0
    });
  };
  const selectProductLogic = (e) => {
    setSelectedProductFilters({ ...e });
    clearGeoFilter(false);
  };

  useEffect(() => {
    if (defaultFilters && defaultFilters.country) {
      setMeasureFilter({
        ...selectedMeasureFilters,
        absIndex: defaultFilters.absIndex,
        unitVolume: defaultFilters.unitVolume,
        taxes: defaultFilters.taxes,
        markUp: defaultFilters.markUp,
        isMarkUp: defaultFilters.isMarkUp
      });
    }
  }, [defaultFilters]);

  const PredictivePackEconomics = useSelector(
    (state: any) => state.PredictivePackEconomics
  );
  return (
    <Card className="m-b-20" style={{ position: "relative" }}>
      <Indicator
        position="absolute"
        show={
          loader ||
          PredictivePackEconomics.predictivePackEconomics ||
          PredictivePackEconomics.predictivePackEconomicsDetails
        }
      />
      <CardContent>
        {!showSkeleton ? (
          <>
            <FilterAccordion title="Geo-Filters">
              <GeoFiltersV2
                key="predictive-pack-economics-top-filter"
                data={geoFilterV2Config}
                onChangeDate={(dt) => {
                  clearGeoFilter(false);
                  setSelectedDate(dt);
                }}
                onSelectFilters={setSelectedGeoFilters}
                clearFilter={clearFilterData}
                apiPath="ApiPredictive"
                showLoader={setLoader}
                defaultFilters={defaultFilters}
              />
            </FilterAccordion>
            <FilterAccordion title="Product Filters">
              <ProductFiltersV3
                onChange={(e) => selectProductLogic(e)}
                data={mockData.productFilter}
                onChangeLoader={setLoader}
                selectedGeoFilters={selectedGeoFilters}
                clearFilter={clearFilterData}
                defaultFilters={
                  defaultFilters
                    ? {
                        category: defaultFilters.category,
                        segment: defaultFilters.segment,
                        brand: defaultFilters.brand,
                        subBrand: defaultFilters.subBrand,
                        packSize: defaultFilters.packSize,
                      }
                    : null
                }
                apiURL="filter/products"
              />
            </FilterAccordion>
            <FilterAccordion title="Measure Filters">
              <MeasureFilter
                data={mockData.measureFilter}
                callback={(e) => setMeasureFilter(e)}
                selectedMeasureFilters={selectedMeasureFilters}
              />
            </FilterAccordion>
            <Grid className="p-l-16">
              <OrangeBtn
                color="secondary"
                className="m-r-20"
                onClick={clearFilter}
              >
                Clear Filter
              </OrangeBtn>
              <PrimaryBtn
                disabled={disableApplyFilter()}
                color="primary"
                onClick={onApplyFilter}
              >
                Apply Filter
              </PrimaryBtn>
            </Grid>
          </>
        ) : (
          <>
            <FilterAccordion showSkeleton>
              <Grid container spacing={2}>
                {Object.keys(geoFilterV2Config).map(() => (
                  <Grid item xs={12} sm={2}>
                    <Skeleton height={22} />
                    <Skeleton variant="rectangular" width={210} height={45} />
                  </Grid>
                ))}
              </Grid>
            </FilterAccordion>
            {/* <FilterAccordion showSkeleton></FilterAccordion> */}
            <Grid className="m-b-20">
              <FilterAccordion showSkeleton>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    key={`measures-dropdown-container-1`}
                  >
                    <DropdownTitle>
                      <Skeleton height={22} />
                    </DropdownTitle>
                  </Grid>
                </Grid>
              </FilterAccordion>
            </Grid>
            <Grid className="p-l-16" container spacing={2}>
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={99}
                height={45}
              />
              <Skeleton
                variant="rectangular"
                className="m-r-20"
                width={110}
                height={45}
              />
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default TopFilters;
